import { render, staticRenderFns } from "./SoccerMatch.vue?vue&type=template&id=780daed2&scoped=true&"
import script from "./SoccerMatch.vue?vue&type=script&lang=js&"
export * from "./SoccerMatch.vue?vue&type=script&lang=js&"
import style0 from "./SoccerMatch.vue?vue&type=style&index=0&id=780daed2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "780daed2",
  null
  
)

export default component.exports