<template>
    <div>
        <vue-recaptcha @verify="markRecaptchaAsVerified"
            sitekey="6Ldyg-0UAAAAAAdfliBDunwIwbkqtYVI4bRlBmBK">
        </vue-recaptcha>
    </div>
</template>

<script>
    import VueRecaptcha from 'vue-recaptcha';

    export default {
        data() {
            return {
                recaptchaVerified: false,
                pleaseTickRecaptchaMessage: ''
            }
        },
        components: { VueRecaptcha },
        methods: {
            markRecaptchaAsVerified(response) {
                this.pleaseTickRecaptchaMessage = '';
                this.recaptchaVerified = true;
                this.$emit('can-continue', {value: true});
            }
        },

        mounted() {
            let recaptchaScript = document.createElement('script')
            recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit')
            document.head.appendChild(recaptchaScript)
//            this.$emit('can-continue', {value: true})
        }
    }
</script>