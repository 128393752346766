<template>
  <v-app>
    <div class="mt-5">
      <div class="mt-12">
        <div class="d-flex justify-center">
          <v-radio-group
            v-model="sortValue"
            row
          >
            <v-radio :value="1">
              <template v-slot:label>
                <span class="font-small">{{ $t('sortbytournament') }}</span>
              </template>
            </v-radio>

            <v-radio :value="0">
              <template v-slot:label>
                <span class="font-small">{{ $t('sortbytime') }}</span>
              </template>
            </v-radio>
          </v-radio-group>
        </div>

        <template v-if="!basicLoaded">
          <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
        </template>

        <template v-else>
          <div class="row no-gutters d-flex justify-center">
            <div class="col-lg-6 col-md-10 col-sm-12">
              <template v-if="inplays && inplays.length">
                <div v-if="!sortValue">
                  <template v-for="group in byTime">
                    <v-list-group
                      :key="group.time + group.league_id"
                      class="grey lighten-2 mb-2"
                      value=1
                    >
                      <template v-slot:activator>
                        <img class='elevation-2 mr-2' :src="group.games[0].league.country.image_path" onerror="this.src='/img/flag/-.png'" />

                        <v-list-item-title
                          class="grey--text text--darken-2 white-space-prewrap"
                          :class="{'caption': $vuetify.breakpoint.xs, 'body-2': $vuetify.breakpoint.smAndUp}">
                          <span :class="{'d-none': $vuetify.breakpoint.xs, '': $vuetify.breakpoint.smAndUp}">
                            {{ group.country }}, 
                          </span>

                          <router-link :to="{name: 'league', params: {lid: group.league_id}}" class="black--text">
                            {{ group.league }}
                          </router-link>

                          <v-icon
                            :color="group.fav_star ? 'grey lighten-1' : 'yellow darken-2'"
                            class="ml-1"
                            v-on:click.native.stop="update_favorite(group.fav_star, group.league_id)">
                            {{ group.fav_star ? 'mdi-star-outline' : 'mdi-star' }}
                          </v-icon>
                        </v-list-item-title>
                      </template>

                      <v-list-item
                        v-for="game in group.games"
                        :key="game.time"
                        class="white"
                        dense
                      >
                        <v-list-item-title
                          class="my-1"
                          :class="{'caption': $vuetify.breakpoint.xs, 'body-2': $vuetify.breakpoint.smAndUp}">
                          <router-link
                            :to="{name: 'fixture', params: {fid: game.id}}"
                            class="d-flex align-center justify-space-between height-30 height-md-40 black--text cursor-bg-beige"
                          >
                            <div class="col-2">
                              <div class="d-flex justify-start">
                                <div>
                                  <template v-if="game.state_id == 1">{{ game.starting_at | moment('MM/DD HH:mm')}}</template>
                            
                                  <template v-else-if="game.state_id == 5 || game.state_id == 7 || game.state_id == 8">
                                    <span class="grey--text">Ended</span>
                                  </template>

                                  <template v-else>
                                    <span v-if="game.periods.length && game.periods[0].minutes" class="font-weight-bold red--text" title="Live match">
                                      <img src="/img/icons/live.gif">

                                      {{ game.periods[0].minutes }}
                                      <span v-if="game.periods[0].time_added && game.periods[0].minutes == 90"> + {{ game.periods[0].time_added }}</span>
                                      '
                                    </span>

                                    <span class="caption blue-grey--text" :title="statusCode[game.state_id - 1].name + ': ' + statusCode[game.state_id - 1].info">
                                      {{ statusCode[game.state_id - 1].short_name }}
                                    </span>
                                  </template>
                                </div>
                              </div>
                            </div>

                            <div
                              class="d-flex justify-center align-center width-100">
                              <div class="d-flex justify-end flex-basis-50 align-center">
                                <div>
                                  <span
                                    :class="((game.scores.penalty[0] && (game.scores.penalty[0] > game.scores.penalty[1])) || (!game.scores.penalty[0] && (game.scores.goal[0] > game.scores.goal[1]))) ? 'font-weight-bold black--text' : 'blue-grey--text'">
                                    {{ $vuetify.breakpoint.xs ? ( game.participants[0].short_code ? game.participants[0].short_code : game.participants[0].name.substring(0, 3)) : game.participants[0].name }}
                                  </span>

                                  <img class="wh-20 mx-1" :src="game.participants[0].image_path ? game.participants[0].image_path : 'https://cdn.sportmonks.com/images/soccer/team_placeholder.png'" />
                                </div>
                              </div>

                              <div
                                class="text-center mx-5"
                                :class="$vuetify.breakpoint.xs ? 'body-2' : 'subtitle-1'"
                                style="min-width: 40px;">
                                <template v-if="game.state_id == 1">
                                  <span style="color: lightgrey" title="Not Started">NS</span>
                                </template>

                                <template v-else>
                                  <span class="white-space-nowrap">
                                    {{ game.scores.goal[0] }} - {{ game.scores.goal[1] }}
                                  </span>

                                  <span v-if="game.scores.penalty[0]" class="red--text" title="Penalty Score">
                                    ({{ game.scores.penalty[0] }}-{{ game.scores.penalty[1] }})
                                  </span>

                                  <template v-if="game.state_id == 7">
                                    <span class="red--text" :title="statusCode[game.state_id - 1].name + ': ' + statusCode[game.state_id - 1].info">
                                      {{ statusCode[game.state_id - 1].short_name }}
                                    </span>
                                  </template>
                                </template>
                              </div>
                                
                              <div class="d-flex flex-basis-50">
                                <img class="wh-20 mx-1" :src="game.participants[1].image_path ? game.participants[1].image_path : 'https://cdn.sportmonks.com/images/soccer/team_placeholder.png'" />

                                <span
                                  class="align-self-center"
                                  :class="((game.scores.penalty[0] && (game.scores.penalty[0] < game.scores.penalty[1])) || (!game.scores.penalty[0] && (game.scores.goal[0] < game.scores.goal[1]))) ? 'font-weight-bold black--text' : 'blue-grey--text'">
                                  {{ $vuetify.breakpoint.xs ? ( game.participants[1].short_code ? game.participants[1].short_code : game.participants[1].name.substring(0, 3)) : game.participants[1].name }}
                                </span>
                              </div>
                            </div>
                          </router-link>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list-group>
                  </template>
                </div>

                <div v-else>
                  <template v-for="group in byCountryLeague">
                    <v-list-group
                      :key="group.league_id"
                      value=1
                      class="grey lighten-2 mb-1"
                    >
                      <template v-slot:activator>
                        <img class="elevation-2 mr-2" :src="group.games[0].league.country.image_path" onerror="this.src='/img/flag/-.png'" />

                        <v-list-item-title
                          class="grey--text text--darken-2 white-space-prewrap"
                          :class="{'caption': $vuetify.breakpoint.xs, 'body-2': $vuetify.breakpoint.smAndUp}">
                          <span :class="{'d-none': $vuetify.breakpoint.xs}">
                            {{ group.country }}, 
                          </span>

                          <router-link :to="{name: 'league', params: {lid: group.league_id}}" class="black--text">
                            {{ group.league }}
                          </router-link>

                          <v-icon
                            :color="group.fav_star ? 'grey lighten-1' : 'yellow darken-2'"
                            class="ml-1"
                            v-on:click.native.stop="update_favorite(group.fav_star, group.league_id)">
                            {{ group.fav_star ? 'mdi-star-outline' : 'mdi-star' }}
                          </v-icon>
                        </v-list-item-title>
                      </template>

                      <v-list-item
                        v-for="game in group.games"
                        :key="game.match_id"
                        class="white"
                        dense
                      >
                        <v-list-item-title
                          class="my-1"
                          :class="{'caption': $vuetify.breakpoint.xs, 'body-2': $vuetify.breakpoint.smAndUp}">
                          <router-link
                            :to="{name: 'fixture', params: {fid: game.id}}"
                            class="d-flex align-center justify-space-between height-30 height-md-40 black--text cursor-bg-beige"
                          >
                            <div class="col-2">
                              <div class="d-flex justify-start">
                                <template v-if="game.state_id == 1">{{ game.starting_at | moment('MM/DD HH:mm')}}</template>
                          
                                <template v-else-if="game.state_id == 5 || game.state_id == 7 || game.state_id == 8">
                                  <span class="grey--text">Ended</span>
                                </template>

                                <template v-else>
                                  <span v-if="game.periods.length && game.periods[0].minutes" class="d-flex align-center font-weight-bold red--text mr-2" title="Live match">
                                    <img class="mr-1" src="/img/icons/live.gif">

                                    {{ game.periods[0].minutes }}
                                    <span v-if="game.periods[0].time_added && game.periods[0].minutes == 90"> + {{ game.periods[0].time_added }}</span>
                                    '
                                  </span>

                                  <span class="caption blue-grey--text" :title="statusCode[game.state_id - 1].name + ': ' + statusCode[game.state_id - 1].info">
                                    {{ statusCode[game.state_id - 1].short_name }}
                                  </span>
                                </template>
                              </div>
                            </div>

                            <div
                              class="d-flex justify-center align-center width-100">
                              <div class="d-flex justify-end flex-basis-50 align-center">
                                <div>
                                  <span
                                    :class="((game.scores.penalty[0] && (game.scores.penalty[0] > game.scores.penalty[1])) || (!game.scores.penalty[0] && (game.scores.goal[0] > game.scores.goal[1]))) ? 'font-weight-bold black--text' : 'blue-grey--text'">
                                    {{ $vuetify.breakpoint.xs ? ( game.participants[0].short_code ? game.participants[0].short_code : game.participants[0].name.substring(0, 3)) : game.participants[0].name }}
                                  </span>

                                  <img class="wh-20 mx-1" :src="game.participants[0].image_path ? game.participants[0].image_path : 'https://cdn.sportmonks.com/images/soccer/team_placeholder.png'" />
                                </div>
                              </div>

                              <div
                                class="text-center mx-5"
                                :class="$vuetify.breakpoint.xs ? 'body-2' : 'subtitle-1'"
                                style="min-width: 40px;">
                                <template v-if="game.state_id == 1">
                                  <span style="color: lightgrey" title="Not Started">NS</span>
                                </template>

                                <template v-else>
                                  <span class="white-space-nowrap">
                                    {{ game.scores.goal[0] }} - {{ game.scores.goal[1] }}
                                  </span>

                                  <span v-if="game.scores.penalty[0]" class="red--text" title="Penalty Score">
                                    ({{ game.scores.penalty[0] }}-{{ game.scores.penalty[1] }})
                                  </span>

                                  <template v-if="game.state_id == 7">
                                    <span class="red--text" :title="statusCode[game.state_id - 1].name + ': ' + statusCode[game.state_id - 1].info">
                                      {{ statusCode[game.state_id - 1].short_name }}
                                    </span>
                                  </template>
                                  <!-- <template v-if="game.state_id != 8 && game.time.status != 'FT_PEN' && game.time.status != 'LIVE' && game.time.status != 'HT'">
                                    <span class="caption elevation-1 red white--text" :title="statusCode[game.time.status]">
                                      {{ game.time.status }}
                                    </span>
                                  </template> -->
                                </template>
                              </div>
                              
                              <div class="d-flex flex-basis-50">
                                <img class="wh-20 mx-1" :src="game.participants[1].image_path ? game.participants[1].image_path : 'https://cdn.sportmonks.com/images/soccer/team_placeholder.png'" />
                                
                                <span
                                  class="align-self-center"
                                  :class="((game.scores.penalty[0] && (game.scores.penalty[0] < game.scores.penalty[1])) || (!game.scores.penalty[0] && (game.scores.goal[0] < game.scores.goal[1]))) ? 'font-weight-bold black--text' : 'blue-grey--text'">
                                  {{ $vuetify.breakpoint.xs ? ( game.participants[1].short_code ? game.participants[1].short_code : game.participants[1].name.substring(0, 3)) : game.participants[1].name }}
                                </span>
                              </div>
                            </div>
                          </router-link>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list-group>
                  </template>
                </div>
              </template>

              <p v-else>No Inplay Games.</p>
            </div>
          </div>
        </template>
      </div>
    </div>
  </v-app>
</template>

<script>

// import { Card, Tabs, TabPane } from '@/components'
import axios from 'axios'
import moment from 'moment-timezone'
// import Team from './Team.vue';

export default {
  components: {},
 
  data() {
    return {
      tz: localStorage.tz ? localStorage.tz : '+0',
      favorite_league: localStorage.favorite_league ? JSON.parse(localStorage.favorite_league) : [],
      sortValue: 1,
      inplays: null,
      
      basicLoaded: false,
      authorization: false,
      message: '',
      statusCode: [
        {'short_name': 'NS', 'name': 'Not Started', 'info': 'The initial status of a game'},
        {'short_name': '1st', 'name': '1st Half', 'info': 'The game is currently inplay in the 1st half'},
        {'short_name': 'HT', 'name': 'Half-Time', 'info': 'The game currently is in half-time'},
        {'short_name': 'BRK', 'name': 'Regular time finished', 'info': 'Waiting for penalties to start'},
        {'short_name': 'FT', 'name': 'Full-Time', 'info': 'The game has ended after 90 minutes. When a game goes into extra time, the FT status will be presented for a few seconds and then move to the BREAK status. '},
        {'short_name': '1et', 'name': 'Extra Time', 'info': 'The game currently is in extra time in the 1st half, can happen in knockout games'},
        {'short_name': 'AET', 'name': 'Finished after extra time', 'info': 'The game has finished after 120 minutes'},
        {'short_name': 'FTP', 'name': 'Full-Time after penalties', 'info': 'Finished after penalty shootout'},
        {'short_name': 'PEN', 'name': 'Penalty Shootout', 'info': "ET status didn't get a winner, penalties are taken to determine the winner"},
        {'short_name': 'POST', 'name': 'PostPoned', 'info': 'The game is postponed'},
        {'short_name': 'SUSP', 'name': 'SUSPENDED', 'info': 'The game has suspended and will continue at a later time or day'},
        {'short_name': 'CANC', 'name': 'Cancelled', 'info': 'The game has been cancelled'},
        {'short_name': 'TBA', 'name': 'To Be Announced', 'info': 'The game does not have a confirmed date and time yet. It will be announced later on.'},
        {'short_name': 'WO', 'name': 'Walk Over', 'info': 'Awarding of a victory to a contestant because there are no other contestants'},
        {'short_name': 'ABAN', 'name': 'Abandoned', 'info': 'The game has abandoned and will continue at a later time or day'},
        {'short_name': 'DELA', 'name': 'Delayed', 'info': 'The game is delayed so it will start later'},
        {'short_name': 'AWAR', 'name': 'Awarded', 'info': 'Winner is being decided externally'},
        {'short_name': 'INT', 'name': 'Interrupted', 'info': 'The game has been interrupted. Can be due to bad weather'},
        {'short_name': 'AU', 'name': 'Awaiting Updates', 'info': 'Can occur when there is a connectivity issue or something'},
        {'short_name': 'DEL', 'name': 'Deleted', 'info': 'Game is not available anymore via normal api calls because it has been replaced. This can happen in leagues that have a lot of changes in their schedules. The games can still be retrieved by adding deleted=1 to your request so you can update your system properly'},
        {'short_name': 'ETB', 'name': 'Extra Time - Break', 'info': 'The game has suspended and will continue at a later time or day'},
        {'short_name': '2nd', 'name': '2nd Half', 'info': 'The game is currently inplay in the 2nd half'},
        {'short_name': '2et', 'name': 'ET - 2nd Half', 'info': 'The game currently is in extra time in the 2nd half, can happen in knockout games'},
        {},
        {'short_name': 'PENB', 'name': 'Penalties - Break', 'info': 'Waiting for penalties to start'},
        {'short_name': 'PEN', 'name': 'Pending', 'info': 'The fixture is awaiting an update'}
      ],
    }
  }, 
  computed: {
    byTime() {
      let allGames = this.inplays

      /* grouping Label : startHour + league */
      let lg = this.favorite_league
      let label = function(game){
          return moment(game['starting_at']).format("HH:mm") + game['league']['country']['name'] + ', ' + game['league']['name']
      }

      let grouping = function(group, label){
          return {
              time: label.substring(0, 5),
              // league: label.substring(5),
              country: group[0]['league']['country']['name'],
              fav_star: (lg && lg.includes(group[0]['league_id'])) ? 0 : 1,
              league: group[0]['league']['name'],
              league_id: group[0]['league_id'],
              games: _.orderBy(group, ['starting_at', 'league.country_id'])
          }
      }
      
      let result = _.chain(allGames)
          .groupBy(label)
          .map(grouping)
          .sortBy('fav_star', 'time', 'league')
          .value()

      return result
    },
    byCountryLeague() {
        let allGames = this.inplays

        let league = function(game){
            return game['league']['country']['name'] + ', ' + game['league']['name']
        }

        let lg = this.favorite_league
        // let exp = this.expanding_league
        let groupToLeague = function(group, league){
            return {
                // league: league,
                country: group[0]['league']['country']['name'],
                league: group[0]['league']['name'],
                league_id: group[0]['league_id'],
                fav_star: (lg && lg.includes(group[0]['league_id'])) ? 0 : 1,
                games: _.orderBy(group, 'starting_at')
            }
        }

        let result = _.chain(allGames)
            .groupBy(league)
            .map(groupToLeague)
            .sortBy('fav_star', 'country')
            .value()
            
        return result
    }
  },
  watch: {},
  mounted() {
    this.init()
    
    this.liveScore()
    setInterval(this.liveScore, 180000)
  },
  methods: {
      async init() {
        //Etc/GMT-X will have an offset of +X and Etc/GMT+X will have an offset of -X.
        moment.tz.setDefault('Etc/GMT' + this.tz)
      },

      liveScore() {
        axios
        .get('/v3/livescore/inplay/',
          { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
        .then(response => {
          if (response.data.type == 0 || response.data.type == 1) {
            this.$router.replace('/message/subscribe/' + (response.data.type ? 'expired' : 'not-paid'))
          }else {
            // this.inplays = response.data.inplay.sort((a, b) => (a.starting_at_timestamp - b.starting_at_timestamp))
            this.inplays = response.data.inplay

            this.inplays.forEach((fx) => {
              if (fx.participants[0].meta.location == "away"){
                fx.participants = [fx.participants[1], fx.participants[0]]
              }

              fx.periods.sort((a, b) => (b.sort_order - a.sort_order))
            })

            this.inplays.forEach((fx) => {
              let new_score = {goal: [], penalty: []}
            
              fx.scores.forEach((sc) => {
                if (sc.type_id == 1525) {
                  if (sc.score.participant == "home") {
                    new_score.goal[0] = sc.score.goals
                  } else {
                    new_score.goal[1] = sc.score.goals
                  }
                } else if (sc.type_id == 5) {
                  if (sc.score.participant == "home") {
                    new_score.penalty[0] = sc.score.goals
                  } else {
                    new_score.penalty[1] = sc.score.goals
                  }
                }
              })

              fx.scores = new_score
            })

            this.authorization = true
          }
          this.basicLoaded = true
        })
        .catch(error => {
          if (error.response.status == 401) {
            localStorage.removeItem('token')
            localStorage.removeItem('uid')
            location.reload()
          }
        })
      },

      /* event for pick favorite star on leagues */
      
      update_favorite(f, lg_id) {
        if (localStorage.token) {
          axios
              .post('/pick_favorite/', 
                {'league_id': lg_id, 'flag': f },
                { headers: { 'Authorization': 'Token ' + localStorage.token }}
                )
              .then((response) => {
                  localStorage.setItem('favorite_league', JSON.stringify(response.data.leagues))
                  this.favorite_league = response.data.leagues
              })
              .catch(error => {
                if (error.response.status == 401) {
                  localStorage.removeItem('token')
                  localStorage.removeItem('uid')
                  location.reload()
                }
              })
        } else {
          let arr = JSON.parse(localStorage.favorite_league || "[]")
          let ind = arr.indexOf(lg_id)

          if (ind < 0) {
            arr.push(lg_id)
          } else {
            arr.splice(ind, 1)
          }

          localStorage.setItem("favorite_league", JSON.stringify(arr))
          this.favorite_league = arr
        }
      },
  }
};
</script>
<style>  
</style>
