const pt = {
    search: 'Procurar',
    upcomings: 'Jogos',
    blog: 'Blog',
    subscription: 'Subscrição',
    setting: 'Configurações',
    login: 'Entrar',
    logout: 'Sair',
    contactus: 'Contacte-nos',
    copyright: 'Direitos de autor',
    rightreserve: 'Todos os direitos reservados.',
    livematch: 'Jogo ao vivo',
    topleague: 'Ligas Principais',
    team: 'Equipa',
    league: 'Liga',
    name: 'Nome',
    home: 'Casa',
    away: 'Fora',
    compare: 'Comparar',
    compareteam: 'Comparar Equipa',
    transfer: 'Transferências',
    enterteam: 'Inserir o nome da equipa',
    searchinupcoming: 'Pesquisar jogos',
    favoriteleagueonly: 'Ligas favoritas apenas',
    sortbytournament: 'Ordenar por torneio',
    sortbytime: 'Ordenar por horas',
    standings: 'Classificação',
    result: 'Resultado',
    topscorer: 'Melhor marcador',
    stats: 'Estatísticas',
    groupstage: 'Fase de Grupos',
    group: 'Grupo',
    season: 'Temporada',
    mp: {
        label: 'JD',
        tooltip: 'Jogos disputados'
    },
    w: {
        label: 'V',
        tooltip: 'Vitórias'
    },
    d: {
        label: 'E',
        tooltip: 'Empates'
    },
    l: {
        label: 'D',
        tooltip: 'Derrotas'
    },
    goalsfor: {
        label: 'Golos',
        tooltip: 'Golos Marcados : Golos Sofridos'
    },
    gd: {
        label: 'DG',
        tooltip: 'Diferença de Gols'
    },
    recent: {
        label: 'Recente',
        tooltip: 'Recente'
    },
    pts: {
        label: 'P',
        tooltip: 'Pontos'
    },
    m: {
        label: 'M',
        tooltip: 'Quantidade de jogos'
    },
    diff: {
        label: 'Diff',
        tooltip: 'Diferença total'
    },
    diff_av: {
        label: 'Diff_av',
        tooltip: 'Diferença média'
    },
    total_av: {
        label: 'Total',
        tooltip: 'Média total'
    },
    teamtotal_av: {
        label: '',
        tooltip: 'Média total da equipa'
    },
    opponenttotal_av: {
        label: 'Opponent',
        tooltip: 'Média total dos adversários'
    },
    diff_yc: {
        label: 'Diff_yc',
        tooltip: 'Diferença total (cartões amarelos)'
    },
    t_yc: {
        label: 'T_yc',
        tooltip: 'Média total (cartões amarelos)'
    },
    t1_yc: {
        label: 'T1_yc',
        tooltip: 'Média total da equipa (cartões amarelos)'
    },
    t2_yc: {
        label: 'T2_yc',
        tooltip: 'Média total dos adversários (cartões amarelos)'
    },
    diff_rc: {
        label: 'Diff_rc',
        tooltip: 'Diferença total (cartões vermelhos)'
    },
    t_rc: {
        label: 'T_rc',
        tooltip: 'Média total (Cartões vermelhos)'
    },
    t1_rc: {
        label: 'T1_rc',
        tooltip: 'Média total da equipa (cartões vermelhos)'
    },
    t2_rc: {
        label: 'T2_rc',
        tooltip: 'Média total dos adversários (cartões vermelhos)'
    },
    f: {
        label: 'F',
        tooltip: 'Falta'
    },
    q_yc: {
        label: 'Q_yc',
        tooltip: 'Quantidade total (cartões amarelos)'
    },
    q_rc: {
        label: 'Q_rc',
        tooltip: 'Quantidade total (cartões vermelhos)'
    },
    goal: 'Golo',
    assist: 'Assistir',
    yellowcard: 'Cartão Amarelo',
    redcard: 'Cartão Vermelho',
    player: 'Jogador',
    total: 'Total',
    message: {
        leaguenotactive: 'Esta liga não está ativa agora.',
        nostandings: 'Sem dados para classificação.',
        noupcomings: 'Sem dados para os próximos.',
        noresults: 'Sem dados para resultados.',
        noscorers: 'Sem dados para marcadores.',
        nostats: 'Sem dados para estatísticas.',
        nolatest: 'Não há dados para o mais recente.',
        nosquad: 'Não há dados para a equipa',
        norefereestats:'Não há dados para os árbitros',
        notransfers: 'Não há dados para transferências',
        notlogin: 'Desculpe, faça o login para ver estes dados. Obrigado.',
        notsubscribe: 'Desculpe, você não tem permissão para visualizar estes dados. Para aceder tem de se increver. Obrigado.',
        expiredsubscribe: 'Desculpe a sua subscrição expirou. Pague para ver estes dados. Obrigado.',
        successsave: 'Guardado com sucesso.',
        choosecountry: 'Aqui pode escolher um país para selecionar a liga.',
        nolineup: 'Não há dados para a formação.',
        noevent: 'Não há dados para o evento',
        nosidelined: 'Não há dados dos jogadores afastados',
        nofixture: 'Não há dados sobre o jogo em questão.',
        noleague: 'Não há dados sobre a liga.',
        noteam: 'Não há dados sobre a equipa.',
        noplayer: 'Não há dados sobre o jogador.',
        nocoach: 'Não há dados sobre o treinador.',
        noreferee: 'Não há dados sobre o árbitro.',
        nocountry: 'Não há dados sobre o país.',
        similar3way: 'Jogos similiares são baseados no mercado de resultado final do tempo regulamentar.',
        impossibleSM: ' Impossível ver jogos semelhantes devido à inexistência de um mercado de probabilidades triplas.',
        invalidusername: 'Este nome de utilizador é inválido.',
        invalidemail: 'Este e-mail é inválido.',
        contactsoon: 'Obrigado. Nós entraremos em contacto consigo brevemente.',
        errorsendingemail: 'Desculpe, ocorreu um erro ao enviar o e-mail.',
        message: 'Mensagem',
        invalidcredential: 'Credenciais inválidas.',
        pleasewait: 'Por favor aguarde.',
        invalidlink: 'Desculpe, este link está inválido de momento.',
        expiredlink: 'Desculpe, este link já expirou.',
        failedsave: 'Desculpe, falhou ao guardar. Tente novamente.',
        successsavepassword: 'Nova palavra-passe guardada com sucesso.',
        passwordnotmatch: 'As palavras-pass não coincidem.',
        invalidactivation: 'Desculpe, este url de ativação é inválido.',
        enteremail: 'Introduza o seu endereço de e-mail Foot-corner.com para que possamos enviar um link de reposição da palavra-passe.',
        validemail: 'Por favor, introduza um endereço de e-mail válido.',
        sentresetpasswordlink: 'Enviámos um link de reposição da palavra-passe para o seu e-mail. Por favor, verifique a sua caixa de correio.',
        typevalidemail: 'Por favor, introduza um endereço de e-mail válido.',
        emailexist: 'Este endereço de e-mail já existe.',
        usernameexist: 'Este nome de utilizador já existe.',
        successsignup: 'Registo bem sucedido.',
        checkemailverification: ' Por favor, verifique o seu e-mail para a verificação. Verifique o spam se não receber na caixa de entrada.',
        minlength5: 'No mínimo 5 caractéres',
        notuseemail: 'Não usar endereço de e-mail.'
    },
    country: 'País',
    loadmore: 'Carregue mais',
    played: 'Jogada',
    avg: 'média',
    moredetails: 'Mais detalhes',
    matchdetails: 'Detalhes da partida',
    stats_type: {
        CORNERS: 'Cantos',
        CAPTAIN: 'Capitão',
        SHOTS_OFF_TARGET: 'Remates fora',
        SHOTS_TOTAL: 'Remates totais',
        ATTACKS: 'Ataques',
        DANGEROUS_ATTACKS: 'Ataques perigosos',
        BALL_POSSESSION: 'Posse de bola',
        BALL_SAFE: 'Bola defendida',
        PENALTIES: 'Pénaltis',
        SHOTS_INSIDEBOX: 'Remates dentro da área',
        SHOTS_OUTSIDEBOX: 'Remates fora da área',
        OFFSIDES: 'Foras de jogo',
        GOALS: 'Golos',
        GOAL_KICKS: 'Pontapé de baliza',
        GOAL_ATTEMPTS: 'Tentativas de golo',
        FREE_KICKS: 'Pontapé livre',
        FOULS: 'Faltas',
        FOULS_COMMITTED: 'Faltas cometidas',
        FOULS_DRAWN: 'Faltas sofridas',
        SAVES: 'Defesas',
        SHOTS_BLOCKED: 'Remate cortado',
        SUBSTITUTIONS: 'Substituições',
        SUBSTITUTIONS_IN: 'Entrada do suplente',
        SUBSTITUTIONS_OUT: 'Saída do substituido ',
        THROWINS: 'Lançamentos',
        BEARTS: 'Finta',
        LONG_PASSES: 'Passes longos',
        SHORT_PASSES: 'Passes curtos',
        HIT_WOODWORK: 'Bola à trave',
        SUCCESSFUL_HEADERS: 'Cabeceamento em cheio',
        SUCCESSFUL_INTERCEPTIONS: 'Intercepções bem-sucedidas',
        HEADERS: 'Cabeceamentos',
        FIRST_SUBSTITUTION: 'Primeira substituição',
        GOALKEEPER_COME_OUTS: 'Saída do Guarda-redes',
        CHALLENGES: 'Desafios',
        TACKLES: 'Desarmes',
        ASSISTS: 'Assistências',
        PASSES: 'Passes',
        PASSES_TOTAL: 'Passes totais',
        PASSES_ACCURATE: 'Passes precisos',
        ACCURATE_PASSES_PERCENTAGE: 'Percentagem de passes precisos',
        SUCCESSFUL_PASSES: 'Passes bem sucedidos',
        SUCCESSFUL_PASSES_PERCENTAGE: 'Porcentagem de passes bem-sucedidos',
        REDCARDS: 'Cartões vermelhos',
        YELLOWCARDS: 'Cartões amarelos',
        YELLOWRED_CARDS: 'Cartões vermelhos e amarelos',
        SHOTS_ON_TARGET: 'Remates à baliza',
        INJURIES: 'Lesões',
        GOALS_CONCEDED: 'Golos concedidos',
        LAST_OFFSIDE: 'Último fora de jogo',
        FIRST_OFFSIDE: 'Primeiro fora de jogo',
        DISPOSSESSED: 'Desarmes',
        OFFSIDES_PROVOKED: 'Foras de jogo provocados',
        PLAYER_FOULS_DRAWN: 'Faltas sofridas',
        BLOCKED_SHOTS: 'Remate cortado',
        TOTAL_CROSSES: 'Total de cruzamentos',
        ACCURATE_CROSSES: 'Cruzamentos precisos',
        INTERCEPTIONS: 'Intercepções',
        CLEARANCES: 'Alívio',
        PUNCHES: 'Bola afastada com os punhos',
        SAVES_INSIDE_BOX: 'Desfesas dentro de área',
        TOTAL_DUELS: 'Total de duelos',
        DUELS_WON: 'Duelos vencidos',
        AERIALS_WON: 'Jogadas de cabeça ganhas',
        DRIBBLE_ATTEMPTS: 'Tentativas de drible',
        SUCCESSFUL_DRIBBLES: 'Dribles bem sucedidos',
        DRIBBLED_PAST: 'Ultrapassado em drible',
        PENALTIES_SCORED: 'Pênaltis marcados',
        PENALTIES_MISSES: 'Pénaltis falhados',
        PENALTIES_SAVED: 'Pénaltis defendidos',
        PENALTIES_COMMITTED: 'Pénaltis cometidos',
        PENALTIES_WON: 'Pénaltis ganhos',
        ACCURATE_PASSES: 'Passes precisos',
        KEY_PASSES: 'Passes chave',
        RATING: 'Avaliação',
        MINUTES_PLAYED: 'Minutos jogados',
        TOUCHES: 'Toques',
        TURN_OVER: 'Perda de posse de bola',
        LONG_BALLS: 'Bolas longas',
        LONG_BALLS_WON: 'Bolas longas ganhas',
        THROUGH_BALLS: 'Passes em profundidade',
        THROUGH_BALLS_WON: 'Passes em profundidad ganhas',
        MATCHES: 'Jogos da temporada',
        TOTAL_TEAMS: 'Equipas da temporada',
        MATCHES_ENDED_IN_DRAW: 'Jogos terminaram empatados',
        NUMBER_OF_GOALS: 'Número de golos',
        BTTS: 'Ambas as equipas marcam',
        CARDS: 'Cartões',
        CLEANSHEET: 'Jogo sem sofrer golos',
        GOALKEEPER_CLEANSHEET: 'Guarda redes sem sofrer golos',
        SCORING_MINUTES: 'Minutos do golo',
        GOAL_LINE: 'Linha de golo',
        WIN_PERCENTAGE: 'Percentagem de vitorias',
        DEFEAT_PERCENTAGE: 'Porcentagem de derrotas',
        DRAW_PERCENTAGE: 'Porcentagem de empates',
        PARTICIPANT_MOST_SCORED: 'Mais golos marcados',
        PARTICIPANT_MOST_CONCEDED: 'Mais golos sofridos',
        PARTICIPANT_MOST_SCORED_PER_MATCH: 'Mais golos marcados por jogo',
        PARTICIPANT_MOST_CONCEDED_PER_MATCH: 'Mais golos sofridos por jogo',
        GOAL_TOPSCORER: 'Melhor marcador',
        ASSIST_TOPSCORER: 'Jogador com mais assistências',
        CARD_TOPSCORER: 'Jogador com mais cartões',
        PENALTY_TOPSCORER: 'Marcador com mis pénaltis',
        MISSED_PENALTY_TOPSCORER: 'Jogador que mais falhou pénaltis',
        HIGHEST_RATED_PLAYER: 'Jogador com classificação mais alta',
        HIGHEST_RATED_TEAM: 'Equipa com classificação mais alta',
        CONCEDED_SCORING_MINUTES: 'Minutos de marcação concedidos',
        WIN: 'Vitórias em equipa',
        DRAW: 'Empates em equipa',
        LOST: 'Jogos perdidos em equipa',
        VAR_MOMENTS: 'Momentos Video árbitro (VAR)',
        APPEARANCES: 'Participações',
        LINEUPS: 'Onze incial',
        BENCH: 'Banco',
        OWN_GOALS: 'Auto-golo',
        ERROR_LEAD_TO_GOAL: 'Erro que leva a golo',
        REFEREES: 'Árbitros',
        COUNTER_ATTACKS: 'Contra-ataques',
        GOALKEEPER_GOALS_CONCEDED: 'Golos sofridos pelo guarda-redes',
        FAILED_TO_SCORE: 'Falha a marcar',
        SHOTS: 'Remates',
        BIG_CHANCES_MISSED: 'Grandes chances de falhar',
        GOALS_SCORED: 'Golos marcados',
        GOALS_CONCEDED: 'Golos concedidos',
        TOTAL_CORNERS: 'Total de Cantos',
        avg_goals_per_90: 'Média de golos/90 mins',
        avg_mins_per_goal: 'Média de minutos/Golo',
        avg_assists_per_90: 'Média de assistências/90 mins',
        avg_mins_per_assist: 'Média de minutos/Assistência',
        avg_owngoals_per_90: 'Média de autogolos/90 mins',
        avg_mins_per_owngoal: 'Média de minutos/Autogolo',
        avg_yellowcards_per_90: 'Média de cartões amarelos/90 mins',
        avg_mins_per_yellowcard: 'Média de minutos/Cartão amarelo',
        avg_redcards_per_90: 'Média de cartões vermelhos/90 mins',
        avg_mins_per_redcard: 'Média de minutos/Cartão vermelho',
        avg_goals_scored_per_game: 'Média de golos marcados por jogo',
        avg_goals_conceded_per_game: 'Média de golos concedidos por jogo',
        avg_corners: 'Média de cantos',
        avg_fouls_per_game: 'Média de faltas por jogo',
        avg_ball_possession: 'Média de posse de bola',
        avg_substitutions: 'Média de substituições',
        avg_yellowcards: 'Média de cartões amarelos',
        avg_yellowredcards: 'Média de cartões amarelos-vermelhos',
        avg_redcards: 'Média de cartões vermelhos',
    },
    basics: 'Príncipios básicos',
    squad: 'Esquadrão',
    goals: 'Golos',
    corners: 'Cantos',
    cards: 'Cartões',
    scorers: 'Marcadores',
    other: 'Outro',
    leaguesattended: 'Ligas disputadas',
    trophy: 'Troféu',
    winner: 'Vencedor',
    runnerup: 'Vice-campeão',
    coach: 'Treinador',
    fullname: 'Nome completo',
    commonname: 'Nome comum',
    birthdate: 'Data de nascimento',
    start: 'Início',
    end: 'Fim',
    venue: 'Local',
    surface: 'campo',
    city: 'Cidade',
    address: 'Endereço',
    capacity: 'Capacidade',
    founded: 'Fundado',
    rival: 'Rival',
    activeseasons: 'Temporadas ativas',
    sidelined: 'Ausências',
    injury: 'Lesão',
    from: 'De',
    to: 'Para',
    tournament: 'Torneio',
    lineup_type: {
        goalkeeper: 'Guarda redes',
        defender: 'Defesa',
        midfielder: 'meio-campo',
        attacker: 'Avançado'
    },
    // goalkeeper: 'Goleiro',
    height: 'Altura',
    weight: 'Peso',
    rowsperpage: 'Linhas por página',
    latest: 'Mais recente',
    preferredfoot: 'Pé preferido',
    penalty: 'Pénalti',
    close: 'Fechar',
    time: 'Tempo',
    fulltime: 'Tempo total do jogo',
    half1: 'Primeira parte',
    half2: 'Segunda parte',
    currentcoach: 'Treinador atual',
    previouscoach: 'Treinador anterior',
    countperpage: 'Contagem por página',
    previous: 'Anterior',
    next: 'Seguinte',
    date: 'Data',
    rd: 'RD',
    detail: 'Detalhes',
    referee: 'Árbitro',
    var: 'Video árbitro',
    type: 'Tipo',
    amount: 'Montante',
    general: 'Geral',
    favorite: 'Favoritas',
    timezone: 'Fuso horário',
    oddsformat: 'Formato das apostas',
    save: 'Guardar',
    lineup: 'Formação',
    event: 'Evento',
    comment: 'Comentário',
    weather: 'Tempo',
    humidity: 'Humidade',
    wind: 'Vento',
    jerseynumber: 'Número da camisola',
    yellowredcard: 'Cartão amarelo-vermelho',
    shootout: 'Cobrança de penáltis',
    penaltyscore: 'Resultado do Penálti',
    penaltygoal: 'Golo de penálti',
    scoreresult: 'Resultado final',
    owngoal: 'Auto-golo',
    relatedplayer: 'Jogador associado',
    missedpenalty: 'Penálti falhado',
    missedgames: 'Jogos perdidos',
    subscribe: {
        first: '1 mês',
        second: '3 meses',
        third: '1 ano',
        message1: 'Acesso total aos dados estatísticos',
        message2: 'Todas as funcionalidades do serviço estão disponíveis',
        message3: "Obrigado pela subscrição está inscrito em",
        plan: 'Plano mensal',
        expiredate: 'Data de expiração',
        nextbilling: 'Próxima data de pagamento',
        cancelbilling: 'Cancelar o próximo pagamento',
    },
    fixtures: 'Tabela de jogos',
    position: 'Posição',
    coach_type: {
        COACH: 'Treinador',
        ASSISTANT_COACH: 'Treinador assistente',
        GOALKEEPING_COACH: 'Treinador do guarda-redes',
        FORWARD_COACH: 'Treinador de avançados',
        CARETAKER_MANAGER: 'Treinador Temporário'
    },
    playertrophies: 'Troféus do jogador',
    transfer_type: {
        LOAN_TRANSFER: 'Empréstimo',
        TRANSFER: 'Transferência',
        FREE_TRANSFER: 'Transferência gratuíta'
    },
    logo: 'Logótipo',
    activeleague: 'Liga Ativa',
    inactiveleague: 'Liga Inativa',
    referee_type: {
        referee: 'Árbitro',
        assistant1: 'Primeiro assistente',
        assistant2: 'Segundo assistente',
        official4: 'Quarto árbitro'
    },
    head2head: 'Confronto direto',
    lastmatches: 'Últimos Jogos',
    similarmatches: 'Jogos similiares ',
    choosecategory: 'Escolhe a categoria',
    teammatches: 'Jogos de equipa',
    penalty_tooltip: 'Percentagem de jogos com pelo menos 1 penáltie',
    foul_tooltip: 'Média total de faltas no jogo',
    with: 'Com',
    all: 'Tudo',
    send: 'Enviar',
    notrobot: 'Não é um robot',
    username: 'Nome de utlizador',
    email: 'E-mail',
    thanks: 'Obrigado',
    odds: {
        oddsmarket: 'Odds',
        ft_result: 'Resultado final',
        cleansheet: 'Jogo sem sofrer golos',
        flts: 'Primeira/última equipa a marcar',
        asianhandicap: 'Handicap Asiático',
        t_ou: 'Total acima/abaixo',
        draw: 'Empate',
        ft: 'FT',
        half1: 'Primeira parte',
        half2: 'Segunda parte',
        btts: {
            label: 'AM',
            tooltip: 'Ambas as equipas marcam'
        },
        ftts: 'Primeira equipa a marcar',
        ltts: 'Última equipa a marcar',
        handicap: 'Handicap',
        teamcorner: 'Cantos da equipa',
        cmb: 'Aposta de cantos no jogo',
        atc: 'Total asiático de cartões',
        teamcard: 'Cartão de equipa',
        rc_in_match: 'Cartão vermelho no jogo',
    },
    yes: 'Sim',
    no: 'Não',
    nogoal: 'Sem golos',
    first: 'Primeiro',
    last: 'Último',
    more: 'Mais',
    over: 'Acima',
    under: 'Abaixo',
    welcome: 'Bem-vindo.',
    password: 'Palavra-passe',
    createaccount: 'Criar conta',
    forgotpassword: 'Esqueci palavra-passe',
    confirmpassword: 'Confirmar palavra-passe',
    confirm: 'Confirmar',
    resetpassword: 'Repor palavra-passe',
    back: 'Voltar',
    check: 'Verificar',
    signup: 'Registar',
    required: 'Necessário',
    continue: 'Continuar',
    affiliate: 'Afiliados',
    topclub: 'Clubes Principais',
    dayscoachchanged: 'Dias desde que mudou de treinador',
    changedcoach: 'Mudou de treinador há',
    daysago: 'dias',
    form: 'Forma',
    stadium: 'Estádio',
    checkduplicates: 'Verificar duplicados',
    teamform_label: 'Forma das Equipas nos Últimos Jogos',
    overall: 'Todos',
    alltournament: 'Todos os Torneios',
    tournamenttoplay: 'Torneio a jogar',
    home_away: 'Casa/Fora',
    username_or_email: 'Nome de utilizador ou email',
    weather_description: {
        sky_is_clear: 'O céu está limpo',
        clear_sky: 'Céu Limpo',
        few_clouds:	'Algumas nuvens',
        scattered_clouds: 'nuvens dispersas',
        broken_clouds: 'Nuvens partidas',
        shower_rain: 'Aguaceiros',
        rain: 'Chuva',
        thunderstorm: 'Trovoada',
        snow: 'Neve',
        mist: 'Nevoeiro',
        clouds: 'Nuvens',
        moderate_rain: 'Chuva Moderada',
        haze: 'Ar Poluido',
        light_intensity_shower_rain: 'Leves Aguaceiros',
        light_rain: 'Chuva Fraca',
        overcast_clouds: 'Céu Nublado',
        light_intensity_drizzle: 'Chuvisco de fraca intensidade',
        fog: 'Névoa',
        heavy_intensity_rain: 'Chuva intensa',
        thunderstorm_with_light_rain: 'Trovoada com chuva fraca',
        thunderstorm_with_rain: 'Trovoada com chuva',
        light_intensity_drizzle: 'Chuvisco de fraca intensidade',
        drizzle: 'Chuvisco',
        heavy_intensity_drizzle: 'Chuvisco de forte intensidade',
        light_intensity_drizzle_rain: 'Chuva Ligeira',
        drizzle_rain: 'Chuva Fraca',
        heavy_intensity_drizzle_rain: 'Chuva Forte',
        shower_rain_and_drizzle: 'Chuva e Chuvisco',
        heavy_shower_rain_and_drizzle: 'Chuva forte e chuvisco',
        shower_drizzle: 'Chuva',
        light_snow: 'Neve ligeira',
        snow: 'Neve',
        heavy_snow: 'Neve forte',
        sleet: 'Granizo',
        light_shower_sleet: 'Chuva fraca e granizo',
        shower_sleet: 'Chuva de granizo',
        light_rain_and_snow: 'Chuva fraca e neve',
        rain_and_snow: 'Chuva e neve',
        light_shower_snow: 'Chuvisco e neve',
        shower_snow: 'Chuva de neve',
        heavy_shower_snow: 'Forte Chuva de neve',
    },
}

export default pt