<template>
  <div>
    <p>{{ $t(message) }}</p>
  </div>
</template>
<script>
import axios from 'axios';
import config from '@/config'

axios.defaults.baseURL = config.API_URL;

export default {
  data() {
      return {
        uidb64: this.$route.query.uidb64,
        token: this.$route.query.token,
        message: 'message.pleasewait',
      }
  },
  mounted() {
      this.activate()
  },
  methods: {
      async activate() {
        axios
          .post('/activate/', {'uidb64': this.uidb64, 'token': this.token})
          .then((response) => {
            if (response.data.invalid == 1) {
              this.message = 'message.invalidactivation'
            }
            else {
              localStorage.token = response.data.token
              this.$router.replace('/')
            }
          })
      }
  }
};
</script>
<style>
</style>