<template id="signup-form">
    <form class="sign-up">
      <h5 class="green--text"><b>{{ $t('createaccount')}}</b></h5>

      <div class="form-group">
          <div class="d-flex mb-1">
            <span>{{ $t('email') }}</span>
            <span class="red--text ml-1">
              *
              <template v-if="email_msg">
                {{ $t(email_msg) }}
              </template>
            </span>
          </div>

          <div class="d-flex flex-column">
            <input
              v-model="email"
              :class="email_status ? 'border-danger' : null"
              type="text"
              placeholder="john@gmail.com"
              maxLength="50" />
          </div>
      </div>
      
      <div class="form-group">
          <div class="d-flex">
            <span>{{ $t('username') }}</span>
            <span class="red--text ml-1">
              *
              <template v-if="username_warning">{{ $t(username_warning) }}</template>
            </span>
          </div>
          
          <div>
            <input
              v-model="username"
              :class="username_status ? 'border-danger' : null"
              type="text"
              class="mr-3"
              maxLength="30" />

            <n-button
              @click.prevent="check_username()"
              :class="!check_btn_status ? 'btn-check' : 'transparent'"
              icon
              :title="$t('checkduplicates')"
              :disabled="!check_btn_status ? false : true"
              >
              <span v-if="!check_btn_status">{{ $t("check") }}</span>
              <span v-else v-html="check_btn_msg"></span>
            </n-button>
          </div>
      </div>

      <div class="form-group">
          <div class="d-flex mb-1">
            <span>{{ $t('password') }}</span>
            <span class="red--text ml-1">
              *
              <template v-if="pwd2_msg">{{ $t(pwd2_msg) }}</template>
            </span>
          </div>

          <input
            v-model="password1"
            :class="password_status ? 'border-danger' : null"
            :placeholder="$t('message.minlength5')"
            type="password"
            maxLength="30"
            />
      </div>

      <div class="form-group">
          <div class="d-flex mb-1">
            <span>{{ $t('confirmpassword') }}</span>
            <span class="red--text ml-1">*</span>
          </div>

          <input
            v-model="password2"
            :class="password_status ? 'border-danger' : null"
            type="password"
            maxLength="30"
            />
      </div>

      <div class="form-group">
        <country-select v-model="country" :usei18n="false" :class="country_status ? 'border-danger' : null" />
      </div>

      <div class="form-group">
          <v-btn
            @click.prevent="on_signup()"
            color="success">
            {{ $t('signup') }}
          </v-btn>
      </div>

      <v-btn
        @click="$router.go(-1)"
      >
        <v-icon>
          mdi-arrow-left
        </v-icon>
        {{ $t('back') }}
      </v-btn>
    </form>
</template>

<script>

import config from '@/config';
import axios from 'axios';
import { Button } from '@/components';

axios.defaults.baseURL = config.API_URL;

export default {
  name: 'signup-form',
  template: '#signup-form',
  data() {
    return {
      email: '',
      email_msg: null,
      email_status: 0,
      username: '',
      username_warning: '',
      username_status: 0,
      check_btn_msg: "check",
      check_btn_status: 0,
      password1: '',
      password2: '',
      pwd2_msg: null,
      password_status: 0,
      country: '',
      country_status: 0,
    }
  },
  components: {
    [Button.name]: Button,
  },
  watch: {
    email: function(value) {
      this.email_status = 0
      this.email_msg = null
    },
    username: function(value) {
      this.username_status = 0
      this.username_warning = null

      this.check_btn_msg = "check"
      this.check_btn_status = 0
    },
    password1: function(value) {
      this.password_status = 0
      this.pwd2_msg = null
    },
    password2: function(value) {
      this.password_status = 0
      this.pwd2_msg = null
    },

    country (val) {
      this.country_status = 0
    }
  },
  
  // METHODS
  methods: {
    check_valid_input() {
      this.valid_email()

      this.valid_username()

      this.valid_password()

      if (this.country == '') {
        this.country_status = 1
      }

      if (!this.email_status && !this.username_status && !this.password_status && this.country) {
        return true
      }else {
        return false
      }
    },
    
    valid_email() {
      if (this.email_status == 0) {
        if (this.email.length == 0) {
          this.email_status = 1
          this.email_msg = null
        } else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
          this.email_status = 0
          this.email_msg = null
        } else {
          this.email_status = 2
          this.email_msg = 'message.typevalidemail'
        }
      }
    },
    
    valid_username() {
      if (this.check_btn_status == 0) {
        if (this.username.length == 0) {
          this.username_status = 1
          this.username_warning = null
        } else if (this.username.length < 5) {
          this.username_status = 2
          this.username_warning = "message.minlength5"
        } else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.username)) {
          this.username_status = 4
          this.username_warning = "message.notuseemail"
        } else {
          this.username_status = 0
          this.username_warning = null
        }
      }
    },

    valid_password() {
      if (this.password_status == 0) {
        if (this.password1.length == 0 || this.password2.length == 0) {
          this.password_status = 1
          this.pwd2_msg = null
        } else if (this.password1.length < 5 || this.password2.length < 5) {
          this.password_status = 2
          this.pwd2_msg = 'message.minlength5'
        } else {
          if (this.password2 != this.password1) {
            this.password_status = 3
            this.pwd2_msg = 'message.passwordnotmatch'
          } else {
            this.password_status = 0
            this.pwd2_msg = null
          }
        }
      }
    },

    check_username() {
      this.valid_username()

      if (!this.username_status && !this.check_btn_status) {
        this.check_btn_msg = "<i class='now-ui-icons loader_refresh'></i>"
        axios
            .post('/check_username/', {'username': this.username})
            .then((response) => {
              if (response.data.status == 1) {
                this.check_btn_msg = "<i class='now-ui-icons ui-1_simple-remove red--text font-weight-bold'></i>"
                this.check_btn_status = 1
                this.username_warning = "message.usernameexist"
                this.username_status = 3
              } else {
                this.check_btn_status = 2
                this.check_btn_msg = "<i class='now-ui-icons ui-1_check green--text font-weight-bold'></i>"
              }

            })
            .catch((err) => {})
      }
    },

    on_signup() {
      if (this.check_valid_input()) {
        axios
            .post('/signup/', {'email': this.email, 'username': this.username, 'password': this.password1, 'country': this.country})
            .then((response) => {
                if (response.data.status == 1) {
                  this.email_status = 3
                  this.email_msg = "message.emailexist"
                } else if (response.data.status == 2) {
                  this.username_status = 3
                  this.username_warning = "message.usernameexist"
                  this.check_btn_status = 1
                  this.check_btn_msg = "<i class='now-ui-icons ui-1_simple-remove red--text font-weight-bold'></i>"
                } else {
                  this.email = ''
                  this.email_msg = null

                  this.username_warning = null

                  this.password1 = ''
                  this.password2 = ''
                  this.pwd2_msg = null
                  
                  this.country = null
                
                  this.$emit('change_comp', 'results')
                }
            })
            .catch((err) => {
            })
      }
    }, 
  }
};
</script>
<style></style>
