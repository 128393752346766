const config = require('./config.js').default;
export default function(username, tapClickId) {
  if (typeof paypal === 'undefined') {
    return false;
  }
  
  let plan = [{
    price: 22.99,
    month: 1
  },{
    price: 60,
    month: 3
  },{
    price: 230,
    month: 12
  }]

  paypal.Buttons({ 
    style: {
      shape: 'pill', 
      color: 'blue', 
      layout: 'vertical', 
      label: 'paypal', 
    }, createSubscription: function(data, actions) { 
      
    return actions.subscription.create({ 'plan_id': config.mode==='debug' ? 'P-9TK39818SA850241ML2TNTHY' : 'P-9GL90550TW274991KMVNTKOQ' });
  }, onApprove: function(data, actions) {
    if (tapClickId) {
      window.location.href = config.API_URL + '/paypal_checkout/' + username + '/' + data.subscriptionID + '?clickID=' + tapClickId + '&amount=' + plan[0].price;
    } else {
      window.location.href = config.API_URL + '/paypal_checkout/' + username + '/' + data.subscriptionID;
    }
  } }).render('#paypal-button-container-1');
  
  paypal.Buttons({ 
    style: {
      shape: 'pill', 
      color: 'blue', 
      layout: 'vertical', 
      label: 'paypal', 
    }, createSubscription: function(data, actions) { 
      
    return actions.subscription.create({ 'plan_id': config.mode==='debug' ? 'P-3TV16680E8290460BL2TU2JY' : 'P-8RY27685G2275825KL2TTJ4I' });
  }, onApprove: function(data, actions) {
    if (tapClickId) {
      window.location.href = config.API_URL + '/paypal_checkout/' + username + '/' + data.subscriptionID + '?clickID=' + tapClickId + '&amount=' + plan[1].price;
    } else {
      window.location.href = config.API_URL + '/paypal_checkout/' + username + '/' + data.subscriptionID;
    }
  } }).render('#paypal-button-container-3');
  
  paypal.Buttons({ 
    style: {
      shape: 'pill', 
      color: 'blue', 
      layout: 'vertical', 
      label: 'paypal', 
    }, createSubscription: function(data, actions) { 
      
    return actions.subscription.create({ 'plan_id': config.mode==='debug' ? 'P-2TW93413KT455472KL2TTB2A' : 'P-4UE167679A411220FL2TTKQA' });
  }, onApprove: function(data, actions) {
    if (tapClickId) {
      window.location.href = config.API_URL + '/paypal_checkout/' + username + '/' + data.subscriptionID + '?clickID=' + tapClickId + '&amount=' + plan[2].price;
    } else {
      window.location.href = config.API_URL + '/paypal_checkout/' + username + '/' + data.subscriptionID;
    }
  } }).render('#paypal-button-container-12');

  return true;
}