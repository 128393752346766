import Vue from 'vue';
import App from './App.vue';
// You can change this import to `import router from './starterRouter'` to quickly start development from a blank layout.
import router from './router';
import NowUiKit from './plugins/now-ui-kit';
import VueSession from 'vue-session'
import vueCountryRegionSelect from 'vue-country-region-select'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import colors from 'vuetify/lib/util/colors'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import VModal from 'vue-js-modal'
import VueMoment from "vue-moment"
import moment from "moment-timezone"
import VueI18n from 'vue-i18n'
import messages from './i18n'

Vue.config.productionTip = false;
Vue.use(NowUiKit)
Vue.use(Vuetify)
Vue.use(VModal)
// Vue.use(VueSession)
Vue.use(vueCountryRegionSelect)
Vue.use(VueMoment, {moment})
Vue.use(VueQuillEditor, /* { default global options } */)
Vue.use(VueI18n)

// var options = {
//   persist: true
// }

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: localStorage.language ? localStorage.language : 'en',
  fallbackLocale: 'en',
  // lazy:true,
  messages: messages,
  silentFallbackWarn: true,
  // silentTranslationWarn: false
});

const opts = {
  icons: {
    iconfont: 'mdi'// 'mdiSvg' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    themes: {
      dark: true,
    },
  },
}
// Vue.use(VueSession, options)

new Vue({
  router,
  vuetify: new Vuetify(opts),
  i18n,
  render: h => h(App)
}).$mount('#app');
