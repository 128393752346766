const en = {
    search: 'Search',
    upcomings: 'Upcomings',
    blog: 'Blog',
    subscription: 'Subscription',
    setting: 'Setting',
    login: 'Login',
    logout: 'Logout',
    contactus: 'Contact Us',
    copyright: 'Copyright',
    rightreserve: 'All rights reserved.',
    livematch: 'Live Match',
    topleague: 'Top Leagues',
    team: 'Team',
    league: 'League',
    name: 'Name',
    home: 'Home',
    away: 'Away',
    compare: 'Compare',
    compareteam: 'Compare Team',
    transfer: 'Transfer',
    enterteam: 'Enter team name',
    searchinupcoming: 'Search in upcomings',
    favoriteleagueonly: 'Favorite leagues only',
    sortbytournament: 'Sort by tournament',
    sortbytime: 'Sort by time',
    standings: 'Standings',
    result: 'Result',
    topscorer: 'Top Scorer',
    stats: 'Stats',
    groupstage: 'Group Stage',
    group: 'Group',
    season: 'Season',
    mp: {
        label: 'MP',
        tooltip: 'Match Played'
    },
    w: {
        label: 'W',
        tooltip: 'Won'
    },
    d: {
        label: 'D',
        tooltip: 'Drawn'
    },
    l: {
        label: 'L',
        tooltip: 'Lost'
    },
    goalsfor: {
        label: 'Goals',
        tooltip: 'Goals For : Goals Against'
    },
    gd: {
        label: 'GD',
        tooltip: 'Goal Difference'
    },
    recent: {
        label: 'Recent',
        tooltip: 'Recent'
    },
    pts: {
        label: 'P',
        tooltip: 'Points'
    },
    m: {
        label: 'M',
        tooltip: 'Matches quantity'
    },
    diff: {
        label: 'Diff',
        tooltip: 'Total difference'
    },
    diff_av: {
        label: 'Diff_av',
        tooltip: 'Average difference'
    },
    total_av: {
        label: 'Total',
        tooltip: 'Average total'
    },
    teamtotal_av: {
        label: '',
        tooltip: 'Average team total'
    },
    opponenttotal_av: {
        label: 'Opponent',
        tooltip: 'Average opponent total'
    },
    diff_yc: {
        label: 'Diff_yc',
        tooltip: 'Total difference (yellow cards)'
    },
    t_yc: {
        label: 'T_yc',
        tooltip: 'Average total (yellow cards)'
    },
    t1_yc: {
        label: 'T1_yc',
        tooltip: 'Average team total (yellow cards)'
    },
    t2_yc: {
        label: 'T2_yc',
        tooltip: 'Average opponent total (yellow cards)'
    },
    diff_rc: {
        label: 'Diff_rc',
        tooltip: 'Total difference (red cards)'
    },
    t_rc: {
        label: 'T_rc',
        tooltip: 'Average total (red cards)'
    },
    t1_rc: {
        label: 'T1_rc',
        tooltip: 'Average team total (red cards)'
    },
    t2_rc: {
        label: 'T2_rc',
        tooltip: 'Average opponent total (red cards)'
    },
    f: {
        label: 'F',
        tooltip: 'Foul'
    },
    q_yc: {
        label: 'Q_yc',
        tooltip: 'Total quantity (yellow cards)'
    },
    q_rc: {
        label: 'Q_rc',
        tooltip: 'Total quantity (red cards)'
    },
    goal: 'Goal',
    assist: 'Assist',
    yellowcard: 'YellowCard',
    redcard: 'RedCard',
    player: 'Player',
    total: 'Total',
    message: {
        leaguenotactive: 'This league is Not Active now.',
        nostandings: 'No Data for Standings.',
        noupcomings: 'No Data for Upcomings.',
        noresults: 'No Data for Results.',
        noscorers: 'No Data for Scorers.',
        nostats: 'No Data for Stats.',
        nolatest: 'No Data for Latest.',
        nosquad: 'No Data for Squad.',
        norefereestats:'No Stats for Referee.',
        notransfers: 'No Data for Transfers.',
        notlogin: 'Sorry, please login to see this data. Thanks.',
        notsubscribe: "Sorry, you don't have permission to view this data. Please subscribe for this. Thanks.",
        expiredsubscribe: 'Sorry, your subscription has expired. Please pay to view this data. Thanks.',
        successsave: 'Successfully saved.',
        choosecountry: 'Here you can choose a country to select league.',
        nolineup: 'No Data for Lineup.',
        noevent: 'No Data for Event.',
        nosidelined: 'No Data for Sidelined.',
        nofixture: 'No Data for Fixture.',
        noleague: 'No Data for League.',
        noteam: 'No Data for Team.',
        noplayer: 'No Data for Player.',
        nocoach: 'No Data for Coach.',
        noreferee: 'No Data for Referee.',
        nocountry: 'No Data for Country.',
        similar3way: 'Similar matches are based on FullTime-Result market',
        impossibleSM: 'Impossible to view similar matches due to no three-way odds market',
        invalidusername: 'This username is invalid.',
        invalidemail: 'This email is invalid.',
        contactsoon: 'Thank you. We will be in touch with you soon.',
        errorsendingemail: 'Sorry, an error occured while sending the email.',
        message: 'Message',
        invalidcredential: 'Invalid Credentials.',
        pleasewait: 'Please wait',
        invalidlink: 'Sorry, this link is invalid at the moment.',
        expiredlink: 'Sorry, this link is already expired.',
        failedsave: 'Sorry, failed to save. Try again later.',
        successsavepassword: 'Successfully saved a new password.',
        passwordnotmatch: 'Passwords do not match',
        invalidactivation: 'Sorry, it is an invalid activation url.',
        enteremail: 'Enter your Foot-corner.com email address so we can send a reset-password link.',
        validemail: 'Please enter a valid email address.',
        sentresetpasswordlink: 'We sent an reset-password link to your email. Please check on your mailbox.',
        typevalidemail: 'Please enter a valid email address.',
        emailexist: 'This email already exists.',
        usernameexist: 'This username already exists.',
        successsignup: 'SignUp Successfully',
        checkemailverification: 'Please check your email for the verification. Check spam if doesn’t received inbox.',
        minlength5: 'At least 5 characters',
        notuseemail: 'Do not use email address.'
    },
    country: 'Country',
    loadmore: 'Load more',
    played: 'played',
    avg: 'avg',
    moredetails: 'More details',
    matchdetails: 'Match details',
    stats_type: {
        CORNERS: 'Corners',
        CAPTAIN: 'Captain',
        SHOTS_OFF_TARGET: 'Shots off target',
        SHOTS_TOTAL: 'Shots total',
        ATTACKS: 'Attacks',
        DANGEROUS_ATTACKS: 'Dangerous attacks',
        BALL_POSSESSION: 'Ball possession',
        BALL_SAFE: 'Ball safe',
        PENALTIES: 'Penalties',
        SHOTS_INSIDEBOX: 'Shots insidebox',
        SHOTS_OUTSIDEBOX: 'Shots outsidebox',
        OFFSIDES: 'Offsides',
        GOALS: 'Goals',
        GOAL_KICKS: 'Goals kicks',
        GOAL_ATTEMPTS: 'Goal attempts',
        FREE_KICKS: 'Free kicks',
        FOULS: 'Fouls',
        FOULS_COMMITTED: 'Fouls-Committed',
        FOULS_DRAWN: 'Fouls-Drawn',
        SAVES: 'Saves',
        SHOTS_BLOCKED: 'Shots blocked',
        SUBSTITUTIONS: 'Substitutions',
        SUBSTITUTIONS_IN: 'Substitute-In',
        SUBSTITUTIONS_OUT: 'Substitute-Out',
        THROWINS: 'Throwins',
        BEARTS: 'Beats',
        LONG_PASSES: 'Long passes',
        SHORT_PASSES: 'Short passes',
        HIT_WOODWORK: 'Hit woodwork',
        SUCCESSFUL_HEADERS: 'Successful headers',
        SUCCESSFUL_INTERCEPTIONS: 'Successful interceptions',
        HEADERS: 'Headers',
        FIRST_SUBSTITUTION: 'First substitution',
        GOALKEEPER_COME_OUTS: 'Goalkeeper comes out',
        CHALLENGES: 'Challenges',
        TACKLES: 'Tackles',
        ASSISTS: 'Assists',
        PASSES: 'Passes',
        PASSES_TOTAL: 'Total passes',
        PASSES_ACCURATE: 'Accurate passes',
        ACCURATE_PASSES_PERCENTAGE: 'Accurate passes percentage',
        SUCCESSFUL_PASSES: 'Successful passes',
        SUCCESSFUL_PASSES_PERCENTAGE: 'Successful passes percentage',
        REDCARDS: 'Redcards',
        YELLOWCARDS: 'Yellowcards',
        YELLOWRED_CARDS: 'Yellowred cards',
        SHOTS_ON_TARGET: 'Shots on target',
        INJURIES: 'Injuries',
        GOALS_CONCEDED: 'Goals conceded',
        LAST_OFFSIDE: 'Last offside',
        FIRST_OFFSIDE: 'First offside',
        DISPOSSESSED: 'Dispossessed',
        OFFSIDES_PROVOKED: 'Offsides provoked',
        PLAYER_FOULS_DRAWN: 'Fouls drawn',
        BLOCKED_SHOTS: 'Blocked shots',
        TOTAL_CROSSES: 'Total crosses',
        ACCURATE_CROSSES: 'Accurate crosses',
        INTERCEPTIONS: 'Interceptions',
        CLEARANCES: 'Clearances',
        PUNCHES: 'Punches',
        SAVES_INSIDE_BOX: 'Saves insidebox',
        TOTAL_DUELS: 'Total duels',
        DUELS_WON: 'Duels won',
        AERIALS_WON: 'Aeriels won',
        DRIBBLE_ATTEMPTS: 'Dribble attempts',
        SUCCESSFUL_DRIBBLES: 'Successful dribbles',
        DRIBBLED_PAST: 'Dribbled past',
        PENALTIES_SCORED: 'Penalties scored',
        PENALTIES_MISSES: 'Penalties missed',
        PENALTIES_SAVED: 'Penalties saved',
        PENALTIES_COMMITTED: 'Penalties committed',
        PENALTIES_WON: 'Penalties won',
        ACCURATE_PASSES: 'Accurate passes',
        KEY_PASSES: 'Key passes',
        RATING: 'Rating',
        MINUTES_PLAYED: 'Minutes played',
        TOUCHES: 'Touches',
        TURN_OVER: 'Turn over',
        LONG_BALLS: 'Long balls',
        LONG_BALLS_WON: 'Long balls won',
        THROUGH_BALLS: 'Through balls',
        THROUGH_BALLS_WON: 'Through balls won',
        MATCHES: 'Season matches',
        TOTAL_TEAMS: 'Season teams',
        MATCHES_ENDED_IN_DRAW: 'Matches ended in draw',
        NUMBER_OF_GOALS: 'Number of goals',
        BTTS: 'Both teams to score',
        CARDS: 'Cards',
        CLEANSHEET: 'Cleansheets',
        GOALKEEPER_CLEANSHEET: 'Goalkeeper cleansheets',
        SCORING_MINUTES: 'Scoring minutes',
        GOAL_LINE: 'Goal line',
        WIN_PERCENTAGE: 'Win percentage',
        DEFEAT_PERCENTAGE: 'Defeat percentage',
        DRAW_PERCENTAGE: 'Draw percentage',
        PARTICIPANT_MOST_SCORED: 'Most scored',
        PARTICIPANT_MOST_CONCEDED: 'Most conceded',
        PARTICIPANT_MOST_SCORED_PER_MATCH: 'Most scored per match',
        PARTICIPANT_MOST_CONCEDED_PER_MATCH: 'Most conceded per match',
        GOAL_TOPSCORER: 'Goal topscorer',
        ASSIST_TOPSCORER: 'Assist topscorer',
        CARD_TOPSCORER: 'Card topscorer',
        PENALTY_TOPSCORER: 'Penalty topscorer',
        MISSED_PENALTY_TOPSCORER: 'Missed penalty topscorer',
        HIGHEST_RATED_PLAYER: 'Highest rated player',
        HIGHEST_RATED_TEAM: 'Highest rated team',
        CONCEDED_SCORING_MINUTES: 'Conceded scoring minutes',
        WIN: 'Team wins',
        DRAW: 'Team draws',
        LOST: 'Team lost',
        VAR_MOMENTS: 'Var moments',
        APPEARANCES: 'Appearances',
        LINEUPS: 'Lineups',
        BENCH: 'Bench',
        OWN_GOALS: 'Own goals',
        ERROR_LEAD_TO_GOAL: 'Error lead to goal',
        REFEREES: 'Referees',
        COUNTER_ATTACKS: 'Counter attacks',
        GOALKEEPER_GOALS_CONCEDED: 'Goalkeeper goals conceded',
        FAILED_TO_SCORE: 'Failed to score',
        SHOTS: 'Shots',
        BIG_CHANCES_MISSED: 'Big chances missed',
        GOALS_SCORED: 'Goals scored',
        GOALS_CONCEDED: 'Goals conceded',
        TOTAL_CORNERS: 'Total corners',
        avg_goals_per_90: 'Avg goals/90 mins',
        avg_mins_per_goal: 'Avg mins/Goal',
        avg_assists_per_90: 'Avg assists/90 mins',
        avg_mins_per_assist: 'Avg mins/Assist',
        avg_owngoals_per_90: 'Avg owngoals/90 mins',
        avg_mins_per_owngoal: 'Avg mins/Owngoal',
        avg_yellowcards_per_90: 'Avg yellowcards/90 mins',
        avg_mins_per_yellowcard: 'Avg mins/Yellowcard',
        avg_redcards_per_90: 'Avg redcards/90 mins',
        avg_mins_per_redcard: 'Avg mins/Redcard',
        avg_goals_scored_per_game: 'Avg goals scored per game',
        avg_goals_conceded_per_game: 'Avg goals conceded  per game',
        avg_corners: 'Avg corners',
        avg_fouls_per_game: 'Avg fouls per game',
        avg_ball_possession: 'Avg Ball Possession',
        avg_substitutions: 'Avg Substitutions',
        avg_yellowcards: 'Avg Yellowcards',
        avg_yellowredcards: 'Avg Yellowred cards',
        avg_redcards: 'Avg Redcards',
    },
    basics: 'Basics',
    squad: 'Squad',
    goals: 'Goals',
    corners: 'Corners',
    cards: 'Cards',
    scorers: 'Scorers',
    other: 'Other',
    leaguesattended: 'Leagues attended',
    trophy: 'Trophy',
    winner: 'Winner',
    runnerup: 'Runner-up',
    coach: 'Coach',
    fullname: 'Full name',
    commonname: 'Common name',
    birthdate: 'Birth date',
    start: 'Start',
    end: 'End',
    venue: 'Venue',
    surface: 'Surface',
    city: 'City',
    address: 'Address',
    capacity: 'Capacity',
    founded: 'Founded',
    rival: 'Rival',
    activeseasons: 'Active seasons',
    sidelined: 'Sidelined',
    injury: 'Injury',
    from: 'From',
    to: 'To',
    tournament: 'Tournament',
    lineup_type: {
        goalkeeper: 'Goalkeeper',
        defender: 'Defender',
        midfielder: 'Midfielder',
        attacker: 'Attacker'
    },
    // goalkeeper: 'Goalkeeper',
    height: 'Height',
    weight: 'Weight',
    rowsperpage: 'Rows per Page',
    latest: 'Latest',
    preferredfoot: 'Preferred foot',
    penalty: 'Penalty',
    close: 'Close',
    time: 'Time',
    fulltime: 'Full time',
    half1: '1 half',
    half2: '2 half',
    currentcoach: 'Current coach',
    previouscoach: 'Previous coach',
    countperpage: 'Count per page',
    previous: 'Previous',
    next: 'Next',
    date: 'Date',
    rd: 'Rd',
    detail: 'Detail',
    referee: 'Referee',    
    var: 'Video Assistant Referee',
    type: 'Type',
    amount: 'Amount',
    general: 'General',
    favorite: 'Favorite',
    timezone: 'Time zone',
    oddsformat: 'Odds format',
    save: 'Save',
    lineup: 'Lineup',
    event: 'Event',
    comment: 'Comment',
    weather: 'Weather',
    humidity: 'Humidity',
    wind: 'Wind',
    jerseynumber: 'Jersey number',
    yellowredcard: 'Yellow-Red card',
    shootout: 'Shootout',
    penaltyscore: 'Penalty score',
    penaltygoal: 'Penalty goal',
    scoreresult: 'Score result',
    owngoal: 'Own goal',
    relatedplayer: 'Related player',
    missedpenalty: 'Missed penalty',
    missedgames: 'Missed games',
    subscribe: {
        first: '1 Month',
        second: '3 Months',
        third: '1 Year',
        message1: 'Full access to the stats data',
        message2: 'All features of the service are available',
        message3: "Thanks for your subscription, you've subscribed to",
        plan: 'Month Plan',
        expiredate: 'Expiration date',
        nextbilling: 'Next billing date',
        cancelbilling: 'Cancel next billing',
    },
    fixtures: 'Fixtures',
    position: 'Position',
    coach_type: {
        COACH: 'Coach',
        ASSISTANT_COACH: 'Assistant Coach',
        GOALKEEPING_COACH: 'Goalkeeping Coach',
        FORWARD_COACH: 'Forward Coach',
        CARETAKER_MANAGER: 'Caretaker Manager'
    },
    playertrophies: 'Trophies of player',
    transfer_type: {
        LOAN_TRANSFER: 'Loan',
        TRANSFER: 'Transfer',
        FREE_TRANSFER: 'Free Transfer'
    },
    logo: 'Logo',
    activeleague: 'Active league',
    inactiveleague: 'Inactive league',
    referee_type: {
        referee: 'Referee',
        assistant1: '1st Assistant',
        assistant2: '2nd Assistant',
        official4: '4th Official'
    },
    head2head: 'Head-to-head',
    lastmatches: 'Last matches',
    similarmatches: 'Similar Matches',
    choosecategory: 'Choose category',
    teammatches: 'Team matches',
    penalty_tooltip: 'The percent of matches with at least 1 penalty',
    foul_tooltip: 'Average total of fouls in match',
    with: 'With',
    all: 'All',
    send: 'Send',
    notrobot: 'Not robot',
    username: 'Username',
    email: 'Email',
    thanks: 'Thanks',
    odds: {
        oddsmarket: 'Odds Market',
        ft_result: 'FullTime Results',
        cleansheet: 'Clean Sheet',
        flts: 'First/Last Team to Score',
        asianhandicap: 'Asian Handicap',
        t_ou: 'Total Over/Under',
        draw: 'Draw',
        ft: 'FT',
        half1: '1st Half',
        half2: '2nd Half',
        btts: {
            label: 'BTTS',
            tooltip: 'Both Teams To Score'
        },
        ftts: 'First Team to Score',
        ltts: 'Last Team to Score',
        handicap: 'Handicap',
        teamcorner: 'Team Corner',
        cmb: 'Corner Match Bet',
        atc: 'Asian Total Cards',
        teamcard: 'Team Card',
        rc_in_match: 'A Red Card in the Match',
    },
    yes: 'Yes',
    no: 'No',
    nogoal: 'No Goal',
    first: 'First',
    last: 'Last',
    more: 'More',
    over: 'Over',
    under: 'Under',
    welcome: 'Welcome',
    password: 'Password',
    createaccount: 'Create Account',
    forgotpassword: 'Forgot Password',
    confirmpassword: 'Confirm Password',
    confirm: 'Confirm',
    resetpassword: 'Reset Password',
    back: 'Back',
    check: 'Check',
    signup: 'Sign up',
    required: 'Required',
    continue: 'Continue',
    affiliate: 'Affiliate',
    topclub: 'Top Clubs',
    dayscoachchanged: 'Days coach changed',
    changedcoach: 'Changed the coach',
    daysago: 'days ago',
    form: 'Form',
    stadium: 'Stadium',
    checkduplicates: 'Check for duplicates',
    teamform_label: 'Teams Form Last Games',
    overall: 'Overall',
    alltournament: 'All tournaments',
    tournamenttoplay: 'Tournament to play',
    home_away: 'Home/Away',
    username_or_email: 'Username or email address',
    weather_description: {
        sky_is_clear: 'Sky is clear',
        clear_sky: 'Clear sky',
        few_clouds:	'Few clouds',
        scattered_clouds: 'Scattered clouds',
        broken_clouds: 'Broken clouds',
        shower_rain: 'Shower rain',
        rain: 'Rain',
        thunderstorm: 'Thunderstorm',
        snow: 'Snow',
        mist: 'Mist',
        clouds: 'Clouds',
        moderate_rain: 'Moderate rain',
        haze: 'Haze',
        light_intensity_shower_rain: 'Light intensity shower rain',
        light_rain: 'Light rain',
        overcast_clouds: 'Overcast clouds',
        light_intensity_drizzle: 'Light intensity drizzle',
        fog: 'Fog',
        heavy_intensity_rain: 'Heavy intensity rain',
        thunderstorm_with_light_rain: 'Thunderstorm with light rain',
        thunderstorm_with_rain: 'Thunderstorm with rain',
        light_intensity_drizzle: 'Light intensity drizzle',
        drizzle: 'Drizzle',
        heavy_intensity_drizzle: 'Heavy intensity drizzle',
        light_intensity_drizzle_rain: 'Light intensity drizzle rain',
        drizzle_rain: 'Drizzle rain',
        heavy_intensity_drizzle_rain: 'Heavy intensity drizzle rain',
        shower_rain_and_drizzle: 'Shower rain and drizzle',
        heavy_shower_rain_and_drizzle: 'Heavy shower rain and drizzle',
        shower_drizzle: 'Shower drizzle',
        light_snow: 'Light snow',
        snow: 'Snow',
        heavy_snow: 'Heavy snow',
        sleet: 'Sleet',
        light_shower_sleet: 'Light shower sleet',
        shower_sleet: 'Shower sleet',
        light_rain_and_snow: 'Light rain and snow',
        rain_and_snow: 'Rain and snow',
        light_shower_snow: 'Light shower snow',
        shower_snow: 'Shower snow',
        heavy_shower_snow: 'Heavy shower snow',
    },
}

export default en