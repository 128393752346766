<template>
  <v-app>
    <v-dialog
      v-if="coach"
      v-model="coachDialog"
      width="fit-content"
      style="z-index: 2000">
      <v-card>
        <div class="d-flex justify-center pt-3">
          <img v-if="coach.image_path" :src="coach.image_path" onerror="this.src='/img/default-avatar.png'">
        </div>

        <div class="mt-2 p-2">
          <template v-if="coach.common_name">
            {{ $t('commonname') }} : <b>{{ coach.common_name }}</b><br>
          </template>

          <template v-if="coach.name">
            {{ $t('name') }} : <b>{{ coach.name }}</b><br>
          </template>

          <template v-if="coach.date_of_birth">
            {{ $t('birthdate') }} : <b>{{ coach.date_of_birth }}</b><br>
          </template>

          <template v-if="coach.country">
            {{ $t('country') }} : <b>{{ coach.country.name }}</b><br>
          </template>
        </div>

        <div class="d-flex justify-center">
          <v-btn
            color="cyan"
            dark
            @click="coachDetail(coach.id)"
            class="m-2"
            small
          >
            {{ $t('moredetails') }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    
    <v-dialog
      v-model="refereeDialog"
      width="fit-content"
      style="z-index: 2000">
      <v-card>
        <template v-if="index4Referee != null && refereeStats[index4Referee]">
          <div class="d-flex justify-center pt-3">
            <img
              v-if="refereeStats[index4Referee].image_path"
              :src="refereeStats[index4Referee].image_path"
              class="wh-70"
              onerror="this.src='/img/default-avatar.png'">

            <div class="mt-2 p-2">
              <template v-if="refereeStats[index4Referee].name">
                {{ $t('name') }} : <b>{{ refereeStats[index4Referee].name }}</b><br>
              </template>

              <template v-if="refereeStats[index4Referee].date_of_birth">
                {{ $t('birthdate') }} : <b>{{ refereeStats[index4Referee].date_of_birth }}</b><br>
              </template>

              <template v-if="refereeStats[index4Referee].country">
                {{ $t('country') }} : <b>{{ refereeStats[index4Referee].country.name }}</b><br>
              </template>
            </div>
          </div>
          
          <div class="d-flex justify-center">
            <template v-if="refereeStats[index4Referee].statistics.length">
              <v-simple-table class="table-row-35 green-top-3">
                <thead>
                  <tr class="white">
                    <th style="border-right: 1px solid lightgrey">{{ $t('season') }}</th>
                    <th style="border-right: 1px solid lightgrey">{{ $t('league') }}</th>
                    <th class="text-center" style="border-right: 1px solid lightgrey" :title="$t('m.tooltip')">{{ $t('m.label') }}</th>
                    <th class="text-center" style="border-right: 1px solid lightgrey" :title="$t('f.tooltip')">{{ $t('f.label') }}</th>

                    <th class="text-center" style="border-right: 1px solid lightgrey" :title="$t('penalty')">
                      <img
                        src="/img/icons/penalty.png"
                        style="width: 15px"
                        title="Penalty">
                    </th>
                    
                    <th class="text-center" style="border-right: 1px solid lightgrey" :title="$t('yellowcard')">
                      <img
                        src="/img/icons/yellow_card.png"
                        title="Yellow Card">
                    </th>

                    <th class="text-center" style="border-right: 1px solid lightgrey" :title="$t('redcard')">
                      <img
                        src="/img/icons/red_card.png"
                        title="Direct Red Card">
                    </th>

                    <th class="text-center" style="border-right: 1px solid lightgrey" :title="$t('var')">
                      <v-icon color="red" class="subtitle-2">mdi-monitor-eye</v-icon>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="st in refereeStats[index4Referee].statistics"
                    :key="st.id"
                  >
                    <td style="border-right: 1px solid lightgrey">{{ st.season.name }}</td>
                    <td style="border-right: 1px solid lightgrey">{{ st.season.league.name }}</td>
                    
                    <td class="text-end" style="border-right: 1px solid lightgrey" :title="$t('m.tooltip')">
                      <template v-if="st.details.matches">{{ st.details.matches }}</template>
                    </td>

                    <td class="text-end" style="border-right: 1px solid lightgrey" :title="$t('f.tooltip')">
                      <template v-if="st.details.fouls">{{ st.details.fouls.count }}</template>
                    </td>

                    <td class="text-end" style="border-right: 1px solid lightgrey" :title="$t('penalty')">
                      <template v-if="st.details.penalties">
                        {{ st.details.penalties.count }}
                      </template>
                    </td>

                    <td class="text-end" style="border-right: 1px solid lightgrey" :title="$t('yellowcard')">
                      <template v-if="st.details.yellowcards">{{ st.details.yellowcards.count }}</template>
                    </td>

                    <td class="text-end" style="border-right: 1px solid lightgrey" :title="$t('redcard')">
                      <template v-if="st.details.redcards">{{ st.details.redcards.count }}</template>
                    </td>

                    <td class="text-end" style="border-right: 1px solid lightgrey" :title="$t('var')">
                      <template v-if="st.details.var_moments">{{ st.details.var_moments.count }}</template>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </template>

            <span v-else>{{ $t('message.norefereestats') }}</span>
          </div>
        </template>
        
        <div v-else class="ripple_loading wh-70">
          <img src="/img/icons/Ripple-1s-200px.png">
        </div>
      </v-card>     
    </v-dialog>

    <v-dialog
      v-if="basic && basic.venue"
      v-model="venueDialog"
      width="fit-content"
      style="z-index: 2000">
      <v-card>
        <img v-if="basic.venue.image_path" :src="basic.venue.image_path">
        
        <div class="mt-2 px-3">
          {{ $t('name') }} : <b>{{ basic.venue.name }}</b><br>
          {{ $t('surface') }} : <b>{{ basic.venue.surface }}</b><br>
          {{ $t('city') }} : <b>{{ basic.venue.city_name }}</b><br>
          {{ $t('address') }} : <b>{{ basic.venue.address }}</b><br>
          {{ $t('capacity') }} : <b>{{ basic.venue.capacity }}</b>
        </div>
      </v-card>
    </v-dialog>
    
    <v-dialog
      v-if="player"
      v-model="playerDialog"
      width="550"
      style="z-index: 2000">
      <v-card>
        <v-card-title class="d-flex flex-nowrap justify-space-between">
          <div class="d-grid">
            <span class="subtitle-1 green--text text--darken-2">{{ player.player_name ? player.player_name : player.player.name}}</span>

            <div class="subtitle-1 grey--text text--lighten-1">
              <template v-if="player.player.position_id">
                {{ $t(`lineup_type.${position[player.player.position_id - 24]}`) }}
              </template>
              
              <span
                v-if="$vuetify.breakpoint.xs && player.jersey_number"
                class="h5 font-weight-bold blue--text text--lighten-3">
                {{ player.jersey_number }}
              </span>

              <v-icon
                v-if="player.captain"
                class="ml-3 rounded-circle deep-orange white--text"
                title="Captain">
                mdi-copyright
              </v-icon>
            </div>
          </div>

          <div>
            <span
              v-if="$vuetify.breakpoint.smAndUp && player.jersey_number"
              class="h2 font-weight-bold blue--text text--lighten-3">
              {{ player.jersey_number }}
            </span>

            <img
              class="rounded-circle"
              :class="{'wh-70': $vuetify.breakpoint.xs, 'wh-100': $vuetify.breakpoint.smAndUp}"
              :src="player.player ? player.player.image_path : '/img/default-avatar.png'"
              onerror="this.src='/img/default-avatar.png'"
            >
          </div>
        </v-card-title>

        <v-divider class="ma-0"></v-divider>

        <v-card-text>
          <div v-if="player.player" class="row no-gutters justify-space-around caption">
            <v-col v-if="player.player.country" cols="auto" class="d-grid">
              <span class="grey--text text--darken-1">{{ $t('country') }}</span>
              <span class="green--text text--darken-2">{{ player.player.country.name }}</span>
            </v-col>

            <v-col cols="auto" class="d-grid">
              <span class="grey--text text--darken-1">{{ $t('height') }}/{{ $t('weight') }}</span>
              <span class="green--text text--darken-2">{{ player.player.height }}cm/{{ player.player.weight }}kg</span>
            </v-col>

            <v-col cols="auto" class="d-grid">
              <span class="grey--text text--darken-1">{{ $t('birthdate') }}</span>
              <span class="green--text text--darken-2">{{ player.player.date_of_birth }}</span>
            </v-col>
          </div>

          <v-simple-table v-if="player.stats" class="mt-1 table-row-35" height="200px">
            <tbody>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.MINUTES_PLAYED') }}:</td>
                <td class="text-right green--text">{{ player.stats.other.minutes_played }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.RATING') }}:</td>
                <td class="text-right title red--text text--darken-4">{{ player.stats.rating }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.GOALS') }}:</td>
                <td class="text-right green--text">{{ player.stats.goals.scored }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.avg_mins_per_goal') }}:</td>
                
                <td class="text-right green--text">
                  <template v-if="player.stats.goals.scored">
                    {{ (player.stats.other.minutes_played / player.stats.goals.scored).toFixed(0) }}
                  </template>
                </td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.ASSISTS') }}:</td>
                <td class="text-right green--text">{{ player.stats.goals.assists }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.avg_mins_per_assist') }}:</td>
                
                <td class="text-right green--text">
                  <template v-if="player.stats.goals.assists">
                    {{ (player.stats.other.minutes_played / player.stats.goals.assists).toFixed(0) }}
                  </template>
                </td>
              </tr>    

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.OWN_GOALS') }}:</td>
                <td class="text-right green--text">{{ player.stats.goals.owngoals }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.avg_mins_per_owngoal') }}:</td>
                
                <td class="text-right green--text">
                  <template v-if="player.stats.goals.owngoals">
                    {{ (player.stats.other.minutes_played / player.stats.goals.owngoals).toFixed(0) }}
                  </template>
                </td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.SHOTS_TOTAL') }}:</td>
                <td class="text-right green--text">{{ player.stats.shots.shots_total }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.SHOTS_ON_TARGET') }}:</td>
                <td class="text-right green--text">{{ player.stats.shots.shots_on_target }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.PASSES_TOTAL') }}:</td>
                <td class="text-right green--text">{{ player.stats.passing.passes }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.PASSES_ACCURATE') }}:</td>
                <td class="text-right green--text">{{ player.stats.passing.accurate_passes }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.ACCURATE_PASSES_PERCENTAGE') }}:</td>
                
                <td class="text-right green--text">{{ player.stats.passing.accurate_passes_percentage }}
                  <template v-if="player.stats.passing.accurate_passes_percentage">%</template>
                </td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.BIG_CHANCES_MISSED') }}:</td>
                <td class="text-right green--text">{{ player.stats.other.big_chances_missed }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.KEY_PASSES') }}:</td>
                <td class="text-right green--text">{{ player.stats.passing.key_passes }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.TOTAL_CROSSES') }}:</td>
                <td class="text-right green--text">{{ player.stats.passing.total_crosses }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.ACCURATE_CROSSES') }}:</td>
                <td class="text-right green--text">{{ player.stats.passing.accurate_crosses }}
                </td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.DRIBBLE_ATTEMPTS') }}:</td>
                <td class="text-right green--text">{{ player.stats.dribbles.attempts }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.SUCCESSFUL_DRIBBLES') }}:</td>
                <td class="text-right green--text">{{ player.stats.dribbles.success }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.DRIBBLED_PAST') }}:</td>
                <td class="text-right green--text">{{ player.stats.dribbles.dribbled_past }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.DISPOSSESSED') }}:</td>
                <td class="text-right green--text">{{ player.stats.other.dispossesed }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.INTERCEPTIONS') }}:</td>
                <td class="text-right green--text">{{ player.stats.other.interceptions }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.TACKLES') }}:</td>
                <td class="text-right green--text">{{ player.stats.other.tackles }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.BLOCKED_SHOTS') }}:</td>
                <td class="text-right green--text">{{ player.stats.other.blocked_shots }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.CLEARANCES') }}:</td>
                <td class="text-right green--text">{{ player.stats.other.clearances }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.SAVES') }}:</td>
                <td class="text-right green--text">{{ player.stats.other.saves }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.SAVES_INSIDE_BOX') }}:</td>
                <td class="text-right green--text">{{ player.stats.other.saves_insidebox }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.YELLOWCARDS') }}:</td>
                <td class="text-right green--text">{{ player.stats.cards.yellowcards + player.stats.cards.yellowredcards }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.avg_mins_per_yellowcard') }}:</td>
                
                <td class="text-right green--text">
                  <template v-if="player.stats.cards.yellowcards">
                    {{ (player.stats.other.minutes_played / (player.stats.cards.yellowcards + player.stats.cards.yellowredcards)).toFixed(0) }}
                  </template>
                </td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.YELLOWRED_CARDS') }}:</td>
                <td class="text-right green--text">{{ player.stats.cards.yellowredcards }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.REDCARDS') }}:</td>
                <td class="text-right green--text">{{ player.stats.cards.redcards }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.avg_mins_per_redcard') }}:</td>
                
                <td class="text-right green--text">
                  <template v-if="player.stats.cards.redcards">
                    {{ (player.stats.other.minutes_played / player.stats.cards.redcards).toFixed(0) }}
                  </template>
                </td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.FOULS_COMMITTED') }}:</td>
                <td class="text-right green--text">{{ player.stats.fouls.committed }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.FOULS_DRAWN') }}:</td>
                <td class="text-right green--text">{{ player.stats.fouls.drawn }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.TOTAL_DUELS') }}:</td>
                <td class="text-right green--text">{{ player.stats.duels.total }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.DUELS_WON') }}:</td>
                <td class="text-right green--text">{{ player.stats.duels.won }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.PENALTIES_WON') }}:</td>
                <td class="text-right green--text">{{ player.stats.other.pen_won }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.PENALTIES_SCORED') }}:</td>
                <td class="text-right green--text">{{ player.stats.other.pen_scored }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.PENALTIES_MISSES') }}:</td>
                <td class="text-right green--text">{{ player.stats.other.pen_missed }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.PENALTIES_COMMITTED') }}:</td>
                <td class="text-right green--text">{{ player.stats.other.pen_committed }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.PENALTIES_SAVED') }}:</td>
                <td class="text-right green--text">{{ player.stats.other.pen_saved }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.AERIALS_WON') }}:</td>
                <td class="text-right green--text">{{ player.stats.other.aerials_won }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.HIT_WOODWORK') }}:</td>
                <td class="text-right green--text">{{ player.stats.other.hit_woodwork }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.OFFSIDES') }}:</td>
                <td class="text-right green--text">{{ player.stats.other.offsides }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.PUNCHES') }}:</td>
                <td class="text-right green--text">{{ player.stats.other.punches }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="cyan"
            dark
            @click="playerDetail(player.player_id)"
            :small="$vuetify.breakpoint.xs"
          >
            {{ $t('moredetails') }}
          </v-btn>

          <v-btn
            color="warning"
            dark
            @click="playerDialog = false"
            :small="$vuetify.breakpoint.xs"
          >
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div>
      <template v-if="!basicLoaded">
        <div class="ripple_loading" style="padding-top: 80px;"><img src="/img/icons/Ripple-1s-200px.png"></div>
      </template>

      <template v-else>
        <div class="row no-gutters justify-center dark-banner pt-50 background-cover">
          <div class="col-lg-10 col-md-11">
            <div class="pt-2 d-flex justify-space-around">
              <div>
                <router-link :to="{name: 'team', params: {id: teams[0].id}}" class="d-flex flex-column align-center" :title="teams[0].name">
                  <img class="banner_logo1" :src="teams[0].image_path" :title="teams[0].name" />
                  
                  <span
                    class="text-center font-weight-bold white--text"
                    :class="{'caption': $vuetify.breakpoint.xs, 'subtitle-1': $vuetify.breakpoint.smAndUp}"
                    style="text-shadow: 0px 0px 10px white;">
                    {{ teams[0].name }}
                  </span>
                </router-link>
              </div>

              <div class="d-flex flex-column justify-center">
                <div
                  class="d-grid justify-center h2 font-weight-bold mb-2 pa-2 white--text"
                  style="background-color: rgba(255, 255, 255, .3); border-radius: 10px;">
                  <template v-if="basic.state_id == 1">
                    -
                  </template>

                  <template v-else>
                    <span class="text-center">
                      {{ basic.scores.goal[0] }} - {{ basic.scores.goal[1] }}
                    </span>
                    
                    <span v-if="basic.scores.penalty[0]" class="subtitle-1 text-center" title="Penalty Score">
                      ( {{ basic.scores.penalty[0] }} - {{ basic.scores.penalty[1] }} )
                    </span>
                    
                    <template v-if="basic.state_id != 5 && basic.state_id != 8">
                      <span
                        class="orange--text subtitle-1 font-weight-bold text-center"
                        :title="statusCode[basic.state_id - 1].name + ': ' + statusCode[basic.state_id - 1].info">
                        {{ statusCode[basic.state_id - 1].short_name }}
                      </span>
                    </template>
                  </template>
                </div>

                <div class="text-center">
                  <span class="subtitle-2 white--text">{{ basic.starting_at_timestamp | moment('DD/MM/YYYY, HH:mm') }}</span>
                </div>
              </div>

              <div>
                <router-link :to="{name: 'team', params: {id: teams[1].id}}" class="d-flex flex-column align-center" :title="teams[1].name">
                  <img class="banner_logo1" :src="teams[1].image_path" :title="teams[1].name" />
                  
                  <span
                    class="text-center font-weight-bold white--text"
                    :class="{'caption': $vuetify.breakpoint.xs, 'subtitle-1': $vuetify.breakpoint.smAndUp}"
                    style="text-shadow: 0px 0px 10px white;">
                    {{ teams[1].name }}
                  </span>
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="justify-center">
          <v-tabs
            v-model="tab"
            centered
            show-arrows
            light
          >
            <v-tab
              v-for="item in items"
              :key="item"
            >
              <span :class="{'caption': $vuetify.breakpoint.xs}">
                {{ $t(item) }}
              </span>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item key="Basic">
              <div class="row no-gutters justify-center mt-3">
                <div class="col-sm-8 col-md-6 col-lg-4 text-center">
                  <div>
                    <router-link :to="{name: 'league', params: {lid: basic.league_id}}">
                      <img class='league_logo' :src="basic.league.image_path" />
                    </router-link>
                  </div>

                  <div class="d-flex flex-column">
                    <span class="subtitle-2 red--text text--darken-4">
                      <router-link :to="{name: 'country', params: {cid: basic.league.country_id}}">
                        {{ basic.league.country.name }}
                      </router-link>
                      -
                      <router-link :to="{name: 'league', params: {lid: basic.league_id}}">{{ basic.league.name }}</router-link>
                      ( {{ basic.season.name }} )
                    </span>

                    <span class="subtitle-2 red--text text--darken-4" title="Stage">
                      {{ basic.stage.name }}
                    </span>
                  </div>

                  <div v-if="basic.venue" class="mt-4">
                    <span class="subtitle-2">
                      <a @click="venueModal()" title="Stadium">
                        {{ basic.venue.name }}<v-icon class="ml-2">mdi-stadium</v-icon>
                      </a>
                    </span>
                  </div>

                  <div v-if="basic.weatherreport" class="mt-4">
                    <div>
                      <span class="grey--text text--darken-1">{{ $t('weather') }}: </span>

                      <img
                        v-if="basic.weatherreport.icon"
                        :src="basic.weatherreport.icon"
                        class='mr-2'
                        style="width: 35px;"
                        :title="$t(`weather_description.${basic.weatherreport.description}`)" />

                      <template v-if="basic.weatherreport.temperature">
                        <span>{{ basic.weatherreport.temperature.evening }}</span>

                        <v-icon>mdi-temperature-celsius</v-icon>
                      </template>
                      
                    </div>

                    <div>
                      <span class="grey--text text--darken-1">{{ $t('humidity') }}: </span>
                      
                      <span v-if="basic.weatherreport.humidity" class="subtitle-1 red--text text--darken-4">
                        {{ basic.weatherreport.humidity }}
                      </span>
                    </div>
                    
                    <div>
                      <span class="grey--text text--darken-1">{{ $t('wind') }}: </span>

                      <span class="subtitle-1 red--text text--darken-4">
                        {{ basic.weatherreport.wind.speed }} m/s
                      </span>
                    </div>
                  </div>

                  <div class="mt-4">
                    <div v-for="(rf, ind) in basic.referees" :key="ind">
                      <span class="grey--text text--darken-1">{{ $t(`referee_type.${refereeType[rf.type_id - 6]}`) }}: </span>
                      <span
                        class="subtitle-1 red--text text--darken-4 curPointer"
                        style="text-decoration-line: underline;"
                        @click="refereeModal(ind, rf.referee_id)">
                        {{ rf.referee.common_name }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </v-tab-item>

            <v-tab-item key="Lineup">
              <template v-if="!tabLoaded" >
                <div class="ripple_loading" ><img src="/img/icons/Ripple-1s-200px.png"></div>
              </template>

              <div v-else class="mt-2 align-center">
                <div v-if="lineup && lineup.length" class="row no-gutters d-flex flex-wrap flex-md-nowrap justify-space-around">
                  <v-card
                    v-for="(item, ind) in formation"
                    :key="ind"
                    elevation="10"
                    class="mb-2 col-lg-6"
                    :class="{'mx-1': $vuetify.breakpoint.smAndUp}"
                  >
                    <v-app-bar
                      :color="colors[ind].bgcolor"
                      dark
                      dense
                      :height="$vuetify.breakpoint.xs ? 30 : 48"
                    >
                      <v-toolbar-title>
                        <span class="subtitle-1" :class="`${colors[ind].color}--text`">
                          {{ teams[ind].name }}
                        </span>
                      </v-toolbar-title>

                      <v-spacer></v-spacer>

                      <span :class="`subtitle-1 ${colors[ind].color}--text`">
                        {{ (ind) ? basic.formations.away : basic.formations.home }}
                      </span>
                    </v-app-bar>

                    <v-card-text class="d-flex flex-column align-center">
                      <a
                        v-if="ind ? basic.coaches[1] : basic.coaches[0]"
                        @click="coachModal(ind)">
                        <div class="my-2">
                          <span class="subtitle-1 grey--text text--darken-1">{{ $t('coach') }}: </span>
                          
                          <img
                            :src="ind ? (basic.coaches[1].image_path ? basic.coaches[1].image_path : '/img/default-avatar.png') : (basic.coaches[0].image_path ? basic.coaches[0].image_path : '/img/default-avatar.png')"
                            onerror="this.src='/img/default-avatar.png'"
                            :title="ind ? basic.coaches[1].name : basic.coaches[0].name"
                            class="rounded-circle"
                            style="width: 40px;" />
                          
                          <span>{{ ind ? basic.coaches[1].common_name : basic.coaches[0].common_name }}</span>
                        </div>
                      </a>
                      
                      <div v-if="formation_success" class="position-relative">
                        <img src="/img/pitch.png" style="opacity: 0.6"/>

                        <div
                          class="d-flex position-absolute"
                          :class="ind ? 'flex-row-reverse' : ''"
                          style="left: 0; right: 0; top: 0; bottom: 0; padding-left: 4%; padding-right: 4%"
                          >
                          <div
                            v-for="(row, x) in item"
                            :key="x"
                            class="d-flex justify-space-around"
                            :class="ind ? 'flex-column align-end' : 'flex-column-reverse align-start'"
                            style="z-index: 1; flex: 1 1 0"
                          >
                            <div
                              v-for="(col, y) in row"
                              :key="y"
                              class="position-relative"
                            >
                              <img
                                :src="(col.player && col.player.image_path) ? col.player.image_path : '/img/default-avatar.png'"
                                :title="col.player_name"
                                class="rounded-circle curPointer white"
                                :class="{'w-30': $vuetify.breakpoint.xs, 'wh-40': $vuetify.breakpoint.smAndUp}"
                                :style="col.captain ? 'border: 4px solid darkorange' : ''"
                                @click="playerModal(col)" />

                              <span
                                v-if="col.jersey_number"
                                class="position-absolute text-center font-weight-bold rounded-circle wh-15"
                                :class="`${colors[ind].color}--text`"
                                style="bottom: -5px; left: 0px; font-size: 10px; line-height: 15px;"
                                :style="`background-color: ${colors[ind].bgcolor}`"
                                :title="$t('jerseynumber')">
                                {{ col.jersey_number }}
                              </span>

                              <span
                                v-if="col.stats.rating"
                                class="position-absolute text-center font-weight-bold red--text text--darken-4" 
                                style="bottom: -10px;"
                                :style="$vuetify.breakpoint.xs ? 'left: 25px; font-size: 10px;': 'left: 35px; font-size: 15px;'"
                                :title="$t('stats_type.RATING')">
                                {{ col.stats.rating }}
                              </span>

                              <template v-if="col.stats.goals.scored">
                                <template v-if="col.stats.goals.scored != col.stats.other.pen_scored">
                                  <img
                                    class="position-absolute rounded-circle white"
                                    :style="$vuetify.breakpoint.xs ? 'top: -10px; right: -10px; width: 15px;': 'top: -13px; right: -10px; width: 20px;'"
                                    src="/img/icons/goal.png"
                                    :title="$t('goal')">

                                  <img v-if="col.stats.other.pen_scored"
                                    class="position-absolute"
                                    style="top: 8px; right: -21px;"
                                    :style="$vuetify.breakpoint.xs ? 'width: 15px;': 'width: 20px;'"
                                    src="/img/icons/penalty.png"
                                    :title="$t('penalty')">
                                </template>

                                <img v-else
                                  class="position-absolute"
                                  style="top: 8px; right: -21px;"
                                  :style="$vuetify.breakpoint.xs ? 'width: 15px;': 'width: 20px;'"
                                  src="/img/icons/penalty.png"
                                  :title="$t('penalty')">
                              </template>

                              <img
                                v-if="col.stats.cards.yellowcards"
                                class="position-absolute"
                                :style="$vuetify.breakpoint.xs ? 'top: -5px; left: -5px; width: 10px;': 'top: -10px; left: -5px; width: 12px;'"
                                src="/img/icons/yellow_card.png"
                                :title="$t('yellowcard')">

                              <img
                                v-if="col.stats.cards.redcards || col.stats.cards.yellowredcards"
                                class="position-absolute"
                                :style="$vuetify.breakpoint.xs ? 'top: -10px; left: 0px; width: 10px;': 'top: -15px; left: 5px; width: 12px;'"
                                src="/img/icons/red_card.png"
                                :title="col.stats.cards.redcards ? $t('redcard') : $t('yellowredcard')">
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-else class="col-sm-10 mt-3 d-flex flex-wrap">
                        <template v-for="(pl, i) in item">
                          <div
                            v-if="pl.team_id == teams[ind].id"
                            :key="i"
                            @click="playerModal(pl)"
                            class="col-sm-6 d-flex justify-space-between p-1 curPointer"
                            style="border-right: 1px solid lightgrey">
                            <div class="d-flex">
                              <img
                                :src="pl.player.image_path"
                                onerror="this.src='/img/default-avatar.png'"
                                class="rounded-circle curPointer white align-self-center wh-35"
                                :style="pl.captain ? 'border: 4px solid darkorange !important' : ''"
                                />

                              <span class="align-center blue-grey--text">{{ pl.player_name }}</span>
                            </div>

                            <template v-if="pl.stats.goals.scored">
                              <template v-if="pl.stats.goals.scored != pl.stats.other.pen_scored">
                                <img
                                  class="rounded-circle white align-self-center wh-15 ml-1"
                                  src="/img/icons/goal.png"
                                  :title="$t('goal')">
                                  
                                <img v-if="pl.stats.other.pen_scored"
                                  class="align-self-center wh-15 ml-1"
                                  src="/img/icons/penalty.png"
                                  :title="$t('penalty')">
                              </template>

                              <img v-else
                                class="align-self-center wh-15 ml-1"
                                src="/img/icons/penalty.png"
                                :title="$t('penalty')">
                            </template>
                            
                            <img
                              v-if="pl.stats.cards.yellowcards"
                              class="align-self-center ml-1"
                              style="width: 10px; height: 15px;"
                              src="/img/icons/yellow_card.png"
                              :title="$t('yellowcard')">

                            <img
                              v-if="pl.stats.cards.redcards || pl.stats.cards.yellowredcards"
                              class="align-self-center ml-1"
                              style="width: 10px; height: 15px;"
                              src="/img/icons/red_card.png"
                              :title="pl.stats.cards.redcards ? $t('redcard') : $t('yellowredcard')">

                            <span
                              v-if="pl.stats.rating"
                              class="font-weight-bold align-self-center red--text text--darken-4 ml-1"
                              style="font-size: 15px;"
                              :title="$t('stats_type.rating')">
                              {{ pl.stats.rating }}
                            </span>
                          </div>
                        </template>
                      </div>

                      <template v-if="substitution && substitution.length">
                        <span class="mt-5 subtitle-1 grey--text text--darken-1">{{ $t('stats_type.SUBSTITUTIONS') }}</span>
                        
                        <v-simple-table class="mt-1">
                          <tbody>
                            <template v-for="(sub, i) in substitution">
                              <tr v-if="sub.participant_id == teams[ind].id" :key="i">
                                <td class="white-space-nowrap">
                                  {{ sub.minute }}
                                  <span v-if="sub.extra_minute && sub.minute == 90"> + {{ sub.extra_minute }}</span>
                                  '
                                </td>

                                <td class="grey--text">
                                  <v-icon color="red">mdi-arrow-down-circle</v-icon> {{ sub.related_player_name }}
                                </td>

                                <td class="text-right blue-grey--text">
                                  {{ sub.player_name }} <v-icon color="green">mdi-arrow-up-circle</v-icon>
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </v-simple-table>
                      </template>

                      <template v-if="bench && bench.length">
                        <span class="mt-5 subtitle-1 grey--text text--darken-1">{{ $t('stats_type.BENCH') }}</span>
                        
                        <div class="col-sm-10 mt-1 d-flex flex-wrap">
                          <template v-for="(ben, i) in bench">
                            <div
                              v-if="ben.team_id == teams[ind].id"
                              :key="i"
                              @click="playerModal(ben)"
                              class="col-sm-6 d-flex justify-space-between p-1 curPointer"
                              style="border-right: 1px solid lightgrey">
                              <div class="d-flex">
                                <img
                                  :src="ben.player ? ben.player.image_path : '/img/default-avatar.png'"
                                  onerror="this.src='/img/default-avatar.png'"
                                  class="rounded-circle white align-self-center wh-35"
                                  :style="ben.captain ? 'border: 4px solid darkorange !important' : ''"
                                  />

                                <span class="align-center blue-grey--text">{{ ben.player_name }}</span>
                              </div>

                              <template v-if="ben.stats.goals.scored">
                                <template v-if="ben.stats.goals.scored != ben.stats.other.pen_scored">
                                  <img
                                    class="rounded-circle white align-self-center wh-15"
                                    src="/img/icons/goal.png"
                                    :title="$t('goal')">
                                    
                                  <img v-if="ben.stats.other.pen_scored"
                                    class="align-self-center wh-15"
                                    src="/img/icons/penalty.png"
                                    :title="$t('penalty')">
                                </template>

                                <img v-else
                                  class="align-self-center wh-15"
                                  src="/img/icons/penalty.png"
                                  :title="$t('penalty')">
                              </template>

                              <img
                                v-if="ben.stats.cards.yellowcards"
                                class="align-self-center"
                                style="width: 10px; height: 15px;"
                                src="/img/icons/yellow_card.png"
                                :title="$t('yellowcard')">

                              <img
                                v-if="ben.stats.cards.redcards || ben.stats.cards.yellowredcards"
                                class="align-self-center"
                                style="width: 10px; height: 15px;"
                                src="/img/icons/red_card.png"
                                :title="ben.stats.cards.redcards ? $t('redcard') : $t('yellowredcard')">

                              <span
                                v-if="ben.stats.rating"
                                class="font-weight-bold align-self-center red--text text--darken-4"
                                style="font-size: 15px;"
                                :title="$t('stats_type.RATING')">
                                {{ ben.stats.rating }}
                              </span>
                            </div>
                          </template>
                        </div>
                      </template>
                    </v-card-text>
                  </v-card>
                </div>

                <p v-else>{{ $t('message.nolineup') }}</p>
              </div>
            </v-tab-item>

            <v-tab-item key="Event">
              <template v-if="!tabLoaded" >
                <div class="ripple_loading" ><img src="/img/icons/Ripple-1s-200px.png"></div>
              </template>
              
              <div v-else class="row no-gutters justify-center mt-3">
                <div v-if="(event && event.length) || (shootout[0] && shootout[0].length)" class="col-lg-6 col-md-8 col-sm-10">
                  <div v-if="shootout[0] && shootout[0].length" class="d-flex flex-column text-center">
                    <span class="mt-3 subtitle-1 grey--text text--darken-1">{{ $t('shootout') }}</span>

                    <div class="d-flex justify-center mb-10 elevation-2 grey lighten-4">
                      <div class="d-flex flex-wrap justify-center col-5">
                        <span
                          v-for="(evt, ind) in shootout[0]"
                          :key="`0-${ind}`"
                          class="rounded-circle curPointer white--text ma-1"
                          :class="(evt.type_id == 23) ? 'green' : 'red'"
                          :style="$vuetify.breakpoint.xs ? 'width: 20px; height: 20px; padding: 0px 1px;' : 'width: 30px; height: 30px; padding: 4px;'"
                          :title="evt.player_name"
                          >
                          {{ ind + 1 }}
                        </span>
                      </div>

                      <span
                        class="px-3 my-3 subtitle-1 align-center blue white--text white-space-nowrap"
                        :title="$t('penaltyscore')"
                        style="border-radius: 2px;">
                        {{ basic.scores.penalty[0] }} : {{ basic.scores.penalty[1] }}
                      </span>

                      <div class="d-flex flex-wrap justify-center col-5">
                        <span
                          v-for="(evt, ind) in shootout[1]"
                          :key="`0-${ind}`"
                          class="rounded-circle curPointer white--text ma-1"
                          :class="(evt.type_id == 23) ? 'green' : 'red'"
                          :style="$vuetify.breakpoint.xs ? 'width: 20px; height: 20px; padding: 0px 1px;' : 'width: 30px; height: 30px; padding: 4px;'"
                          :title="evt.player_name"
                          >
                          {{ ind + 1 }}
                        </span>
                      </div>
                    </div>

                    <!-- <span class="mt-10 subtitle-1 grey--text text--darken-1">Events</span> -->
                  </div>

                  <v-simple-table v-if="event && event.length" class="table-row-40 elevation-2">
                    <thead class="grey--text text--darken-2">
                      <th>{{ $t('time') }}</th>
                      
                      <th>
                        <div class="d-flex justify-space-between">
                          <span class="mr-3">{{ teams[0].name }}</span>
                          <span>{{ teams[1].name }}</span>
                        </div>
                      </th>
                    </thead>

                    <tbody>
                      <tr v-for="(evt, i) in event" :key="i">
                        <template v-if="evt.type_id != 22 && evt.type_id != 23">
                          <td class="grey--text text--darken-2 white-space-nowrap">
                            {{ evt.minute }}
                            <span v-if="evt.extra_minute">+{{ evt.extra_minute }}</span>
                            '
                          </td>

                          <td>
                            <div
                              class="d-flex justify-start align-center"
                              :class="(evt.participant_id == teams[0].id) ? '' : 'flex-row-reverse'">
                              <template v-if="evt.type_id == 19">
                                <img
                                  class="w-15"
                                  src="/img/icons/yellow_card.png">

                                <span class="mx-2 blue-grey--text">{{ evt.player_name }}</span>
                              </template>

                              <template v-else-if="evt.type_id == 18">
                                <v-icon color="green">mdi-arrow-up-circle</v-icon>

                                <span class="mx-2 blue-grey--text align-center">{{ evt.player_name }}</span>

                                <v-icon color="red" class="text--lighten-2">mdi-arrow-down-circle</v-icon>

                                <span class="mx-2 grey--text align-center">{{ evt.related_player_name }}</span>
                              </template>

                              <template v-else-if="evt.type_id == 14">
                                <img
                                  class="wh-20"
                                  src="/img/icons/goal.png"
                                  :title="$t('goal')">
                                
                                <span class="mx-2 blue-grey--text">{{ evt.player_name }}</span>

                                <template v-if="evt.related_player_name && !$vuetify.breakpoint.xs">
                                  <v-icon color="grey">mdi-minus</v-icon>

                                  <span class="mx-2 grey--text" :title="$t('assist')">{{ evt.related_player_name }}</span>
                                </template>

                                <span v-if="evt.result" :title="$t('scoreresult')" class="blue white--text subtitle-2 px-3 white-space-no-wrap" style="border-radius: 2px;">{{ evt.result }}</span>
                              </template>

                              <template v-else-if="evt.type_id == 15">
                                <img
                                  class="wh-20"
                                  src="/img/icons/goal.png"
                                  :title="$t('goal')">
                                
                                <span class="mx-2 blue-grey--text">{{ evt.player_name }}</span>
                                
                                <span class="px-2 red lighten-2 white--text" style="border-radius: 2px">{{ $t('owngoal') }}</span>

                                <template v-if="evt.related_player_name && !$vuetify.breakpoint.xs">
                                  <v-icon color="grey">mdi-minus</v-icon>

                                  <span class="mx-2 grey--text" :title="$t('relatedplayer')">{{ evt.related_player_name }}</span>
                                </template>

                                <span v-if="evt.result" :title="$t('scoreresult')" class="blue white--text subtitle-2 mx-1 px-3 white-space-nowrap" style="border-radius: 2px;">{{ evt.result }}</span>
                              </template>

                              <template v-else-if="evt.type_id == 16">
                                <img
                                  class="wh-20"
                                  src="/img/icons/penalty.png"
                                  :title="$t('penaltygoal')">

                                <span class="mx-2 blue-grey--text">{{ evt.player_name }}</span>

                                <span v-if="evt.result" :title="$t('scoreresult')" class="blue white--text subtitle-2 px-3" style="border-radius: 2px">{{ evt.result }}</span>
                              </template>

                              <template v-else-if="evt.type_id == 17">
                                <div>
                                  <img
                                    class="wh-20"
                                    src="/img/icons/penalty.png"
                                    :title="$t('missedpenalty')">

                                  <v-icon color="red">mdi-close</v-icon>
                                </div>

                                <span class="mx-2 blue-grey--text">{{ evt.player_name }}</span>
                              </template>

                              <template v-else-if="evt.type_id == 21">
                                <div>
                                  <img
                                    class="w-15 mr-1"
                                    src="/img/icons/yellow_card.png">

                                  <img
                                    class="w-15"
                                    src="/img/icons/red_card.png"
                                    :title="$t('yellowredcard')">
                                </div>

                                <span class="mx-2 blue-grey--text">{{ evt.player_name }}</span>
                              </template>

                              <template v-else-if="evt.type_id == 20">
                                <img
                                  class="w-15"
                                  src="/img/icons/red_card.png">

                                <span class="mx-2 blue-grey--text">{{ evt.player_name }}</span>
                              </template>

                              <template v-else-if="evt.type_id == 10">
                                <v-icon color="red" :title="$t('var')">mdi-monitor-eye</v-icon>

                                <span v-if="evt.addition" class="mx-2 px-2 red lighten-2 white--text" style="border-radius: 2px" :title="evt.info">{{ evt.addition }}</span>

                                <span class="mx-2 blue-grey--text">{{ evt.player_name }}</span>
                              </template>
                            </div>
                          </td>
                        </template>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </div>

                <p v-else>{{ $t('message.noevent') }}</p>
              </div>
            </v-tab-item>

            <!-- <v-tab-item key="Comment">
              <template v-if="!tabLoaded" >
                <div class="ripple_loading" ><img src="/img/icons/Ripple-1s-200px.png"></div>
              </template>
              
              <div v-else class="row no-gutters justify-center mt-3">
                <div v-if="comment && comment.length" class="col-lg-8 col-md-10">
                  <v-simple-table class="table-row-40 elevation-2">
                    <tbody>
                      <tr v-for="(cmt, i) in comment" :key="i">
                        <td class="grey--text text--darken-2 white-space-nowrap">
                          {{ cmt.minute }}
                          <span v-if="cmt.extra_minute">+{{ cmt.extra_minute }}'</span>
                          '
                        </td>

                        <td>
                          <img
                            v-if="cmt.is_goal"
                            class="wh-20"
                            src="/img/icons/goal.png"
                            title="Goal">
                          
                          <span class="mx-2 blue-grey--text">{{ cmt.comment }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </div>

                <p v-else>No Data for Comment.</p>
              </div>
            </v-tab-item> -->

            <v-tab-item key="Stats">
              <template v-if="!tabLoaded" >
                <div class="ripple_loading" ><img src="/img/icons/Ripple-1s-200px.png"></div>
              </template>
              
              <div v-else class="row no-gutters justify-center">
                <div v-if="stats && Object.keys(stats).length !== 0" class="col-sm-11 col-lg-6 col-md-8">
                  <div class="d-flex flex-column" :class="$vuetify.breakpoint.xs ? 'caption' : 'subtitle-1'">
                    <v-progress-linear
                      v-if="stats.BALL_POSSESSION != []"
                      :value="stats.BALL_POSSESSION[0]"
                      class="my-2 elevation-5"
                      background-color="pink"
                      color="green"
                      height="30">
                      <span class="d-flex justify-space-between mx-2" style="width: 100%;">
                        <span class="white--text">{{ stats.BALL_POSSESSION[0] }} %</span>
                        <span class="white--text">{{ $t('stats_type.BALL_POSSESSION') }}</span>
                        <span class="white--text">{{ stats.BALL_POSSESSION[1] }} %</span>
                      </span>
                    </v-progress-linear>

                    <v-progress-linear
                      v-if="stats.SHOTS_ON_TARGET != []"
                      :value="stats.SHOTS_ON_TARGET[0] * 100 / (stats.SHOTS_ON_TARGET[0] + stats.SHOTS_ON_TARGET[1])"
                      class="my-2 elevation-5"
                      :background-color="(stats.SHOTS_ON_TARGET[0] == 0 && stats.SHOTS_ON_TARGET[1] == 0) ? 'grey' : 'pink'"
                      color="green"
                      height="30">
                      <span class="d-flex justify-space-between mx-2" style="width: 100%;">
                        <span class="white--text">{{ stats.SHOTS_ON_TARGET[0] }}</span>
                        <span class="white--text">{{ $t('stats_type.SHOTS_ON_TARGET') }}</span>
                        <span class="white--text">{{ stats.SHOTS_ON_TARGET[1] }}</span>
                      </span>
                    </v-progress-linear>

                    <v-progress-linear
                      v-if="stats.SHOTS_TOTAL != []"
                      :value="stats.SHOTS_TOTAL[0] * 100 / (stats.SHOTS_TOTAL[0] + stats.SHOTS_TOTAL[1])"
                      class="my-2 elevation-5"
                      :background-color="(stats.SHOTS_TOTAL[0] == 0 && stats.SHOTS_TOTAL[1] == 0) ? 'grey' : 'pink'"
                      color="green"
                      height="30">
                      <span class="d-flex justify-space-between mx-2" style="width: 100%;">
                        <span class="white--text">{{ stats.SHOTS_TOTAL[0] }}</span>
                        <span class="white--text">{{ $t('stats_type.SHOTS_TOTAL') }}</span>
                        <span class="white--text">{{ stats.SHOTS_TOTAL[1] }}</span>
                      </span>
                    </v-progress-linear>

                    <v-progress-linear
                      v-if="stats.GOAL_ATTEMPTS != []"
                      :value="stats.GOAL_ATTEMPTS[0] * 100 / (stats.GOAL_ATTEMPTS[0] + stats.GOAL_ATTEMPTS[1])"
                      class="my-2 elevation-5"
                      :background-color="(stats.GOAL_ATTEMPTS[0] == 0 && stats.GOAL_ATTEMPTS[1] == 0) ? 'grey' : 'pink'"
                      color="green"
                      height="30">
                      <span class="d-flex justify-space-between mx-2" style="width: 100%;">
                        <span class="white--text">{{ stats.GOAL_ATTEMPTS[0] }}</span>
                        <span class="white--text">{{ $t('stats_type.GOAL_ATTEMPTS') }}</span>
                        <span class="white--text">{{ stats.GOAL_ATTEMPTS[1] }}</span>
                      </span>
                    </v-progress-linear>

                    <v-progress-linear
                      v-if="stats.SAVES != []"
                      :value="stats.SAVES[0] * 100 / (stats.SAVES[0] + stats.SAVES[1])"
                      class="my-2 elevation-5"
                      :background-color="(stats.SAVES[0] == 0 && stats.SAVES[1] == 0) ? 'grey' : 'pink'"
                      color="green"
                      height="30">
                      <span class="d-flex justify-space-between mx-2" style="width: 100%;">
                        <span class="white--text">{{ stats.SAVES[0] }}</span>
                        <span class="white--text">{{ $t('stats_type.SAVES') }}</span>
                        <span class="white--text">{{ stats.SAVES[1] }}</span>
                      </span>
                    </v-progress-linear>

                    <v-progress-linear
                      v-if="stats.CORNERS != []"
                      :value="stats.CORNERS[0] * 100 / (stats.CORNERS[0] + stats.CORNERS[1])"
                      class="my-2 elevation-5"
                      :background-color="(stats.CORNERS[0] == 0 && stats.CORNERS[1] == 0) ? 'grey' : 'pink'"
                      color="green"
                      height="30">
                      <span class="d-flex justify-space-between mx-2" style="width: 100%;">
                        <span class="white--text">{{ stats.CORNERS[0] }}</span>
                        <span class="white--text">{{ $t('stats_type.CORNERS') }}</span>
                        <span class="white--text">{{ stats.CORNERS[1] }}</span>
                      </span>
                    </v-progress-linear>

                    <v-progress-linear
                      v-if="stats.FREE_KICKS != []"
                      :value="stats.FREE_KICKS[0] * 100 / (stats.FREE_KICKS[0] + stats.FREE_KICKS[1])"
                      class="my-2 elevation-5"
                      :background-color="(stats.FREE_KICKS[0] == 0 && stats.FREE_KICKS[1] == 0) ? 'grey' : 'pink'"
                      color="green"
                      height="30">
                      <span class="d-flex justify-space-between mx-2" style="width: 100%;">
                        <span class="white--text">{{ stats.FREE_KICKS[0] }}</span>
                        <span class="white--text">{{ $t('stats_type.FREE_KICKS') }}</span>
                        <span class="white--text">{{ stats.FREE_KICKS[1] }}</span>
                      </span>
                    </v-progress-linear>

                    <v-progress-linear
                      v-if="stats.ATTACKS != []"
                      :value="stats.ATTACKS[0] * 100 / (stats.ATTACKS[0] + stats.ATTACKS[1])"
                      class="my-2 elevation-5"
                      :background-color="(stats.ATTACKS[0] == 0 && stats.ATTACKS[1] == 0) ? 'grey' : 'pink'"
                      color="green"
                      height="30">
                      <span class="d-flex justify-space-between mx-2" style="width: 100%;">
                        <span class="white--text">{{ stats.ATTACKS[0] }}</span>
                        <span class="white--text">{{ $t('stats_type.ATTACKS') }}</span>
                        <span class="white--text">{{ stats.ATTACKS[1] }}</span>
                      </span>
                    </v-progress-linear>

                    <v-progress-linear
                      v-if="stats.DANGEROUS_ATTACKS != []"
                      :value="stats.DANGEROUS_ATTACKS[0] * 100 / (stats.DANGEROUS_ATTACKS[0] + stats.DANGEROUS_ATTACKS[1])"
                      class="my-2 elevation-5"
                      :background-color="(stats.DANGEROUS_ATTACKS[0] == 0 && stats.DANGEROUS_ATTACKS[1] == 0) ? 'grey' : 'pink'"
                      color="green"
                      height="30">
                      <span class="d-flex justify-space-between mx-2" style="width: 100%;">
                        <span class="white--text">{{ stats.DANGEROUS_ATTACKS[0] }}</span>
                        <span class="white--text">{{ $t('stats_type.DANGEROUS_ATTACKS') }}</span>
                        <span class="white--text">{{ stats.DANGEROUS_ATTACKS[1] }}</span>
                      </span>
                    </v-progress-linear>

                    <v-progress-linear
                      v-if="stats.PASSES != []"
                      :value="stats.PASSES[0] * 100 / (stats.PASSES[0] + stats.PASSES[1])"
                      class="my-2 elevation-5"
                      :background-color="(stats.PASSES[0] == 0 && stats.PASSES[1] == 0) ? 'grey' : 'pink'"
                      color="green"
                      height="30">
                      <span class="d-flex justify-space-between mx-2" style="width: 100%;">
                        <span class="white--text">{{ stats.PASSES[0] }}</span>
                        <span class="white--text">{{ $t('stats_type.PASSES_TOTAL') }}</span>
                        <span class="white--text">{{ stats.PASSES[1] }}</span>
                      </span>
                    </v-progress-linear>

                    <v-progress-linear
                      v-if="stats.SUCCESSFUL_PASSES != []"
                      :value="stats.SUCCESSFUL_PASSES[0] * 100 / (stats.SUCCESSFUL_PASSES[0] + stats.SUCCESSFUL_PASSES[1])"
                      class="my-2 elevation-5"
                      :background-color="(stats.SUCCESSFUL_PASSES[0] == 0 && stats.SUCCESSFUL_PASSES[1] == 0) ? 'grey' : 'pink'"
                      color="green"
                      height="30">
                      <span class="d-flex justify-space-between mx-2" style="width: 100%;">
                        <span class="white--text">{{ stats.SUCCESSFUL_PASSES[0] }} ({{ stats.SUCCESSFUL_PASSES_PERCENTAGE[0] }} %)</span>
                        <span class="white--text">{{ $t('stats_type.SUCCESSFUL_PASSES') }}</span>
                        <span class="white--text">{{ stats.SUCCESSFUL_PASSES[1] }} ({{ stats.SUCCESSFUL_PASSES_PERCENTAGE[1] }} %)</span>
                      </span>
                    </v-progress-linear>

                    <v-progress-linear
                      v-if="stats.OFFSIDES != []"
                      :value="stats.OFFSIDES[0] * 100 / (stats.OFFSIDES[0] + stats.OFFSIDES[1])"
                      class="my-2 elevation-5"
                      :background-color="(stats.OFFSIDES[0] == 0 && stats.OFFSIDES[1] == 0) ? 'grey' : 'pink'"
                      color="green"
                      height="30">
                      <span class="d-flex justify-space-between mx-2" style="width: 100%;">
                        <span class="white--text">{{ stats.OFFSIDES[0] }}</span>
                        <span class="white--text">{{ $t('stats_type.OFFSIDES') }}</span>
                        <span class="white--text">{{ stats.OFFSIDES[1] }}</span>
                      </span>
                    </v-progress-linear>

                    <v-progress-linear
                      v-if="stats.FOULS != []"
                      :value="stats.FOULS[0] * 100 / (stats.FOULS[0] + stats.FOULS[1])"
                      class="my-2 elevation-5"
                      :background-color="(stats.FOULS[0] == 0 && stats.FOULS[1] == 0) ? 'grey' : 'pink'"
                      color="green"
                      height="30">
                      <span class="d-flex justify-space-between mx-2" style="width: 100%;">
                        <span class="white--text">{{ stats.FOULS[0] }}</span>
                        <span class="white--text">{{ $t('stats_type.FOULS') }}</span>
                        <span class="white--text">{{ stats.FOULS[1] }}</span>
                      </span>
                    </v-progress-linear>

                    <v-progress-linear
                      v-if="stats.YELLOWCARDS != []"
                      :value="(stats.YELLOWCARDS[0] + stats.YELLOWRED_CARDS[0]) * 100 / (stats.YELLOWCARDS[0] + stats.YELLOWRED_CARDS[0] + stats.YELLOWCARDS[1] + stats.YELLOWRED_CARDS[1])"
                      class="my-2 elevation-5"
                      :background-color="(stats.YELLOWCARDS[0] == 0 && stats.YELLOWCARDS[1] == 0) ? 'grey' : 'pink'"
                      color="green"
                      height="30">
                      <span class="d-flex justify-space-between mx-2" style="width: 100%;">
                        <span class="white--text">{{ stats.YELLOWCARDS[0] + stats.YELLOWRED_CARDS[0] }}</span>
                        <span class="white--text">{{ $t('stats_type.YELLOWCARDS') }}</span>
                        <span class="white--text">{{ stats.YELLOWCARDS[1] + stats.YELLOWRED_CARDS[1] }}</span>
                      </span>
                    </v-progress-linear>

                    <v-progress-linear
                      v-if="stats.REDCARDS != []"
                      :value="stats.REDCARDS[0] * 100 / (stats.REDCARDS[0] + stats.REDCARDS[1])"
                      class="my-2 elevation-5"
                      :background-color="(stats.REDCARDS[0] == 0 && stats.REDCARDS[1] == 0) ? 'grey' : 'pink'"
                      color="green"
                      height="30">
                      <span class="d-flex justify-space-between mx-2" style="width: 100%;">
                        <span class="white--text">{{ stats.REDCARDS[0] }}</span>
                        <span class="white--text">{{ $t('stats_type.REDCARDS') }}</span>
                        <span class="white--text">{{ stats.REDCARDS[1] }}</span>
                      </span>
                    </v-progress-linear>

                    <v-progress-linear
                      v-if="stats.TACKLES != []"
                      :value="stats.TACKLES[0] * 100 / (stats.TACKLES[0] + stats.TACKLES[1])"
                      class="my-2 elevation-5"
                      :background-color="(stats.TACKLES[0] == 0 && stats.TACKLES[1] == 0) ? 'grey' : 'pink'"
                      color="green"
                      height="30">
                      <span class="d-flex justify-space-between mx-2" style="width: 100%;">
                        <span class="white--text">{{ stats.TACKLES[0] }}</span>
                        <span class="white--text">{{ $t('stats_type.TACKLES') }}</span>
                        <span class="white--text">{{ stats.TACKLES[1] }}</span>
                      </span>
                    </v-progress-linear>

                    <v-progress-linear
                      v-if="stats.INJURIES != []"
                      :value="stats.INJURIES[0] * 100 / (stats.INJURIES[0] + stats.INJURIES[1])"
                      class="my-2 elevation-5"
                      :background-color="(stats.INJURIES[0] == 0 && stats.INJURIES[1] == 0) ? 'grey' : 'pink'"
                      color="green"
                      height="30">
                      <span class="d-flex justify-space-between mx-2" style="width: 100%;">
                        <span class="white--text">{{ stats.INJURIES[0] }}</span>
                        <span class="white--text">{{ $t('stats_type.INJURIES') }}</span>
                        <span class="white--text">{{ stats.INJURIES[1] }}</span>
                      </span>
                    </v-progress-linear>
                  </div>
                </div>

                <p v-else>{{ $t('message.nostats') }}</p>
              </div>
            </v-tab-item>

            <v-tab-item key="Sidelined">
              <template v-if="!tabLoaded" >
                <div class="ripple_loading" ><img src="/img/icons/Ripple-1s-200px.png"></div>
              </template>
              
              <div v-else class="row no-gutters justify-center mt-3">
                <div v-if="sidelined && sidelined.length" class="col-lg-7 col-md-9">
                  <v-simple-table class="table-row-40 elevation-2">
                    <thead>
                      <tr class="grey--text text--darken-1">
                        <td>{{ $t('player') }}</td>
                        <td>{{ $t('injury') }}</td>
                        <td v-if="$vuetify.breakpoint.lg">{{ $t('from') }}</td>
                        <td v-if="$vuetify.breakpoint.lg">{{ $t('to') }}</td>
                        <td>{{ $t('missedgames') }}</td>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(item, i) in sidelined"
                        :key="i"
                        @click="playerModal(item.sideline)"
                        class="curPointer">
                        <td class="grey--text text--darken-2 white-space-nowrap">
                          <div
                            class="col-sm-6 d-flex justify-space-between p-1">
                            <div class="d-flex">
                              <img
                                :src="item.sideline.player ? item.sideline.player.image_path : '/img/default-avatar.png'"
                                onerror="this.src='/img/default-avatar.png'"
                                class="rounded-circle white align-self-center wh-35"
                                />

                              <span class="align-center blue-grey--text">{{ item.sideline.player.common_name }}</span>
                            </div>
                          </div>
                        </td>

                        <td>
                          <span class="mx-2 blue-grey--text">{{ item.sideline.type.name }}</span>
                        </td>

                        <td v-if="$vuetify.breakpoint.lg">
                          <span class="mx-2 blue-grey--text">{{ item.sideline.start_date }}</span>
                        </td>

                        <td v-if="$vuetify.breakpoint.lg">
                          <span v-if="item.sideline.end_date" class="mx-2 blue-grey--text">{{ item.sideline.end_date }}</span>
                        </td>

                        <td>
                          <span class="mx-2 blue-grey--text">{{ item.sideline.games_missed }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </div>

                <p v-else>{{ $t('message.nosidelined') }}</p>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </template>
    </div>
  </v-app>
</template>

<script>

import { Card, Tabs, TabPane } from '@/components'
import axios from 'axios'
import moment from 'moment-timezone'

export default {
  components: {
    Card,
    Tabs,
    TabPane,
  },
  data() {
    return {
      tz: localStorage.tz ? localStorage.tz : '+0',
      basic: null,
      colors: [
        {'bgcolor': 'white', 'color': 'black'},
        {'bgcolor': 'black', 'color': 'white'}],  // default color
      teams: [],
      event: null,
      comment: null,
      shootout: [[], []],
      stats: null,
      sidelined: null,
      lineup: null,
      bench: null,
      substitution: null,
      formation: [[], []],
      formation_code: [[], []],
      coach: null,
      player: null,
      refereeStats: [],
      index4Referee: null,
      tab: null,

      basicLoaded: false,
      authorization: false,
      coachDialog: false,
      playerDialog: false,
      venueDialog: false,
      refereeDialog: false,
      formation_success: true,
      message: '',
      tabLoaded: false,
      items: ['basics', 'lineup', 'event', 'stats', 'sidelined'],
      position: ['goalkeeper', 'defender', 'midfielder', 'attacker'],
      statusCode: [
        {'short_name': 'NS', 'name': 'Not Started', 'info': 'The initial status of a game'},
        {'short_name': '1st', 'name': '1st Half', 'info': 'The game is currently inplay in the 1st half'},
        {'short_name': 'HT', 'name': 'Half-Time', 'info': 'The game currently is in half-time'},
        {'short_name': 'BRK', 'name': 'Regular time finished', 'info': 'Waiting for penalties to start'},
        {'short_name': 'FT', 'name': 'Full-Time', 'info': 'The game has ended after 90 minutes. When a game goes into extra time, the FT status will be presented for a few seconds and then move to the BREAK status. '},
        {'short_name': '1et', 'name': 'Extra Time', 'info': 'The game currently is in extra time in the 1st half, can happen in knockout games'},
        {'short_name': 'AET', 'name': 'Finished after extra time', 'info': 'The game has finished after 120 minutes'},
        {'short_name': 'FTP', 'name': 'Full-Time after penalties', 'info': 'Finished after penalty shootout'},
        {'short_name': 'PEN', 'name': 'Penalty Shootout', 'info': "ET status didn't get a winner, penalties are taken to determine the winner"},
        {'short_name': 'POST', 'name': 'PostPoned', 'info': 'The game is postponed'},
        {'short_name': 'SUSP', 'name': 'SUSPENDED', 'info': 'The game has suspended and will continue at a later time or day'},
        {'short_name': 'CANC', 'name': 'Cancelled', 'info': 'The game has been cancelled'},
        {'short_name': 'TBA', 'name': 'To Be Announced', 'info': 'The game does not have a confirmed date and time yet. It will be announced later on.'},
        {'short_name': 'WO', 'name': 'Walk Over', 'info': 'Awarding of a victory to a contestant because there are no other contestants'},
        {'short_name': 'ABAN', 'name': 'Abandoned', 'info': 'The game has abandoned and will continue at a later time or day'},
        {'short_name': 'DELA', 'name': 'Delayed', 'info': 'The game is delayed so it will start later'},
        {'short_name': 'AWAR', 'name': 'Awarded', 'info': 'Winner is being decided externally'},
        {'short_name': 'INT', 'name': 'Interrupted', 'info': 'The game has been interrupted. Can be due to bad weather'},
        {'short_name': 'AU', 'name': 'Awaiting Updates', 'info': 'Can occur when there is a connectivity issue or something'},
        {'short_name': 'DEL', 'name': 'Deleted', 'info': 'Game is not available anymore via normal api calls because it has been replaced. This can happen in leagues that have a lot of changes in their schedules. The games can still be retrieved by adding deleted=1 to your request so you can update your system properly'},
        {'short_name': 'ETB', 'name': 'Extra Time - Break', 'info': 'The game has suspended and will continue at a later time or day'},
        {'short_name': '2nd', 'name': '2nd Half', 'info': 'The game is currently inplay in the 2nd half'},
        {'short_name': '2et', 'name': 'ET - 2nd Half', 'info': 'The game currently is in extra time in the 2nd half, can happen in knockout games'},
        {},
        {'short_name': 'PENB', 'name': 'Penalties - Break', 'info': 'Waiting for penalties to start'},
        {'short_name': 'PEN', 'name': 'Pending', 'info': 'The fixture is awaiting an update'}
      ],
      refereeType: ['referee', 'assistant1', 'assistant2', 'official4']
    }
  },
  mounted() {
      this.init()
  },
  watch: {
    /*
      events for tab changes
    */
    tab (val) {
      if (val == 1) {
        /* tab for Lineup */
        if (this.lineup) {
          this.tabLoaded = true
        } else {
          this.tabLoaded = false

          this.getLineup()
        }
      }

      if (val == 2) {
        /* tab for Event */
        if (this.event) {
          this.tabLoaded = true
        } else {
          this.tabLoaded = false

          this.getEvent()
        }
      }

      // if (val == 3) {
      //   /* tab for Comment */
      //   if (this.comment) {
      //     this.tabLoaded = true
      //   } else {
      //     this.tabLoaded = false

      //     this.getComment()
      //   }
      // }

      if (val == 3) {
        /* tab for Stats */
        if (this.stats) {
          this.tabLoaded = true
        } else {
          this.tabLoaded = false

          this.getStats()
        }
      }

      if (val == 4) {
        /* tab for Sidelined */
        if (this.sidelined) {
          this.tabLoaded = true
        } else {
          this.tabLoaded = false

          this.getSidelined()
        }
      }
    }
  },
  beforeCreate: function () {
    axios
      .get('/v3/fixture/' + this.$route.params.fid,
        { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
      .then(response => {
        if (response.data.type == 0 || response.data.type == 1) {
          this.$router.replace('/message/subscribe/' + (response.data.type ? 'expired' : 'not-paid'))
        } else {
          this.basic = response.data
          this.modifyMeta()

          if (this.basic.participants[0].meta.location == "away") {
            this.teams = [this.basic.participants[1], this.basic.participants[0]]
          } else {
            this.teams = this.basic.participants
          }
          
          if (this.basic.coaches.length > 0 && this.basic.coaches[0].meta.participant_id == this.teams[1].id) {
            this.basic.coaches = [this.basic.coaches[1], this.basic.coaches[0]]
          }

          // modify scores
          let new_score = {goal: [], penalty: []}
          this.basic.scores.forEach((sc) => {
            if (sc.type_id == 1525) {
              if (sc.score.participant == "home") {
                new_score.goal[0] = sc.score.goals
              } else {
                new_score.goal[1] = sc.score.goals
              }
            } else if (sc.type_id == 5) {
              if (sc.score.participant == "home") {
                new_score.penalty[0] = sc.score.goals
              } else {
                new_score.penalty[1] = sc.score.goals
              }
            }
          })

          this.basic.scores = new_score
          
          if (this.basic.referees) {
            this.basic.referees = _.orderBy(this.basic.referees, ['type_id'])
          }

          // change weather description to Code
          if (this.basic.weatherreport && this.basic.weatherreport.description) {
            let text = this.basic.weatherreport.description
            this.basic.weatherreport.description = text.replace(/ /g, "_")
          }

          this.authorization = true
        }
        this.basicLoaded = true
      })
      .catch(error => {
        if (error.response.status == 401) {
          localStorage.removeItem('token')
          localStorage.removeItem('uid')
          location.reload()
        }              
      })
  },
  methods: {
    async init() {
      //Etc/GMT-X will have an offset of +X and Etc/GMT+X will have an offset of -X.
      moment.tz.setDefault('Etc/GMT' + this.tz)
    },

    venueModal() {
      this.venueDialog = true
    },

    /*
      method for get lineup data from api.
    */
    getLineup() {
      axios
      .get('/v3/fixture/lineup/' + this.$route.params.fid,
        { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
      .then(response => {
        /* 
          Set lineup from null to []
          To prevent duplication api calls for unsubscribed user
        */
        this.lineup = []
        this.substitution = []

        if (response.data.type == 0 || response.data.type == 1) {
          this.$router.replace('/message/subscribe/' + (response.data.type ? 'expired' : 'not-paid'))
        } else {
          let arr = this.modifyDetail(response.data.lineup)

          this.bench = arr.filter(pl => pl.type_id == 12)
          this.lineup = arr.filter(pl => pl.type_id == 11)
          this.substitution = response.data.substitution.sort((a, b) => { return (a.minute + a.extra_minute) - (b.minute + b.extra_minute)})
          this.player = this.lineup[0]

          this.arrangeFormation()
        }
        this.tabLoaded = true
      })
      .catch(error => {
        if (error.response.status == 401) {
          localStorage.removeItem('token')
          localStorage.removeItem('uid')
          location.reload()
        }              
      })
    },

    /*
      method for modify stats from each player
    */
    modifyDetail(arr) {
      arr.forEach(pl => {
        let tmp = {
          "shots": {
            "shots_total": 0,
            "shots_on_target": 0
          },
          "goals": {
            "scored": 0,
            "assists": 0,
            "conceded": 0,
            "owngoals": 0,
            "team_conceded": 0
          },
          "fouls": {
            "drawn": 0,
            "committed": 0
          },
          "cards": {
            "yellowcards": 0,
            "redcards": 0,
            "yellowredcards": 0
          },
          "passing": {
            "total_crosses": 0,
            "accurate_crosses": 0,
            "passes": 0,
            "accurate_passes": 0,
            "accurate_passes_percentage": 0,
            "key_passes": 0
          },
          "dribbles": {
            "attempts": 0,
            "success": 0,
            "dribbled_past": 0
          },
          "duels": {
            "total": 0,
            "won": 0
          },
          "other": {
            "aerials_won": 0,
            "big_chances_missed": 0,
            "punches": 0,
            "offsides": 0,
            "saves": 0,
            "saves_insidebox": 0,
            "pen_scored": 0,
            "pen_missed": 0,
            "pen_saved": 0,
            "pen_committed": 0,
            "pen_won": 0,
            "hit_woodwork": 0,
            "tackles": 0,
            "blocked_shots": 0,
            "interceptions": 0,
            "clearances": 0,
            "dispossesed": 0,
            "minutes_played": 0
          },
          "rating": null
        }

        if (pl.details) {
          pl.details.forEach(stats => {
            switch (stats.type_id) {
              case 40:
                pl.captain = stats.data.value
                break;
              case 42:
                tmp.shots.shots_total = stats.data.value
                break;
              case 51:
                tmp.other.offsides = stats.data.value
                break;
              case 52:
                tmp.goals.scored = stats.data.value
                break;
              case 56:
                tmp.fouls.committed = stats.data.value
                break;
              case 57:
                tmp.other.saves = stats.data.value
                break;
              case 64:
                tmp.other.hit_woodwork = stats.data.value
                break;
              case 78:
                tmp.other.tackles = stats.data.value
                break;
              case 79:
                tmp.goals.assists = stats.data.value
                break;
              case 80:
                tmp.passing.passes = stats.data.value
                break;
              case 83:
                tmp.cards.redcards = stats.data.value
                break;
              case 84:
                tmp.cards.yellowcards = stats.data.value
                break;
              case 85:
                tmp.cards.yellowredcards = stats.data.value
                break;
              case 86:
                tmp.shots.shots_on_target = stats.data.value
                break;
              case 88:
                tmp.goals.team_conceded = stats.data.value
              break;
              case 94:
                tmp.other.dispossesed = stats.data.value
                break
              case 96:
                tmp.fouls.drawn = stats.data.value
                break;
              case 97:
                tmp.other.blocked_shots = stats.data.value
                break;
              case 98:
                tmp.passing.total_crosses = stats.data.value
                break;
              case 99:
                tmp.passing.accurate_crosses = stats.data.value
                break;
              case 100:
                tmp.other.interceptions = stats.data.value
                break;
              case 101:
                tmp.other.clearances = stats.data.value
                break;
              case 103:
                tmp.other.punches = stats.data.value
                break;
              case 104:
                tmp.other.saves_insidebox = stats.data.value
                break;
              case 105:
                tmp.duels.total = stats.data.value
                break;
              case 106:
                tmp.duels.won = stats.data.value
                break;
              case 107:
                tmp.other.aerials_won = stats.data.value
                break;
              case 108:
                tmp.dribbles.attempts = stats.data.value
                break;
              case 109:
                tmp.dribbles.success = stats.data.value
                break;
              case 110:
                tmp.dribbles.dribbled_past = stats.data.value
                break;
              case 111:
                tmp.other.pen_scored = stats.data.value
                break;
              case 112:
                tmp.other.pen_missed = stats.data.value
                break;
              case 113:
                tmp.other.pen_saved = stats.data.value
                break;
              case 114:
                tmp.other.pen_committed = stats.data.value
                break;
              case 115:
                tmp.other.pen_won = stats.data.value
                break;
              case 116:
                tmp.passing.accurate_passes = stats.data.value
                break;
              case 117:
                tmp.passing.key_passes = stats.data.value
                break;
              case 118:
                tmp.rating = stats.data.value
                break;
              case 119:
                tmp.other.minutes_played = stats.data.value
                break;
              case 324:
                tmp.goals.owngoals = stats.data.value
                break;
              case 581:
                tmp.other.big_chances_missed = stats.data.value
                break;
              case 1584:
                tmp.passing.accurate_passes_percentage = stats.data.value
                break;
            }
          })

          pl.stats = tmp
        }
      })

      return arr
    },

    /*
      method for arrange lineup array as formation
    */
    arrangeFormation() {
      let local = [], visitor = []
      this.lineup.forEach((item) => {
        if (!item.position_id || !item.formation_position) {
          this.formation_success = false
        }

        if (item.team_id == this.teams[0].id) {
          local.push(item)
        } else {
          visitor.push(item)
        }
      })

      if (this.formation_success && this.basic.formations.home && this.basic.formations.away) {
        // cases of formations' error. eg. none formation, none position
        local.sort((a, b) => (a.formation_position - b.formation_position))
        visitor.sort((a, b) => (a.formation_position - b.formation_position))  


        let tmp = "1-".concat(this.basic.formations.home)
        this.formation_code[0] = tmp.split('-')
        tmp = "1-".concat(this.basic.formations.away)
        this.formation_code[1] = tmp.split('-')

        this.formation_code[0].forEach((item, ind) => {
          let tmp = local
          local = tmp.splice(item)
          this.formation[0][ind] = tmp
        })

        this.formation_code[1].forEach((item, ind) => {
          let tmp = visitor
          visitor = tmp.splice(item)
          this.formation[1][ind] = tmp
        })
      } else {
        this.formation_success = false
        this.formation = [local, visitor]
      }
    },

    /*
      method for get contrast color based on background color and formations
    */
    modifyMeta() {
      let frm = {}
      this.basic.metadata.forEach((item) => {
        if (item.type_id == 159) {
          frm = item.values
        } else {
          this.colors[item.type_id - 161].bgcolor = item.values.participant
          this.colors[item.type_id - 161].color = this.hexToLuma(item.values.participant)
        }
      })

      this.basic.formations = frm
    },

    hexToLuma(colour) {
      const hex   = colour.replace(/#/, '')
      const r     = parseInt(hex.substr(0, 2), 16)
      const g     = parseInt(hex.substr(2, 2), 16)
      const b     = parseInt(hex.substr(4, 2), 16)

      let v = ((r * 299) + (g * 587) + (b * 114)) / 1000
      return (v >= 128) ? 'black' : 'white'
    },

    /*
      method for get event data from api.
    */
    getEvent() {
      axios
      .get('/v3/fixture/event/' + this.$route.params.fid,
        { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
      .then(response => {
        /* 
          Set event from null to []
          To prevent duplication api calls for unsubscribed user
        */
        this.event = []

        if (response.data.type == 0 || response.data.type == 1) {
          this.$router.replace('/message/subscribe/' + (response.data.type ? 'expired' : 'not-paid'))
        }else {
          this.event = response.data.event.sort((a, b) => { return (a.minute + a.extra_minute) - (b.minute + b.extra_minute)})
          
          this.event.forEach((evt) => {
            if (evt.type_id == 22 || evt.type_id == 23) {
              if (evt.participant_id == this.teams[0].id ) {
                this.shootout[0].push(evt)
              } else {
                this.shootout[1].push(evt)
              }
            }
          })
        }
        this.tabLoaded = true
      })
      .catch(error => {
        if (error.response.status == 401) {
          localStorage.removeItem('token')
          localStorage.removeItem('uid')
          location.reload()
        }              
      })
    },

    /*
      method for get comment data from api.
    */
    getComment() {
      axios
      .get('/v3/fixture/comment/' + this.$route.params.fid,
        { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
      .then(response => {
        /* 
          Set comment from null to []
          To prevent duplication api calls for unsubscribed user
        */
        this.comment = []

        if (response.data.type == 0 || response.data.type == 1) {
          this.$router.replace('/message/subscribe/' + (response.data.type ? 'expired' : 'not-paid'))
        }else {
          this.comment = _.orderBy(response.data.comment, 'order')
        }

        this.tabLoaded = true
      })
      .catch(error => {
        if (error.response.status == 401) {
          localStorage.removeItem('token')
          localStorage.removeItem('uid')
          location.reload()
        }
      })
    },

    /*
      method for modify teams' stats
    */
    modifyStats(arr) {
      if (arr.length == 0) {
        return {}
      }

      let res = {
        "CORNERS": [],
        "BALL_POSSESSION": [],
        "SUCCESSFUL_HEADERS": [],
        "SHOTS_INSIDEBOX": [],
        "SHOTS_BLOCKED": [],
        "SHOTS_OUTSIDEBOX": [],
        "OFFSIDES": [],
        "CHALLENGES": [],
        "INTERCEPTIONS": [],
        "DUELS_WON": [],
        "DANGEROUS_ATTACKS": [],
        "REDCARDS": [],
        "SHOTS_ON_TARGET": [],
        "YELLOWCARDS": [],
        "BALL_SAFE": [],
        "GOAL_KICKS": [],
        "GOALS": [],
        "SHOTS_OFF_TARGET": [],
        "SHOTS_TOTAL": [],
        "SUCCESSFUL_PASSES": [],
        "ATTACKS": [],
        "COUNTER_ATTACKS": [],
        "KEY_PASSES": [],
        "SUCCESSFUL_INTERCEPTIONS": [],
        "PENALTIES": [],
        "SUCCESSFUL_PASSES_PERCENTAGE": [],
        "TACKLES": [],
        "FOULS": [],
        "FREE_KICKS": [],
        "YELLOWRED_CARDS": [],
        "SUBSTITUTIONS": [],
        "THROWINS": [],
        "BEATS": [],
        "GOAL_ATTEMPTS": [],
        "SAVES": [],
        "TOTAL_CROSSES": [],
        "HEADERS": [],
        "PASSES": [],
        "TREATMENTS": [],
        "INJURIES": [],
        "ASSISTS": [],
      }

      arr.forEach(item => {
        try {
          if (item.participant_id==this.teams[0].id){
            res[item.type.developer_name][0] = item.data.value
          } else {
            res[item.type.developer_name][1] = item.data.value
          }
        }
        catch(err) {
          return {}
        }
      })

      if (res.YELLOWRED_CARDS.length == 0) {
        res.YELLOWRED_CARDS = [0, 0]
      }
      
      return res
    },

    /*
      method for get stats data from api.
    */
    getStats() {
      axios
      .get('/v3/fixture/stats/' + this.$route.params.fid,
        { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
      .then(response => {
        /* 
          Set stats from null to {}
          To prevent duplication api calls for unsubscribed user
        */
        this.stats = {}

        if (response.data.type == 0 || response.data.type == 1) {
          this.$router.replace('/message/subscribe/' + (response.data.type ? 'expired' : 'not-paid'))
        } else {
          this.stats = this.modifyStats(response.data.stats)
        }

        this.tabLoaded = true
      })
      .catch(error => {
        if (error.response.status == 401) {
          localStorage.removeItem('token')
          localStorage.removeItem('uid')
          location.reload()
        }              
      })
    },

    /*
      method for get stats data from api.
    */
    getSidelined() {
      axios
      .get('/v3/fixture/sidelined/' + this.$route.params.fid,
        { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
      .then(response => {
        /* 
          Set sidelined from null to []
          To prevent duplication api calls for unsubscribed user
        */
        this.sidelined = []

        if (response.data.type == 0 || response.data.type == 1) {
          this.$router.replace('/message/subscribe/' + (response.data.type ? 'expired' : 'not-paid'))
        }else {
          this.sidelined = response.data.sidelined
        }
        this.tabLoaded = true
      })
      .catch(error => {
        if (error.response.status == 401) {
          localStorage.removeItem('token')
          localStorage.removeItem('uid')
          location.reload()
        }              
      })
    },
        
    coachModal(ind) {
      this.coach = ind ? this.basic.coaches[1] : this.basic.coaches[0]
      this.coachDialog = true
    },

    refereeModal(ind, rid) {
      this.index4Referee = null
      this.refereeDialog = true

      if(this.refereeStats[ind]) {
        this.index4Referee = ind
      } else {
        axios
          .get('/v3/referee/stats/' + rid,
            { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
          .then(response => {
            if (response.data.type == 0 || response.data.type == 1) {
              this.$router.replace('/message/subscribe/' + (response.data.type ? 'expired' : 'not-paid'))
            }else {
              response.data.statistics.forEach(element => {
                let detail = {
                  redcards: null,
                  yellowcards: null,
                  yellowredcards: null,
                  penalties: null,
                  fouls: null,
                  var_moments: null,
                  matches: 0,
                }

                element.details.forEach(val => {
                  switch (val.type_id) {
                    case 47:
                      detail.penalties = val.value.all
                      break
                    case 56:
                      detail.fouls = val.value
                      break
                    case 188:
                      detail.matches = val.value.count
                      break
                    case 83:
                      detail.redcards = val.value.all
                      break
                    case 84:
                      detail.yellowcards = val.value.all
                      break
                    case 85:
                      detail.yellowredcards = val.value.all
                      break
                    case 314:
                        detail.var_moments = val.value
                        break
                  }
                })

                element.details = detail
                element.season_name = element.season.name
              })

              response.data.statistics = _.orderBy(response.data.statistics, ['season_name'], ['desc'])
              this.refereeStats[ind] = response.data

              this.index4Referee = ind
            }
          })
          .catch(error => {
            if (error.response.status == 401) {
              localStorage.removeItem('token')
              localStorage.removeItem('uid')
              location.reload()
            } else {
              this.refereeDialog = false
            }
          })
      }
    },

    playerModal(member) {
      if (member.player_id) {
        this.player = member
        this.playerDialog = true
      }
    },

    /*
      open player page on new tab
    */
    playerDetail(id) {
      let routeData = this.$router.resolve({name: 'player', params: {pid: id}});
      window.open(routeData.href, '_blank');
    },

    coachDetail(id) {
      let routeData = this.$router.resolve({name: 'coach', params: {id: id}});
      window.open(routeData.href, '_blank');
    },
  }
};
</script>
<style>
  @import '../assets/footcorner/soccermatch.css'
</style>
