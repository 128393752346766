<script src="https://unpkg.com/vue/dist/vue.js"></script>
<script src="https://js.stripe.com/v3/"></script>

<template>
  <v-app>
    <div class="page-header clear-filter">
      <div
        class="page-header-image"
        style="background-image: url('img/payment.jpg');"
      ></div>

      <div class="subscription">
        <div v-if="isSubscribed == false" class="container">
          <div class="row center">
            <div class="col-sm-12 col-md-6 col-lg-4 ml-auto mr-auto" v-for="plan in plans" :key="plan.month">
              <card type="plan" data-background-color="white" plain>
                <h3 class='card-title title-up'>{{plan.title}}</h3>

                <h2 class='title'>{{plan.price.toFixed(2)}} EUR</h2>

                <h5>{{$t('subscribe.message1')}}</h5>

                <h5>{{$t('subscribe.message2')}}</h5>

                <div class="card-footer text-center pay-buttons">
                  <button type="button" class="btn btn-round" v-on:click="payWithStripe(plan)">
                    <i class="fab fa-stripe-s"></i> &nbsp; {{creditCardTitle}}
                  </button>
                  
                  <button v-if="isLogged == false" type="button" class="btn btn-round" v-on:click="goToLogin()">
                    <i class="fab fa-paypal"></i> &nbsp;  {{paypalTitle}}
                  </button>
                  
                  <div :id="'paypal-button-container-' + plan.month"></div>
                </div>
              </card>
            </div>
          </div>
        </div>

        <div v-else class="container row center">
          <div v-if="isSubscribed==true" class="col-md-4 ml-auto mr-auto">
            <card type="plan" data-background-color="white" plain>
              <h4 class="card-title title-up">{{$t('subscribe.message3')}}</h4>
              
              <h2 class="title">{{selectedPlan}} {{$t('subscribe.plan')}}</h2>
              
              <h5>{{ subscriptionCanceled ? `${$t('subscribe.expiredate')} :` : `${$t('subscribe.expiredate')} :`}} {{expire_date}}</h5>
              
              <button v-if="subscriptionCanceled == false" type="button" class="btn-subscribe btn btn-round" v-on:click="cancelSubscription">{{$t('subscribe.cancelbilling')}}</button>
              
              <a href="https://"></a>
              
              <!-- <div class="card-footer text-center">
                <button type="button text-capitalize" class="btn-subscribe btn btn-round btn-lg" v-on:click="goUpcoming">{{$t('upcoming')}}</button>
              </div> -->
            </card>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import Vue from 'vue'
import router from '../router'
import { Card, Button, FormGroupInput } from '@/components';
import MainFooter from '@/layout/MainFooter';
import axios from 'axios';
import config from '@/config';
import init_paypal from '@/paypal';
import moment from 'moment'
import Cookies from 'js-cookie';

axios.defaults.baseURL = config.API_URL;
export default {
  name: 'subscription-page',
  bodyClass: 'subscription-page',
  components: {
    Card,
    MainFooter,
    [Button.name]: Button,
  },
  computed: {
    plans () {
      return [{
        price: 22.99,
        title: this.$t('subscribe.first'),
        month: 1
      },{
        price: 60,
        title: this.$t('subscribe.second'),
        month: 3
      },{
        price: 230,
        title: this.$t('subscribe.third'),
        month: 12
      }]
    }
  },
  data() {
    return {
      creditCardTitle: 'Credit Card',
      paypalTitle: 'Paypal',
      isLogged: false,
      token: '',
      // braintree_client_token: 'sandbox_x6zqhq8s_6p8mspmwh67gcf4s',
      // paypal_config: {
      //   flow: 'checkout',
      //   amount: '20.00',
      //   currency: 'USD'
      // },
      card_config: {
        cardholderName: {
          required: true
        }
      },
      selectedPlan: -1,
      creatingTransaction: false,
      expire_date: '',
      isSubscribed: false,
      subscriptionCanceled: false,
      //stripe
      loading: false,
      amount: 0,
      publishableKey: 'pk_test_JkjcISpSCUDfkUHZJO4V3NKQ005whXoRqc', 
      token: null,
      charge: null,
      // tapfiliate
      // referralCode: null,
      clickId: null,
    }
  },
  created() {
    // this.referralCode = Cookies.get('referralCode')
    this.clickId = Cookies.get('clickId')
    if (typeof this.clickId === 'undefined') {
      this.clickId = null
    }
  },
  mounted() {
    let stripeScript = document.createElement('script')
    stripeScript.setAttribute('src', 'https://js.stripe.com/v3/')
    document.head.appendChild(stripeScript)
    let paypalScript = document.createElement('script')
    if (config.mode === 'production') {
      paypalScript.setAttribute('src', 'https://www.paypal.com/sdk/js?client-id=Af6YsC072JCUq887Xej_ykkwO4Ikq1QG2V3WoKLJigaX9Ch5_s8YUkCdVteyo5B3HWRAza6d5g6MSm11&vault=true&disable-funding=credit,card')
    } else {
      paypalScript.setAttribute('src', 'https://www.paypal.com/sdk/js?client-id=AWgj1D4BoeoUs2lymqxeTN15N7xynydrECBPEfRo910Ahs6eBGjWjpSr7Cet1zZ891GrlKc3eX-w7vJ7&vault=true&disable-funding=credit,card')
    }
    paypalScript.setAttribute('data-sdk-integration-source', 'button-factory')
    document.head.appendChild(paypalScript)
    
    this.check_session()
  },
  methods: {
    check_session : function () {
      if (localStorage.token != null) {
        this.isLogged = true
        this.token = localStorage.token
        let username = localStorage.uid
        axios
          .post('/check_subscription/', null, { 'headers': { 'Authorization': 'Token ' + this.token } })
          .then((response) => {
            if (response.data.subscribed !== 0 && response.data.expired == false) {
              this.isSubscribed = true
              this.selectedPlan = response.data.subscribed > 0 ? response.data.subscribed : -response.data.subscribed
              this.subscriptionCanceled = response.data.subscribed < 0
              this.expire_date = moment(response.data['expire_date']).format('MMM D, YYYY')    
            } else {
              const timer = setInterval(function() {
                if (init_paypal(username, this.clickId)) {
                  clearInterval(timer);
                }
              }, 300);
            }
          })
          .catch(error => {
            if (error.response.status == 401) {
              localStorage.removeItem('token')
              localStorage.removeItem('uid')
              location.reload()
            }              
          })
      }
    },
    goToLogin: function() {
      router.push('login')
    },
    subscribe: function(month) {
      if (!this.isLogged) {
        router.push('login')
      } else {
        this.selectedPlan = month
      }
    },
    goUpcoming: function() {
      router.push('/')
    },
    //stripe
    payWithStripe: function(plan) {
      if (!this.isLogged) {
        router.push('login')
        return
      }
      this.selectedPlan = plan.month
      axios
          .post('/stripe_checkout/', {
              selectedPlan: this.selectedPlan,
              price: plan.price,
              clickId: this.clickId,
            }, {
              'headers': { 'Authorization': 'Token ' + this.token } 
            })
          .then((response) => {
            var stripe = Stripe(config.stripe_public_key);
            stripe.redirectToCheckout({
              sessionId: response.data.session_id
            }).then(function (result) {
              // console.log(JSON.stringify(result))
            });
          })
          .catch(error => {
            if (error.response.status == 401) {
              localStorage.removeItem('token')
              localStorage.removeItem('uid')
              location.reload()
            }              
          })
    },
    cancelSubscription: function() {
      if (!this.isLogged || !this.isSubscribed) {
        return
      }
      axios
          .post('/cancel_subscription/', null, { 'headers': { 'Authorization': 'Token ' + this.token } })
          .then((response) => {
            // console.log(JSON.stringify(response))
            if (response.status == 200 && response.data.success) {
              this.subscriptionCanceled = true
            }
          })
          .catch(error => {
            if (error.response.status == 401) {
              localStorage.removeItem('token')
              localStorage.removeItem('uid')
              location.reload()
            }              
          })
    }
  }
};
</script>
<style>

.subscription {
  margin-top: 20vh;
}

.center {
  margin: auto !important;
}
.pay-buttons {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  margin-top: 50px !important;
}

.pay-buttons button {
  margin: auto;
}

.pay-buttons .btn-round {
  width: 200px;
  margin-bottom: 15px;
  font-weight: bold;
  font-style: italic;
  font-family: unset;
  font-size: 14px;
  height: 34px;
  line-height: 10px;
  background-color: #008cce;
  box-shadow: 1px 1px 3px rgba(255, 255, 255, 0.7);
}

.pay-buttons .btn-round:hover {
  background-color: white;
  color: #008cce;
  box-shadow: 1px 1px 3px rgba(255, 255, 255, 0.7);
}

.card-body .btn {
  
}
.card-body .btn-subscribe {
  background-color: rgba(255, 255, 255, 0.9);
  color: brown;
  font-weight: bold;
}
.card-body .btn-subscribe:hover {
  background-color: #008cce;
  color: white;
}
</style>