<template>
  <v-card
    elevation="3"
  >
    <v-app-bar
      color="blue"
      dark
      dense
      flat
      height="35"
    >
      <v-toolbar-title>
        <span
          :class="{'subtitle-2': $vuetify.breakpoint.smAndDown, 'subtitle-1': $vuetify.breakpoint.mdAndUp}">
          {{ $t('topleague') }}
        </span>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-icon @click="show = !show">
        {{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
      </v-icon>
    </v-app-bar>

    <v-expand-transition>
      <div v-show="!show">
        <v-card-text>
          <div v-for="(item, ind) in majorLeagues" :key="ind" class="mb-1">
            <router-link :to="{name: 'league', params: {lid: item.league_id}}" class="black--text">
              <img class='mr-1' :src="item.league_logo" style="width: 25px; height: 25px;" :title="item.country_name"/>

              <span
                :title="item.country_name">
                {{ item.league_name }}
              </span>
            </router-link>
          </div>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
  import axios from 'axios'
  import config from '@/config'

  axios.defaults.baseURL = config.API_URL;

  export default {
    name: 'major-leagues',
    data() {
      return {
        show: this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm,
        majorLeagues: [],
      }
    },

    created: function() {
      // axios
      //   .get('/top/league/')
      //   .then(response => {
      //     this.majorLeagues = response.data.top_league;
      //   })
      //   .catch(error => {})
      if (localStorage.topleague) {
        this.majorLeagues = JSON.parse(localStorage.topleague);
      } else {
        this.majorLeagues = [
          {"league_id":2, "league_name":"Champions League", "league_logo":"https://cdn.sportmonks.com/images/soccer/leagues/2.png", "country_id":41, "country_name":"Europe"},
          {"league_id":8, "league_name":"Premier League", "league_logo":"https://cdn.sportmonks.com/images/soccer/leagues/8/8.png", "country_id":462, "country_name":"England"},
          {"league_id":564, "league_name":"La Liga", "league_logo":"https://cdn.sportmonks.com/images/soccer/leagues/564.png", "country_id":32, "country_name":"Spain"},
          {"league_id":384, "league_name":"Serie A", "league_logo":"https://cdn.sportmonks.com/images//soccer/leagues/0/384.png", "country_id":251, "country_name":"Italy"},
          {"league_id":82, "league_name":"Bundesliga", "league_logo":"https://cdn.sportmonks.com/images/soccer/leagues/82.png", "country_id":11, "country_name":"Germany"},
          {"league_id":301, "league_name":"Ligue 1", "league_logo":"https://cdn.sportmonks.com/images//soccer/leagues/13/301.png", "country_id":17, "country_name":"France"}];
        
        localStorage.setItem("topleague", JSON.stringify(this.majorLeagues));
      }
    },

  }
</script>