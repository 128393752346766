<template>
  <v-app>
    <v-dialog
      v-model="subCornerDialog"
      width="fit-content"
      style="z-index: 2000">
      <v-simple-table
        class="table-row-35">
        <thead>
          <tr>
            <!-- <th class="text-center">{{ subCorner.dt }}</th> -->
            <th class="text-center">{{ subCorner.home }}</th>
            <th class="text-center">{{ subCorner.away }}</th>
          </tr>
        </thead>

        <tbody class="text-center">
          <tr
            v-for="(Evt, index) in subCorner.data"
            :key="index">
            <template v-if="Evt.comment != 'X'">
              <!-- <td>{{ Evt.comment }}</td> -->
              <td>{{ Evt.h_m }}</td>
              <td>{{ Evt.a_m }}</td>
            </template>
          </tr>
        </tbody>
      </v-simple-table>
    </v-dialog>

    <v-dialog
      v-model="subGoalDialog"
      width="fit-content"
      style="z-index: 2000">
      <v-simple-table
        class="table-row-35">
        <thead>
          <tr>
            <th class="text-center">{{ subGoal.dt }}</th>
            <th class="text-center">{{ $t('player') }}</th>
            <th class="text-center">{{ subGoal.home }}</th>
            <th class="text-center">{{ subGoal.away }}</th>
          </tr>
        </thead>

        <tbody class="text-center">
          <tr v-for="(Evt, index) in subGoal.data" :key="index">
            <td>{{ Evt.minute }}</td>

            <td>
              <router-link :to="{name: 'player', params: {pid: Evt.player_id}}" class="blue-grey--text">
                {{ Evt.player }}
              </router-link>
            </td>

            <td>
              <img v-if="Evt.h_g=='penalty'" src="/img/icons/penalty.png" class="h-20" :title="$t('penalty')">
              <img v-else-if="Evt.h_g=='goal' || Evt.h_g=='own-goal'" src="/img/icons/goal.png" class="h-20">
            </td>
            
            <td>
              <img v-if="Evt.a_g=='penalty'" src="/img/icons/penalty.png" class="h-20" :title="$t('penalty')">
              <img v-else-if="Evt.a_g=='goal' || Evt.a_g=='own-goal'" src="/img/icons/goal.png" class="h-20">
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-dialog>

    <v-dialog
      v-model="subCardDialog"
      width="fit-content"
      style="z-index: 2000">
      <v-simple-table
        class="table-row-35">
        <thead>
          <tr>
            <th class="text-center">{{ subCard.dt }}</th>
            <th class="text-center">{{ $t('player') }}</th>
            <th class="text-center">{{ subCard.home }}</th>
            <th class="text-center">{{ subCard.away }}</th>
          </tr>
        </thead>

        <tbody class="text-center">
          <tr v-for="(Evt, index) in subCard.data" :key="index">
            <td>{{ Evt.minute }}</td>

            <td>
              <router-link :to="{name: 'player', params: {pid: Evt.player_id}}" class="blue-grey--text">
                {{ Evt.player }}
              </router-link>
            </td>

            <td>
              <img v-if="Evt.h_c=='yellowcard'" src="/img/icons/yellow_card.png">
              <img v-else-if="Evt.h_c=='yellowred' || Evt.h_c=='redcard'" src="/img/icons/red_card.png">
            </td>

            <td>
              <img v-if="Evt.a_c=='yellowcard'" src="/img/icons/yellow_card.png">
              <img v-else-if="Evt.a_c=='yellowred' || Evt.a_c=='redcard'" src="/img/icons/red_card.png">
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-dialog>

    <v-dialog
      v-model="subRCardDialog"
      width="fit-content"
      style="z-index: 2000">
      <v-simple-table
        class="table-row-35">
        <thead>
          <tr>
            <th class="text-center">{{ subCard.dt }}</th>
            <th class="text-center">{{ $t('player') }}</th>
            <th class="text-center">{{ subCard.home }}</th>
            <th class="text-center">{{ subCard.away }}</th>
          </tr>
        </thead>

        <tbody class="text-center">
          <tr v-for="(Evt, index) in subCard.data" :key="index">
            <td>{{ Evt.minute }}</td>

            <td>
              <router-link :to="{name: 'player', params: {pid: Evt.player_id}}">
                {{ Evt.player }}
              </router-link>
            </td>

            <td>
              <img v-if="Evt.h_c=='yellowcard'" src="/img/icons/yellow_card.png">
              <img v-else-if="Evt.h_c=='yellowred' || Evt.h_c=='redcard'" src="/img/icons/red_card.png">
            </td>

            <td>
              <img v-if="Evt.a_c=='yellowcard'" src="/img/icons/yellow_card.png">
              <img v-else-if="Evt.a_c=='yellowred' || Evt.a_c=='redcard'" src="/img/icons/red_card.png">
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-dialog>

    <v-dialog
      v-model="refereeDialog"
      width="fit-content"
      style="z-index: 2000">
      <v-card>
        <template v-if="chosenReferee">
          <div class="d-flex justify-center pt-3">
            <img
              v-if="chosenReferee.image_path"
              :src="chosenReferee.image_path"
              class="wh-70"
              onerror="this.src='/img/default-avatar.png'">

            <div class="mt-2 p-2">
              <template v-if="chosenReferee.name">
                {{ $t('name') }} : <b>{{ chosenReferee.name }}</b><br>
              </template>

              <template v-if="chosenReferee.date_of_birth">
                {{ $t('birthdate') }} : <b>{{ chosenReferee.date_of_birth }}</b><br>
              </template>

              <template v-if="chosenReferee.country">
                {{ $t('country') }} : <b>{{ chosenReferee.country.name }}</b><br>
              </template>
            </div>
          </div>
          
          <div class="d-flex justify-center">
            <template v-if="chosenReferee.statistics.length">
              <v-simple-table class="table-row-35 green-top-3">
                <thead>
                  <tr class="white">
                    <th style="border-right: 1px solid lightgrey">{{ $t('season') }}</th>
                    <th style="border-right: 1px solid lightgrey">{{ $t('league') }}</th>
                    <th class="text-center" style="border-right: 1px solid lightgrey" :title="$t('m.tooltip')">{{ $t('m.label') }}</th>
                    <th class="text-center" style="border-right: 1px solid lightgrey" :title="$t('f.tooltip')">{{ $t('f.label') }}</th>

                    <th class="text-center" style="border-right: 1px solid lightgrey" :title="$t('penalty')">
                      <img
                        src="/img/icons/penalty.png"
                        style="width: 15px"
                        :title="$t('penalty')">
                    </th>
                    
                    <th class="text-center" style="border-right: 1px solid lightgrey" :title="$t('yellowcard')">
                      <img
                        src="/img/icons/yellow_card.png"
                        :title="$t('yellowcard')">
                    </th>

                    <th class="text-center" style="border-right: 1px solid lightgrey" :title="$t('redcard')">
                      <img
                        src="/img/icons/red_card.png"
                        :title="$t('redcard')">
                    </th>

                    <th class="text-center" style="border-right: 1px solid lightgrey" :title="$t('var')">
                      <v-icon color="red" class="subtitle-2">mdi-monitor-eye</v-icon>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="st in chosenReferee.statistics"
                    :key="st.id"
                  >
                    <td style="border-right: 1px solid lightgrey">{{ st.season.name }}</td>
                    <td style="border-right: 1px solid lightgrey">{{ st.season.league.name }}</td>
                    
                    <td class="text-end" style="border-right: 1px solid lightgrey" :title="$t('m.tooltip')">
                      <template v-if="st.details.matches">{{ st.details.matches }}</template>
                    </td>

                    <td class="text-end" style="border-right: 1px solid lightgrey" :title="$t('f.tooltip')">
                      <template v-if="st.details.fouls">{{ st.details.fouls.count }}</template>
                    </td>

                    <td class="text-end" style="border-right: 1px solid lightgrey" :title="$t('penalty')">
                      <template v-if="st.details.penalties">
                        {{ st.details.penalties.count }}
                      </template>
                    </td>

                    <td class="text-end" style="border-right: 1px solid lightgrey" :title="$t('yellowcard')">
                      <template v-if="st.details.yellowcards">{{ st.details.yellowcards.count }}</template>
                    </td>

                    <td class="text-end" style="border-right: 1px solid lightgrey" :title="$t('redcard')">
                      <template v-if="st.details.redcards">{{ st.details.redcards.count }}</template>
                    </td>

                    <td class="text-end" style="border-right: 1px solid lightgrey" :title="$t('var')">
                      <template v-if="st.details.var_moments">{{ st.details.var_moments.count }}</template>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </template>

            <span v-else>{{ $t('message.norefereestats') }}</span>
          </div>
        </template>
        
        <div v-else class="ripple_loading wh-70">
          <img src="/img/icons/Ripple-1s-200px.png">
        </div>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="cornerOddsLoaded"
      v-model="cornerOddsDialog"
      width="fit-content"
      style="z-index: 2000">
      <div class="d-flex flex-wrap justify-center text-center white">
        <div class="d-flex flex-wrap justify-center">
          <v-simple-table
            v-if="moreOdds['CH']"
            class="table-row-35 border-lightgrey"
            style="border-radius: 0">
            <thead>
              <tr>
                <th>{{ $t('odds.handicap') }}</th>
                <th class="green--text">{{ basic.local_name }}</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in moreOdds['CH'][0]" :key="index">
                <td>{{ index }}</td>
                <td class="green--text">{{ item[odds_format] }}</td>
              </tr>
            </tbody>
          </v-simple-table>

          <v-simple-table
            v-if="moreOdds['CH']"
            class="table-row-35 border-lightgrey"
            style="border-radius: 0">
            <thead>
              <tr>
                <th>{{ $t('odds.handicap') }}</th>
                <th class="green--text">{{ basic.visitor_name }}</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in moreOdds['CH'][1]" :key="index">
                <td>{{ index }}</td>
                <td class="green--text">{{ item[odds_format] }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>

        <div class="d-flex flex-wrap justify-center">
          <v-simple-table
            v-if="moreOdds['COU']"
            class="table-row-35 border-lightgrey"
            style="border-radius: 0">
            <thead>
              <tr>
                <th>{{ $t('total') }}</th>
                <th class="green--text">{{ $t('over') }}</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in moreOdds['COU'][0]" :key="index">
                <td>{{ index }}</td>
                <td class="green--text">{{ item[odds_format] }}</td>
              </tr>
            </tbody>
          </v-simple-table>

          <v-simple-table
            v-if="moreOdds['COU']"
            class="table-row-35 border-lightgrey"
            style="border-radius: 0">
            <thead>
              <tr>
                <th>{{ $t('total') }}</th>
                <th class="green--text">{{ $t('under') }}</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in moreOdds['COU'][1]" :key="index">
                <td>{{ index }}</td>
                <td class="green--text">{{ item[odds_format] }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
      </div>
    </v-dialog>

    <v-dialog
      v-if="goalOddsLoaded"
      v-model="goalOddsDialog"
      width="fit-content"
      style="z-index: 2000">
      <div class="d-flex flex-wrap justify-center text-center white">
        <div class="d-flex flex-wrap justify-center">
          <v-simple-table
          v-if="moreOdds['AH']"
          class="table-row-35 border-lightgrey"
          style="border-radius: 0">
          <thead>
            <tr>
              <th>{{ $t('odds.handicap') }}</th>
              <th class="green--text">{{ basic.local_name }}</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item, index) in moreOdds['AH'][0]" :key="index">
              <td>{{ index }}</td>
              <td class="green--text">{{ item[odds_format] }}</td>
            </tr>
          </tbody>
          </v-simple-table>

          <v-simple-table
            v-if="moreOdds['AH']"
            class="table-row-35 border-lightgrey"
            style="border-radius: 0">
            <thead>
              <tr>
                <th>{{ $t('odds.handicap') }}</th>
                <th class="green--text">{{ basic.visitor_name }}</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in moreOdds['AH'][1]" :key="index">
                <td>{{ index }}</td>
                <td class="green--text">{{ item[odds_format] }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>

        <div class="d-flex flex-wrap justify-center">
          <v-simple-table
          v-if="moreOdds['OU']"
          class="table-row-35 border-lightgrey"
          style="border-radius: 0">
          <thead>
            <tr>
              <th>{{ $t('total') }}</th>
              <th class="green--text">{{ $t('over') }}</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item, index) in moreOdds['OU'][0]" :key="index">
              <td>{{ index }}</td>
              <td class="green--text">{{ item[odds_format] }}</td>
            </tr>
          </tbody>
          </v-simple-table>

          <v-simple-table
            v-if="moreOdds['OU']"
            class="table-row-35 border-lightgrey"
            style="border-radius: 0">
            <thead>
              <tr>
                <th>{{ $t('total') }}</th>
                <th class="green--text">{{ $t('under') }}</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in moreOdds['OU'][1]" :key="index">
                <td>{{ index }}</td>
                <td class="green--text">{{ item[odds_format] }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
      </div>
    </v-dialog>

    <v-dialog
      v-if="cardOddsLoaded"
      v-model="cardOddsDialog"
      width="fit-content"
      style="z-index: 2000">
      <div class="d-flex flex-wrap justify-center text-center white">
        <div class="d-flex flex-wrap justify-center">
          <v-simple-table
            v-if="moreOdds['AHC']"
            class="table-row-35 border-lightgrey"
            style="border-radius: 0">
            <thead>
              <tr>
                <th>{{ $t('odds.handicap') }}</th>
                <th class="green--text">{{ basic.local_name }}</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in moreOdds['AHC'][0]" :key="index">
                <td>{{ index }}</td>
                <td class="green--text">{{ item[odds_format] }}</td>
              </tr>
            </tbody>
          </v-simple-table>

          <v-simple-table
            v-if="moreOdds['AHC']"
            class="table-row-35 border-lightgrey"
            style="border-radius: 0">
            <thead>
              <tr>
                <th>{{ $t('odds.handicap') }}</th>
                <th class="green--text">{{ basic.visitor_name }}</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in moreOdds['AHC'][1]" :key="index">
                <td>{{ index }}</td>
                <td class="green--text">{{ item[odds_format] }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>

        <div class="d-flex flex-wrap justify-center">
          <v-simple-table
            v-if="moreOdds['ATC']"
            class="table-row-35 border-lightgrey"
            style="border-radius: 0">
            <thead>
              <tr>
                <th>{{ $t('total') }}</th>
                <th class="green--text">{{ $t('over') }}</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in moreOdds['ATC'][0]" :key="index">
                <td>{{ index }}</td>
                <td class="green--text">{{ item[odds_format] }}</td>
              </tr>
            </tbody>
          </v-simple-table>

          <v-simple-table
            v-if="moreOdds['ATC']"
            class="table-row-35 border-lightgrey"
            style="border-radius: 0">
            <thead>
              <tr>
                <th>{{ $t('total') }}</th>
                <th class="green--text">{{ $t('under') }}</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in moreOdds['ATC'][1]" :key="index">
                <td>{{ index }}</td>
                <td class="green--text">{{ item[odds_format] }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
      </div>
    </v-dialog>

    <div>
      <v-row v-if="!basicLoaded" justify="center">
        <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
      </v-row>
      
      <template v-else>
        <div class="row no-gutters justify-center light-banner pt-50 background-cover">
          <div class="col-lg-10 col-md-11">
            <div class="p-2 d-flex justify-space-around">
              <div class="d-flex justify-center">
                <router-link :to="{name: 'team', params: {id: basic.local_id}}" class="d-flex flex-column align-center" :title="basic.local_name">
                  <div class="d-flex">
                    <img class='banner_logo1' :src="basic.local_logo" />

                    <div class="py-3 d-flex flex-column justify-space-between align-start">
                      <span
                        class="text-center font-weight-bold red white--text px-1"
                        :class="{'caption': $vuetify.breakpoint.xs}"
                        style="line-height: 22px; border-radius: 20%;"
                        :title="$t('position')">
                        {{ basic.local_pos }}
                      </span>
                      
                      <span
                        v-if="basic.form && $vuetify.breakpoint.smAndUp"
                        class="white--text p-1 font-weight-bold"
                        :class="basic.form.local_color"
                        style="border-radius: 3px;"
                        :title="`${$t('home')} ${$t('form')}`">
                        {{ basic.form.local }}
                      </span>
                    </div>
                  </div>

                  <div class="d-flex flex-column align-center">
                    <span
                      class="text-center font-weight-bold white--text"
                      :class="{'caption': $vuetify.breakpoint.xs, 'subtitle-1': $vuetify.breakpoint.smAndUp}"
                      style="text-shadow: 0px 0px 10px white;">
                      {{ basic.local_name }}
                    </span>
                    
                    <span
                      v-if="basic.form && $vuetify.breakpoint.xs"
                      class="white--text p-1 font-weight-bold caption"
                      :class="basic.form.local_color"
                      style="border-radius: 3px;"
                      :title="`${$t('home')} ${$t('form')}`">
                      {{ basic.form.local }}
                    </span>
                  </div>
                </router-link>
              </div>

              <div
                class="d-flex flex-column justify-center font-weight-bold"
                :class="{'caption': $vuetify.breakpoint.xs, 'subtitle-2': $vuetify.breakpoint.smAndUp}">
                <div class="align-center">
                  <router-link :to="{name: 'league', params: {lid: basic.lg_id}}">
                    <img class='league_logo' :src="basic.lg_logo" />
                  </router-link>
                </div>

                <div class="d-flex flex-wrap align-center red--text text--darken-4">
                  <router-link v-if="lg_country_id != '-'" :to="{name: 'country', params: {cid: lg_country_id}}">{{ lg_country_name }} -</router-link>
                  <router-link :to="{name: 'league', params: {lid: basic.lg_id}}">{{ basic.lg_name }}</router-link>
                  ( {{ basic.season }} )
                </div>

                <div v-if="basic.referee_id" class="d-flex align-center">
                  <v-icon
                    :class="{'subtitle-2': $vuetify.breakpoint.xs, 'subtitle-1': $vuetify.breakpoint.smAndUp}"
                    left
                    :title="$t('referee')">
                    mdi-whistle-outline
                  </v-icon>

                  <span
                    class="red--text text--darken-4 curPointer"
                    style="text-shadow: 1px 2px 2px white; text-decoration-line: underline;"
                    :title="$t('referee')"
                    @click.stop="modal4RefereeStats(basic.referee_id)">
                    {{ basic.referee_name }}
                  </span>
                </div>

                <div class="d-flex flex-column align-center">
                  <span
                    class="red--text text--darken-4"
                    style="text-shadow: 1px 2px 2px white;">
                    {{ basic.start_dt | moment('DD/MM/YYYY, HH:mm') }}
                  </span>

                  <span
                    v-if="basic.stadium"
                    class="red--text text--darken-4"
                    style="text-shadow: 1px 2px 2px white;"
                    :title="$t('stadium')">
                    {{ basic.stadium }}
                  </span>
                </div>
              </div>

              <div class="d-flex justify-center">
                <router-link :to="{name: 'team', params: {id: basic.visitor_id}}" class="d-flex flex-column align-center" :title="basic.visitor_name">
                  <div class="d-flex">
                    <div class="py-3 d-flex flex-column justify-space-between align-end">
                      <span
                        class="text-center font-weight-bold red white--text px-1"
                        :class="{'caption': $vuetify.breakpoint.xs}"
                        style="line-height: 22px; border-radius: 20%;"
                        :title="$t('position')">
                        {{ basic.visitor_pos }}
                      </span>

                      <span
                        v-if="basic.form && $vuetify.breakpoint.smAndUp"
                        class="white--text p-1 font-weight-bold"
                        :class="basic.form.visitor_color"
                        style="border-radius: 3px;"
                        :title="`${$t('away')} ${$t('form')}`">
                        {{ basic.form.visitor }}
                      </span>
                    </div>

                    <img class='banner_logo1' :src="basic.visitor_logo" />
                  </div>

                  <div class="d-flex flex-column align-center">
                    <span
                      class="text-center font-weight-bold white--text"
                      :class="{'caption': $vuetify.breakpoint.xs, 'subtitle-1': $vuetify.breakpoint.smAndUp}"
                      style="text-shadow: 0px 0px 10px white;">
                      {{ basic.visitor_name }}
                    </span>
                    
                    <span
                      v-if="basic.form && $vuetify.breakpoint.xs"
                      class="white--text p-1 font-weight-bold caption"
                      :class="basic.form.visitor_color"
                      style="border-radius: 3px;"
                      :title="`${$t('away')} ${$t('form')}`">
                      {{ basic.form.visitor }}
                    </span>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="pl-1 justify-center">
          <v-tabs
            v-model="tab"
            centered
            show-arrows
            light
          >
            <v-tab
              v-for="item in items"
              :key="item"
              @click="tab_onclick(item)"
            >
              <span :class="{'caption': $vuetify.breakpoint.xs}">
                {{ $t(item) }}
              </span>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item key="Goals">
              <v-row class="no-gutters" justify="center">
                <v-col lg="8" md="10">
                  <v-expansion-panels
                    v-if="goalOddsLoaded && show_odds_market"
                    v-model="expanding_market[0]"
                    hover
                    flat
                    tile>
                    <v-expansion-panel class="mb-2">
                      <v-expansion-panel-header class="grey lighten-2 grey--text text--darken-1">
                        {{ $t('odds.oddsmarket') }}
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>
                        <div class="grey lighten-3">
                          <div class="d-flex flex-wrap p-1">
                            <v-checkbox
                              :disabled="!(odds.FT || odds.HTR || odds.SHR)"
                              v-model="show_odds_market[0]"
                              color="blue"
                              class="mr-1"
                              hide-details
                              dense
                              :label="$t('odds.ft_result')"
                              />
                            <v-checkbox
                              :disabled="!(odds.cShHome || odds.cShAway || odds.btts)"
                              v-model="show_odds_market[1]"
                              color="blue"
                              class="mr-1"
                              hide-details
                              dense
                              :label="$t('odds.cleansheet')"
                              />
                            <v-checkbox
                              :disabled="!(odds.firstTS || odds.lastTS)"
                              v-model="show_odds_market[2]"
                              color="blue"
                              class="mr-1"
                              hide-details
                              dense
                              :label="$t('odds.flts')"
                              />
                            <v-checkbox
                              :disabled="!odds.AH"
                              v-model="show_odds_market[3]"
                              color="blue"
                              class="mr-1"
                              hide-details
                              dense
                              :label="$t('odds.asianhandicap')"
                              />
                            <v-checkbox
                              :disabled="!odds.OU"
                              v-model="show_odds_market[4]"
                              color="blue"
                              class="mr-1"
                              hide-details
                              dense
                              :label="$t('odds.t_ou')"
                              />
                          </div>

                          <div
                            class="d-flex flex-wrap justify-space-around"
                            :class="{'caption': $vuetify.breakpoint.xs}"
                            >
                            <div v-if="show_odds_market[0] && (odds.FT || odds.HTR || odds.SHR)">
                              <div class="text-center"><span>{{ $t('odds.ft_result') }}</span></div>

                              <v-simple-table
                                class="table-row-35 border-lightgrey text-center"
                                style="border-radius: 0; box-shadow: 1px 1px 3px lightgrey;">
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td :title="basic.local_name">1</td>
                                    <td :title="$t('odds.draw')">X</td>
                                    <td :title="basic.visitor_name">2</td>
                                  </tr>

                                  <tr v-if="odds.FT">
                                    <td :title="$t('fulltime')">{{ $t('odds.ft') }}</td>
                                    <td>{{ odds.FT[0].value }}</td>
                                    <td>{{ odds.FT[1].value }}</td>
                                    <td>{{ odds.FT[2].value }}</td>
                                  </tr>

                                  <tr v-if="odds.HTR">
                                    <td :title="$t('half1')">{{ $t('odds.half1') }}</td>
                                    <td>{{ odds.HTR[0].value }}</td>
                                    <td>{{ odds.HTR[1].value }}</td>
                                    <td>{{ odds.HTR[2].value }}</td>
                                  </tr>

                                  <tr v-if="odds.SHR">
                                    <td :title="$t('half2')">{{ $t('odds.half2') }}</td>
                                    <td>{{ odds.SHR[0].value }}</td>
                                    <td>{{ odds.SHR[1].value }}</td>
                                    <td>{{ odds.SHR[2].value }}</td>
                                  </tr>
                                </tbody>
                              </v-simple-table>
                            </div>

                            <div v-if="show_odds_market[1] && (odds.cShHome || odds.cShAway || odds.btts)">
                              <div class="text-center"><span>{{ $t('odds.cleansheet') }}</span></div>

                              <v-simple-table
                                class="table-row-35 border-lightgrey text-center"
                                style="border-radius: 0; box-shadow: 1px 1px 3px lightgrey;">
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td>{{ $t('yes') }}</td>
                                    <td>{{ $t('no') }}</td>
                                  </tr>

                                  <tr v-if="odds.cShHome">
                                    <td :title="`${basic.local_name} ${$t('odds.cleansheet')}`">1</td>
                                    <td>{{ odds.cShHome[0].value }}</td>
                                    <td>{{ odds.cShHome[1].value }}</td>
                                  </tr>

                                  <tr v-if="odds.cShAway">
                                    <td :title="`${basic.visitor_name} ${$t('odds.cleansheet')}`">2</td>
                                    <td>{{ odds.cShAway[0].value }}</td>
                                    <td>{{ odds.cShAway[1].value }}</td>
                                  </tr>

                                  <tr v-if="odds.btts">
                                    <td :title="$t('odds.btts.tooltip')">{{ $t('odds.btts.label') }}</td>
                                    <td>{{ odds.btts[0].value }}</td>
                                    <td>{{ odds.btts[1].value }}</td>
                                  </tr>
                                </tbody>
                              </v-simple-table>
                            </div>
                            
                            <div v-if="show_odds_market[2] && (odds.firstTS || odds.lastTS)">
                              <div class="text-center"><span>{{ $t('odds.flts') }}</span></div>

                              <v-simple-table
                                class="table-row-35 border-lightgrey text-center"
                                style="border-radius: 0; box-shadow: 1px 1px 3px lightgrey;">
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td :title="basic.local_name">1</td>
                                    <td>{{ $t('nogoal') }}</td>
                                    <td :title="basic.visitor_name">2</td>
                                  </tr>

                                  <tr v-if="odds.firstTS">
                                    <td :title="$t('odds.ftts')">{{ $t('first') }}</td>
                                    <td :title="$t('odds.ftts')">{{ odds.firstTS[0].value }}</td>
                                    <td :title="$t('odds.ftts')">{{ odds.firstTS[1].value }}</td>
                                    <td :title="$t('odds.ftts')">{{ odds.firstTS[2].value }}</td>
                                  </tr>

                                  <tr v-if="odds.lastTS">
                                    <td :title="$t('odds.ltts')">{{ $t('last') }}</td>
                                    <td :title="$t('odds.ltts')">{{ odds.lastTS[0].value }}</td>
                                    <td :title="$t('odds.ltts')">{{ odds.lastTS[1].value }}</td>
                                    <td :title="$t('odds.ltts')">{{ odds.lastTS[2].value }}</td>
                                  </tr>
                                </tbody>
                              </v-simple-table>
                            </div>

                            <div v-if="show_odds_market[3] && odds.AH">
                              <div class="text-center"><span>{{ $t('odds.asianhandicap') }}</span></div>

                              <v-simple-table
                                class="table-row-35 border-lightgrey text-center"
                                style="border-radius: 0; box-shadow: 1px 1px 3px lightgrey;">
                                <tbody>
                                  <tr>
                                    <td>{{ basic.local_name }}</td>
                                    <td>{{ basic.visitor_name }}</td>
                                    <td v-if="(Object.keys(moreOdds['AH'][0]).length + Object.keys(moreOdds['AH'][1]).length) > 2">
                                      {{ $t('more') }}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>{{ moreOdds['maxAH'][0] }} : {{ moreOdds['maxAH'][1][odds_format] }}</td>
                                    <td>{{ moreOdds['maxAH'][2] }} : {{ moreOdds['maxAH'][3][odds_format] }}</td>
                                    <td v-if="(Object.keys(moreOdds['AH'][0]).length + Object.keys(moreOdds['AH'][1]).length) > 2"
                                      class="curPointer"
                                      @click="goalOddsModal()" >
                                      <v-icon color="success">mdi-dice-5</v-icon>
                                    </td>
                                  </tr>
                                </tbody>
                              </v-simple-table>
                            </div>

                            <div v-if="show_odds_market[4] && odds.OU">
                              <div class="text-center"><span>{{ $t('odds.t_ou') }}</span></div>

                              <v-simple-table
                                class="table-row-35 border-lightgrey text-center"
                                style="border-radius: 0; box-shadow: 1px 1px 3px lightgrey;">
                                <tbody>
                                  <tr>
                                    <td>{{ $t('total') }}</td>
                                    <td>{{ $t('over') }}</td>
                                    <td>{{ $t('under') }}</td>
                                    <td v-if="(Object.keys(moreOdds['OU'][0]).length + Object.keys(moreOdds['OU'][1]).length) > 2">
                                      {{ $t('more') }}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>{{ moreOdds['maxOU'] }}</td>
                                    <td>{{ moreOdds['OU'][0][moreOdds['maxOU']][odds_format] }}</td>
                                    <td>{{ moreOdds['OU'][1][moreOdds['maxOU']][odds_format] }}</td>
                                    <td v-if="(Object.keys(moreOdds['OU'][0]).length + Object.keys(moreOdds['OU'][1]).length) > 2"
                                      class="curPointer"
                                      @click="goalOddsModal()" >
                                      <v-icon color="success">mdi-dice-5</v-icon>
                                    </td>
                                  </tr>
                                </tbody>
                              </v-simple-table>
                            </div>
                          </div>
                        </div>      
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>

                  <div class="game-nav">
                    <div class="game-nav__btn" :class="{'game-nav__btn_active' : flags.goal==0}" @click="goal_flag(0)">{{ $t('head2head') }}</div>
                    <div class="game-nav__btn" :class="{'game-nav__btn_active' : flags.goal==1}" @click="goal_flag(1)">{{ $t('lastmatches') }}</div>
                    <div class="game-nav__btn" :class="{'game-nav__btn_active' : flags.goal==2}" @click="goal_flag(2)" :title="$t('message.similar3way')">{{ $t('similarmatches') }}</div>
                  </div>

                  <div v-if="!flags.goal" class="mt-4">
                    <div v-if="!h2hLoaded" >
                      <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                    </div>

                    <div v-else>
                      <v-container fluid>
                        <div class="row d-flex">
                          <v-select
                            v-model="h2hChosenLeagues"
                            :items="h2hLeagues"
                            item-text="league_name"
                            item-value="league_id"
                            class="col-12 col-sm-7 col-lg-9"
                            attach
                            dense
                            :label="$t('tournament')"
                            hide-details
                            multiple
                            />
                          <v-select
                            v-model="h2hChosenSeasons"
                            :items="h2hSeasons"
                            :label="$t('season')"
                            class="col-12 col-sm-5 col-lg-3"
                            attach
                            dense
                            hide-details
                            multiple
                            />
                        </div>

                        <div class="row d-flex flex-wrap justify-space-between mt-4">
                          <v-select
                            v-model="h2hFullHalf"
                            :items="timeCategories"
                            :item-text="item => $t(item.text)"
                            item-value="index"
                            :label="$t('time')"
                            class="col-5 col-sm-3 col-md-2"
                            attach
                            dense
                            hide-details
                          />

                          <v-checkbox
                            v-model="h2hLocal"
                            color="blue"
                            hide-details
                            dense
                            :label="$t('home')"
                            />
                          
                          <v-checkbox
                            v-model="h2hVisitor"
                            color="blue"
                            hide-details
                            dense
                            :label="$t('away')"
                            />

                          <template v-if="basic.local_coach && basic.visitor_coach">
                            <v-checkbox
                              v-model="h2hCurrentCoach"
                              color="blue"
                              hide-details
                              dense
                              :label="`${$t('currentcoach')}(${h2h_coach_count})`"
                              />
                            <v-checkbox
                              v-model="h2hPrevCoach"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('previouscoach')"
                              />
                          </template>

                          <v-select
                            v-model="h2hPerPage"
                            :items="h2hCountPerPage"
                            attach
                            dense
                            class="col-4 col-sm-2 col-md-1"
                            :hint="$t('countperpage')"
                          />

                          <v-text-field
                            v-model="h2hPPageByUser"
                            class="col-3 col-sm-2 col-md-1"
                            dense
                            :hint="$t('countperpage')"
                            type="number"
                            max=149
                            min=1
                            v-on:keyup.enter="h2hPerPageChange"
                          />
                        </div>
                      </v-container>

                      <v-simple-table
                        v-if="goalData.matches"
                        class="table-row-35 border-lightgrey text-center"
                        style="border-radius: 0">
                        <tbody>
                          <tr>
                            <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                            <td :title="$t('pts.tooltip')">{{ $t('pts.label') }}</td>
                            <td :title="$t('w.tooltip')">{{ $t('w.label') }}</td>
                            <td :title="$t('d.tooltip')">{{ $t('d.label') }}</td>
                            <td :title="$t('l.tooltip')">{{ $t('l.label') }}</td>
                            <td :title="$t('diff.tooltip')">{{ $t('diff.label') }}</td>
                            <td :title="$t('diff_av.tooltip')">{{ $t('diff_av.label') }}</td>
                            <td :title="$t('total_av.tooltip')">{{ $t('total_av.label') }}</td>
                            <td :title="$t('teamtotal_av.tooltip')">{{ basic.local_name }}</td>
                            <td :title="$t('opponenttotal_av.tooltip')"> {{ basic.visitor_name }}</td>
                          </tr>

                          <tr class="font-weight-bold">
                            <td>{{ goalData.matches }}</td>
                            <td>{{ goalData.points }}</td>
                            <td>{{ goalData.win }}</td>
                            <td>{{ goalData.draw }}</td>
                            <td>{{ goalData.lost }}</td>
                            <td>{{ goalData.diff }}</td>
                            <td>{{ goalData.diff_av }}</td>
                            <td>{{ goalData.totalAvg }}</td>
                            <td>{{ goalData.homeTotalAvg }}</td>
                            <td>{{ goalData.awayTotalAvg }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>

                      <div class="curPointer pt-5 pb-2">
                        <a :class="{'d-none' : h2hPage == h2hLastPage}" class="ml-1 mr-4" @click="h2hPrev()">{{ $t('previous') }}</a>
                        <a :class="{'d-none' : !h2hPage}" @click="h2hNext()">{{ $t('next') }}</a>
                      </div>

                      <v-simple-table class="table-row-35 cell-py-10 border-lightgrey" style="border-radius: 0">
                        <thead>
                          <tr>
                            <th>{{ $t('date') }}</th>
                            <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                            <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                            <th>{{ $t('home') }}</th>
                            <th></th>
                            <th class="text-right">{{ $t('away') }}</th>
                            <th class="text-center">{{ $t('detail') }}</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="game in CGC[h2hPage]"
                            :key="game.index"
                            class="blue-grey--text"
                            :class="{'curPointer': (game.local_goals != 0 || game.visitor_goals != 0)}"
                            @click="goalModal(game.index, game.local_id, game.local_name, game.visitor_name, 0)">
                            <td class="overflow-anywhere">
                              <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                              <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                            </td>

                            <td :class="{'d-none': $vuetify.breakpoint.xs}" class="overflow-anywhere">
                              <router-link :to="{name: 'league', params: {lid: game.league_id}}" class="blue-grey--text">
                                {{ game.league_name }}
                              </router-link>
                            </td>

                            <td :class="{'d-none': $vuetify.breakpoint.xs}">{{ game.round_name ? game.round_name : '' }}</td>

                            <td class="overflow-anywhere">{{ game.local_name }}</td>

                            <td
                              class="white--text"
                              :style="'background-color:' + game.goals_bgcolor">
                              <div class="d-flex justify-space-around align-center subtitle-2">
                                <span>{{ game.local_goals }}</span>
                                <span> - </span>
                                <span>{{ game.visitor_goals }}</span>
                              </div>
                            </td>

                            <td class="overflow-anywhere text-right">{{ game.visitor_name }}</td>

                            <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </div>
                  </div>

                  <template v-else-if="flags.goal==1">
                    <div v-if="!lastHomeLoaded && !lastAwayLoaded">
                      <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                    </div>

                    <div v-else-if="!authorization">
                      <v-alert
                        type="warning"
                        prominent
                      >
                        {{ $t(`message.${msg_last}`) }}
                      </v-alert>
                    </div>

                    <template v-else>
                      <div class="matches_heading">{{ basic.local_name }} {{ $t('lastmatches') }}</div>

                      <div>
                        <v-container fluid>
                          <div v-if='homeLeagueLoaded' class="row d-flex">
                            <v-select
                              v-model="homeChosenLeagues"
                              :items="HLeagues"
                              item-text="league_name"
                              item-value="league_id"
                              class="col-12 col-sm-7 col-lg-9"
                              attach
                              dense
                              :label="$t('tournament')"
                              hide-details
                              multiple
                              />
                            
                            <v-select
                              v-model="homeChosenSeasons"
                              :items="HSeasons"
                              :label="$t('season')"
                              class="col-12 col-sm-5 col-lg-3"
                              attach
                              dense
                              hide-details
                              multiple
                            />
                          </div>

                          <div class="row d-flex flex-wrap justify-space-between mt-4">
                            <v-select
                              v-model="hLastFullHalf"
                              :items="timeCategories"
                              :item-text="item => $t(item.text)"
                              item-value="index"
                              :label="$t('time')"
                              class="col-5 col-sm-3 col-md-2"
                              attach
                              dense
                              hide-details
                            />

                            <v-checkbox
                              v-model="hLocal"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('home')"
                              />
                            
                            <v-checkbox
                              v-model="hVisitor"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('away')"
                              />

                            <template v-if="basic.local_coach">
                              <v-checkbox
                                v-model="hCurrentCoach"
                                color="blue"
                                hide-details
                                dense
                                :label="`${$t('currentcoach')}(${local_coach_count})`"
                                />
                              <v-checkbox
                                v-model="hPrevCoach"
                                color="blue"
                                hide-details
                                dense
                                :label="$t('previouscoach')"
                                />
                            </template>

                            <v-select
                              v-model="homePerPage"
                              :items="countPerPage"
                              attach
                              dense
                              class="col-4 col-sm-2 col-md-1"
                              :hint="$t('countperpage')"
                            />

                            <v-text-field
                              v-model="hPPageByUser"
                              class="col-3 col-sm-2 col-md-1"
                              dense
                              :hint="$t('countperpage')"
                              type="number"
                              max=149
                              min=1
                              v-on:keyup.enter="homePerPageChange"
                            />
                          </div>
                        </v-container>

                        <template v-if="lastHomeLoaded">
                        <v-simple-table
                          v-if="hLastAvgLoaded && hLastAvg.goalMatch"
                          class="table-row-35 border-lightgrey text-center"
                          style="border-radius: 0">
                          <tbody>
                            <tr>
                              <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                              <td :title="$t('pts.tooltip')">{{ $t('pts.label') }}</td>
                              <td :title="$t('w.tooltip')">{{ $t('w.label') }}</td>
                              <td :title="$t('d.tooltip')">{{ $t('d.label') }}</td>
                              <td :title="$t('l.tooltip')">{{ $t('l.label') }}</td>
                              <td :title="$t('diff.tooltip')">{{ $t('diff.label') }}</td>
                              <td :title="$t('diff_av.tooltip')">{{ $t('diff_av.label') }}</td>
                              <td :title="$t('total_av.tooltip')">{{ $t('total_av.label') }}</td>
                              <td :title="$t('teamtotal_av.tooltip')">{{ basic.local_name }}</td>
                              <td :title="$t('opponenttotal_av.tooltip')"> {{ $t('opponenttotal_av.label') }}</td>
                            </tr>

                            <tr class="font-weight-bold">
                              <td>{{ hLastAvg.goalMatch }}</td>
                              <td>{{ hLastAvg.goalPoint }}</td>
                              <td>{{ hLastAvg.goalWDL[0] }}</td>
                              <td>{{ hLastAvg.goalWDL[1] }}</td>
                              <td>{{ hLastAvg.goalWDL[2] }}</td>
                              <td>{{ hLastAvg.goalDiff }}</td>
                              <td>{{ hLastAvg.goalDiff_avg }}</td>
                              <td>{{ hLastAvg.goalTotal }}</td>
                              <td>{{ hLastAvg.goalT1 }}</td>
                              <td>{{ hLastAvg.goalT2 }}</td>
                            </tr>
                          </tbody>
                        </v-simple-table>

                        <div class="curPointer pt-5 pb-2">
                          <a :class="{'d-none' : homePage == hLastPage}" class="ml-1 mr-4" @click="homePrev()">{{ $t('previous') }}</a>
                          <a :class="{'d-none' : !homePage}" @click="homeNext()">{{ $t('next') }}</a>
                        </div>

                        <v-simple-table class="table-row-35 cell-py-10 border-lightgrey" style="border-radius: 0">
                          <thead>
                            <tr>
                              <th>{{ $t('date') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                              <th>{{ $t('home') }}</th>
                              <th></th>
                              <th class="text-right">{{ $t('away') }}</th>
                              <th class="text-center">{{ $t('detail') }}</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-for="(game, index) in Hlast[homePage]"
                              :key="index"
                              :class="{'curPointer': (game.local_score != 0 || game.visitor_score != 0)}"
                              @click="goalModal(index, game.local_id, game.local_name, game.visitor_name, 1)">
                              <td class="overflow-anywhere blue-grey--text">
                                <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                                <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="overflow-anywhere">
                                <router-link :to="{name: 'league', params: {lid: game.league.league_id}}" class="blue-grey--text">
                                  {{ game.league.name }}
                                </router-link>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="blue-grey--text">
                                {{ game.round ? game.round.name : '' }}
                              </td>

                              <td v-if="game.local_id != basic.local_id">
                                <router-link :to="{name: 'team', params: {id: game.local_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.local_name }}
                                </router-link>
                              </td>

                              <td v-else class="overflow-anywhere black--text">{{ game.local_name }}</td>

                              <td
                                class="white--text"
                                :style="'background-color:' + stats_color[game.goal_color]">
                                <div class="d-flex justify-space-around align-center subtitle-2">
                                  <span>{{ game.local_score }}</span>
                                  <span> - </span>
                                  <span>{{ game.visitor_score }}</span>
                                </div>
                              </td>

                              <td v-if="game.visitor_id != basic.local_id" class="text-right">
                                <router-link :to="{name: 'team', params: {id: game.visitor_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.visitor_name }}
                                </router-link>
                              </td>

                              <td v-else class="overflow-anywhere text-right black--text">{{ game.visitor_name }}</td>

                              <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                        </template>

                        <div v-else>
                          <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                        </div>
                      </div>

                      <div class="matches_heading">{{ basic.visitor_name }} {{ $t('lastmatches') }}</div>

                      <div>
                        <v-container fluid>
                          <div v-if='awayLeagueLoaded' class="row d-flex">
                            <v-select
                              v-model="awayChosenLeagues"
                              :items="ALeagues"
                              item-text="league_name"
                              item-value="league_id"
                              class="col-12 col-sm-7 col-lg-9"
                              attach
                              dense
                              :label="$t('tournament')"
                              hide-details
                              multiple
                              />

                            <v-select
                              v-model="awayChosenSeasons"
                              :items="ASeasons"
                              :label="$t('season')"
                              class="col-12 col-sm-5 col-lg-3"
                              attach
                              dense
                              hide-details
                              multiple
                            />
                          </div>

                          <div class="row d-flex flex-wrap justify-space-between mt-4">
                            <v-select
                              v-model="aLastFullHalf"
                              :items="timeCategories"
                              :item-text="item => $t(item.text)"
                              item-value="index"
                              :label="$t('time')"
                              class="col-5 col-sm-3 col-md-2"
                              attach
                              dense
                              hide-details
                            />

                            <v-checkbox
                              v-model="aLocal"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('home')"
                              />
                            
                            <v-checkbox
                              v-model="aVisitor"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('away')"
                              />

                            <template v-if="basic.visitor_coach">
                              <v-checkbox
                                v-model="aCurrentCoach"
                                color="blue"
                                hide-details
                                dense
                                :label="`${$t('currentcoach')}(${visitor_coach_count})`"
                                />
                              <v-checkbox
                                v-model="aPrevCoach"
                                color="blue"
                                hide-details
                                dense
                                :label="$t('previouscoach')"
                                />
                            </template>

                            <v-select
                              v-model="awayPerPage"
                              :items="countPerPage"
                              attach
                              dense
                              class="col-4 col-sm-2 col-md-1"
                              :hint="$t('countperpage')"
                            />

                            <v-text-field
                              v-model="aPPageByUser"
                              class="col-3 col-sm-2 col-md-1"
                              dense
                              :hint="$t('countperpage')"
                              type="number"
                              max=149
                              min=1
                              v-on:keyup.enter="awayPerPageChange"
                            />
                          </div>
                        </v-container>

                        <template v-if="lastAwayLoaded">
                        <v-simple-table
                          v-if="aLastAvgLoaded && aLastAvg.goalMatch"
                          class="table-row-35 border-lightgrey text-center"
                          style="border-radius: 0">
                          <tbody>
                            <tr>
                              <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                              <td :title="$t('pts.tooltip')">{{ $t('pts.label') }}</td>
                              <td :title="$t('w.tooltip')">{{ $t('w.label') }}</td>
                              <td :title="$t('d.tooltip')">{{ $t('d.label') }}</td>
                              <td :title="$t('l.tooltip')">{{ $t('l.label') }}</td>
                              <td :title="$t('diff.tooltip')">{{ $t('diff.label') }}</td>
                              <td :title="$t('diff_av.tooltip')">{{ $t('diff_av.label') }}</td>
                              <td :title="$t('total_av.tooltip')">{{ $t('total_av.label') }}</td>
                              <td :title="$t('teamtotal_av.tooltip')">{{ basic.visitor_name }}</td>
                              <td :title="$t('opponenttotal_av.tooltip')"> {{ $t('opponenttotal_av.label') }}</td>
                            </tr>
                            
                            <tr class="font-weight-bold">
                              <td>{{ aLastAvg.goalMatch }}</td>
                              <td>{{ aLastAvg.goalPoint }}</td>
                              <td>{{ aLastAvg.goalWDL[0] }}</td>
                              <td>{{ aLastAvg.goalWDL[1] }}</td>
                              <td>{{ aLastAvg.goalWDL[2] }}</td>
                              <td>{{ aLastAvg.goalDiff }}</td>
                              <td>{{ aLastAvg.goalDiff_avg }}</td>
                              <td>{{ aLastAvg.goalTotal }}</td>
                              <td>{{ aLastAvg.goalT1 }}</td>
                              <td>{{ aLastAvg.goalT2 }}</td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                        
                        <div class="curPointer pt-5 pb-2">
                          <a :class="{'d-none' : awayPage == aLastPage}" class="ml-1 mr-4" @click="awayPrev()">{{ $t('previous') }}</a>
                          <a :class="{'d-none' : !awayPage}" @click="awayNext()">{{ $t('next') }}</a>
                        </div>

                        <v-simple-table class="table-row-35 cell-py-10 border-lightgrey" style="border-radius: 0">
                          <thead>
                            <tr>
                              <th>{{ $t('date') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                              <th>{{ $t('home') }}</th>
                              <th></th>
                              <th class="text-right">{{ $t('away') }}</th>
                              <th class="text-center">{{ $t('detail') }}</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-for="(game, index) in Alast[awayPage]"
                              :key="index"
                              :class="{'curPointer': (game.local_score != 0 || game.visitor_score != 0)}"
                              @click="goalModal(index, game.local_id, game.local_name, game.visitor_name, 2)" >
                              <td class="overflow-anywhere blue-grey--text">
                                <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                                <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}">
                                <router-link :to="{name: 'league', params: {lid: game.league.league_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.league.name }}
                                </router-link>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="blue-grey--text">
                                {{ game.round ? game.round.name : '' }}
                              </td>

                              <td v-if="game.local_id != basic.visitor_id">
                                <router-link :to="{name: 'team', params: {id: game.local_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.local_name }}
                                </router-link>
                              </td>

                              <td v-else class="overflow-anywhere black--text">{{ game.local_name }}</td>

                              <td
                                class="white--text"
                                :style="'background-color:' + stats_color[game.goal_color]">
                                <div class="d-flex justify-space-around align-center subtitle-2">
                                  <span>{{ game.local_score }}</span>
                                  <span> - </span>
                                  <span>{{ game.visitor_score }}</span>
                                </div>
                              </td>

                              <td v-if="game.visitor_id != basic.visitor_id" class="text-right">
                                <router-link :to="{name: 'team', params: {id: game.visitor_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.visitor_name }}
                                </router-link>
                              </td>

                              <td v-else class="overflow-anywhere text-right black--text">{{ game.visitor_name }}</td>

                              <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                        </template>

                        <div v-else>
                          <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                        </div>
                      </div>
                    </template>
                  </template>

                  <template v-else>
                    <div v-if="!odds.FT">
                      <v-alert
                        type="info"
                        prominent
                      >
                        {{ $t('message.impossibleSM') }}
                      </v-alert>
                    </div>

                    <div v-else-if="!similarHomeLoaded && !similarAwayLoaded">
                      <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                    </div>

                    <div v-else-if="!authorization">
                      <v-alert
                        type="warning"
                        prominent
                      >
                        {{ $t(`message.${msg_last}`) }}
                      </v-alert>
                    </div>

                    <template v-else>
                      <div class="matches_heading">{{ basic.local_name }} {{ $t('similarmatches') }}</div>

                      <div>
                        <v-container fluid>
                          <div v-if='homeLeagueLoaded' class="row d-flex">
                            <v-select
                              v-model="hSChosenLeagues"
                              :items="HLeagues"
                              item-text="league_name"
                              item-value="league_id"
                              class="col-12 col-sm-7 col-lg-9"
                              attach
                              dense
                              :label="$t('tournament')"
                              hide-details
                              multiple
                            ></v-select>

                            <v-select
                              v-model="hSChosenSeasons"
                              :items="HSeasons"
                              :label="$t('season')"
                              class="col-12 col-sm-5 col-lg-3"
                              attach
                              dense
                              hide-details
                              multiple
                            />
                          </div>

                          <div class="row d-flex flex-wrap justify-space-between mt-4">
                            <v-select
                              v-model="hSimilarFullHalf"
                              :items="timeCategories"
                              :item-text="item => $t(item.text)"
                              item-value="index"
                              :label="$t('time')"
                              class="col-5 col-sm-3 col-md-2"
                              attach
                              dense
                              hide-details
                            />

                            <v-checkbox
                              v-model="hSLocal"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('home')"
                              />
                            
                            <v-checkbox
                              v-model="hSVisitor"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('away')"
                              />
                            
                            <template v-if="basic.local_coach">
                              <v-checkbox
                                v-model="hSCurrentCoach"
                                color="blue"
                                hide-details
                                dense
                                :label="`${$t('currentcoach')}(${ls_coach_count})`"
                                />
                              <v-checkbox
                                v-model="hSPrevCoach"
                                color="blue"
                                hide-details
                                dense
                                :label="$t('previouscoach')"
                                />
                            </template>

                            <v-select
                              v-model="hSPerPage"
                              :items="countPerPage"
                              attach
                              dense
                              class="col-4 col-sm-2 col-md-1"
                              :hint="$t('countperpage')"
                            />

                            <v-text-field
                              v-model="hSPPageByUser"
                              class="col-3 col-sm-2 col-md-1"
                              dense
                              :hint="$t('countperpage')"
                              type="number"
                              max=149
                              min=1
                              v-on:keyup.enter="hSPerPageChange"
                            />
                          </div>
                        </v-container>

                        <template v-if="similarHomeLoaded">
                        <v-simple-table
                          v-if="hSimilarAvgLoaded && hSimilarAvg.goalMatch"
                          class="table-row-35 border-lightgrey text-center"
                          style="border-radius: 0">
                          <tbody>
                            <tr>
                              <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                              <td :title="$t('pts.tooltip')">{{ $t('pts.label') }}</td>
                              <td :title="$t('w.tooltip')">{{ $t('w.label') }}</td>
                              <td :title="$t('d.tooltip')">{{ $t('d.label') }}</td>
                              <td :title="$t('l.tooltip')">{{ $t('l.label') }}</td>
                              <td :title="$t('diff.tooltip')">{{ $t('diff.label') }}</td>
                              <td :title="$t('diff_av.tooltip')">{{ $t('diff_av.label') }}</td>
                              <td :title="$t('total_av.tooltip')">{{ $t('total_av.label') }}</td>
                              <td :title="$t('teamtotal_av.tooltip')">{{ basic.local_name }}</td>
                              <td :title="$t('opponenttotal_av.tooltip')"> {{ $t('opponenttotal_av.label') }}</td>
                            </tr>
                            
                            <tr class="font-weight-bold">
                              <td>{{ hSimilarAvg.goalMatch }}</td>
                              <td>{{ hSimilarAvg.goalPoint }}</td>
                              <td>{{ hSimilarAvg.goalWDL[0] }}</td>
                              <td>{{ hSimilarAvg.goalWDL[1] }}</td>
                              <td>{{ hSimilarAvg.goalWDL[2] }}</td>
                              <td>{{ hSimilarAvg.goalDiff }}</td>
                              <td>{{ hSimilarAvg.goalDiff_avg }}</td>
                              <td>{{ hSimilarAvg.goalTotal }}</td>
                              <td>{{ hSimilarAvg.goalT1 }}</td>
                              <td>{{ hSimilarAvg.goalT2 }}</td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                        
                        <div class="curPointer pt-5 pb-2">
                          <a :class="{'d-none' : hSPage == hSLastPage}" class="ml-1 mr-4" @click="hSPrev()">{{ $t('previous') }}</a>
                          <a :class="{'d-none' : !hSPage}" @click="hSNext()">{{ $t('next') }}</a>
                        </div>

                        <v-simple-table class="table-row-35 cell-py-10 border-lightgrey" style="border-radius: 0">
                          <thead>
                            <tr>
                              <th>{{ $t('date') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                              <th>
                                {{ $t('home') }}
                                <template v-if="$vuetify.breakpoint.xs">({{ $t('w.label') }})</template>
                              </th>

                              <th class="text-center">
                                <template v-if="$vuetify.breakpoint.xs">({{ $t('d.label') }})</template>
                              </th>

                              <th class="text-right">
                                {{ $t('away') }}
                                <template v-if="$vuetify.breakpoint.xs">({{ $t('l.label') }})</template>
                              </th>

                              <th :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center">{{ $t('w.label') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center">{{ $t('d.label') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center">{{ $t('l.label') }}</th>
                              <th class="text-center">{{ $t('detail') }}</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr
                              v-for="(game, index) in hSimilar[hSPage]"
                              :key="index"
                              :class="{'curPointer': (game.local_score != 0 || game.visitor_score != 0)}"
                              @click="goalModal(index, game.local_id, game.local_name, game.visitor_name, 3)" >
                              <td class="overflow-anywhere blue-grey--text">
                                <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                                <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}">
                                <router-link :to="{name: 'league', params: {lid: game.league.league_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.league.name }}
                                </router-link>
                              </td>
                              
                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="blue-grey--text">
                                {{ game.round ? game.round.name : '' }}
                              </td>

                              <td class="overflow-anywhere">
                                <template v-if="game.local_id != basic.local_id">
                                  <router-link :to="{name: 'team', params: {id: game.local_id}}" class="blue-grey--text">
                                    {{ game.local_name }}
                                  </router-link>
                                </template>

                                <span v-else class="black--text">{{ game.local_name }}</span>

                                <span v-if="$vuetify.breakpoint.xs"> ({{ game.three_way[1] }})</span>
                              </td>

                              <td class="white--text text-center" :style="'background-color:' + stats_color[game.goal_color]">
                                <div class="d-flex justify-space-around align-center subtitle-2">
                                  <span>{{ game.local_score }}</span>
                                  <span> - </span>
                                  <span>{{ game.visitor_score }}</span>
                                </div>
                                
                                <span v-if="$vuetify.breakpoint.xs">({{ game.three_way.X }})</span>
                              </td>

                              <td class="overflow-anywhere text-right">
                                <template v-if="game.visitor_id != basic.local_id">
                                  <router-link :to="{name: 'team', params: {id: game.visitor_id}}" class="blue-grey--text">
                                    {{ game.visitor_name }}
                                  </router-link>
                                </template>

                                <span v-else class="black--text">{{ game.visitor_name }}</span>

                                <span v-if="$vuetify.breakpoint.xs"> ({{ game.three_way[2] }})</span>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center blue-grey--text">{{ game.three_way[1] }}</td>
                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center blue-grey--text">{{ game.three_way.X }}</td>
                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center blue-grey--text">{{ game.three_way[2] }}</td>
                              
                              <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                        </template>

                        <div v-else>
                          <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                        </div>
                      </div>

                      <div class="matches_heading">{{ basic.visitor_name }} {{ $t('similarmatches') }}</div>

                      <div>
                        <v-container fluid>
                          <div v-if='awayLeagueLoaded' class="row d-flex">
                            <v-select
                              v-model="aSChosenLeagues"
                              :items="ALeagues"
                              item-text="league_name"
                              item-value="league_id"
                              class="col-12 col-sm-7 col-lg-9"
                              attach
                              dense
                              :label="$t('tournament')"
                              hide-details
                              multiple
                            />

                            <v-select
                              v-model="aSChosenSeasons"
                              :items="ASeasons"
                              :label="$t('season')"
                              class="col-12 col-sm-5 col-lg-3"
                              attach
                              dense
                              hide-details
                              multiple
                            />
                          </div>

                          <div class="row d-flex flex-wrap justify-space-between mt-4">
                            <v-select
                              v-model="aSimilarFullHalf"
                              :items="timeCategories"
                              :item-text="item => $t(item.text)"
                              item-value="index"
                              :label="$t('time')"
                              class="col-5 col-sm-3 col-md-2"
                              attach
                              dense
                              hide-details
                            />

                            <v-checkbox
                              v-model="aSLocal"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('home')"
                              />
                            
                            <v-checkbox
                              v-model="aSVisitor"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('away')"
                              />

                            <template v-if="basic.visitor_coach">
                              <v-checkbox
                                v-model="aSCurrentCoach"
                                color="blue"
                                hide-details
                                dense
                                :label="`${$t('currentcoach')}(${vs_coach_count})`"
                                />
                              <v-checkbox
                                v-model="aSPrevCoach"
                                color="blue"
                                hide-details
                                dense
                                :label="$t('previouscoach')"
                                />
                            </template>

                            <v-select
                              v-model="aSPerPage"
                              :items="countPerPage"
                              attach
                              dense
                              class="col-4 col-sm-2 col-md-1"
                              :hint="$t('countperpage')"
                            />

                            <v-text-field
                              v-model="aSPPageByUser"
                              class="col-3 col-sm-2 col-md-1"
                              dense
                              :hint="$t('countperpage')"
                              type="number"
                              max=149
                              min=1
                              v-on:keyup.enter="aSPerPageChange"
                            />
                          </div>
                        </v-container>

                        <template v-if="similarAwayLoaded">
                        <v-simple-table
                          v-if="aSimilarAvgLoaded && aSimilarAvg.goalMatch"
                          class="table-row-35 border-lightgrey text-center"
                          style="border-radius: 0">
                          <tbody>
                            <tr>
                              <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                              <td :title="$t('pts.tooltip')">{{ $t('pts.label') }}</td>
                              <td :title="$t('w.tooltip')">{{ $t('w.label') }}</td>
                              <td :title="$t('d.tooltip')">{{ $t('d.label') }}</td>
                              <td :title="$t('l.tooltip')">{{ $t('l.label') }}</td>
                              <td :title="$t('diff.tooltip')">{{ $t('diff.label') }}</td>
                              <td :title="$t('diff_av.tooltip')">{{ $t('diff_av.label') }}</td>
                              <td :title="$t('total_av.tooltip')">{{ $t('total_av.label') }}</td>
                              <td :title="$t('teamtotal_av.tooltip')">{{ basic.visitor_name }}</td>
                              <td :title="$t('opponenttotal_av.tooltip')"> {{ $t('opponenttotal_av.label') }}</td>
                            </tr>
                            
                            <tr class="font-weight-bold">
                              <td>{{ aSimilarAvg.goalMatch }}</td>
                              <td>{{ aSimilarAvg.goalPoint }}</td>
                              <td>{{ aSimilarAvg.goalWDL[0] }}</td>
                              <td>{{ aSimilarAvg.goalWDL[1] }}</td>
                              <td>{{ aSimilarAvg.goalWDL[2] }}</td>
                              <td>{{ aSimilarAvg.goalDiff }}</td>
                              <td>{{ aSimilarAvg.goalDiff_avg }}</td>
                              <td>{{ aSimilarAvg.goalTotal }}</td>
                              <td>{{ aSimilarAvg.goalT1 }}</td>
                              <td>{{ aSimilarAvg.goalT2 }}</td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                        
                        <div class="curPointer pt-5 pb-2">
                          <a :class="{'d-none' : aSPage == aSLastPage}" class="ml-1 mr-4" @click="aSPrev()">{{ $t('previous') }}</a>
                          <a :class="{'d-none' : !aSPage}" @click="aSNext()">{{ $t('next') }}</a>
                        </div>

                        <v-simple-table class="table-row-35 cell-py-10 border-lightgrey" style="border-radius: 0">
                          <thead>
                            <tr>
                              <th>{{ $t('date') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                              <th>
                                {{ $t('home') }}
                                <template v-if="$vuetify.breakpoint.xs">({{ $t('w.label') }})</template>
                              </th>
                              
                              <th class="text-center">
                                <template v-if="$vuetify.breakpoint.xs">({{ $t('d.label') }})</template>
                              </th>

                              <th class="text-right">
                                {{ $t('away') }}
                                <template v-if="$vuetify.breakpoint.xs">({{ $t('l.label') }})</template>
                              </th>

                              <th :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center">{{ $t('w.label') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center">{{ $t('d.label') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center">{{ $t('l.label') }}</th>
                              <th class="text-center">{{ $t('detail') }}</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-for="(game, index) in aSimilar[aSPage]"
                              :key="index"
                              :class="{'curPointer': (game.local_score != 0 || game.visitor_score != 0)}"
                              @click="goalModal(index, game.local_id, game.local_name, game.visitor_name, 4)" >
                              <td class="overflow-anywhere blue-grey--text">
                                <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                                <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}">
                                <router-link :to="{name: 'league', params: {lid: game.league.league_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.league.name }}
                                </router-link>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="blue-grey--text">
                                {{ game.round ? game.round.name : '' }}
                              </td>

                              <td class="overflow-anywhere">
                                <template v-if="game.local_id != basic.visitor_id">
                                  <router-link :to="{name: 'team', params: {id: game.local_id}}" class="blue-grey--text">
                                    {{ game.local_name }}
                                  </router-link>
                                </template>

                                <span v-else class="black--text">{{ game.local_name }}</span>

                                <span v-if="$vuetify.breakpoint.xs"> ({{ game.three_way[1] }})</span>
                              </td>

                              <td class="white--text text-center" :style="'background-color:' + stats_color[game.goal_color]">
                                <div class="d-flex justify-space-around align-center subtitle-2">
                                  <span>{{ game.local_score }}</span>
                                  <span> - </span>
                                  <span>{{ game.visitor_score }}</span>
                                </div>

                                <span v-if="$vuetify.breakpoint.xs">({{ game.three_way.X }})</span>
                              </td>

                              <td class="overflow-anywhere text-right">
                                <template v-if="game.visitor_id != basic.visitor_id" class="text-right">
                                  <router-link :to="{name: 'team', params: {id: game.visitor_id}}" class="blue-grey--text">
                                    {{ game.visitor_name }}
                                  </router-link>
                                </template>

                                <span v-else class="text-right black--text">{{ game.visitor_name }}</span>

                                <span v-if="$vuetify.breakpoint.xs"> ({{ game.three_way[2] }})</span>
                              </td>
                              
                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center blue-grey--text">{{ game.three_way[1] }}</td>
                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center blue-grey--text">{{ game.three_way.X }}</td>
                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center blue-grey--text">{{ game.three_way[2] }}</td>
                              
                              <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                        </template>

                        <div v-else>
                          <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                        </div>
                      </div>
                    </template>
                  </template>
                </v-col>
              </v-row>
            </v-tab-item>
            
            <v-tab-item key="Corners">
              <v-row class="no-gutters" justify="center">
                <v-col lg="8" md="10">
                  <v-expansion-panels
                    v-if="cornerOddsLoaded && show_odds_market"
                    v-model="expanding_market[1]"
                    hover
                    flat
                    tile>
                    <v-expansion-panel class="mb-2">
                      <v-expansion-panel-header class="grey lighten-2 grey--text text--darken-1">
                        {{ $t('odds.oddsmarket') }}
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>
                        <div class="grey lighten-3">
                          <div class="d-flex flex-wrap p-1">
                            <v-checkbox
                              :disabled="!odds.CMB"
                              v-model="show_odds_market[5]"
                              color="blue"
                              class="mr-1"
                              hide-details
                              dense
                              label="1x2"
                              />
                            <v-checkbox
                              :disabled="!odds.CH"
                              v-model="show_odds_market[6]"
                              color="blue"
                              class="mr-1"
                              hide-details
                              dense
                              :label="$t('odds.handicap')"
                              />
                            <v-checkbox
                              :disabled="!odds.tCorner"
                              v-model="show_odds_market[7]"
                              color="blue"
                              class="mr-1"
                              hide-details
                              dense
                              :label="$t('odds.teamcorner')"
                              />
                            <v-checkbox
                              :disabled="!odds.COU"
                              v-model="show_odds_market[8]"
                              color="blue"
                              class="mr-1"
                              hide-details
                              dense
                              :label="$t('odds.t_ou')"
                              />
                          </div>

                          <div
                            class="d-flex flex-wrap justify-space-around"
                            :class="{'caption': $vuetify.breakpoint.xs}">
                            <div v-if="show_odds_market[5] && odds.CMB">
                              <div class="text-center"><span>{{ $t('odds.cmb') }}</span></div>

                              <v-simple-table
                                class="table-row-35 border-lightgrey text-center"
                                style="border-radius: 0; box-shadow: 1px 1px 3px lightgrey;">
                                <tbody>
                                  <tr>
                                    <td :title="basic.local_name">1</td>
                                    <td :title="$t('odds.draw')">X</td>
                                    <td :title="basic.visitor_name">2</td>
                                  </tr>

                                  <tr>
                                    <td>{{ odds.CMB[0].value }}</td>
                                    <td>{{ odds.CMB[1].value }}</td>
                                    <td>{{ odds.CMB[2].value }}</td>
                                  </tr>
                                </tbody>
                              </v-simple-table>
                            </div>

                            <div v-if="show_odds_market[6] && odds.CH">
                              <div class="text-center"><span>{{ $t('odds.handicap') }}</span></div>

                              <v-simple-table
                                class="table-row-35 border-lightgrey text-center"
                                style="border-radius: 0; box-shadow: 1px 1px 3px lightgrey;">
                                <tbody>
                                  <tr>
                                    <td>{{ basic.local_name }}</td>
                                    <td>{{ basic.visitor_name }}</td>
                                    <td v-if="(Object.keys(moreOdds['CH'][0]).length + Object.keys(moreOdds['CH'][1]).length) > 2">
                                      {{ $t('more') }}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>{{ moreOdds['maxCH'][0] }} : {{ moreOdds['maxCH'][1][odds_format] }}</td>
                                    <td>{{ moreOdds['maxCH'][2] }} : {{ moreOdds['maxCH'][3][odds_format] }}</td>
                                    <td v-if="(Object.keys(moreOdds['CH'][0]).length + Object.keys(moreOdds['CH'][1]).length) > 2"
                                      class="curPointer"
                                      @click="cornerOddsModal()">
                                      <v-icon color="success">mdi-dice-5</v-icon>
                                    </td>
                                  </tr>
                                </tbody>
                              </v-simple-table>
                            </div>

                            <div v-if="show_odds_market[7] && odds.tCorner" class="d-flex align-center text-center">
                              <div>
                                <span>{{ basic.local_name }}</span>

                                <v-simple-table
                                  class="table-row-35 border-lightgrey text-center"
                                  style="border-radius: 0; box-shadow: 1px 1px 3px lightgrey;">
                                  <tbody>
                                    <tr>
                                      <td>{{ $t(moreOdds['tCorner'][0][0].handicap[0]) }} {{ moreOdds['tCorner'][0][0].handicap[1] }}</td>
                                      <td>{{ $t(moreOdds['tCorner'][0][1].handicap[0]) }} {{ moreOdds['tCorner'][0][1].handicap[1] }}</td>
                                    </tr>

                                    <tr>
                                      <td>{{ moreOdds['tCorner'][0][0].value[odds_format] }}</td>
                                      <td>{{ moreOdds['tCorner'][0][1].value[odds_format] }}</td>
                                    </tr>
                                  </tbody>
                                </v-simple-table>
                              </div>

                              <div>
                                <span>{{ basic.visitor_name }}</span>

                                <v-simple-table
                                  class="table-row-35 border-lightgrey text-center"
                                  style="border-radius: 0; box-shadow: 1px 1px 3px lightgrey;">
                                  <tbody>
                                    <tr>
                                      <td>{{ $t(moreOdds['tCorner'][1][0].handicap[0]) }} {{ moreOdds['tCorner'][1][0].handicap[1] }}</td>
                                      <td>{{ $t(moreOdds['tCorner'][1][1].handicap[0]) }} {{ moreOdds['tCorner'][1][1].handicap[1] }}</td>
                                    </tr>

                                    <tr>
                                      <td>{{ moreOdds['tCorner'][1][0].value[odds_format] }}</td>
                                      <td>{{ moreOdds['tCorner'][1][1].value[odds_format] }}</td>
                                    </tr>
                                  </tbody>
                                </v-simple-table>
                              </div>
                            </div>

                            <div v-if="show_odds_market[8] && odds.COU">
                              <div class="text-center"><span>{{ $t('odds.t_ou') }}</span></div>

                              <v-simple-table
                                class="table-row-35 border-lightgrey text-center"
                                style="border-radius: 0; box-shadow: 1px 1px 3px lightgrey;">
                                <tbody>
                                  <tr>
                                    <td>{{ $t('total') }}</td>
                                    <td>{{ $t('over') }}</td>
                                    <td>{{ $t('under') }}</td>
                                    <td v-if="(Object.keys(moreOdds['COU'][0]).length + Object.keys(moreOdds['COU'][1]).length) > 2">
                                      {{ $t('more') }}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>{{ moreOdds['maxCOU'] }}</td>
                                    <td>{{ moreOdds['COU'][0][moreOdds['maxCOU']][odds_format] }}</td>
                                    <td>{{ moreOdds['COU'][1][moreOdds['maxCOU']][odds_format] }}</td>
                                    <td v-if="(Object.keys(moreOdds['COU'][0]).length + Object.keys(moreOdds['COU'][1]).length) > 2"
                                      class="curPointer"
                                      @click="cornerOddsModal()">
                                      <v-icon color="success">mdi-dice-5</v-icon>
                                    </td>
                                  </tr>
                                </tbody>
                              </v-simple-table>
                            </div>
                          </div>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>

                  <div class="game-nav">
                    <div class="game-nav__btn" :class="{'game-nav__btn_active' : flags.corner==0}" @click="corner_flag(0)">{{ $t('head2head') }}</div>
                    <div class="game-nav__btn" :class="{'game-nav__btn_active' : flags.corner==1}" @click="corner_flag(1)">{{ $t('lastmatches') }}</div>
                    <div class="game-nav__btn" :class="{'game-nav__btn_active' : flags.corner==2}" @click="corner_flag(2)" :title="$t('message.similar3way')">{{ $t('similarmatches') }}</div>
                  </div>

                  <div v-if="!flags.corner" class="mt-4">
                    <div v-if="!h2hLoaded" >
                      <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                    </div>

                    <div v-else>
                      <v-container fluid>
                        <div class="row d-flex">
                          <v-select
                            v-model="h2hChosenLeagues"
                            :items="h2hLeagues"
                            item-text="league_name"
                            item-value="league_id"
                            class="col-12 col-sm-7 col-lg-9"
                            attach
                            dense
                            :label="$t('tournament')"
                            hide-details
                            multiple
                          />

                          <v-select
                            v-model="h2hChosenSeasons"
                            :items="h2hSeasons"
                            :label="$t('season')"
                            class="col-12 col-sm-5 col-lg-3"
                            attach
                            dense
                            hide-details
                            multiple
                          />
                        </div>

                        <div class="row d-flex flex-wrap justify-space-between mt-4">
                          <v-select
                            v-model="h2hFullHalf"
                            :items="timeCategories"
                            :item-text="item => $t(item.text)"
                            item-value="index"
                            :label="$t('time')"
                            class="col-5 col-sm-3 col-md-2"
                            attach
                            dense
                            hide-details
                          />

                          <v-checkbox
                            v-model="h2hLocal"
                            color="blue"
                            hide-details
                            dense
                            :label="$t('home')"
                            />
                          
                          <v-checkbox
                            v-model="h2hVisitor"
                            color="blue"
                            hide-details
                            dense
                            :label="$t('away')"
                            />

                          <template v-if="basic.local_coach && basic.visitor_coach">
                            <v-checkbox
                              v-model="h2hCurrentCoach"
                              color="blue"
                              hide-details
                              dense
                              :label="`${$t('currentcoach')}(${h2h_coach_count})`"
                              />
                            <v-checkbox
                              v-model="h2hPrevCoach"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('previouscoach')"
                              />
                          </template>

                          <v-select
                            v-model="h2hPerPage"
                            :items="h2hCountPerPage"
                            attach
                            dense
                            class="col-4 col-sm-2 col-md-1"
                            :hint="$t('countperpage')"
                          />

                          <v-text-field
                            v-model="h2hPPageByUser"
                            class="col-3 col-sm-2 col-md-1"
                            dense
                            :hint="$t('countperpage')"
                            type="number"
                            max=149
                            min=1
                            v-on:keyup.enter="h2hPerPageChange"
                          />
                        </div>
                      </v-container>

                      <v-simple-table
                        v-if="cornerData.matches"
                        class="table-row-35 border-lightgrey text-center"
                        style="border-radius: 0">
                        <tbody>
                          <tr>
                            <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                            <td :title="$t('pts.tooltip')">{{ $t('pts.label') }}</td>
                            <td :title="$t('w.tooltip')">{{ $t('w.label') }}</td>
                            <td :title="$t('d.tooltip')">{{ $t('d.label') }}</td>
                            <td :title="$t('l.tooltip')">{{ $t('l.label') }}</td>
                            <td :title="$t('diff.tooltip')">{{ $t('diff.label') }}</td>
                            <td :title="$t('diff_av.tooltip')">{{ $t('diff_av.label') }}</td>
                            <td :title="$t('total_av.tooltip')">{{ $t('total_av.label') }}</td>
                            <td :title="$t('teamtotal_av.tooltip')">{{ basic.local_name }}</td>
                            <td :title="$t('opponenttotal_av.tooltip')">{{ basic.visitor_name }}</td>
                          </tr>

                          <tr class="font-weight-bold">
                            <td>{{ cornerData.matches }}</td>
                            <td>{{ cornerData.points }}</td>
                            <td>{{ cornerData.win }}</td>
                            <td>{{ cornerData.draw }}</td>
                            <td>{{ cornerData.lost }}</td>
                            <td>{{ cornerData.diff }}</td>
                            <td>{{ cornerData.diff_av }}</td>
                            <td>{{ cornerData.totalAvg }}</td>
                            <td>{{ cornerData.homeTotalAvg }}</td>
                            <td>{{ cornerData.awayTotalAvg }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>

                      <div class="curPointer pt-5 pb-2">
                        <a :class="{'d-none' : h2hPage == h2hLastPage}" class="ml-1 mr-4" @click="h2hPrev()">{{ $t('previous') }}</a>
                        <a :class="{'d-none' : !h2hPage}" @click="h2hNext()">{{ $t('next') }}</a>
                      </div>

                      <v-simple-table  class="table-row-35 cell-py-10 border-lightgrey" style="border-radius: 0">
                        <thead>
                          <tr>
                            <th>{{ $t('date') }}</th>
                            <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                            <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                            <th>{{ $t('home') }}</th>
                            <th></th>
                            <th class="text-right">{{ $t('away') }}</th>
                            <th class="text-center">{{ $t('detail') }}</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="(game, index) in CGC[h2hPage]"
                            :key="index"
                            class="blue-grey--text"
                            :class="{'curPointer': (game.local_corners != 0 || game.visitor_corners != 0)}"
                            @click="cornerModal(game.index, game.local_id, game.local_name, game.visitor_name, 0)">
                            <td class="overflow-anywhere">
                              <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                              <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                            </td>

                            <td :class="{'d-none': $vuetify.breakpoint.xs}" class="overflow-anywhere">
                              <router-link :to="{name: 'league', params: {lid: game.league_id}}" class="blue-grey--text">
                                {{ game.league_name }}
                              </router-link>
                            </td>

                            <td :class="{'d-none': $vuetify.breakpoint.xs}">{{ game.round_name ? game.round_name : '' }}</td>
                            
                            <td class="overflow-anywhere">{{ game.local_name }}</td>

                            <td
                              class="white--text"
                              :style="'background-color:' + game.corners_bgcolor">
                              <div class="d-flex justify-space-around align-center subtitle-2">
                                <span>{{ (game.local_corners != 0 || game.visitor_corners != 0) ? game.local_corners : '?' }}</span>
                                
                                <span v-if="$vuetify.breakpoint.xs">-</span>

                                <img v-else src='/img/icons/corner_kick.png'>

                                <span>{{ (game.local_corners != 0 || game.visitor_corners != 0) ? game.visitor_corners : '?' }}</span>
                              </div>
                            </td>

                            <td class="overflow-anywhere text-right">{{ game.visitor_name }}</td>

                            <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </div>
                  </div>

                  <template v-else-if="flags.corner==1">
                    <div v-if="!lastHomeLoaded && !lastAwayLoaded">
                      <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                    </div>

                    <div v-else-if="!authorization">
                      <v-alert
                        type="warning"
                        prominent
                      >
                        {{ $t(`message.${msg_last}`) }}
                      </v-alert>
                    </div>

                    <template v-else>
                      <div class="matches_heading">{{ basic.local_name }} {{ $t('lastmatches') }}</div>

                      <div>
                        <v-container fluid>
                          <div v-if='homeLeagueLoaded' class="row d-flex">
                              <v-select
                                v-model="homeChosenLeagues"
                                :items="HLeagues"
                                item-text="league_name"
                                item-value="league_id"
                                class="col-12 col-sm-7 col-lg-9"
                                attach
                                dense
                                :label="$t('tournament')"
                                hide-details
                                multiple
                              />

                              <v-select
                                v-model="homeChosenSeasons"
                                :items="HSeasons"
                                :label="$t('season')"
                                class="col-12 col-sm-5 col-lg-3"
                                attach
                                dense
                                hide-details
                                multiple
                              />
                          </div>

                          <div class="row d-flex flex-wrap justify-space-between mt-4">
                            <v-select
                              v-model="hLastFullHalf"
                              :items="timeCategories"
                              :item-text="item => $t(item.text)"
                              item-value="index"
                              :label="$t('time')"
                              class="col-5 col-sm-3 col-md-2"
                              attach
                              dense
                              hide-details
                            />

                            <v-checkbox
                              v-model="hLocal"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('home')"
                              />
                            
                            <v-checkbox
                              v-model="hVisitor"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('away')"
                              />

                            <template v-if="basic.local_coach">
                              <v-checkbox
                                v-model="hCurrentCoach"
                                color="blue"
                                hide-details
                                dense
                                :label="`${$t('currentcoach')}(${local_coach_count})`"
                                />
                              <v-checkbox
                                v-model="hPrevCoach"
                                color="blue"
                                hide-details
                                dense
                                :label="$t('previouscoach')"
                                />
                            </template>

                            <v-select
                              v-model="homePerPage"
                              :items="countPerPage"
                              attach
                              dense
                              class="col-4 col-sm-2 col-md-1"
                              :hint="$t('countperpage')"
                            />

                            <v-text-field
                              v-model="hPPageByUser"
                              class="col-3 col-sm-2 col-md-1"
                              dense
                              :hint="$t('countperpage')"
                              type="number"
                              max=149
                              min=1
                              v-on:keyup.enter="homePerPageChange"
                            />
                          </div>
                        </v-container>

                        <template v-if="lastHomeLoaded">
                        <v-simple-table
                          v-if="hLastAvgLoaded && hLastAvg.cornerMatch"
                          class="table-row-35 border-lightgrey text-center"
                          style="border-radius: 0">
                          <tbody>
                            <tr>
                              <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                              <td :title="$t('pts.tooltip')">{{ $t('pts.label') }}</td>
                              <td :title="$t('w.tooltip')">{{ $t('w.label') }}</td>
                              <td :title="$t('d.tooltip')">{{ $t('d.label') }}</td>
                              <td :title="$t('l.tooltip')">{{ $t('l.label') }}</td>
                              <td :title="$t('diff.tooltip')">{{ $t('diff.label') }}</td>
                              <td :title="$t('diff_av.tooltip')">{{ $t('diff_av.label') }}</td>
                              <td :title="$t('total_av.tooltip')">{{ $t('total_av.label') }}</td>
                              <td :title="$t('teamtotal_av.tooltip')">{{ basic.local_name }}</td>
                              <td :title="$t('opponenttotal_av.tooltip')"> {{ $t('opponenttotal_av.label') }}</td>
                            </tr>
                            
                            <tr class="font-weight-bold">
                              <td>{{ hLastAvg.cornerMatch }}</td>
                              <td>{{ hLastAvg.cornerPoint }}</td>
                              <td>{{ hLastAvg.cornerWDL[0] }}</td>
                              <td>{{ hLastAvg.cornerWDL[1] }}</td>
                              <td>{{ hLastAvg.cornerWDL[2] }}</td>
                              <td>{{ hLastAvg.cornerDiff }}</td>
                              <td>{{ hLastAvg.cornerDiff_avg }}</td>
                              <td>{{ hLastAvg.cornerTotal }}</td>
                              <td>{{ hLastAvg.cornerT1 }}</td>
                              <td>{{ hLastAvg.cornerT2 }}</td>
                            </tr>
                          </tbody>
                        </v-simple-table>

                        <div class="curPointer pt-5 pb-2">
                          <a :class="{'d-none' : homePage == hLastPage}" class="ml-1 mr-4" @click="homePrev()">{{ $t('previous') }}</a>
                          <a :class="{'d-none' : !homePage}" @click="homeNext()">{{ $t('next') }}</a>
                        </div>

                        <v-simple-table
                          class="table-row-35 cell-py-10 border-lightgrey"
                          style="border-radius: 0">
                          <thead>
                            <tr>
                              <th>{{ $t('date') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                              <th>{{ $t('home') }}</th>
                              <th></th>
                              <th class="text-right">{{ $t('away') }}</th>
                              <th class="text-center">{{ $t('detail') }}</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-for="(game, index) in Hlast[homePage]"
                              :key="index"
                              :class="{'curPointer': (game.corners[game.local_id] != 0 || game.corners[game.visitor_id] != 0)}"
                              @click="cornerModal(index, game.local_id, game.local_name, game.visitor_name, 1)">
                              <td class="overflow-anywhere blue-grey--text">
                                <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                                <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="overflow-anywhere">
                                <router-link :to="{name: 'league', params: {lid: game.league.league_id}}" class="blue-grey--text">
                                  {{ game.league.name }}
                                </router-link>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="blue-grey--text">
                                {{ game.round ? game.round.name : '' }}
                              </td>

                              <td v-if="game.local_id!=basic.local_id">
                                <router-link :to="{name: 'team', params: {id: game.local_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.local_name }}
                                </router-link>
                              </td>

                              <td v-else class="overflow-anywhere black--text">{{ game.local_name }}</td>

                              <td
                                class="white--text"
                                :style="'background-color:' + stats_color[game.corner_color]">
                                <div class="d-flex justify-space-around align-center subtitle-2">
                                  <span>{{ (game.corners[game.local_id] != 0 || game.corners[game.visitor_id] != 0) ? game.corners[game.local_id] : '?' }}</span>
                                  
                                  <span v-if="$vuetify.breakpoint.xs">-</span>

                                  <img v-else src='/img/icons/corner_kick.png'>

                                  <span>{{ (game.corners[game.local_id] != 0 || game.corners[game.visitor_id] != 0) ? game.corners[game.visitor_id] : '?' }}</span>
                                </div>
                              </td>

                              <td v-if="game.visitor_id != basic.local_id"  class="text-right">
                                <router-link :to="{name: 'team', params: {id: game.visitor_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.visitor_name }}
                                </router-link>
                              </td>

                              <td v-else class="overflow-anywhere text-right black--text">{{ game.visitor_name }}</td>
                              
                              <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                        </template>

                        <div v-else>
                          <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                        </div>
                      </div>

                      <div class="matches_heading">{{ basic.visitor_name }} {{ $t('lastmatches') }}</div>

                      <div>
                        <v-container fluid>
                          <div v-if='awayLeagueLoaded' class="row d-flex">
                            <v-select
                              v-model="awayChosenLeagues"
                              :items="ALeagues"
                              item-text="league_name"
                              item-value="league_id"
                              class="col-12 col-sm-7 col-lg-9"
                              attach
                              dense
                              :label="$t('tournament')"
                              hide-details
                              multiple
                            />

                            <v-select
                              v-model="awayChosenSeasons"
                              :items="ASeasons"
                              :label="$t('season')"
                              class="col-12 col-sm-5 col-lg-3"
                              attach
                              dense
                              hide-details
                              multiple
                            />
                          </div>

                          <div class="row d-flex flex-wrap justify-space-between mt-4">
                            <v-select
                              v-model="aLastFullHalf"
                              :items="timeCategories"
                              :item-text="item => $t(item.text)"
                              item-value="index"
                              :label="$t('time')"
                              class="col-5 col-sm-3 col-md-2"
                              attach
                              dense
                              hide-details
                            />

                            <v-checkbox
                              v-model="aLocal"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('home')"
                              />
                            
                            <v-checkbox
                              v-model="aVisitor"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('away')"
                              />

                            <template v-if="basic.visitor_coach">
                              <v-checkbox
                                v-model="aCurrentCoach"
                                color="blue"
                                hide-details
                                dense
                                :label="`${$t('currentcoach')}(${visitor_coach_count})`"
                                />
                              <v-checkbox
                                v-model="aPrevCoach"
                                color="blue"
                                hide-details
                                dense
                                :label="$t('previouscoach')"
                                />
                            </template>

                            <v-select
                              v-model="awayPerPage"
                              :items="countPerPage"
                              attach
                              dense
                              class="col-4 col-sm-2 col-md-1"
                              :hint="$t('countperpage')"
                            />

                            <v-text-field
                              v-model="aPPageByUser"
                              class="col-3 col-sm-2 col-md-1"
                              dense
                              :hint="$t('countperpage')"
                              type="number"
                              max=149
                              min=1
                              v-on:keyup.enter="awayPerPageChange"
                            />
                          </div>
                        </v-container>

                        <template v-if="lastAwayLoaded">
                        <v-simple-table
                          v-if="aLastAvgLoaded && aLastAvg.cornerMatch"
                          class="table-row-35 border-lightgrey text-center"
                          style="border-radius: 0">
                          <tbody>
                            <tr>
                              <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                              <td :title="$t('pts.tooltip')">{{ $t('pts.label') }}</td>
                              <td :title="$t('w.tooltip')">{{ $t('w.label') }}</td>
                              <td :title="$t('d.tooltip')">{{ $t('d.label') }}</td>
                              <td :title="$t('l.tooltip')">{{ $t('l.label') }}</td>
                              <td :title="$t('diff.tooltip')">{{ $t('diff.label') }}</td>
                              <td :title="$t('diff_av.tooltip')">{{ $t('diff_av.label') }}</td>
                              <td :title="$t('total_av.tooltip')">{{ $t('total_av.label') }}</td>
                              <td :title="$t('teamtotal_av.tooltip')">{{ basic.visitor_name }}</td>
                              <td :title="$t('opponenttotal_av.tooltip')"> {{ $t('opponenttotal_av.label') }}</td>
                            </tr>

                            <tr class="font-weight-bold">
                              <td>{{ aLastAvg.cornerMatch }}</td>
                              <td>{{ aLastAvg.cornerPoint }}</td>
                              <td>{{ aLastAvg.cornerWDL[0] }}</td>
                              <td>{{ aLastAvg.cornerWDL[1] }}</td>
                              <td>{{ aLastAvg.cornerWDL[2] }}</td>
                              <td>{{ aLastAvg.cornerDiff }}</td>
                              <td>{{ aLastAvg.cornerDiff_avg }}</td>
                              <td>{{ aLastAvg.cornerTotal }}</td>
                              <td>{{ aLastAvg.cornerT1 }}</td>
                              <td>{{ aLastAvg.cornerT2 }}</td>
                            </tr>
                          </tbody>
                        </v-simple-table>

                        <div class="curPointer pt-5 pb-2">
                          <a :class="{'d-none' : awayPage == aLastPage}" class="ml-1 mr-4" @click="awayPrev()">{{ $t('previous') }}</a>
                          <a :class="{'d-none' : !awayPage}" @click="awayNext()">{{ $t('next') }}</a>
                        </div>

                        <v-simple-table class="table-row-35 cell-py-10 border-lightgrey" style="border-radius: 0">
                          <thead>
                            <tr>
                              <th>{{ $t('date') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                              <th>{{ $t('home') }}</th>
                              <th></th>
                              <th class="text-right">{{ $t('away') }}</th>
                              <th class="text-center">{{ $t('detail') }}</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-for="(game, index) in Alast[awayPage]"
                              :key="index"
                              :class="{'curPointer': (game.corners[game.local_id] != 0 || game.corners[game.visitor_id] != 0)}"
                              @click="cornerModal(index, game.local_id, game.local_name, game.visitor_name, 2)">
                              <td class="overflow-anywhere blue-grey--text">
                                <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                                <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="overflow-anywhere">
                                <router-link :to="{name: 'league', params: {lid: game.league.league_id}}" class="blue-grey--text">
                                  {{ game.league.name }}
                                </router-link>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="blue-grey--text">
                                {{ game.round ? game.round.name : '' }}
                              </td>

                              <td v-if="game.local_id != basic.visitor_id">
                                <router-link :to="{name: 'team', params: {id: game.local_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.local_name }}
                                </router-link>
                              </td>

                              <td v-else class="overflow-anywhere black--text">{{ game.local_name }}</td>

                              <td
                                class="white--text"
                                :style="'background-color:' + stats_color[game.corner_color]">
                                <div class="d-flex justify-space-around align-center subtitle-2">
                                  <span>{{ (game.corners[game.local_id] != 0 || game.corners[game.visitor_id] != 0) ? game.corners[game.local_id] : '?' }}</span>
                                  
                                  <span v-if="$vuetify.breakpoint.xs">-</span>

                                  <img v-else src='/img/icons/corner_kick.png'>

                                  <span>{{ (game.corners[game.local_id] != 0 || game.corners[game.visitor_id] != 0) ? game.corners[game.visitor_id] : '?' }}</span>
                                </div>
                              </td>
                              
                              <td v-if="game.visitor_id != basic.visitor_id" class="text-right">
                                <router-link :to="{name: 'team', params: {id: game.visitor_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.visitor_name }}
                                </router-link>
                              </td>

                              <td v-else class="overflow-anywhere text-right black--text">{{ game.visitor_name }}</td>

                              <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                        </template>

                        <div v-else>
                          <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                        </div>
                      </div>
                    </template>
                  </template>

                  <template v-else>
                    <div v-if="!odds.FT">
                      <v-alert
                        type="info"
                        prominent
                      >
                        {{ $t('message.impossibleSM') }}
                      </v-alert>
                    </div>

                    <div v-else-if="!similarHomeLoaded && !similarAwayLoaded">
                      <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                    </div>

                    <div v-else-if="!authorization">
                      <v-alert
                        type="warning"
                        prominent
                      >
                        {{ $t(`message.${msg_last}`) }}
                      </v-alert>
                    </div>

                    <template v-else>
                      <div class="matches_heading">{{ basic.local_name }} {{ $t('similarmatches') }}</div>

                      <div>
                        <v-container fluid>
                          <div v-if='homeLeagueLoaded' class="row d-flex">
                            <v-select
                              v-model="hSChosenLeagues"
                              :items="HLeagues"
                              item-text="league_name"
                              item-value="league_id"
                              class="col-12 col-sm-7 col-lg-9"
                              attach
                              dense
                              :label="$t('tournament')"
                              hide-details
                              multiple
                            />

                            <v-select
                              v-model="hSChosenSeasons"
                              :items="HSeasons"
                              :label="$t('season')"
                              class="col-12 col-sm-5 col-lg-3"
                              attach
                              dense
                              hide-details
                              multiple
                            />
                          </div>

                          <div class="row d-flex flex-wrap justify-space-between mt-4">
                            <v-select
                              v-model="hSimilarFullHalf"
                              :items="timeCategories"
                              :item-text="item => $t(item.text)"
                              item-value="index"
                              :label="$t('time')"
                              class="col-5 col-sm-3 col-md-2"
                              attach
                              dense
                              hide-details
                            />

                            <v-checkbox
                              v-model="hSLocal"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('home')"
                              />
                            
                            <v-checkbox
                              v-model="hSVisitor"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('away')"
                              />

                            <template v-if="basic.local_coach">
                              <v-checkbox
                                v-model="hSCurrentCoach"
                                color="blue"
                                hide-details
                                dense
                                :label="`${$t('currentcoach')}(${ls_coach_count})`"
                                />
                              <v-checkbox
                                v-model="hSPrevCoach"
                                color="blue"
                                hide-details
                                dense
                                :label="$t('previouscoach')"
                                />
                            </template>

                            <v-select
                              v-model="hSPerPage"
                              :items="countPerPage"
                              attach
                              dense
                              class="col-4 col-sm-2 col-md-1"
                              :hint="$t('countperpage')"
                            />

                            <v-text-field
                              v-model="hSPPageByUser"
                              class="col-3 col-sm-2 col-md-1"
                              dense
                              :hint="$t('countperpage')"
                              type="number"
                              max=149
                              min=1
                              v-on:keyup.enter="hSPerPageChange"
                            />
                          </div>
                        </v-container>

                        <template v-if="similarHomeLoaded">
                        <v-simple-table
                          v-if="hSimilarAvgLoaded && hSimilarAvg.cornerMatch"
                          class="table-row-35 border-lightgrey text-center"
                          style="border-radius: 0">
                          <tbody>
                            <tr>
                              <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                              <td :title="$t('pts.tooltip')">{{ $t('pts.label') }}</td>
                              <td :title="$t('w.tooltip')">{{ $t('w.label') }}</td>
                              <td :title="$t('d.tooltip')">{{ $t('d.label') }}</td>
                              <td :title="$t('l.tooltip')">{{ $t('l.label') }}</td>
                              <td :title="$t('diff.tooltip')">{{ $t('diff.label') }}</td>
                              <td :title="$t('diff_av.tooltip')">{{ $t('diff_av.label') }}</td>
                              <td :title="$t('total_av.tooltip')">{{ $t('total_av.label') }}</td>
                              <td :title="$t('teamtotal_av.tooltip')">{{ basic.local_name }}</td>
                              <td :title="$t('opponenttotal_av.tooltip')"> {{ $t('opponenttotal_av.label') }}</td>
                            </tr>

                            <tr class="font-weight-bold">
                              <td>{{ hSimilarAvg.cornerMatch }}</td>
                              <td>{{ hSimilarAvg.cornerPoint }}</td>
                              <td>{{ hSimilarAvg.cornerWDL[0] }}</td>
                              <td>{{ hSimilarAvg.cornerWDL[1] }}</td>
                              <td>{{ hSimilarAvg.cornerWDL[2] }}</td>
                              <td>{{ hSimilarAvg.cornerDiff }}</td>
                              <td>{{ hSimilarAvg.cornerDiff_avg }}</td>
                              <td>{{ hSimilarAvg.cornerTotal }}</td>
                              <td>{{ hSimilarAvg.cornerT1 }}</td>
                              <td>{{ hSimilarAvg.cornerT2 }}</td>
                            </tr>
                          </tbody>
                        </v-simple-table>

                        <div class="curPointer pt-5 pb-2">
                          <a :class="{'d-none' : hSPage == hSLastPage}" class="ml-1 mr-4" @click="hSPrev()">{{ $t('previous') }}</a>
                          <a :class="{'d-none' : !hSPage}" @click="hSNext()">{{ $t('next') }}</a>
                        </div>

                        <v-simple-table class="table-row-35 cell-py-10 border-lightgrey" style="border-radius: 0">
                          <thead>
                            <tr>
                              <th>{{ $t('date') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                              <th>
                                {{ $t('home') }}
                                <template v-if="$vuetify.breakpoint.xs">({{ $t('w.label') }})</template>
                              </th>

                              <th class="text-center">
                                <template v-if="$vuetify.breakpoint.xs">({{ $t('d.label') }})</template>
                              </th>

                              <th class="text-right">
                                {{ $t('away') }}
                                <template v-if="$vuetify.breakpoint.xs">({{ $t('l.label') }})</template>
                              </th>

                              <th :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center">{{ $t('w.label') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center">{{ $t('d.label') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center">{{ $t('l.label') }}</th>
                              <th class="text-center">{{ $t('detail') }}</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-for="(game, index) in hSimilar[hSPage]"
                              :key="index"
                              :class="{'curPointer': (game.corners[game.local_id] != 0 || game.corners[game.visitor_id] != 0)}"
                              @click="cornerModal(index, game.local_id, game.local_name, game.visitor_name, 3)">
                              <td class="overflow-anywhere blue-grey--text">
                                <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                                <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}">
                                <router-link :to="{name: 'league', params: {lid: game.league.league_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.league.name }}
                                </router-link>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="blue-grey--text">
                                {{ game.round ? game.round.name : '' }}
                              </td>

                              <td class="overflow-anywhere">
                                <template v-if="game.local_id!=basic.local_id">
                                  <router-link :to="{name: 'team', params: {id: game.local_id}}" class="blue-grey--text">
                                    {{ game.local_name }}
                                  </router-link>
                                </template>

                                <span v-else class="black--text">{{ game.local_name }}</span>

                                <span v-if="$vuetify.breakpoint.xs"> ({{ game.three_way[1] }})</span>
                              </td>                               

                              <td
                                class="white--text text-center"
                                :style="'background-color:' + stats_color[game.corner_color]">
                                <div class="d-flex justify-space-around align-center subtitle-2">
                                  <span>{{ (game.corners[game.local_id] != 0 || game.corners[game.visitor_id] != 0) ? game.corners[game.local_id] : '?' }}</span>
                                  
                                  <span v-if="$vuetify.breakpoint.xs">-</span>

                                  <img v-else src='/img/icons/corner_kick.png'>

                                  <span>{{ (game.corners[game.local_id] != 0 || game.corners[game.visitor_id] != 0) ? game.corners[game.visitor_id] : '?' }}</span>
                                </div>

                                <span v-if="$vuetify.breakpoint.xs">({{ game.three_way.X }})</span>
                              </td>

                              <td class="overflow-anywhere text-right">
                                <template v-if="game.visitor_id != basic.local_id">
                                  <router-link :to="{name: 'team', params: {id: game.visitor_id}}" class="blue-grey--text">
                                    {{ game.visitor_name }}
                                  </router-link>
                                </template>

                                <span v-else class="black--text">{{ game.visitor_name }}</span>

                                <span v-if="$vuetify.breakpoint.xs"> ({{ game.three_way[2] }})</span>
                              </td>
                              
                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center blue-grey--text">{{ game.three_way[1] }}</td>
                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center blue-grey--text">{{ game.three_way.X }}</td>
                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center blue-grey--text">{{ game.three_way[2] }}</td>
                              
                              <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                        </template>

                        <div v-else>
                          <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                        </div>
                      </div>

                      <div class="matches_heading">{{ basic.visitor_name }} {{ $t('similarmatches') }}</div>

                      <div>
                        <v-container fluid>
                          <div v-if='awayLeagueLoaded' class="row d-flex">
                            <v-select
                              v-model="aSChosenLeagues"
                              :items="ALeagues"
                              item-text="league_name"
                              item-value="league_id"
                              class="col-12 col-sm-7 col-lg-9"
                              attach
                              dense
                              :label="$t('tournament')"
                              hide-details
                              multiple
                            />

                            <v-select
                              v-model="aSChosenLeagues"
                              :items="ASeasons"
                              :label="$t('season')"
                              class="col-12 col-sm-5 col-lg-3"
                              attach
                              dense
                              hide-details
                              multiple
                            />
                          </div>

                          <div class="row d-flex flex-wrap justify-space-between mt-4">
                            <v-select
                              v-model="aSimilarFullHalf"
                              :items="timeCategories"
                              :item-text="item => $t(item.text)"
                              item-value="index"
                              :label="$t('time')"
                              class="col-5 col-sm-3 col-md-2"
                              attach
                              dense
                              hide-details
                            />

                            <v-checkbox
                              v-model="aSLocal"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('home')"
                              />
                            
                            <v-checkbox
                              v-model="aSVisitor"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('away')"
                              />

                            <template v-if="basic.visitor_coach">
                              <v-checkbox
                                v-model="aSCurrentCoach"
                                color="blue"
                                hide-details
                                dense
                                :label="`${$t('currentcoach')}(${vs_coach_count})`"
                                />
                              <v-checkbox
                                v-model="aSPrevCoach"
                                color="blue"
                                hide-details
                                dense
                                :label="$t('previouscoach')"
                                />
                            </template>

                            <v-select
                              v-model="aSPerPage"
                              :items="countPerPage"
                              attach
                              dense
                              class="col-4 col-sm-2 col-md-1"
                              :hint="$t('countperpage')"
                            />

                            <v-text-field
                              v-model="aSPPageByUser"
                              class="col-3 col-sm-2 col-md-1"
                              dense
                              :hint="$t('countperpage')"
                              type="number"
                              max=149
                              min=1
                              v-on:keyup.enter="aSPerPageChange"
                            />
                          </div>
                        </v-container>

                        <template v-if="similarAwayLoaded">
                        <v-simple-table
                          v-if="aSimilarAvgLoaded && aSimilarAvg.cornerMatch"
                          class="table-row-35 border-lightgrey text-center"
                          style="border-radius: 0">
                          <tbody>
                            <tr>
                              <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                              <td :title="$t('pts.tooltip')">{{ $t('pts.label') }}</td>
                              <td :title="$t('w.tooltip')">{{ $t('w.label') }}</td>
                              <td :title="$t('d.tooltip')">{{ $t('d.label') }}</td>
                              <td :title="$t('l.tooltip')">{{ $t('l.label') }}</td>
                              <td :title="$t('diff.tooltip')">{{ $t('diff.label') }}</td>
                              <td :title="$t('diff_av.tooltip')">{{ $t('diff_av.label') }}</td>
                              <td :title="$t('total_av.tooltip')">{{ $t('total_av.label') }}</td>
                              <th :title="$t('teamtotal_av.tooltip')">{{ basic.visitor_name }}</th>
                              <th :title="$t('opponenttotal_av.tooltip')">{{ $t('opponenttotal_av.label') }}</th>
                            </tr>
                            
                            <tr class="font-weight-bold">
                              <td>{{ aSimilarAvg.cornerMatch }}</td>
                              <td>{{ aSimilarAvg.cornerPoint }}</td>
                              <td>{{ aSimilarAvg.cornerWDL[0] }}</td>
                              <td>{{ aSimilarAvg.cornerWDL[1] }}</td>
                              <td>{{ aSimilarAvg.cornerWDL[2] }}</td>
                              <td>{{ aSimilarAvg.cornerDiff }}</td>
                              <td>{{ aSimilarAvg.cornerDiff_avg }}</td>
                              <td>{{ aSimilarAvg.cornerTotal }}</td>
                              <td>{{ aSimilarAvg.cornerT1 }}</td>
                              <td>{{ aSimilarAvg.cornerT2 }}</td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                        
                        <div class="curPointer pt-5 pb-2">
                          <a :class="{'d-none' : aSPage == aSLastPage}" class="ml-1 mr-4" @click="aSPrev()">{{ $t('previous') }}</a>
                          <a :class="{'d-none' : !aSPage}" @click="aSNext()">{{ $t('next') }}</a>
                        </div>

                        <v-simple-table class="table-row-35 cell-py-10 border-lightgrey" style="border-radius: 0">
                          <thead>
                            <tr>
                              <th>{{ $t('date') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                              <th>
                                {{ $t('home') }}
                                <template v-if="$vuetify.breakpoint.xs">({{ $t('w.label') }})</template>
                              </th>

                              <th class="text-center">
                                <template v-if="$vuetify.breakpoint.xs">({{ $t('d.label') }})</template>
                              </th>

                              <th class="text-right">
                                {{ $t('away') }}
                                <template v-if="$vuetify.breakpoint.xs">({{ $t('l.label') }})</template>
                              </th>

                              <th :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center">{{ $t('w.label') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center">{{ $t('d.label') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center">{{ $t('l.label') }}</th>
                              <th class="text-center">{{ $t('detail') }}</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-for="(game, index) in aSimilar[aSPage]"
                              :key="index"
                              :class="{'curPointer': (game.corners[game.local_id] != 0 || game.corners[game.visitor_id] != 0)}"
                              @click="cornerModal(index, game.local_id, game.local_name, game.visitor_name, 4)">
                              <td class="overflow-anywhere blue-grey--text">
                                <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                                <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}">
                                <router-link :to="{name: 'league', params: {lid: game.league.league_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.league.name }}
                                </router-link>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="blue-grey--text">
                                {{ game.round ? game.round.name : '' }}
                              </td>

                              <td class="overflow-anywhere">
                                <template v-if="game.local_id != basic.visitor_id">
                                  <router-link :to="{name: 'team', params: {id: game.local_id}}" class="blue-grey--text">
                                    {{ game.local_name }}
                                  </router-link>
                                </template>

                                <span v-else class="black--text">{{ game.local_name }}</span>

                                <span v-if="$vuetify.breakpoint.xs"> ({{ game.three_way[1] }})</span>
                              </td>

                              <td
                                class="white--text text-center"
                                :style="'background-color:' + stats_color[game.corner_color]">
                                <div class="d-flex justify-space-around align-center subtitle-2">
                                  <span>{{ (game.corners[game.local_id] != 0 || game.corners[game.visitor_id] != 0) ? game.corners[game.local_id] : '?' }}</span>                                  
                                  
                                  <span v-if="$vuetify.breakpoint.xs">-</span>

                                  <img v-else src='/img/icons/corner_kick.png'>

                                  <span>{{ (game.corners[game.local_id] != 0 || game.corners[game.visitor_id] != 0) ? game.corners[game.visitor_id] : '?' }}</span>
                                </div>

                                <span v-if="$vuetify.breakpoint.xs">({{ game.three_way.X }})</span>
                              </td>

                              <td class="overflow-anywhere text-right">
                                <template v-if="game.visitor_id != basic.visitor_id">
                                  <router-link :to="{name: 'team', params: {id: game.visitor_id}}" class="blue-grey--text">
                                    {{ game.visitor_name }}
                                  </router-link>
                                </template>

                                <span v-else class="black--text">{{ game.visitor_name }}</span>

                                <span v-if="$vuetify.breakpoint.xs"> ({{ game.three_way[2] }})</span>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center blue-grey--text">{{ game.three_way[1] }}</td>
                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center blue-grey--text">{{ game.three_way.X }}</td>
                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center blue-grey--text">{{ game.three_way[2] }}</td>
                              
                              <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                        </template>

                        <div v-else>
                          <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                        </div>
                      </div>
                    </template>
                  </template>
                </v-col>
              </v-row>
            </v-tab-item>              

            <v-tab-item key="Cards">
              <v-row class="no-gutters" justify="center">
                <v-col lg="8" md="10">
                  <v-expansion-panels
                    v-if="cardOddsLoaded && show_odds_market"
                    v-model="expanding_market[2]"
                    hover
                    flat
                    tile>
                    <v-expansion-panel class="mb-2">
                      <v-expansion-panel-header class="grey lighten-2 grey--text text--darken-1">
                        {{ $t('odds.oddsmarket') }}
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>
                        <div class="grey lighten-3">
                          <div class="d-flex flex-wrap p-1">
                            <v-checkbox
                              :disabled="!odds.AHC"
                              v-model="show_odds_market[9]"
                              color="blue"
                              class="mr-1"
                              hide-details
                              dense
                              :label="$t('odds.asianhandicap')"
                              />
                            <v-checkbox
                              :disabled="!odds.ATC"
                              v-model="show_odds_market[10]"
                              color="blue"
                              class="mr-1"
                              hide-details
                              dense
                              :label="$t('odds.atc')"
                              />
                            <v-checkbox
                              :disabled="!odds.tCard"
                              v-model="show_odds_market[11]"
                              color="blue"
                              class="mr-1"
                              hide-details
                              dense
                              :label="$t('odds.teamcard')"
                              />
                            <v-checkbox
                              :disabled="!odds.redCard"
                              v-model="show_odds_market[12]"
                              color="blue"
                              class="mr-1"
                              hide-details
                              dense
                              :label="$t('redcard')"
                              />
                          </div>

                          <div
                            class="d-flex flex-wrap justify-space-around"
                            :class="{'caption': $vuetify.breakpoint.xs}">
                            <div v-if="show_odds_market[9] && odds.AHC">
                              <div class="text-center"><span>{{ $t('odds.asianhandicap') }}</span></div>

                              <v-simple-table
                                class="table-row-35 border-lightgrey text-center"
                                style="border-radius: 0; box-shadow: 1px 1px 3px lightgrey;">
                                <tbody>
                                  <tr>
                                    <td>{{ basic.local_name }}</td>
                                    <td>{{ basic.visitor_name }}</td>
                                    <td v-if="(Object.keys(moreOdds['AHC'][0]).length + Object.keys(moreOdds['AHC'][1]).length) > 2">
                                      {{ $t('more') }}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>{{ moreOdds['maxAHC'][0] }} : {{ moreOdds['maxAHC'][1][odds_format] }}</td>
                                    <td>{{ moreOdds['maxAHC'][2] }} : {{ moreOdds['maxAHC'][3][odds_format] }}</td>
                                    <td v-if="(Object.keys(moreOdds['AHC'][0]).length + Object.keys(moreOdds['AHC'][1]).length) > 2"
                                      class="curPointer"
                                      @click="cardOddsModal()">
                                      <v-icon color="success">mdi-dice-5</v-icon>
                                    </td>
                                  </tr>
                                </tbody>
                              </v-simple-table>
                            </div>

                            <div v-if="show_odds_market[10] && odds.ATC">
                              <div class="text-center"><span>{{ $t('odds.atc') }}</span></div>

                              <v-simple-table
                                class="table-row-35 border-lightgrey text-center"
                                style="border-radius: 0; box-shadow: 1px 1px 3px lightgrey;">
                                <tbody>
                                  <tr>
                                    <td>{{ $t('total') }}</td>
                                    <td>{{ $t('over') }}</td>
                                    <td>{{ $t('under') }}</td>
                                    <td v-if="(Object.keys(moreOdds['ATC'][0]).length + Object.keys(moreOdds['ATC'][1]).length) > 2">
                                      {{ $t('more') }}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>{{ moreOdds['maxATC'] }}</td>
                                    <td>{{ moreOdds['ATC'][0][moreOdds['maxATC']][odds_format] }}</td>
                                    <td>{{ moreOdds['ATC'][1][moreOdds['maxATC']][odds_format] }}</td>
                                    <td v-if="(Object.keys(moreOdds['ATC'][0]).length + Object.keys(moreOdds['ATC'][1]).length) > 2"
                                      class="curPointer"
                                      @click="cardOddsModal()">
                                      <v-icon color="success">mdi-dice-5</v-icon>
                                    </td>
                                  </tr>
                                </tbody>
                              </v-simple-table>
                            </div>

                            <div v-if="show_odds_market[11] && odds.tCard" class="d-flex align-center text-center">
                              <div>
                                <span>{{ basic.local_name }}</span>

                                <v-simple-table
                                  class="table-row-35 border-lightgrey text-center"
                                  style="border-radius: 0; box-shadow: 1px 1px 3px lightgrey;">
                                  <tbody>
                                    <tr>
                                      <td>{{ $t(moreOdds['tCard'][0][0].handicap[0]) }} {{ moreOdds['tCard'][0][0].handicap[1] }}</td>
                                      <td>{{ $t(moreOdds['tCard'][0][1].handicap[0]) }} {{ moreOdds['tCard'][0][1].handicap[1] }}</td>
                                    </tr>

                                    <tr>
                                      <td>{{ moreOdds['tCard'][0][0].value[odds_format] }}</td>
                                      <td>{{ moreOdds['tCard'][0][1].value[odds_format] }}</td>
                                    </tr>
                                  </tbody>
                                </v-simple-table>
                              </div>

                              <div>
                                <span>{{ basic.visitor_name }}</span>

                                <v-simple-table
                                  class="table-row-35 border-lightgrey text-center"
                                  style="border-radius: 0; box-shadow: 1px 1px 3px lightgrey;">
                                  <tbody>
                                    <tr>
                                      <td>{{ $t(moreOdds['tCard'][1][0].handicap[0]) }} {{ moreOdds['tCard'][1][0].handicap[1] }}</td>
                                      <td>{{ $t(moreOdds['tCard'][1][1].handicap[0]) }} {{ moreOdds['tCard'][1][1].handicap[1] }}</td>
                                    </tr>

                                    <tr>
                                      <td>{{ moreOdds['tCard'][1][0].value[odds_format] }}</td>
                                      <td>{{ moreOdds['tCard'][1][1].value[odds_format] }}</td>
                                    </tr>
                                  </tbody>
                                </v-simple-table>
                              </div>
                            </div>

                            <div v-if="show_odds_market[12] && odds.redCard">
                              <div class="text-center"><span>{{ $t('odds.rc_in_match')}}</span></div>

                              <v-simple-table
                                class="table-row-35 border-lightgrey text-center"
                                style="border-radius: 0; box-shadow: 1px 1px 3px lightgrey;">
                                <tbody>
                                  <tr>
                                    <td>{{ odds.redCard[0].label }}</td>
                                    <td>{{ odds.redCard[1].label }}</td>
                                  </tr>

                                  <tr>
                                    <td>{{ odds.redCard[0].value }}</td>
                                    <td>{{ odds.redCard[1].value }}</td>
                                  </tr>
                                </tbody>
                              </v-simple-table>
                            </div>
                          </div>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  
                  <div class="game-nav">
                    <div class="game-nav__btn" :class="{'game-nav__btn_active' : flags.card==0}" @click="card_flag(0)">{{ $t('head2head') }}</div>
                    <div class="game-nav__btn" :class="{'game-nav__btn_active' : flags.card==1}" @click="card_flag(1)">{{ $t('lastmatches') }}</div>
                    <div class="game-nav__btn" :class="{'game-nav__btn_active' : flags.card==2}" @click="card_flag(2)" :title="$t('message.similar3way')">{{ $t('similarmatches') }}</div>
                  </div>

                  <div v-if="!flags.card" class="mt-4">
                    <div v-if="!h2hLoaded">
                      <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                    </div>

                    <div v-else>
                      <v-container fluid>
                        <div class="row d-flex">
                          <v-select
                            v-model="h2hChosenLeagues"
                            :items="h2hLeagues"
                            item-text="league_name"
                            item-value="league_id"
                            class="col-12 col-sm-7 col-lg-9"
                            attach
                            dense
                            :label="$t('tournament')"
                            hide-details
                            multiple
                          />

                          <v-select
                            v-model="h2hChosenSeasons"
                            :items="h2hSeasons"
                            :label="$t('season')"
                            class="col-12 col-sm-5 col-lg-3"
                            attach
                            dense
                            hide-details
                            multiple
                          />
                        </div>

                        <div class="row d-flex flex-wrap justify-space-between mt-4">
                          <v-select
                            v-model="h2hFullHalf"
                            :items="timeCategories"
                            :item-text="item => $t(item.text)"
                            item-value="index"
                            :label="$t('time')"
                            class="col-5 col-sm-3 col-md-2"
                            attach
                            dense
                            hide-details
                          />

                          <v-checkbox
                            v-model="h2hLocal"
                            color="blue"
                            hide-details
                            dense
                            :label="$t('home')"
                            />
                          
                          <v-checkbox
                            v-model="h2hVisitor"
                            color="blue"
                            hide-details
                            dense
                            :label="$t('away')"
                            />

                          <template v-if="basic.local_coach && basic.visitor_coach">
                            <v-checkbox
                              v-model="h2hCurrentCoach"
                              color="blue"
                              hide-details
                              dense
                              :label="`${$t('currentcoach')}(${h2h_coach_count})`"
                              />
                            <v-checkbox
                              v-model="h2hPrevCoach"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('previouscoach')"
                              />
                          </template>

                          <v-select
                            v-model="h2hPerPage"
                            :items="h2hCountPerPage"
                            attach
                            dense
                            class="col-4 col-sm-2 col-md-1"
                            :hint="$t('countperpage')"
                          />

                          <v-text-field
                            v-model="h2hPPageByUser"
                            class="col-3 col-sm-2 col-md-1"
                            dense
                            :hint="$t('countperpage')"
                            type="number"
                            max=149
                            min=1
                            v-on:keyup.enter="h2hPerPageChange"
                          />
                        </div>
                      </v-container>

                      <v-simple-table
                        v-if="cardData.matches"
                        class="table-row-35 border-lightgrey text-center"
                        style="border-radius: 0">
                        <tbody>
                          <tr>
                            <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                            <td :title="$t('diff_yc.tooltip')">{{ $t('diff_yc.label') }}</td>
                            <td :title="$t('t_yc.tooltip')">{{ $t('t_yc.label') }}</td>
                            <td :title="$t('t1_yc.tooltip')">{{ $t('t1_yc.label') }}</td>
                            <td :title="$t('t2_yc.tooltip')">{{ $t('t2_yc.label') }}</td>
                            <td :title="$t('diff_rc.tooltip')">{{ $t('diff_rc.label') }}</td>
                            <td :title="$t('t_rc.tooltip')">{{ $t('t_rc.label') }}</td>
                            <td :title="$t('t1_rc.tooltip')">{{ $t('t1_rc.label') }}</td>
                            <td :title="$t('t2_rc.tooltip')">{{ $t('t2_rc.label') }}</td>
                          </tr>

                          <tr class="font-weight-bold">
                            <td>{{ cardData.matches }}</td>
                            <td>{{ cardData.diff_yc }}</td>
                            <td>{{ cardData.totalYC }}</td>
                            <td>{{ cardData.homeTotalYC }}</td>
                            <td>{{ cardData.awayTotalYC }}</td>
                            <td>{{ cardData.diff_rc }}</td>
                            <td>{{ cardData.totalRC }}</td>
                            <td>{{ cardData.homeTotalRC }}</td>
                            <td>{{ cardData.awayTotalRC }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>

                      <div class="curPointer pt-5 pb-2">
                        <a :class="{'d-none' : h2hPage == h2hLastPage}" class="ml-1 mr-4" @click="h2hPrev()">{{ $t('previous') }}</a>
                        <a :class="{'d-none' : !h2hPage}" @click="h2hNext()">{{ $t('next') }}</a>
                      </div>

                      <v-simple-table class="cell-py-10 border-lightgrey" style="border-radius: 0">
                        <thead>
                          <tr>
                            <th>{{ $t('date') }}</th>
                            <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                            <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                            <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('referee') }}</th>
                            <th>{{ $t('home') }}</th>
                            <th></th>
                            <th class="text-right">{{ $t('away') }}</th>
                            <th class="text-center">{{ $t('detail') }}</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="(game, index) in CGC[h2hPage]"
                            :key="index"
                            class="blue-grey--text"
                            :class="{'curPointer': (game.local_cards[0] != -1)}"
                            @click="cardModal(game.index, game.local_id, game.local_name, game.visitor_name, 0)">
                            <td class="overflow-anywhere">
                              <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                              <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                            </td>

                            <td :class="{'d-none': $vuetify.breakpoint.xs}" class="overflow-anywhere">
                              <router-link :to="{name: 'league', params: {lid: game.league_id}}" class="blue-grey--text">
                                {{ game.league_name }}
                              </router-link>
                            </td>

                            <td :class="{'d-none': $vuetify.breakpoint.xs}">{{ game.round_name ? game.round_name : '' }}</td>

                            <td
                              :class="{'d-none': $vuetify.breakpoint.xs}"
                              style="text-decoration-line: underline;"
                              @click.stop="modal4RefereeStats(game.referee_id)">
                              {{ game.referee }}
                            </td>

                            <td class="overflow-anywhere">{{ game.local_name }}</td>

                            <td>
                              <div class="d-flex justify-space-around align-center subtitle-2">
                                <div>
                                  <template 
                                    v-if="game.local_cards[0] == -1">
                                    ?
                                  </template>

                                  <template v-else>
                                    <span class="team_1_yellow_cards">{{ game.local_cards[0] }}</span>
                                    <br>
                                    <span class="team_1_red_cards">{{ game.local_cards[1] + game.local_cards[2] }}</span>
                                  </template>
                                </div>
                                
                                <div style="min-width: 11px;">
                                  <img src="/img/icons/yellow_card.png">
                                  <br>
                                  <img src="/img/icons/red_card.png">
                                </div>
                                
                                <div>
                                  <template 
                                    v-if="game.local_cards[0] == -1">
                                    ?
                                  </template>

                                  <template v-else>
                                    <span class='team_2_yellow_cards'>{{ game.visitor_cards[0] }}</span>
                                    <br>
                                    <span class='team_2_red_cards'>{{ game.visitor_cards[1] + game.visitor_cards[2] }}</span>
                                  </template>
                                </div>
                              </div>
                            </td>

                            <td class="overflow-anywhere text-right">{{ game.visitor_name }}</td>

                            <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </div>
                  </div>

                  <template v-else-if="flags.card==1">
                    <div v-if="!lastHomeLoaded && !lastAwayLoaded">
                      <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                    </div>

                    <div v-else-if="!authorization">
                      <v-alert
                        type="warning"
                        prominent
                      >
                        {{ $t(`message.${msg_last}`) }}
                      </v-alert>
                    </div>

                    <template v-else>
                      <div class="matches_heading">{{ basic.local_name }} {{ $t('lastmatches') }}</div>

                      <div>
                        <v-container fluid>
                          <div v-if='homeLeagueLoaded' class="row d-flex">
                            <v-select
                              v-model="homeChosenLeagues"
                              :items="HLeagues"
                              item-text="league_name"
                              item-value="league_id"
                              class="col-12 col-sm-7 col-lg-9"
                              attach
                              dense
                              :label="$t('tournament')"
                              hide-details
                              multiple
                            />

                            <v-select
                              v-model="homeChosenSeasons"
                              :items="HSeasons"
                              :label="$t('season')"
                              class="col-12 col-sm-5 col-lg-3"
                              attach
                              dense
                              hide-details
                              multiple
                            />
                          </div>

                          <div class="row d-flex flex-wrap justify-space-between mt-4">
                            <v-select
                              v-model="hLastFullHalf"
                              :items="timeCategories"
                              :item-text="item => $t(item.text)"
                              item-value="index"
                              :label="$t('time')"
                              class="col-5 col-sm-3 col-md-2"
                              attach
                              dense
                              hide-details
                            />

                            <v-checkbox
                              v-model="hLocal"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('home')"
                              />
                            
                            <v-checkbox
                              v-model="hVisitor"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('away')"
                              />
                            
                            <template v-if="basic.local_coach">
                              <v-checkbox
                                v-model="hCurrentCoach"
                                color="blue"
                                hide-details
                                dense
                                :label="`${$t('currentcoach')}(${local_coach_count})`"
                                />
                              <v-checkbox
                                v-model="hPrevCoach"
                                color="blue"
                                hide-details
                                dense
                                :label="$t('previouscoach')"
                                />
                            </template>

                            <v-select
                              v-model="homePerPage"
                              :items="countPerPage"
                              attach
                              dense
                              class="col-4 col-sm-2 col-md-1"
                              :hint="$t('countperpage')"
                            />

                            <v-text-field
                              v-model="hPPageByUser"
                              class="col-3 col-sm-2 col-md-1"
                              dense
                              :hint="$t('countperpage')"
                              type="number"
                              max=149
                              min=1
                              v-on:keyup.enter="homePerPageChange"
                            />
                          </div>
                        </v-container>

                        <template v-if="lastHomeLoaded">
                        <v-simple-table
                          v-if="hLastAvgLoaded && hLastAvg.cardMatch"
                          class="table-row-35 border-lightgrey text-center"
                          style="border-radius: 0">
                          <tbody>
                            <tr>
                              <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                              <td :title="$t('diff_yc.tooltip')">{{ $t('diff_yc.label') }}</td>
                              <td :title="$t('t_yc.tooltip')">{{ $t('t_yc.label') }}</td>
                              <td :title="$t('t1_yc.tooltip')">{{ $t('t1_yc.label') }}</td>
                              <td :title="$t('t2_yc.tooltip')">{{ $t('t2_yc.label') }}</td>
                              <td :title="$t('diff_rc.tooltip')">{{ $t('diff_rc.label') }}</td>
                              <td :title="$t('t_rc.tooltip')">{{ $t('t_rc.label') }}</td>
                              <td :title="$t('t1_rc.tooltip')">{{ $t('t1_rc.label') }}</td>
                              <td :title="$t('t2_rc.tooltip')">{{ $t('t2_rc.label') }}</td>
                            </tr>
                            
                            <tr class="font-weight-bold">
                              <td>{{ hLastAvg.cardMatch }}</td>
                              <td>{{ hLastAvg.cardDiff_yc }}</td>
                              <td>{{ hLastAvg.cardTotal_yc }}</td>
                              <td>{{ hLastAvg.cardT1_yc }}</td>
                              <td>{{ hLastAvg.cardT2_yc }}</td>
                              <td>{{ hLastAvg.cardDiff_rc }}</td>
                              <td>{{ hLastAvg.cardTotal_rc }}</td>
                              <td>{{ hLastAvg.cardT1_rc }}</td>
                              <td>{{ hLastAvg.cardT2_rc }}</td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                        
                        <div class="curPointer pt-5 pb-2">
                          <a :class="{'d-none' : homePage == hLastPage}" class="ml-1 mr-4" @click="homePrev()">{{ $t('previous') }}</a>
                          <a :class="{'d-none' : !homePage}" @click="homeNext()">{{ $t('next') }}</a>
                        </div>

                        <v-simple-table class="cell-py-10 border-lightgrey" style="border-radius: 0">
                          <thead>
                            <tr>
                              <th>{{ $t('date') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('referee') }}</th>
                              <th>{{ $t('home') }}</th>
                              <th></th>
                              <th class="text-right">{{ $t('away') }}</th>
                              <th class="text-center">{{ $t('detail') }}</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-for="(game, index) in Hlast[homePage]"
                              :key="index"
                              :class="{'curPointer': (game.local_cards.yellow != -1)}"
                              @click="cardModal(index, game.local_id, game.local_name, game.visitor_name, 1)">
                              <td class="overflow-anywhere blue-grey--text">
                                <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                                <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="overflow-anywhere">
                                <router-link :to="{name: 'league', params: {lid: game.league.league_id}}" class="blue-grey--text">
                                  {{ game.league.name }}
                                </router-link>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="blue-grey--text">
                                {{ game.round ? game.round.name : '' }}
                              </td>

                              <td
                                :class="{'d-none': $vuetify.breakpoint.xs}"
                                class="blue-grey--text"
                                style="text-decoration-line: underline;"
                                @click.stop="modal4RefereeStats(game.referee)">
                                {{ game.referee_name }}
                              </td>

                              <td v-if="game.local_id != basic.local_id">
                                <router-link :to="{name: 'team', params: {id: game.local_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.local_name }}
                                </router-link>
                              </td>

                              <td v-else class="overflow-anywhere black--text">{{ game.local_name }}</td>

                              <td class="blue-grey--text">
                                <div class="d-flex justify-space-around align-center subtitle-2">
                                  <div>
                                    <template 
                                      v-if="game.local_cards.yellow == -1">
                                      ?
                                    </template>

                                    <template v-else>
                                      <span class="team_1_yellow_cards">{{ game.local_cards.yellow }}</span><br>
                                      <span class="team_1_red_cards">{{ game.local_cards.red }}</span>
                                    </template>
                                  </div>
                                  
                                  <div style="min-width: 11px;">
                                    <img src="/img/icons/yellow_card.png"><br>
                                    <img src="/img/icons/red_card.png">
                                  </div>
                                  
                                  <div>
                                    <template 
                                      v-if="game.local_cards.yellow == -1">
                                      ?
                                    </template>

                                    <template v-else>
                                      <span class='team_2_yellow_cards'>{{ game.visitor_cards.yellow }}</span><br>
                                      <span class='team_2_red_cards'>{{ game.visitor_cards.red }}</span>
                                    </template>
                                  </div>
                                </div>
                              </td>

                              <td v-if="game.visitor_id != basic.local_id" class="text-right">
                                <router-link :to="{name: 'team', params: {id: game.visitor_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.visitor_name }}
                                </router-link>
                              </td>

                              <td v-else class="overflow-anywhere text-right black--text">{{ game.visitor_name }}</td>

                              <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                        </template>

                        <div v-else>
                          <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                        </div>
                      </div>

                      <div class="matches_heading">{{ basic.visitor_name }} {{ $t('lastmatches') }}</div>

                      <div>
                        <v-container fluid>
                          <div v-if='awayLeagueLoaded' class="row d-flex">
                            <v-select
                              v-model="awayChosenLeagues"
                              :items="ALeagues"
                              item-text="league_name"
                              item-value="league_id"
                              class="col-12 col-sm-7 col-lg-9"
                              attach
                              dense
                              :label="$t('tournament')"
                              hide-details
                              multiple
                            />

                            <v-select
                              v-model="awayChosenSeasons"
                              :items="ASeasons"
                              :label="$t('season')"
                              class="col-12 col-sm-5 col-lg-3"
                              attach
                              dense
                              hide-details
                              multiple
                            />
                          </div>

                          <div class="row d-flex flex-wrap justify-space-between mt-4">
                            <v-select
                              v-model="aLastFullHalf"
                              :items="timeCategories"
                              :item-text="item => $t(item.text)"
                              item-value="index"
                              :label="$t('time')"
                              class="col-5 col-sm-3 col-md-2"
                              attach
                              dense
                              hide-details
                            />

                            <v-checkbox
                              v-model="aLocal"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('home')"
                              />
                            
                            <v-checkbox
                              v-model="aVisitor"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('away')"
                              />
                            
                            <template v-if="basic.visitor_coach">
                              <v-checkbox
                                v-model="aCurrentCoach"
                                color="blue"
                                hide-details
                                dense
                                :label="`${$t('currentcoach')}(${visitor_coach_count})`"
                                />
                              <v-checkbox
                                v-model="aPrevCoach"
                                color="blue"
                                hide-details
                                dense
                                :label="$t('previouscoach')"
                                />
                            </template>

                            <v-select
                              v-model="awayPerPage"
                              :items="countPerPage"
                              attach
                              dense
                              class="col-4 col-sm-2 col-md-1"
                              :hint="$t('countperpage')"
                            />

                            <v-text-field
                              v-model="aPPageByUser"
                              class="col-3 col-sm-2 col-md-1"
                              dense
                              :hint="$t('countperpage')"
                              type="number"
                              max=149
                              min=1
                              v-on:keyup.enter="awayPerPageChange"
                            />
                          </div>
                        </v-container>

                        <template v-if="lastAwayLoaded">
                        <v-simple-table
                          v-if="aLastAvgLoaded && aLastAvg.cardMatch"
                          class="table-row-35 border-lightgrey text-center"
                          style="border-radius: 0">
                          <tbody>
                            <tr>
                              <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                              <td :title="$t('diff_yc.tooltip')">{{ $t('diff_yc.label') }}</td>
                              <td :title="$t('t_yc.tooltip')">{{ $t('t_yc.label') }}</td>
                              <td :title="$t('t1_yc.tooltip')">{{ $t('t1_yc.label') }}</td>
                              <td :title="$t('t2_yc.tooltip')">{{ $t('t2_yc.label') }}</td>
                              <td :title="$t('diff_rc.tooltip')">{{ $t('diff_rc.label') }}</td>
                              <td :title="$t('t_rc.tooltip')">{{ $t('t_rc.label') }}</td>
                              <td :title="$t('t1_rc.tooltip')">{{ $t('t1_rc.label') }}</td>
                              <td :title="$t('t2_rc.tooltip')">{{ $t('t2_rc.label') }}</td>
                            </tr>
                            
                            <tr class="font-weight-bold">
                              <td>{{ aLastAvg.cardMatch }}</td>
                              <td>{{ aLastAvg.cardDiff_yc }}</td>
                              <td>{{ aLastAvg.cardTotal_yc }}</td>
                              <td>{{ aLastAvg.cardT1_yc }}</td>
                              <td>{{ aLastAvg.cardT2_yc }}</td>
                              <td>{{ aLastAvg.cardDiff_rc }}</td>
                              <td>{{ aLastAvg.cardTotal_rc }}</td>
                              <td>{{ aLastAvg.cardT1_rc }}</td>
                              <td>{{ aLastAvg.cardT2_rc }}</td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                        
                        <div class="curPointer pt-5 pb-2">
                          <a :class="{'d-none' : awayPage == aLastPage}" class="ml-1 mr-4" @click="awayPrev()">{{ $t('previous') }}</a>
                          <a :class="{'d-none' : !awayPage}" @click="awayNext()">{{ $t('next') }}</a>
                        </div>

                        <v-simple-table class="cell-py-10 border-lightgrey" style="border-radius: 0">
                          <thead>
                            <tr>
                              <th>{{ $t('date') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('referee') }}</th>
                              <th>{{ $t('home') }}</th>
                              <th></th>
                              <th class="text-right">{{ $t('away') }}</th>
                              <th class="text-center">{{ $t('detail') }}</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-for="(game, index) in Alast[awayPage]"
                              :key="index"
                              :class="{'curPointer': (game.local_cards.yellow != -1)}"
                              @click="cardModal(index, game.local_id, game.local_name, game.visitor_name, 2)">
                              <td class="overflow-anywhere blue-grey--text">
                                <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                                <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="overflow-anywhere">
                                <router-link :to="{name: 'league', params: {lid: game.league.league_id}}" class="blue-grey--text">
                                  {{ game.league.name }}
                                </router-link>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="blue-grey--text">
                                {{ game.round ? game.round.name : '' }}
                              </td>

                              <td
                                :class="{'d-none': $vuetify.breakpoint.xs}"
                                class="blue-grey--text"
                                style="text-decoration-line: underline;"
                                @click.stop="modal4RefereeStats(game.referee)">
                                {{ game.referee_name }}
                              </td>

                              <td v-if="game.local_id != basic.visitor_id">
                                <router-link :to="{name: 'team', params: {id: game.local_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.local_name }}
                                </router-link>
                              </td>

                              <td v-else class="overflow-anywhere black--text">{{ game.local_name }}</td>

                              <td class="blue-grey--text">
                                <div class="d-flex justify-space-around align-center subtitle-2">
                                  <div>
                                    <template 
                                      v-if="game.local_cards.yellow == -1">
                                      ?
                                    </template>

                                    <template v-else>
                                      <span class="team_1_yellow_cards">{{ game.local_cards.yellow }}</span><br>
                                      <span class="team_1_red_cards">{{ game.local_cards.red }}</span>
                                    </template>
                                  </div>

                                  <div style="min-width: 11px;">
                                    <img src="/img/icons/yellow_card.png"><br>
                                    <img src="/img/icons/red_card.png">
                                  </div>

                                  <div>
                                    <template 
                                      v-if="game.local_cards.yellow == -1">
                                      ?
                                    </template>

                                    <template v-else>
                                      <span class='team_2_yellow_cards'>{{ game.visitor_cards.yellow }}</span><br>
                                      <span class='team_2_red_cards'>{{ game.visitor_cards.red }}</span>
                                    </template>
                                  </div>
                                </div>
                              </td>

                              <td v-if="game.visitor_id != basic.visitor_id" class="text-right">
                                <router-link :to="{name: 'team', params: {id: game.visitor_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.visitor_name }}
                                </router-link>
                              </td>

                              <td v-else class="overflow-anywhere text-right black--text">{{ game.visitor_name }}</td>

                              <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                        </template>

                        <div v-else>
                          <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                        </div>
                      </div>
                    </template>
                  </template>

                  <template v-else>
                    <div v-if="!odds.FT">
                      <v-alert
                        type="info"
                        prominent
                      >
                        {{ $t('message.impossibleSM') }}
                      </v-alert>
                    </div>

                    <div v-else-if="!similarHomeLoaded && !similarAwayLoaded">
                      <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                    </div>

                    <div v-else-if="!authorization">
                      <v-alert
                        type="warning"
                        prominent
                      >
                        {{ $t(`message.${msg_last}`) }}
                      </v-alert>
                    </div>

                    <template v-else>
                      <div class="matches_heading">{{ basic.local_name }} {{ $t('similarmatches') }}</div>

                      <div>
                        <v-container fluid>
                          <div v-if='homeLeagueLoaded' class="row d-flex">
                            <v-select
                              v-model="hSChosenLeagues"
                              :items="HLeagues"
                              item-text="league_name"
                              item-value="league_id"
                              class="col-12 col-sm-7 col-lg-9"
                              attach
                              dense
                              :label="$t('tournament')"
                              hide-details
                              multiple
                            />

                            <v-select
                              v-model="hSChosenSeasons"
                              :items="HSeasons"
                              :label="$t('season')"
                              class="col-12 col-sm-5 col-lg-3"
                              attach
                              dense
                              hide-details
                              multiple
                            />
                          </div>

                          <div class="row d-flex flex-wrap justify-space-between mt-4">
                            <v-select
                              v-model="hSimilarFullHalf"
                              :items="timeCategories"
                              :item-text="item => $t(item.text)"
                              item-value="index"
                              :label="$t('time')"
                              class="col-5 col-sm-3 col-md-2"
                              attach
                              dense
                              hide-details
                            />

                            <v-checkbox
                              v-model="hSLocal"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('home')"
                              />
                            
                            <v-checkbox
                              v-model="hSVisitor"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('away')"
                              />
                            
                            <template v-if="basic.local_coach">
                              <v-checkbox
                                v-model="hSCurrentCoach"
                                color="blue"
                                hide-details
                                dense
                                :label="`${$t('currentcoach')}(${ls_coach_count})`"
                                />
                              <v-checkbox
                                v-model="hSPrevCoach"
                                color="blue"
                                hide-details
                                dense
                                :label="$t('previouscoach')"
                                />
                            </template>

                            <v-select
                              v-model="hSPerPage"
                              :items="countPerPage"
                              attach
                              dense
                              class="col-4 col-sm-2 col-md-1"
                              :hint="$t('countperpage')"
                            />

                            <v-text-field
                              v-model="hSPPageByUser"
                              class="col-3 col-sm-2 col-md-1"
                              dense
                              :hint="$t('countperpage')"
                              type="number"
                              max=149
                              min=1
                              v-on:keyup.enter="hSPerPageChange"
                            />
                          </div>
                        </v-container>

                        <template v-if="similarHomeLoaded">
                        <v-simple-table
                          v-if="hSimilarAvgLoaded && hSimilarAvg.cardMatch"
                          class="table-row-35 border-lightgrey text-center"
                          style="border-radius: 0">
                          <tbody>
                            <tr>
                              <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                              <td :title="$t('diff_yc.tooltip')">{{ $t('diff_yc.label') }}</td>
                              <td :title="$t('t_yc.tooltip')">{{ $t('t_yc.label') }}</td>
                              <td :title="$t('t1_yc.tooltip')">{{ $t('t1_yc.label') }}</td>
                              <td :title="$t('t2_yc.tooltip')">{{ $t('t2_yc.label') }}</td>
                              <td :title="$t('diff_rc.tooltip')">{{ $t('diff_rc.label') }}</td>
                              <td :title="$t('t_rc.tooltip')">{{ $t('t_rc.label') }}</td>
                              <td :title="$t('t1_rc.tooltip')">{{ $t('t1_rc.label') }}</td>
                              <td :title="$t('t2_rc.tooltip')">{{ $t('t2_rc.label') }}</td>
                            </tr>
                            
                            <tr class="font-weight-bold">
                              <td>{{ hSimilarAvg.cardMatch }}</td>
                              <td>{{ hSimilarAvg.cardDiff_yc }}</td>
                              <td>{{ hSimilarAvg.cardTotal_yc }}</td>
                              <td>{{ hSimilarAvg.cardT1_yc }}</td>
                              <td>{{ hSimilarAvg.cardT2_yc }}</td>
                              <td>{{ hSimilarAvg.cardDiff_rc }}</td>
                              <td>{{ hSimilarAvg.cardTotal_rc }}</td>
                              <td>{{ hSimilarAvg.cardT1_rc }}</td>
                              <td>{{ hSimilarAvg.cardT2_rc }}</td>
                            </tr>
                          </tbody>
                        </v-simple-table>

                        <div class="curPointer pt-5 pb-2">
                          <a :class="{'d-none' : hSPage == hSLastPage}" class="ml-1 mr-4" @click="hSPrev()">{{ $t('previous') }}</a>
                          <a :class="{'d-none' : !hSPage}" @click="hSNext()">{{ $t('next') }}</a>
                        </div>

                        <v-simple-table class="cell-py-10 border-lightgrey" style="border-radius: 0">
                          <thead>
                            <tr>
                              <th>{{ $t('date') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('referee') }}</th>

                              <th>
                                {{ $t('home') }}
                                <template v-if="$vuetify.breakpoint.xs">({{ $t('w.label') }})</template>
                              </th>

                              <th class="text-center">
                                <template v-if="$vuetify.breakpoint.xs">({{ $t('d.label') }})</template>
                              </th>

                              <th class="text-right">
                                {{ $t('away') }}
                                <template v-if="$vuetify.breakpoint.xs">({{ $t('l.label') }})</template>
                              </th>

                              <th :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center">{{ $t('w.label') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center">{{ $t('d.label') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center">{{ $t('l.label') }}</th>
                              <th class="text-center">{{ $t('detail') }}</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-for="(game, index) in hSimilar[hSPage]"
                              :key="index"
                              :class="{'curPointer': (game.local_cards.yellow != -1)}"
                              @click="cardModal(index, game.local_id, game.local_name, game.visitor_name, 3)">
                              <td class="overflow-anywhere blue-grey--text">
                                <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                                <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}">
                                <router-link :to="{name: 'league', params: {lid: game.league.league_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.league.name }}
                                </router-link>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="blue-grey--text">
                                {{ game.round ? game.round.name : '' }}
                              </td>

                              <td
                                :class="{'d-none': $vuetify.breakpoint.xs}"
                                class="blue-grey--text"
                                style="text-decoration-line: underline;"
                                @click.stop="modal4RefereeStats(game.referee)">
                                {{ game.referee_name }}
                              </td>

                              <td class="overflow-anywhere">
                                <template v-if="game.local_id != basic.local_id">
                                  <router-link :to="{name: 'team', params: {id: game.local_id}}" class="blue-grey--text">
                                    {{ game.local_name }}
                                  </router-link>
                                </template>

                                <span v-else class="black--text">{{ game.local_name }}</span>

                                <span v-if="$vuetify.breakpoint.xs"> ({{ game.three_way[1] }})</span>
                              </td>

                              <td class="blue-grey--text text-center">
                                <div class="d-flex justify-space-around align-center subtitle-2">
                                  <div>
                                    <template 
                                      v-if="game.local_cards.yellow == -1">
                                      ?
                                    </template>

                                    <template v-else>
                                      <span class="team_1_yellow_cards">{{ game.local_cards.yellow }}</span><br>
                                      <span class="team_1_red_cards">{{ game.local_cards.red }}</span>
                                    </template>
                                  </div>
                                  
                                  <div style="min-width: 11px;">
                                    <img src="/img/icons/yellow_card.png"><br>
                                    <img src="/img/icons/red_card.png">
                                  </div>

                                  <div>
                                    <template 
                                      v-if="game.local_cards.yellow == -1">
                                      ?
                                    </template>

                                    <template v-else>
                                      <span class='team_2_yellow_cards'>{{ game.visitor_cards.yellow }}</span><br>
                                      <span class='team_2_red_cards'>{{ game.visitor_cards.red }}</span>
                                    </template>
                                  </div>
                                </div>

                                <span v-if="$vuetify.breakpoint.xs">({{ game.three_way.X }})</span>
                              </td>

                              <td class="overflow-anywhere text-right">
                                <template v-if="game.visitor_id != basic.local_id">
                                  <router-link :to="{name: 'team', params: {id: game.visitor_id}}" class="blue-grey--text">
                                    {{ game.visitor_name }}
                                  </router-link>
                                </template>

                                <span v-else class="black--text">{{ game.visitor_name }}</span>

                                <span v-if="$vuetify.breakpoint.xs"> ({{ game.three_way[2] }})</span>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center blue-grey--text">{{ game.three_way[1] }}</td>
                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center blue-grey--text">{{ game.three_way.X }}</td>
                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center blue-grey--text">{{ game.three_way[2] }}</td>

                              <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                        </template>

                        <div v-else>
                          <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                        </div>
                      </div>
                      
                      <div class="matches_heading">{{ basic.visitor_name }} {{ $t('similarmatches') }}</div>

                      <div>
                        <v-container fluid>
                          <div v-if='awayLeagueLoaded' class="row d-flex">
                            <v-select
                              v-model="aSChosenLeagues"
                              :items="ALeagues"
                              item-text="league_name"
                              item-value="league_id"
                              class="col-12 col-sm-7 col-lg-9"
                              attach
                              dense
                              :label="$t('tournament')"
                              hide-details
                              multiple
                            />

                            <v-select
                              v-model="aSChosenSeasons"
                              :items="ASeasons"
                              :label="$t('season')"
                              class="col-12 col-sm-5 col-lg-3"
                              attach
                              dense
                              hide-details
                              multiple
                            />
                          </div>

                          <div class="row d-flex flex-wrap justify-space-between mt-4">
                            <v-select
                              v-model="aSimilarFullHalf"
                              :items="timeCategories"
                              :item-text="item => $t(item.text)"
                              item-value="index"
                              :label="$t('time')"
                              class="col-5 col-sm-3 col-md-2"
                              attach
                              dense
                              hide-details
                            />

                            <v-checkbox
                              v-model="aSLocal"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('home')"
                              />
                            
                            <v-checkbox
                              v-model="aSVisitor"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('away')"
                              />
                            
                            <template v-if="basic.visitor_coach">
                              <v-checkbox
                                v-model="aSCurrentCoach"
                                color="blue"
                                hide-details
                                dense
                                :label="`${$t('currentcoach')}(${vs_coach_count})`"
                                />
                              <v-checkbox
                                v-model="aSPrevCoach"
                                color="blue"
                                hide-details
                                dense
                                :label="$t('previouscoach')"
                                />
                            </template>
                            
                            <v-select
                              v-model="aSPerPage"
                              :items="countPerPage"
                              attach
                              dense
                              class="col-4 col-sm-2 col-md-1"
                              :hint="$t('countperpage')"
                            />

                            <v-text-field
                              v-model="aSPPageByUser"
                              class="col-3 col-sm-2 col-md-1"
                              dense
                              :hint="$t('countperpage')"
                              type="number"
                              max=149
                              min=1
                              v-on:keyup.enter="aSPerPageChange"
                            />
                          </div>
                        </v-container>

                        <template v-if="similarAwayLoaded">
                        <v-simple-table
                          v-if="aSimilarAvgLoaded && aSimilarAvg.cardMatch"
                          class="table-row-35 border-lightgrey text-center"
                          style="border-radius: 0">
                          <tbody>
                            <tr>
                              <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                              <td :title="$t('diff_yc.tooltip')">{{ $t('diff_yc.label') }}</td>
                              <td :title="$t('t_yc.tooltip')">{{ $t('t_yc.label') }}</td>
                              <td :title="$t('t1_yc.tooltip')">{{ $t('t1_yc.label') }}</td>
                              <td :title="$t('t2_yc.tooltip')">{{ $t('t2_yc.label') }}</td>
                              <td :title="$t('diff_rc.tooltip')">{{ $t('diff_rc.label') }}</td>
                              <td :title="$t('t_rc.tooltip')">{{ $t('t_rc.label') }}</td>
                              <td :title="$t('t1_rc.tooltip')">{{ $t('t1_rc.label') }}</td>
                              <td :title="$t('t2_rc.tooltip')">{{ $t('t2_rc.label') }}</td>
                            </tr>
                            
                            <tr class="font-weight-bold">
                              <td>{{ aSimilarAvg.cardMatch }}</td>
                              <td>{{ aSimilarAvg.cardDiff_yc }}</td>
                              <td>{{ aSimilarAvg.cardTotal_yc }}</td>
                              <td>{{ aSimilarAvg.cardT1_yc }}</td>
                              <td>{{ aSimilarAvg.cardT2_yc }}</td>
                              <td>{{ aSimilarAvg.cardDiff_rc }}</td>
                              <td>{{ aSimilarAvg.cardTotal_rc }}</td>
                              <td>{{ aSimilarAvg.cardT1_rc }}</td>
                              <td>{{ aSimilarAvg.cardT2_rc }}</td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                        
                        <div class="curPointer pt-5 pb-2">
                          <a :class="{'d-none' : aSPage == aSLastPage}" class="ml-1 mr-4" @click="aSPrev()">{{ $t('previous') }}</a>
                          <a :class="{'d-none' : !aSPage}" @click="aSNext()">{{ $t('next') }}</a>
                        </div>

                        <v-simple-table class="cell-py-10 border-lightgrey" style="border-radius: 0">
                          <thead>
                            <tr>
                              <th>{{ $t('date') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('referee') }}</th>
                              <th>
                                {{ $t('home') }}
                                <template v-if="$vuetify.breakpoint.xs">({{ $t('w.label') }})</template>
                              </th>

                              <th class="text-center">
                                <template v-if="$vuetify.breakpoint.xs">({{ $t('d.label') }})</template>
                              </th>

                              <th class="text-right">
                                {{ $t('away') }}
                                <template v-if="$vuetify.breakpoint.xs">({{ $t('l.label') }})</template>
                              </th>

                              <th :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center">{{ $t('w.label') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center">{{ $t('d.label') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center">{{ $t('l.label') }}</th>
                              <th class="text-center">{{ $t('detail') }}</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-for="(game, index) in aSimilar[aSPage]"
                              :key="index"
                              :class="{'curPointer': (game.local_cards.yellow != -1)}"
                              @click="cardModal(index, game.local_id, game.local_name, game.visitor_name, 4)">
                              <td class="overflow-anywhere blue-grey--text">
                                <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                                <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}">
                                <router-link :to="{name: 'league', params: {lid: game.league.league_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.league.name }}
                                </router-link>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="blue-grey--text">
                                {{ game.round ? game.round.name : '' }}
                              </td>

                              <td
                                :class="{'d-none': $vuetify.breakpoint.xs}"
                                class="blue-grey--text"
                                style="text-decoration-line: underline;"
                                @click.stop="modal4RefereeStats(game.referee)">
                                {{ game.referee_name }}
                              </td>

                              <td class="overflow-anywhere">
                                <template v-if="game.local_id != basic.visitor_id">
                                  <router-link :to="{name: 'team', params: {id: game.local_id}}" class="blue-grey--text">
                                    {{ game.local_name }}
                                  </router-link>
                                </template>

                                <span v-else class="black--text">{{ game.local_name }}</span>

                                <span v-if="$vuetify.breakpoint.xs"> ({{ game.three_way[1] }})</span>
                              </td>

                              <td class="blue-grey--text text-center">
                                <div class="d-flex justify-space-around align-center subtitle-2">
                                  <div>
                                    <template 
                                      v-if="game.local_cards.yellow == -1">
                                      ?
                                    </template>

                                    <template v-else>
                                      <span class="team_1_yellow_cards">{{ game.local_cards.yellow }}</span><br>
                                      <span class="team_1_red_cards">{{ game.local_cards.red }}</span>
                                    </template>
                                  </div>

                                  <div style="min-width: 11px;">
                                    <img src="/img/icons/yellow_card.png"><br>
                                    <img src="/img/icons/red_card.png">
                                  </div>
                                  
                                  <div>
                                    <template 
                                      v-if="game.local_cards.yellow == -1">
                                      ?
                                    </template>

                                    <template v-else>
                                      <span class='team_2_yellow_cards'>{{ game.visitor_cards.yellow }}</span><br>
                                      <span class='team_2_red_cards'>{{ game.visitor_cards.red }}</span>
                                    </template>
                                  </div>
                                </div>

                                <span v-if="$vuetify.breakpoint.xs">({{ game.three_way.X }})</span>
                              </td>

                              <td class="overflow-anywhere text-right">
                                <template v-if="game.visitor_id != basic.visitor_id">
                                  <router-link :to="{name: 'team', params: {id: game.visitor_id}}" class="blue-grey--text">
                                    {{ game.visitor_name }}
                                  </router-link>
                                </template>

                                <span v-else class="black--text">{{ game.visitor_name }}</span>

                                <span v-if="$vuetify.breakpoint.xs"> ({{ game.three_way[2] }})</span>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center blue-grey--text">{{ game.three_way[1] }}</td>
                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center blue-grey--text">{{ game.three_way.X }}</td>
                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center blue-grey--text">{{ game.three_way[2] }}</td>

                              <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                        </template>

                        <div v-else>
                          <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                        </div>
                      </div>
                    </template>
                  </template>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item key="Stats">
              <v-row class="no-gutters" justify="center">
                <v-col lg="8" md="10">
                  <div class="center col-lg-6 col-md-7">
                    <v-select
                      v-model="chosenCategory"
                      :items="categories"
                      item-text="text"
                      item-value="index"
                      attach
                      hide-details
                      dense
                      :label="$t('choosecategory')"
                      outlined
                    />
                  </div>

                  <v-expansion-panels
                    v-if="statsOddsLoaded && show_odds_market && ((chosenCategory == 0 && odds.tSh) || (chosenCategory == 1 && odds.tShT) 
                      || (chosenCategory == 14 && odds.tOffs) || (chosenCategory == 15 && (odds.sPt || odds.mPt || odds.penalty)))"
                    v-model="expanding_market[3]"
                    hover
                    flat
                    tile>
                    <v-expansion-panel class="mb-2">
                      <v-expansion-panel-header class="grey lighten-2 grey--text text--darken-1">
                        {{ $t('odds.oddsmarket') }}
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>
                  <!-- <div class="center mb-3"> -->
                        <div v-if="chosenCategory == 0 && odds.tSh" class="p-2 grey lighten-3 d-flex flex-wrap justify-space-around text-center">
                          <div>
                            <span>{{ basic.local_name }}</span>

                            <v-simple-table
                              class="table-row-35 border-lightgrey text-center"
                              style="border-radius: 0; box-shadow: 1px 1px 3px lightgrey;">
                              <tbody>
                                <tr>
                                  <td>{{ $t(moreOdds['tSh'][0][0].handicap[0]) }} {{ moreOdds['tSh'][0][0].handicap[1] }}</td>
                                  <td>{{ $t(moreOdds['tSh'][0][1].handicap[0]) }} {{ moreOdds['tSh'][0][1].handicap[1] }}</td>
                                </tr>

                                <tr>
                                  <td>{{ moreOdds['tSh'][0][0].value }}</td>
                                  <td>{{ moreOdds['tSh'][0][1].value }}</td>
                                </tr>
                              </tbody>
                            </v-simple-table>
                          </div>

                          <div>
                            <span>{{ basic.visitor_name }}</span>

                            <v-simple-table
                              class="table-row-35 border-lightgrey text-center"
                              style="border-radius: 0; box-shadow: 1px 1px 3px lightgrey;">
                              <tbody>
                                <tr>
                                  <td>{{ $t(moreOdds['tSh'][1][0].handicap[0]) }} {{ moreOdds['tSh'][1][0].handicap[1] }}</td>
                                  <td>{{ $t(moreOdds['tSh'][1][1].handicap[0]) }} {{ moreOdds['tSh'][1][1].handicap[1] }}</td>
                                </tr>

                                <tr>
                                  <td>{{ moreOdds['tSh'][1][0].value }}</td>
                                  <td>{{ moreOdds['tSh'][1][1].value }}</td>
                                </tr>
                              </tbody>
                            </v-simple-table>
                          </div>
                        </div>

                        <div v-if="chosenCategory == 1 && odds.tShT" class="p-2 grey lighten-3 d-flex flex-wrap justify-space-around text-center">
                          <div>
                            <span>{{ basic.local_name }}</span>

                            <v-simple-table
                              class="table-row-35 border-lightgrey text-center"
                              style="border-radius: 0; box-shadow: 1px 1px 3px lightgrey;">
                              <tbody>
                                <tr>
                                  <td>{{ $t(moreOdds['tShT'][0][0].handicap[0]) }} {{ moreOdds['tShT'][0][0].handicap[1] }}</td>
                                  <td>{{ $t(moreOdds['tShT'][0][1].handicap[0]) }} {{ moreOdds['tShT'][0][1].handicap[1] }}</td>
                                </tr>

                                <tr>
                                  <td>{{ moreOdds['tShT'][0][0].value }}</td>
                                  <td>{{ moreOdds['tShT'][0][1].value }}</td>
                                </tr>
                              </tbody>
                            </v-simple-table>
                          </div>

                          <div>
                            <span>{{ basic.visitor_name }}</span>

                            <v-simple-table
                              class="table-row-35 border-lightgrey text-center"
                              style="border-radius: 0; box-shadow: 1px 1px 3px lightgrey;">
                              <tbody>
                                <tr>
                                  <td>{{ $t(moreOdds['tShT'][1][0].handicap[0]) }} {{ moreOdds['tShT'][1][0].handicap[1] }}</td>
                                  <td>{{ $t(moreOdds['tShT'][1][1].handicap[0]) }} {{ moreOdds['tShT'][1][1].handicap[1] }}</td>
                                </tr>

                                <tr>
                                  <td>{{ moreOdds['tShT'][1][0].value }}</td>
                                  <td>{{ moreOdds['tShT'][1][1].value }}</td>
                                </tr>
                              </tbody>
                            </v-simple-table>
                          </div>
                        </div>

                        <div v-if="chosenCategory == 14 && odds.tOffs" class="p-2 grey lighten-3 d-flex flex-wrap justify-space-around text-center">
                          <div>
                            <span>{{ basic.local_name }}</span>

                            <v-simple-table
                              class="table-row-35 border-lightgrey text-center"
                              style="border-radius: 0; box-shadow: 1px 1px 3px lightgrey;">
                              <tbody>
                                <tr>
                                  <td>{{ $t(moreOdds['tOffs'][0][0].handicap[0]) }} {{ moreOdds['tOffs'][0][0].handicap[1] }}</td>
                                  <td>{{ $t(moreOdds['tOffs'][0][1].handicap[0]) }} {{ moreOdds['tOffs'][0][1].handicap[1] }}</td>
                                </tr>

                                <tr>
                                  <td>{{ moreOdds['tOffs'][0][0].value }}</td>
                                  <td>{{ moreOdds['tOffs'][0][1].value }}</td>
                                </tr>
                              </tbody>
                            </v-simple-table>
                          </div>

                          <div>
                            <span>{{ basic.visitor_name }}</span>

                            <v-simple-table
                              class="table-row-35 border-lightgrey text-center"
                              style="border-radius: 0; box-shadow: 1px 1px 3px lightgrey;">
                              <tbody>
                                <tr>
                                  <td>{{ $t(moreOdds['tOffs'][1][0].handicap[0]) }} {{ moreOdds['tOffs'][1][0].handicap[1] }}</td>
                                  <td>{{ $t(moreOdds['tOffs'][1][1].handicap[0]) }} {{ moreOdds['tOffs'][1][1].handicap[1] }}</td>
                                </tr>

                                <tr>
                                  <td>{{ moreOdds['tOffs'][1][0].value }}</td>
                                  <td>{{ moreOdds['tOffs'][1][1].value }}</td>
                                </tr>
                              </tbody>
                            </v-simple-table>
                          </div>
                        </div>
                        
                        <div v-if="chosenCategory == 15 && (odds.sPt || odds.mPt || odds.penalty)" class="grey lighten-3">
                          <div class="d-flex flex-wrap p-1">
                            <v-checkbox
                              :disabled="!odds.sPt"
                              v-model="show_odds_market[13]"
                              color="blue"
                              class="mr-1"
                              hide-details
                              dense
                              label="Score a Penalty"
                              />
                            <v-checkbox
                              :disabled="!odds.mPt"
                              v-model="show_odds_market[14]"
                              color="blue"
                              class="mr-1"
                              hide-details
                              dense
                              label="Miss a Penalty"
                              />
                            <v-checkbox
                              :disabled="!odds.penalty"
                              v-model="show_odds_market[15]"
                              color="blue"
                              class="mr-1"
                              hide-details
                              dense
                              label="Penalty in the Match"
                              />
                          </div>

                          <div class="p-2 d-flex flex-wrap justify-space-around text-center">
                            <div v-if="show_odds_market[13] && odds.sPt">
                              <div class="text-center"><span>Score a Penalty</span></div>

                              <v-simple-table
                                class="table-row-35 border-lightgrey text-center"
                                style="border-radius: 0; box-shadow: 1px 1px 3px lightgrey;">
                                <tbody>
                                  <tr>
                                    <td>{{ odds.sPt[0].label }}</td>
                                    <td>{{ odds.sPt[0].value }}</td>
                                  </tr>

                                  <tr>
                                    <td>{{ odds.sPt[1].label }}</td>
                                    <td>{{ odds.sPt[1].value }}</td>
                                  </tr>
                                </tbody>
                              </v-simple-table>
                            </div>

                            <div v-if="show_odds_market[14] && odds.mPt">
                              <div class="text-center"><span>Miss a Penalty</span></div>

                              <v-simple-table
                                class="table-row-35 border-lightgrey text-center"
                                style="border-radius: 0; box-shadow: 1px 1px 3px lightgrey;">
                                <tbody>
                                  <tr>
                                    <td>{{ odds.mPt[0].label }}</td>
                                    <td>{{ odds.mPt[0].value }}</td>
                                  </tr>

                                  <tr>
                                    <td>{{ odds.mPt[1].label }}</td>
                                    <td>{{ odds.mPt[1].value }}</td>
                                  </tr>
                                </tbody>
                              </v-simple-table>
                            </div>

                            <div v-if="show_odds_market[15] && odds.penalty">
                              <div class="text-center"><span>A Penalty in the Match</span></div>

                              <v-simple-table
                                class="table-row-35 border-lightgrey text-center"
                                style="border-radius: 0; box-shadow: 1px 1px 3px lightgrey;">
                                <tbody>
                                  <tr>
                                    <td>{{ odds.penalty[0].label }}</td>
                                    <td>{{ odds.penalty[1].label }}</td>
                                  </tr>

                                  <tr>
                                    <td>{{ odds.penalty[0].value }}</td>
                                    <td>{{ odds.penalty[1].value }}</td>
                                  </tr>
                                </tbody>
                              </v-simple-table>
                            </div>
                          </div>
                        </div>
                  <!-- </div> -->
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>

                  <div class="game-nav">
                    <div class="game-nav__btn" :class="{'game-nav__btn_active' : flags.other==0}" @click="other_flag(0)">{{ $t('head2head') }}</div>
                    <div class="game-nav__btn" :class="{'game-nav__btn_active' : flags.other==1}" @click="other_flag(1)">{{ $t('lastmatches') }}</div>
                    <div class="game-nav__btn" :class="{'game-nav__btn_active' : flags.other==2}" @click="other_flag(2)" :title="$t('message.similar3way')">{{ $t('similarmatches') }}</div>
                  </div>

                  <div v-if="!flags.other" class="mt-4">
                    <div v-if="!h2hLoaded" >
                      <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                    </div>

                    <div v-else>
                      <v-container fluid>
                        <div class="row d-flex">
                          <v-select
                            v-model="h2hChosenLeagues"
                            :items="h2hLeagues"
                            item-text="league_name"
                            item-value="league_id"
                            class="col-12 col-sm-7 col-lg-9"
                            attach
                            dense
                            :label="$t('tournament')"
                            hide-details
                            multiple
                          />
                          
                          <v-select
                            v-model="h2hChosenSeasons"
                            :items="h2hSeasons"
                            :label="$t('season')"
                            class="col-12 col-sm-5 col-lg-3"
                            attach
                            dense
                            hide-details
                            multiple
                          />
                        </div>

                        <div class="row d-flex flex-wrap justify-space-between">
                          <v-checkbox
                            v-model="h2hLocal"
                            color="blue"
                            hide-details
                            dense
                            :label="$t('home')"
                            />
                          
                          <v-checkbox
                            v-model="h2hVisitor"
                            color="blue"
                            hide-details
                            dense
                            :label="$t('away')"
                            />
                          
                          <template v-if="basic.local_coach && basic.visitor_coach">
                            <v-checkbox
                              v-model="h2hCurrentCoach"
                              color="blue"
                              hide-details
                              dense
                              :label="`${$t('currentcoach')}(${h2h_coach_count})`"
                              />
                            <v-checkbox
                              v-model="h2hPrevCoach"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('previouscoach')"
                              />
                          </template>

                          <v-select
                            v-model="h2hPerPage"
                            :items="h2hCountPerPage"
                            attach
                            dense
                            class="col-4 col-sm-2 col-md-1"
                            :hint="$t('countperpage')"
                          />

                          <v-text-field
                            v-model="h2hPPageByUser"
                            class="col-3 col-sm-2 col-md-1"
                            dense
                            :hint="$t('countperpage')"
                            type="number"
                            max=149
                            min=1
                            v-on:keyup.enter="h2hPerPageChange"
                          />
                        </div>
                      </v-container>

                      <v-simple-table
                        v-if="otherH2hM[this.chosenCategory]"
                        class="table-row-35 border-lightgrey text-center"
                        style="border-radius: 0">
                        <tbody>
                          <tr>
                            <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                            <td :title="$t('pts.tooltip')">{{ $t('pts.label') }}</td>
                            <td :title="$t('w.tooltip')">{{ $t('w.label') }}</td>
                            <td :title="$t('d.tooltip')">{{ $t('d.label') }}</td>
                            <td :title="$t('l.tooltip')">{{ $t('l.label') }}</td>
                            <td :title="$t('diff.tooltip')">{{ $t('diff.label') }}</td>
                            <td :title="$t('diff_av.tooltip')">{{ $t('diff_av.label') }}</td>
                            <td :title="$t('total_av.tooltip')">{{ $t('total_av.label') }}</td>
                            <td :title="$t('teamtotal_av.tooltip')">{{ basic.local_name }}</td>
                            <td :title="$t('opponenttotal_av.tooltip')">{{ basic.visitor_name }}</td>
                          </tr>
                          
                          <tr class="font-weight-bold">
                            <td>{{ this.otherH2hM[this.chosenCategory] }}</td>
                            <td>{{ this.otherH2hP[this.chosenCategory] }}</td>
                            <td>{{ this.otherH2hW[this.chosenCategory] }}</td>
                            <td>{{ this.otherH2hD[this.chosenCategory] }}</td>
                            <td>{{ this.otherH2hL[this.chosenCategory] }}</td>
                            <td>{{ this.otherH2hDiff[this.chosenCategory] }}</td>
                            <td>{{ this.otherH2hD_av[this.chosenCategory] }}</td>
                            <td>{{ this.otherH2hT[this.chosenCategory] }}</td>
                            <td>{{ this.otherH2hT1[this.chosenCategory] }}</td>
                            <td>{{ this.otherH2hT2[this.chosenCategory] }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>

                      <div class="curPointer pt-5 pb-2">
                        <a :class="{'d-none' : h2hPage == h2hLastPage}" class="ml-1 mr-4" @click="h2hPrev()">{{ $t('previous') }}</a>
                        <a :class="{'d-none' : !h2hPage}" @click="h2hNext()">{{ $t('next') }}</a>
                      </div>

                      <v-simple-table class="table-row-35 cell-py-10 border-lightgrey" style="border-radius: 0">
                        <thead>
                          <tr>
                            <th>{{ $t('date') }}</th>
                            <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                            <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                            <th>{{ $t('home') }}</th>
                            <th></th>
                            <th class="text-right">{{ $t('away') }}</th>
                            <th class="text-center">{{ $t('detail') }}</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="(game, index) in CGC[h2hPage]" :key="index" class="blue-grey--text">
                            <td class="overflow-anywhere">
                              <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                              <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                            </td>

                            <td :class="{'d-none': $vuetify.breakpoint.xs}" class="overflow-anywhere">
                              <router-link :to="{name: 'league', params: {lid: game.league_id}}" class="blue-grey--text">
                                {{ game.league_name }}
                              </router-link>
                            </td>
                            
                            <td :class="{'d-none': $vuetify.breakpoint.xs}">{{ game.round_name ? game.round_name : '' }}</td>

                            <td class="overflow-anywhere">{{ game.local_name }}</td>
                            
                            <td
                              v-if="game.stats.length"
                              class="white--text"
                              :style="'background-color:' + game.stats[chosenCategory][2]">
                              <div class="d-flex justify-space-around align-center subtitle-2">
                                <span>{{ game.stats[chosenCategory][0] }}</span>
                                <span> - </span>
                                <span>{{ game.stats[chosenCategory][1] }}</span>
                              </div>
                            </td>

                            <td
                              v-else
                              class="white--text"
                              :style="'background-color:' + stats_color[1]">
                              <div class="d-flex justify-space-around align-center subtitle-2">
                                <span>?</span>
                                <span> - </span>
                                <span>?</span>
                              </div>
                            </td>

                            <td class="overflow-anywhere text-right">{{ game.visitor_name }}</td>
                            
                            <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </div>
                  </div>

                  <template v-else-if="flags.other==1">
                    <div v-if="!lastHomeLoaded && !lastAwayLoaded">
                      <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                    </div>

                    <div v-else-if="!authorization">
                      <v-alert
                        type="warning"
                        prominent
                      >
                        {{ $t(`message.${msg_last}`) }}
                      </v-alert>
                    </div>

                    <template v-else>
                      <div class="matches_heading">{{ basic.local_name }} {{ $t('lastmatches') }}</div>

                      <div>
                        <v-container fluid>
                          <div v-if='homeLeagueLoaded' class="row d-flex">
                            <v-select
                              v-model="homeChosenLeagues"
                              :items="HLeagues"
                              item-text="league_name"
                              item-value="league_id"
                              class="col-12 col-sm-7 col-lg-9"
                              attach
                              dense
                              :label="$t('tournament')"
                              hide-details
                              multiple
                            />

                            <v-select
                              v-model="homeChosenSeasons"
                              :items="HSeasons"
                              :label="$t('season')"
                              class="col-12 col-sm-5 col-lg-3"
                              attach
                              dense
                              hide-details
                              multiple
                            />
                          </div>

                          <div class="row d-flex flex-wrap justify-space-between">
                            <v-checkbox
                              v-model="hLocal"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('home')"
                              />
                            
                            <v-checkbox
                              v-model="hVisitor"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('away')"
                              />

                            <template v-if="basic.local_coach">
                              <v-checkbox
                                v-model="hCurrentCoach"
                                color="blue"
                                hide-details
                                dense
                                :label="`${$t('currentcoach')}(${local_coach_count})`"
                                />
                              <v-checkbox
                                v-model="hPrevCoach"
                                color="blue"
                                hide-details
                                dense
                                :label="$t('previouscoach')"
                                />
                            </template>

                            <v-select
                              v-model="homePerPage"
                              :items="countPerPage"
                              attach
                              dense
                              class="col-4 col-sm-2 col-md-1"
                              :hint="$t('countperpage')"
                            />

                            <v-text-field
                              v-model="hPPageByUser"
                              class="col-3 col-sm-2 col-md-1"
                              dense
                              :hint="$t('countperpage')"
                              type="number"
                              max=149
                              min=1
                              v-on:keyup.enter="homePerPageChange"
                            />
                          </div>
                        </v-container>

                        <template v-if="lastHomeLoaded">
                        <v-simple-table
                          v-if="hLastAvgLoaded && hOtherLastAvg.match[chosenCategory]"
                          class="table-row-35 border-lightgrey text-center"
                          style="border-radius: 0">
                          <tbody>
                            <tr>
                              <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                              <td :title="$t('pts.tooltip')">{{ $t('pts.label') }}</td>
                              <td :title="$t('w.tooltip')">{{ $t('w.label') }}</td>
                              <td :title="$t('d.tooltip')">{{ $t('d.label') }}</td>
                              <td :title="$t('l.tooltip')">{{ $t('l.label') }}</td>
                              <td :title="$t('diff.tooltip')">{{ $t('diff.label') }}</td>
                              <td :title="$t('diff_av.tooltip')">{{ $t('diff_av.label') }}</td>
                              <td :title="$t('total_av.tooltip')">{{ $t('total_av.label') }}</td>
                              <td :title="$t('teamtotal_av.tooltip')">{{ basic.local_name }}</td>
                              <td :title="$t('opponenttotal_av.tooltip')"> {{ $t('opponenttotal_av.label') }}</td>
                            </tr>
                            
                            <tr class="font-weight-bold">
                              <td>{{ hOtherLastAvg.match[chosenCategory] }}</td>
                              <td>{{ hOtherLastAvg.point[chosenCategory] }}</td>
                              <td>{{ hOtherLastAvg.win[chosenCategory] }}</td>
                              <td>{{ hOtherLastAvg.draw[chosenCategory] }}</td>
                              <td>{{ hOtherLastAvg.lost[chosenCategory] }}</td>
                              <td>{{ hOtherLastAvg.diff[chosenCategory] }}</td>
                              <td>{{ hOtherLastAvg.diff_av[chosenCategory] }}</td>
                              <td>{{ hOtherLastAvg.total[chosenCategory] }}</td>
                              <td>{{ hOtherLastAvg.t1[chosenCategory] }}</td>
                              <td>{{ hOtherLastAvg.t2[chosenCategory] }}</td>
                            </tr>
                          </tbody>
                        </v-simple-table>

                        <div class="curPointer pt-5 pb-2">
                          <a :class="{'d-none' : homePage == hLastPage}" class="ml-1 mr-4" @click="homePrev()">{{ $t('previous') }}</a>
                          <a :class="{'d-none' : !homePage}" @click="homeNext()">{{ $t('next') }}</a>
                        </div>

                        <v-simple-table class="table-row-35 cell-py-10 border-lightgrey" style="border-radius: 0">
                          <thead>
                            <tr>
                              <th>{{ $t('date') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                              <th>{{ $t('home') }}</th>
                              <th></th>
                              <th class="text-right">{{ $t('away') }}</th>
                              <th class="text-center">{{ $t('detail') }}</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-for="(game, index) in Hlast[homePage]" :key="index">
                              <td class="overflow-anywhere blue-grey--text">
                                <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                                <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="overflow-anywhere">
                                <router-link :to="{name: 'league', params: {lid: game.league.league_id}}" class="blue-grey--text">
                                  {{ game.league.name }}
                                </router-link>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="blue-grey--text">
                                {{ game.round ? game.round.name : '' }}
                              </td>

                              <td v-if="game.local_id != basic.local_id">
                                <router-link :to="{name: 'team', params: {id: game.local_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.local_name }}
                                </router-link>
                              </td>

                              <td v-else class="overflow-anywhere black--text">{{ game.local_name }}</td>

                              <td
                                v-if="game.stats.length"
                                class="white--text"
                                :style="'background-color:' + stats_color[game.stats[chosenCategory][2]]">
                                <div class="d-flex justify-space-around align-center subtitle-2">
                                  <span>{{ game.stats[chosenCategory][0] }}</span>
                                  <span> - </span>
                                  <span>{{ game.stats[chosenCategory][1] }}</span>
                                </div>
                              </td>

                              <td
                                v-else
                                class="white--text"
                                :style="'background-color:' + stats_color[1]">
                                <div class="d-flex justify-space-around align-center subtitle-2">
                                  <span>?</span>
                                  <span> - </span>
                                  <span>?</span>
                                </div>
                              </td>
                              
                              <td v-if="game.visitor_id != basic.local_id" class="text-right">
                                <router-link :to="{name: 'team', params: {id: game.visitor_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.visitor_name }}
                                </router-link>
                              </td>
                              
                              <td v-else class="overflow-anywhere text-right black--text">{{ game.visitor_name }}</td>
                              
                              <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                        </template>

                        <div v-else>
                          <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                        </div>
                      </div>

                      <div class="matches_heading">{{ basic.visitor_name }} {{ $t('lastmatches') }}</div>

                      <div>
                        <v-container fluid>
                          <div v-if='awayLeagueLoaded' class="row d-flex">
                            <v-select
                              v-model="awayChosenLeagues"
                              :items="ALeagues"
                              item-text="league_name"
                              item-value="league_id"
                              class="col-12 col-sm-7 col-lg-9"
                              attach
                              dense
                              :label="$t('tournament')"
                              hide-details
                              multiple
                            />

                            <v-select
                              v-model="awayChosenSeasons"
                              :items="ASeasons"
                              :label="$t('season')"
                              class="col-12 col-sm-5 col-lg-3"
                              attach
                              dense
                              hide-details
                              multiple
                            />
                          </div>

                          <div class="row d-flex flex-wrap justify-space-between">
                            <v-checkbox
                              v-model="aLocal"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('home')"
                              />
                            
                            <v-checkbox
                              v-model="aVisitor"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('away')"
                              />
                            
                            <template v-if="basic.visitor_coach">
                              <v-checkbox
                                v-model="aCurrentCoach"
                                color="blue"
                                hide-details
                                dense
                                :label="`${$t('currentcoach')}(${visitor_coach_count})`"
                                />
                              <v-checkbox
                                v-model="aPrevCoach"
                                color="blue"
                                hide-details
                                dense
                                :label="$t('previouscoach')"
                                />
                            </template>

                            <v-select
                              v-model="awayPerPage"
                              :items="countPerPage"
                              attach
                              dense
                              class="col-4 col-sm-2 col-md-1"
                              :hint="$t('countperpage')"
                            />

                            <v-text-field
                              v-model="aPPageByUser"
                              class="col-3 col-sm-2 col-md-1"
                              dense
                              :hint="$t('countperpage')"
                              type="number"
                              max=149
                              min=1
                              v-on:keyup.enter="awayPerPageChange"
                            />
                          </div>
                        </v-container>

                        <template v-if="lastAwayLoaded">
                        <v-simple-table
                          v-if="aLastAvgLoaded && aOtherLastAvg.match[chosenCategory]"
                          class="table-row-35 border-lightgrey text-center"
                          style="border-radius: 0">
                          <tbody>
                            <tr>
                              <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                              <td :title="$t('pts.tooltip')">{{ $t('pts.label') }}</td>
                              <td :title="$t('w.tooltip')">{{ $t('w.label') }}</td>
                              <td :title="$t('d.tooltip')">{{ $t('d.label') }}</td>
                              <td :title="$t('l.tooltip')">{{ $t('l.label') }}</td>
                              <td :title="$t('diff.tooltip')">{{ $t('diff.label') }}</td>
                              <td :title="$t('diff_av.tooltip')">{{ $t('diff_av.label') }}</td>
                              <td :title="$t('total_av.tooltip')">{{ $t('total_av.label') }}</td>
                              <td :title="$t('teamtotal_av.tooltip')">{{ basic.visitor_name }}</td>
                              <td :title="$t('opponenttotal_av.tooltip')"> {{ $t('opponenttotal_av.label') }}</td>
                            </tr>
                            
                            <tr class="font-weight-bold">
                              <td>{{ aOtherLastAvg.match[chosenCategory] }}</td>
                              <td>{{ aOtherLastAvg.point[chosenCategory] }}</td>
                              <td>{{ aOtherLastAvg.win[chosenCategory] }}</td>
                              <td>{{ aOtherLastAvg.draw[chosenCategory] }}</td>
                              <td>{{ aOtherLastAvg.lost[chosenCategory] }}</td>
                              <td>{{ aOtherLastAvg.diff[chosenCategory] }}</td>
                              <td>{{ aOtherLastAvg.diff_av[chosenCategory] }}</td>
                              <td>{{ aOtherLastAvg.total[chosenCategory] }}</td>
                              <td>{{ aOtherLastAvg.t1[chosenCategory] }}</td>
                              <td>{{ aOtherLastAvg.t2[chosenCategory] }}</td>
                            </tr>
                          </tbody>
                        </v-simple-table>

                        <div class="curPointer pt-5 pb-2">
                          <a :class="{'d-none' : awayPage == aLastPage}" class="ml-1 mr-4" @click="awayPrev()">{{ $t('previous') }}</a>
                          <a :class="{'d-none' : !awayPage}" @click="awayNext()">{{ $t('next') }}</a>
                        </div>

                        <v-simple-table class="table-row-35 cell-py-10 border-lightgrey" style="border-radius: 0">
                          <thead>
                            <tr>
                              <th>{{ $t('date') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                              <th>{{ $t('home') }}</th>
                              <th></th>
                              <th class="text-right">{{ $t('away') }}</th>
                              <th class="text-center">{{ $t('detail') }}</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-for="(game, index) in Alast[awayPage]" :key="index">
                              <td class="overflow-anywhere blue-grey--text">
                                <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                                <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="overflow-anywhere">
                                <router-link :to="{name: 'league', params: {lid: game.league.league_id}}" class="blue-grey--text">
                                  {{ game.league.name }}
                                </router-link>
                              </td>
                              
                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="blue-grey--text">
                                {{ game.round ? game.round.name : '' }}
                              </td>
                              
                              <td v-if="game.local_id != basic.visitor_id">
                                <router-link :to="{name: 'team', params: {id: game.local_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.local_name }}
                                </router-link>
                              </td>
                              
                              <td v-else class="overflow-anywhere black--text">{{ game.local_name }}</td>
                              
                              <td
                                v-if="game.stats.length"
                                class="white--text"
                                :style="'background-color:' + stats_color[game.stats[chosenCategory][2]]">
                                <div class="d-flex justify-space-around align-center subtitle-2">
                                  <span>{{ game.stats[chosenCategory][0] }}</span>
                                  <span> - </span>
                                  <span>{{ game.stats[chosenCategory][1] }}</span>
                                </div>
                              </td>
                              
                              <td
                                v-else
                                class="white--text"
                                :style="'background-color:' + stats_color[1]">
                                <div class="d-flex justify-space-around align-center subtitle-2">
                                  <span>?</span>
                                  <span> - </span>
                                  <span>?</span>
                                </div>
                              </td>
                              
                              <td v-if="game.visitor_id != basic.visitor_id" class="text-right">
                                <router-link :to="{name: 'team', params: {id: game.visitor_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.visitor_name }}
                                </router-link>
                              </td>
                              
                              <td v-else class="overflow-anywhere text-right black--text">{{ game.visitor_name }}</td>
                              
                              <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                        </template>

                        <div v-else>
                          <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                        </div>
                      </div>
                    </template>
                  </template>

                  <template v-else>
                    <div v-if="!odds.FT">
                      <v-alert
                        type="info"
                        prominent
                      >
                        {{ $t('message.impossibleSM') }}
                      </v-alert>
                    </div>

                    <div v-else-if="!similarHomeLoaded && !similarAwayLoaded">
                      <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                    </div>

                    <div v-else-if="!authorization">
                      <v-alert
                        type="warning"
                        prominent
                      >
                        {{ $t(`message.${msg_last}`) }}
                      </v-alert>
                    </div>

                    <template v-else>
                      <div class="matches_heading">{{ basic.local_name }} {{ $t('similarmatches') }}</div>

                      <div>
                        <v-container fluid>
                          <div v-if='homeLeagueLoaded' class="row d-flex">
                            <v-select
                              v-model="hSChosenLeagues"
                              :items="HLeagues"
                              item-text="league_name"
                              item-value="league_id"
                              class="col-12 col-sm-7 col-lg-9"
                              attach
                              dense
                              :label="$t('tournament')"
                              hide-details
                              multiple
                            />

                            <v-select
                              v-model="hSChosenSeasons"
                              :items="HSeasons"
                              :label="$t('season')"
                              class="col-12 col-sm-5 col-lg-3"
                              attach
                              dense
                              hide-details
                              multiple
                            />
                          </div>

                          <div class="row d-flex flex-wrap justify-space-between">
                            <v-checkbox
                              v-model="hSLocal"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('home')"
                              />
                            
                            <v-checkbox
                              v-model="hSVisitor"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('away')"
                              />

                            <template v-if="basic.local_coach">
                              <v-checkbox
                                v-model="hSCurrentCoach"
                                color="blue"
                                hide-details
                                dense
                                :label="`${$t('currentcoach')}(${ls_coach_count})`"
                                />
                              <v-checkbox
                                v-model="hSPrevCoach"
                                color="blue"
                                hide-details
                                dense
                                :label="$t('previouscoach')"
                                />
                            </template>

                            <v-select
                              v-model="hSPerPage"
                              :items="countPerPage"
                              attach
                              dense
                              class="col-4 col-sm-2 col-md-1"
                              :hint="$t('countperpage')"
                            />

                            <v-text-field
                              v-model="hSPPageByUser"
                              class="col-3 col-sm-2 col-md-1"
                              dense
                              :hint="$t('countperpage')"
                              type="number"
                              max=149
                              min=1
                              v-on:keyup.enter="hSPerPageChange"
                            />
                          </div>
                        </v-container>

                        <template v-if="similarHomeLoaded">
                        <v-simple-table
                          v-if="hSimilarAvgLoaded && hOtherSimilarAvg.match[chosenCategory]"
                          class="table-row-35 border-lightgrey text-center"
                          style="border-radius: 0">
                          <tbody>
                            <tr>
                              <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                              <td :title="$t('pts.tooltip')">{{ $t('pts.label') }}</td>
                              <td :title="$t('w.tooltip')">{{ $t('w.label') }}</td>
                              <td :title="$t('d.tooltip')">{{ $t('d.label') }}</td>
                              <td :title="$t('l.tooltip')">{{ $t('l.label') }}</td>
                              <td :title="$t('diff.tooltip')">{{ $t('diff.label') }}</td>
                              <td :title="$t('diff_av.tooltip')">{{ $t('diff_av.label') }}</td>
                              <td :title="$t('total_av.tooltip')">{{ $t('total_av.label') }}</td>
                              <td :title="$t('teamtotal_av.tooltip')">{{ basic.local_name }}</td>
                              <td :title="$t('opponenttotal_av.tooltip')"> {{ $t('opponenttotal_av.label') }}</td>
                            </tr>
                            
                            <tr class="font-weight-bold">
                              <td>{{ hOtherSimilarAvg.match[chosenCategory] }}</td>
                              <td>{{ hOtherSimilarAvg.point[chosenCategory] }}</td>
                              <td>{{ hOtherSimilarAvg.win[chosenCategory] }}</td>
                              <td>{{ hOtherSimilarAvg.draw[chosenCategory] }}</td>
                              <td>{{ hOtherSimilarAvg.lost[chosenCategory] }}</td>
                              <td>{{ hOtherSimilarAvg.diff[chosenCategory] }}</td>
                              <td>{{ hOtherSimilarAvg.diff_av[chosenCategory] }}</td>
                              <td>{{ hOtherSimilarAvg.total[chosenCategory] }}</td>
                              <td>{{ hOtherSimilarAvg.t1[chosenCategory] }}</td>
                              <td>{{ hOtherSimilarAvg.t2[chosenCategory] }}</td>
                            </tr>
                          </tbody>
                        </v-simple-table>

                        <div class="curPointer pt-5 pb-2">
                          <a :class="{'d-none' : hSPage == hSLastPage}" class="ml-1 mr-4" @click="hSPrev()">{{ $t('previous') }}</a>
                          <a :class="{'d-none' : !hSPage}" @click="hSNext()">{{ $t('next') }}</a>
                        </div>
                        
                        <v-simple-table class="table-row-35 cell-py-10 border-lightgrey" style="border-radius: 0">
                          <thead>
                            <tr>
                              <th>{{ $t('date') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>

                              <th>
                                {{ $t('home') }}
                                <template v-if="$vuetify.breakpoint.xs">({{ $t('w.label') }})</template>
                              </th>

                              <th class="text-center">
                                <template v-if="$vuetify.breakpoint.xs">({{ $t('d.label') }})</template>
                              </th>

                              <th class="text-right">
                                {{ $t('away') }}
                                <template v-if="$vuetify.breakpoint.xs">({{ $t('l.label') }})</template>
                              </th>

                              <th :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center">{{ $t('w.label') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center">{{ $t('d.label') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center">{{ $t('l.label') }}</th>
                              <th class="text-center">{{ $t('detail') }}</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-for="(game, index) in hSimilar[hSPage]" :key="index">
                              <td class="overflow-anywhere blue-grey--text">
                                <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                                <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}">
                                <router-link :to="{name: 'league', params: {lid: game.league.league_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.league.name }}
                                </router-link>
                              </td>
                              
                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="blue-grey--text">
                                {{ game.round ? game.round.name : '' }}
                              </td>
                              
                              <td class="overflow-anywhere">
                                <template v-if="game.local_id != basic.local_id">
                                  <router-link :to="{name: 'team', params: {id: game.local_id}}" class="blue-grey--text">
                                    {{ game.local_name }}
                                  </router-link>
                                </template>

                                <span v-else class="black--text">{{ game.local_name }}</span>

                                <span v-if="$vuetify.breakpoint.xs"> ({{ game.three_way[1] }})</span>
                              </td>
                              
                              <td
                                v-if="game.stats.length"
                                class="white--text text-center"
                                :style="'background-color:' + stats_color[game.stats[chosenCategory][2]]">
                                <div class="d-flex justify-space-around align-center subtitle-2">
                                  <span>{{ game.stats[chosenCategory][0] }}</span>
                                  <span> - </span>
                                  <span>{{ game.stats[chosenCategory][1] }}</span>
                                </div>

                                <span v-if="$vuetify.breakpoint.xs">({{ game.three_way.X }})</span>
                              </td>
                              
                              <td
                                v-else
                                class="white--text text-center"
                                :style="'background-color:' + stats_color[1]">
                                <div class="d-flex justify-space-around align-center subtitle-2">
                                  <span>?</span>
                                  <span> - </span>
                                  <span>?</span>
                                </div>

                                <span v-if="$vuetify.breakpoint.xs">({{ game.three_way.X }})</span>
                              </td>
                              
                              <td class="overflow-anywhere text-right">
                                <template v-if="game.visitor_id != basic.local_id">
                                  <router-link :to="{name: 'team', params: {id: game.visitor_id}}" class="blue-grey--text">
                                    {{ game.visitor_name }}
                                  </router-link>
                                </template>

                                <span v-else class="black--text">{{ game.visitor_name }}</span>

                                <span v-if="$vuetify.breakpoint.xs"> ({{ game.three_way[2] }})</span>
                              </td>
                              
                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center blue-grey--text">{{ game.three_way[1] }}</td>
                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center blue-grey--text">{{ game.three_way.X }}</td>
                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center blue-grey--text">{{ game.three_way[2] }}</td>                                
                              <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                        </template>

                        <div v-else>
                          <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                        </div>
                      </div>
                      
                      <div class="matches_heading">{{ basic.visitor_name }} {{ $t('similarmatches') }}</div>
                      
                      <div>
                        <v-container fluid>
                          <div v-if='awayLeagueLoaded' class="row d-flex">
                            <v-select
                              v-model="aSChosenLeagues"
                              :items="ALeagues"
                              item-text="league_name"
                              item-value="league_id"
                              class="col-12 col-sm-7 col-lg-9"
                              attach
                              dense
                              :label="$t('tournament')"
                              hide-details
                              multiple
                            />

                            <v-select
                              v-model="aSChosenSeasons"
                              :items="ASeasons"
                              :label="$t('season')"
                              class="col-12 col-sm-5 col-lg-3"
                              attach
                              dense
                              hide-details
                              multiple
                            />
                          </div>

                          <div class="row d-flex flex-wrap justify-space-between">
                            <v-checkbox
                              v-model="aSLocal"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('home')"
                              />
                            
                            <v-checkbox
                              v-model="aSVisitor"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('away')"
                              />
                            
                            <template v-if="basic.visitor_coach">
                              <v-checkbox
                                v-model="aSCurrentCoach"
                                color="blue"
                                hide-details
                                dense
                                :label="`${$t('currentcoach')}(${vs_coach_count})`"
                                />
                              <v-checkbox
                                v-model="aSPrevCoach"
                                color="blue"
                                hide-details
                                dense
                                :label="$t('previouscoach')"
                                />
                            </template>

                            <v-select
                              v-model="aSPerPage"
                              :items="countPerPage"
                              attach
                              dense
                              class="col-4 col-sm-2 col-md-1"
                              :hint="$t('countperpage')"
                            />

                            <v-text-field
                              v-model="aSPPageByUser"
                              class="col-3 col-sm-2 col-md-1"
                              dense
                              :hint="$t('countperpage')"
                              type="number"
                              max=149
                              min=1
                              v-on:keyup.enter="aSPerPageChange"
                            />
                          </div>
                        </v-container>

                        <template v-if="similarAwayLoaded">
                        <v-simple-table
                          v-if="aSimilarAvgLoaded && aOtherSimilarAvg.match[chosenCategory]"
                          class="table-row-35 border-lightgrey text-center"
                          style="border-radius: 0">
                          <tbody>
                            <tr>
                              <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                              <td :title="$t('pts.tooltip')">{{ $t('pts.label') }}</td>
                              <td :title="$t('w.tooltip')">{{ $t('w.label') }}</td>
                              <td :title="$t('d.tooltip')">{{ $t('d.label') }}</td>
                              <td :title="$t('l.tooltip')">{{ $t('l.label') }}</td>
                              <td :title="$t('diff.tooltip')">{{ $t('diff.label') }}</td>
                              <td :title="$t('diff_av.tooltip')">{{ $t('diff_av.label') }}</td>
                              <td :title="$t('total_av.tooltip')">{{ $t('total_av.label') }}</td>
                              <td :title="$t('teamtotal_av.tooltip')">{{ basic.visitor_name }}</td>
                              <td :title="$t('opponenttotal_av.tooltip')"> {{ $t('opponenttotal_av.label') }}</td>
                            </tr>
                            
                            <tr class="font-weight-bold">
                              <td>{{ aOtherSimilarAvg.match[chosenCategory] }}</td>
                              <td>{{ aOtherSimilarAvg.point[chosenCategory] }}</td>
                              <td>{{ aOtherSimilarAvg.win[chosenCategory] }}</td>
                              <td>{{ aOtherSimilarAvg.draw[chosenCategory] }}</td>
                              <td>{{ aOtherSimilarAvg.lost[chosenCategory] }}</td>
                              <td>{{ aOtherSimilarAvg.diff[chosenCategory] }}</td>
                              <td>{{ aOtherSimilarAvg.diff_av[chosenCategory] }}</td>
                              <td>{{ aOtherSimilarAvg.total[chosenCategory] }}</td>
                              <td>{{ aOtherSimilarAvg.t1[chosenCategory] }}</td>
                              <td>{{ aOtherSimilarAvg.t2[chosenCategory] }}</td>
                            </tr>
                          </tbody>
                        </v-simple-table>

                        <div class="curPointer pt-5 pb-2">
                          <a :class="{'d-none' : aSPage == aSLastPage}" class="ml-1 mr-4" @click="aSPrev()">{{ $t('previous') }}</a>
                          <a :class="{'d-none' : !aSPage}" @click="aSNext()">{{ $t('next') }}</a>
                        </div>

                        <v-simple-table class="table-row-35 cell-py-10 border-lightgrey" style="border-radius: 0">
                          <thead>
                            <tr>
                              <th>{{ $t('date') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                              
                              <th>
                                {{ $t('home') }}
                                <template v-if="$vuetify.breakpoint.xs">({{ $t('w.label') }})</template>
                              </th>

                              <th class="text-center">
                                <template v-if="$vuetify.breakpoint.xs">({{ $t('d.label') }})</template>
                              </th>

                              <th class="text-right">
                                {{ $t('away') }}
                                <template v-if="$vuetify.breakpoint.xs">({{ $t('l.label') }})</template>
                              </th>

                              <th :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center">{{ $t('w.label') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center">{{ $t('d.label') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center">{{ $t('l.label') }}</th>
                              <th class="text-center">{{ $t('detail') }}</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-for="(game, index) in aSimilar[aSPage]" :key="index">
                              <td class="overflow-anywhere blue-grey--text">
                                <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                                <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}">
                                <router-link :to="{name: 'league', params: {lid: game.league.league_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.league.name }}
                                </router-link>
                              </td>
                              
                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="blue-grey--text">
                                {{ game.round ? game.round.name : '' }}
                              </td>
                              
                              <td class="overflow-anywhere">
                                <template v-if="game.local_id != basic.visitor_id">
                                  <router-link :to="{name: 'team', params: {id: game.local_id}}" class="blue-grey--text">
                                    {{ game.local_name }}
                                  </router-link>
                                </template>

                                <span v-else class="black--text">{{ game.local_name }}</span>

                                <span v-if="$vuetify.breakpoint.xs"> ({{ game.three_way[1] }})</span>
                              </td>

                              <td
                                v-if="game.stats.length"
                                class="white--text text-center"
                                :style="'background-color:' + stats_color[game.stats[chosenCategory][2]]">
                                <div class="d-flex justify-space-around align-center subtitle-2">
                                  <span>{{ game.stats[chosenCategory][0] }}</span>
                                  <span> - </span>
                                  <span>{{ game.stats[chosenCategory][1] }}</span>
                                </div>

                                <span v-if="$vuetify.breakpoint.xs">({{ game.three_way.X }})</span>
                              </td>

                              <td
                                v-else
                                class="white--text text-center"
                                :style="'background-color:' + stats_color[1]">
                                <div class="d-flex justify-space-around align-center subtitle-2">
                                  <span>?</span>
                                  <span> - </span>
                                  <span>?</span>
                                </div>

                                <span v-if="$vuetify.breakpoint.xs">({{ game.three_way.X }})</span>
                              </td>

                              <td class="overflow-anywhere text-right">
                                <template v-if="game.visitor_id != basic.visitor_id">
                                  <router-link :to="{name: 'team', params: {id: game.visitor_id}}" class="blue-grey--text">
                                    {{ game.visitor_name }}
                                  </router-link>
                                </template>

                                <span v-else class="black--text">{{ game.visitor_name }}</span>

                                <span v-if="$vuetify.breakpoint.xs"> ({{ game.three_way[2] }})</span>                                  
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center blue-grey--text">{{ game.three_way[1] }}</td>
                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center blue-grey--text">{{ game.three_way.X }}</td>
                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="text-center blue-grey--text">{{ game.three_way[2] }}</td>                                
                              <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                        </template>

                        <div v-else>
                          <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                        </div>
                      </div>
                    </template>
                  </template>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item v-if="basic.referee_id" key="Referee">
              <v-row class="no-gutters" justify="center">
                <v-col lg="8" md="10">
                  <div class="game-nav">
                    <div class="game-nav__btn" :class="{'game-nav__btn_active' : flags.referee == 1}" @click="referee_flag(1)">{{ basic.referee_name }}</div>
                    <div class="game-nav__btn" :class="{'game-nav__btn_active' : flags.referee == 2}" @click="referee_flag(2)">{{ $t('teammatches') }}</div>
                  </div>

                  <div v-if="flags.referee == 1">
                    <div v-if="!rLastLoaded" >
                      <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                    </div>

                    <div v-else-if="!authorization">
                      <v-alert
                        type="warning"
                        prominent
                      >
                        {{ $t(`message.${msg_last}`) }}
                      </v-alert>
                    </div>

                    <div v-else>
                      <v-simple-table
                        v-if="rAvgM_c"
                        class="table-row-35 border-lightgrey text-center"
                        style="border-radius: 0">
                        <tbody>
                          <tr>
                            <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                            <td :title="$t('q_yc.tooltip')">{{ $t('q_yc.label') }}</td>
                            <td :title="$t('t_yc.tooltip')">{{ $t('t_yc.label') }}</td>
                            <td :title="$t('q_rc.tooltip')">{{ $t('q_rc.label') }}</td>
                            <td :title="$t('t_rc.tooltip')">{{ $t('t_rc.label') }}</td>
                            <td :title="$t('penalty_tooltip')">{{ $t('penalty') }}</td>
                            <td :title="$t('foul_tooltip')">{{ $t('f.tooltip') }}</td>
                          </tr>
                          
                          <tr class="font-weight-bold">
                            <td>{{ rAvgM_c }}</td>
                            <td>{{ rAvgQ_yc }}</td>
                            <td>{{ rAvgT_yc }}</td>
                            <td>{{ rAvgQ_rc }}</td>
                            <td>{{ rAvgT_rc }}</td>
                            <td>{{ rAvgPen }}%</td>
                            <td>{{ rAvgFouls }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>

                      <div class="matches_heading">{{ basic.referee_name }}. {{ $t('lastmatches') }}</div>

                      <v-container fluid>
                        <div class="row d-flex">
                          <v-select
                            v-model="rChosenLeagues"
                            :items="rLeagues"
                            item-text="league_name"
                            item-value="league_id"
                            class="col-12 col-sm-7 col-lg-9"
                            attach
                            dense
                            :label="$t('tournament')"
                            hide-details
                            multiple
                          />

                          <v-select
                            v-model="rChosenSeasons"
                            :items="rSeasons"
                            :label="$t('season')"
                            class="col-12 col-sm-5 col-lg-3"
                            attach
                            dense
                            hide-details
                            multiple
                          />
                        </div>

                        <div class="row d-flex flex-wrap justify-space-between">
                          <v-select
                            v-model="rPerPage"
                            :items="rCountPerPage"
                            attach
                            dense
                            class="col-4 col-sm-2 col-md-1"
                            :hint="$t('countperpage')"
                          />

                          <v-text-field
                            v-model="rPPageByUser"
                            class="col-3 col-sm-2 col-md-1"
                            dense
                            :hint="$t('countperpage')"
                            type="number"
                            max=149
                            min=1
                            v-on:keyup.enter="rPerPageChange(0)"
                          />
                        </div>
                      </v-container>

                      <div class="curPointer pt-5 pb-2">
                        <a :class="{'d-none' : rPage == rLastPage}" class="ml-1 mr-4" @click="rPrev(0)">{{ $t('previous') }}</a>
                        <a :class="{'d-none' : !rPage}" @click="rNext(0)">{{ $t('next') }}</a>
                      </div>

                      <v-simple-table class="cell-py-10 border-lightgrey" style="border-radius: 0">
                        <thead>
                          <tr>
                            <th>{{ $t('date') }}</th>
                            <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                            <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                            <th>{{ $t('home') }}</th>
                            <th></th>
                            <th class="text-right">{{ $t('away') }}</th>
                            <th class="text-center">{{ $t('detail') }}</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="(game, index) in rLast[rPage]"
                            :key="index"
                            :class="{'curPointer': (game.local_cards[0] != -1)}"
                            @click="refereeModal(game.index, game.local_id, game.local_name, game.visitor_name)">
                          <!-- :key="index" v-if="game.referee == basic.referee_id"> -->
                              <td class="overflow-anywhere blue-grey--text">
                                <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                                <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="overflow-anywhere">
                                <router-link :to="{name: 'league', params: {lid: game.league_id}}" class="blue-grey--text">
                                  {{ game.league_name }}
                                </router-link>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="blue-grey--text">
                                {{ game.round ? game.round.name : '' }}
                              </td>

                              <td v-if="(game.local_id != basic.local_id) && (game.local_id != basic.visitor_id)">
                                <router-link :to="{name: 'team', params: {id: game.local_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.local_name }}
                                </router-link>
                              </td>

                              <td v-else class="overflow-anywhere black--text">{{ game.local_name }}</td>

                              <td class="blue-grey--text">
                                <div class="d-flex justify-space-around align-center subtitle-2">
                                  <div>
                                    <template 
                                      v-if="game.local_cards[0] == -1">
                                      ?
                                    </template>

                                    <template v-else>
                                      <span class="team_1_yellow_cards">{{ game.local_cards[0] }}</span><br>
                                      <span class="team_1_red_cards">{{ game.local_cards[1] + game.local_cards[2] }}</span>
                                    </template>
                                  </div>
                                  
                                  <div style="min-width: 11px;">
                                    <img src="/img/icons/yellow_card.png"><br>
                                    <img src="/img/icons/red_card.png">
                                  </div>
                                  
                                  <div>
                                    <template 
                                      v-if="game.local_cards[0] == -1">
                                      ?
                                    </template>

                                    <template v-else>
                                      <span class='team_2_yellow_cards'>{{ game.visitor_cards[0] }}</span><br>
                                      <span class='team_2_red_cards'>{{ game.visitor_cards[1] + game.visitor_cards[2] }}</span>
                                    </template>
                                  </div>
                                </div>
                              </td>

                              <td v-if="(game.visitor_id != basic.local_id) && (game.visitor_id != basic.visitor_id)" class="text-right">
                                <router-link :to="{name: 'team', params: {id: game.visitor_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.visitor_name }}
                                </router-link>
                              </td>

                              <td v-else class="overflow-anywhere text-right black--text">{{ game.visitor_name }}</td>

                              <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </div>
                  </div>

                  <div v-else-if="flags.referee == 2">
                    <div v-if="!rHLastLoaded && !rALastLoaded" >
                      <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                    </div>

                    <div v-else-if="!authorization">
                      <v-alert
                        type="warning"
                        prominent
                      >
                        {{ $t(`message.${msg_last}`) }}
                      </v-alert>
                    </div>

                    <template v-else>
                      <div v-if="rHLastLoaded">
                        <div class="matches_heading">{{ basic.local_name }} {{ $t('lastmatches') }} {{ $t('with') }} {{ basic.referee_name }}</div>

                        <v-container fluid>
                          <div class="row d-flex">
                            <v-select
                              v-model="rHChosenLeagues"
                              :items="rLeagues"
                              item-text="league_name"
                              item-value="league_id"
                              class="col-12 col-sm-7 col-lg-9"
                              attach
                              dense
                              :label="$t('tournament')"
                              hide-details
                              multiple
                            />

                            <v-select
                              v-model="rHChosenSeasons"
                              :items="rSeasons"
                              :label="$t('season')"
                              class="col-12 col-sm-5 col-lg-3"
                              attach
                              dense
                              hide-details
                              multiple
                            />
                          </div>

                          <div class="row d-flex flex-wrap justify-space-between">
                            <v-select
                              v-model="rHPerPage"
                              :items="rCountPerPage"
                              attach
                              dense
                              class="col-4 col-sm-2 col-md-1"
                              :hint="$t('countperpage')"
                            />

                            <v-text-field
                              v-model="rHPPageByUser"
                              class="col-3 col-sm-2 col-md-1"
                              dense
                              :hint="$t('countperpage')"
                              type="number"
                              max=149
                              min=1
                              v-on:keyup.enter="rPerPageChange(1)"
                            />
                          </div>
                        </v-container>

                        <v-simple-table
                          v-if="rHAvgM_c"
                          class="table-row-35 border-lightgrey text-center"
                          style="border-radius: 0">
                          <tbody>
                            <tr>
                              <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                              <td :title="$t('q_yc.tooltip')">{{ $t('q_yc.label') }}</td>
                              <td :title="$t('t_yc.tooltip')">{{ $t('t_yc.label') }}</td>
                              <td :title="$t('q_rc.tooltip')">{{ $t('q_rc.label') }}</td>
                              <td :title="$t('t_rc.tooltip')">{{ $t('t_rc.label') }}</td>
                              <td :title="$t('penalty_tooltip')">{{ $t('penalty') }}</td>
                              <td :title="$t('foul_tooltip')">{{ $t('f.tooltip') }}</td>
                            </tr>
                            
                            <tr class="font-weight-bold">
                              <td>{{ rHAvgM_c }}</td>
                              <td>{{ rHAvgQ_yc }}</td>
                              <td>{{ rHAvgT_yc }}</td>
                              <td>{{ rHAvgQ_rc }}</td>
                              <td>{{ rHAvgT_rc }}</td>
                              <td>{{ rHAvgPen }}%</td>
                              <td>{{ rHAvgFouls }}</td>
                            </tr>
                          </tbody>
                        </v-simple-table>

                        <div class="curPointer pt-5 pb-2">
                          <a :class="{'d-none' : rHPage == rHLastPage}" class="ml-1 mr-4" @click="rPrev(1)">{{ $t('previous') }}</a>
                          <a :class="{'d-none' : !rHPage}" @click="rNext(1)">{{ $t('next') }}</a>
                        </div>

                        <v-simple-table class="cell-py-10 border-lightgrey" style="border-radius: 0">
                          <thead>
                            <tr>
                              <th>{{ $t('date') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                              <th>{{ $t('home') }}</th>
                              <th></th>
                              <th class="text-right">{{ $t('away') }}</th>
                              <th class="text-center">{{ $t('detail') }}</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-for="(game, index) in rHLast[rHPage]"
                              :key="index"
                              :class="{'curPointer': (game.local_cards[0] != -1)}"
                              @click="refereeModal(game.index, game.local_id, game.local_name, game.visitor_name)">
                                <td class="overflow-anywhere blue-grey--text">
                                  <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                                  <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                                </td>

                                <td :class="{'d-none': $vuetify.breakpoint.xs}" class="overflow-anywhere">
                                  <router-link :to="{name: 'league', params: {lid: game.league_id}}" class="blue-grey--text">
                                    {{ game.league_name }}
                                  </router-link>
                                </td>
                                
                                <td :class="{'d-none': $vuetify.breakpoint.xs}" class="blue-grey--text">
                                  {{ game.round ? game.round.name : '' }}
                                </td>
                                
                                <td v-if="(game.local_id != basic.local_id) && (game.local_id != basic.visitor_id)">
                                  <router-link :to="{name: 'team', params: {id: game.local_id}}" class="overflow-anywhere blue-grey--text">
                                    {{ game.local_name }}
                                  </router-link>
                                </td>
                                
                                <td v-else class="overflow-anywhere black--text">{{ game.local_name }}</td>
                                
                                <td class="blue-grey--text">
                                  <div class="d-flex justify-space-around align-center subtitle-2">
                                    <div>
                                      <template 
                                        v-if="game.local_cards[0] == -1">
                                        ?
                                      </template>

                                      <template v-else>
                                        <span class="team_1_yellow_cards">{{ game.local_cards[0] }}</span><br>
                                        <span class="team_1_red_cards">{{ game.local_cards[1] + game.local_cards[2] }}</span>
                                      </template>
                                    </div>
                                    
                                    <div style="min-width: 11px;">
                                      <img src="/img/icons/yellow_card.png"><br>
                                      <img src="/img/icons/red_card.png">
                                    </div>

                                    <div>
                                      <template 
                                        v-if="game.local_cards[0] == -1">
                                        ?
                                      </template>

                                      <template v-else>
                                        <span class='team_2_yellow_cards'>{{ game.visitor_cards[0] }}</span><br>
                                        <span class='team_2_red_cards'>{{ game.visitor_cards[1] + game.visitor_cards[2] }}</span>
                                      </template>
                                    </div>
                                  </div>
                                </td>
                                
                                <td v-if="(game.visitor_id != basic.local_id) && (game.visitor_id != basic.visitor_id)" class="text-right">
                                  <router-link :to="{name: 'team', params: {id: game.visitor_id}}" class="overflow-anywhere blue-grey--text">
                                    {{ game.visitor_name }}
                                  </router-link>
                                </td>
                                
                                <td v-else class="overflow-anywhere text-right black--text">{{ game.visitor_name }}</td>
                                
                                <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </div>

                      <div v-if="rALastLoaded">
                        <div class="matches_heading">{{ basic.visitor_name }} {{ $t('lastmatches') }} {{ $t('with') }} {{ basic.referee_name }}</div>

                        <v-container fluid>
                          <div class="row d-flex">
                            <v-select
                              v-model="rAChosenLeagues"
                              :items="rLeagues"
                              item-text="league_name"
                              item-value="league_id"
                              class="col-12 col-sm-7 col-lg-9"
                              attach
                              dense
                              :label="$t('tournament')"
                              hide-details
                              multiple
                            />

                            <v-select
                              v-model="rAChosenSeasons"
                              :items="rSeasons"
                              :label="$t('season')"
                              class="col-12 col-sm-5 col-lg-3"
                              attach
                              dense
                              hide-details
                              multiple
                            />
                          </div>

                          <div class="row d-flex flex-wrap justify-space-between">
                            <v-select
                              v-model="rAPerPage"
                              :items="rCountPerPage"
                              attach
                              dense
                              class="col-4 col-sm-2 col-md-1"
                              :hint="$t('countperpage')"
                            />

                            <v-text-field
                              v-model="rAPPageByUser"
                              class="col-3 col-sm-2 col-md-1"
                              dense
                              :hint="$t('countperpage')"
                              type="number"
                              max=149
                              min=1
                              v-on:keyup.enter="rPerPageChange(2)"
                            />
                          </div>
                        </v-container>

                        <v-simple-table
                          v-if="rAAvgM_c"
                          class="table-row-35 border-lightgrey text-center"
                          style="border-radius: 0">
                          <tbody>
                            <tr>
                              <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                              <td :title="$t('q_yc.tooltip')">{{ $t('q_yc.label') }}</td>
                              <td :title="$t('t_yc.tooltip')">{{ $t('t_yc.label') }}</td>
                              <td :title="$t('q_rc.tooltip')">{{ $t('q_rc.label') }}</td>
                              <td :title="$t('t_rc.tooltip')">{{ $t('t_rc.label') }}</td>
                              <td :title="$t('penalty_tooltip')">{{ $t('penalty') }}</td>
                              <td :title="$t('foul_tooltip')">{{ $t('f.tooltip') }}</td>
                            </tr>
                            
                            <tr class="font-weight-bold">
                              <td>{{ rAAvgM_c }}</td>
                              <td>{{ rAAvgQ_yc }}</td>
                              <td>{{ rAAvgT_yc }}</td>
                              <td>{{ rAAvgQ_rc }}</td>
                              <td>{{ rAAvgT_rc }}</td>
                              <td>{{ rAAvgPen }}%</td>
                              <td>{{ rAAvgFouls }}</td>
                            </tr>
                          </tbody>
                        </v-simple-table>

                        <div class="curPointer pt-5 pb-2">
                          <a :class="{'d-none' : rAPage == rALastPage}" class="ml-1 mr-4" @click="rPrev(2)">{{ $t('previous') }}</a>
                          <a :class="{'d-none' : !rAPage}" @click="rNext(2)">{{ $t('next') }}</a>
                        </div>
                        
                        <v-simple-table class="cell-py-10 border-lightgrey" style="border-radius: 0">
                          <thead>
                            <tr>
                              <th>{{ $t('date') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                              <th>{{ $t('home') }}</th>
                              <th></th>
                              <th class="text-right">{{ $t('away') }}</th>
                              <th class="text-center">{{ $t('detail') }}</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-for="(game, index) in rALast[rAPage]"
                              :key="index"
                              :class="{'curPointer': (game.local_cards[0] != -1)}"
                              @click="refereeModal(game.index, game.local_id, game.local_name, game.visitor_name)">
                                <td class="overflow-anywhere blue-grey--text">
                                  <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                                  <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                                </td>

                                <td :class="{'d-none': $vuetify.breakpoint.xs}" class="overflow-anywhere">
                                  <router-link :to="{name: 'league', params: {lid: game.league_id}}" class="blue-grey--text">
                                    {{ game.league_name }}
                                  </router-link>
                                </td>

                                <td :class="{'d-none': $vuetify.breakpoint.xs}" class="blue-grey--text">
                                  {{ game.round ? game.round.name : '' }}
                                </td>

                                <td v-if="(game.local_id != basic.local_id) && (game.local_id != basic.visitor_id)">
                                  <router-link :to="{name: 'team', params: {id: game.local_id}}" class="overflow-anywhere blue-grey--text">
                                    {{ game.local_name }}
                                  </router-link>
                                </td>

                                <td v-else class="overflow-anywhere black--text">{{ game.local_name }}</td>

                                <td class="blue-grey--text">
                                  <div class="d-flex justify-space-around align-center subtitle-2">
                                    <div>
                                      <template 
                                        v-if="game.local_cards[0] == -1">
                                        ?
                                      </template>

                                      <template v-else>
                                        <span class="team_1_yellow_cards">{{ game.local_cards[0] }}</span><br>
                                        <span class="team_1_red_cards">{{ game.local_cards[1] + game.local_cards[2] }}</span>
                                      </template>
                                    </div>

                                    <div style="min-width: 11px;">
                                      <img src="/img/icons/yellow_card.png"><br>
                                      <img src="/img/icons/red_card.png">
                                    </div>
                                    
                                    <div>
                                      <template 
                                        v-if="game.local_cards[0] == -1">
                                        ?
                                      </template>

                                      <template v-else>
                                        <span class='team_2_yellow_cards'>{{ game.visitor_cards[0] }}</span><br>
                                        <span class='team_2_red_cards'>{{ game.visitor_cards[1] + game.visitor_cards[2] }}</span>
                                      </template>
                                    </div>
                                  </div>
                                </td>

                                <td v-if="(game.visitor_id != basic.local_id) && (game.visitor_id != basic.visitor_id)" class="text-right">
                                  <router-link :to="{name: 'team', params: {id: game.visitor_id}}" class="overflow-anywhere blue-grey--text">
                                    {{ game.visitor_name }}
                                  </router-link>
                                </td>
                                
                                <td v-else class="overflow-anywhere text-right black--text">{{ game.visitor_name }}</td>
                                
                                <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </div>
                    </template>
                  </div>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </template>
    </div>
  </v-app>
</template>

<script>
import { Card, Tabs, TabPane } from '@/components'
import moment from 'moment-timezone'
import axios from 'axios'
import colors from 'vuetify/lib/util/colors'

export default {
  components: {
    Card,
    Tabs,
    TabPane,
  },
  data() {
      return {
        match_id: this.$route.query.mid,
        lg_country_id: this.$route.query.cid,
        lg_country_name: this.$route.query.cn,
        tz: localStorage.tz ? localStorage.tz : '+0',
        odds_format: localStorage.odds_format ? localStorage.odds_format : 0,
        
        /* variable for check boxs on Odds Markets Panel
         0: FullTime, 1: Clean Sheet, 2: First Team to Score/Last, 3: Asian Handicap, 4: Total Over/Under
         5: Corner 1x2, 6: Corner Handicap, 7: Team Corner, 8: Total Corner Over/Under
         9: Card Asian Handicap, 10: Asian Total Cards, 11: Team Card, 12: Red Card
         13: Score Penalty, 14: Miss Penalty, 15: Penalty in Match
        */
        show_odds_market: localStorage.odds_market ? JSON.parse(localStorage.odds_market) : 
            [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true],

        // variable for expanding odds markets on tabs
        expanding_market: localStorage.expanding_market ? JSON.parse(localStorage.expanding_market) : [null, null, null, null],

        // h2h stats
        cornerData: {},
        goalData: {},
        cardData: {},
        h2h: [],
        CGC: [],
        originCGC: [],
        filteredCGC: [],

        // last stats
        Hlast: [],
        Alast: [],
        hLastAvg: {},
        aLastAvg: {},

        // similar stats
        hSimilar: [],
        aSimilar: [],
        hSimilarAvg: {},
        aSimilarAvg: {},

        // odds
        odds: {},
        moreOdds: [],

        // basic info and stats for modal when click
        basic: {
          match_id: '',
          lg_name: '',
          lg_logo: '',
          local_id: '',
          local_name: '',
          local_logo: '',
          local_coach: '',
          visitor_id: '',
          visitor_name: '',
          visitor_logo: '',
          visitor_coach: '',
          start_dt: '',
          season: '',
          referee_id: '',
          referee_name: '',
        },
        subCorner: {
          dt: '',
          home: '',
          away: '',
          data: [],
        },
        subCard: {
          dt: '',
          home: '',
          away: '',
          data: [],
        },
        subGoal: {
          dt: '',
          home: '',
          away: '',
          data: [],
        },

        basicLoaded: false,
        h2hLoaded: false,
        lastHomeLoaded: false,
        lastAwayLoaded: false,
        similarHomeLoaded: false,
        similarAwayLoaded: false,
        hLastAvgLoaded: false,
        aLastAvgLoaded: false,
        hSimilarAvgLoaded: false,
        aSimilarAvgLoaded: false,
        goalOddsLoaded: false,
        cornerOddsLoaded: false,
        cardOddsLoaded: false,
        statsOddsLoaded: false,

        // Modal Dialogs
        subCornerDialog: false,
        subGoalDialog: false,
        subCardDialog: false,
        subRCardDialog: false,
        cornerOddsDialog: false,
        goalOddsDialog: false,
        cardOddsDialog: false,
        refereeDialog: false,

        homePage: 0,
        awayPage: 0,
        h2hPage: 0,
        // similar home & away page
        hSPage: 0,
        aSPage: 0,
        homePerPage: 20,
        awayPerPage: 20,
        h2hPerPage: 20,
        hSPerPage: 20,
        aSPerPage: 20,
        hPPageByUser: '',
        aPPageByUser: '',
        h2hPPageByUser: '',
        // similar home & away per page
        hSPPageByUser: '',
        aSPPageByUser: '',
        hLastPage: -1,
        aLastPage: -1,
        h2hLastPage: -1,
        hSLastPage: -1,
        aSLastPage: -1,
        
        hCurrentCoach: false,
        hPrevCoach: false,

        aCurrentCoach: false,
        aPrevCoach: false,

        h2hCurrentCoach: false,
        h2hPrevCoach: false,

        hSCurrentCoach: false,
        hSPrevCoach: false,

        aSCurrentCoach: false,
        aSPrevCoach: false,

        hLocal: true,
        hVisitor: true,
        aLocal: true,
        aVisitor: true,
        hSLocal: true,
        hSVisitor: true,
        aSLocal: true,
        aSVisitor: true,
        h2hLocal: true,
        h2hVisitor: true,
        homeLeagueLoaded: false,
        awayLeagueLoaded: false,
        hQuerySeasons: '',
        aQuerySeasons: '',
        hSQuerySeasons: '',
        aSQuerySeasons: '',
        homeChosenLeagues: [],
        awayChosenLeagues: [],
        hSChosenLeagues: [],
        aSChosenLeagues: [],
        h2hChosenLeagues: [],
        homeChosenSeasons: [],
        awayChosenSeasons: [],
        hSChosenSeasons: [],
        aSChosenSeasons: [],
        h2hChosenSeasons: [],
        HLeagues: [],
        ALeagues: [],
        h2hLeagues: [],
        HSeasons: [],
        ASeasons: [],
        h2hSeasons: [],
        HTotalSeasons: [],
        ATotalSeasons: [],
        h2hTotalSeasons: [],

        substitutionLoaded: false,
        tab: null,
        items: ['goals', 'corners', 'cards', 'stats'],
        flags: {
            corner: 0,
            goal: 0,
            card: 0,
            referee: 0,
            other: 0,
          },
        msg_last: 'notlogin',
        msg_similar: 'notlogin',
        authorization: false,
        stats_color: [
          '#28a745',
          '#ffc107',
          '#dc3545'
        ],

        // begin of the variables for referee tab
        rLastLoaded: false,
        rHLastLoaded: false,
        rALastLoaded: false,
        originRLast: [],
        rLast: [],
        rHLast: [],
        rALast: [],
        originRefereeLast: [],
        originRHomeLast: [],
        originRAwayLast: [],
        filteredRefereeLast: [],
        filteredRHomeLast: [],
        filteredRAwayLast: [],
        rPage: 0,
        rHPage: 0,
        rAPage: 0,
        rPerPage: 20,
        rHPerPage: 20,
        rAPerPage: 20,
        rPPageByUser: '',
        rHPPageByUser: '',
        rAPPageByUser: '',
        rLastPage: -1,
        rHLastPage: -1,
        rALastPage: -1,
        rLeagueLoaded: false,
        rQuerySeasons: '',
        rChosenSeasons: [],
        rHChosenSeasons: [],
        rAChosenSeasons: [],
        rChosenLeagues: [],
        rHChosenLeagues: [],
        rAChosenLeagues: [],
        rLeagues: [],
        rSeasons: [],
        rTotalSeasons: [],
        // end of the variables for referee tab

        // begin of the avg stats on referee
        rAvgM_c: 0,
        rAvgQ_yc: 0,
        rAvgT_yc: 0,
        rAvgQ_rc: 0,
        rAvgT_rc: 0,
        rAvgPen: 0,
        rAvgFouls: 0,
        rHAvgM_c: 0,
        rHAvgQ_yc: 0,
        rHAvgT_yc: 0,
        rHAvgQ_rc: 0,
        rHAvgT_rc: 0,
        rHAvgPen: 0,
        rHAvgFouls: 0,
        rAAvgM_c: 0,
        rAAvgQ_yc: 0,
        rAAvgT_yc: 0,
        rAAvgQ_rc: 0,
        rAAvgT_rc: 0,
        rAAvgPen: 0,
        rAAvgFouls: 0,
        // end of the avg stats on referee

        chosenReferee: null,
        refereeStats: new Map(),

        // begin of 'other' tab
        chosenCategory: 0,

        otherH2hM: [],
        otherH2hP: [],
        otherH2hW: [],
        otherH2hD: [],
        otherH2hL: [],
        otherH2hDiff: [],
        otherH2hD_av: [],
        otherH2hT: [],
        otherH2hT1: [],
        otherH2hT2: [],

        hOtherLastAvg: {},
        aOtherLastAvg: {},

        hOtherSimilarAvg: {},
        aOtherSimilarAvg: {},
        // end of 'other' tab

        // begin of half / full time combobox
        h2hFullHalf: 0,
        hLastFullHalf: 0,
        aLastFullHalf: 0,
        hSimilarFullHalf: 0,
        aSimilarFullHalf: 0,
        timeCategories: [
          {text: 'fulltime', index: 0},
          {text: 'half1', index: 1},
          {text: 'half2', index: 2}],
        // end of half / full time combobox

        // count of matches with current coach
        h2h_coach_count: null,
        local_coach_count: null,
        visitor_coach_count: null,
        ls_coach_count: null,
        vs_coach_count: null,

        // home/away last data including stats
        hLastData: [],
        aLastData: [],

        // home/away similar stats
        hSimilarStats: [],
        aSimilarStats: [],

        // form filterings
        count_form: localStorage.count_form ? localStorage.count_form : 5,
        type_form: localStorage.type_form ? Number(localStorage.type_form) : 0,       // 0: home/away, 1: overall
        league_form: localStorage.league_form ? Number(localStorage.league_form) : 0, // 0: all leagues, 1: league to play
      }
  },
  computed: {
    countPerPage () {
      return [20, 30, 40, 60, 80, this.$t('all')]
    },
    
    h2hCountPerPage () {
      return [5, 10, 20, 30, 50, this.$t('all')]
    },

    rCountPerPage () {
      return [20, 30, 40, 60, 80, this.$t('all')]
    },

    categories () {
      return [
        {text: this.$t('stats_type.SHOTS_TOTAL'), index: 0},
        {text: this.$t('stats_type.SHOTS_ON_TARGET'), index: 1},
        {text: this.$t('stats_type.SHOTS_BLOCKED'), index: 2},
        {text: this.$t('stats_type.SHOTS_INSIDEBOX'), index: 3},
        {text: this.$t('stats_type.ATTACKS'), index: 4},
        {text: this.$t('stats_type.DANGEROUS_ATTACKS'), index: 5},
        {text: this.$t('stats_type.PASSES_TOTAL'), index: 6},
        {text: this.$t('stats_type.PASSES_ACCURATE'), index: 7},
        {text: this.$t('stats_type.BALL_SAFE'), index: 8},
        {text: this.$t('stats_type.FOULS'), index: 9},
        {text: this.$t('stats_type.FREE_KICKS'), index: 10},
        {text: this.$t('stats_type.GOAL_ATTEMPTS'), index: 11},
        {text: this.$t('stats_type.GOAL_KICKS'), index: 12},
        {text: this.$t('stats_type.INJURIES'), index: 13},
        {text: this.$t('stats_type.OFFSIDES'), index: 14},
        {text: this.$t('stats_type.PENALTIES'), index: 15},
        {text: this.$t('stats_type.BALL_POSSESSION'), index: 16},
        {text: this.$t('stats_type.SAVES'), index: 17},
        {text: this.$t('stats_type.SUBSTITUTIONS'), index: 18},
        {text: this.$t('stats_type.TACKLES'), index: 19},
        {text: this.$t('stats_type.THROWINS'), index: 20}
      ]
    }
  },
  mounted() {
      this.getBasics()
  },
  watch: {
    homePerPage: function (val) {
      this.homePage = 0
      this.hLastPage = -1
      this.Hlast = []
      this.hLastData = []
      this.lastHomeLoaded = false
    
      this.getLast(1)
    },

    awayPerPage: function (val) {
      this.awayPage = 0
      this.aLastPage = -1
      this.Alast = []
      this.aLastData = []
      this.lastAwayLoaded = false
    
      this.getLast(2)
    },
    
    hSPerPage: function (val) {
      this.hSPage = 0
      this.hSLastPage = -1
      this.hSimilar = []
      this.hSimilarStats = []
      this.similarHomeLoaded = false
    
      this.getSimilar(1)
    },

    aSPerPage: function (val) {
      this.aSPage = 0
      this.aSLastPage = -1
      this.aSimilar = []
      this.aSimilarStats = []
      this.similarAwayLoaded = false
    
      this.getSimilar(2)
    },

    h2hPerPage: function (val) {
      this.sliceH2h()
    },

    rPerPage: function (val) {
      this.sliceRefereeLast(0)
    },

    rHPerPage: function (val) {
      this.sliceRefereeLast(1)
    },

    rAPerPage: function (val) {
      this.sliceRefereeLast(2)
    },

    homeChosenLeagues: function (val) {
      this.changeQSeasons(1)
    },

    homeChosenSeasons: function (val) {
      this.changeQSeasons(1)
    },

    awayChosenLeagues: function (val) {
      this.changeQSeasons(2)
    },

    awayChosenSeasons: function (val) {
      this.changeQSeasons(2)
    },

    hSChosenLeagues: function (val) {
      this.changeSQSeasons(1)
    },

    hSChosenSeasons: function (val) {
      this.changeSQSeasons(1)
    },

    aSChosenLeagues: function (val) {
      this.changeSQSeasons(2)
    },

    aSChosenSeasons: function (val) {
      this.changeSQSeasons(2)
    },

    h2hChosenLeagues: function (val) {
      this.changeH2HFilter()
    },

    h2hChosenSeasons: function (val) {
      this.changeH2HFilter()
    },

    rChosenLeagues: function (val) {
      this.changeRFilter(0)
    },

    rChosenSeasons: function (val) {
      this.changeRFilter(0)
    },

    rHChosenLeagues: function (val) {
      this.changeRFilter(1)
    },

    rHChosenSeasons: function (val) {
      this.changeRFilter(1)
    },

    rAChosenLeagues: function (val) {
      this.changeRFilter(2)
    },

    rAChosenSeasons: function (val) {
      this.changeRFilter(2)
    },

    hCurrentCoach (val) {
      this.homePage = 0
      this.hLastPage = -1
      this.Hlast = []
      this.hLastData = []
      this.lastHomeLoaded = false
      
      this.getLast(1)
    },

    hPrevCoach (val) {
      this.homePage = 0
      this.hLastPage = -1
      this.Hlast = []
      this.hLastData = []
      this.lastHomeLoaded = false
      
      this.getLast(1)
    },

    aCurrentCoach (val) {
      this.awayPage = 0
      this.aLastPage = -1
      this.Alast = []
      this.aLastData = []
      this.lastAwayLoaded = false
      
      this.getLast(2)
    },

    aPrevCoach (val) {
      this.awayPage = 0
      this.aLastPage = -1
      this.Alast = []
      this.aLastData = []
      this.lastAwayLoaded = false
      
      this.getLast(2)
    },

    hSCurrentCoach (val) {
      this.hSPage = 0
      this.hSLastPage = -1
      this.hSimilar = []
      this.hSimilarStats = []
      this.similarHomeLoaded = false
      
      this.getSimilar(1)
    },

    hSPrevCoach (val) {
      this.hSPage = 0
      this.hSLastPage = -1
      this.hSimilar = []
      this.hSimilarStats = []
      this.similarHomeLoaded = false
      
      this.getSimilar(1)
    },

    aSCurrentCoach (val) {
      this.aSPage = 0
      this.aSLastPage = -1
      this.aSimilar = []
      this.aSimilarStats = []
      this.similarAwayLoaded = false
      
      this.getSimilar(2)
    },

    aSPrevCoach (val) {
      this.aSPage = 0
      this.aSLastPage = -1
      this.aSimilar = []
      this.aSimilarStats = []
      this.similarAwayLoaded = false
      
      this.getSimilar(2)
    },

    h2hCurrentCoach (val) {
      this.changeH2HFilter()
    },

    h2hPrevCoach (val) {
      this.changeH2HFilter()
    },

    hLocal (val) {
      if (!val && !this.hVisitor) {
        this.hLocal = !val
      }else {
        this.homePage = 0
        this.hLastPage = -1
        this.Hlast = []
        this.hLastData = []
        this.lastHomeLoaded = false

        this.getLast(1)
      }
    },
    
    hVisitor (val) {
      if (!val && !this.hLocal) {
        this.hVisitor = !val
      }else {
        this.homePage = 0
        this.hLastPage = -1
        this.Hlast = []
        this.hLastData = []
        this.lastHomeLoaded = false

        this.getLast(1)
      }
    },    

    aLocal (val) {
      if (!val && !this.aVisitor) {
        this.aLocal = !val
      }else {
        this.awayPage = 0
        this.aLastPage = -1
        this.Alast = []
        this.aLastData = []
        this.lastAwayLoaded = false

        this.getLast(2)
      }
    },
    
    aVisitor (val) {
      if (!val && !this.aLocal) {
        this.aVisitor = !val
      }else {
        this.awayPage = 0
        this.aLastPage = -1
        this.Alast = []
        this.aLastData = []
        this.lastAwayLoaded = false

        this.getLast(2)
      }
    },

    hSLocal (val) {
      if (!val && !this.hSVisitor) {
        this.hSLocal = !val
      }else {
        this.hSPage = 0
        this.hSLastPage = -1
        this.hSimilar = []
        this.hSimilarStats = []
        this.similarHomeLoaded = false

        this.getSimilar(1)
      }
    },
    
    hSVisitor (val) {
      if (!val && !this.hSLocal) {
        this.hSVisitor = !val
      }else {
        this.hSPage = 0
        this.hSLastPage = -1
        this.hSimilar = []
        this.hSimilarStats = []
        this.similarHomeLoaded = false

        this.getSimilar(1)
      }
    },    

    aSLocal (val) {
      if (!val && !this.aSVisitor) {
        this.aSLocal = !val
      }else {
        this.aSPage = 0
        this.aSLastPage = -1
        this.aSimilar = []
        this.aSimilarStats = []
        this.similarAwayLoaded = false

        this.getSimilar(2)
      }
    },
    
    aSVisitor (val) {
      if (!val && !this.aSLocal) {
        this.aSVisitor = !val
      }else {
        this.aSPage = 0
        this.aSLastPage = -1
        this.aSimilar = []
        this.aSimilarStats = []
        this.similarAwayLoaded = false

        this.getSimilar(2)
      }
    },

    h2hLocal (val) {
      this.changeH2HFilter()
    },

    h2hVisitor (val) {
      this.changeH2HFilter()
    },

    h2hFullHalf (val) {
      this.h2hLoaded = false
      this.originCGC = []
      this.filteredCGC = []
      this.get_cgc()
      this.changeH2HFilter()
    },

    hLastFullHalf (val) {
      this.homePage = 0
      this.hLastPage = -1
      this.Hlast = []
      this.hLastData = []
      this.lastHomeLoaded = false

      this.getLast(1)
    },

    aLastFullHalf (val) {
      this.awayPage = 0
      this.aLastPage = -1
      this.Alast = []
      this.aLastData = []
      this.lastAwayLoaded = false

      this.getLast(2)
    },

    hSimilarFullHalf (val) {
      this.hSPage = 0
      this.hSLastPage = -1
      this.hSimilar = []
      this.hSimilarStats = []
      this.similarHomeLoaded = false

      this.getSimilar(1)
    },

    aSimilarFullHalf (val) {
      this.aSPage = 0
      this.aSLastPage = -1
      this.aSimilar = []
      this.aSimilarStats = []
      this.similarAwayLoaded = false

      this.getSimilar(2)
    },

    show_odds_market (val) {
      localStorage.setItem("odds_market", JSON.stringify(val))
    },

    expanding_market (val) {
      localStorage.setItem("expanding_market", JSON.stringify(val))
    }
  },
  methods: {
      async getBasics() {
        //Etc/GMT-X will have an offset of +X and Etc/GMT+X will have an offset of -X.
        moment.tz.setDefault('Etc/GMT' + this.tz)

        // get odds
        this.getOdds()

        axios
          .get('/match_info/' + this.match_id,
            { params: {
                league_form: this.league_form,
                type_form: this.type_form,
                count_form: this.count_form }})
          .then(response => {
            // check if api sent none match data
            if (response.data.length) {
              this.basic = response.data[0]
              if (this.basic.referee_id) {
                this.items.push('referee')
              }

              if (this.basic.local_coach && this.basic.visitor_coach) {
                this.h2h_coach_count = 0
              } else {
                this.h2h_coach_count = -1
        
                if (!this.basic.local_coach) {
                  this.local_coach_count = -1
                  this.ls_coach_count = -1
                }

                if (!this.basic.visitor_coach) {
                  this.visitor_coach_count = -1
                  this.vs_coach_count = -1
                }
              }

              // get form
              this.basic.form.local = this.basic.form.local ? parseFloat(this.basic.form.local).toFixed(2) : null
              this.basic.form.visitor = this.basic.form.visitor ? parseFloat(this.basic.form.visitor).toFixed(2) : null
              // set color form of team
              this.basic.form.local_color = this.get_form_color(this.basic.form.local)
              this.basic.form.visitor_color = this.get_form_color(this.basic.form.visitor)

              this.basicLoaded = true
              this.getH2H()
            } else if (!localStorage.token) {
              this.$router.replace('/login')
            } else {
              axios
                .get('/v3/fixture/' + this.match_id,
                { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
                .then(response => {
                  if (response.data.type == 0 || response.data.type == 1) {
                    this.$router.replace('/message/subscribe/' + (response.data.type ? 'expired' : 'not-paid'))
                  } else {
                    let data  = response.data
                    
                    if (data.participants[0].meta.location == "away") {
                      data.teams = [data.participants[1], data.participants[0]]
                    } else {
                      data.teams = data.participants
                    }

                    if (data.coaches.length) {
                      if (data.coaches[0].meta.participant_id == data.teams[1].id) {
                        data.coaches = [data.coaches[1], data.coaches[0]]
                      }
                    } else {
                      data.coaches = [{id: null}, {id: null}]
                    }

                    if (data.coaches[0].id && data.coaches[1].id) {
                      this.h2h_coach_count = 0
                    } else {
                      this.h2h_coach_count = -1
              
                      if (!data.coaches[0].id) {
                        this.local_coach_count = -1
                        this.ls_coach_count = -1
                      }

                      if (!data.coaches[1].id) {
                        this.visitor_coach_count = -1
                        this.vs_coach_count = -1
                      }
                    }

                    data.referees = _.orderBy(data.referees, ['type_id'])

                    this.basic = {
                        match_id: data.id,
                        lg_id: data.league_id,
                        lg_name: data.league.name,
                        lg_logo: data.league.image_path,                      
                        local_id: data.teams[0].id,
                        local_name: data.teams[0].name,
                        local_logo: data.teams[0].image_path,
                        local_coach: data.coaches[0].id,
                        local_pos: data.teams[0].meta.position,
                        visitor_id: data.teams[1].id,
                        visitor_name: data.teams[1].name,
                        visitor_logo: data.teams[1].image_path,
                        visitor_coach: data.coaches[1].id,
                        visitor_pos: data.teams[1].meta.position,
                        start_dt: data.starting_at_timestamp,
                        season: data.season.name,
                        referee_id: data.referees.length ? data.referees[0].referee_id : null,
                        referee_name: data.referees.length ? data.referees[0].referee.common_name : '',
                        stadium: data.venue ? data.venue.name : null
                      }

                    this.lg_country_id = data.league.country_id
                    this.lg_country_name = data.league.country.name

                    if (this.basic.referee_id) {
                      this.items.push('referee')
                    }
                      
                    this.basicLoaded = true
                    this.getH2H()
                  }                 
                })
                .catch(error => {
                  if (error.response.status == 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('uid')
                    this.$router.replace('/login')
                  }              
                })
            }
          })
          //.catch(error => {})
      },

      getOdds() {
        if (localStorage.token) {
          axios
            .get('/v3/odds/pre-match/' + this.match_id,
              { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
            .then(response => {
              if (response.data.type == 2 && response.data.exists) {
                this.odds = response.data.odds

                this.modifyOdds()
                this.goalOddsLoaded = response.data.exists[0]
                this.cornerOddsLoaded = response.data.exists[1]
                this.cardOddsLoaded = response.data.exists[2]
                this.statsOddsLoaded = response.data.exists[3]
              }
            })
            //.catch(error => {})
        }
      },

      async getH2H() {
        axios
          .get('/match_h2h/' + this.basic.local_id + '/' + this.basic.visitor_id)
          .then(response => {
            this.h2h = response.data.data
            this.get_cgc()
            this.h2hLoaded = true
          })
          //.catch(error => {})
      },

      get_cgc() {
        let seasonIds = [], leagueIds = []
        let index = 0

        this.h2h.forEach((game) => {
            let item = {
                index: index,
                match_id: game.match_id,
                date: game.start_dt,
                local_id: game.local_id,
                local_name: game.local_name,
                visitor_id: game.visitor_id,
                visitor_name: game.visitor_name,
                league_id: game.league_id,
                league_name: game.league_name,
                season_id: game.season_id,
                round_name: game.round_name,
                local_coach: game.local_coach_id,
                visitor_coach: game.visitor_coach_id,
                local_corners: 0,
                visitor_corners: 0,
                local_goals: 0,
                visitor_goals: 0,
                corners_bgcolor: '',
                goals_bgcolor: '',
                local_cards: [0, 0, 0],
                visitor_cards: [0, 0, 0],
                referee: '',
                referee_id: '',
                stats: [], // other stats
            }

            // count for current coach
            if (this.h2h_coach_count != -1 && ((game.local_id == this.basic.local_id && game.local_coach_id == this.basic.local_coach && game.visitor_id == this.basic.visitor_id && game.visitor_coach_id == this.basic.visitor_coach)
              || (game.local_id == this.basic.visitor_id && game.local_coach_id == this.basic.visitor_coach && game.visitor_id == this.basic.local_id && game.visitor_coach_id == this.basic.local_coach))) {
              this.h2h_coach_count += 1
            }

            if (!this.h2hFullHalf) {
              item.local_goals = game.scores.localteam_score
              item.visitor_goals = game.scores.visitorteam_score
            } else {
              let ht = (game.scores.ht_score) ? game.scores.ht_score.split('-') : [0, 0]
              if (this.h2hFullHalf == 1) {
                item.local_goals = Number(ht[0])
                item.visitor_goals = Number(ht[1])
              } else if (this.h2hFullHalf == 2) {
                item.local_goals = game.scores.localteam_score - Number(ht[0])
                item.visitor_goals = game.scores.visitorteam_score - Number(ht[1])
              }
            }

            index++

            if (game.corners) {
              game.corners.data.forEach((evt) => {
                if (!evt.comment.includes("Race")) { 
                  if (evt.comment != 'X' && 
                    (this.h2hFullHalf == 0 || (this.h2hFullHalf == 1 && Number(evt.minute) < 46) || (this.h2hFullHalf == 2 && Number(evt.minute) > 45))) {
                    if (evt.team_id == game.local_id) item.local_corners++
                    else if(evt.team_id == game.visitor_id) item.visitor_corners++
                  }
                }else {
                  evt.comment='X'
                }
              })
            }

            if (((game.local_id == this.basic.local_id) && (item.local_corners > item.visitor_corners)) ||
                ((game.local_id == this.basic.visitor_id) && (item.local_corners < item.visitor_corners))) {
                  item.corners_bgcolor = this.stats_color[0]
            }else if (item.local_corners == item.visitor_corners){
              item.corners_bgcolor = this.stats_color[1]
            }else {
              item.corners_bgcolor = this.stats_color[2]
            }

            if (((game.local_id == this.basic.local_id) && (item.local_goals > item.visitor_goals)) ||
              ((game.local_id == this.basic.visitor_id) && (item.local_goals < item.visitor_goals))) {
                item.goals_bgcolor = this.stats_color[0]
            }else if (item.local_goals == item.visitor_goals){
              item.goals_bgcolor = this.stats_color[1]
            }else {
              item.goals_bgcolor = this.stats_color[2]
            }

            let cards_exists = 0
            if (game.cards) {
              game.cards.data.forEach((evt) => {
                cards_exists = 1
                if (evt.minute <= 90 && (this.h2hFullHalf == 0 || (this.h2hFullHalf == 1 && Number(evt.minute) < 46) || (this.h2hFullHalf == 2 && Number(evt.minute) > 45))) {
                  if (evt.team_id == game.local_id) {
                    if (evt.type == 'yellowcard') item.local_cards[0]++
                    else if (evt.type == 'redcard') item.local_cards[1]++
                    else if (evt.type == 'yellowred') item.local_cards[2]++
                  }else {
                    if (evt.type == 'yellowcard') item.visitor_cards[0]++
                    else if (evt.type == 'redcard') item.visitor_cards[1]++
                    else if (evt.type == 'yellowred') item.visitor_cards[2]++
                  }
                }
              })
            }
            // check if wrong cards data
            if (!cards_exists) {
              if (this.h2hFullHalf == 0 && game.stats) {
                game.stats.data.forEach((t) => {
                  if (t.team_id == game.local_id){
                    if (t.yellowcards) item.local_cards[0] += t.yellowcards
                    if (t.redcards) item.local_cards[1] += t.yellowcards
                    if (t.yellowredcards) item.local_cards[2] += t.yellowcards
                  }else {
                    if (t.yellowcards) item.visitor_cards[0] += t.yellowcards
                    if (t.redcards) item.visitor_cards[1] += t.yellowcards
                    if (t.yellowredcards) item.visitor_cards[2] += t.yellowcards
                  }
                })
              }
              
              // if cards empty [] and stats cards also 0, set cards wrong data -1 for showing '?'
              if (item.local_cards[0] == 0 && item.local_cards[1] == 0 && item.local_cards[2] == 0
              && item.visitor_cards[0] == 0 && item.visitor_cards[1] == 0 && item.visitor_cards[2] == 0) {
                item.local_cards[0] = -1
              }
            }

            if (game.referee_id != null) {
              item.referee = game.referee_common_name
              item.referee_id = game.referee_id
            }

            // update h2h league-season info
            if (!seasonIds.includes(game.season_id)) {
              seasonIds.push(game.season_id)

              if (!this.h2hSeasons.includes(game.season_name))
                this.h2hSeasons.push(game.season_name)

              if (!leagueIds.includes(game.league_id)) {
                leagueIds.push(game.league_id)

                this.h2hLeagues.push({league_id: game.league_id, league_name: game.league_name})
              }

              this.h2hTotalSeasons.push({
                season_id: game.season_id,
                season_name: game.season_name,
                league_id: game.league_id,
                league_name: game.league_name})
            }

            if (game.stats && game.stats.data.length != 0) {
              // stats[0] : total shots
              if (game.stats.data[0].shots == null || game.stats.data[1].shots == null) {
                item.stats.push(['?', '?', this.stats_color[1]])
                item.stats.push(['?', '?', this.stats_color[1]])
                item.stats.push(['?', '?', this.stats_color[1]])
                item.stats.push(['?', '?', this.stats_color[1]])
              } else {
                item.stats.push(this.paint_color(game.local_id, game.stats.data[0].shots.total, game.stats.data[1].shots.total))
                item.stats.push(this.paint_color(game.local_id, game.stats.data[0].shots.ongoal, game.stats.data[1].shots.ongoal))
                item.stats.push(this.paint_color(game.local_id, game.stats.data[0].shots.blocked, game.stats.data[1].shots.blocked))
                item.stats.push(this.paint_color(game.local_id, game.stats.data[0].shots.insidebox, game.stats.data[1].shots.insidebox))
              }

              // stats[4] : attacks
              if (game.stats.data[0].attacks == null || game.stats.data[1].attacks == null) {
                item.stats.push(['?', '?', this.stats_color[1]])
                item.stats.push(['?', '?', this.stats_color[1]])
              } else {
                item.stats.push(this.paint_color(game.local_id, game.stats.data[0].attacks.attacks, game.stats.data[1].attacks.attacks))
                item.stats.push(this.paint_color(game.local_id, game.stats.data[0].attacks.dangerous_attacks, game.stats.data[1].attacks.dangerous_attacks))
              }

              // stats[6] : passes total
              if (game.stats.data[0].passes == null || game.stats.data[1].passes == null) {
                item.stats.push(['?', '?', this.stats_color[1]])
                item.stats.push(['?', '?', this.stats_color[1]])
              } else {
                item.stats.push(this.paint_color(game.local_id, game.stats.data[0].passes.total, game.stats.data[1].passes.total))
                item.stats.push(this.paint_color(game.local_id, game.stats.data[0].passes.accurate, game.stats.data[1].passes.accurate))
              }
              
              // stats[8] : ball safe
              item.stats.push(this.paint_color(game.local_id, game.stats.data[0].ball_safe, game.stats.data[1].ball_safe))

              // stats[9] : fouls
              item.stats.push(this.paint_color(game.local_id, game.stats.data[0].fouls, game.stats.data[1].fouls))

              // stats[10] : free kick
              item.stats.push(this.paint_color(game.local_id, game.stats.data[0].free_kick, game.stats.data[1].free_kick))

              // stats[11] : goal attempts
              item.stats.push(this.paint_color(game.local_id, game.stats.data[0].goal_attempts, game.stats.data[1].goal_attempts))

              // stats[12] : goal kick
              item.stats.push(this.paint_color(game.local_id, game.stats.data[0].goal_kick, game.stats.data[1].goal_kick))

              // stats[13] : injuries
              item.stats.push(this.paint_color(game.local_id, game.stats.data[0].injuries, game.stats.data[1].injuries))

              // stats[14] : offsides
              item.stats.push(this.paint_color(game.local_id, game.stats.data[0].offsides, game.stats.data[1].offsides))

              // stats[15] : penalties
              item.stats.push(this.paint_color(game.local_id, game.stats.data[0].penalties, game.stats.data[1].penalties))

              // stats[16] : possession time
              item.stats.push(this.paint_color(game.local_id, game.stats.data[0].possessiontime, game.stats.data[1].possessiontime))

              // stats[17] : saves
              item.stats.push(this.paint_color(game.local_id, game.stats.data[0].saves, game.stats.data[1].saves))

              // stats[18] : substitutions
              item.stats.push(this.paint_color(game.local_id, game.stats.data[0].substitutions, game.stats.data[1].substitutions))

              // stats[19] : tackles
              if (game.stats.data[0].tackles == null || game.stats.data[1].tackles == null) {
                item.stats.push(['?', '?', this.stats_color[1]])
              } else {
                item.stats.push(this.paint_color(game.local_id, game.stats.data[0].tackles, game.stats.data[1].tackles))
              }

              // stats[20] : throw in
              item.stats.push(this.paint_color(game.local_id, game.stats.data[0].throw_in, game.stats.data[1].throw_in))

            }

            this.originCGC.push(item)

        })

        this.filteredCGC = this.originCGC
        this.sliceH2h()

      },

      paint_color(local, val1, val2) {
        let a = (val1 == null) ? '?' : val1
        let b = (val2 == null) ? '?' : val2
        let col = this.stats_color[2]

        if (a == '?' || b == '?') {
          a = '?'
          b = '?'
          col = this.stats_color[1]
        } else if (a == b) {
          col = this.stats_color[1]
        } else if (((local == this.basic.local_id) && (a > b)) ||
          ((local == this.basic.visitor_id) && (a < b))) {
            col = this.stats_color[0]
        }

        return [a, b, col]
      },

      getH2HStats() {
        this.resetH2HStats()

        let homeVal = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        let awayVal = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

        let homeCorners = 0, awayCorners = 0
        let homeGoals = 0, awayGoals = 0
        let homeYC = 0, homeRC = 0, awayYC = 0, awayRC = 0

        var i = 0

        // from here, get avg stats on CGC[0]
        this.CGC[this.h2hPage].forEach((item) => {
          if (item.corners_bgcolor == this.stats_color[0]) {
            this.cornerData.win++
          } else if (item.corners_bgcolor == this.stats_color[2]){
            this.cornerData.lost++
          } else if (item.local_corners != 0) {
            this.cornerData.draw++
          }

          if (item.goals_bgcolor == this.stats_color[0]) {
            this.goalData.win++
          } else if (item.goals_bgcolor == this.stats_color[2]){
            this.goalData.lost++
          } else {
            this.goalData.draw++
          }

          if (item.local_id == this.basic.local_id) {
            homeCorners += item.local_corners
            awayCorners += item.visitor_corners
            
            homeGoals += item.local_goals
            awayGoals += item.visitor_goals

            if (item.local_cards[0] != -1) {
              homeYC += item.local_cards[0]
              homeRC += (item.local_cards[1] + item.local_cards[2])
              awayYC += item.visitor_cards[0]
              awayRC += (item.visitor_cards[1] + item.visitor_cards[2])
            }
          } else {
            homeCorners += item.visitor_corners
            awayCorners += item.local_corners

            homeGoals += item.visitor_goals
            awayGoals += item.local_goals

            if (item.local_cards[0] != -1) {
              homeYC += item.visitor_cards[0]
              homeRC += (item.visitor_cards[1] + item.visitor_cards[2])
              awayYC += item.local_cards[0]
              awayRC += (item.local_cards[1] + item.local_cards[2])
            }
          }

          // remove 0 - 0 cards matches
          if (item.local_cards[0] != -1) {
            this.cardData.matches++
          }

          // calculate avg for other tab
          if (item.stats.length) {
            for(i = 0; i < 21; i++) {
              if (item.stats[i][0] != '?' && item.stats[i][1] != '?') {
                if (item.stats[i][2] == this.stats_color[0]) {
                  this.otherH2hW[i]++
                } else if (item.stats[i][2] == this.stats_color[2]){
                  this.otherH2hL[i]++
                } else {
                  this.otherH2hD[i]++
                }
                
                if (item.local_id == this.basic.local_id) {
                  homeVal[i] += item.stats[i][0]
                  awayVal[i] += item.stats[i][1]
                } else {
                  homeVal[i] += item.stats[i][1]
                  awayVal[i] += item.stats[i][0]
                }

                this.otherH2hM[i]++
              }
            }
          }

        })

        // store corner data
        this.cornerData.matches = this.cornerData.win + this.cornerData.draw + this.cornerData.lost
        this.cornerData.points = (this.cornerData.win * 3) + this.cornerData.draw
        this.cornerData.diff = homeCorners + ' - ' + awayCorners
        this.cornerData.diff_av = !this.cornerData.matches ? 0 : ((homeCorners - awayCorners) / this.cornerData.matches).toFixed(2)
        this.cornerData.totalAvg = !this.cornerData.matches ? 0 : ((homeCorners + awayCorners) / this.cornerData.matches).toFixed(2)
        this.cornerData.homeTotalAvg = !this.cornerData.matches ? 0 : (homeCorners / this.cornerData.matches).toFixed(2)
        this.cornerData.awayTotalAvg = !this.cornerData.matches ? 0 : (awayCorners / this.cornerData.matches).toFixed(2)

        // store goal data
        this.goalData.matches = this.goalData.win + this.goalData.draw + this.goalData.lost
        this.goalData.points = (this.goalData.win * 3) + this.goalData.draw
        this.goalData.diff = homeGoals + ' - ' + awayGoals
        this.goalData.diff_av = !this.goalData.matches ? 0 : ((homeGoals - awayGoals) / this.goalData.matches).toFixed(2)
        this.goalData.totalAvg = !this.goalData.matches ? 0 : ((homeGoals + awayGoals) / this.goalData.matches).toFixed(2)
        this.goalData.homeTotalAvg = !this.goalData.matches ? 0 : (homeGoals / this.goalData.matches).toFixed(2)
        this.goalData.awayTotalAvg = !this.goalData.matches ? 0 : (awayGoals / this.goalData.matches).toFixed(2)

        // store card data
        this.cardData.diff_yc = homeYC + ' - ' + awayYC
        this.cardData.totalYC = !this.cardData.matches ? 0 : ((homeYC + awayYC) / this.cardData.matches).toFixed(2)
        this.cardData.homeTotalYC = !this.cardData.matches ? 0 : (homeYC / this.cardData.matches).toFixed(2)
        this.cardData.awayTotalYC = !this.cardData.matches ? 0 : (awayYC / this.cardData.matches).toFixed(2)
        this.cardData.diff_rc = homeRC + ' - ' + awayRC
        this.cardData.totalRC = !this.cardData.matches ? 0 : ((homeRC + awayRC) / this.cardData.matches).toFixed(2)
        this.cardData.homeTotalRC = !this.cardData.matches ? 0 : (homeRC / this.cardData.matches).toFixed(2)
        this.cardData.awayTotalRC = !this.cardData.matches ? 0 : (awayRC / this.cardData.matches).toFixed(2)

        // store avg for other tab
        for (i = 0; i < 21; i++) {
          this.otherH2hP[i] = (this.otherH2hW[i] * 3) + this.otherH2hD[i]
          this.otherH2hDiff[i] = homeVal[i] + '-' + awayVal[i]
          this.otherH2hD_av[i] = !this.otherH2hM[i] ? 0 : ((homeVal[i] - awayVal[i]) / this.otherH2hM[i]).toFixed(2)
          this.otherH2hT[i] = !this.otherH2hM[i] ? 0 : ((homeVal[i] + awayVal[i]) / this.otherH2hM[i]).toFixed(2)
          this.otherH2hT1[i] = !this.otherH2hM[i] ? 0 : (homeVal[i] / this.otherH2hM[i]).toFixed(2)
          this.otherH2hT2[i] = !this.otherH2hM[i] ? 0 : (awayVal[i] / this.otherH2hM[i]).toFixed(2)
        }
      },
      
      resetH2HStats() {
        // reset stats
        this.cornerData = {
          matches: 0,
          points: 0,
          win: 0,
          draw: 0,
          lost: 0,
          diff: 0,
          diff_av: 0,
          totalAvg: 0,
          homeTotalAvg: 0,
          awayTotalAvg: 0,
        }
        this.goalData = {
          matches: 0,
          points: 0,
          win: 0,
          draw: 0,
          lost: 0,
          diff: 0,
          diff_av: 0,
          totalAvg: 0,
          homeTotalAvg: 0,
          awayTotalAvg: 0,
        }
        this.cardData = {
          matches: 0,
          diff_yc: 0,
          diff_rc: 0,
          totalYC: 0,
          homeTotalYC: 0,
          awayTotalYC: 0,
          totalRC: 0,
          homeTotalRC: 0,
          awayTotalRC: 0,
        }

        this.otherH2hM = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        this.otherH2hP = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        this.otherH2hW = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        this.otherH2hD = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        this.otherH2hL = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        this.otherH2hDiff = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        this.otherH2hD_av = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        this.otherH2hT = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        this.otherH2hT1 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        this.otherH2hT2 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },

      sliceH2h() {
        // slice filteredCGC array by h2hPerPage, get slice and calculate avg stats from it.
        
        this.CGC = []
        
        this.h2hLastPage = -1
        if (this.filteredCGC.length) {
          let perpage = 0
          if (this.h2hPerPage == this.$t('all')) perpage = this.filteredCGC.length
          else perpage = Number(this.h2hPerPage)

          var i, j=this.filteredCGC.length, temporary
          for (i=0; i<j; i+=perpage) {
              temporary = this.filteredCGC.slice(i,i+perpage)
              this.CGC.push(temporary)
              this.h2hLastPage += 1
          }

          this.getH2HStats()
        } else {
          this.h2hLastPage = 0
          this.resetH2HStats()
        }

        this.h2hLoaded = true
      },

      changeH2HFilter() {
        // filter origin
        this.h2hPage = 0
        this.filteredCGC = this.queryH2HFilter()
        this.sliceH2h()
      },

      // filter originCGC by league, season, coach or home/away
      queryH2HFilter() {
        let temp = []
        
        // filter by seasons
        let querys = []
        this.h2hTotalSeasons.forEach((item) => {
          let leagues = this.h2hChosenLeagues.filter(league => league == item.league_id )
            let seasons = this.h2hChosenSeasons.filter(season => season == item.season_name )
            if((this.h2hChosenLeagues.length == 0 || leagues.length > 0) && (this.h2hChosenSeasons.length == 0 || seasons.length > 0))
              querys.push(item.season_id)
          })

        if(querys.length > 0) {
          temp = this.originCGC.filter(game => querys.includes(game.season_id))
        }

        // filter by coach

        if (this.h2hCurrentCoach && !this.h2hPrevCoach) {
          temp = temp.filter(game => 
            ((game.local_coach == this.basic.local_coach) && (game.visitor_coach == this.basic.visitor_coach)) || 
            ((game.local_coach == this.basic.visitor_coach) && (game.visitor_coach == this.basic.local_coach))
            )
        } else if (!this.h2hCurrentCoach && this.h2hPrevCoach) {
          temp = temp.filter(game => 
            ((game.local_coach != this.basic.local_coach) || (game.visitor_coach != this.basic.visitor_coach)) && 
            ((game.local_coach != this.basic.visitor_coach) || (game.visitor_coach != this.basic.local_coach))
            )
        }

        // filter by home/away
        if ((!this.h2hLocal && this.h2hVisitor) || (this.h2hLocal && !this.h2hVisitor)) {
          if (this.h2hLocal) {
            temp = temp.filter( game => game.local_id == this.basic.local_id )
          }else {
            temp = temp.filter( game => game.local_id != this.basic.local_id )
          }

          if (this.h2hVisitor) {
            temp = temp.filter( game => game.visitor_id == this.basic.local_id )
          }else {
            temp = temp.filter( game => game.visitor_id != this.basic.local_id )
          }
        }

        return temp
      },

      changeQSeasons(flag) {
        if (flag == 1) {
          let querys = ''
          this.HTotalSeasons.forEach((item) => {
            let leagues = this.homeChosenLeagues.filter(function (league) { return league == item.league_id })
            let seasons = this.homeChosenSeasons.filter(function (season) { return season == item.season_name })
            if((this.homeChosenLeagues.length == 0 || leagues.length > 0) && (this.homeChosenSeasons.length == 0 || seasons.length > 0))
              querys += (item.season_id + ',')
          })
          if(!querys)
            querys = '-1'
          this.hQuerySeasons = querys

          this.homePage = 0
          this.hLastPage = -1
          this.Hlast = []
          this.hLastData = []
          this.lastHomeLoaded = false

          this.getLast(1)
        }else {
          let querys = ''
          this.ATotalSeasons.forEach((item) => {
            let leagues = this.awayChosenLeagues.filter(function (league) { return league == item.league_id })
            let seasons = this.awayChosenSeasons.filter(function (season) { return season == item.season_name })
            if((this.awayChosenLeagues.length == 0 || leagues.length > 0) && (this.awayChosenSeasons.length == 0 || seasons.length > 0))
              querys += (item.season_id + ',')
          })
          if(!querys)
            querys = '-1'
          this.aQuerySeasons = querys

          this.awayPage = 0
          this.aLastPage = -1
          this.Alast = []
          this.aLastData = []
          this.lastAwayLoaded = false

          this.getLast(2)
        }
      },
      
      changeSQSeasons(flag) {
        if (flag == 1) {
          let querys = ''
          this.HTotalSeasons.forEach((item) => {
            let leagues = this.hSChosenLeagues.filter(function (league) { return league == item.league_id })
            let seasons = this.hSChosenSeasons.filter(function (season) { return season == item.season_name })
            if((this.hSChosenLeagues.length == 0 || leagues.length > 0) && (this.hSChosenSeasons.length == 0 || seasons.length > 0))
              querys += (item.season_id + ',')
          })
          if(!querys)
            querys = '-1'
          this.hSQuerySeasons = querys

          this.hSPage = 0
          this.hSLastPage = -1
          this.hSimilar = []
          this.hSimilarStats = []
          this.similarHomeLoaded = false

          this.getSimilar(1)
        }else {
          let querys = ''
          this.ATotalSeasons.forEach((item) => {
            let leagues = this.aSChosenLeagues.filter(function (league) { return league == item.league_id })
            let seasons = this.aSChosenSeasons.filter(function (season) { return season == item.season_name })
            if((this.aSChosenLeagues.length == 0 || leagues.length > 0) && (this.aSChosenSeasons.length == 0 || seasons.length > 0))
              querys += (item.season_id + ',')
          })
          if(!querys)
            querys = '-1'
          this.aSQuerySeasons = querys

          this.aSPage = 0
          this.aSLastPage = -1
          this.aSimilar = []
          this.aSimilarStats = []
          this.similarAwayLoaded = false

          this.getSimilar(2)
        }
      },

      getLastStats(stats) {
        let lastAvg = {}
        let otherLastAvg = {}
        
        // calculate last average table stats
        lastAvg.cornerMatch = stats.corner[0] + stats.corner[1] + stats.corner[2]
        lastAvg.cornerWDL = stats.corner
        lastAvg.cornerPoint = 3 * stats.corner[0] + stats.corner[1]
        lastAvg.cornerDiff = stats.tCorner + ' - ' + stats.oCorner
        lastAvg.cornerDiff_avg = !lastAvg.cornerMatch ? 0 : ((stats.tCorner - stats.oCorner) / lastAvg.cornerMatch).toFixed(2)
        lastAvg.cornerTotal = !lastAvg.cornerMatch ? 0 : ((stats.tCorner + stats.oCorner) / lastAvg.cornerMatch).toFixed(2)
        lastAvg.cornerT1 = !lastAvg.cornerMatch ? 0 : (stats.tCorner / lastAvg.cornerMatch).toFixed(2)
        lastAvg.cornerT2 = !lastAvg.cornerMatch ? 0 : (stats.oCorner / lastAvg.cornerMatch).toFixed(2)

        lastAvg.goalMatch = stats.goal[0] + stats.goal[1] + stats.goal[2]
        lastAvg.goalWDL = stats.goal
        lastAvg.goalPoint = 3 * stats.goal[0] + stats.goal[1]
        lastAvg.goalDiff = stats.tGoal + ' - ' + stats.oGoal
        lastAvg.goalDiff_avg = !lastAvg.goalMatch ? 0 : ((stats.tGoal - stats.oGoal) / lastAvg.goalMatch).toFixed(2)
        lastAvg.goalTotal = !lastAvg.goalMatch ? 0 : ((stats.tGoal + stats.oGoal) / lastAvg.goalMatch).toFixed(2)
        lastAvg.goalT1 = !lastAvg.goalMatch ? 0 : (stats.tGoal / lastAvg.goalMatch).toFixed(2)
        lastAvg.goalT2 = !lastAvg.goalMatch ? 0 : (stats.oGoal / lastAvg.goalMatch).toFixed(2)

        lastAvg.cardMatch = stats.card
        lastAvg.cardDiff_yc = stats.tYC + ' - ' + stats.oYC
        lastAvg.cardTotal_yc = !lastAvg.cardMatch ? 0 : ((stats.tYC + stats.oYC) / lastAvg.cardMatch).toFixed(2)
        lastAvg.cardT1_yc = !lastAvg.cardMatch ? 0 : (stats.tYC / lastAvg.cardMatch).toFixed(2)
        lastAvg.cardT2_yc = !lastAvg.cardMatch ? 0 : (stats.oYC / lastAvg.cardMatch).toFixed(2)
        lastAvg.cardDiff_rc = stats.tRC + ' - ' + stats.oRC
        lastAvg.cardTotal_rc = !lastAvg.cardMatch ? 0 : ((stats.tRC + stats.oRC) / lastAvg.cardMatch).toFixed(2)
        lastAvg.cardT1_rc = !lastAvg.cardMatch ? 0 : (stats.tRC / lastAvg.cardMatch).toFixed(2)
        lastAvg.cardT2_rc = !lastAvg.cardMatch ? 0 : (stats.oRC / lastAvg.cardMatch).toFixed(2)

        // get avg stats for other tab
        otherLastAvg.win = stats.avg_w
        otherLastAvg.draw = stats.avg_d
        otherLastAvg.lost = stats.avg_l
        otherLastAvg.point = []
        otherLastAvg.match = []
        otherLastAvg.diff = []
        otherLastAvg.diff_av = []
        otherLastAvg.total = []
        otherLastAvg.t1 = []
        otherLastAvg.t2 = []
        for(let i = 0; i < 21; i++) {
          otherLastAvg.point.push((3 * stats.avg_w[i]) + stats.avg_d[i])
          otherLastAvg.match.push(stats.avg_w[i] + stats.avg_d[i] + stats.avg_l[i])
          otherLastAvg.diff.push(stats.avg_t1[i] + ' - ' + stats.avg_t2[i])
          otherLastAvg.diff_av.push(!otherLastAvg.match[i] ? 0 : ((stats.avg_t1[i] - stats.avg_t2[i]) / otherLastAvg.match[i]).toFixed(2))
          otherLastAvg.total.push(!otherLastAvg.match[i] ? 0 : ((stats.avg_t1[i] + stats.avg_t2[i]) / otherLastAvg.match[i]).toFixed(2))
          otherLastAvg.t1.push(!otherLastAvg.match[i] ? 0 : (stats.avg_t1[i] / otherLastAvg.match[i]).toFixed(2))
          otherLastAvg.t2.push(!otherLastAvg.match[i] ? 0 : (stats.avg_t2[i] / otherLastAvg.match[i]).toFixed(2))
        }

        return [lastAvg, otherLastAvg]
      },

      getLast(flag) {
        if (!localStorage.token) {
          this.msg_last = "notlogin"
          if (flag == 1) {
            this.lastHomeLoaded = true
          }else {
            this.lastAwayLoaded = true
          }
        } else {
          // check if already got leagues
          if ((flag == 1 && !this.homeLeagueLoaded) || (flag == 2 && !this.awayLeagueLoaded)) {
            this.callLeague(flag)
          }

          let id, seasons, coach, home, away, perPage, pgNo, fh, prevcoach, current_coach
          if (flag == 1) {
            id = this.basic.local_id
            seasons = this.hQuerySeasons
            coach = this.hCurrentCoach ? this.basic.local_coach : 0
            prevcoach = this.hPrevCoach ? this.basic.local_coach : 0
            perPage = this.homePerPage
            home = this.hLocal ? 1 : 0
            away = this.hVisitor ? 1: 0
            pgNo = this.homePage
            fh = this.hLastFullHalf
            current_coach = this.local_coach_count == null ? this.basic.local_coach : 0
          }else {
            id = this.basic.visitor_id
            seasons = this.aQuerySeasons
            coach = this.aCurrentCoach ? this.basic.visitor_coach : 0
            prevcoach = this.aPrevCoach ? this.basic.visitor_coach : 0
            perPage = this.awayPerPage
            home = this.aLocal ? 1 : 0
            away = this.aVisitor ? 1: 0
            pgNo = this.awayPage
            fh = this.aLastFullHalf
            current_coach = this.visitor_coach_count == null ? this.basic.visitor_coach : 0
          }

          if (!seasons) seasons = 0

          axios
          .get(
            '/getLast/',{
              params: {
                id: id,
                seasons: seasons,
                pgNo: pgNo,
                perPage: perPage,
                home: home,
                away: away,
                coach: coach,
                prevcoach: prevcoach,
                fullHalf: fh,
                similar: 0,
                current_coach: current_coach
                },
              headers: { 'Authorization': 'Token ' + localStorage.token } })
          .then(response => {
            if (response.data.type == 0) {
              this.msg_last = "notsubscribe"
            }else if (response.data.type == 1) {
              this.msg_last = "expiredsubscribe"
            }else {
              response = response.data

              // get count of matches with the current coach
              if (response.c_coach != null) {
                if (flag == 1) {
                  this.local_coach_count = response.c_coach
                } else {
                  this.visitor_coach_count = response.c_coach
                }
              }

              if (flag == 1){
                [this.hLastAvg, this.hOtherLastAvg] = this.getLastStats(response.stats)
                this.hLastAvgLoaded = true

                if (response.stats.foundCount == 0) {
                  if (this.homePage == 0) {
                    this.hLastPage = 0
                  }else {
                    this.homePage -= 1
                    this.hLastPage = this.homePage
                  }
                }else {
                  if (this.homePerPage == this.$t('all') || response.stats.foundCount < this.homePerPage) {
                    this.hLastPage = this.homePage
                  }
                  this.Hlast[this.homePage] = response.stats.data
                  this.hLastData[this.homePage] = response.stats
                }
              }else {
                [this.aLastAvg, this.aOtherLastAvg] = this.getLastStats(response.stats)
                this.aLastAvgLoaded = true

                if (response.stats.foundCount == 0) {
                  if (this.awayPage == 0) {
                    this.aLastPage = 0
                  }else {
                    this.awayPage -= 1
                    this.aLastPage = this.awayPage
                  }
                }else {
                  if (this.awayPerPage == this.$t('all') || response.stats.foundCount < this.awayPerPage) {
                    this.aLastPage = this.awayPage
                  }
                  this.Alast[this.awayPage] = response.stats.data
                  this.aLastData[this.awayPage] = response.stats
                }
              }
              this.authorization = true
            }
            if (flag == 1) {
              this.lastHomeLoaded = true
            }else {
              this.lastAwayLoaded = true
            }
          })
          .catch(error => {
            if (error.response.status == 401) {
              localStorage.removeItem('token')
              localStorage.removeItem('uid')
              location.reload()
            }
          })
        }
      },

      getSimilar(flag) {
        if (!localStorage.token) {
          this.msg_similar = "notlogin"
          if (flag == 1) {
            this.similarHomeLoaded = true
          }else {
            this.similarAwayLoaded = true
          }
        } else {
          if (!this.odds.FT){
            // this.msg_similar = "Sorry, no 1X2 odds."
            if (flag == 1) {
              this.similarHomeLoaded = true
            }else {
              this.similarAwayLoaded = true
            }
          } else {
            // check if already got leagues
            if ((flag == 1 && !this.homeLeagueLoaded) || (flag == 2 && !this.awayLeagueLoaded)) {
              this.callLeague(flag)
            }

            let id, seasons, coach, home, away, perPage, pgNo, fh, prevcoach, current_coach
            if (flag == 1) {
              id = this.basic.local_id
              seasons = this.hSQuerySeasons
              coach = this.hSCurrentCoach ? this.basic.local_coach : 0
              prevcoach = this.hSPrevCoach ? this.basic.local_coach : 0
              perPage = this.hSPerPage
              home = this.hSLocal ? 1 : 0
              away = this.hSVisitor ? 1: 0
              pgNo = this.hSPage
              fh = this.hSimilarFullHalf
              current_coach = this.ls_coach_count == null ? this.basic.local_coach : 0
            }else {
              id = this.basic.visitor_id
              seasons = this.aSQuerySeasons
              coach = this.aSCurrentCoach ? this.basic.visitor_coach : 0
              prevcoach = this.aSPrevCoach ? this.basic.visitor_coach : 0
              perPage = this.aSPerPage
              home = this.aSLocal ? 1 : 0
              away = this.aSVisitor ? 1: 0
              pgNo = this.aSPage
              fh = this.aSimilarFullHalf
              current_coach = this.vs_coach_count == null ? this.basic.visitor_coach : 0
            }

            if (!seasons) seasons = 0
            
            let team_odds
            if (flag == 1){
              team_odds = this.odds.FT[0].value
            }else {
              team_odds = this.odds.FT[2].value
            }

            axios
            .get(
              '/getLast/',{
                params: {
                  id: id,
                  seasons: seasons,
                  pgNo: pgNo,
                  perPage: perPage,
                  home: home,
                  away: away,
                  coach: coach,
                  prevcoach: prevcoach,
                  fullHalf: fh,
                  similar: team_odds,
                  current_coach: current_coach
                  },
                headers: { 'Authorization': 'Token ' + localStorage.token } })
            .then(response => {
              if (response.data.type == 0) {
                this.msg_similar = "notsubscribe"
              }else if (response.data.type == 1) {
                this.msg_similar = "expiredsubscribe"
              }else {
                response = response.data

                // get count of matches with the current coach
                if (response.c_coach != null) {
                  if (flag == 1) {
                    this.ls_coach_count = response.c_coach
                  } else {
                    this.vs_coach_count = response.c_coach
                  }
                }
               
                if (flag == 1){
                  [this.hSimilarAvg, this.hOtherSimilarAvg] = this.getLastStats(response.stats[0])
                  // this.hSimilarAvg = lastAvg
                  // this.hOtherSimilarAvg = otherLastAvg
                  this.hSimilarAvgLoaded = true
                  this.hSLastPage = response.stats.length - 1

                  response.stats.forEach(element => {
                    this.hSimilar.push(element.data)
                    // this.Hlast[this.homePage] = response.stats.data
                    this.hSimilarStats.push(element)
                  });
                }else {
                  [this.aSimilarAvg, this.aOtherSimilarAvg] = this.getLastStats(response.stats[0])
                  // this.aSimilarAvg = lastAvg
                  // this.aOtherSimilarAvg = otherLastAvg
                  this.aSimilarAvgLoaded = true
                  this.aSLastPage = response.stats.length - 1

                  response.stats.forEach(element => {
                    this.aSimilar.push(element.data)
                    this.aSimilarStats.push(element)
                  });
                }
                this.authorization = true
              }
              if (flag == 1) {
                this.similarHomeLoaded = true
              }else {
                this.similarAwayLoaded = true
              }
            })
            .catch(error => {
              if (error.response.status == 401) {
                localStorage.removeItem('token')
                localStorage.removeItem('uid')
                location.reload()
              }
            })
          }
          
          // this.authorization = true
        }
      },

      getRefereeLast() {
        if (!localStorage.token) {
          this.msg_last = "notlogin"
          this.rLastLoaded = true
        } else {
          axios
          .get('/referee_last/' + this.basic.referee_id,
            { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
          .then(response => {
            if (response.data.type == 0) {
              this.msg_last = "notsubscribe"
            }else if (response.data.type == 1) {
              this.msg_last = "expiredsubscribe"
            }else {
              this.originRLast = response.data.data
              this.get_formatted_referee()
              
              // this.authorization = true

              this.sliceRefereeLast(1)
              this.sliceRefereeLast(2)
            }
            this.authorization = true
          })
          .catch(error => {
            if (error.response.status == 401) {
              localStorage.removeItem('token')
              localStorage.removeItem('uid')
              location.reload()
            }
          })
        }
      },

      get_formatted_referee() {
        // change format of array to show cards

        let seasonIds = [], leagueIds = []
        let index = 0
        this.originRLast.forEach((game) => {
            let item = {
                index: index,
                match_id: game.match_id,
                date: game.start_dt,
                local_id: game.local_id,
                local_name: game.local_name,
                visitor_id: game.visitor_id,
                visitor_name: game.visitor_name,
                league_id: game.league_id,
                league_name: game.league_name,
                season_id: game.season_id,
                local_coach: game.local_coach_id,
                visitor_coach: game.visitor_coach_id,
                local_cards: [0, 0, 0],
                visitor_cards: [0, 0, 0],
                fouls: [0, 0],
                penalties: [0, 0],
                referee: '',
            }

            index++

            let cards_exists = 0

            if (game.cards) {
              game.cards.data.forEach((evt) => {
                cards_exists = 1
                if (evt.minute <= 90) {
                  if (evt.team_id == game.local_id) {
                    if (evt.type == 'yellowcard') item.local_cards[0]++
                    else if (evt.type == 'redcard') item.local_cards[1]++
                    else if (evt.type == 'yellowred') item.local_cards[2]++
                  }else {
                    if (evt.type == 'yellowcard') item.visitor_cards[0]++
                    else if (evt.type == 'redcard') item.visitor_cards[1]++
                    else if (evt.type == 'yellowred') item.visitor_cards[2]++
                  }
                }
              })
            }

            // check if wrong cards data
            if (!cards_exists) {
              if (game.stats) {
                game.stats.data.forEach((t) => {
                  if (t.team_id == game.local_id){
                    if (t.yellowcards) item.local_cards[0] += t.yellowcards
                    if (t.redcards) item.local_cards[1] += t.yellowcards
                    if (t.yellowredcards) item.local_cards[2] += t.yellowcards
                  }else {
                    if (t.yellowcards) item.visitor_cards[0] += t.yellowcards
                    if (t.redcards) item.visitor_cards[1] += t.yellowcards
                    if (t.yellowredcards) item.visitor_cards[2] += t.yellowcards
                  }
                })
              }

              // if cards empty [] and stats cards also 0, set cards wrong data -1 for showing '?'
              if (item.local_cards[0] == 0 && item.local_cards[1] == 0 && item.local_cards[2] == 0
               && item.visitor_cards[0] == 0 && item.visitor_cards[1] == 0 && item.visitor_cards[2] == 0) {
                 item.local_cards[0] = -1
               }
            }

            if (game.referee_id != null) {
              item.referee = game.referee_common_name
            }

            // get fouls and penalties
            if (game.stats && game.stats.data.length) {
              item.fouls = [game.stats.data[0].fouls, game.stats.data[1].fouls]
              item.penalties = [game.stats.data[0].penalties, game.stats.data[1].penalties]
            } else {
              item.penalties = [0, 0]
              item.fouls = [0, 0]
            }

            // update h2h league-season info
            if (!seasonIds.includes(game.season_id)) {
              seasonIds.push(game.season_id)

              if (!this.rSeasons.includes(game.season_name))
                this.rSeasons.push(game.season_name)

              if (!leagueIds.includes(game.league_id)) {
                leagueIds.push(game.league_id)

                this.rLeagues.push({league_id: game.league_id, league_name: game.league_name})
              }

              this.rTotalSeasons.push({
                season_id: game.season_id,
                season_name: game.season_name,
                league_id: game.league_id,
                league_name: game.league_name})
            }
            
            this.originRefereeLast.push(item)

            // store data of Home team & Away team
            if (item.local_id == this.basic.local_id || item.visitor_id == this.basic.local_id) this.originRHomeLast.push(item)
            if (item.local_id == this.basic.visitor_id || item.visitor_id == this.basic.visitor_id) this.originRAwayLast.push(item)
        })

        this.filteredRefereeLast = this.originRefereeLast
        this.filteredRHomeLast = this.originRHomeLast
        this.filteredRAwayLast = this.originRAwayLast
        
        this.sliceRefereeLast(0)
      },

      getRefereeStats(key) {
        let m_c = 0, q_yc = 0, q_rc = 0, pen = 0, fouls = 0

        switch (key) {
          case 0:
            this.rLast[this.rPage].forEach(game => {
              if (game.local_cards[0] != -1) {
                m_c += 1
                q_yc += (game.local_cards[0] + game.visitor_cards[0])
                q_rc += (game.local_cards[1] + game.local_cards[2] + game.visitor_cards[1] + game.visitor_cards[2])
                
                if (game.penalties[0] || game.penalties[1]) pen += 1

                if (game.fouls[0]) fouls += game.fouls[0]
                if (game.fouls[1]) fouls += game.fouls[1]
              }
            });

            this.rAvgQ_yc = q_yc
            this.rAvgT_yc = !m_c ? 0 : (q_yc / m_c).toFixed(2)
            this.rAvgQ_rc = q_rc
            this.rAvgT_rc = !m_c ? 0 : (q_rc / m_c).toFixed(2)
            this.rAvgPen = !m_c ? 0 : (pen / m_c * 100).toFixed(1)
            this.rAvgFouls = !m_c ? 0 : (fouls / m_c).toFixed(2)

            this.rAvgM_c = m_c
            break
          case 1:
            this.rHLast[this.rHPage].forEach(game => {
              if (game.local_cards[0] != -1) {
                m_c += 1
                q_yc += (game.local_cards[0] + game.visitor_cards[0])
                q_rc += (game.local_cards[1] + game.local_cards[2] + game.visitor_cards[1] + game.visitor_cards[2])
                
                if (game.penalties[0] || game.penalties[1]) pen += 1

                if (game.fouls[0]) fouls += game.fouls[0]
                if (game.fouls[1]) fouls += game.fouls[1]
              }
            });

            this.rHAvgQ_yc = q_yc
            this.rHAvgT_yc = !m_c ? 0 : (q_yc / m_c).toFixed(2)
            this.rHAvgQ_rc = q_rc
            this.rHAvgT_rc = !m_c ? 0 : (q_rc / m_c).toFixed(2)
            this.rHAvgPen = !m_c ? 0 : (pen / m_c * 100).toFixed(1)
            this.rHAvgFouls = !m_c ? 0 : (fouls / m_c).toFixed(2)

            this.rHAvgM_c = m_c
            break
          case 2:
            this.rALast[this.rAPage].forEach(game => {
              if (game.local_cards[0] != -1) {
                m_c += 1
                q_yc += (game.local_cards[0] + game.visitor_cards[0])
                q_rc += (game.local_cards[1] + game.local_cards[2] + game.visitor_cards[1] + game.visitor_cards[2])
                
                if (game.penalties[0] || game.penalties[1]) pen += 1

                if (game.fouls[0]) fouls += game.fouls[0]
                if (game.fouls[1]) fouls += game.fouls[1]
              }
            });

            this.rAAvgQ_yc = q_yc
            this.rAAvgT_yc = !m_c ? 0 : (q_yc / m_c).toFixed(2)
            this.rAAvgQ_rc = q_rc
            this.rAAvgT_rc = !m_c ? 0 : (q_rc / m_c).toFixed(2)
            this.rAAvgPen = !m_c ? 0 : (pen / m_c * 100).toFixed(1)
            this.rAAvgFouls = !m_c ? 0 : (fouls / m_c).toFixed(2)
            
            this.rAAvgM_c = m_c
            break
        }
      },

      sliceRefereeLast(key) {
        if (key == 0) {
          // slice filteredRefereeLast array by rPerPage, get slice and calculate avg stats from it.
          this.rLast = []
          
          this.rLastPage = -1
          if (this.filteredRefereeLast.length) {

            let perpage = 0
            if (this.rPerPage == this.$t('all')) perpage = this.filteredRefereeLast.length
            else perpage = Number(this.rPerPage)

            var i, j=this.filteredRefereeLast.length, temporary
            for (i=0; i<j; i+=perpage) {
                temporary = this.filteredRefereeLast.slice(i,i+perpage)
                this.rLast.push(temporary)
                this.rLastPage += 1
            }

            this.getRefereeStats(0)
          } else {
            this.rLastPage = 0
            this.rAvgM_c = 0
          }

          this.rLastLoaded = true
        } else if (key == 1) {
          // slice filteredRHomeLast array by rHPerPage, get slice and calculate avg stats from it.    
          this.rHLast = []
          
          this.rHLastPage = -1
          if (this.filteredRHomeLast.length) {

            let perpage = 0
            if (this.rHPerPage == this.$t('all')) perpage = this.filteredRHomeLast.length
            else perpage = Number(this.rHPerPage)

            var i, j=this.filteredRHomeLast.length, temporary
            for (i=0; i<j; i+=perpage) {
                temporary = this.filteredRHomeLast.slice(i,i+perpage)
                this.rHLast.push(temporary)
                this.rHLastPage += 1
            }

            this.getRefereeStats(1)
          } else {
            this.rHLastPage = 0  
            this.rHAvgM_c = 0
          }

          this.rHLastLoaded = true
        } else if (key == 2) {
          // slice filteredRAwayLast array by rAPerPage, get slice and calculate avg stats from it.
          this.rALast = []
          
          this.rALastPage = -1
          if (this.filteredRAwayLast.length) {

            let perpage = 0
            if (this.rAPerPage == this.$t('all')) perpage = this.filteredRAwayLast.length
            else perpage = Number(this.rAPerPage)

            var i, j=this.filteredRAwayLast.length, temporary
            for (i=0; i<j; i+=perpage) {
                temporary = this.filteredRAwayLast.slice(i,i+perpage)
                this.rALast.push(temporary)
                this.rALastPage += 1
            }

            this.getRefereeStats(2)
          } else {
            this.rALastPage = 0
            this.rAAvgM_c = 0
          }          
          
          this.rALastLoaded = true
        }
      },

      changeRFilter(key) {
        if (key == 0) {
          // filter origin
          this.rPage = 0
          this.filteredRefereeLast = this.queryRFilter(0)
          this.sliceRefereeLast(0)
        } else if (key == 1) {
          this.rHPage = 0
          this.filteredRHomeLast = this.queryRFilter(1)
          this.sliceRefereeLast(1)
        } else if (key == 2) {
          this.rAPage = 0
          this.filteredRAwayLast = this.queryRFilter(2)
          this.sliceRefereeLast(2)
        }
      },

      // filter originCGC by league, season, coach or home/away
      queryRFilter(key) {
        let temp = []
        
        // filter by seasons
        let querys = []

        if (key == 0) {
          this.rTotalSeasons.forEach((item) => {
            let leagues = this.rChosenLeagues.filter(league => league == item.league_id )
              let seasons = this.rChosenSeasons.filter(season => season == item.season_name )
              if((this.rChosenLeagues.length == 0 || leagues.length > 0) && (this.rChosenSeasons.length == 0 || seasons.length > 0))
                querys.push(item.season_id)
            })
          if(querys.length > 0) {
            temp = this.originRefereeLast.filter(game => querys.includes(game.season_id))
          }
        } else if (key == 1) {
          this.rTotalSeasons.forEach((item) => {
            let leagues = this.rHChosenLeagues.filter(league => league == item.league_id )
              let seasons = this.rHChosenSeasons.filter(season => season == item.season_name )
              if((this.rHChosenLeagues.length == 0 || leagues.length > 0) && (this.rHChosenSeasons.length == 0 || seasons.length > 0))
                querys.push(item.season_id)
            })
          if(querys.length > 0) {
            temp = this.originRHomeLast.filter(game => querys.includes(game.season_id))
          }
        } else if (key == 2) {
          this.rTotalSeasons.forEach((item) => {
            let leagues = this.rAChosenLeagues.filter(league => league == item.league_id )
              let seasons = this.rAChosenSeasons.filter(season => season == item.season_name )
              if((this.rAChosenLeagues.length == 0 || leagues.length > 0) && (this.rAChosenSeasons.length == 0 || seasons.length > 0))
                querys.push(item.season_id)
            })
          if(querys.length > 0) {
            temp = this.originRAwayLast.filter(game => querys.includes(game.season_id))
          }
        }

        return temp
      },

      // flag: home? away?
      callLeague(flag) {
        let id
        if (flag == 1) {
          id = this.basic.local_id
        }else {
          id = this.basic.visitor_id
        }

        axios
          .get('/v3/team/season/' + id,
            { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
          .then(response => {
            if (!response.data.type) {
              let data = this.modifySeason(response.data.season)
              if (flag == 1){
                this.HLeagues = data.leagues
                this.HSeasons = data.seasons
                this.HTotalSeasons = data.totalSeason
                this.homeLeagueLoaded = true
                this.showHomeLeague = true
              }else {
                this.ALeagues = data.leagues
                this.ASeasons = data.seasons
                this.ATotalSeasons = data.totalSeason
                this.awayLeagueLoaded = true
                this.showAwayLeague = true
              }
            }
          })
          .catch(error => {
            if (error.response.status == 401) {
              localStorage.removeItem('token')
              localStorage.removeItem('uid')
              location.reload()
            }
          })
      },

      /*
        modify array of seasons to a specific format
      */
      modifySeason(total) {
        let totalSeason = [], seasons = [], leagues = []
        // let latest = total[0]
        
        total.forEach(el => {
          totalSeason.push({
            season_id: el.id,
            season_name: el.name,
            league_id: el.league_id,
            league_name: el.league.name
          })
          
          seasons.push(el.name)

          let lg = {
            league_id: el.league_id,
            league_name: el.league.name,
          }

          if (!leagues.includes(lg)) {
            leagues.push(lg)
          }
        })

        let season_without_duplicate = seasons.filter((item, index, array) => array.indexOf(item) === index).sort().reverse()

        return {'leagues': leagues, 'seasons': season_without_duplicate, 'totalSeason': totalSeason}
      },

      corner_flag(f) {
        this.flags.corner = f
        if ((f == 1) && !this.lastHomeLoaded && !this.lastAwayLoaded) {
          this.getLast(1)
          this.getLast(2)
        }else if ((f == 2) && !this.similarHomeLoaded && !this.similarAwayLoaded) {
          this.getSimilar(1)
          this.getSimilar(2)
        }
      },

      goal_flag(f) {
        this.flags.goal = f
        if ((f == 1) && !this.lastHomeLoaded && !this.lastAwayLoaded) {
          this.getLast(1)
          this.getLast(2)
        }else if ((f == 2) && !this.similarHomeLoaded && !this.similarAwayLoaded) {
          this.getSimilar(1)
          this.getSimilar(2)
        }
      },

      card_flag(f) {
        this.flags.card = f
        if ((f == 1) && !this.lastHomeLoaded && !this.lastAwayLoaded) {
          this.getLast(1)
          this.getLast(2)
        }else if ((f == 2) && !this.similarHomeLoaded && !this.similarAwayLoaded) {
          this.getSimilar(1)
          this.getSimilar(2)
        }
      },

      referee_flag(f) {
        this.flags.referee = f
      },

      other_flag(f) {
        this.flags.other = f
        if ((f == 1) && !this.lastHomeLoaded && !this.lastAwayLoaded) {
          this.getLast(1)
          this.getLast(2)
        }else if ((f == 2) && !this.similarHomeLoaded && !this.similarAwayLoaded) {
          this.getSimilar(1)
          this.getSimilar(2)
        }
      },

      cornerModal(id, home_id, home, away, f) {
        if (f == 0) {
          if (this.h2h[id].corners && this.h2h[id].corners.data.length != 0) {
            this.subCorner.dt = moment(this.h2h[id].start_dt).format('DD/MM/YY')
            this.subCorner.home = home
            this.subCorner.away = away
            this.subCorner.data = []
            this.h2h[id].corners.data.forEach((evt) => {
              let item = {
                  comment: evt.comment,
                  h_m: '',
                  a_m: ''
                }
              if (evt.team_id == home_id) item.h_m = (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'"
              else item.a_m = (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'"

              this.subCorner.data.push(item)
            })
            this.subCornerDialog = true
          }
        }else if (f == 1) {
          if (this.Hlast[this.homePage][id].corners && this.Hlast[this.homePage][id].corners.data.length != 0) {
            this.subCorner.dt = moment(this.Hlast[this.homePage][id].date).format('DD/MM/YY')
            this.subCorner.home = home
            this.subCorner.away = away
            this.subCorner.data = []
            this.Hlast[this.homePage][id].corners.data.forEach((evt) => {
              let item = {
                  comment: evt.comment,
                  h_m: '',
                  a_m: ''
                }
              if (evt.team_id == home_id) item.h_m = (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'"
              else item.a_m = (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'"
              this.subCorner.data.push(item)
            })
            this.subCornerDialog = true
          }
        }else if (f == 2) {
          if (this.Alast[this.awayPage][id].corners && this.Alast[this.awayPage][id].corners.data.length != 0) {
            this.subCorner.dt = moment(this.Alast[this.awayPage][id].date).format('DD/MM/YY')
            this.subCorner.home = home
            this.subCorner.away = away
            this.subCorner.data = []
            this.Alast[this.awayPage][id].corners.data.forEach((evt) => {
              let item = {
                  comment: evt.comment,
                  h_m: '',
                  a_m: ''
                }
              if (evt.team_id == home_id) item.h_m = (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'"
              else item.a_m = (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'"
              this.subCorner.data.push(item)
            })
            this.subCornerDialog = true
          }
        }else if (f == 3) {
          if (this.hSimilar[this.hSPage][id].corners && this.hSimilar[this.hSPage][id].corners.data.length != 0) {
            this.subCorner.dt = moment(this.hSimilar[this.hSPage][id].date).format('DD/MM/YY')
            this.subCorner.home = home
            this.subCorner.away = away
            this.subCorner.data = []
            this.hSimilar[this.hSPage][id].corners.data.forEach((evt) => {
              let item = {
                  comment: evt.comment,
                  h_m: '',
                  a_m: ''
                }
              if (evt.team_id == home_id) item.h_m = (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'"
              else item.a_m = (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'"
              this.subCorner.data.push(item)
            })
            this.subCornerDialog = true
          }
        }else if (f == 4) {
          if (this.aSimilar[this.aSPage][id].corners && this.aSimilar[this.aSPage][id].corners.data.length != 0) {
            this.subCorner.dt = moment(this.aSimilar[this.aSPage][id].date).format('DD/MM/YY')
            this.subCorner.home = home
            this.subCorner.away = away
            this.subCorner.data = []
            this.aSimilar[this.aSPage][id].corners.data.forEach((evt) => {
              let item = {
                  comment: evt.comment,
                  h_m: '',
                  a_m: ''
                }
              if (evt.team_id == home_id) item.h_m = (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'"
              else item.a_m = (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'"
              this.subCorner.data.push(item)
            })
            this.subCornerDialog = true
          }
        }
      },

      cardModal(id, home_id, home, away, f) {
        if (f == 0) {
          if (this.h2h[id].cards && this.h2h[id].cards.data.length != 0) {
            this.subCard.dt = moment(this.h2h[id].start_dt).format('DD/MM/YY')
            this.subCard.home = home
            this.subCard.away = away
            this.subCard.data = []
            this.h2h[id].cards.data.forEach((evt) => {
              let item = {
                  player: evt.player_name,
                  player_id: evt.player_id,
                  minute: (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'",
                  h_c: '',
                  a_c: ''
                }
              if (evt.team_id == home_id) item.h_c = evt.type
              else item.a_c = evt.type

              this.subCard.data.push(item)
            })
            this.subCardDialog = true
          }
        }else if (f == 1) {
          if (this.Hlast[this.homePage][id].cards && this.Hlast[this.homePage][id].cards.length != 0) {
            this.subCard.dt = moment(this.Hlast[this.homePage][id].date).format('DD/MM/YY')
            this.subCard.home = home
            this.subCard.away = away
            this.subCard.data = []
            this.Hlast[this.homePage][id].cards.forEach((evt) => {
              let item = {
                  player: evt.player_name,
                  player_id: evt.player_id,
                  minute: (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'",
                  h_c: '',
                  a_c: ''
                }
              if (evt.team == home_id) item.h_c = evt.type
              else item.a_c = evt.type

              this.subCard.data.push(item)
            })
            this.subCardDialog = true
          }
        }else if (f == 2) {
          if (this.Alast[this.awayPage][id].cards && this.Alast[this.awayPage][id].cards.length != 0) {
            this.subCard.dt = moment(this.Alast[this.awayPage][id].date).format('DD/MM/YY')
            this.subCard.home = home
            this.subCard.away = away
            this.subCard.data = []
            this.Alast[this.awayPage][id].cards.forEach((evt) => {
              let item = {
                  player: evt.player_name,
                  player_id: evt.player_id,
                  minute: (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'",
                  h_c: '',
                  a_c: ''
                }
              if (evt.team == home_id) item.h_c = evt.type
              else item.a_c = evt.type

              this.subCard.data.push(item)
            })
            this.subCardDialog = true
          }
        }else if (f == 3) {
          if (this.hSimilar[this.hSPage][id].cards && this.hSimilar[this.hSPage][id].cards.length != 0) {
            this.subCard.dt = moment(this.hSimilar[this.hSPage][id].date).format('DD/MM/YY')
            this.subCard.home = home
            this.subCard.away = away
            this.subCard.data = []
            this.hSimilar[this.hSPage][id].cards.forEach((evt) => {
              let item = {
                  player: evt.player_name,
                  player_id: evt.player_id,
                  minute: (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'",
                  h_c: '',
                  a_c: ''
                }
              if (evt.team == home_id) item.h_c = evt.type
              else item.a_c = evt.type

              this.subCard.data.push(item)
            })
            this.subCardDialog = true
          }
        }else if (f == 4) {
          if (this.aSimilar[this.aSPage][id].cards && this.aSimilar[this.aSPage][id].cards.length != 0) {
            this.subCard.dt = moment(this.aSimilar[this.aSPage][id].date).format('DD/MM/YY')
            this.subCard.home = home
            this.subCard.away = away
            this.subCard.data = []
            this.aSimilar[this.aSPage][id].cards.forEach((evt) => {
              let item = {
                  player: evt.player_name,
                  player_id: evt.player_id,
                  minute: (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'",
                  h_c: '',
                  a_c: ''
                }
              if (evt.team == home_id) item.h_c = evt.type
              else item.a_c = evt.type

              this.subCard.data.push(item)
            })
            this.subCardDialog = true
          }
        }
      },

      goalModal(id, home_id, home, away, f) {
        if (f == 0) {
          if (this.h2h[id].goals && this.h2h[id].goals.data.length != 0) {
            this.subGoal.dt = moment(this.h2h[id].start_dt).format('DD/MM/YY')
            this.subGoal.home = home
            this.subGoal.away = away
            this.subGoal.data = []
            this.h2h[id].goals.data.forEach((evt) => {
              let item = {
                  player: evt.player_name,
                  player_id: evt.player_id,
                  minute: (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'",
                  h_g: '',
                  a_g: ''
                }
              if (evt.team_id == home_id) item.h_g = evt.type
              else item.a_g = evt.type

              this.subGoal.data.push(item)
            })
            this.subGoalDialog = true
          }
        }else if (f == 1) {
          if (this.Hlast[this.homePage][id].goals && this.Hlast[this.homePage][id].goals.length != 0) {
            this.subGoal.dt = moment(this.Hlast[this.homePage][id].date).format('DD/MM/YY')
            this.subGoal.home = home
            this.subGoal.away = away
            this.subGoal.data = []
            this.Hlast[this.homePage][id].goals.forEach((evt) => {
              let item = {
                  player: evt.player_name,
                  player_id: evt.player_id,
                  minute: (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'",
                  h_g: '',
                  a_g: ''
                }
              if (evt.team_id == home_id) item.h_g = evt.type
              else item.a_g = evt.type

              this.subGoal.data.push(item)
            })
            this.subGoalDialog = true
          }
        }else if (f == 2) {
          if (this.Alast[this.awayPage][id].goals && this.Alast[this.awayPage][id].goals.length != 0) {
            this.subGoal.dt = moment(this.Alast[this.awayPage][id].date).format('DD/MM/YY')
            this.subGoal.home = home
            this.subGoal.away = away
            this.subGoal.data = []
            this.Alast[this.awayPage][id].goals.forEach((evt) => {
              let item = {
                  player: evt.player_name,
                  player_id: evt.player_id,
                  minute: (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'",
                  h_g: '',
                  a_g: ''
                }
              if (evt.team_id == home_id) item.h_g = evt.type
              else item.a_g = evt.type

              this.subGoal.data.push(item)
            })
            this.subGoalDialog = true
          }
        }else if (f == 3) {
          if (this.hSimilar[this.hSPage][id].goals && this.hSimilar[this.hSPage][id].goals.length != 0) {
            this.subGoal.dt = moment(this.hSimilar[this.hSPage][id].date).format('DD/MM/YY')
            this.subGoal.home = home
            this.subGoal.away = away
            this.subGoal.data = []
            this.hSimilar[this.hSPage][id].goals.forEach((evt) => {
              let item = {
                  player: evt.player_name,
                  player_id: evt.player_id,
                  minute: (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'",
                  h_g: '',
                  a_g: ''
                }
              if (evt.team_id == home_id) item.h_g = evt.type
              else item.a_g = evt.type

              this.subGoal.data.push(item)
            })
            this.subGoalDialog = true
          }
        }else if (f == 4) {
          if (this.aSimilar[this.aSPage][id].goals && this.aSimilar[this.aSPage][id].goals.length != 0) {
            this.subGoal.dt = moment(this.aSimilar[this.aSPage][id].date).format('DD/MM/YY')
            this.subGoal.home = home
            this.subGoal.away = away
            this.subGoal.data = []
            this.aSimilar[this.aSPage][id].goals.forEach((evt) => {
              let item = {
                  player: evt.player_name,
                  player_id: evt.player_id,
                  minute: (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'",
                  h_g: '',
                  a_g: ''
                }
              if (evt.team_id == home_id) item.h_g = evt.type
              else item.a_g = evt.type

              this.subGoal.data.push(item)
            })
            this.subGoalDialog = true
          }
        }
      },

      refereeModal(id, home_id, home, away) {
        if (this.originRLast[id].cards && this.originRLast[id].cards.data.length != 0) {
          this.subCard.dt = moment(this.originRLast[id].start_dt).format('DD/MM/YY')
          this.subCard.home = home
          this.subCard.away = away
          this.subCard.data = []
          this.originRLast[id].cards.data.forEach((evt) => {
            let item = {
                player: evt.player_name,
                player_id: evt.player_id,
                minute: (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'",
                h_c: '',
                a_c: ''
              }
            if (evt.team_id == home_id) item.h_c = evt.type
            else item.a_c = evt.type

            this.subCard.data.push(item)
          })
          this.subRCardDialog = true
        }
      },

      modal4RefereeStats(rid) {
        this.chosenReferee = this.refereeStats.get(rid)
        this.refereeDialog = true

        if(!this.chosenReferee) {
          axios
            .get('/v3/referee/stats/' + rid,
              { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
            .then(response => {
              if (response.data.type == 0 || response.data.type == 1) {
                this.$router.replace('/message/subscribe/' + (response.data.type ? 'expired' : 'not-paid'))
              }else {
                response.data.statistics.forEach(element => {
                  let detail = {
                    redcards: null,
                    yellowcards: null,
                    yellowredcards: null,
                    penalties: null,
                    fouls: null,
                    var_moments: null,
                    matches: 0,
                  }

                  element.details.forEach(val => {
                    switch (val.type_id) {
                      case 47:
                        detail.penalties = val.value.all
                        break
                      case 56:
                        detail.fouls = val.value
                        break
                      case 188:
                        detail.matches = val.value.count
                        break
                      case 83:
                        detail.redcards = val.value.all
                        break
                      case 84:
                        detail.yellowcards = val.value.all
                        break
                      case 85:
                        detail.yellowredcards = val.value.all
                        break
                      case 314:
                        detail.var_moments = val.value
                        break
                    }
                  })

                  element.details = detail
                  element.season_name = element.season.name
                })

                response.data.statistics = _.orderBy(response.data.statistics, ['season_name'], ['desc'])
                this.refereeStats.set(rid, response.data)
                this.chosenReferee = response.data
              }
            })
            .catch(error => {
              if (error.response.status == 401) {
                localStorage.removeItem('token')
                localStorage.removeItem('uid')
                location.reload()
              } else {
                this.refereeDialog = false
              }
            })
        }
      },

      homePrev() {
        this.homePage++
        if (!this.Hlast[this.homePage]) {
          this.lastHomeLoaded = false
          this.getLast(1)
        } else {
          [this.hLastAvg, this.hOtherLastAvg] = this.getLastStats(this.hLastData[this.homePage])
        }
      },

      homeNext() {
        this.homePage--
        [this.hLastAvg, this.hOtherLastAvg] = this.getLastStats(this.hLastData[this.homePage])
      },

      awayPrev() {
        this.awayPage++
        if (!this.Alast[this.awayPage]) {
          this.lastAwayLoaded = false
          this.getLast(2)
        } else {
          [this.aLastAvg, this.aOtherLastAvg] = this.getLastStats(this.aLastData[this.awayPage])
        }
      },

      awayNext() {
        this.awayPage--
        [this.aLastAvg, this.aOtherLastAvg] = this.getLastStats(this.aLastData[this.awayPage])
      },

      hSPrev() {
        this.hSPage++
        if (!this.hSimilar[this.hSPage]) {
          this.similarHomeLoaded = false
          this.getSimilar(1)
        } else {
          [this.hSimilarAvg, this.hOtherSimilarAvg] = this.getLastStats(this.hSimilarStats[this.hSPage])
        }
      },

      hSNext() {
        this.hSPage--
        [this.hSimilarAvg, this.hOtherSimilarAvg] = this.getLastStats(this.hSimilarStats[this.hSPage])
      },

      aSPrev() {
        this.aSPage++
        if (!this.aSimilar[this.aSPage]) {
          this.similarAwayLoaded = false
          this.getSimilar(2)
        } else {
          [this.aSimilarAvg, this.aOtherSimilarAvg] = this.getLastStats(this.aSimilarStats[this.aSPage])
        }
      },

      aSNext() {
        this.aSPage--
        [this.aSimilarAvg, this.aOtherSimilarAvg] = this.getLastStats(this.aSimilarStats[this.aSPage])
      },

      h2hPrev() {
        this.h2hPage++
        this.getH2HStats()
      },

      h2hNext() {
        this.h2hPage--
        this.getH2HStats()
      },

      rPrev(key) {
        if (key == 0) {
          this.rPage++
        } else if (key == 1) {
          this.rHPage++
        } else if (key == 2) {
          this.rAPage++
        }

        this.getRefereeStats(key)
      },

      rNext(key) {
        if (key == 0) {
          this.rPage--
        } else if (key == 1) {
          this.rHPage--
        } else if (key == 2) {
          this.rAPage--
        }

        this.getRefereeStats(key)
      },

      homePerPageChange(evt) {
        this.homePerPage = this.hPPageByUser
      },

      awayPerPageChange(evt) {
        this.awayPerPage = this.aPPageByUser
      },

      hSPerPageChange(evt) {
        this.hSPerPage = this.hSPPageByUser
      },

      aSPerPageChange(evt) {
        this.aSPerPage = this.aSPPageByUser
      },

      h2hPerPageChange(evt) {
        this.h2hPerPage = this.h2hPPageByUser
        // this.sliceH2h()
      },

      rPerPageChange(key) {
        if (key == 0) {
          this.rPerPage = this.rPPageByUser
        } else if (key == 1) {
          this.rHPerPage = this.rHPPageByUser
        } else if (key == 2) {
          this.rAPerPage = this.rAPPageByUser
        }
      },

      modifyOdds() {
        let max = 0, maxKey = ''

        // Over/Under
        if (this.odds.OU) {
          let over = {}
          let under = {}

          this.odds.OU.forEach((item) => {
            if (item.value < 2 && item.value > max) {
              max = item.value
              maxKey = item.total
            }

            if (item.label.toLowerCase() == "over") {
              if (!over[item.total]) {
                over[item.total] = [item.value, item.american]
              } else if (over[item.total][0] < item.value) {
                over[item.total] = [item.value, item.american]
              }
            } else if (item.label.toLowerCase() == "under") {
              if (!under[item.total]) {
                under[item.total] = [item.value, item.american]
              } else if (under[item.total][0] < item.value) {
                under[item.total] = [item.value, item.american]
              }
            }
          })
          
          this.moreOdds['OU'] = [over, under]
          this.moreOdds['maxOU'] = maxKey
        }     

        // Asian Handicap
        if (this.odds.AH) {
          let lAH = {}
          let vAH = {}
          max = 0
          maxKey = []

          this.odds.AH.forEach((item, ind) => {
            if (item.label == "1" || item.label.toLowerCase() == "home") {
              if (!lAH[item.handicap]) {
                lAH[item.handicap] = [item.value, item.american]
                
                if (!ind) {
                  max = item.value
                  maxKey = [0, item.handicap]
                }
              } else if (lAH[item.handicap][0] < item.value) {
                lAH[item.handicap] = [item.value, item.american]
              }
              
              if ((item.value < 2 && max > 2) || (item.value < 2 && item.value > max)) {
                max = item.value
                maxKey = [0, item.handicap]
              }
            } else if (item.label == "2" || item.label.toLowerCase() == "away") {
              if (!vAH[item.handicap]) {
                vAH[item.handicap] = [item.value, item.american]

                if (!ind) {
                  max = item.value
                  maxKey = [1, item.handicap]
                }
              } else if (vAH[item.handicap][0] < item.value) {
                vAH[item.handicap] = [item.value, item.american]
              }
              
              if ((item.value < 2 && max > 2) || (item.value < 2 && item.value > max)) {
                max = item.value
                maxKey = [1, item.handicap]
              }
            }
          })
          
          this.moreOdds['AH'] = [lAH, vAH]

          let otherHdc = ''
          otherHdc = this.convertHandicap(maxKey[1])
          try {
            if (maxKey[0] == 0) {
              this.moreOdds['maxAH'] = [maxKey[1], lAH[maxKey[1]], otherHdc, vAH[otherHdc]]
            } else if (maxKey[0] == 1){
              this.moreOdds['maxAH'] = [otherHdc, lAH[otherHdc], maxKey[1], vAH[maxKey[1]]]
            }
            
            if (!this.moreOdds['maxAH'][1] || !this.moreOdds['maxAH'][3]) {
              this.odds.AH = null
              this.moreOdds['maxAH'] = null
            }
          }
          catch(err) {
            this.odds.AH = null
          }
        }

        // Asian Handicap Cards
        if (this.odds.AHC) {
          let lAHC = {}
          let vAHC = {}
          max = 0
          maxKey = []

          this.odds.AHC.forEach((item, ind) => {
            if (item.label == "1" || item.label.toLowerCase() == "home") {
              if (!lAHC[item.handicap]) {
                lAHC[item.handicap] = [item.value, item.american]

                if (!ind) {
                  max = item.value
                  maxKey = [0, item.handicap]
                }
                return
              } else if (lAHC[item.handicap][0] < item.value) {
                lAHC[item.handicap] = [item.value, item.american]
              }
              
              if ((item.value < 2 && max > 2) || (item.value < 2 && item.value > max)) {
                max = item.value
                maxKey = [0, item.handicap]
              }
            } else if (item.label == "2" || item.label.toLowerCase() == "away") {
              if (!vAHC[item.handicap]) {
                vAHC[item.handicap] = [item.value, item.american]

                if (!ind) {
                  max = item.value
                  maxKey = [1, item.handicap]
                }
                return
              } else if (vAHC[item.handicap][0] < item.value) {
                vAHC[item.handicap] = [item.value, item.american]
              }
              
              if ((item.value < 2 && max > 2) || (item.value < 2 && item.value > max)) {
                max = item.value
                maxKey = [1, item.handicap]
              }
            }
          })

          this.moreOdds['AHC'] = [lAHC, vAHC]
        
          let otherHdc = ''
          
          otherHdc = this.convertHandicap(maxKey[1])
          try {
            if (maxKey[0] == 0) {
              this.moreOdds['maxAHC'] = [maxKey[1], lAHC[maxKey[1]], otherHdc, vAHC[otherHdc]]
            } else if (maxKey[0] == 1){
              this.moreOdds['maxAHC'] = [otherHdc, lAHC[otherHdc], maxKey[1], vAHC[maxKey[1]]]
            }
            if (!this.moreOdds['maxAHC'][1] || !this.moreOdds['maxAHC'][3]) {
              this.odds.AHC = null
              this.moreOdds['maxAHC'] = null
            }
          }
          catch(err) {
            this.odds.AHC = null
          }
        }

        // Corner Handicap
        if (this.odds.CH) {
          let lCH = {}
          let vCH = {}
          max = 0
          maxKey = []

          this.odds.CH.forEach((item, ind) => {
            if (item.label == "1" || item.label.toLowerCase() == "home") {
              if (!lCH[item.handicap]) {
                lCH[item.handicap] = [item.value, item.american]

                if (!ind) {
                  max = item.value
                  maxKey = [0, item.handicap]
                }
              } else if (lCH[item.handicap][0] < item.value) {
                lCH[item.handicap] = [item.value, item.american]
              }

              if ((item.value < 2 && max > 2) || (item.value < 2 && item.value > max)) {
                max = item.value
                maxKey = [0, item.handicap]
              }
            } else if (item.label == "2" || item.label.toLowerCase() == "away") {
              if (!vCH[item.handicap]) {
                vCH[item.handicap] = [item.value, item.american]

                if (!ind) {
                  max = item.value
                  maxKey = [1, item.handicap]
                }
              } else if (vCH[item.handicap][0] < item.value) {
                vCH[item.handicap] = [item.value, item.american]
              }

              if ((item.value < 2 && max > 2) || (item.value < 2 && item.value > max)) {
                max = item.value
                maxKey = [1, item.handicap]
              }
            }
          })

          if (Object.keys(lCH).length === 0 || Object.keys(vCH).length === 0) {
            this.odds.CH = null
          } else {
            this.moreOdds['CH'] = [lCH, vCH]
            
            if (maxKey[1]) {
              let otherHdc = this.convertHandicap(maxKey[1])
            
              if (maxKey[0] == 0) {
                this.moreOdds['maxCH'] = [maxKey[1], lCH[maxKey[1]], otherHdc, vCH[otherHdc]]
              } else if (maxKey[0] == 1){
                this.moreOdds['maxCH'] = [otherHdc, lCH[otherHdc], maxKey[1], vCH[maxKey[1]]]
              }
            }
          }
        }

        // Corner Over/Under
        if (this.odds.COU) {
          let cOver = {}
          let cUnder = {}
          max = 0
          maxKey = ''

          this.odds.COU.forEach((item) => {
            if (item.value < 2 && item.value > max) {
              max = item.value
              maxKey = item.total
            }

            if (item.label.toLowerCase() == "over") {
              if (!cOver[item.total]) {
                cOver[item.total] = [item.value, item.american]
              } else if (cOver[item.total][0] < item.value) {
                cOver[item.total] = [item.value, item.american]
              }
            } else if (item.label.toLowerCase() == "under") {
              if (!cUnder[item.total]) {
                cUnder[item.total] = [item.value, item.american]
              } else if (cUnder[item.total][0] < item.value) {
                cUnder[item.total] = [item.value, item.american]
              }
            }
          })
          
          this.moreOdds['COU'] = [cOver, cUnder]
          this.moreOdds['maxCOU'] = maxKey
        }       

        // Card Over/Under; Asian Total Cards
        if (this.odds.ATC) {
          let cOver = {}
          let cUnder = {}
          max = 0
          maxKey = ''
          
          this.odds.ATC.forEach((item) => {
            if (item.value < 2 && item.value > max) {
              max = item.value
              maxKey = item.total
            }

            if (item.label.toLowerCase() == "over") {
              if (!cOver[item.total]) {
                cOver[item.total] = [item.value, item.american]
              } else if (cOver[item.total][0] < item.value) {
                cOver[item.total] = [item.value, item.american]
              }
            } else if (item.label.toLowerCase() == "under") {
              if (!cUnder[item.total]) {
                cUnder[item.total] = [item.value, item.american]
              } else if (cUnder[item.total][0] < item.value) {
                cUnder[item.total] = [item.value, item.american]
              }
            }
          })
          
          this.moreOdds['ATC'] = [cOver, cUnder]
          this.moreOdds['maxATC'] = maxKey
        }

        // Team Cards
        if (this.odds.tCard) {
          let lTC = []
          let vTC = []

          this.odds.tCard.forEach((item, ind) => {
            if (item.handicap == null) item.handicap = item.total

            if (item.label == "1" || item.label.toLowerCase() == "home") {
              lTC.push({
                handicap: item.handicap.toLowerCase().split(' '),
                value: [item.value, item.american]})
            } else if (item.label == "2" || item.label.toLowerCase() == "away") {
              vTC.push({
                handicap: item.handicap.toLowerCase().split(' '),
                value: [item.value, item.american]})
            }
          })

          if (Object.keys(lTC).length === 0 || Object.keys(vTC).length === 0) {
            this.odds.tCard = null
          } else {
            this.moreOdds['tCard'] = [lTC, vTC]
          }
        }

        // Team Corners
        if (this.odds.tCorner) {
          let lTCorner = []
          let vTCorner = []

          this.odds.tCorner.forEach((item, ind) => {
            if (item.label == "1" || item.label.toLowerCase() == "home") {
              lTCorner.push({
                handicap: item.total.toLowerCase().split(' '),
                value: [item.value, item.american]})
            } else if (item.label == "2" || item.label.toLowerCase() == "away") {
              vTCorner.push({
                handicap: item.total.toLowerCase().split(' '),
                value: [item.value, item.american]})
            }
          })

          if (Object.keys(lTCorner).length === 0 || Object.keys(vTCorner).length === 0) {
            this.odds.tCorner = null
          } else {
            this.moreOdds['tCorner'] = [lTCorner, vTCorner]
          }
        }

        // Team Shots On Target
        if (this.odds.tShT) {
          let lTShT = []
          let vTShT = []

          this.odds.tShT.forEach((item, ind) => {
            if (item.handicap == null) item.handicap = item.total

            if (item.label == "1" || item.label.toLowerCase() == "home") {
              lTShT.push({
                handicap: item.handicap.toLowerCase().split(' '),
                value: [item.value, item.american]})
            } else if (item.label == "2" || item.label.toLowerCase() == "away") {
              vTShT.push({
                handicap: item.handicap.toLowerCase().split(' '),
                value: [item.value, item.american]})
            }
          })

          this.moreOdds['tShT'] = [lTShT, vTShT]
        }

        // Team Shots
        if (this.odds.tSh) {
          let lTSh = []
          let vTSh = []

          this.odds.tSh.forEach((item, ind) => {
            if (item.handicap == null) item.handicap = item.total

            if (item.label == "1" || item.label.toLowerCase() == "home") {
              lTSh.push({
                handicap: item.handicap.toLowerCase().split(' '),
                value: [item.value, item.american]})
            } else if (item.label == "2" || item.label.toLowerCase() == "away") {
              vTSh.push({
                handicap: item.handicap.toLowerCase().split(' '),
                value: [item.value, item.american]})
            }
          })

          this.moreOdds['tSh'] = [lTSh, vTSh]
        }

        // Team Offsides
        if (this.odds.tOffs) {
          let lOffs = []
          let vOffs = []

          this.odds.tOffs.forEach((item, ind) => {
            if (item.handicap == null) item.handicap = item.total
            
            if (item.label == "1" || item.label.toLowerCase() == "home") {
              lOffs.push({
                handicap: item.handicap.toLowerCase().split(' '),
                value: [item.value, item.american]})
            } else if (item.label == "2" || item.label.toLowerCase() == "away") {
              vOffs.push({
                handicap: item.handicap.toLowerCase().split(' '),
                value: [item.value, item.american]})
            }
          })

          this.moreOdds['tOffs'] = [lOffs, vOffs]
        }

        // Score a penalty
        // if (this.odds.sPt) {
        //   this.moreOdds['sPt'] = []

        //   this.odds.sPt.forEach((item, ind) => {
        //     this.moreOdds['sPt'].push({
        //         label: item.label,
        //         value: item.value,
        //         probability: item.probability})
        //   })
        // }     
      },

      convertHandicap(hdc) {
        if (hdc.substring(0, 1) == '-') {
          return '+' + hdc.substring(1)
        } else if (hdc.substring(0, 1) == '+') {
          return '-' + hdc.substring(1)
        } else {
          return hdc
        }
      },

      goalOddsModal() {
        this.goalOddsDialog = true
      },

      cornerOddsModal() {
        this.cornerOddsDialog = true
      },

      cardOddsModal() {
        this.cardOddsDialog = true
      },

      tab_onclick(key) {
        if (key == 'referee') {
          this.flags.referee = 1
          if (!this.rLastLoaded) this.getRefereeLast()
        }
      },

      matchDetail(id) {
        let routeData = this.$router.resolve({name: 'fixture', params: {fid: id}});
        window.open(routeData.href, '_blank');
      },

      /* function get color for strength */

      get_form_color(val) {
        if (!val) {
          return ''
        } else if (val < 1) {
          return 'red lighten-2'
        } else if (val < 1.5) {
          return 'yellow darken-3'
        } else if (val < 2) {
          return 'green lighten-1'
        } else {
          return 'green darken-2'
        }
      }
  },
};
</script>
<style scoped>
  @import '../assets/footcorner/soccermatch.css';
</style>