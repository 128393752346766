<template>
  <!-- <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  > -->
  <div class="row no-gutters footer">
    <div class="col-md-10 col-lg-8 d-flex flex-column flex-md-row" style="justify-content: space-between;">
      <div class="align-self-center">
        <router-link
          class="nav-link"
          to="/contacts"
        >
          {{ $t('contactus') }}
        </router-link>
      </div>
      
      <div class="align-self-center">
        <a
          class="nav-link"
          href="https://footcorner.tapfiliate.com"
        >
          {{ $t('affiliate') }}
        </a>
      </div>

      <div class="copyright">
        <img class="logo" src="/img/logo.png">

        {{ $t('copyright') }} @ {{ year }}, Foot-corner. {{ $t('rightreserve') }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
