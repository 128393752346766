<template>
  <v-app>
    <div class="page-header clear-filter">
      <div
        class="page-header-image image-opacity"
        style="background-image: url('img/soccer-bg1.jpg')"
      ></div>

      <div class="content">
        <div id="app" class="signup">
          <transition name="fade" mode="out-in">    
            <component :is="compname" @change_comp="swapcomp($event)"></component>
          </transition>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import { SignupForm, Terms, Results } from '@/components'
import MainFooter from '@/layout/MainFooter'

export default {
  name: 'signup-page',
  data() {
    return {
        compname: 'signup-form'
    }
  },
  components: {
    'signup-form': SignupForm,
    'results': Results,
    'terms': Terms
  },
  
  methods: {
    swapcomp: function(comp) {
      this.compname = comp;
    }
  }
};
</script>
<style lang="scss">
    @import '@/assets/footcorner/signup.scss';
</style>