<template>
  <v-app>
    <v-dialog
      v-if="trophies"
      v-model="trophyDialog"
      width="fit-content"
      style="z-index: 2000">
      <v-card>
        <div class="d-flex justify-center align-center p-3">
          <img
            v-if="trophies[index4trophy][1].league_logo"
            :src="trophies[index4trophy][1].league_logo"
            onerror="this.src='/img/default-avatar.png'"
            style="width: 70px; height: 70px;">

          <div class="d-flex flex-column">
            <div v-if="trophies[index4trophy][1].trophy[0].length" class="p-2">
              <span>{{ $t('winner') }}:</span>
              
              <div class="ml-5 d-flex flex-wrap">
                <span
                  v-for="cup in trophies[index4trophy][1].trophy[0]"
                  :key="cup.id"
                  class="mr-1 px-1"
                  style="border: 1px solid lightgrey;">
                  {{ cup.name }}
                </span>
              </div>
            </div>

            <div v-if="trophies[index4trophy][1].trophy[1].length" class="p-2">
              <span>{{ $t('runnerup') }}:</span>
              
              <div class="ml-5 d-flex flex-wrap">
                <span
                  v-for="cup in trophies[index4trophy][1].trophy[1]"
                  :key="cup.id"
                  class="mr-1 px-1"
                  style="border: 1px solid lightgrey;">
                  {{ cup.name }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="stats && stats.length"
      v-model="statsDialog"
      width="550"
      style="z-index: 2000">
      <v-card>
        <v-card-title>
          <div class="d-grid">
            <router-link :to="{name: 'league', params: {lid: stats[curSeason].league.id}}">
              <span class="green--text text--darken-2">{{ stats[curSeason].league.name }}</span>
            </router-link>

            <span v-if="stats[curSeason].season" class="subtitle-1 grey--text text--darken-1">{{ stats[curSeason].season }}</span>
          </div>

          <v-spacer/>

          <router-link :to="{name: 'team', params: {id: stats[curSeason].team.id}}">
            <span class="subtitle-1 red--text text--darken-4">{{ stats[curSeason].team.name }}</span>
            
            <img
              class="wh-40 ml-2"
              :src="stats[curSeason].team.image_path"
            >
          </router-link>
        </v-card-title>

        <v-card-text>
          <v-simple-table class="table-row-35">
            <tbody>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.APPEARANCES') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.appearances }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.MINUTES_PLAYED') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.minutes }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.RATING') }}:</td>
                <td class="text-right title red--text text--darken-4">{{ stats[curSeason].details.rating.average }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.GOALS') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.goals.total }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.avg_goals_per_90') }}:</td>
                
                <td class="text-right green--text text--darken-2">
                  <template v-if="stats[curSeason].details.minutes">
                    {{ (stats[curSeason].details.goals.total / stats[curSeason].details.minutes * 90).toFixed(1) }}
                  </template>
                </td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.avg_mins_per_goal') }}:</td>
                
                <td class="text-right green--text text--darken-2">
                  <template v-if="stats[curSeason].details.goals.total">
                    {{ (stats[curSeason].details.minutes / stats[curSeason].details.goals.total).toFixed(0) }}
                  </template>
                </td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.ASSISTS') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.assists }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.avg_assists_per_90') }}:</td>
                
                <td class="text-right green--text text--darken-2">
                  <template v-if="stats[curSeason].details.minutes">
                    {{ (stats[curSeason].details.assists / stats[curSeason].details.minutes * 90).toFixed(1) }}
                  </template>
                </td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.avg_mins_per_assist') }}:</td>
                
                <td class="text-right green--text text--darken-2">
                  <template v-if="stats[curSeason].details.assists">
                    {{ (stats[curSeason].details.minutes / stats[curSeason].details.assists).toFixed(0) }}
                  </template>
                </td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.OWN_GOALS') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.owngoals }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.avg_owngoals_per_90') }}:</td>
                
                <td class="text-right green--text text--darken-2">
                  <template v-if="stats[curSeason].details.minutes">
                    {{ (stats[curSeason].details.owngoals / stats[curSeason].details.minutes * 90).toFixed(1) }}
                  </template>
                </td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.avg_mins_per_owngoal') }}:</td>
                
                <td class="text-right green--text text--darken-2">
                  <template v-if="stats[curSeason].details.owngoals">
                    {{ (stats[curSeason].details.minutes / stats[curSeason].details.owngoals).toFixed(0) }}
                  </template>
                </td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.LINEUPS') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.lineups }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.SUBSTITUTIONS_IN') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.substitutions.in }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.SUBSTITUTIONS_OUT') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.substitutions.out }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.CAPTAIN') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.captain }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.SHOTS_TOTAL') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.shots.total }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.SHOTS_ON_TARGET') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.shots.shots_on_target }}</td>
              </tr>

              <!-- <tr>
                <td class="grey--text text--darken-1">Hit-post:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.hit_post }}</td>
              </tr> -->

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.PASSES_TOTAL') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.passes.total }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.PASSES_ACCURATE') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.passes.accurate }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.KEY_PASSES') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.passes.key_passes }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.DRIBBLE_ATTEMPTS') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.dribbles.attempts }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.SUCCESSFUL_DRIBBLES') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.dribbles.success }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.DRIBBLED_PAST') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.dribbles.dribbled_past }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.DISPOSSESSED') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.dispossesed }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.INTERCEPTIONS') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.interceptions }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.TACKLES') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.tackles }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.BLOCKED_SHOTS') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.blocked_shots }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.SHOTS_BLOCKED') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.shots_blocked }}</td>
              </tr>

              <!-- <tr>
                <td class="grey--text text--darken-1">Cleansheets:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.cleansheets }}</td>
              </tr> -->

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.SAVES') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.saves }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.SAVES_INSIDE_BOX') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.saves_insidebox }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.YELLOWCARDS') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.yellowcards + stats[curSeason].details.yellowredcards }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.avg_yellowcards_per_90') }}:</td>
                
                <td class="text-right green--text text--darken-2">
                  <template v-if="stats[curSeason].details.minutes">
                    {{ ((stats[curSeason].details.yellowcards + stats[curSeason].details.yellowredcards) / stats[curSeason].details.minutes * 90).toFixed(1) }}
                  </template>
                </td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.avg_mins_per_yellowcard') }}:</td>
                
                <td class="text-right green--text text--darken-2">
                  <template v-if="stats[curSeason].details.yellowcards">
                    {{ (stats[curSeason].details.minutes / (stats[curSeason].details.yellowcards + stats[curSeason].details.yellowredcards)).toFixed(0) }}
                  </template>
                </td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.YELLOWRED_CARDS') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.yellowredcards }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.REDCARDS') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.redcards }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.avg_redcards_per_90') }}:</td>
                
                <td class="text-right green--text text--darken-2">
                  <template v-if="stats[curSeason].details.minutes">
                    {{ (stats[curSeason].details.redcards / stats[curSeason].details.minutes * 90).toFixed(1) }}
                  </template>
                </td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.avg_mins_per_redcard') }}:</td>
                
                <td class="text-right green--text text--darken-2">
                  <template v-if="stats[curSeason].details.redcards">
                    {{ (stats[curSeason].details.minutes / stats[curSeason].details.redcards).toFixed(0) }}
                  </template>
                </td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.FOULS_COMMITTED') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.fouls.committed }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.FOULS_DRAWN') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.fouls.drawn }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.TOTAL_CROSSES') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.crosses.total }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.ACCURATE_CROSSES') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.crosses.accurate }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.TOTAL_DUELS') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.duels.total }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.DUELS_WON') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.duels.won }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.PENALTIES_SCORED') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.goals.penalties }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="warning"
            dark
            @click="statsDialog = false"
          >
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="pt-60">
      <template v-if="!infoLoaded">
        <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
      </template>

      <template v-else-if="!authorization">
        <v-alert
          type="warning"
          prominent
        >
          {{ $t(`message.${message}`) }}
        </v-alert>
      </template>

      <template v-else>
        <div class="row justify-center">
          <div class="d-flex py-3">
            <img
              class="banner_logo2 rounded-circle"
              :src="player.image_path"
              onerror="this.src='/img/default-avatar.png'"
              :title="player.name"
            >

            <div class="ml-3 d-flex flex-column justify-space-around">
              <div>
                <span
                  class="font-weight-bold green--text text--darken-2"
                  :class="{'subtitle-1': $vuetify.breakpoint.xs, 'h4': $vuetify.breakpoint.smAndUp}">
                  {{ player.display_name }}
                </span>
              </div>

              <div class="text-center">
                <router-link :to="{name: 'country', params: {cid: player.country_id}}">
                  <img class="country_logo elevation-5" :src="player.country.image_path" onerror="style.display='none'" />
                  
                  <span class="ml-1 red--text text--darken-4">{{ player.country.name }}</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="row no-gutters mt-2 justify-center">
          <div class="col-sm-12 col-md-10 col-lg-9 elevation-2">
            <v-tabs 
              v-model="tabInd"
              @change="tabChange"
              centered
              show-arrows
              light
            >
              <v-tab 
                v-for="(item, i) in tabs"
                :key="i"
              >
                <span style="font-size: small">{{ $t(item.text) }}</span>
              </v-tab>

              <v-tab-item>
                <div class="py-2">
                  <div class="my-5 d-flex flex-wrap justify-space-around">
                    <div
                      v-for="team in player.teams"
                      :key="team.team_id">
                      <router-link :to="{name: 'team', params: {id: team.team_id}}">
                        <img
                          class="wh-40"
                          :src="team.team.image_path"
                          :title="team.team.name"
                          onerror="style.display='none'"
                        >

                        <span class="h5 ml-5">{{ team.team.name }}</span>
                      </router-link>

                      <span v-if="team.start || team.end" class="my-1 align-center blue-grey--text">{{ team.start }} ~ {{ team.end }}</span>
                      <span v-else class="my-1 align-center blue-grey--text"></span>

                      <span 
                        class="align-center"
                        :style="{color: position_color[team.position_id - 24]}"
                      >
                        {{ $t(`lineup_type.${position_label[team.position_id - 24]}`) }}
                      </span>
                    </div>
                  </div>

                  <div class="my-5 d-flex flex-wrap justify-space-around align-center">
                    <div class="my-2 d-flex justify-space-between">
                      <span class="mr-3 font-weight-bold grey--text text--darken-1">{{ $t('height') }}/{{ $t('weight') }}: </span>
                      <span class="red--text text--darken-4">{{ player.height }}cm / {{ player.weight }}kg</span>
                    </div>

                    <div class="my-2 d-flex justify-space-between">
                      <span class="mr-3 font-weight-bold grey--text text--darken-1">{{ $t('birthdate') }}: </span>
                      <span class="red--text text--darken-4">{{ player.date_of_birth }}</span>
                    </div>
                    
                    <div v-if="player.metadata[0] && player.metadata[0].values" class="my-2 d-flex justify-space-between">
                      <span class="mr-3 font-weight-bold grey--text text--darken-1">{{ $t('preferredfoot') }}: </span>
                      <span class="red--text text--darken-4">{{ player.metadata[0].values }}</span>
                    </div>
                  </div>
                </div>
                
                <div v-if="trophies" class="mb-5 p-1 d-flex">
                  <span class="mr-10 align-center grey--text text--darken-1 font-weight-bold">{{ $t('trophy') }}: </span>

                  <div class="d-flex flex-wrap">
                    <a
                      v-for="(cup, ind) in trophies"
                      :key="cup[0]"
                      class="w-fit-content elevation-1 m-1 px-1 curPointer"
                      style="border: 1px solid lightgrey; border-radius: 2px;"
                      @click="trophyModal(ind)">
                      <img
                        class="mx-1 wh-20"
                        :src="cup[1].league_logo"
                        :title="cup[1].league_name"
                        onerror="style.display='none'"
                      >
                      
                      <span class="blue-grey--text subtitle-2">{{ cup[1].league_name }}</span>
                      <span v-if="cup[1].trophy[0].length" class="red white--text ml-1 px-1 caption" style="border-radius: 25%" :title="$t('winner')">{{ cup[1].trophy[0].length }}</span>
                      <span v-if="cup[1].trophy[1].length" class="blue white--text ml-1 px-1 caption" style="border-radius: 25%" :title="$t('runnerup')">{{ cup[1].trophy[1].length }}</span>
                    </a>
                  </div>
                </div>

                <div v-if="transfers.length" class="mb-5 pt-5 align-center">
                  <v-simple-table class="table-row-35">
                    <thead class="green-top-3 lightgrey-bottom-2">
                      <tr class="text-left grey--text text--darken-1 font-weight-bold">
                        <td>{{ $t('from') }}</td>
                        <td>{{ $t('to') }}</td>
                        <td colspan=3>{{ $t('transfer') }}</td>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(item, ind) in transfers"
                        :key="ind"
                        class="text-left"
                      >
                        <td>
                          <router-link :to="{name: 'team', params: {id: item.from_team_id}}">
                            <img 
                              class="wh-30 mr-1"
                              :src="item.fromteam.image_path"
                              :title="item.fromteam.name"
                              />

                              <span>{{ item.fromteam.name }}</span>
                          </router-link>
                        </td>

                        <td>
                          <router-link :to="{name: 'team', params: {id: item.to_team_id}}">
                            <img 
                              class="wh-30 mr-1"
                              :src="item.toteam.image_path"
                              :title="item.toteam.name"
                              />

                              <span>{{ item.toteam.name }}</span>
                          </router-link>
                        </td>

                        <td style="white-space:nowrap">
                          <span class="blue-grey--text">{{ item.date }}</span>
                        </td>

                        <td class="d-none d-sm-table-cell" style="white-space:nowrap">
                          <span 
                            v-if="item.type_id != 'n/a'"
                            :class="`${transferColors[item.type_id - 218]}--text`"
                          >
                            {{ $t(`transfer_type.${item.type.developer_name}`) }}
                          </span>
                        </td>

                        <td class="d-none d-sm-table-cell">
                          <span v-if="item.amount" class="font-weight-bold deep-orange--text">{{ parseFloat((item.amount / 1000000).toFixed(2)) }}M</span>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </div>
              </v-tab-item>

              <v-tab-item>
                <div class="row no-gutters justify-center">
                  <div class="col-lg-10 py-2 align-center">
                    <v-simple-table v-if="player.latest && player.latest.length" class="my-2 table-row-30">
                      <tbody>
                        <tr
                          v-for="(item, ind) in player.latest"
                          :key="ind"
                          :title="item.fixture ? item.fixture.result_info : ''"
                        >
                          <template v-if="item.fixture">
                            <td class="overflow-anywhere blue-grey--text" :class="{'text-center' : $vuetify.breakpoint.xs}">
                              <template v-if="$vuetify.breakpoint.xs">{{ item.fixture.starting_at_timestamp | moment('MM/DD HH:mm')}}</template>
                              <template v-else>{{ item.fixture.starting_at_timestamp | moment('YYYY/MM/DD HH:mm')}}</template>
                            </td>

                            <td :class="{'d-none': $vuetify.breakpoint.xs}">
                              <router-link :to="{name: 'league', params: {lid: item.fixture.league_id}}">
                                <img
                                  :src="item.fixture.league.image_path"
                                  class="wh-20 mr-1"
                                  :title="item.fixture.league.name"
                                >

                                <span class="blue-grey--text">{{ item.fixture.league.name }}</span>
                              </router-link>
                            </td>

                            <td class="black--text justify-end text-end">
                              <router-link 
                                v-if="item.fixture.participants[0].id != item.team_id"
                                :to="{name: 'team', params: {id: item.fixture.participants[0].id}}"
                                class="d-flex align-center justify-end text-end">
                                <span>
                                  {{ $vuetify.breakpoint.xs ? ( item.fixture.participants[0].short_code ? item.fixture.participants[0].short_code : item.fixture.participants[0].name.substring(0, 3)) : item.fixture.participants[0].name }}
                                </span>
                                
                                <img
                                  :src="item.fixture.participants[0].image_path"
                                  class="wh-20 ml-1"
                                  onerror="style.display='none'"
                                >
                              </router-link>

                              <template v-else>
                                <span>
                                  {{ $vuetify.breakpoint.xs ? ( item.fixture.participants[0].short_code ? item.fixture.participants[0].short_code : item.fixture.participants[0].name.substring(0, 3)) : item.fixture.participants[0].name }}
                                </span>
                                
                                <img
                                  :src="item.fixture.participants[0].image_path"
                                  class="wh-20 ml-1"
                                  onerror="style.display='none'"
                                >
                              </template>
                            </td>

                            <td
                              class="text-center"
                              :style="{ 'font-size': $vuetify.breakpoint.xs ? 'xx-small !important' : ''}">
                              <span>VS</span>
                            </td>

                            <td>
                              <router-link
                                v-if="item.fixture.participants[1].id != item.team_id"
                                :to="{name: 'team', params: {id: item.fixture.participants[1].id}}"
                                class="d-flex align-center justify-start text-start">
                                <img
                                  :src="item.fixture.participants[1].image_path"
                                  class="wh-20 mr-1"
                                  onerror="style.display='none'"
                                >

                                <span>
                                  {{ $vuetify.breakpoint.xs ? ( item.fixture.participants[1].short_code ? item.fixture.participants[1].short_code : item.fixture.participants[1].name.substring(0, 3)) : item.fixture.participants[1].name }}
                                </span>
                              </router-link>

                              <template v-else>
                                <img
                                  :src="item.fixture.participants[1].image_path"
                                  class="wh-20 mr-1"
                                  onerror="style.display='none'"
                                >

                                <span class="black--text">
                                  {{ $vuetify.breakpoint.xs ? ( item.fixture.participants[1].short_code ? item.fixture.participants[1].short_code : item.fixture.participants[1].name.substring(0, 3)) : item.fixture.participants[1].name }}
                                </span>
                              </template>
                            </td>

                            <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(item.fixture_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                          </template>
                        </tr>
                      </tbody>
                    </v-simple-table>

                    <p v-else>{{ $t('nolatest') }}</p>
                  </div>
                </div>
              </v-tab-item>

              <v-tab-item>
                <div class="row no-gutters justify-center">
                  <div class="col-sm-12 col-md-9 col-lg-7 py-5 align-center">
                    <template v-if="tabLoaded">
                      <template v-if="stats.length">
                        <v-data-table
                          :headers="statsHeaders"
                          :items="stats"
                          :sort-by="['season']"
                          :sort-desc="[true]"
                          :items-per-page="15"
                          item-key="key"
                          @click:row="showStats"
                          class="table-row-35 curPointer green-top-3"
                          :mobile-breakpoint="0"
                          :footer-props="{
                            'items-per-page-text':$t('rowsperpage')
                          }"
                        >
                          <template v-slot:item.league="{ item }">
                            <span class="red--text text--darken-4">{{ item.league.name }}</span>
                          </template>
                          
                          <template v-slot:item.season="{ item }">
                            <span class="grey--text text--darken-1">{{ item.season }}</span>
                          </template>
                        </v-data-table>
                      </template>

                      <p v-else>{{ $t('nostats') }}</p>
                    </template>

                    <div v-else class="ripple_loading">
                      <img src="/img/icons/Ripple-1s-200px.png">
                    </div>
                  </div>
                </div>
              </v-tab-item>
            </v-tabs>
          </div>
        </div>
      </template>
    </div>
  </v-app>
</template>

<script>

import { Card } from '@/components'
import axios from 'axios'
import moment from 'moment-timezone'

export default {
  components: {
    Card,
  },
  computed: {
    statsHeaders () {
      return [
        {text: this.$t('season'), align: 'start', value: 'season'},
        {text: this.$t('league'), align: 'start', value: 'league'}
      ]
    }
  },
  data() {
    return {
      tz: localStorage.tz ? localStorage.tz : '+0',
      player: null,
      transfers: [],
      stats: null,
      curSeason: 0,
      infoLoaded: false,
      tabLoaded: false,
      authorization: false,
      statsDialog: false,
      trophyDialog: false,
      
      index4trophy: 0,

      message: 'notlogin',

      position_label: ['goalkeeper', 'defender', 'midfielder', 'attacker'],
      position_color: ['green', 'darkcyan', 'orange', 'maroon'],
      transferColors: ['brown', 'orange', 'green'],
      tabs: [
        { text: 'basics', icon: '' },
        { text: 'latest', icon: '' },
        { text: 'stats', icon: '' },
      ],
      trophies: null,
      tabInd: 0,
      f: false,
    }
  },
  beforeCreate: function () {
    axios
      .get('/v3/player/info/' + this.$route.params.pid,
        { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
      .then(response => {
        if (response.data.type == 0) {
          this.message = "notsubscribe"
        }else if (response.data.type == 1) {
          this.message = "expiredsubscribe"
        }else {
          this.player = response.data

          this.player.latest.forEach(fx => {
            if (fx.fixture && fx.fixture.participants[0].meta.location == "away") {
              fx.fixture.participants = [fx.fixture.participants[1], fx.fixture.participants[0]]
            }
          })

          this.trophies = response.data.trophies.length ? this.modifyTrophies(response.data.trophies) : null
          this.transfers = this.player.transfers
          
          this.authorization = true
          // this.init()
        }
        this.infoLoaded = true
      })
      .catch(error => {
        if (error.response.status == 401) {
          localStorage.removeItem('token')
          localStorage.removeItem('uid')
          location.reload()
        }              
      })
  },
  watch: {},
  mounted() {
  },
  methods: {
    /*
      event for tab clicks
    */
    tabChange() {
      this.tabLoaded = false
      if (this.tabInd == 2) {
        if (this.stats) {
         this.tabLoaded = true
        } else {
          axios
            .get('/v3/player/stats/' + this.$route.params.pid,
              { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
            .then(response => {
              if (response.data.type == 0) {
                this.message = "notsubscribe"
              }else if (response.data.type == 1) {
                this.message = "expiredsubscribe"
              }else {
                this.stats = this.modifyStats(response.data.stats)
              }
              this.tabLoaded = true
            })
            .catch(error => {
              if (error.response.status == 401) {
                localStorage.removeItem('token')
                localStorage.removeItem('uid')
                location.reload()
              }              
            })
        }
      }
    },

    modifyStats(arr) {
      let tmp = []
      
      arr.forEach((element, ind) => {
        let detail = {
          appearances: 0,
          assists: 0,
          blocked_shots: 0,
          shots_blocked: 0,
          captain: 0,
          crosses: { total: 0, accurate: 0 },
          dispossesed: 0,
          dribbles: { attempts: 0, success: 0, dribbled_past: 0 },
          duels: { total: 0, won: 0 },
          fouls: { committed: 0, drawn: 0 },
          goals: { total: 0, goals: 0, penalties: 0 },
          saves_insidebox: 0,
          interceptions: 0,
          lineups: 0,
          minutes: 0,
          owngoals: 0,
          passes: { total: 0, accurate: 0, key_passes: 0 },
          rating: { average: 0, highest: 0, lowest: 0 },
          redcards: 0,
          yellowcards: 0,
          yellowredcards: 0,
          saves: 0,
          shots: { total: 0, shots_on_target: 0 },
          substitutions: { in: 0, out: 0 },
          tackles: 0,
        }

        element.details.forEach(item => {
          switch (item.type_id) {
            case 321:
              detail.appearances = item.value.total
              break;
            case 79:
              detail.assists = item.value.total
              break;
            case 97:
              detail.blocked_shots = item.value.total
              break;
            case 58:
              detail.shots_blocked = item.value.total
              break;
            case 40:
              detail.captain = item.value.total
              break;
            case 98:
              detail.crosses.total = item.value.total
              break;
            case 99:
              detail.crosses.accurate = item.value.total
              break;
            case 94:
              detail.dispossesed = item.value.total
              break;
            case 108:
              detail.dribbles.attempts = item.value.total
              break;
            case 109:
              detail.dribbles.success = item.value.total
              break;
            case 110:
              detail.dribbles.dribbled_past = item.value.total
              break;
            case 105:
              detail.duels.total = item.value.total
              break;
            case 106:
              detail.duels.won = item.value.total
              break;
            case 56:
              detail.fouls.committed = item.value.total
              break;
            case 96:
              detail.fouls.drawn = item.value.total
              break;
            case 52:
              detail.goals = item.value
              break;
            case 104:
              detail.saves_insidebox = item.value.total
              break;
            case 100:
              detail.interceptions = item.value.total
              break;
            case 322:
              detail.lineups = item.value.total
              break;
            case 119:
              detail.minutes = item.value.total
              break;
            case 324:
              detail.owngoals = item.value.total
              break;
            case 80:
              detail.passes.total = item.value.total
              break;
            case 116:
              detail.passes.accurate = item.value.total
              break;
            case 117:
              detail.passes.key_passes = item.value.total
              break;
            case 118:
              detail.rating = item.value
              break;
            case 83:
              detail.redcards = item.value.total
              break;
            case 57:
              detail.saves = item.value.total
              break;
            case 42:
              detail.shots.total = item.value.total
              break;
            case 86:
              detail.shots.shots_on_target = item.value.total
              break;
            case 59:
              detail.substitutions = item.value
              break;
            case 78:
              detail.tackles = item.value.total
              break;
            case 84:
              detail.yellowcards = item.value.total
              break;
            case 85:
              detail.yellowredcards = item.value.total
              break;
          }
        });

        tmp.push({
          key: ind,
          team: element.team,
          league: { id: element.season.league_id, name: element.season.league.name },
          season: element.season.name,
          details: detail
        })
      });

      return tmp
    },

    /*
      event for show stats Dialog
    */
    showStats(val) {
      this.curSeason = val.key
      this.statsDialog = true
    },

    /*
      method for modify Trophies array
    */
    modifyTrophies(arr) {
      let result = new Map()

      arr.forEach(element => {
        if (!element.league) return

        let tmp = result.get(element.league_id)

        if (tmp) {
          tmp.trophy[element.trophy_id - 1].push({
            id: element.season_id,
            name: element.season.name
          })
          result.set(element.league_id, tmp)
        } else {
          let trp = [[], []]

          trp[element.trophy_id - 1] = [{
            id: element.season_id,
            name: element.season.name
          }]

          result.set(element.league_id, {
            league_name: element.league.name,
            league_logo: element.league.image_path,
            trophy: trp
          })
        }
      });
      
      let res = [...result.entries()].sort((a, b) => 
        (b[1].trophy[0].length - a[1].trophy[0].length) || (b[1].trophy[1].length - a[1].trophy[1].length))
      
      res.forEach(element => {
        element[1].trophy[0] = _.orderBy(element[1].trophy[0], ['name'], ['desc'])
        element[1].trophy[1] = _.orderBy(element[1].trophy[1], ['name'], ['desc'])
      });

      return res
    },

    matchDetail(id) {
      let routeData = this.$router.resolve({name: 'fixture', params: {fid: id}});
      window.open(routeData.href, '_blank');
    },

    trophyModal(ind) {
      this.index4trophy = ind
      this.trophyDialog = true
    },
  }
};
</script>
<style>
  @import '../assets/footcorner/main.css'
</style>