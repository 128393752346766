<template>
  <v-app>
    <v-dialog
      v-if="playerTrophies"
      v-model="trophyDialog"
      width="fit-content"
      style="z-index: 2000">
      <v-card>
        <div class="d-flex justify-center align-center p-3">
          <img
            v-if="playerTrophies[index4trophy][1].league_logo"
            :src="playerTrophies[index4trophy][1].league_logo"
            onerror="this.src='/img/default-avatar.png'"
            style="width: 70px; height: 70px;">

          <div class="d-flex flex-column">
            <div v-if="playerTrophies[index4trophy][1].trophy[0].length" class="p-2">
              <span>{{ $t('winner') }}:</span>
              
              <div class="ml-5 d-flex flex-wrap">
                <span
                  v-for="cup in playerTrophies[index4trophy][1].trophy[0]"
                  :key="cup.id"
                  class="mr-1 px-1"
                  style="border: 1px solid lightgrey;">
                  {{ cup.name }}
                </span>
              </div>
            </div>

            <div v-if="playerTrophies[index4trophy][1].trophy[1].length" class="p-2">
              <span>{{ $t('runnerup') }}:</span>
              
              <div class="ml-5 d-flex flex-wrap">
                <span
                  v-for="cup in playerTrophies[index4trophy][1].trophy[1]"
                  :key="cup.id"
                  class="mr-1 px-1"
                  style="border: 1px solid lightgrey;">
                  {{ cup.name }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="stats && stats.length"
      v-model="statsDialog"
      width="550"
      style="z-index: 2000">
      <v-card>
        <v-card-title>
          <div class="d-grid">
            <router-link :to="{name: 'league', params: {lid: stats[curSeason].league.id}}">
              <span class="green--text text--darken-2">{{ stats[curSeason].league.name }}</span>
            </router-link>

            <span v-if="stats[curSeason].season" class="subtitle-1 grey--text text--darken-1">{{ stats[curSeason].season }}</span>
          </div>

          <v-spacer/>

          <router-link :to="{name: 'team', params: {id: stats[curSeason].team.id}}">
            <span class="subtitle-1 red--text text--darken-4">{{ stats[curSeason].team.name }}</span>
            
            <img
              class="wh-40 ml-2"
              :src="stats[curSeason].team.image_path"
            >
          </router-link>
        </v-card-title>

        <v-card-text>
          <v-simple-table class="table-row-35">
            <tbody>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.MATCHES') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.matches }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.WIN') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.team_wins }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.DRAW') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.team_draws }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.LOST') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.team_lost }}</td>
              </tr>
              
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.SUBSTITUTIONS') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.substitutions.count }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.avg_substitutions') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.substitutions.average }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.YELLOWCARDS') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.yellowcards.count }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.avg_yellowcards') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.yellowcards.average }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.YELLOWRED_CARDS') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.yellowredcards.count }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.avg_yellowredcards') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.yellowredcards.average }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.REDCARDS') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.redcards.count }}</td>
              </tr>

              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.avg_redcards') }}:</td>
                <td class="text-right green--text text--darken-2">{{ stats[curSeason].details.redcards.average }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="warning"
            dark
            @click="statsDialog = false"
          >
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="pt-60">
      <template v-if="!infoLoaded">
        <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
      </template>

      <template v-else-if="!authorization">
        <v-alert
          type="warning"
          prominent
        >
          {{ $t(`message.${message}`) }}
        </v-alert>
      </template>

      <template v-else>
        <div class="row justify-center">
          <div class="d-flex py-3">
            <img
              class="banner_logo2 rounded-circle"
              :src="coach.image_path"
              onerror="this.src='/img/default-avatar.png'"
              :title="coach.name"
            >

            <div class="ml-3 d-flex flex-column justify-space-around">
              <div>
                <span
                  class="font-weight-bold green--text text--darken-2"
                  :class="{'subtitle-1': $vuetify.breakpoint.xs, 'h4': $vuetify.breakpoint.smAndUp}">
                  {{ coach.display_name }}
                </span>
              </div>

              <div class="text-center">
                <router-link :to="{name: 'country', params: {cid: coach.country_id}}">
                  <img class="country_logo elevation-5" :src="coach.country.image_path" onerror="style.display='none'" />
                  
                  <span class="ml-1 red--text text--darken-4">{{ coach.country.name }}</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="row no-gutters mt-2 justify-center">
          <div class="col-sm-12 col-md-10 col-lg-9 elevation-2">
            <v-tabs 
              v-model="tabInd"
              @change="tabChange"
              centered
              show-arrows
              light
            >
              <v-tab 
                v-for="(item, i) in tabs"
                :key="i"
              >
                <span style="font-size: small">{{ $t(item.text) }}</span>
              </v-tab>

              <v-tab-item>
                <div v-if="teams.length" class="mb-5 pt-5 align-center">
                  <v-simple-table class="table-row-35 elevation-2">
                    <thead style="background: lavender">
                      <tr class="green-top-3 lightgrey-bottom-2">
                        <th>
                          <span class="grey--text text--darken-1 float-left font-weight-bold ml-2">{{ $t('team') }}</span>
                        </th>
                        <th>
                          <span class="ml-1">{{ $t('from') }}</span>
                        </th>
                        <th>
                          <span class="ml-1">{{ $t('to')}}</span>
                        </th>
                        <th>
                          <span class="ml-1">{{ $t('position') }}</span>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(team, ind) in teams"
                        :key="ind"
                      >
                        <td>
                          <router-link :to="{name: 'team', params: {id: team.team_id}}">
                            <img
                              class="wh-30 mx-2"
                              :src="team.team.image_path"
                              :title="team.team.name"
                              onerror="style.display='none'"
                            >

                            <span class="subtitle-2 mr-1">{{ team.team.name }}</span>
                          </router-link>
                        </td>
                        
                        <td>
                          <span class="mx-1 blue-grey--text">{{ team.start }}</span>
                        </td>
                        
                        <td>
                          <span class="mx-1 blue-grey--text">{{ team.end }}</span>
                        </td>

                        <td>
                          <span class="mx-1 brown--text font-weight-bold">{{ $t(`coach_type.${team.position.developer_name}`) }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </div>

                <div>
                  <div class="my-5 d-flex flex-wrap justify-space-around align-center">
                    <div v-if="coach.height || coach.weight" class="my-2 d-flex justify-space-between">
                      <span class="mr-3 font-weight-bold grey--text text--darken-1">{{ $t('height') }} / {{ $t('weight') }}: </span>
                      <span class="red--text text--darken-4">{{ coach.height }}cm / {{ coach.weight }}kg</span>
                    </div>

                    <div class="my-2 d-flex justify-space-between">
                      <span class="mr-3 font-weight-bold grey--text text--darken-1">{{ $t('birthdate') }}: </span>
                      <span class="red--text text--darken-4">{{ coach.date_of_birth }}</span>
                    </div>
                  </div>
                </div>

                <div v-if="upcomings && upcomings.length" class="my-5 align-center">
                  <v-simple-table class="my-2 table-row-30 elevation-2">
                    <thead style="background: lavender">
                      <tr class="green-top-3 lightgrey-bottom-2">
                        <th colspan="6">
                          <span class="grey--text text--darken-1 float-left font-weight-bold ml-5">{{ $t('upcomings') }}</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, ind) in upcomings"
                        :key="ind"
                      >
                        <td class="overflow-anywhere blue-grey--text" :class="{'text-center' : $vuetify.breakpoint.xs}">
                          <template v-if="$vuetify.breakpoint.xs">{{ item.starting_at_timestamp | moment('MM/DD HH:mm')}}</template>
                          <template v-else>{{ item.starting_at_timestamp | moment('YYYY/MM/DD HH:mm')}}</template>
                        </td>

                        <td :class="{'d-none': $vuetify.breakpoint.xs}">
                          <router-link :to="{name: 'league', params: {lid: item.league_id}}">
                            <img
                              :src="item.league.image_path"
                              class="wh-20 mr-1"
                              :title="item.league.name"
                            >

                            <span class="blue-grey--text">{{ item.league.name }}</span>
                          </router-link>
                        </td>

                        <td class="black--text justify-end text-end">
                          <router-link 
                            :to="{name: 'team', params: {id: item.participants[0].id}}"
                            class="d-flex align-center justify-end text-end">
                            <span>
                              {{ $vuetify.breakpoint.xs ? ( item.participants[0].short_code ? item.participants[0].short_code : item.participants[0].name.substring(0, 3)) : item.participants[0].name }}
                            </span>
                            
                            <img
                              :src="item.participants[0].image_path"
                              class="wh-20 ml-1"
                              onerror="style.display='none'"
                            >
                          </router-link>
                        </td>

                        <td
                          class="text-center"
                          :style="{ 'font-size': $vuetify.breakpoint.xs ? 'xx-small !important' : ''}">
                          <template v-if="item.state_id == 1">
                            <span>VS</span>
                          </template>

                          <template v-else>
                            <span class="red--text" :title="statusCode[item.state_id - 1].name + ': ' + statusCode[item.state_id - 1].info">
                              {{ statusCode[item.state_id - 1].short_name }}
                            </span>
                          </template>
                        </td>

                        <td>
                          <router-link
                            :to="{name: 'team', params: {id: item.participants[1].id}}"
                            class="d-flex align-center justify-start text-start">
                            <img
                              :src="item.participants[1].image_path"
                              class="wh-20 mr-1"
                              onerror="style.display='none'"
                            >

                            <span>
                              {{ $vuetify.breakpoint.xs ? ( item.participants[1].short_code ? item.participants[1].short_code : item.participants[1].name.substring(0, 3)) : item.participants[1].name }}
                            </span>
                          </router-link>
                        </td>

                        <td class="curPointer text-center" :title="$t('moredetails')" @click.stop="upcomingDetail(item.id, item.league.country_id, item.state_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </div>
                
                <div v-if="playerTrophies && playerTrophies.length" class="mb-5 pl-3 d-flex">
                  <span class="mr-3 align-center grey--text text--darken-1 font-weight-bold">{{ $t('playertrophies')}}: </span>

                  <div class="d-flex flex-wrap">
                    <a
                      v-for="(cup, ind) in playerTrophies"
                      :key="cup[0]"
                      class="w-fit-content elevation-1 m-1 px-1 curPointer"
                      style="border: 1px solid lightgrey; border-radius: 2px;"
                      @click="trophyModal(ind)">
                      <img
                        class="mx-1 wh-20"
                        :src="cup[1].league_logo"
                        :title="cup[1].league_name"
                        onerror="style.display='none'"
                      >
                      
                      <span class="blue-grey--text subtitle-2">{{ cup[1].league_name }}</span>
                      <span v-if="cup[1].trophy[0].length" class="red white--text ml-1 px-1 caption" style="border-radius: 25%" title="Winner">{{ cup[1].trophy[0].length }}</span>
                      <span v-if="cup[1].trophy[1].length" class="blue white--text ml-1 px-1 caption" style="border-radius: 25%" title="Runner-up">{{ cup[1].trophy[1].length }}</span>
                    </a>
                  </div>
                </div>

                <div v-if="transfers.length" class="mb-5 pt-5 align-center">
                  <v-simple-table class="table-row-35">
                    <thead class="green-top-3 lightgrey-bottom-2">
                      <tr class="text-left grey--text text--darken-1 font-weight-bold">
                        <td>{{ $t('from') }}</td>
                        <td>{{ $t('to') }}</td>
                        <td colspan=3>{{ $t('transfer') }}</td>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(item, ind) in transfers"
                        :key="ind"
                        class="text-left"
                      >
                        <td>
                          <router-link :to="{name: 'team', params: {id: item.from_team_id}}">
                            <img 
                              class="wh-30 mr-1"
                              :src="item.fromteam.image_path"
                              :title="item.fromteam.name"
                              />

                              <span>{{ item.fromteam.name }}</span>
                          </router-link>
                        </td>

                        <td>
                          <router-link :to="{name: 'team', params: {id: item.to_team_id}}">
                            <img 
                              class="wh-30 mr-1"
                              :src="item.toteam.image_path"
                              :title="item.toteam.name"
                              />

                              <span>{{ item.toteam.name }}</span>
                          </router-link>
                        </td>

                        <td style="white-space:nowrap">
                          <span class="blue-grey--text">{{ item.date }}</span>
                        </td>

                        <td class="d-none d-sm-table-cell" style="white-space:nowrap">
                          <span 
                            v-if="item.type_id != 'n/a'"
                            :class="`${transferColors[item.type_id - 218]}--text`"
                          >
                            {{ $t(`transfer_type.${item.type.developer_name}`) }}
                          </span>
                        </td>

                        <td class="d-none d-sm-table-cell">
                          <span v-if="item.amount" class="font-weight-bold deep-orange--text">{{ parseFloat((item.amount / 1000000).toFixed(2)) }}M</span>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </div>
              </v-tab-item>

              <v-tab-item>
                <div class="row justify-center my-1">
                  <div class="col-sm-5 col-md-3 col-lg-2">
                    <v-select
                      v-model="chosenSeason4Fixture"
                      :items="season4Fixture"
                      :label="$t('season')"
                      attach
                      hide-details
                      dense
                      outlined
                    />
                  </div>
                </div>
                
                <div class="align-center">
                  <template v-if="!tabLoaded" >
                    <div class='ripple_loading' ><img src='/img/icons/Ripple-1s-200px.png'></div>
                  </template>

                  <div v-else-if="fixtures && fixtures.length">
                    <v-simple-table class="table-row-40">
                      <tbody>
                        <tr
                          v-for="(item, ind) in fixtures"
                          :key="ind"
                        >
                          <td class="overflow-anywhere" :class="{'text-center' : $vuetify.breakpoint.xs}">
                            <template v-if="$vuetify.breakpoint.xs">{{ item.starting_at_timestamp | moment('YY/MM/DD HH:mm')}}</template>
                            <template v-else>{{ item.starting_at_timestamp | moment('YYYY/MM/DD HH:mm')}}</template>
                          </td>

                          <td :class="{'d-none': $vuetify.breakpoint.xs}">
                            <router-link :to="{name: 'league', params: {lid: item.league_id}}">
                              <img
                                :src="item.league.image_path"
                                class="wh-20 mr-1"
                                :title="item.league.name"
                              >

                              <span class="blue-grey--text">{{ item.league.name }}</span>
                            </router-link>
                          </td>

                          <td v-if="!$vuetify.breakpoint.xs" class="red--text">{{ item.stage.name }}</td>
                          
                          <td>
                            <router-link :to="{name: 'team', params: {id: item.participants[0].id}}" class="d-flex align-center justify-end text-end">
                              <span
                                :class="((item.scores.penalty[0] && (item.scores.penalty[0] > item.scores.penalty[1])) || (!item.scores.penalty[0] && (item.scores.goal[0] > item.scores.goal[1]))) ? 'font-weight-bold black--text' : 'blue-grey--text'">
                                {{ $vuetify.breakpoint.xs ? ( item.participants[0].short_code ? item.participants[0].short_code : item.participants[0].name.substring(0, 3)) : item.participants[0].name }}
                              </span>

                              <img
                                :src="item.participants[0].image_path"
                                class="wh-30 ml-3"
                                onerror="style.display='none'"
                              >
                            </router-link>
                          </td>
                          
                          <td class="text-center" :class="$vuetify.breakpoint.xs ? 'body-2' : 'subtitle-1'">
                            <span class="white-space-nowrap">
                              {{ item.scores.goal[0] }} - {{ item.scores.goal[1] }}
                            </span>

                            <span v-if="item.scores.penalty[0]" class="red--text" title="Penalty Score">
                              ({{ item.scores.penalty[0] }}-{{ item.scores.penalty[1] }})
                            </span>

                            <template v-if="item.state_id != 5 && item.state_id != 8">
                              <span class="red--text" :title="statusCode[item.state_id - 1].name + ': ' + statusCode[item.state_id - 1].info">
                                {{ statusCode[item.state_id - 1].short_name }}
                              </span>
                            </template>
                          </td>
                          
                          <td>
                            <router-link :to="{name: 'team', params: {id: item.participants[1].id}}" class="d-flex align-center justify-start text-start">
                              <img
                                :src="item.participants[1].image_path"
                                class="wh-30 mr-3"
                                onerror="style.display='none'"
                              >
                              
                              <span
                                :class="((item.scores.penalty[0] && (item.scores.penalty[0] < item.scores.penalty[1])) || (!item.scores.penalty[0] && (item.scores.goal[0] < item.scores.goal[1]))) ? 'font-weight-bold black--text' : 'blue-grey--text'">
                                {{ $vuetify.breakpoint.xs ? ( item.participants[1].short_code ? item.participants[1].short_code : item.participants[1].name.substring(0, 3)) : item.participants[1].name }}
                              </span>
                            </router-link>
                          </td>

                          <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(item.id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </div>

                  <p v-else>{{ $t('message.nofixture') }}</p>
                </div>
              </v-tab-item>

              <v-tab-item>
                <div class="row no-gutters justify-center">
                  <div class="col-sm-12 col-md-9 col-lg-7 py-5 align-center">
                    <template v-if="tabLoaded">
                      <template v-if="stats && stats.length">
                        <v-simple-table class="my-2 table-row-30 elevation-2 green-top-3">
                          <thead>
                            <tr class="white">
                              <th>{{ $t('season') }}</th>
                              <th>{{ $t('league') }}</th>
                              <th>{{ $t('team') }}</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr
                              v-for="(item, ind) in stats"
                              :key="ind"
                              class="curPointer"
                              @click="showStats(ind)"
                            >
                              <td>
                                <span class="grey--text text--darken-1">{{ item.season }}</span>
                              </td>

                              <td>
                                <span class="red--text text--darken-4">{{ item.league.name }}</span>
                              </td>

                              <td>
                                <router-link :to="{name: 'team', params: {id: item.team.id}}">
                                  <img
                                    class="wh-20 mx-2"
                                    :src="item.team.image_path"
                                    :title="item.team.name"
                                    onerror="style.display='none'"
                                  >

                                  <span class="grey--text text--darken-1 mr-3">
                                    {{ $vuetify.breakpoint.xs ? ( item.team.short_code ? item.team.short_code : item.team.name.substring(0, 3)) : item.team.name }}
                                  </span>
                                </router-link>
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </template>

                      <p v-else>{{ $t('message.nostats') }}</p>
                    </template>

                    <div v-else class="ripple_loading">
                      <img src="/img/icons/Ripple-1s-200px.png">
                    </div>
                  </div>
                </div>
              </v-tab-item>
            </v-tabs>
          </div>
        </div>
      </template>
    </div>
  </v-app>
</template>

<script>

import { Card } from '@/components'
import axios from 'axios'
import moment from 'moment-timezone'

export default {
  components: {
    Card,
  },
  data() {
    return {
      tz: localStorage.tz ? localStorage.tz : '+0',
      coach: null,
      teams: null,
      upcomings: null,
      transfers: null,
      playerTrophies: null,
      fixtures: null,
      allSeasons: null,
      season4Fixture: null,
      chosenSeason4Fixture: null,

      stats: null,
      curSeason: 0,
      infoLoaded: false,
      tabLoaded: false,
      authorization: false,
      statsDialog: false,
      trophyDialog: false,
      
      index4trophy: 0,

      message: 'notlogin',

      transferColors: ['brown', 'orange', 'green'],
      tabs: [
        { text: 'basics', icon: '' },
        { text: 'fixtures', icon: '' },
        { text: 'stats', icon: '' },
      ],
      // trophies: null,
      statsHeaders: [
        {text: 'Season', align: 'start', value: 'season'},
        {text: 'League', align: 'start', value: 'league'},
        {text: 'Team', align: 'start', value: 'team'}
      ],

      statusCode: [
        {'short_name': 'NS', 'name': 'Not Started', 'info': 'The initial status of a game'},
        {'short_name': '1st', 'name': '1st Half', 'info': 'The game is currently inplay in the 1st half'},
        {'short_name': 'HT', 'name': 'Half-Time', 'info': 'The game currently is in half-time'},
        {'short_name': 'BRK', 'name': 'Regular time finished', 'info': 'Waiting for penalties to start'},
        {'short_name': 'FT', 'name': 'Full-Time', 'info': 'The game has ended after 90 minutes. When a game goes into extra time, the FT status will be presented for a few seconds and then move to the BREAK status. '},
        {'short_name': '1et', 'name': 'Extra Time', 'info': 'The game currently is in extra time in the 1st half, can happen in knockout games'},
        {'short_name': 'AET', 'name': 'Finished after extra time', 'info': 'The game has finished after 120 minutes'},
        {'short_name': 'FTP', 'name': 'Full-Time after penalties', 'info': 'Finished after penalty shootout'},
        {'short_name': 'PEN', 'name': 'Penalty Shootout', 'info': "ET status didn't get a winner, penalties are taken to determine the winner"},
        {'short_name': 'POST', 'name': 'PostPoned', 'info': 'The game is postponed'},
        {'short_name': 'SUSP', 'name': 'SUSPENDED', 'info': 'The game has suspended and will continue at a later time or day'},
        {'short_name': 'CANC', 'name': 'Cancelled', 'info': 'The game has been cancelled'},
        {'short_name': 'TBA', 'name': 'To Be Announced', 'info': 'The game does not have a confirmed date and time yet. It will be announced later on.'},
        {'short_name': 'WO', 'name': 'Walk Over', 'info': 'Awarding of a victory to a contestant because there are no other contestants'},
        {'short_name': 'ABAN', 'name': 'Abandoned', 'info': 'The game has abandoned and will continue at a later time or day'},
        {'short_name': 'DELA', 'name': 'Delayed', 'info': 'The game is delayed so it will start later'},
        {'short_name': 'AWAR', 'name': 'Awarded', 'info': 'Winner is being decided externally'},
        {'short_name': 'INT', 'name': 'Interrupted', 'info': 'The game has been interrupted. Can be due to bad weather'},
        {'short_name': 'AU', 'name': 'Awaiting Updates', 'info': 'Can occur when there is a connectivity issue or something'},
        {'short_name': 'DEL', 'name': 'Deleted', 'info': 'Game is not available anymore via normal api calls because it has been replaced. This can happen in leagues that have a lot of changes in their schedules. The games can still be retrieved by adding deleted=1 to your request so you can update your system properly'},
        {'short_name': 'ETB', 'name': 'Extra Time - Break', 'info': 'The game has suspended and will continue at a later time or day'},
        {'short_name': '2nd', 'name': '2nd Half', 'info': 'The game is currently inplay in the 2nd half'},
        {'short_name': '2et', 'name': 'ET - 2nd Half', 'info': 'The game currently is in extra time in the 2nd half, can happen in knockout games'},
        {},
        {'short_name': 'PENB', 'name': 'Penalties - Break', 'info': 'Waiting for penalties to start'},
        {'short_name': 'PEN', 'name': 'Pending', 'info': 'The fixture is awaiting an update'}
      ],
      
      tabInd: 0,
      f: false,
    }
  },

  watch: {
    chosenSeason4Fixture: function (val) {
      this.tabLoaded = false

      let tmp = this.allSeasons.filter(item => item.season_name == val)

      let param = ""
      tmp.forEach((element, ind) => {
        if (ind) {
          param += (',' + element.season_id)
        } else {
          param += element.season_id
        }
      })

      axios
        .get('/v3/coach/fixture/' + this.$route.params.id + '/' + param,
          { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
        .then(response => {
          if (response.data.type == 0) {
            this.message = "notsubscribe"
          }else if (response.data.type == 1) {
            this.message = "expiredsubscribe"
          }else {
            let res = _.orderBy(response.data.fixture, ['starting_at_timestamp'], ['desc'])
            res.forEach(fx => {
              if (fx.participants[0].meta.location == "away") {
                fx.participants = [fx.participants[1], fx.participants[0]]
              }
              
              let new_score = {goal: [], penalty: []}
            
              fx.scores.forEach((sc) => {
                if (sc.type_id == 1525) {
                  if (sc.score.participant == "home") {
                    new_score.goal[0] = sc.score.goals
                  } else {
                    new_score.goal[1] = sc.score.goals
                  }
                } else if (sc.type_id == 5) {
                  if (sc.score.participant == "home") {
                    new_score.penalty[0] = sc.score.goals
                  } else {
                    new_score.penalty[1] = sc.score.goals
                  }
                }
              })

              fx.scores = new_score
            })

            this.fixtures = res
          }
          this.tabLoaded = true
        })
        .catch(error => {
          if (error.response.status == 401) {
            localStorage.removeItem('token')
            localStorage.removeItem('uid')
            location.reload()
          }              
        })
    },
  },

  beforeCreate: function () {
    axios
      .get('/v3/coach/info/' + this.$route.params.id,
        { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
      .then(response => {
        if (response.data.type == 0) {
          this.message = "notsubscribe"
        }else if (response.data.type == 1) {
          this.message = "expiredsubscribe"
        }else {
          this.coach = response.data
          
          this.teams = _.orderBy(this.coach.teams, ['end'], ['desc'])
          this.upcomings = this.modifyUpcoming(response.data.fixtures)
          this.transfers = _.orderBy(this.coach.player.transfers, ['date'], ['desc'])
          this.playerTrophies = this.coach.player.trophies.length ? this.modifyTrophies(this.coach.player.trophies) : null

          this.modifySeason(response.data.statistics)

          this.authorization = true
        }
        this.infoLoaded = true
      })
      .catch(error => {
        if (error.response.status == 401) {
          localStorage.removeItem('token')
          localStorage.removeItem('uid')
          location.reload()
        }              
      })
  },
  mounted() {
  },
  methods: {
    /*
      method for modify upcoming array
    */
    modifyUpcoming(arr) {
      arr.forEach(fx => {
        if (fx.participants[0].meta.location == "away") {
          fx.participants = [fx.participants[1], fx.participants[0]]
        }
      })

      return _.orderBy(arr, ['starting_at_timestamp'], ['asc'])
    },

    /*
      event for tab clicks
    */
    tabChange() {
      this.tabLoaded = false
      if (this.tabInd == 1) {
        if (this.fixtures) {
         this.tabLoaded = true
        } else {
          this.tabLoaded = false

          this.chosenSeason4Fixture = this.season4Fixture[0]
        }
      }
      
      if (this.tabInd == 2) {
        if (this.stats) {
         this.tabLoaded = true
        } else {
          this.tabLoaded = false

          axios
            .get('/v3/coach/stats/' + this.$route.params.id,
              { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
            .then(response => {
              if (response.data.type == 0) {
                this.message = "notsubscribe"
              }else if (response.data.type == 1) {
                this.message = "expiredsubscribe"
              }else {
                this.stats = this.modifyStats(response.data.stats)
              }
              this.tabLoaded = true
            })
            .catch(error => {
              if (error.response.status == 401) {
                localStorage.removeItem('token')
                localStorage.removeItem('uid')
                location.reload()
              }
            })
        }
      }
    },

    modifyStats(arr) {
      let tmp = []
      
      arr.forEach((element, ind) => {
        let detail = {
          redcards: {count: 0, average: 0.0},
          yellowcards: {count: 0, average: 0.0},
          yellowredcards: {count: 0, average: 0.0},
          substitutions: {count: 0, average: 0.0},
          team_wins: 0,
          team_draws: 0,
          team_lost: 0,
          matches: 0,
        }

        element.details.forEach(item => {
          switch (item.type_id) {
            case 83:
              detail.redcards = item.value
              break
            case 84:
              detail.yellowcards = item.value
              break
            case 85:
              detail.yellowredcards = item.value
              break
            case 214:
              detail.team_wins = item.value.count
              break
            case 215:
              detail.team_draws = item.value.count
              break
            case 216:
              detail.team_lost = item.value.count
              break
            case 188:
              detail.matches = item.value.count
              break
            case 59:
              detail.substitutions = item.value
              break
          }
        })

        tmp.push({
          key: ind,
          team: element.team,
          league: { id: element.season.league_id, name: element.season.league.name },
          season: element.season.name,
          details: detail
        })
      })

      return _.orderBy(tmp, ['season'], ['desc'])
    },

    /*
      event for show stats Dialog
    */
    showStats(ind) {
      this.curSeason = ind
      this.statsDialog = true
    },

    /*
      method for modify player Trophies array
    */
    modifyTrophies(arr) {
      let result = new Map()

      arr.forEach(element => {
        if (!element.league) return

        let tmp = result.get(element.league_id)

        if (tmp) {
          tmp.trophy[element.trophy_id - 1].push({
            id: element.season_id,
            name: element.season.name
          })
          result.set(element.league_id, tmp)
        } else {
          let trp = [[], []]

          trp[element.trophy_id - 1] = [{
            id: element.season_id,
            name: element.season.name
          }]

          result.set(element.league_id, {
            league_name: element.league.name,
            league_logo: element.league.image_path,
            trophy: trp
          })
        }
      })
      
      let res = [...result.entries()].sort((a, b) => 
        (b[1].trophy[0].length - a[1].trophy[0].length) || (b[1].trophy[1].length - a[1].trophy[1].length))
      
      res.forEach(element => {
        element[1].trophy[0] = _.orderBy(element[1].trophy[0], ['name'], ['desc'])
        element[1].trophy[1] = _.orderBy(element[1].trophy[1], ['name'], ['desc'])
      })

      return res
    },

    modifySeason(arr) {
      let all = []

      let ss = []

      arr.forEach(element => {
        all.push({
          season_id: element.season_id,
          season_name: element.season.name,
          league_id: element.season.league_id,
          league_name: element.season.league.name,
          league_logo: element.season.league.image_path,
          team_id: element.team_id,
          team_name: element.team.name,
        })

        if (!ss.includes(element.season.name)) {
          ss.push(element.season.name)
        }
      })

      this.season4Fixture = ss.sort().reverse()
      this.chosenSeason4Fixture = ss[0]

      this.allSeasons = all
    },

    matchDetail(id) {
      let routeData = this.$router.resolve({name: 'fixture', params: {fid: id}})
      window.open(routeData.href, '_blank')
    },

    upcomingDetail(id, cid, state) {
      if (state == 1 || (state > 9 && state < 21)) {
        let routeData = this.$router.resolve({name: 'soccermatch', query: {mid: id, cid: cid}})
        window.open(routeData.href, '_blank')
      } else {
        let routeData = this.$router.resolve({name: 'fixture', params: {fid: id}})
        window.open(routeData.href, '_blank')
      }
    },

    trophyModal(ind) {
      this.index4trophy = ind
      this.trophyDialog = true
    },
  }
}
</script>