<template>
  <v-app>
    <v-dialog
      v-if="coach"
      v-model="coachDialog"
      width="fit-content"
      style="z-index: 2000">
      <v-card>
        <div class="d-flex justify-center pt-3">
          <img v-if="coach.coach.image_path" :src="coach.coach.image_path" onerror="this.src='/img/default-avatar.png'">
        </div>

        <div class="mt-2 p-2">
          {{ $t('commonname') }} : <b>{{ coach.coach.common_name }}</b><br>
          {{ $t('fullname') }} : <b>{{ coach.coach.name }}</b><br>
          {{ $t('birthdate') }} : <b>{{ coach.coach.date_of_birth }}</b><br>
          {{ $t('country') }} : <b>{{ coach.coach.country.name }}</b><br>
          {{ $t('start') }} : <b>{{ coach.start }}</b><br>
          {{ $t('end') }} : <b>{{ coach.end }}</b><br>
        </div>

        <div class="d-flex justify-center">
          <v-btn
            color="cyan"
            dark
            @click="coachDetail(coach.coach_id)"
            class="m-2"
            small
          >
            {{ $t('moredetails') }}..
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="venue"
      v-model="venueDialog"
      width="fit-content"
      style="z-index: 2000">
      <v-card>
        <img v-if="venue.image_path" :src="venue.image_path">
        
        <div class="mt-2 px-3">
          {{ $t('name') }} : <b>{{ venue.name }}</b><br>
          {{ $t('surface') }} : <b>{{ venue.surface }}</b><br>
          {{ $t('city') }} : <b>{{ venue.city_name }}</b><br>
          {{ $t('address') }} : <b>{{ venue.address }}</b><br>
          {{ $t('capacity') }} : <b>{{ venue.capacity }}</b>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="subCornerDialog"
      width="fit-content"
      style="z-index: 2000">
      <v-simple-table
        class="table-row-35">
        <thead>
          <tr>
            <!-- <th class="text-center">{{ subCorner.dt }}</th> -->
            <th class="text-center">{{ subCorner.home }}</th>
            <th class="text-center">{{ subCorner.away }}</th>
          </tr>
        </thead>
        
        <tbody class="text-center">
          <tr v-for="(Evt, index) in subCorner.data" :key="index">
            <template v-if="Evt.comment != 'X'">
            <!-- <td>{{ Evt.comment }}</td> -->
              <td>{{ Evt.h_m }}</td>
              <td>{{ Evt.a_m }}</td>
            </template>
          </tr>
        </tbody>
      </v-simple-table>
    </v-dialog>

    <v-dialog
      v-model="subGoalDialog"
      width="fit-content"
      style="z-index: 2000">
      <v-simple-table
        class="table-row-35">
        <thead>
          <tr>
            <th class="text-center">{{ subGoal.dt }}</th>
            <th class="text-center">{{ $t('player') }}</th>
            <th class="text-center">{{ subGoal.home }}</th>
            <th class="text-center">{{ subGoal.away }}</th>
          </tr>
        </thead>

        <tbody class="text-center">
          <tr v-for="(Evt, index) in subGoal.data" :key="index">
            <td>{{ Evt.minute }}</td>

            <td>
              <router-link :to="{name: 'player', params: {pid: Evt.player_id}}" class="blue-grey--text">
                {{ Evt.player }}
              </router-link>
            </td>

            <td>
              <img v-if="Evt.h_g=='penalty'" src="/img/icons/penalty.png" class="h-20" title="Penalty">
              <img v-else-if="Evt.h_g=='goal' || Evt.h_g=='own-goal'" src="/img/icons/goal.png" class="h-20">
            </td>

            <td>
              <img v-if="Evt.a_g=='penalty'" src="/img/icons/penalty.png" class="h-20" title="Penalty">
              <img v-else-if="Evt.a_g=='goal' || Evt.a_g=='own-goal'" src="/img/icons/goal.png" class="h-20">
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-dialog>

    <v-dialog
      v-model="subCardDialog"
      width="fit-content"
      style="z-index: 2000">
      <v-simple-table
        class="table-row-35">
        <thead>
          <tr>
            <th class="text-center">{{ subCard.dt }}</th>
            <th class="text-center">{{ $t('player') }}</th>
            <th class="text-center">{{ subCard.home }}</th>
            <th class="text-center">{{ subCard.away }}</th>
          </tr>
        </thead>

        <tbody class="text-center">
          <tr v-for="(Evt, index) in subCard.data" :key="index">
            <td>{{ Evt.minute }}</td>

            <td>
              <router-link :to="{name: 'player', params: {pid: Evt.player_id}}" class="blue-grey--text">
                {{ Evt.player }}
              </router-link>
            </td>

            <td>
              <img v-if="Evt.h_c=='yellowcard'" src="/img/icons/yellow_card.png">
              <img v-else-if="Evt.h_c=='yellowred' || Evt.h_c=='redcard'" src="/img/icons/red_card.png">
            </td>

            <td>
              <img v-if="Evt.a_c=='yellowcard'" src="/img/icons/yellow_card.png">
              <img v-else-if="Evt.a_c=='yellowred' || Evt.a_c=='redcard'" src="/img/icons/red_card.png">
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-dialog>

    <v-dialog
      v-if="player"
      v-model="playerDialog"
      width="550"
      style="z-index: 2000">
      <v-card>
        <v-card-title class="d-flex flex-nowrap justify-space-between">
          <div class="d-grid">
            <span class="subtitle-1 green--text text--darken-2">{{ player.player.common_name }}</span>
            
            <div class="subtitle-1 grey--text text--lighten-1">
              <template v-if="player.position_id">{{ $t(`lineup_type.${position[player.position_id - 24]}`) }}</template>
              
              <span
                v-if="$vuetify.breakpoint.xs && player.jersey_number"
                class="h5 font-weight-bold blue--text text--lighten-3">
                {{ player.jersey_number }}
              </span>
            </div>
          </div>

          <div>
            <span
              v-if="$vuetify.breakpoint.smAndUp && player.jersey_number"
              class="h2 font-weight-bold blue--text text--lighten-3">
              {{ player.jersey_number }}
            </span>
          
            <img
              class="rounded-circle"
              :class="{'wh-70': $vuetify.breakpoint.xs, 'wh-100': $vuetify.breakpoint.smAndUp}"
              :src="player.player ? player.player.image_path : '/img/default-avatar.png'"
              onerror="this.src='/img/default-avatar.png'"
            >
          </div>
        </v-card-title>

        <v-divider class="ma-0"></v-divider>

        <v-card-text>
          <div v-if="player.player" class="row no-gutters justify-space-around caption">
            <v-col v-if="player.player.country" class="d-grid" cols="auto">
              <span class="grey--text text--darken-1">{{ $t('country') }}</span>
              <span class="green--text text--darken-2">{{ player.player.country.name }}</span>
            </v-col>

            <v-col class="d-grid" cols="auto">
              <span class="grey--text text--darken-1">{{ $t('height') }}/{{ $t('weight') }}</span>
              <span class="green--text text--darken-2">{{ player.player.height }}cm/{{ player.player.weight }}kg</span>
            </v-col>

            <v-col class="d-grid" cols="auto">
              <span class="grey--text text--darken-1">{{ $t('birthdate') }}</span>
              <span class="green--text text--darken-2">{{ player.player.date_of_birth }}</span>
            </v-col>
          </div>

          <v-simple-table class="table-row-35" height="200px">
            <tbody>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.APPEARANCES') }}:</td>
                <td class="text-right green--text">{{ player.details.other.appearances }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.MINUTES_PLAYED') }}:</td>
                <td class="text-right green--text">{{ player.details.other.minutes_played }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.RATING') }}:</td>
                <td class="text-right title red--text text--darken-4">{{ player.details.rating }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.GOALS') }}:</td>
                <td class="text-right green--text">{{ player.details.goals.scored }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.avg_goals_per_90') }}:</td>
                <td class="text-right green--text">
                  <template v-if="player.details.other.minutes_played">
                    {{ (player.details.goals.scored / player.details.other.minutes_played * 90).toFixed(1) }}
                  </template>
                </td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.avg_mins_per_goal') }}:</td>
                <td class="text-right green--text">
                  <template v-if="player.details.goals.scored">
                    {{ (player.details.other.minutes_played / player.details.goals.scored).toFixed(0) }}
                  </template>
                </td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.ASSISTS') }}:</td>
                <td class="text-right green--text">{{ player.details.goals.assists }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.avg_assists_per_90') }}:</td>
                <td class="text-right green--text">
                  <template v-if="player.details.other.minutes_played">
                    {{ (player.details.goals.assists / player.details.other.minutes_played * 90).toFixed(1) }}
                  </template>
                </td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.avg_mins_per_assist') }}:</td>
                <td class="text-right green--text">
                  <template v-if="player.details.goals.assists">
                    {{ (player.details.other.minutes_played / player.details.goals.assists).toFixed(0) }}
                  </template>
                </td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.OWN_GOALS') }}:</td>
                <td class="text-right green--text">{{ player.details.goals.owngoals }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.avg_owngoals_per_90') }}:</td>
                <td class="text-right green--text">
                  <template v-if="player.details.other.minutes_played">
                    {{ (player.details.goals.owngoals / player.details.other.minutes_played * 90).toFixed(1) }}
                  </template>
                </td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.avg_mins_per_owngoal') }}:</td>
                <td class="text-right green--text">
                  <template v-if="player.details.goals.owngoals">
                    {{ (player.details.other.minutes_played / player.details.goals.owngoals).toFixed(0) }}
                  </template>
                </td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.LINEUPS') }}:</td>
                <td class="text-right green--text">{{ player.details.other.lineups }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.SUBSTITUTIONS_IN') }}:</td>
                <td class="text-right green--text">{{ player.details.substitutions.in }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.SUBSTITUTIONS_OUT') }}:</td>
                <td class="text-right green--text">{{ player.details.substitutions.out }}</td>
              </tr>
              <!-- <tr>
                <td class="grey--text text--darken-1">Substitute-On-Bench:</td>
                <td class="text-right green--text">{{ player.substitutes_on_bench }}</td>
              </tr> -->
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.CAPTAIN') }}:</td>
                <td class="text-right green--text">{{ player.details.other.captain }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.SHOTS_TOTAL') }}:</td>
                <td class="text-right green--text">{{ player.details.shots.shots_total }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.SHOTS_ON_TARGET') }}:</td>
                <td class="text-right green--text">{{ player.details.shots.shots_on_target }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.PASSES_TOTAL') }}:</td>
                <td class="text-right green--text">{{ player.details.passing.passes }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.PASSES_ACCURATE') }}:</td>
                <td class="text-right green--text">{{ player.details.passing.accurate_passes }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.ACCURATE_PASSES_PERCENTAGE') }}:</td>
                <td class="text-right green--text">{{ player.details.passing.accurate_passes_percentage }}%</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.BIG_CHANCES_MISSED') }}:</td>
                <td class="text-right green--text">{{ player.details.other.big_chances_missed }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.KEY_PASSES') }}:</td>
                <td class="text-right green--text">{{ player.details.passing.key_passes }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.DRIBBLE_ATTEMPTS') }}:</td>
                <td class="text-right green--text">{{ player.details.dribbles.attempts }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.SUCCESSFUL_DRIBBLES') }}:</td>
                <td class="text-right green--text">{{ player.details.dribbles.success }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.DRIBBLED_PAST') }}:</td>
                <td class="text-right green--text">{{ player.details.dribbles.dribbled_past }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.DISPOSSESSED') }}:</td>
                <td class="text-right green--text">{{ player.details.other.dispossesed }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.INTERCEPTIONS') }}:</td>
                <td class="text-right green--text">{{ player.details.other.interceptions }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.TACKLES') }}:</td>
                <td class="text-right green--text">{{ player.details.other.tackles }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.BLOCKED_SHOTS') }}:</td>
                <td class="text-right green--text">{{ player.details.other.blocked_shots }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.CLEARANCES') }}:</td>
                <td class="text-right green--text">{{ player.details.other.clearances }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.SAVES') }}:</td>
                <td class="text-right green--text">{{ player.details.other.saves }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.SAVES_INSIDE_BOX') }}:</td>
                <td class="text-right green--text">{{ player.details.other.saves_insidebox }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.YELLOWCARDS') }}:</td>
                <td class="text-right green--text">{{ player.details.cards.yellowcards + player.details.cards.yellowredcards }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.avg_yellowcards_per_90') }}:</td>
                <td class="text-right green--text">
                  <template v-if="player.details.other.minutes_played">
                    {{ ((player.details.cards.yellowcards + player.details.cards.yellowredcards) / player.details.other.minutes_played * 90).toFixed(1) }}
                  </template>
                </td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.avg_mins_per_yellowcard') }}:</td>
                <td class="text-right green--text">
                  <template v-if="player.details.cards.yellowcards">
                    {{ (player.details.other.minutes_played / (player.details.cards.yellowcards + player.details.cards.yellowredcards)).toFixed(0) }}
                  </template>
                </td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.YELLOWRED_CARDS') }}:</td>
                <td class="text-right green--text">{{ player.details.cards.yellowredcards }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.REDCARDS') }}:</td>
                <td class="text-right green--text">{{ player.details.cards.redcards }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.avg_redcards_per_90') }}:</td>
                <td class="text-right green--text">
                  <template v-if="player.details.other.minutes_played">
                    {{ (player.details.cards.redcards / player.details.other.minutes_played * 90).toFixed(1) }}
                  </template>
                </td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.avg_mins_per_redcard') }}:</td>
                <td class="text-right green--text">
                  <template v-if="player.details.cards.redcards">
                    {{ (player.details.other.minutes_played / player.details.cards.redcards).toFixed(0) }}
                  </template>
                </td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.FOULS_COMMITTED') }}:</td>
                <td class="text-right green--text">{{ player.details.fouls.committed }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.FOULS_DRAWN') }}:</td>
                <td class="text-right green--text">{{ player.details.fouls.drawn }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.TOTAL_CROSSES') }}:</td>
                <td class="text-right green--text">{{ player.details.passing.total_crosses }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.ACCURATE_CROSSES') }}:</td>
                <td class="text-right green--text">{{ player.details.passing.accurate_crosses }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.TOTAL_DUELS') }}:</td>
                <td class="text-right green--text">{{ player.details.duels.total }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.DUELS_WON') }}:</td>
                <td class="text-right green--text">{{ player.details.duels.won }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.PENALTIES_SCORED') }}:</td>
                <td class="text-right green--text">{{ player.details.goals.penalties }}</td>
              </tr>
              <!-- <tr>
                <td class="grey--text text--darken-1">Penalties-Won:</td>
                <td class="text-right green--text">{{ player.details.other.pen_won }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">Penalties-Missed:</td>
                <td class="text-right green--text">{{ player.details.other.pen_missed }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">Penalties-Committed:</td>
                <td class="text-right green--text">{{ player.details.other.pen_committed }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">Penalties-Saves:</td>
                <td class="text-right green--text">{{ player.details.other.pen_saved }}</td>
              </tr> -->
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.AERIALS_WON') }}:</td>
                <td class="text-right green--text">{{ player.details.other.aerials_won }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.HIT_WOODWORK') }}:</td>
                <td class="text-right green--text">{{ player.details.other.hit_woodwork }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.OFFSIDES') }}:</td>
                <td class="text-right green--text">{{ player.details.other.offsides }}</td>
              </tr>
              <tr>
                <td class="grey--text text--darken-1">{{ $t('stats_type.PUNCHES') }}:</td>
                <td class="text-right green--text">{{ player.details.other.punches }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="cyan"
            dark
            @click="playerDetail(player.player_id)"
            :small="$vuetify.breakpoint.xs"
          >
            {{ $t('moredetails') }}
          </v-btn>

          <v-btn
            color="warning"
            dark
            @click="playerDialog = false"
            :small="$vuetify.breakpoint.xs"
          >
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="trophies"
      v-model="trophyDialog"
      width="fit-content"
      style="z-index: 2000">
      <v-card>
        <div class="d-flex justify-center align-center p-3">
          <img
            v-if="trophies[index4trophy][1].league_logo"
            :src="trophies[index4trophy][1].league_logo"
            onerror="this.src='/img/default-avatar.png'"
            style="width: 70px; height: 70px;">

          <div class="d-flex flex-column">
            <div v-if="trophies[index4trophy][1].trophy[0].length" class="p-2">
              <span>{{ $t('winner') }}:</span>
              
              <div class="ml-5 d-flex flex-wrap">
                <span
                  v-for="cup in trophies[index4trophy][1].trophy[0]"
                  :key="cup.id"
                  class="mr-1 px-1"
                  style="border: 1px solid lightgrey;">
                  {{ cup.name }}
                </span>
              </div>
            </div>

            <div v-if="trophies[index4trophy][1].trophy[1].length" class="p-2">
              <span>{{ $t('runnerup') }}:</span>
              
              <div class="ml-5 d-flex flex-wrap">
                <span
                  v-for="cup in trophies[index4trophy][1].trophy[1]"
                  :key="cup.id"
                  class="mr-1 px-1"
                  style="border: 1px solid lightgrey;">
                  {{ cup.name }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="refereeDialog"
      width="fit-content"
      style="z-index: 2000">
      <v-card>
        <template v-if="chosenReferee">
          <div class="d-flex justify-center pt-3">
            <img
              v-if="chosenReferee.image_path"
              :src="chosenReferee.image_path"
              class="wh-70"
              onerror="this.src='/img/default-avatar.png'">

            <div class="mt-2 p-2">
              <template v-if="chosenReferee.name">
                {{ $t('name') }} : <b>{{ chosenReferee.name }}</b><br>
              </template>

              <template v-if="chosenReferee.date_of_birth">
                {{ $t('birthdate') }} : <b>{{ chosenReferee.date_of_birth }}</b><br>
              </template>

              <template v-if="chosenReferee.country">
                {{ $t('country') }} : <b>{{ chosenReferee.country.name }}</b><br>
              </template>
            </div>
          </div>
          
          <div class="d-flex justify-center">
            <template v-if="chosenReferee.statistics.length">
              <v-simple-table class="table-row-35 green-top-3">
                <thead>
                  <tr class="white">
                    <th style="border-right: 1px solid lightgrey">{{ $t('season') }}</th>
                    <th style="border-right: 1px solid lightgrey">{{ $t('league') }}</th>
                    <th class="text-center" style="border-right: 1px solid lightgrey" :title="$t('m.tooltip')">{{ $t('m.label') }}</th>
                    <th class="text-center" style="border-right: 1px solid lightgrey" :title="$t('f.tooltip')">{{ $t('f.label') }}</th>

                    <th class="text-center" style="border-right: 1px solid lightgrey" :title="$t('penalty')">
                      <img
                        src="/img/icons/penalty.png"
                        style="width: 15px"
                        :title="$t('penalty')">
                    </th>
                    
                    <th class="text-center" style="border-right: 1px solid lightgrey" :title="$t('yellowcard')">
                      <img
                        src="/img/icons/yellow_card.png"
                        :title="$t('yellowcard')">
                    </th>

                    <th class="text-center" style="border-right: 1px solid lightgrey" :title="$t('redcard')">
                      <img
                        src="/img/icons/red_card.png"
                        :title="$t('redcard')">
                    </th>

                    <th class="text-center" style="border-right: 1px solid lightgrey" :title="$t('var')">
                      <v-icon color="red" class="subtitle-2">mdi-monitor-eye</v-icon>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="st in chosenReferee.statistics"
                    :key="st.id"
                  >
                    <td style="border-right: 1px solid lightgrey">{{ st.season.name }}</td>
                    <td style="border-right: 1px solid lightgrey">{{ st.season.league.name }}</td>
                    
                    <td class="text-end" style="border-right: 1px solid lightgrey" :title="$t('m.tooltip')">
                      <template v-if="st.details.matches">{{ st.details.matches }}</template>
                    </td>

                    <td class="text-end" style="border-right: 1px solid lightgrey" :title="$t('f.tooltip')">
                      <template v-if="st.details.fouls">{{ st.details.fouls.count }}</template>
                    </td>

                    <td class="text-end" style="border-right: 1px solid lightgrey" :title="$t('penalty')">
                      <template v-if="st.details.penalties">
                        {{ st.details.penalties.count }}
                      </template>
                    </td>

                    <td class="text-end" style="border-right: 1px solid lightgrey" :title="$t('yellowcard')">
                      <template v-if="st.details.yellowcards">{{ st.details.yellowcards.count }}</template>
                    </td>

                    <td class="text-end" style="border-right: 1px solid lightgrey" :title="$t('redcard')">
                      <template v-if="st.details.redcards">{{ st.details.redcards.count }}</template>
                    </td>

                    <td class="text-end" style="border-right: 1px solid lightgrey" :title="$t('var')">
                      <template v-if="st.details.var_moments">{{ st.details.var_moments.count }}</template>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </template>

            <span v-else>{{ $t('message.norefereestats') }}</span>
          </div>
        </template>
        
        <div v-else class="ripple_loading wh-70">
          <img src="/img/icons/Ripple-1s-200px.png">
        </div>
      </v-card>
    </v-dialog>

    <div>
      <template v-if="!basicLoaded">
        <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
      </template>

      <template v-else>
        <div class="row no-gutters justify-center light-banner pt-50 background-cover">
          <div class="d-flex py-3">
            <img
              class="banner_logo1 align-self-center"
              :src="team.logo"
              :title="team.name"
              onerror="style.display='none'"
            >

            <div class="ml-4 d-flex flex-column justify-space-around">
              <div class="text-center">
                <span
                  class="font-weight-bold green--text text--darken-2"
                  :class="{'subtitle-1': $vuetify.breakpoint.xs, 'h4': $vuetify.breakpoint.smAndUp}">
                  {{ team.name }}</span>
              </div>

              <div v-if="team.type != 'national'" class="text-center">
                <router-link :to="{name: 'country', params: {cid: country.id}}">
                  <img
                    class="country_logo elevation-5 mr-3"
                    :class="{'d-none': $vuetify.breakpoint.xs}"
                    :src="country.flag"
                    onerror="style.display='none'" />
                  
                  <span class="red--text text--darken-4">{{ country.name }}</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        
        <div class="justify-center">
          <div class="py-0">
            <v-tabs
              v-model="tab"
              centered
              show-arrows
              @change="tabChange"
              light
            >
              <v-tab
                v-for="item in items"
                :key="item"
              >
                <span style="font-size: medium" class="text-capitalize">{{ $t(item) }}</span>
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item key="Basics">
                <div class="row justify-center">
                  <div class="col-md-10 col-lg-8 p-3">
                    <div v-if="leagues.length" class="my-2 p-1 d-flex">
                      <span class="mr-10 align-center grey--text text--darken-1 font-weight-bold">{{ $t('leaguesattended') }}: </span>

                      <div class="d-flex flex-wrap">
                        <router-link
                          v-for="lg in leagues"
                          :key="lg.league_id"
                          :to="{name: 'league', params: {lid: lg.league_id}}"
                          class="w-fit-content elevation-1 m-1 px-1 curPointer"
                          style="border: 1px solid lightgrey; border-radius: 2px;"
                        >
                          <!-- <img
                            class="league_logo"
                            :src="lg.logo"
                            :title="league.name"
                            onerror="style.display='none'"
                          > -->
                          
                          <span class="blue-grey--text subtitle-2">{{ lg.league_name }}</span>
                          <span class="green white--text ml-1 px-1 caption" style="border-radius: 25%">{{ lg.attendence }}</span>
                        </router-link>
                      </div>
                    </div>

                    <div v-if="trophies" class="my-2 p-1 d-flex">
                      <span class="mr-10 align-center grey--text text--darken-1 font-weight-bold">{{ $t('trophy') }}: </span>

                      <div class="d-flex flex-wrap">
                        <a
                          v-for="(cup, ind) in trophies"
                          :key="cup[0]"
                          class="w-fit-content elevation-1 m-1 px-1 curPointer"
                          style="border: 1px solid lightgrey; border-radius: 2px;"
                          @click="trophyModal(ind)">
                          <img
                            class="mx-1 wh-20"
                            :src="cup[1].league_logo"
                            :title="cup[1].league_name"
                            onerror="style.display='none'"
                          >

                          <span class="blue-grey--text subtitle-2">{{ cup[1].league_name }}</span>
                          <span v-if="cup[1].trophy[0].length" class="red white--text ml-1 px-1 caption" style="border-radius: 25%" :title="$t('winner')">{{ cup[1].trophy[0].length }}</span>
                          <span v-if="cup[1].trophy[1].length" class="blue white--text ml-1 px-1 caption" style="border-radius: 25%" :title="$t('runnerup')">{{ cup[1].trophy[1].length }}</span>
                        </a>
                      </div>
                    </div>

                    <div class="my-2 p-1 d-flex flex-wrap justify-space-between">
                      <div v-if="allCoaches && allCoaches.length" class="d-flex align-center my-1">
                        <span class="mr-10 grey--text text--darken-1 font-weight-bold">{{ $t('coach') }}: </span>

                        <img
                          class="mx-1 wh-70 curPointer"
                          :class="$vuetify.breakpoint.xs ? 'd-none' : ''"
                          :src="allCoaches[0].coach.image_path"
                          @click="coachModal(0)"
                          style="border: 1px solid lightgrey;"
                          onerror="style.display='none'"
                        >

                        <div class="d-flex flex-column">
                          <a v-for="(element, ind) in allCoaches" :key="ind" @click="coachModal(ind)" class="curPointer">
                            {{ element.coach.common_name }}
                            
                            <span class="grey--text ml-2 float-right"> {{ element.start }} - {{ element.end }}</span>
                            <!-- <v-icon class="mdi-24px">mdi-help-circle-outline</v-icon> -->
                          </a>
                        </div>
                      </div>

                      <div class="d-flex align-center my-1">
                        <span class="mr-10 grey--text text--darken-1 font-weight-bold">{{ $t('venue') }}: </span>

                        <img
                          class="mx-1 wh-120 curPointer"
                          :class="$vuetify.breakpoint.xs ? 'd-none' : ''"
                          :src="venue.image_path"
                          @click="venueModal()"
                          style="border: 1px solid lightgrey;"
                          onerror="style.display='none'"
                        >

                        <a @click="venueModal()" class="curPointer" title="Stadium">
                          {{ venue.name }}<v-icon class="ml-2">mdi-stadium</v-icon>
                        </a>
                      </div>

                      <div class="d-flex align-center my-1">
                        <span class="mr-10 grey--text text--darken-1 font-weight-bold ">{{ $t('founded') }}: </span>
                        <span class="red--text text--darken-4 font-weight-bold">{{ team.founded }}</span>
                      </div>
                    </div>

                    <div class="my-2 p-1 d-flex flex-wrap ">
                      <div v-if="rivals" class="d-flex my-1">
                        <span class="mr-10 align-center grey--text text--darken-1 font-weight-bold">{{ $t('rival') }}: </span>

                        <div class="d-flex flex-wrap">
                          <router-link
                            v-for="el in rivals"
                            :key="el.id"
                            :to="{name: 'team', params: {id: el.id}}"
                            class="d-flex align-center w-fit-content elevation-1 m-1 p-1 curPointer"
                            style="border: 1px solid lightgrey; border-radius: 2px;"
                            :title="el.short_code"
                          >
                            <img
                              class="mx-1 wh-20"
                              :src="el.image_path"
                              onerror="style.display='none'"
                            >

                            <span class="blue-grey--text subtitle-2">{{ el.name }}</span>
                          </router-link>
                        </div>
                      </div>
                    </div>

                    <div v-if="current_leagues" class="my-2 p-1 d-flex">
                      <span class="mr-10 align-center grey--text text--darken-1 font-weight-bold">{{ $t('activeseasons') }}: </span>

                      <div class="d-flex flex-wrap">
                        <router-link
                          v-for="lg in current_leagues"
                          :key="lg.league_id"
                          :to="{name: 'league', params: {lid: lg.league_id}}"
                          class="d-flex justify-center align-center w-fit-content elevation-1 m-1 p-1 curPointer"
                          style="border: 1px solid lightgrey; border-radius: 2px;"
                        >
                          <img
                            class="mx-1 wh-20"
                            :src="lg.league.image_path"
                            :title="lg.league.name"
                            onerror="style.display='none'"
                          >
                          <span class="blue-grey--text subtitle-2">{{ lg.league.name }}</span>
                        </router-link>
                      </div>
                    </div>                   

                    <v-simple-table v-if="upcomings && upcomings.length" class="my-2 table-row-30 elevation-2">
                      <thead style="background: lavender">
                        <tr class="green-top-3 lightgrey-bottom-2">
                          <th colspan="6">
                            <span class="grey--text text--darken-1 float-left font-weight-bold ml-5">{{ $t('upcomings') }}</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, ind) in upcomings"
                          :key="ind"
                        >
                          <td class="overflow-anywhere blue-grey--text" :class="{'text-center' : $vuetify.breakpoint.xs}">
                            <template v-if="$vuetify.breakpoint.xs">{{ item.starting_at_timestamp | moment('MM/DD HH:mm')}}</template>
                            <template v-else>{{ item.starting_at_timestamp | moment('YYYY/MM/DD HH:mm')}}</template>
                          </td>

                          <td :class="{'d-none': $vuetify.breakpoint.xs}">
                            <router-link :to="{name: 'league', params: {lid: item.league_id}}">
                              <img
                                :src="item.league.image_path"
                                class="wh-20 mr-1"
                                :title="item.league.name"
                              >

                              <span class="blue-grey--text">{{ item.league.name }}</span>
                            </router-link>
                          </td>

                          <td class="black--text justify-end text-end">
                            <router-link 
                              v-if="item.participants[0].meta.location != item.meta.location"
                              :to="{name: 'team', params: {id: item.participants[0].id}}"
                              class="d-flex align-center justify-end text-end">
                              <span>
                                {{ $vuetify.breakpoint.xs ? ( item.participants[0].short_code ? item.participants[0].short_code : item.participants[0].name.substring(0, 3)) : item.participants[0].name }}
                              </span>
                              
                              <img
                                :src="item.participants[0].image_path"
                                class="wh-20 ml-1"
                                onerror="style.display='none'"
                              >
                            </router-link>

                            <template v-else>
                              <span>
                                {{ $vuetify.breakpoint.xs ? ( item.participants[0].short_code ? item.participants[0].short_code : item.participants[0].name.substring(0, 3)) : item.participants[0].name }}
                              </span>
                              
                              <img :src="team.logo" class="wh-20 ml-1">
                            </template>
                          </td>

                          <td
                            class="text-center"
                            :style="{ 'font-size': $vuetify.breakpoint.xs ? 'xx-small !important' : ''}">
                            <template v-if="item.state_id == 1">
                              <span>VS</span>
                            </template>

                            <template v-else>
                              <span class="red--text" :title="statusCode[item.state_id - 1].name + ': ' + statusCode[item.state_id - 1].info">
                                {{ statusCode[item.state_id - 1].short_name }}
                              </span>
                            </template>
                          </td>

                          <td>
                            <router-link
                              v-if="item.participants[1].meta.location != item.meta.location"
                              :to="{name: 'team', params: {id: item.participants[1].id}}"
                              class="d-flex align-center justify-start text-start">
                              <img
                                :src="item.participants[1].image_path"
                                class="wh-20 mr-1"
                                onerror="style.display='none'"
                              >

                              <span>
                                {{ $vuetify.breakpoint.xs ? ( item.participants[1].short_code ? item.participants[1].short_code : item.participants[1].name.substring(0, 3)) : item.participants[1].name }}
                              </span>
                            </router-link>

                            <template v-else>
                              <img :src="team.logo" class="wh-20 mr-1">

                              <span class="black--text">
                                {{ $vuetify.breakpoint.xs ? ( item.participants[1].short_code ? item.participants[1].short_code : item.participants[1].name.substring(0, 3)) : item.participants[1].name }}
                              </span>
                            </template>
                          </td>

                          <td class="curPointer text-center" :title="$t('moredetails')" @click.stop="upcomingDetail(item.id, item.league.country_id, item.state_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                        </tr>
                      </tbody>
                    </v-simple-table>

                    <v-data-table
                      v-if="sidelined && sidelined.length"
                      :headers="sidelinedHeaders"
                      :items="sidelined"
                      :sort-by="['start_date']"
                      :sort-desc="[true]"
                      :items-per-page="15"
                      class="table-row-30 elevation-2 green-top-3 lightgrey-bottom-2"
                      :mobile-breakpoint="0"
                      hide-default-footer
                      disable-pagination
                    >
                      <template v-slot:item.player="{ item }">
                        <router-link :to="{name: 'player', params: {pid: item.player_id}}">
                          <img
                            class="wh-30" :src="item.player.image_path"
                            onerror="this.src='/img/default-avatar.png'"
                            :title="item.player.name" />
                          
                          <span class="blue-grey--text ml-2">{{ item.player.common_name }}</span>
                        </router-link>
                      </template>

                      <template v-slot:item.description="{ item }">
                        <span class="red--text text--darken-4">{{ item.type.name }} </span>
                      </template>
                    </v-data-table>
                  </div>
                </div>
              </v-tab-item>

              <v-tab-item key="Squad">
                <template v-if="!authorization">
                  <div class="row justify-center">
                    <div class="col-lg-8 col-md-10">
                      <v-alert
                        type="warning"
                        prominent
                      >
                        {{ $t(`message.${message}`) }}
                      </v-alert>
                    </div>
                  </div>
                </template>

                <template v-else>
                  <div class="row justify-center my-1">
                    <div class="col-sm-6 col-md-4 col-lg-4">
                      <v-select
                        v-model="tournamentForPlayers"
                        :items="leagues"
                        item-text="league_name"
                        item-value="league_id"
                        :label="$t('tournament')"
                        attach
                        hide-details
                        dense
                        outlined
                      />
                    </div>

                    <div class="col-sm-3 col-md-2 col-lg-2">
                      <v-select
                        v-model="seasonForPlayers"
                        :items="chosenSeasonsForPlayers"
                        item-text="season_name"
                        item-value="season_id"
                        :label="$t('season')"
                        attach
                        hide-details
                        dense
                        outlined
                      />
                    </div>
                  </div>

                  <div class="align-center">
                    <template v-if="!tabLoaded">
                      <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                    </template>

                    <template v-else-if="squad && squad.length != 0">
                      <div class="d-flex flex-wrap justify-space-around">
                        <template v-for="(item, ind) in squad">
                          <v-card
                            v-if="item.length"
                            :key="ind"
                            elevation="2"
                            class="mb-5"
                            :class="{'mx-1': $vuetify.breakpoint.smAndUp}"
                          >
                            <v-app-bar
                              color="grey"
                              dark
                              dense
                              :height="$vuetify.breakpoint.xs ? 30 : 38"
                            >
                              <v-toolbar-title>
                                <span class="subtitle-1 text-capitalize">{{ $t(`lineup_type.${ind}`) }}</span>
                              </v-toolbar-title>
                            </v-app-bar>

                            <v-card-text>
                              <v-list>
                                <v-list-item
                                  v-for="(member, index) in item"
                                  :key="index"
                                  @click="playerModal(member)"
                                  class="px-1"
                                >
                                  <v-list-item-avatar>
                                    <img :src="member.player.image_path" onerror="this.src='/img/default-avatar.png'" :title="member.player.name" />
                                  </v-list-item-avatar>

                                  <v-list-item-title class="d-flex grey--text text--darken-1 align-center" :class="{'caption': $vuetify.breakpoint.xs}">
                                    <span
                                      class="white-space-nowrap"
                                      style="width: 150px; text-overflow: ellipsis; overflow: hidden; flex: 1;"
                                    >
                                      {{ member.player.common_name }}
                                    </span>

                                    <span class="body-2 font-weight-bold float-right deep-orange--text">{{ member.details.rating }}</span>
                                  </v-list-item-title>

                                  <v-list-item-icon>
                                    <img
                                      class="elevation-1 w-30 h-20"
                                      :src="'/img/flag/'+ member.player.country.id + '.png'"
                                      :title="member.player.country.name"
                                    />
                                  </v-list-item-icon>
                                </v-list-item>
                              </v-list>
                            </v-card-text>
                          </v-card>
                        </template>
                      </div>
                    </template>

                    <v-row v-else justify="center"><v-col align="center">{{ $t('message.nosquad') }}</v-col></v-row>
                  </div>
                </template>                  
              </v-tab-item>

              <v-tab-item key="Goals">
                <div class="row no-gutters justify-center">
                  <div class="col-lg-8 col-md-10">
                    <div v-if="!lastLoaded">
                      <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                    </div>

                    <div v-else-if="!authorization">
                      <v-alert
                        type="warning"
                        prominent
                      >
                        {{ $t(`message.${message}`) }}
                      </v-alert>
                    </div>

                    <template v-else>
                      <v-container fluid>
                        <div v-if="leagueLoaded" class="row d-flex mt-2">
                          <v-select
                            v-model="chosenLeagues"
                            :items="leagues"
                            item-text="league_name"
                            item-value="league_id"
                            class="col-12 col-sm-7 col-lg-9"
                            attach
                            dense
                            :label="$t('tournament')"
                            hide-details
                            multiple
                          />

                          <v-select
                            v-model="chosenSeasons"
                            :items="seasons"
                            :label="$t('season')"
                            class="col-12 col-sm-5 col-lg-3"
                            attach
                            dense
                            hide-details
                            multiple
                          />
                        </div>

                        <div class="row d-flex flex-wrap justify-space-between mt-4">
                          <v-select
                            v-model="fullHalf"
                            :items="timeCategories"
                            :item-text="item => $t(item.text)"
                            item-value="index"
                            :label="$t('time')"
                            class="col-5 col-sm-3 col-md-2"
                            attach
                            dense
                            hide-details
                            />

                          <v-checkbox
                            v-model="fLocal"
                            color="blue"
                            hide-details
                            dense
                            :label="$t('home')"
                            />

                          <v-checkbox
                            v-model="fVisitor"
                            color="blue"
                            hide-details
                            dense
                            :label="$t('away')"
                            />

                          <template v-if="coach && coach.coach_id">
                            <v-checkbox
                              v-model="currentCoach"
                              color="blue"
                              hide-details
                              dense
                              :label="`${$t('currentcoach')}(${coach_count})`"
                              />
                            <v-checkbox
                              v-model="prevCoach"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('previouscoach')"
                              />
                          </template>

                          <v-select
                            v-model="perPage"
                            :items="countPerPage"
                            attach
                            dense
                            class="col-4 col-sm-2 col-md-1"
                            :hint="$t('countperpage')"
                            />

                          <v-text-field
                            v-model="pPageByUser"
                            class="col-3 col-sm-2 col-md-1"
                            dense
                            :hint="$t('countperpage')"
                            type="number"
                            max=149
                            min=1
                            v-on:keyup.enter="perPageChange"
                          />
                        </div>
                      </v-container>

                      <template v-if="statsLoaded">
                      <v-simple-table
                        v-if="lastAvgLoaded"
                        class="table-row-35 border-lightgrey text-center"
                        style="border-radius: 0">
                        <tbody>
                          <tr>
                            <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                            <td :title="$t('pts.tooltip')">{{ $t('pts.label') }}</td>
                            <td :title="$t('w.tooltip')">{{ $t('w.label') }}</td>
                            <td :title="$t('d.tooltip')">{{ $t('d.label') }}</td>
                            <td :title="$t('l.tooltip')">{{ $t('l.label') }}</td>
                            <td :title="$t('diff.tooltip')">{{ $t('diff.label') }}</td>
                            <td :title="$t('diff_av.tooltip')">{{ $t('diff_av.label') }}</td>
                            <td :title="$t('total_av.tooltip')">{{ $t('total_av.label') }}</td>
                            <td :title="$t('teamtotal_av.tooltip')">{{ team.name }}</td>
                            <td :title="$t('opponenttotal_av.tooltip')"> {{ $t('opponenttotal_av.label') }}</td>
                          </tr>
                            
                          <tr class="font-weight-bold">
                            <td>{{ lastAvg.goalMatch }}</td>
                            <td>{{ lastAvg.goalPoint }}</td>
                            <td>{{ lastAvg.goalWDL[0] }}</td>
                            <td>{{ lastAvg.goalWDL[1] }}</td>
                            <td>{{ lastAvg.goalWDL[2] }}</td>
                            <td>{{ lastAvg.goalDiff }}</td>
                            <td>{{ lastAvg.goalDiff_avg }}</td>
                            <td>{{ lastAvg.goalTotal }}</td>
                            <td>{{ lastAvg.goalT1 }}</td>
                            <td>{{ lastAvg.goalT2 }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                        
                      <div class="curPointer pt-5 pb-2">
                        <a :class="{'d-none' : page == lastPage}" class="ml-1 mr-4" @click="prev()">{{ $t('previous') }}</a>
                        <a :class="{'d-none' : !page}" @click="next()">{{ $t('next') }}</a>
                      </div>

                      <v-simple-table class="table-row-35 cell-py-10 border-lightgrey" style="border-radius: 0">
                        <thead>
                          <tr>
                            <th>{{ $t('date') }}</th>
                            <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                            <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                            <th>{{ $t('home') }}</th>
                            <th></th>
                            <th class="text-right">{{ $t('away') }}</th>
                            <th class="text-center">{{ $t('detail') }}</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="(game, index) in last[page]"
                            :key="index"
                            :class="{'curPointer': (game.local_score != 0 || game.visitor_score != 0)}"
                            @click="goalModal(index, game.local_id, game.local_name, game.visitor_name)" >
                            <td class="overflow-anywhere blue-grey--text">
                              <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                              <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                            </td>

                            <td :class="{'d-none': $vuetify.breakpoint.xs}" class="overflow-anywhere">
                              <router-link :to="{name: 'league', params: {lid: game.league.league_id}}" class="blue-grey--text">
                                {{ game.league.name }}
                              </router-link>
                            </td>

                            <td :class="{'d-none': $vuetify.breakpoint.xs}" class="blue-grey--text">
                              {{ game.round ? game.round.name : '' }}
                            </td>

                            <td v-if="game.local_id != team.id">
                              <router-link :to="{name: 'team', params: {id: game.local_id}}" class="overflow-anywhere blue-grey--text">
                                {{ game.local_name }}
                              </router-link>
                            </td>

                            <td v-else class="overflow-anywhere black--text">{{ game.local_name }}</td>

                            <td
                              class="white--text"
                              :style="'background-color:' + stats_color[game.goal_color]">
                              <div class="d-flex justify-space-around align-center subtitle-2">
                                <span>{{ game.local_score }}</span>
                                <span> - </span>
                                <span>{{ game.visitor_score }}</span>
                              </div>
                            </td>
                              
                            <td v-if="game.visitor_id != team.id" class="text-right">
                              <router-link :to="{name: 'team', params: {id: game.visitor_id}}" class="overflow-anywhere blue-grey--text">
                                {{ game.visitor_name }}
                              </router-link>
                            </td>

                            <td v-else class="overflow-anywhere text-right black--text">{{ game.visitor_name }}</td>

                            <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      </template>

                      <div v-else>
                        <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                      </div>
                    </template>
                  </div>
                </div>
              </v-tab-item>

              <v-tab-item key="Corners">
                <div class="row no-gutters justify-center">
                  <div class="col-lg-8 col-md-10">
                    <div v-if="!lastLoaded">
                      <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                    </div>

                    <div v-else-if="!authorization">
                      <v-alert
                        type="warning"
                        prominent
                      >
                        {{ $t(`message.${message}`) }}
                      </v-alert>
                    </div>

                    <template v-else>
                      <v-container fluid>
                        <div v-if="leagueLoaded" class="row d-flex mt-2">
                          <v-select
                            v-model="chosenLeagues"
                            :items="leagues"
                            item-text="league_name"
                            item-value="league_id"
                            class="col-12 col-sm-7 col-lg-9"
                            attach
                            dense
                            :label="$t('tournament')"
                            hide-details
                            multiple
                          />
                          <v-select
                            v-model="chosenSeasons"
                            :items="seasons"
                            :label="$t('season')"
                            class="col-12 col-sm-5 col-lg-3"
                            attach
                            dense
                            hide-details
                            multiple
                          />
                        </div>

                        <div class="row d-flex flex-wrap justify-space-between mt-4">
                          <v-select
                            v-model="fullHalf"
                            :items="timeCategories"
                            :item-text="item => $t(item.text)"
                            item-value="index"
                            :label="$t('time')"
                            class="col-5 col-sm-3 col-md-2"
                            attach
                            dense
                            hide-details
                          />

                          <v-checkbox
                            v-model="fLocal"
                            color="blue"
                            hide-details
                            dense
                            :label="$t('home')"
                            />

                          <v-checkbox
                            v-model="fVisitor"
                            color="blue"
                            hide-details
                            dense
                            :label="$t('away')"
                            />

                          <template v-if="coach && coach.coach_id">
                            <v-checkbox
                              v-model="currentCoach"
                              color="blue"
                              hide-details
                              dense
                              :label="`${$t('currentcoach')}(${coach_count})`"
                              />
                            <v-checkbox
                              v-model="prevCoach"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('previouscoach')"
                              />
                          </template>

                          <v-select
                            v-model="perPage"
                            :items="countPerPage"
                            attach
                            dense
                            class="col-4 col-sm-2 col-md-1"
                            :hint="$t('countperpage')"
                          />

                          <v-text-field
                            v-model="pPageByUser"
                            class="col-3 col-sm-2 col-md-1"
                            dense
                            :hint="$t('countperpage')"
                            type="number"
                            max=149
                            min=1
                            v-on:keyup.enter="perPageChange"
                          />
                        </div>
                      </v-container>

                      <template  v-if="statsLoaded">
                      <v-simple-table
                        v-if="lastAvgLoaded"
                        class="table-row-35 border-lightgrey text-center"
                        style="border-radius: 0">
                        <tbody>
                          <tr>
                            <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                            <td :title="$t('pts.tooltip')">{{ $t('pts.label') }}</td>
                            <td :title="$t('w.tooltip')">{{ $t('w.label') }}</td>
                            <td :title="$t('d.tooltip')">{{ $t('d.label') }}</td>
                            <td :title="$t('l.tooltip')">{{ $t('l.label') }}</td>
                            <td :title="$t('diff.tooltip')">{{ $t('diff.label') }}</td>
                            <td :title="$t('diff_av.tooltip')">{{ $t('diff_av.label') }}</td>
                            <td :title="$t('total_av.tooltip')">{{ $t('total_av.label') }}</td>
                            <td :title="$t('teamtotal_av.tooltip')">{{ team.name }}</td>
                            <td :title="$t('opponenttotal_av.tooltip')"> {{ $t('opponenttotal_av.label') }}</td>
                          </tr>

                          <tr class="font-weight-bold">
                            <td>{{ lastAvg.cornerMatch }}</td>
                            <td>{{ lastAvg.cornerPoint }}</td>
                            <td>{{ lastAvg.cornerWDL[0] }}</td>
                            <td>{{ lastAvg.cornerWDL[1] }}</td>
                            <td>{{ lastAvg.cornerWDL[2] }}</td>
                            <td>{{ lastAvg.cornerDiff }}</td>
                            <td>{{ lastAvg.cornerDiff_avg }}</td>
                            <td>{{ lastAvg.cornerTotal }}</td>
                            <td>{{ lastAvg.cornerT1 }}</td>
                            <td>{{ lastAvg.cornerT2 }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>

                      <div class="curPointer pt-5 pb-2">
                        <a :class="{'d-none' : page == lastPage}" class="ml-1 mr-4" @click="prev()">{{ $t('previous') }}</a>
                        <a :class="{'d-none' : !page}" @click="next()">{{ $t('next') }}</a>
                      </div>

                      <v-simple-table class="table-row-35 cell-py-10 border-lightgrey" style="border-radius: 0">
                        <thead>
                          <tr>
                            <th>{{ $t('date') }}</th>
                            <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                            <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                            <th>{{ $t('home') }}</th>
                            <th></th>
                            <th class="text-right">{{ $t('away') }}</th>
                            <th class="text-center">{{ $t('detail') }}</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="(game, index) in last[page]"
                            :key="index"
                            :class="{'curPointer': (game.corners[game.local_id] != 0 || game.corners[game.visitor_id] != 0)}"
                            @click="cornerModal(index, game.local_id, game.local_name, game.visitor_name)">
                            <td class="overflow-anywhere blue-grey--text">
                              <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                              <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                            </td>

                            <td :class="{'d-none': $vuetify.breakpoint.xs}" class="overflow-anywhere">
                              <router-link :to="{name: 'league', params: {lid: game.league.league_id}}" class="blue-grey--text">
                                {{ game.league.name }}
                              </router-link>
                            </td>

                            <td :class="{'d-none': $vuetify.breakpoint.xs}" class="blue-grey--text">
                              {{ game.round ? game.round.name : '' }}
                            </td>

                            <td v-if="game.local_id!=team.id">
                              <router-link :to="{name: 'team', params: {id: game.local_id}}" class="overflow-anywhere blue-grey--text">
                                {{ game.local_name }}
                              </router-link>
                            </td>

                            <td v-else class="overflow-anywhere black--text">{{ game.local_name }}</td>

                            <td
                              class="white--text"
                              :style="'background-color:' + stats_color[game.corner_color]">
                              <div class="d-flex justify-space-around align-center subtitle-2">
                                <span>{{ (game.corners[game.local_id] != 0 || game.corners[game.visitor_id] != 0) ? game.corners[game.local_id] : '?' }}</span>

                                <span v-if="$vuetify.breakpoint.xs">-</span>

                                <img v-else src='/img/icons/corner_kick.png'>

                                <span>{{ (game.corners[game.local_id] != 0 || game.corners[game.visitor_id] != 0) ? game.corners[game.visitor_id] : '?' }}</span>
                              </div>
                            </td>

                            <td v-if="game.visitor_id != team.id" class="text-right">
                              <router-link :to="{name: 'team', params: {id: game.visitor_id}}" class="overflow-anywhere blue-grey--text">
                                {{ game.visitor_name }}
                              </router-link>
                            </td>

                            <td v-else class="overflow-anywhere text-right black--text">{{ game.visitor_name }}</td>

                            <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      </template>

                      <div v-else>
                        <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                      </div>
                    </template>
                  </div>
                </div>
              </v-tab-item>

              <v-tab-item key="Cards">
                <div class="row no-gutters justify-center">
                  <div class="col-lg-8 col-md-10">
                    <div v-if="!lastLoaded">
                      <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                    </div>

                    <div v-else-if="!authorization">
                      <v-alert
                        type="warning"
                        prominent
                      >
                        {{ $t(`message.${message}`) }}
                      </v-alert>
                    </div>

                    <template v-else>
                      <v-container fluid>
                        <div v-if="leagueLoaded" class="row d-flex mt-2">
                          <v-select
                            v-model="chosenLeagues"
                            :items="leagues"
                            item-text="league_name"
                            item-value="league_id"
                            class="col-12 col-sm-7 col-lg-9"
                            attach
                            dense
                            :label="$t('tournament')"
                            hide-details
                            multiple
                          />

                          <v-select
                            v-model="chosenSeasons"
                            :items="seasons"
                            :label="$t('season')"
                            class="col-12 col-sm-5 col-lg-3"
                            attach
                            dense
                            hide-details
                            multiple
                          />
                        </div>

                        <div class="row d-flex flex-wrap justify-space-between mt-4">
                          <v-select
                            v-model="fullHalf"
                            :items="timeCategories"
                            :item-text="item => $t(item.text)"
                            item-value="index"
                            :label="$t('time')"
                            class="col-5 col-sm-3 col-md-2"
                            attach
                            dense
                            hide-details
                          />

                          <v-checkbox
                            v-model="fLocal"
                            color="blue"
                            hide-details
                            dense
                            :label="$t('home')"
                            />

                          <v-checkbox
                            v-model="fVisitor"
                            color="blue"
                            hide-details
                            dense
                            :label="$t('away')"
                            />

                          <template v-if="coach && coach.coach_id">
                            <v-checkbox
                              v-model="currentCoach"
                              color="blue"
                              hide-details
                              dense
                              :label="`${$t('currentcoach')}(${coach_count})`"
                              />
                            <v-checkbox
                              v-model="prevCoach"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('previouscoach')"
                              />
                          </template>

                          <v-select
                            v-model="perPage"
                            :items="countPerPage"
                            attach
                            dense
                            class="col-4 col-sm-2 col-md-1"
                            :hint="$t('countperpage')"
                          />

                          <v-text-field
                            v-model="pPageByUser"
                            class="col-3 col-sm-2 col-md-1"
                            dense
                            :hint="$t('countperpage')"
                            type="number"
                            max=149
                            min=1
                            v-on:keyup.enter="perPageChange"
                          />
                        </div>
                      </v-container>

                      <template v-if="statsLoaded">
                      <v-simple-table
                        v-if="lastAvgLoaded"
                        class="table-row-35 border-lightgrey text-center"
                        style="border-radius: 0">
                        <tbody>
                          <tr>
                            <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                            <td :title="$t('diff_yc.tooltip')">{{ $t('diff_yc.label') }}</td>
                            <td :title="$t('t_yc.tooltip')">{{ $t('t_yc.label') }}</td>
                            <td :title="$t('t1_yc.tooltip')">{{ $t('t1_yc.label') }}</td>
                            <td :title="$t('t2_yc.tooltip')">{{ $t('t2_yc.label') }}</td>
                            <td :title="$t('diff_rc.tooltip')">{{ $t('diff_rc.label') }}</td>
                            <td :title="$t('t_rc.tooltip')">{{ $t('t_rc.label') }}</td>
                            <td :title="$t('t1_rc.tooltip')">{{ $t('t1_rc.label') }}</td>
                            <td :title="$t('t2_rc.tooltip')">{{ $t('t2_rc.label') }}</td>
                          </tr>
                              
                          <tr class="font-weight-bold">
                            <td>{{ lastAvg.cardMatch }}</td>
                            <td>{{ lastAvg.cardDiff_yc }}</td>
                            <td>{{ lastAvg.cardTotal_yc }}</td>
                            <td>{{ lastAvg.cardT1_yc }}</td>
                            <td>{{ lastAvg.cardT2_yc }}</td>
                            <td>{{ lastAvg.cardDiff_rc }}</td>
                            <td>{{ lastAvg.cardTotal_rc }}</td>
                            <td>{{ lastAvg.cardT1_rc }}</td>
                            <td>{{ lastAvg.cardT2_rc }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                          
                      <div class="curPointer pt-5 pb-2">
                        <a :class="{'d-none' : page == lastPage}" class="ml-1 mr-4" @click="prev()">{{ $t('previous') }}</a>
                        <a :class="{'d-none' : !page}" @click="next()">{{ $t('next') }}</a>
                      </div>
                      
                      <v-simple-table class="table-row-35 cell-py-10 border-lightgrey" style="border-radius: 0">
                        <thead>
                          <tr>
                            <th>{{ $t('date') }}</th>
                            <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                            <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                            <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('referee') }}</th>
                            <th>{{ $t('home') }}</th>
                            <th></th>
                            <th class="text-right">{{ $t('away') }}</th>
                            <th class="text-center">{{ $t('detail') }}</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="(game, index) in last[page]"
                            :key="index"
                            :class="{'curPointer': (game.local_cards.yellow != -1)}"
                            @click="cardModal(index, game.local_id, game.local_name, game.visitor_name)">
                            <td class="overflow-anywhere blue-grey--text">
                              <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                              <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                            </td>

                            <td :class="{'d-none': $vuetify.breakpoint.xs}" class="overflow-anywhere">
                              <router-link :to="{name: 'league', params: {lid: game.league.league_id}}" class="blue-grey--text">
                                {{ game.league.name }}
                              </router-link>
                            </td>

                            <td :class="{'d-none': $vuetify.breakpoint.xs}" class="blue-grey--text">
                              {{ game.round ? game.round.name : '' }}
                            </td>

                            <td
                              :class="{'d-none': $vuetify.breakpoint.xs}"
                              class="blue-grey--text"
                              style="text-decoration-line: underline;"
                              @click.stop="refereeModal(game.referee)">
                              {{ game.referee_name }}
                            </td>

                            <td v-if="game.local_id != team.id">
                              <router-link :to="{name: 'team', params: {id: game.local_id}}" class="overflow-anywhere blue-grey--text">
                                {{ game.local_name }}
                              </router-link>
                            </td>

                            <td v-else class="overflow-anywhere black--text">{{ game.local_name }}</td>

                            <td class="blue-grey--text">
                              <div class="d-flex justify-space-around align-center subtitle-2">
                                <div>
                                  <template 
                                    v-if="game.local_cards.yellow == -1">
                                    ?
                                  </template>

                                  <template v-else>
                                    <span class="team_1_yellow_cards">{{ game.local_cards.yellow }}</span>
                                    
                                    <br>

                                    <span class="team_1_red_cards">{{ game.local_cards.red }}</span>
                                  </template>
                                </div>

                                <div style="min-width: 11px;">
                                  <img src="/img/icons/yellow_card.png">

                                  <br>

                                  <img src="/img/icons/red_card.png">
                                </div>
                                    
                                <div>
                                  <template 
                                    v-if="game.local_cards.yellow == -1">
                                    ?
                                  </template>

                                  <template v-else>
                                    <span class='team_2_yellow_cards'>{{ game.visitor_cards.yellow }}</span>
                                    
                                    <br>

                                    <span class='team_2_red_cards'>{{ game.visitor_cards.red }}</span>
                                  </template>
                                </div>
                              </div>
                            </td>

                            <td v-if="game.visitor_id != team.id" class="text-right">
                              <router-link :to="{name: 'team', params: {id: game.visitor_id}}" class="overflow-anywhere blue-grey--text">
                                {{ game.visitor_name }}
                              </router-link>
                            </td>

                            <td v-else class="overflow-anywhere text-right black--text">{{ game.visitor_name }}</td>

                            <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      </template>

                      <div v-else>
                        <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                      </div>
                    </template>
                  </div>
                </div>
              </v-tab-item>

              <v-tab-item key="Scorers">
                <template v-if="!authorization">
                  <v-alert
                    type="warning"
                    prominent
                  >
                    {{ $t(`message.${message}`) }}
                  </v-alert>
                </template>

                <template v-else>
                  <div class="row justify-center my-1">
                    <div class="col-sm-6 col-md-4 col-lg-4">
                      <v-select
                        v-model="tournamentForScorers"
                        :items="leagues"
                        item-text="league_name"
                        item-value="league_id"
                        :label="$t('tournament')"
                        attach
                        hide-details
                        dense
                        outlined
                      />
                    </div>

                    <div class="col-sm-3 col-md-2 col-lg-2">
                      <v-select
                        v-model="seasonForScorers"
                        :items="chosenSeasonsForScorers"
                        :item-text="(tournamentForScorers == 0) ? '' : 'season_name'"
                        :item-value="(tournamentForScorers == 0) ? '' : 'season_id'"
                        :label="$t('season')"
                        attach
                        hide-details
                        dense
                        outlined
                      />
                    </div>
                  </div>

                  <div class="align-center">
                    <template v-if="!tabLoaded">
                      <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                    </template>

                    <div v-else-if="scorers && scorers.length" class="d-flex flex-wrap justify-space-around">
                      <v-card
                        v-if="scorers[0].length"
                        min-width="250"
                        max-width="380"
                        elevation="2"
                        class="mr-2 mb-2"
                      >
                        <v-app-bar
                          dark
                          color="grey lighten-1"
                          dense
                          flat
                          height=35
                        >
                          <v-app-bar-nav-icon><v-icon>mdi-soccer</v-icon></v-app-bar-nav-icon>

                          <v-toolbar-title class="subtitle-1">{{ $t('scorers') }}</v-toolbar-title>
                        </v-app-bar>

                        <v-card-text>
                          <div v-for="(item, ind) in scorers[0]" :key="ind" :class="{'m-1': $vuetify.breakpoint.xs, 'mb-2': $vuetify.breakpoint.smAndUp}">
                            <v-progress-linear
                              :value="item.value * 100 / scorers[0][0].value"
                              color="green lighten-3"
                              rounded
                              height="30"
                            >
                              <span class="ml-2 justify-start" style="width: 100%;">
                                <router-link :to="{name: 'player', params: {pid: item.player_id}}">
                                  <img 
                                    class="rounded-circle wh-30"
                                    :src="item.player_logo"
                                    onerror="this.src='/img/default-avatar.png'"
                                    />

                                <span class="red--text text--darken-4" :class="{'caption': $vuetify.breakpoint.xs}">
                                    {{ item.player_name }} {{ item.value }}
                                  </span>
                                </router-link>
                              </span>
                            </v-progress-linear>
                          </div>
                        </v-card-text>
                      </v-card>

                      <v-card
                        v-if="scorers[1].length"
                        min-width="250"
                        max-width="380"
                        elevation="2"
                        class="mr-2 mb-2"
                      >
                        <v-app-bar
                          dark
                          color="grey lighten-1"
                          dense
                          flat
                          height=35
                        >
                          <v-app-bar-nav-icon><v-icon>mdi-shoe-formal</v-icon></v-app-bar-nav-icon>

                          <v-toolbar-title class="subtitle-1">{{ $t('stats_type.ASSISTS') }}</v-toolbar-title>
                        </v-app-bar>

                        <v-card-text>
                          <div v-for="(item, ind) in scorers[1]" :key="ind" :class="{'m-1': $vuetify.breakpoint.xs, 'mb-2': $vuetify.breakpoint.smAndUp}">
                            <v-progress-linear
                              :value="item.value * 100 / scorers[1][0].value"
                              color="amber lighten-3"
                              rounded
                              height="30"
                            >
                              <span class="ml-2 justify-start" style="width: 100%;">
                                <router-link :to="{name: 'player', params: {pid: item.player_id}}">
                                  <img
                                    class="rounded-circle wh-30"
                                    :src="item.player_logo"
                                    onerror="this.src='/img/default-avatar.png'"
                                    />

                                <span :class="{'caption': $vuetify.breakpoint.xs}">
                                    {{ item.player_name }} {{ item.value }}
                                  </span>
                                </router-link>
                              </span>
                            </v-progress-linear>
                          </div>
                        </v-card-text>
                      </v-card>

                      <v-card
                        v-if="scorers[2].length"
                        min-width="250"
                        max-width="380"
                        elevation="2"
                        class="mr-2 mb-2"
                      >
                        <v-app-bar
                          dark
                          color="grey lighten-1"
                          dense
                          flat
                          height=35
                        >
                          <v-app-bar-nav-icon><v-icon>mdi-card</v-icon></v-app-bar-nav-icon>

                          <v-toolbar-title class="subtitle-1">{{ $t('stats_type.CARDS')}}</v-toolbar-title>
                        </v-app-bar>

                        <v-card-text>
                          <div v-for="(item, ind) in scorers[2]" :key="ind" :class="{'m-1': $vuetify.breakpoint.xs, 'mb-2': $vuetify.breakpoint.smAndUp}">
                            <v-progress-linear
                              :value="item.value * 100 / scorers[2][0].value"
                              color="pink lighten-3"
                              rounded
                              height="30"
                            >
                              <span class="ml-2 justify-start" style="width: 100%;">
                                <router-link :to="{name: 'player', params: {pid: item.player_id}}">
                                  <img
                                    class="rounded-circle wh-30"
                                    :src="item.player_logo"
                                    onerror="this.src='/img/default-avatar.png'"
                                    />

                                <span class="indigo--text" :class="{'caption': $vuetify.breakpoint.xs}">
                                    {{ item.player_name }} {{ item.value }}
                                  </span>
                                </router-link>
                              </span>
                            </v-progress-linear>
                          </div>
                        </v-card-text>
                      </v-card>

                      <v-card
                        v-if="scorers[3].length"
                        min-width="250"
                        max-width="380"
                        elevation="2"
                        class="mb-2"
                      >
                        <v-app-bar
                          dark
                          color="grey lighten-1"
                          dense
                          flat
                          height=35
                        >
                          <v-app-bar-nav-icon><v-icon>mdi-account</v-icon></v-app-bar-nav-icon>

                          <v-toolbar-title class="subtitle-1">{{ $t('stats_type.APPEARANCES') }}</v-toolbar-title>
                        </v-app-bar>

                        <v-card-text>
                        <div
                          v-for="(item, ind) in scorers[3]"
                          :key="ind"
                          :class="{'m-1': $vuetify.breakpoint.xs, 'mb-2': $vuetify.breakpoint.smAndUp}"
                          >
                            <v-progress-linear
                              :value="item.value * 100 / scorers[3][0].value"
                              color="green lighten-3"
                              rounded
                              height="30"
                            >
                              <span class="ml-2 justify-start" style="width: 100%;">
                                <router-link :to="{name: 'player', params: {pid: item.player_id}}">
                                  <img 
                                    class="rounded-circle wh-30"
                                    :src="item.player_logo"
                                    onerror="this.src='/img/default-avatar.png'"
                                    />
                                  
                                  <span class="red--text text--darken-4" :class="{'caption': $vuetify.breakpoint.xs}">
                                    {{ item.player_name }} {{ item.value }}
                                  </span>
                                </router-link>
                              </span>
                            </v-progress-linear>
                          </div>
                        </v-card-text>
                      </v-card>
                    </div>

                    <p v-else>{{ $t('message.noscorers') }}</p>
                  </div>
                </template>
              </v-tab-item>

              <v-tab-item key="Stats">
                <template v-if="!authorization">
                  <v-alert
                    type="warning"
                    prominent
                  >
                    {{ $t(`message.${message}`) }}
                  </v-alert>
                </template>

                <template v-else>
                  <div class="row justify-center my-1">
                    <div class="col-sm-6 col-md-4 col-lg-4">
                      <v-select
                        v-model="tournamentForStats"
                        :items="leaguesAndAll"
                        item-text="league_name"
                        item-value="league_id"
                        :label="$t('tournament')"
                        attach
                        hide-details
                        dense
                        outlined
                      />
                    </div>

                    <div class="col-sm-3 col-md-2 col-lg-2">
                      <v-select
                        v-model="seasonForStats"
                        :items="chosenSeasonsForStats"
                        :item-text="(tournamentForStats == 0) ? '' : 'season_name'"
                        :item-value="(tournamentForStats == 0) ? '' : 'season_id'"
                        :label="$t('season')"
                        attach
                        hide-details
                        dense
                        outlined
                      />
                    </div>
                  </div>

                  <div class="align-center">
                    <template v-if="!tabLoaded">
                      <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                    </template>

                    <v-simple-table v-else-if="teamStats" class="table-row-35">
                      <tbody>
                        <tr>
                          <td class="blue-grey--text">{{ $t('w.tooltip') }}:</td>
                          <td class="text-right green--text text--darken-2" :title="`${ $t('total') } ( ${ $t('home') } / ${ $t('away') } )`">
                            {{ teamStats.win.all.count }} ({{ teamStats.win.home.count }} / {{ teamStats.win.away.count }})
                          </td>
                        </tr>

                        <tr>
                          <td class="blue-grey--text">{{ $t('d.tooltip') }}:</td>
                          <td class="text-right deep-orange--text" :title="`${ $t('total') } ( ${ $t('home') } / ${ $t('away') } )`">
                            {{ teamStats.draw.all.count }} ({{ teamStats.draw.home.count }} / {{ teamStats.draw.away.count }})
                          </td>
                        </tr>

                        <tr>
                          <td class="blue-grey--text">{{ $t('l.tooltip') }}:</td>
                          <td class="text-right red--text text--darken-4" :title="`${ $t('total') } ( ${ $t('home') } / ${ $t('away') } )`">
                            {{ teamStats.lost.all.count }} ({{ teamStats.lost.home.count }} / {{ teamStats.lost.away.count }})
                          </td>
                        </tr>

                        <tr>
                          <td class="blue-grey--text">{{ $t('stats_type.GOALS_SCORED') }}:</td>
                          <td class="text-right" :title="`${ $t('total') } ( ${ $t('home') } / ${ $t('away') } )`">
                            {{ teamStats.goals.all.count }} ( {{ teamStats.goals.home.count }} / {{ teamStats.goals.away.count }} )
                          </td>
                        </tr>

                        <tr>
                          <td class="blue-grey--text">{{ $t('stats_type.avg_goals_scored_per_game') }}:</td>
                          <td class="text-right" :title="`${ $t('total') } ( ${ $t('home') } / ${ $t('away') } )`">
                            {{ teamStats.goals.all.average }} ( {{ teamStats.goals.home.average }} / {{ teamStats.goals.away.average }} )
                          </td>
                        </tr>

                        <tr>
                          <td class="blue-grey--text">{{ $t('stats_type.GOALS_CONCEDED') }}:</td>
                          <td class="text-right" :title="`${ $t('total') } ( ${ $t('home') } / ${ $t('away') } )`">
                            {{ teamStats.goals_conceded.all.count }} ( {{ teamStats.goals_conceded.home.count }} / {{ teamStats.goals_conceded.away.count }} )
                          </td>
                        </tr>

                        <tr>
                          <td class="blue-grey--text">{{ $t('stats_type.avg_goals_conceded_per_game') }}:</td>
                          <td class="text-right" :title="`${ $t('total') } ( ${ $t('home') } / ${ $t('away') } )`">
                            {{ teamStats.goals_conceded.all.average }} ( {{ teamStats.goals_conceded.home.average }} / {{ teamStats.goals_conceded.away.average }} )
                          </td>
                        </tr>

                        <tr>
                          <td class="blue-grey--text">{{ $t('stats_type.CLEANSHEET') }}:</td>
                          <td class="text-right" :title="`${ $t('total') } ( ${ $t('home') } / ${ $t('away') } )`">
                            <!-- <template v-if="teamStats.clean_sheet"> -->
                              {{ teamStats.clean_sheet.all.count }} ( {{ teamStats.clean_sheet.home.count }} / {{ teamStats.clean_sheet.away.count }} )
                            <!-- </template> -->
                          </td>
                        </tr>

                        <tr>
                          <td class="blue-grey--text">{{ $t('stats_type.TOTAL_CORNERS') }}:</td>
                          <td class="text-right">{{ teamStats.corners.count }}</td>
                        </tr>

                        <tr>
                          <td class="blue-grey--text">{{ $t('stats_type.avg_corners') }}:</td>
                          <td class="text-right">{{ teamStats.corners.average }}</td>
                        </tr>

                        <tr>
                          <td class="blue-grey--text">{{ $t('stats_type.PENALTIES') }}:</td>
                          
                          <td class="text-right" :title="`${ $t('stats_type.PENALTIES_SCORED') } / ${ $t('stats_type.PENALTIES_MISSES') }`">
                            {{ teamStats.penalties.scored }} / {{ teamStats.penalties.missed }}
                          </td>
                        </tr>

                        <tr>
                          <td class="blue-grey--text">{{ $t('yellowcard') }}:</td>
                          <td class="text-right">{{ teamStats.yellowcards.count }}</td>
                        </tr>

                        <tr>
                          <td class="blue-grey--text">{{ $t('redcard') }}:</td>
                          <td class="text-right">{{ teamStats.redcards.count }}</td>
                        </tr>

                        <tr>
                          <td class="blue-grey--text">{{ $t('stats_type.FOULS') }}:</td>
                          <td class="text-right">{{ teamStats.fouls.count }}</td>
                        </tr>

                        <tr>
                          <td class="blue-grey--text">{{ $t('stats_type.avg_fouls_per_game') }}:</td>
                          <td class="text-right">{{ teamStats.fouls.average }}</td>
                        </tr>

                        <tr>
                          <td class="blue-grey--text">{{ $t('stats_type.OFFSIDES') }}:</td>
                          <td class="text-right">{{ teamStats.offsides.count }}</td>
                        </tr>

                        <tr>
                          <td class="blue-grey--text">{{ $t('stats_type.ATTACKS') }}:</td>
                          <td class="text-right">{{ teamStats.attacks.count }}</td>
                        </tr>

                        <tr>
                          <td class="blue-grey--text">{{ $t('stats_type.DANGEROUS_ATTACKS') }}:</td>
                          <td class="text-right">{{ teamStats.dangerous_attacks.count }}</td>
                        </tr>

                        <tr>
                          <td class="blue-grey--text">{{ $t('stats_type.avg_ball_possession') }}:</td>
                          <td class="text-right">
                            {{ teamStats.ball_possession.average }}%
                          </td>
                        </tr>

                        <tr>
                          <td class="blue-grey--text">{{ $t('stats_type.TACKLES') }}:</td>
                          <td class="text-right">{{ teamStats.tackles.count }}</td>
                        </tr>

                        <tr>
                          <td class="blue-grey--text">{{ $t('stats_type.HIGHEST_RATED_PLAYER') }}:</td>
                          <td class="text-right">
                            {{ teamStats.highest_rated_player.rating }}, {{ teamStats.highest_rated_player.player_name }}
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>

                    <p v-else>{{ $t('message.nostats') }}</p>
                  </div>
                </template>
              </v-tab-item>

              <v-tab-item key="Transfers">
                <template v-if="!authorization">
                  <v-alert
                    type="warning"
                    prominent
                  >
                    {{ $t(`message.${message}`) }}
                  </v-alert>
                </template>

                <template v-else>
                  <div class="align-center">
                    <template v-if="tabLoaded">
                      <div v-if="transfers.length" class="row no-gutters justify-center">
                        <div class="align-center">
                          <v-simple-table class="mt-5 table-row-35 green-top-3">
                            <thead>
                              <tr class="text-left white grey--text text--darken-1">
                                <td>{{ $t('player') }}</td>
                                <td>{{ $t('from') }}</td>
                                <td>{{ $t('to') }}</td>
                                <td>{{ $t('date') }}</td>
                                <td class="d-none d-sm-table-cell">{{ $t('type') }}</td>
                                <td class="d-none d-sm-table-cell">{{ $t('amount') }}</td>
                              </tr>
                            </thead>

                            <tbody>
                              <tr
                                v-for="(item, ind) in transfers"
                                :key="ind"
                                class="text-left"
                              >
                                <td>
                                  <router-link :to="{name: 'player', params: {pid: item.player_id}}">
                                    <img
                                      class="wh-30"
                                      :src="item.player.image_path"
                                      onerror="this.src='/img/default-avatar.png'"
                                      :title="item.player.common_name" />
                                    
                                    <span class="blue-grey--text ml-2">{{ item.player.common_name }}</span>
                                  </router-link>
                                </td>

                                <td>
                                  <router-link v-if="item.from_team_id != team.id" :to="{name: 'team', params: {id: item.from_team_id}}">
                                    <img 
                                      class="wh-30"
                                      :src="item.fromteam.image_path"
                                      :title="item.fromteam.name"
                                      />
                                  </router-link>

                                  <img v-else
                                    class="wh-30"
                                    :src="team.logo"
                                    :title="team.name"
                                    />
                                </td>

                                <td>
                                  <router-link v-if="item.to_team_id != team.id" :to="{name: 'team', params: {id: item.to_team_id}}">
                                    <img 
                                      class="wh-30"
                                      :src="item.toteam.image_path"
                                      :title="item.toteam.name"
                                      />
                                  </router-link>

                                  <img v-else
                                    class="wh-30"
                                    :src="team.logo"
                                    :title="team.name"
                                    />
                                </td>

                                <td style="white-space:nowrap">
                                  <span class="blue-grey--text">{{ item.date }}</span>
                                </td>

                                <td class="d-none d-sm-table-cell" style="white-space:nowrap">
                                  <span 
                                    v-if="item.type_id != 'n/a'"
                                    :class="`${transferColors[item.type_id - 218]}--text`"
                                  >
                                    {{ $t(`transfer_type.${item.type.developer_name}`) }}
                                  </span>
                                </td>

                                <td class="d-none d-sm-table-cell">
                                  <span v-if="item.amount" class="font-weight-bold deep-orange--text">{{ parseFloat((item.amount / 1000000).toFixed(2)) }}M</span>
                                </td>
                              </tr>
                            </tbody>
                          </v-simple-table>

                          <v-btn
                            :class="{'d-none': !transfer_has_more}"
                            :loading="transfer_loading"
                            :disabled="transfer_loading"
                            color="success"
                            @click="getTeamTransfer()"
                            small
                          >
                            {{ $t('loadmore') }}
                          </v-btn>
                        </div>
                      </div>

                      <p v-else>{{ $t('message.notransfers') }}</p>
                    </template>

                    <div v-else class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                  </div>
                </template>
              </v-tab-item>

              <v-tab-item key="Others">
                <div class="row justify-center my-1">
                  <div class="col-lg-8 col-md-10">
                    <div v-if="!lastLoaded" >
                      <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                    </div>

                    <div v-else-if="!authorization">
                      <v-alert
                        type="warning"
                        prominent
                      >
                        {{ $t(`message.${message}`) }}
                      </v-alert>
                    </div>

                    <template v-else>
                      <div class="row no-gutters justify-center">
                        <v-select
                          v-model="chosenCategory"
                          :items="categories"
                          item-text="text"
                          item-value="index"
                          attach
                          class="col-12 col-sm-6 col-lg-4"
                          hide-details
                          dense
                          outlined
                        />
                      </div>

                      <v-container fluid>
                        <div v-if="leagueLoaded" class="row d-flex my-1">
                          <v-select
                            v-model="chosenLeagues"
                            :items="leagues"
                            item-text="league_name"
                            item-value="league_id"
                            attach
                            class="col-12 col-sm-7 col-lg-9"
                            dense
                            :label="$t('tournament')"
                            hide-details
                            multiple
                          />

                          <v-select
                            v-model="chosenSeasons"
                            :items="seasons"
                            :label="$t('season')"
                            attach
                            class="col-12 col-sm-5 col-lg-3"
                            dense
                            hide-details
                            multiple
                          />
                        </div>

                        <div class="row d-flex flex-wrap justify-space-between mt-4">
                          <v-checkbox
                            v-model="fLocal"
                            color="blue"
                            hide-details
                            dense
                            :label="$t('home')"
                            />

                          <v-checkbox
                            v-model="fVisitor"
                            color="blue"
                            hide-details
                            dense
                            :label="$t('away')"
                            />
                          
                          <template v-if="coach && coach.coach_id">
                            <v-checkbox
                              v-model="currentCoach"
                              color="blue"
                              hide-details
                              dense
                              :label="`${$t('currentcoach')}(${coach_count})`"
                              />
                            <v-checkbox
                              v-model="prevCoach"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('previouscoach')"
                              />
                          </template>

                          <v-select
                            v-model="perPage"
                            :items="countPerPage"
                            attach
                            dense
                            class="col-4 col-sm-2 col-md-1"
                            :hint="$t('countperpage')"
                            />

                          <v-text-field
                            v-model="pPageByUser"
                            class="col-3 col-sm-2 col-md-1"
                            dense
                            :hint="$t('countperpage')"
                            type="number"
                            max=149
                            min=1
                            v-on:keyup.enter="perPageChange"
                          />
                        </div>
                      </v-container>

                      <v-simple-table
                        v-if="lastAvgLoaded && otherLastAvg.match[chosenCategory]" 
                        class="table-row-35 border-lightgrey text-center"
                        style="border-radius: 0">
                        <tbody>
                          <tr>
                            <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                            <td :title="$t('pts.tooltip')">{{ $t('pts.label') }}</td>
                            <td :title="$t('w.tooltip')">{{ $t('w.label') }}</td>
                            <td :title="$t('d.tooltip')">{{ $t('d.label') }}</td>
                            <td :title="$t('l.tooltip')">{{ $t('l.label') }}</td>
                            <td :title="$t('diff.tooltip')">{{ $t('diff.label') }}</td>
                            <td :title="$t('diff_av.tooltip')">{{ $t('diff_av.label') }}</td>
                            <td :title="$t('total_av.tooltip')">{{ $t('total_av.label') }}</td>
                            <td :title="$t('teamtotal_av.tooltip')">{{ team.name }}</td>
                            <td :title="$t('opponenttotal_av.tooltip')"> {{ $t('opponenttotal_av.label') }}</td>
                          </tr>
                          
                          <tr class="font-weight-bold">
                            <td>{{ otherLastAvg.match[chosenCategory] }}</td>
                            <td>{{ otherLastAvg.point[chosenCategory] }}</td>
                            <td>{{ otherLastAvg.win[chosenCategory] }}</td>
                            <td>{{ otherLastAvg.draw[chosenCategory] }}</td>
                            <td>{{ otherLastAvg.lost[chosenCategory] }}</td>
                            <td>{{ otherLastAvg.diff[chosenCategory] }}</td>
                            <td>{{ otherLastAvg.diff_av[chosenCategory] }}</td>
                            <td>{{ otherLastAvg.total[chosenCategory] }}</td>
                            <td>{{ otherLastAvg.t1[chosenCategory] }}</td>
                            <td>{{ otherLastAvg.t2[chosenCategory] }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>

                      <div class="curPointer pt-5 pb-2">
                        <a :class="{'d-none' : page == lastPage}" class="ml-1 mr-4" @click="prev()">{{ $t('previous') }}</a>
                        <a :class="{'d-none' : !page}" @click="next()">{{ $t('next') }}</a>
                      </div>

                      <v-simple-table v-if="statsLoaded" class="table-row-35 cell-py-10 border-lightgrey" style="border-radius: 0">
                        <thead>
                          <tr>
                            <th>{{ $t('date') }}</th>
                            <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                            <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                            <th>{{ $t('home') }}</th>
                            <th></th>
                            <th class="text-right">{{ $t('away') }}</th>
                            <th class="text-center">{{ $t('detail') }}</th>
                          </tr>
                        </thead>
                        
                        <tbody>
                          <tr v-for="(game, index) in last[page]"
                            :key="index">
                            <td class="overflow-anywhere blue-grey--text">
                              <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                              <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                            </td>

                            <td :class="{'d-none': $vuetify.breakpoint.xs}" class="overflow-anywhere">
                              <router-link :to="{name: 'league', params: {lid: game.league.league_id}}" class="blue-grey--text">
                                {{ game.league.name }}
                              </router-link>
                            </td>

                            <td :class="{'d-none': $vuetify.breakpoint.xs}" class="blue-grey--text">
                              {{ game.round ? game.round.name : '' }}
                            </td>

                            <td v-if="game.local_id != team.id">
                              <router-link :to="{name: 'team', params: {id: game.local_id}}" class="overflow-anywhere blue-grey--text">
                                {{ game.local_name }}
                              </router-link>
                            </td>

                            <td v-else class="overflow-anywhere black--text">{{ game.local_name }}</td>

                            <td
                              v-if="game.stats.length"
                              class="white--text"
                              :style="'background-color:' + stats_color[game.stats[chosenCategory][2]]">
                              <div class="d-flex justify-space-around align-center subtitle-2">
                                <span>{{ game.stats[chosenCategory][0] }}</span>
                                <span> - </span>
                                <span>{{ game.stats[chosenCategory][1] }}</span>
                              </div>
                            </td>

                            <td
                              v-else
                              class="white--text"
                              :style="'background-color:' + stats_color[1]">
                              <div class="d-flex justify-space-around align-center subtitle-2">
                                <span>?</span>
                                <span> - </span>
                                <span>?</span>
                              </div>
                            </td>

                            <td v-if="game.visitor_id != team.id" class="text-right">
                              <router-link :to="{name: 'team', params: {id: game.visitor_id}}" class="overflow-anywhere blue-grey--text">
                                {{ game.visitor_name }}
                              </router-link>
                            </td>

                            <td v-else class="overflow-anywhere text-right black--text">{{ game.visitor_name }}</td>

                            <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                          </tr>
                        </tbody>
                      </v-simple-table>

                      <div v-else>
                        <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                      </div>
                    </template>
                  </div>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </div>
      </template>
    </div>
  </v-app>
</template>

<script>

import { Card, Tabs, TabPane } from '@/components'
import axios from 'axios'
import moment from 'moment-timezone'

export default {
  components: {
    Card,
    Tabs,
    TabPane,
  },
  data() {
    return {
      tz: localStorage.tz ? localStorage.tz : '+0',
      team: {
          id: '',
          name: '',
          logo: '',
          founded: '',
          isNational: '',
      },
      league: {
          id: '',
          name: '',
          logo: '',
          season_id: '',
      },
      country: {
          id: '',
          name: '',
          flag: '',
      },
      venue: [],
      coach: null,
      allCoaches: [],
      last: [],
      lastAvg: {},
      squad: null,
      tournamentForPlayers: null,
      seasonForPlayers: null,
      subCorner: {
        dt: '',
        home: '',
        away: '',
        data: [],
      },
      subCard: {
        dt: '',
        home: '',
        away: '',
        data: [],
      },
      subGoal: {
        dt: '',
        home: '',
        away: '',
        data: [],
      },
      player: null,

      page: 0,
      perPage: 20,
      pPageByUser: '',
      lastPage: -1,
      currentCoach: false,
      prevCoach: false,

      fLocal: true,
      fVisitor: true,

      leagueLoaded: false,
      querySeasons: '',
      chosenLeagues: [],
      chosenSeasons: [],
      leagues: [],
      seasons: [],
      attendence: new Map(),
      totalSeasons: [],
      leaguesAndAll: [],

      // Modal Dialogs
      subCornerDialog: false,
      subGoalDialog: false,
      subCardDialog: false,
      coachDialog: false,
      trophyDialog: false,
      refereeDialog: false,
      
      index4trophy: 0,

      basicLoaded: false,
      lastLoaded: false,
      lastAvgLoaded: false,
      statsLoaded: false,
      authorization: false,
      message: 'notlogin',
      tab: null,
      tabLoaded: false,
      items: [
        'basics',
        'squad',
        'goals',
        'corners',
        'cards',
        'scorers',
        'stats',
        'transfer',
        'other'],
      scorers: null,
      // originScorers: null,
      tournamentForScorers: null,
      seasonForScorers: null,
      mostAppears: null,
      rivals: null,
      trophies: null,
      transfers: [],
      refereeStats: new Map(),
      chosenReferee: null,
      transferColors: ['brown', 'orange', 'green'],
      teamStats: null,
      originStats: null,
      teamStatsHeaders: [
        {text: 'Stage', align: 'center', value: 'stage'},
      ],
      tournamentForStats: null,
      seasonForStats: null,
      upcomings: null,
      sidelined: null,
      // expandedSidelined: [],
      current_leagues: null,
      curStage: 0,
      ranking: [],
      position: ['goalkeeper', 'defender', 'midfielder', 'attacker'],
      stats_color: [
        '#28a745',
        '#ffc107',
        '#dc3545'
      ],

      // begin of 'other' tab
      chosenCategory: 0,

      otherLastAvg: {},
      // end of 'other' tab

      // begin of half / full time combobox
      fullHalf: 0,
      timeCategories: [
        {text: 'fulltime', index: 0},
        {text: 'half1', index: 1},
        {text: 'half2', index: 2}],
      // end of half / full time combobox

      playerDialog: false,
      venueDialog: false,

      statusCode: [
        {'short_name': 'NS', 'name': 'Not Started', 'info': 'The initial status of a game'},
        {'short_name': '1st', 'name': '1st Half', 'info': 'The game is currently inplay in the 1st half'},
        {'short_name': 'HT', 'name': 'Half-Time', 'info': 'The game currently is in half-time'},
        {'short_name': 'BRK', 'name': 'Regular time finished', 'info': 'Waiting for penalties to start'},
        {'short_name': 'FT', 'name': 'Full-Time', 'info': 'The game has ended after 90 minutes. When a game goes into extra time, the FT status will be presented for a few seconds and then move to the BREAK status. '},
        {'short_name': '1et', 'name': 'Extra Time', 'info': 'The game currently is in extra time in the 1st half, can happen in knockout games'},
        {'short_name': 'AET', 'name': 'Finished after extra time', 'info': 'The game has finished after 120 minutes'},
        {'short_name': 'FTP', 'name': 'Full-Time after penalties', 'info': 'Finished after penalty shootout'},
        {'short_name': 'PEN', 'name': 'Penalty Shootout', 'info': "ET status didn't get a winner, penalties are taken to determine the winner"},
        {'short_name': 'POST', 'name': 'PostPoned', 'info': 'The game is postponed'},
        {'short_name': 'SUSP', 'name': 'SUSPENDED', 'info': 'The game has suspended and will continue at a later time or day'},
        {'short_name': 'CANC', 'name': 'Cancelled', 'info': 'The game has been cancelled'},
        {'short_name': 'TBA', 'name': 'To Be Announced', 'info': 'The game does not have a confirmed date and time yet. It will be announced later on.'},
        {'short_name': 'WO', 'name': 'Walk Over', 'info': 'Awarding of a victory to a contestant because there are no other contestants'},
        {'short_name': 'ABAN', 'name': 'Abandoned', 'info': 'The game has abandoned and will continue at a later time or day'},
        {'short_name': 'DELA', 'name': 'Delayed', 'info': 'The game is delayed so it will start later'},
        {'short_name': 'AWAR', 'name': 'Awarded', 'info': 'Winner is being decided externally'},
        {'short_name': 'INT', 'name': 'Interrupted', 'info': 'The game has been interrupted. Can be due to bad weather'},
        {'short_name': 'AU', 'name': 'Awaiting Updates', 'info': 'Can occur when there is a connectivity issue or something'},
        {'short_name': 'DEL', 'name': 'Deleted', 'info': 'Game is not available anymore via normal api calls because it has been replaced. This can happen in leagues that have a lot of changes in their schedules. The games can still be retrieved by adding deleted=1 to your request so you can update your system properly'},
        {'short_name': 'ETB', 'name': 'Extra Time - Break', 'info': 'The game has suspended and will continue at a later time or day'},
        {'short_name': '2nd', 'name': '2nd Half', 'info': 'The game is currently inplay in the 2nd half'},
        {'short_name': '2et', 'name': 'ET - 2nd Half', 'info': 'The game currently is in extra time in the 2nd half, can happen in knockout games'},
        {},
        {'short_name': 'PENB', 'name': 'Penalties - Break', 'info': 'Waiting for penalties to start'},
        {'short_name': 'PEN', 'name': 'Pending', 'info': 'The fixture is awaiting an update'}
      ],

      transfer_pg: 1,
      transfer_has_more: null,
      transfer_loading: false,

      // count of matches with current coach
      coach_count: null,

      // last data including stats
      lastData: [],
    }
  },
  computed: {
    countPerPage () {
      return [20, 30, 40, 60, 80, this.$t('all')]
    },
      
    categories () {
      return [
        {text: this.$t('stats_type.SHOTS_TOTAL'), index: 0},
        {text: this.$t('stats_type.SHOTS_ON_TARGET'), index: 1},
        {text: this.$t('stats_type.SHOTS_BLOCKED'), index: 2},
        {text: this.$t('stats_type.SHOTS_INSIDEBOX'), index: 3},
        {text: this.$t('stats_type.ATTACKS'), index: 4},
        {text: this.$t('stats_type.DANGEROUS_ATTACKS'), index: 5},
        {text: this.$t('stats_type.PASSES_TOTAL'), index: 6},
        {text: this.$t('stats_type.PASSES_ACCURATE'), index: 7},
        {text: this.$t('stats_type.BALL_SAFE'), index: 8},
        {text: this.$t('stats_type.FOULS'), index: 9},
        {text: this.$t('stats_type.FREE_KICKS'), index: 10},
        {text: this.$t('stats_type.GOAL_ATTEMPTS'), index: 11},
        {text: this.$t('stats_type.GOAL_KICKS'), index: 12},
        {text: this.$t('stats_type.INJURIES'), index: 13},
        {text: this.$t('stats_type.OFFSIDES'), index: 14},
        {text: this.$t('stats_type.PENALTIES'), index: 15},
        {text: this.$t('stats_type.BALL_POSSESSION'), index: 16},
        {text: this.$t('stats_type.SAVES'), index: 17},
        {text: this.$t('stats_type.SUBSTITUTIONS'), index: 18},
        {text: this.$t('stats_type.TACKLES'), index: 19},
        {text: this.$t('stats_type.THROWINS'), index: 20}
      ]
    },

    sidelinedHeaders () {
      return [
        {text: this.$t('sidelined'), align: 'start', sortable: false, value: 'player'},
        {text: this.$t('injury'), align: 'start', value: 'description'},
        {text: this.$t('from'), align: 'center d-none d-sm-table-cell', value: 'start_date'},
        {text: this.$t('to'), align: 'center d-none d-sm-table-cell', value: 'end_date'},
      ]
    },

    /* event for selection tournament on statistics */
    chosenSeasonsForStats () {
      let ss = ["All"]
      if (this.tournamentForStats == 0) {
        return ss.concat(this.seasons)
      } else {
        let tmp = this.totalSeasons.filter(item => item.league_id === this.tournamentForStats)
        tmp = tmp.sort((a, b) => (b.season_name > a.season_name) ? 1 : ((a.season_name > b.season_name) ? -1 : 0))
        return tmp
      }
    },

    /* event for selection tournament on scorers */
    chosenSeasonsForScorers () {
      let tmp = this.totalSeasons.filter(item => item.league_id === this.tournamentForScorers)
      tmp = tmp.sort((a, b) => (b.season_name > a.season_name) ? 1 : ((a.season_name > b.season_name) ? -1 : 0))
      return tmp
    },
    
    /* event for selection tournament on players */
    chosenSeasonsForPlayers () {
      let ss = this.totalSeasons.filter(item => item.league_id === this.tournamentForPlayers)
      ss = ss.sort((a, b) => (b.season_name > a.season_name) ? 1 : ((a.season_name > b.season_name) ? -1 : 0))
      return ss
    }
  },
  mounted() {
    this.getBasics()
    // this.getLast()
  },
  watch: {
      async '$route' (to, from) {
        //initialization when new team is shown
        this.last = []
        this.lastAvg = {}
        this.players = []
        
        this.page = 0
        this.lastPage = -1
        
        this.fLocal = true
        this.fVisitor = true

        this.calledLast = false
        this.calledLeague = false
        this.calledSquad = false
        this.leagueLoaded = false
        this.leagues = []
        this.seasons = []
        this.totalSeasons = []

        this.basicLoaded = false
        this.lastAvgLoaded = false
        this.statsLoaded = false
        this.lastLoaded = false
                
        this.currentCoach = false
        this.prevCoach = false
        this.chosenLeagues = []
        this.chosenSeasons = []
        this.querySeasons = ''
        
        this.getBasics()
      },

      perPage: function (val) {
        if (this.lastLoaded) {
          this.page = 0
          this.lastPage = -1
          this.last = []
          this.lastData = []
          this.statsLoaded = false
        
          this.getLast()
        }
      },

      chosenLeagues: function (val) {
        if (this.lastLoaded)
          this.changeQSeasons()
      },

      chosenSeasons: function (val) {
        if (this.lastLoaded)
          this.changeQSeasons()
      },

      currentCoach (val) {
        if (this.lastLoaded) {
          this.page = 0
          this.lastPage = -1
          this.last = []
          this.lastData = []
          this.statsLoaded = false
          
          this.getLast()
        }
      },

      prevCoach (val) {
        if (this.lastLoaded) {
          this.page = 0
          this.lastPage = -1
          this.last = []
          this.lastData = []
          this.statsLoaded = false
          
          this.getLast()
        }
      },

      fLocal (val) {
        if (this.lastLoaded) {
          if (!val && !this.fVisitor) {
            this.fLocal = !val
          }else {
            this.page = 0
            this.lastPage = -1
            this.last = []
            this.lastData = []
            this.statsLoaded = false

            this.getLast()
          }
        }
      },
      
      fVisitor (val) {
        if (this.lastLoaded) {
          if (!val && !this.fLocal) {
            this.fVisitor = !val
          }else {
            this.page = 0
            this.lastPage = -1
            this.last = []
            this.lastData = []
            this.statsLoaded = false

            this.getLast()
          }
        }
      },

      fullHalf (val) {
        this.page = 0
        this.lastPage = -1
        this.last = []
        this.lastData = []
        this.statsLoaded = false

        this.getLast()
      },

      /* event for changes of Tournament on statistics */
      tournamentForStats (val) {
        if (this.originStats) {
          this.seasonForStats = (val == 0) ? this.chosenSeasonsForStats[0] : this.chosenSeasonsForStats[0].season_id
        }
      },

      /* event for changes of Season on statistics */
      seasonForStats (val) {
        this.tabLoaded = false
        if (this.tournamentForStats == 0 && val != "All") {
          let temp = this.totalSeasons.filter(function (ss) { return ss.season_name == val })
          let ss = []
          temp.forEach((item) => { ss.push(item.season_id) })
          this.getTeamStats(ss)
        } else {
          this.getTeamStats(val)
        }
      },

      /* event for changes of Tournament on scorers */
      tournamentForScorers (val) {
        this.seasonForScorers = (val == 0) ? this.chosenSeasonsForScorers[0] : this.chosenSeasonsForScorers[0].season_id
      },

      /* event for changes of Season on scorers */
      seasonForScorers (val) {
        this.tabLoaded = false

        this.getTeamScorers(val)
      },

      /* event for changes of Tournament on players */
      tournamentForPlayers (val) {
        if (this.squad) {
          this.seasonForPlayers = this.chosenSeasonsForPlayers[0].season_id
        }
      },

      /* event for changes of Season on players */
      seasonForPlayers (val) {
        this.tabLoaded = false
        this.getTeamPlayers(val)
      }
  },
  methods: {
      async getBasics() {
        //Etc/GMT-X will have an offset of +X and Etc/GMT+X will have an offset of -X.
        moment.tz.setDefault('Etc/GMT' + this.tz)

        axios
        .get('/v3/team/info/' + this.$route.params.id)
        .then(response => {
            let data = response.data

            this.team.id = data.id
            this.team.name = data.name
            this.team.logo = data.image_path ? data.image_path : ''
            this.team.type = data.type
            this.team.founded = data.founded ? data.founded : ''
            
            this.country.id = data.country ? data.country.id : ''
            this.country.name = data.country ? data.country.name : ''
            this.country.flag = data.country.image_path ? data.country.image_path : '/img/flag/'+ data.country_id + '.png'

            this.venue = data.venue ? data.venue : ''

            this.allCoaches = data.coaches ? this.modifyCoach(data.coaches) : ''
            this.coach = this.allCoaches[0]

            if (!this.coach.coach_id) {
              this.coach_count = -1
            }
            
            this.trophies = data.trophies.length ? this.modifyTrophies(data.trophies) : null
            
            this.rivals = data.rivals
            this.current_leagues = data.activeseasons
            this.upcomings = this.modifyUpcoming(data.upcoming)

            this.modifySeason(data.seasons)

            this.sidelined = data.sidelined
            
            this.basicLoaded = true

            this.getLast()
        })
        //.catch(error => {})
      },

      /*
        modify arrange of coaches
      */
      modifyCoach(coaches) {
        let res = []
        let arr = _.orderBy(coaches.filter((item) => item.position_id == 221), ['id'], ['desc'])

        return arr
        // forEach(element => {
        // })
      },

      /*
        modify array of seasons to a specific format
      */
      modifySeason(total) {
        let arr = []
        let latest = total[0]
        
        total.forEach(el => {
          let val = this.attendence.get(el.league_id)

          if (val) {
            this.attendence.set(el.league_id, ++val)
          } else {
            this.attendence.set(el.league_id, 1)
          }

          arr.push({
            season_id: el.id,
            season_name: el.name,
            league_id: el.league_id,
            league_name: el.league.name
          })

          // if ((new Date(el.league.last_played_at) > new Date(latest.league.last_played_at)) || 
            // ((el.league_id == latest.league_id) && (new Date(el.ending_at) > new Date(latest.ending_at)))) {
          if (new Date(el.ending_at) > new Date(latest.ending_at)) {
            latest = el
          }
        })

        this.getLeague()

        this.totalSeasons = arr

        this.league.id = latest.league_id
        this.league.name = latest.league.name
        this.league.logo = latest.league.image_path
        this.league.season_id = latest.id

        arr = []
        total.forEach(el => {
          arr.push(el.name)
        })
        this.seasons = arr.filter((item, index) => arr.indexOf(item) === index).sort().reverse()
      },

      /*
        method for modify stats from each player
      */
      modifyPlayerDetail(arr) {
        arr.forEach(pl => {
          let tmp = {
            "shots": {
              "shots_total": 0,
              "shots_on_target": 0
            },
            "substitutions": {
              "in": 0,
              "out": 0
            },
            "goals": {
              "scored": 0,
              "assists": 0,
              "conceded": 0,
              "owngoals": 0,
              "team_conceded": 0,
              "penalties": 0
            },
            "fouls": {
              "drawn": 0,
              "committed": 0
            },
            "cards": {
              "yellowcards": 0,
              "redcards": 0,
              "yellowredcards": 0
            },
            "passing": {
              "total_crosses": 0,
              "accurate_crosses": 0,
              "passes": 0,
              "accurate_passes": 0,
              "accurate_passes_percentage": 0,
              "key_passes": 0
            },
            "dribbles": {
              "attempts": 0,
              "success": 0,
              "dribbled_past": 0
            },
            "duels": {
              "total": 0,
              "won": 0
            },
            "other": {
              "aerials_won": 0,
              "big_chances_missed": 0,
              "punches": 0,
              "offsides": 0,
              "saves": 0,
              "saves_insidebox": 0,
              // "pen_scored": 0,
              // "pen_missed": 0,
              // "pen_saved": 0,
              // "pen_committed": 0,
              // "pen_won": 0,
              "hit_woodwork": 0,
              "tackles": 0,
              "blocked_shots": 0,
              "interceptions": 0,
              "clearances": 0,
              "dispossesed": 0,
              "minutes_played": 0,
              "bench": 0,
              "appearances": 0,
              "lineups": 0,
              "captain": 0,
            },
            "rating": null
          }

          if (pl.details) {
            pl.details.forEach(stats => {
              switch (stats.type_id) {
                case 40:
                  tmp.other.captain = stats.value.total
                  break;
                case 42:
                  tmp.shots.shots_total = stats.value.total
                  break;
                case 51:
                  tmp.other.offsides = stats.value.total
                  break;
                case 52:
                  tmp.goals.scored = stats.value.total
                  tmp.goals.penalties = stats.value.penalties
                  break;
                case 56:
                  tmp.fouls.committed = stats.value.total
                  break;
                case 57:
                  tmp.other.saves = stats.value.total
                  break;
                case 59:
                  tmp.substitutions.in = stats.value.in
                  tmp.substitutions.out = stats.value.out
                  break;
                case 64:
                  tmp.other.hit_woodwork = stats.value.total
                  break;
                case 78:
                  tmp.other.tackles = stats.value.total
                  break;
                case 79:
                  tmp.goals.assists = stats.value.total
                  break;
                case 80:
                  tmp.passing.passes = stats.value.total
                  break;
                case 83:
                  tmp.cards.redcards = stats.value.total
                  break;
                case 84:
                  tmp.cards.yellowcards = stats.value.total
                  break;
                case 85:
                  tmp.cards.yellowredcards = stats.value.total
                  break;
                case 86:
                  tmp.shots.shots_on_target = stats.value.total
                  break;
                case 88:
                  tmp.goals.team_conceded = stats.value.total
                break;
                case 94:
                  tmp.other.dispossesed = stats.value.total
                  break
                case 96:
                  tmp.fouls.drawn = stats.value.total
                  break;
                case 97:
                  tmp.other.blocked_shots = stats.value.total
                  break;
                case 98:
                  tmp.passing.total_crosses = stats.value.total
                  break;
                case 99:
                  tmp.passing.accurate_crosses = stats.value.total
                  break;
                case 100:
                  tmp.other.interceptions = stats.value.total
                  break;
                case 101:
                  tmp.other.clearances = stats.value.total
                  break;
                case 103:
                  tmp.other.punches = stats.value.total
                  break;
                case 104:
                  tmp.other.saves_insidebox = stats.value.total
                  break;
                case 105:
                  tmp.duels.total = stats.value.total
                  break;
                case 106:
                  tmp.duels.won = stats.value.total
                  break;
                case 107:
                  tmp.other.aerials_won = stats.value.total
                  break;
                case 108:
                  tmp.dribbles.attempts = stats.value.total
                  break;
                case 109:
                  tmp.dribbles.success = stats.value.total
                  break;
                case 110:
                  tmp.dribbles.dribbled_past = stats.value.total
                  break;
                // case 111:
                //   tmp.other.pen_scored = stats.value.total
                //   break;
                // case 112:
                //   tmp.other.pen_missed = stats.value.total
                //   break;
                // case 113:
                //   tmp.other.pen_saved = stats.value.total
                //   break;
                // case 114:
                //   tmp.other.pen_committed = stats.value.total
                //   break;
                // case 115:
                //   tmp.other.pen_won = stats.value.total
                //   break;
                case 116:
                  tmp.passing.accurate_passes = stats.value.total
                  break;
                case 117:
                  tmp.passing.key_passes = stats.value.total
                  break;
                case 118:
                  // highest, average, lowest
                  tmp.rating = stats.value.average
                  break;
                case 119:
                  tmp.other.minutes_played = stats.value.total
                  break;
                case 321:
                  tmp.other.appearances = stats.value.total
                  break;
                case 322:
                  tmp.other.lineups = stats.value.total
                  break;
                case 323:
                  tmp.other.bench = stats.value.total
                  break;
                case 324:
                  tmp.goals.owngoals = stats.value.total
                  break;
                case 581:
                  tmp.other.big_chances_missed = stats.value.total
                  break;
                case 1584:
                  tmp.passing.accurate_passes_percentage = stats.value.total
                  break;
              }
            })

            pl.details = tmp
          }
        })

        return arr
      },

      /*
        method for modify upcoming array
      */
      modifyUpcoming(arr) {
        arr.forEach(fx => {
          if (fx.participants[0].meta.location == "away") {
            fx.participants = [fx.participants[1], fx.participants[0]]
          }
        })

        return arr
      },

      /*
        method for modify Trophies array
      */
      modifyTrophies(arr) {
        let result = new Map() 

        arr.forEach(element => {
          if (!element.league || !element.season) return

          let tmp = result.get(element.league_id)

          if (tmp) {
            tmp.trophy[element.trophy_id - 1].push({
              id: element.season_id,
              name: element.season.name
            })
            result.set(element.league_id, tmp)
          } else {
            let trp = [[], []]

            trp[element.trophy_id - 1] = [{
              id: element.season_id,
              name: element.season.name
            }]

            result.set(element.league_id, {
              league_name: element.league.name,
              league_logo: element.league.image_path,
              trophy: trp
            })
          }
        });
        
        let res = [...result.entries()].sort((a, b) => 
          (b[1].trophy[0].length - a[1].trophy[0].length) || (b[1].trophy[1].length - a[1].trophy[1].length))
        
        res.forEach(element => {
          element[1].trophy[0] = _.orderBy(element[1].trophy[0], ['name'], ['desc'])
          element[1].trophy[1] = _.orderBy(element[1].trophy[1], ['name'], ['desc'])
        });

        return res
      },

      /*
        method for modify Scorers array
      */
     modifyScorer(arr) {
      let goal = [], assist = [], card = [], appearance = []

      arr.forEach(pl => {
        let tmp_card = 0

        pl.details.forEach(el => {
          switch (el.type_id) {
            case 52:
              goal.push({
                player_id: pl.player_id,
                player_name: pl.player.common_name,
                player_logo: pl.player.image_path,
                value: el.value.total
              })
              break;
            case 79:
              assist.push({
                player_id: pl.player_id,
                player_name: pl.player.common_name,
                player_logo: pl.player.image_path,
                value: el.value.total
              })
              break;
            case 321:
              appearance.push({
                player_id: pl.player_id,
                player_name: pl.player.common_name,
                player_logo: pl.player.image_path,
                value: el.value.total
              })
              break;
            default:
              tmp_card += el.value.total
          }
        })

        if (tmp_card) {
          card.push({
            player_id: pl.player_id,
            player_name: pl.player.common_name,
            player_logo: pl.player.image_path,
            value: tmp_card
          })
        }
      })

      return [
        _.orderBy(goal, ['value'], ['desc']),
        _.orderBy(assist, ['value'], ['desc']),
        _.orderBy(card, ['value'], ['desc']),
        _.orderBy(appearance, ['value'], ['desc'])
      ]
     },

      getTeamPlayers(val) {
        axios
        .get('/v3/team/squad/' + val + '/' + this.team.id,
          { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
        .then(response => {
          /* 
            Set squad from null to {}
            To prevent duplication api calls for unsubscribed user
          */
          this.squad = {}

          if (response.data.type == 0) {
            this.message = "notsubscribe"
          }else if (response.data.type == 1) {
            this.message = "expiredsubscribe"
          }else {
            
            let pls = this.modifyPlayerDetail(response.data.players)
            if (pls.length) {
              this.squad = {goalkeeper: [],
                          defender: [],
                          midfielder: [],
                          attacker: []}

              pls.forEach((item) => {
                if (item.position_id == 24) this.squad.goalkeeper.push(item)
                else if (item.position_id == 25) this.squad.defender.push(item)
                else if (item.position_id == 26) this.squad.midfielder.push(item)
                else this.squad.attacker.push(item)
              })

              /* for modal on player */
              this.player = this.squad.goalkeeper[0]
            } else {
              this.squad = []
            }          

            this.authorization = true          
          }
          this.tabLoaded = true
        })
        .catch(error => {
          if (error.response.status == 401) {
            localStorage.removeItem('token')
            localStorage.removeItem('uid')
            location.reload()
          }              
        })
      },

      /*
        function for filtering stats by selected seasons
      */
      filterStats(ss) {
        /*
          -filter stats by ss
          -sum up all stages' stats and convert below stats;
            avg_player_rating to avg_player_rating_per_stage
            avg_first_goal_scored to avg_first_goal_scored_per_stage
        */
        let stats
        if (this.tournamentForStats == 0) {
          if (this.seasonForStats != "All") {
            stats = this.originStats.filter(item => ss.includes(item.season_id))
          } else {
            stats = this.originStats
          }
        } else {
          stats = this.originStats.filter(item => item.season_id === this.seasonForStats)
        }

        let tmp = {
          win: {
            all: { count: 0, percentage: 0},
            home: { count: 0, percentage: 0},
            away: { count: 0, percentage: 0}
          },
          draw: {
            all: { count: 0, percentage: 0},
            home: { count: 0, percentage: 0},
            away: { count: 0, percentage: 0}
          },
          lost: {
            all: { count: 0, percentage: 0},
            home: { count: 0, percentage: 0},
            away: { count: 0, percentage: 0}
          },
          goals: {
            all: { count: 0, average: 0, first: 0 },
            home: { count: 0, percentage: 0, average: 0, first: 0 },
            away: { count: 0, percentage: 0, average: 0, first: 0 }
          },
          goals_conceded: {
            all: { count: 0, average: 0, first: 0 },
            home: { count: 0, percentage: 0, average: 0, first: 0 },
            away: { count: 0, percentage: 0, average: 0, first: 0 }
          },
          clean_sheet: {
            all: { count: 0, percentage: 0},
            home: { count: 0, percentage: 0},
            away: { count: 0, percentage: 0}
          },
          penalties: { scored: 0, missed: 0, conversion_rate: 0},
          attacks: { count: 0, average: 0 },
          dangerous_attacks: { count: 0, average: 0 },
          ball_possession: { count: 0, average: 0 },
          // no_ball_possession: 0,
          fouls: { count: 0, average: 0 },
          offsides: { count: 0, average: 0 },
          redcards: { count: 0, average: 0 },
          yellowcards: { count: 0, average: 0 },
          corners: { count: 0, average: 0 },
          highest_rated_player: { rating: 0, player_id: null, player_name: null },
          tackles: { count: 0, average: 0 }
        }

        if (stats.length > 1) {
          let ct = stats.length

          stats.forEach((stg, ind) => {
            stg.details.forEach(element => {
              switch (element.type_id) {
                case 34:
                  tmp.corners.count += element.value.count
                  break
                case 43:
                  tmp.attacks.count += element.value.count
                  break
                case 44:
                  tmp.dangerous_attacks.count += element.value.count
                  break
                case 45:
                  tmp.ball_possession.count += element.value.count
                  break
                case 47:
                  tmp.penalties.scored += element.value.scored
                  tmp.penalties.missed += element.value.missed
                  break
                case 51:
                  tmp.offsides.count += element.value.count
                  break
                case 52:
                  tmp.goals.all.count += element.value.all.count
                  tmp.goals.home.count += element.value.home.count
                  tmp.goals.away.count += element.value.away.count
                  break
                case 56:
                  tmp.fouls.count = element.value.count
                  break
                case 78:
                  tmp.tackles.count = element.value.count
                  break
                case 83:
                  tmp.redcards.count += element.value.count
                  // tmp.redcards.average = element.value.average
                  break
                case 84:
                  tmp.yellowcards.count += element.value.count
                  // tmp.yellowcards.average = element.value.average
                  break
                case 88:
                  tmp.goals_conceded.all.count += element.value.all.count
                  tmp.goals_conceded.home.count += element.value.home.count
                  tmp.goals_conceded.away.count += element.value.away.count
                  break
                case 194:
                  tmp.clean_sheet.all.count += element.value.all.count
                  tmp.clean_sheet.home.count += element.value.home.count
                  tmp.clean_sheet.away.count += element.value.away.count
                  break
                case 211:
                  if (tmp.highest_rated_player.rating < element.value.rating) {
                    tmp.highest_rated_player = element.value
                  }
                  break
                case 214:
                  tmp.win.all.count += element.value.all.count
                  tmp.win.home.count += element.value.home.count
                  tmp.win.away.count += element.value.away.count
                  break
                case 215:
                  tmp.draw.all.count += element.value.all.count
                  tmp.draw.home.count += element.value.home.count
                  tmp.draw.away.count += element.value.away.count
                  break
                case 216:
                  tmp.lost.all.count += element.value.all.count
                  tmp.lost.home.count += element.value.home.count
                  tmp.lost.away.count += element.value.away.count
                  break
              }
            })
          })

          tmp.totalPlayed = {
            "all": tmp.win.all.count + tmp.draw.all.count + tmp.lost.all.count,
            "home": tmp.win.home.count + tmp.draw.home.count + tmp.lost.home.count,
            "away": tmp.win.away.count + tmp.draw.away.count + tmp.lost.away.count
          }

          tmp.corners.average = (tmp.corners.count / tmp.totalPlayed.all).toFixed(2)
          tmp.fouls.average = (tmp.fouls.count / tmp.totalPlayed.all).toFixed(2)

          tmp.goals.all.average = (tmp.goals.all.count / tmp.totalPlayed.all).toFixed(2)
          tmp.goals.home.average = (tmp.goals.home.count / tmp.totalPlayed.home).toFixed(2)
          tmp.goals.away.average = (tmp.goals.away.count / tmp.totalPlayed.away).toFixed(2)

          tmp.goals_conceded.all.average = (tmp.goals_conceded.all.count / tmp.totalPlayed.all).toFixed(2)
          tmp.goals_conceded.home.average = (tmp.goals_conceded.home.count / tmp.totalPlayed.home).toFixed(2)
          tmp.goals_conceded.away.average = (tmp.goals_conceded.away.count / tmp.totalPlayed.away).toFixed(2)

          tmp.ball_possession.average = (tmp.ball_possession.count / tmp.totalPlayed.all).toFixed(2)

          tmp.goals.all.average = (tmp.goals.all.count / tmp.totalPlayed.all).toFixed(2)
          tmp.goals.home.average = (tmp.goals.home.count / tmp.totalPlayed.home).toFixed(2)
          tmp.goals.away.average = (tmp.goals.away.count / tmp.totalPlayed.away).toFixed(2)

          tmp.goals_conceded.all.average = (tmp.goals_conceded.all.count / tmp.totalPlayed.all).toFixed(2)
          tmp.goals_conceded.home.average = (tmp.goals_conceded.home.count / tmp.totalPlayed.home).toFixed(2)
          tmp.goals_conceded.away.average = (tmp.goals_conceded.away.count / tmp.totalPlayed.away).toFixed(2)

          this.teamStats = tmp
        } else if (stats.length == 1){
          stats[0].details.forEach(element => {
            switch (element.type_id) {
              case 34:
                tmp.corners = element.value
                break
              case 43:
                tmp.attacks = element.value
                break
              case 44:
                tmp.dangerous_attacks = element.value
                break
              case 45:
                tmp.ball_possession = element.value
                break
              case 47:
                tmp.penalties = element.value
                break
              case 51:
                tmp.offsides = element.value
                break
              case 52:
                tmp.goals = element.value
                break
              case 56:
                tmp.fouls = element.value
                break
              case 78:
                tmp.tackles = element.value
                break
              case 83:
                tmp.redcards.count = element.value.count
                tmp.redcards.average = element.value.average
                break
              case 84:
                tmp.yellowcards.count = element.value.count
                tmp.yellowcards.average = element.value.average
                break
              case 88:
                tmp.goals_conceded = element.value
                break
              case 194:
                tmp.clean_sheet = element.value
                break
              case 211:
                tmp.highest_rated_player = element.value
                break
              case 214:
                tmp.win = element.value
                break
              case 215:
                tmp.draw = element.value
                break
              case 216:
                tmp.lost = element.value
                break
            }
          })
          this.teamStats = tmp
        } else {
          this.teamStats = null
        }
      },

      /*
        function for getting team stats by season
      */
      getTeamStats(ss) {
        // call api only when first stats tab opening
        if (!this.originStats) {
          axios
            .get('/v3/team/stats/' + this.$route.params.id,
              { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
            .then(response => {
              /* 
                Set Statistics from null to []
                To prevent duplication api calls for unsubscribed user
              */
              this.originStats = []

              if (response.data.type == 0) {
                this.message = "notsubscribe"
              }else if (response.data.type == 1) {
                this.message = "expiredsubscribe"
              }else {
                this.originStats = response.data.stats
                this.filterStats(ss)
                this.authorization = true
              }
              this.tabLoaded = true
            })
            .catch(error => {
              if (error.response.status == 401) {
                localStorage.removeItem('token')
                localStorage.removeItem('uid')
                location.reload()
              }              
            })
        } else {
          this.filterStats(ss)
          this.tabLoaded = true
        }
      },

      /*
        function for getting team scorers by season
      */
      getTeamScorers(ss) {
        // call api only when first scorers tab opening
        axios
          .get('/v3/team/scorer/' + ss + '/' + this.$route.params.id,
            { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
          .then(response => {
            /* 
              Set originScorers from null to {}
              To prevent duplication api calls for unsubscribed user
            */
            this.scorers = []

            if (response.data.type == 0) {
              this.message = "notsubscribe"
            }else if (response.data.type == 1) {
              this.message = "expiredsubscribe"
            }else {
              
              if (response.data.scorer.length) {
                this.scorers = this.modifyScorer(response.data.scorer)
              }
              
              this.authorization = true
            }
            this.tabLoaded = true
          })
          .catch(error => {
            if (error.response.status == 401) {
              localStorage.removeItem('token')
              localStorage.removeItem('uid')
              location.reload()
            }              
          })
      },

      /*
        get transfers from team by pagination
      */
      getTeamTransfer() {
        this.transfer_loading = true
        axios
          .get('/v3/team/transfer/' + this.$route.params.id + '/' + this.transfer_pg,
            { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
          .then(response => {
            if (response.data.type == 0) {
              this.message = "notsubscribe"
            }else if (response.data.type == 1) {
              this.message = "expiredsubscribe"
            }else {
              this.transfers = this.transfers.concat(response.data.transfer)
              this.transfer_pg += 1
              this.transfer_has_more = response.data.has_more
              this.transfer_loading = false
            }
            this.tabLoaded = true
          })
          .catch(error => {
            if (error.response.status == 401) {
              localStorage.removeItem('token')
              localStorage.removeItem('uid')
              location.reload()
            }              
          })
      },

      // return x % n
      get_remaining(x, n) {
        return (x - 1 + n) % n + 1;
      },

      // get suitable per page
      get_perPage(total, per) {
        for (let i = per; i <= total; i++) {
          if (this.get_remaining(total, i) >= per) return i
        }
        return total
      },

      getLeague() {
        if (!localStorage.token) {
          this.message = "notlogin"
        } else {
          axios
            .get('/v3/team/league/' + this.$route.params.id,
              { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
            .then(response => {
              if (response.data.type == 0) {
                this.message = "notsubscribe"
              }else if (response.data.type == 1) {
                this.message = "expiredsubscribe"
              }else {
                let arr = []
                response.data.leagues.forEach(el => {
                  arr.push({
                    league_id: el.id,
                    league_name: el.name,
                    attendence: this.attendence.get(el.id),
                    // last_played_at: el.last_played_at,
                    // season_id: (el.seasons.sort((a, b) => b.id - a.id))[0].id
                  })
                })

                this.leagues = arr.sort((a, b) => ((b.attendence - a.attendence) || (a.league_id - b.league_id)))
                // this.leagues = arr.sort((a, b) => (new Date(b.last_played_at) - new Date(a.last_played_at)))
                
                let tmp = [{league_id: 0, league_name: "All"}]
                this.leaguesAndAll = tmp.concat(this.leagues)
                
                this.leagueLoaded = true
                this.showLeague = true
                this.authorization = true
              }
            })
            .catch(error => {
              if (error.response.status == 401) {
                localStorage.removeItem('token')
                localStorage.removeItem('uid')
                location.reload()
              }              
            })
        }
      },
      
      changeQSeasons() {
        let querys = ''
        this.totalSeasons.forEach((item) => {
          let leagues = this.chosenLeagues.filter(function (league) { return league == item.league_id })
          let seasons = this.chosenSeasons.filter(function (season) { return season == item.season_name })
          if((this.chosenLeagues.length == 0 || leagues.length > 0) && (this.chosenSeasons.length == 0 || seasons.length > 0))
            querys += (item.season_id + ',')
        })
        
        if(!querys) {
          if (this.lastLoaded)
            querys = '-1'
          else
            querys = 0
        }

        this.querySeasons = querys

        this.page = 0
        this.lastPage = -1
        this.last = []
        this.lastData = []
        this.statsLoaded = false

        this.getLast()
      },

      getLastStats(stats) {
        let lastAvg = {}
        let otherLastAvg = {}

        lastAvg.cornerMatch = stats.corner[0] + stats.corner[1] + stats.corner[2]
        lastAvg.cornerWDL = stats.corner
        lastAvg.cornerPoint = 3 * stats.corner[0] + stats.corner[1]
        lastAvg.cornerDiff = stats.tCorner + ' - ' + stats.oCorner
        lastAvg.cornerDiff_avg = !lastAvg.cornerMatch ? 0 : ((stats.tCorner - stats.oCorner) / lastAvg.cornerMatch).toFixed(2)
        lastAvg.cornerTotal = !lastAvg.cornerMatch ? 0 : ((stats.tCorner + stats.oCorner) / lastAvg.cornerMatch).toFixed(2)
        lastAvg.cornerT1 = !lastAvg.cornerMatch ? 0 : (stats.tCorner / lastAvg.cornerMatch).toFixed(2)
        lastAvg.cornerT2 = !lastAvg.cornerMatch ? 0 : (stats.oCorner / lastAvg.cornerMatch).toFixed(2)

        lastAvg.goalMatch = stats.goal[0] + stats.goal[1] + stats.goal[2]
        lastAvg.goalWDL = stats.goal
        lastAvg.goalPoint = 3 * stats.goal[0] + stats.goal[1]
        lastAvg.goalDiff = stats.tGoal + ' - ' + stats.oGoal
        lastAvg.goalDiff_avg = !lastAvg.goalMatch ? 0 : ((stats.tGoal - stats.oGoal) / lastAvg.goalMatch).toFixed(2)
        lastAvg.goalTotal = !lastAvg.goalMatch ? 0 : ((stats.tGoal + stats.oGoal) / lastAvg.goalMatch).toFixed(2)
        lastAvg.goalT1 = !lastAvg.goalMatch ? 0 : (stats.tGoal / lastAvg.goalMatch).toFixed(2)
        lastAvg.goalT2 = !lastAvg.goalMatch ? 0 : (stats.oGoal / lastAvg.goalMatch).toFixed(2)

        lastAvg.cardMatch = stats.card
        lastAvg.cardDiff_yc = stats.tYC + ' - ' + stats.oYC
        lastAvg.cardTotal_yc = !lastAvg.cardMatch ? 0 : ((stats.tYC + stats.oYC) / lastAvg.cardMatch).toFixed(2)
        lastAvg.cardT1_yc = !lastAvg.cardMatch ? 0 : (stats.tYC / lastAvg.cardMatch).toFixed(2)
        lastAvg.cardT2_yc = !lastAvg.cardMatch ? 0 : (stats.oYC / lastAvg.cardMatch).toFixed(2)
        lastAvg.cardDiff_rc = stats.tRC + ' - ' + stats.oRC
        lastAvg.cardTotal_rc = !lastAvg.cardMatch ? 0 : ((stats.tRC + stats.oRC) / lastAvg.cardMatch).toFixed(2)
        lastAvg.cardT1_rc = !lastAvg.cardMatch ? 0 : (stats.tRC / lastAvg.cardMatch).toFixed(2)
        lastAvg.cardT2_rc = !lastAvg.cardMatch ? 0 : (stats.oRC / lastAvg.cardMatch).toFixed(2)
      
        // get avg stats for other tab
        otherLastAvg.win = stats.avg_w
        otherLastAvg.draw = stats.avg_d
        otherLastAvg.lost = stats.avg_l
        otherLastAvg.point = []
        otherLastAvg.match = []
        otherLastAvg.diff = []
        otherLastAvg.diff_av = []
        otherLastAvg.total = []
        otherLastAvg.t1 = []
        otherLastAvg.t2 = []
        for(let i = 0; i < 21; i++) {
          otherLastAvg.point.push((3 * stats.avg_w[i]) + stats.avg_d[i])
          otherLastAvg.match.push(stats.avg_w[i] + stats.avg_d[i] + stats.avg_l[i])
          otherLastAvg.diff.push(stats.avg_t1[i] + ' - ' + stats.avg_t2[i])
          otherLastAvg.diff_av.push(!otherLastAvg.match[i] ? 0 : ((stats.avg_t1[i] - stats.avg_t2[i]) / otherLastAvg.match[i]).toFixed(2))
          otherLastAvg.total.push(!otherLastAvg.match[i] ? 0 : ((stats.avg_t1[i] + stats.avg_t2[i]) / otherLastAvg.match[i]).toFixed(2))
          otherLastAvg.t1.push(!otherLastAvg.match[i] ? 0 : (stats.avg_t1[i] / otherLastAvg.match[i]).toFixed(2))
          otherLastAvg.t2.push(!otherLastAvg.match[i] ? 0 : (stats.avg_t2[i] / otherLastAvg.match[i]).toFixed(2))
        }

        return [lastAvg, otherLastAvg]
      },

      getLast() {
        if (!localStorage.token) {
          this.msg_last = "Sorry, please login to view the Last Matches. Thanks."
          this.lastLoaded = true
        } else {
          let seasons, coach, prevcoach, home, away, current_coach
          home = this.fLocal ? 1 : 0
          away = this.fVisitor ? 1: 0
          coach = this.currentCoach ? this.coach.coach_id : 0
          prevcoach = this.prevCoach ? this.coach.coach_id : 0
          seasons = !this.querySeasons ? 0 : this.querySeasons
          current_coach = this.coach_count == null ? this.coach.coach_id : 0

          axios
          .get(
            '/getLast/',{
              params: {
                id: this.$route.params.id,
                seasons: seasons,
                pgNo: this.page,
                perPage: this.perPage,
                home: home,
                away: away,
                coach: coach,
                prevcoach: prevcoach,
                fullHalf: this.fullHalf,
                similar: 0,
                current_coach: current_coach
                },
              headers: { 'Authorization': 'Token ' + localStorage.token } })
          .then(response => {
            if (response.data.type == 0) {
              this.msg_last = "Sorry, you have not permission to view the Last Matches. Please subscribe for it. Thanks."
            }else if (response.data.type == 1) {
              this.msg_last = "Sorry, your subscription had expired. Kindly pay to view the Last Matches. Thanks."
            }else {
              response = response.data

              // get count of matches with the current coach
              if (response.c_coach != null) {
                this.coach_count = response.c_coach
              }

              [this.lastAvg, this.otherLastAvg] = this.getLastStats(response.stats)
              this.lastAvgLoaded = true

              if (response.stats.foundCount == 0) {
                if (this.page == 0) {
                  this.lastPage = 0
                }else {
                  this.page -= 1
                  this.lastPage = this.page
                }
              }else {
                if (this.perPage == this.$t('all') || response.stats.foundCount < this.perPage) {
                  this.lastPage = this.page
                }
                this.last[this.page] = response.stats.data
                this.lastData[this.page] = response.stats
              }
              this.authorization = true
            }
            
            this.lastLoaded = true
            this.statsLoaded = true
          })
          .catch(error => {
            if (error.response.status == 401) {
              localStorage.removeItem('token')
              localStorage.removeItem('uid')
              location.reload()
            }              
          })
        }
      },

      cornerModal(id, home_id, home, away) {
        if (this.last[this.page][id].corners.data.length != 0) {
          this.subCorner.dt = moment(this.last[this.page][id].date).format('DD/MM/YY')
          this.subCorner.home = home
          this.subCorner.away = away
          this.subCorner.data = []
          this.last[this.page][id].corners.data.forEach((evt) => {
            let item = {
                comment: evt.comment,
                h_m: '',
                a_m: ''
              }
            if (evt.team_id == home_id) item.h_m = (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'"
            else item.a_m = (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'"
            this.subCorner.data.push(item)
          })
          this.subCornerDialog = true
        }
      },

      coachModal(ind) {
        this.coach = this.allCoaches[ind]
        this.coachDialog = true
      },

      trophyModal(ind) {
        this.index4trophy = ind
        this.trophyDialog = true
      },

      cardModal(id, home_id, home, away) {
        if (this.last[this.page][id].cards.length != 0) {
          this.subCard.dt = moment(this.last[this.page][id].date).format('DD/MM/YY')
          this.subCard.home = home
          this.subCard.away = away
          this.subCard.data = []
          this.last[this.page][id].cards.forEach((evt) => {
            let item = {
                player: evt.player_name,
                player_id: evt.player_id,
                minute: (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'",
                h_c: '',
                a_c: ''
              }
            if (evt.team == home_id) item.h_c = evt.type
            else item.a_c = evt.type

            this.subCard.data.push(item)
          })
          this.subCardDialog = true
        }
      },

      goalModal(id, home_id, home, away) {
        if (this.last[this.page][id].goals.length != 0) {
          this.subGoal.dt = moment(this.last[this.page][id].date).format('DD/MM/YY')
          this.subGoal.home = home
          this.subGoal.away = away
          this.subGoal.data = []
          this.last[this.page][id].goals.forEach((evt) => {
            let item = {
                player: evt.player_name,
                player_id: evt.player_id,
                minute: (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'",
                h_g: '',
                a_g: ''
              }
            if (evt.team_id == home_id) item.h_g = evt.type
            else item.a_g = evt.type

            this.subGoal.data.push(item)
          })
          this.subGoalDialog = true
        }
      },

      playerModal(member) {
        this.player = member
        this.playerDialog = true
      },

      refereeModal(rid) {
        this.chosenReferee = this.refereeStats.get(rid)
        this.refereeDialog = true

        if(!this.chosenReferee) {
          axios
            .get('/v3/referee/stats/' + rid,
              { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
            .then(response => {
              if (response.data.type == 0 || response.data.type == 1) {
                this.$router.replace('/message/subscribe/' + (response.data.type ? 'expired' : 'not-paid'))
              }else {
                response.data.statistics.forEach(element => {
                  let detail = {
                    redcards: null,
                    yellowcards: null,
                    yellowredcards: null,
                    penalties: null,
                    fouls: null,
                    var_moments: null,
                    matches: 0,
                  }

                  element.details.forEach(val => {
                    switch (val.type_id) {
                      case 47:
                        detail.penalties = val.value.all
                        break
                      case 56:
                        detail.fouls = val.value
                        break
                      case 188:
                        detail.matches = val.value.count
                        break
                      case 83:
                        detail.redcards = val.value.all
                        break
                      case 84:
                        detail.yellowcards = val.value.all
                        break
                      case 85:
                        detail.yellowredcards = val.value.all
                        break
                      case 314:
                        detail.var_moments = val.value
                        break
                    }
                  })

                  element.details = detail
                  element.season_name = element.season.name
                })

                response.data.statistics = _.orderBy(response.data.statistics, ['season_name'], ['desc'])
                this.refereeStats.set(rid, response.data)
                this.chosenReferee = response.data
              }
            })
            .catch(error => {
              if (error.response.status == 401) {
                localStorage.removeItem('token')
                localStorage.removeItem('uid')
                location.reload()
              } else {
                this.refereeDialog = false
              }
            })
        }
      },

      venueModal() {
        this.venueDialog = true
      },

      prev() {
        this.page++
        if (!this.last[this.page]) {
          this.statsLoaded = false
          this.getLast()        
        } else {
          [this.lastAvg, this.otherLastAvg] = this.getLastStats(this.lastData[this.page])
        }
      },

      next() {
        this.page--
        [this.lastAvg, this.otherLastAvg] = this.getLastStats(this.lastData[this.page])
      },

      perPageChange(evt) {
        this.perPage = this.pPageByUser
      },

      /*
        open player page on new tab
      */
      playerDetail(id) {
        let routeData = this.$router.resolve({name: 'player', params: {pid: id}});
        window.open(routeData.href, '_blank');
      },

      coachDetail(id) {
        let routeData = this.$router.resolve({name: 'coach', params: {id: id}});
        window.open(routeData.href, '_blank');
      },

      /*
        events for tab changes
      */
      tabChange() {
        if (this.authorization) {
          /* tab = 1 for Squad */
          if (this.tab == 1) {
            if (this.squad) {
              this.tabLoaded = true
            } else {
              if (!localStorage.token) {
                this.message = "notlogin"
                this.tabLoaded = true
              } else {
                this.tabLoaded = false

                // choose first league as default for players
                this.tournamentForPlayers = this.league.id
                this.seasonForPlayers = this.league.season_id
              }
            }
          }

          /* tab = 5 for Scorers */
          if (this.tab == 5) {
            if (this.scorers) {
              this.tabLoaded = true
            } else {
              if (!localStorage.token) {
                this.message = "notlogin"
                this.tabLoaded = true
              } else {
                this.tabLoaded = false

                // setting default season for statistics
                this.tournamentForScorers = this.league.id
                this.seasonForScorers = this.league.season_id
              }
            }
          }

          /* tab = 6 for Statistics */
          if (this.tab == 6) {
            if (this.teamStats) {
              this.tabLoaded = true
            } else {
              if (!localStorage.token) {
                this.message = "notlogin"
                this.tabLoaded = true
              } else {
                this.tabLoaded = false

                // choose first league as default for statistics
                this.tournamentForStats = this.league.id
                this.seasonForStats = this.league.season_id
              }
            }
          }

          /* tab = 7 for Transfers */
          if (this.tab == 7) {
            if (this.transfers.length) {
              this.tabLoaded = true
            } else {
              if (!localStorage.token) {
                this.message = "notlogin"
                this.tabLoaded = true
              } else {
                this.tabLoaded = false

                this.getTeamTransfer()
              }
            }
          }
        } else {
          this.tabLoaded = true
        }
      },
      
      matchDetail(id) {
        let routeData = this.$router.resolve({name: 'fixture', params: {fid: id}})
        window.open(routeData.href, '_blank')
      },

      upcomingDetail(id, cid, state) {
        if (state == 1 || (state > 9 && state < 21)) {
          let routeData = this.$router.resolve({name: 'soccermatch', query: {mid: id, cid: cid}})
          window.open(routeData.href, '_blank')
        } else {
          let routeData = this.$router.resolve({name: 'fixture', params: {fid: id}})
          window.open(routeData.href, '_blank')
        }
      }
  }
};
</script>
<style>
  @import '../assets/footcorner/soccermatch.css';
</style>
