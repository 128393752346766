<template>
  <v-app>
    <div class="page-header clear-filter">
      <div
        class="page-header-image"
        style="background-image: url('img/corner-ball.jpg')"
      ></div>
      
      <div class="content login-form">
        <div class="container">
            <div class="col-lg-6 col-md-10 ml-auto mr-auto">
              <card v-if="status == null" type="login" plain>
                <div slot="header">
                  <span class="h3 white--text">{{ $t('message.pleasewait') }}...</span>
                </div>
              </card>

              <card v-else-if="status == 0" type="login" plain>
                <div slot="header">
                  <span v-show="message" class="h3 white--text">{{ $t(message) }}</span>
                </div>

                <fg-input
                  class="no-border input-lg login-input"
                  addon-left-icon="now-ui-icons objects_key-25"
                  :placeholder="$t('password')"
                  type="password"
                  v-model="pswd1"
                  @keyup.enter.native="save"
                >
                </fg-input>

                <fg-input
                  class="no-border input-lg login-input"
                  addon-left-icon="now-ui-icons objects_key-25"
                  :placeholder="$t('confirm')"
                  type="password"
                  v-model="pswd2"
                  @keyup.enter.native="save"
                >
                </fg-input>

                <span v-show="pwd2_msg" class="error-msg">{{ $t(pwd2_msg) }}</span>

                <template slot="raw-content">
                  <div class="card-footer text-center">
                    <button
                      class="btn btn-primary btn-round btn-lg btn-block"
                      :class="{active: disable_btn}"
                      :disabled="disable_btn"
                      v-on:click="save"
                      >{{ $t('save') }}</button>
                  </div>
                </template>
              </card>

              <card v-else-if="status == 1" type="login" plain>
                <div slot="header">
                  <span v-show="message" class="h3 white--text">{{ $t(message) }}</span>
                </div>
              </card>

              <card v-else type="login" plain>
                <div slot="header">
                  <span class="h1 white--text">
                    <i class="now-ui-icons ui-1_check"></i>
                  </span>
                </div>

                <span class="white--text">{{ $t('message.successsavepassword') }}</span>
                
                <template slot="raw-content">
                  <div class="card-footer text-center">
                    <button
                      class="btn btn-primary btn-round btn-lg btn-block"
                      v-on:click="$router.push('/login')"
                      >{{ $t('login') }}</button>
                  </div>
                </template>
              </card>
            </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import { Card, Button, FormGroupInput } from '@/components'
import MainFooter from '@/layout/MainFooter'
import axios from 'axios'
import config from '@/config'

axios.defaults.baseURL = config.API_URL
export default {
  name: 'reset-password',
  bodyClass: 'login-page',
  components: {
    Card,
    MainFooter,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  
  data() {
    return {
      pswd1: '',
      pswd2: '',
      message: null,
      pwd2_msg: null,
      status: null,
      disable_btn: true
    }
  },
  
  beforeCreate: function () {
    axios
      .get('/account/reset-password/valid-link/' + this.$route.query.token)
      .then((response) => {
        if (response.data.status == 1) {
          this.status = 1
          this.message = 'message.invalidlink'
        } else if (response.data.status == 2) {
          this.status = 1
          this.message = 'message.expiredlink'
        } else {
          this.message = 'resetpassword'
          this.status = 0
        }
      })
  },

  methods: {
    save () {
      if (this.pswd1 != '' && this.pswd2 != ''){
        axios
          .post('/account/reset-password/save/', {
            'pwd': this.pswd1,
            'token': this.$route.query.token
            })
          .then((response) => {
            if (response.data.status == 1) {
              this.status = 1
              this.message = 'message.invalidlink'
            } else if (response.data.status == 2) {
              this.status = 1
              this.message = 'message.expiredlink'
            } else if (response.data.status == 3) {
              this.status = 1
              this.message = 'message.failedsave'
            } else {
              this.status = 2
            }
          })
      }
    },

    check_valid_input() {
      if (this.check_passwords_match()) {
        this.disable_btn = false
      }else {
        this.disable_btn = true
      }
    },

    check_passwords_match() {
      if (this.pswd1.length == 0 && this.pswd2.length == 0) {
        this.pwd2_msg = null
        return false
      }else if (this.pswd2 != this.pswd1) {
        this.pwd2_msg = 'message.passwordnotmatch'
        return false
      } else {
        this.pwd2_msg = null
        return true
      }
    },
  },

  watch: {
    pswd1: function(value) {
      this.check_valid_input()
    },

    pswd2: function(value) {
      this.check_valid_input()
    }
  },
}
</script>
<style>
  .card-header {
    margin-bottom: 30px !important;
  }

  .header {
    color: white;
    font-size: 25px;
    font-weight: bold;
  }
  
  .page-header .login-form {
    margin-top: 20vh;
  }

  .login-input input {
    font-weight: bold;
  }

  .error-msg {
    color: orange;
    font-weight: bold;
  }

  a.link:hover, a.link:focus {
    color: lightgrey !important;
  }

  @media (max-width: 450px) {
    .page-header .login-form {
      margin-top: 15vh;
    }
  }
</style>