<template>
  <div id="app" v-scroll="onScroll">
    <router-view name="header" :key="$route.fullPath"/>
    <div class="wrapper">
        <router-view :key="$route.fullPath" />
    </div>
    <v-fab-transition origin>
      <v-btn
        v-show="fab"
        dark
        bottom
        right
        fixed
        fab
        style="background-color: rgb(0, 123, 255, 0.8)"
        @click="top"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-fab-transition>
    <router-view name="footer" />
  </div>
</template>
<script>
import axios from 'axios'
import config from '@/config'

axios.defaults.baseURL = config.API_URL

export default {
  data() {
    return {
       fab: false
    }
  },
  beforeCreate: function () {
      if (localStorage.token) {
        axios
          .post('/check_token/', null, { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
          .then((response) => {
            localStorage.uid = response.data.user
            localStorage.setItem('favorite_league', JSON.stringify(response.data.favorite_league))
          })
          .catch((error) => {
            if (error.response.status == 401) {
              localStorage.removeItem('token')
              localStorage.removeItem('uid')
              location.reload()
            }  
          })
      }
  },
  methods: {
    top () {
      window.scrollTo({
        top: 0,
        // left: 0,
        behavior: 'smooth'
      });
    },
    onScroll (e) {
        if (e.target.scrollingElement.scrollTop > 100 ) {
          this.fab = true
        } else {
          this.fab = false
        }
      },
  }
}
</script>
