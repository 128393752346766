<template>
  <v-card
    elevation="3"
  >
    <v-app-bar
      color="blue"
      dark
      dense
      flat
      height="35"
    >
      <v-toolbar-title>
        <span
          :class="{'subtitle-2': $vuetify.breakpoint.smAndDown, 'subtitle-1': $vuetify.breakpoint.mdAndUp}">
          {{ $t('topclub') }}
        </span>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-icon @click="show = !show">
        {{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
      </v-icon>
    </v-app-bar>

    <v-expand-transition>
      <div v-show="!show">
        <v-card-text>
          <div v-for="(item, ind) in topClubs" :key="ind" class="mb-1">
            <router-link :to="{name: 'team', params: {id: item.team_id}}" class="black--text">
              <img class='mr-1' :src="item.team_image" style="width: 25px; height: 25px;" :title="item.country_name"/>

              <span
                :title="item.country_name">
                {{ item.team_name }}
              </span>
            </router-link>
          </div>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
  import axios from 'axios'
  import config from '@/config'

  axios.defaults.baseURL = config.API_URL;

  export default {
    name: 'top-clubs',
    data() {
      return {
        show: this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm,
        topClubs: [],
      }
    },

    created: function() {
      // axios
      //   .get('/top/club/')
      //   .then(response => {
      //     this.topClubs = response.data.top_club;
      //   })
      //   .catch(error => {})
      if (localStorage.topclub) {
        this.topClubs = JSON.parse(localStorage.topclub);
      } else {
        this.topClubs = [
          {"team_id":3468, "team_name":"Real Madrid", "team_image":"https://cdn.sportmonks.com/images/soccer/teams/12/3468.png", "country_name":"Spain", "order":0},
          {"team_id":9, "team_name":"Manchester City", "team_image":"https://cdn.sportmonks.com/images/soccer/teams/9/9.png", "country_name":"England", "order":1},
          {"team_id":503, "team_name":"FC Bayern München", "team_image":"https://cdn.sportmonks.com/images/soccer/teams/23/503.png", "country_name":"Germany", "order":2},
          {"team_id":8, "team_name":"Liverpool", "team_image":"https://cdn.sportmonks.com/images/soccer/teams/8/8.png", "country_name":"England", "order":3},
          {"team_id":14, "team_name":"Manchester United", "team_image":"https://cdn.sportmonks.com/images/soccer/teams/14/14.png", "country_name":"England", "order":4}]
        
        localStorage.setItem("topclub", JSON.stringify(this.topClubs));
      }
    },

  }
</script>