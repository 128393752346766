<template>
    <div style="padding: 2rem 3rem; text-align: left;">
        <div class="field">
            <label class="label">{{ $t('message.contactsoon') }}</label>
        </div>
    </div>
</template>

<script>
    
    export default {
        data() {
            return {
                message: 'message.contactsoon',
                error: 'message.errorsendingemail',
            }
        },
        mounted() {
            
        }
    }
</script>
<style>
    .field {
        margin-bottom: 2vh;
    }

    .field .label {
        color: black;
        font-weight: bold;
    }

    .help {
        color: red;
        font-weight: bold;
        font-size: 14px;
    }

    .control input,textarea {
        width: 50vw;
    }

    @media screen and (max-width: 450px){
        .control input, textarea {
            width: 80vw;
        }
    }
</style>