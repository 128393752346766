<template>
  <v-app>
    <div class="page-header clear-filter">
      <div
        class="page-header-image"
        style="background-image: url('img/corner-ball.jpg')"
      ></div>
      
      <div class="content login-form">
        <div class="container">
            <div class="col-lg-6 col-md-10 ml-auto mr-auto">
              <card type="login" plain>
                <div slot="header">
                  <span class="h3 white--text">{{ $t('welcome') }}!</span>
                </div>

                <fg-input
                  class="no-border input-lg login-input"
                  addon-left-icon="now-ui-icons users_single-02"
                  :placeholder="$t('username_or_email')"
                  v-model="username"
                  v-on:keyup="clearError"
                  @keyup.enter.native="login"
                >
                </fg-input>

                <fg-input
                  class="no-border input-lg login-input"
                  addon-left-icon="now-ui-icons objects_key-25"
                  :placeholder="$t('password')"
                  type="password"
                  v-model="password"
                  v-on:keyup="clearError"
                  @keyup.enter.native="login"
                >
                </fg-input>

                <p v-show="message" class="error-msg">{{ $t(message) }}</p>

                <template slot="raw-content">
                  <div class="card-footer text-center">
                    <button
                      class="btn btn-primary btn-round btn-lg btn-block"
                      v-on:click="login"
                      >{{ $t('login') }}</button>
                  </div>
                  
                  <div class="pull-left">
                    <h6>
                      <router-link to="signup" class="text-capitalize subtitle-2 link footer-link">{{ $t('createaccount') }}</router-link>
                    </h6>
                  </div>

                  <div class="pull-right">
                    <h6>
                      <router-link to="/account/forgot-password" class="text-capitalize subtitle-2 link footer-link">{{ $t('forgotpassword') }}?</router-link>
                    </h6>
                  </div>
                </template>
              </card>
            </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import { Card, Button, FormGroupInput } from '@/components'
import MainFooter from '@/layout/MainFooter'
import axios from 'axios'
import config from '@/config'

axios.defaults.baseURL = config.API_URL;
export default {
  name: 'login',
  bodyClass: 'login-page',
  components: {
    Card,
    MainFooter,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      username: '',
      password: '',
      message: null,
    }
  },
  beforeCreate: function () {
    if (localStorage.token) {
      this.$router.replace('/')
    }
  },
  methods: {
    login () {
      if (this.username != '' && this.password != ''){
        axios
          .post('/login/', {'username_or_email': this.username, 'password': this.password})
          .then((response) => {
            if (response.data.invalid == 1) {
              this.message = 'message.invalidcredential'
            } else {
              localStorage.token = response.data.token
              localStorage.uid = response.data.user
              localStorage.setItem('favorite_league', JSON.stringify(response.data.favorite_league))
              
              location.reload()
            }
          })
      }
    },

    clearError : function () {
      this.message = null
    }
  }
};
</script>
<style>
  .card-header {
    margin-bottom: 30px !important;
  }

  .header {
    color: white;
    font-size: 25px;
    font-weight: bold;
  }
  
  .page-header .login-form {
    margin-top: 20vh;
  }

  .login-input input {
    font-weight: bold;
  }

  .error-msg {
    color: orange;
    font-weight: bold;
  }

  a.link:hover, a.link:focus {
    color: lightgrey !important;
  }

  @media (max-width: 450px) {
    .page-header .login-form {
      margin-top: 15vh;
    }
  }
</style>