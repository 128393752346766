<template>
  <v-app>
    <div class="pt-60">
      <template v-if="!infoLoaded">
        <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
      </template>

      <template v-else-if="!authorization">
        <v-alert
          type="warning"
          prominent
        >
          {{ $t(`message.${message}`) }}
        </v-alert>
      </template>

      <template v-else>
        <template v-if="country">
          <div class="row my-3 justify-center">
            <div class="d-flex align-center">
              <img
                class="wh-70 elevation-5"
                :src="country.image_path"
                onerror="this.src='/img/flag/-.png'">

              <div class="ml-4 d-flex flex-column justify-space-around">
                <div class="text-center">
                  <span
                    class="font-weight-bold green--text text--darken-2"
                    :class="{'subtitle-1': $vuetify.breakpoint.xs, 'h4': $vuetify.breakpoint.smAndUp}">
                    {{ country.name }}</span>
                </div>

                <div class="text-center">
                  <span class="red--text text--darken-4">{{ country.continent.name }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="row no-gutters justify-center">
            <div class="col-sm-12 col-md-10 col-lg-7 elevation-2">
              <v-tabs 
                v-model="tabInd"
                @change="tabChange"
                centered
                show-arrows
                light
              >
                <v-tab 
                  v-for="(item, i) in tabs"
                  :key="i"
                >
                  <span style="font-size: small">{{ $t(item.text) }}</span>
                </v-tab>

                <v-tab-item key="League">
                  <div class="align-center">
                    <template v-if="country.leagues && country.leagues.length">
                      <v-simple-table class="table-row-35 mt-5">
                        <thead class="lightgrey-border-y-2">
                          <tr class="text-left white grey--text text--darken-1">
                            <td>{{ $t('name') }}</td>
                            <td>{{ $t('logo') }}</td>
                            <td class="d-none d-sm-table-cell">{{ $t('type') }}</td>
                          </tr>
                        </thead>

                        <tbody>
                          <tr
                            v-for="(item, ind) in country.leagues"
                            :key="ind"
                            class="text-left"
                            :title="item.active ? $t('activeleague') : $t('inactiveleague')"
                            :style="item.active ? '' : 'opacity: 0.5'"
                          >
                            <td>
                              <router-link :to="{name: 'league', params: {lid: item.id}}">
                                <span :class="item.active ? 'red--text text--darken-4' : 'grey--text'">{{ item.name }}</span>
                              </router-link>
                            </td>

                            <td>
                              <img class="wh-30" :src="item.image_path" onerror="style.display='none'" />
                            </td>

                            <td class="ml-3 d-none d-sm-table-cell">
                              <span :class="item.active ? 'blue--text' : 'grey--text'">{{ item.sub_type }}</span>
                            </td>                          
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </template>

                    <div v-else class="row no-gutters justify-center"><p>{{ $t('message.noleague') }}</p></div>
                  </div>
                </v-tab-item>

                <v-tab-item key="Team">
                  <template v-if="tabLoaded">
                    <div v-if="teams.length" class="row no-gutters justify-center">
                      <div class="align-center">
                        <v-simple-table class="mt-5 table-row-35">
                          <thead class="lightgrey-border-y-2">
                            <tr class="text-left white grey--text text--darken-1">
                              <td>{{ $t('name') }}</td>
                              <td>{{ $t('founded') }}</td>
                            </tr>
                          </thead>

                          <tbody>
                            <tr
                              v-for="(item, ind) in teams"
                              :key="ind"
                              class="text-left"
                            >
                              <td>
                                <router-link :to="{name: 'team', params: {id: item.id}}">
                                  <img class="wh-20" :src="item.image_path" onerror="style.display='none'" />
                                  
                                  <span class="ml-2 red--text text--darken-4">{{ item.name }}</span>
                                </router-link>
                              </td>

                              <td>
                                <span class="blue-grey--text">{{ item.founded }}</span>
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>

                        <v-btn
                          :class="{'d-none': !team_has_more}"
                          :loading="team_loading"
                          :disabled="team_loading"
                          color="success"
                          @click="getTeam()"
                          small
                        >
                          {{ $t('loadmore') }}
                        </v-btn>
                      </div>
                    </div>

                    <div v-else class="row no-gutters justify-center"><p>{{ $t('message.noteam') }}</p></div>
                  </template>

                  <div v-else class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                </v-tab-item>

                <v-tab-item key="Player">
                  <template v-if="tabLoaded">
                    <div v-if="players.length" class="row no-gutters justify-center">
                      <div class="align-center">
                        <v-simple-table class="mt-5 table-row-35">
                          <thead class="lightgrey-border-y-2">
                            <tr class="text-left white grey--text text--darken-1">
                              <td>{{ $t('player') }}</td>
                              <td>{{ $t('team') }}</td>
                              <td :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('height') }}</td>
                              <td :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('weight') }}</td>
                              <td :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('birthdate') }}</td>
                            </tr>
                          </thead>

                          <tbody>
                            <tr
                              v-for="(item, ind) in players"
                              :key="ind"
                              class="text-left"
                            >
                              <td>
                                <router-link :to="{name: 'player', params: {pid: item.id}}">
                                  <img class="wh-30" :src="item.image_path" :title="item.name" onerror="style.display='none'" />
                                  
                                  <span class="red--text text--darken-4 ml-2">{{ item.common_name }}</span>
                                </router-link>
                              </td>

                              <td class="justify-center">
                                <template v-if="item.teams.length">
                                  <div v-for="(t, ind) in item.teams" :key="ind" class="d-flex flex-wrap">
                                    <router-link                                   
                                      class="mr-1"
                                      :to="{name: 'team', params: {id: t.team_id}}">
                                      <img class="wh-20 mr-1" :src="t.team.image_path" onerror="style.display='none'" />

                                      <span class="blue-grey--text">{{ t.team.name }}</span>
                                    </router-link>
                                  </div>
                                </template>
                              </td>
                              
                              <td :class="{'d-none': $vuetify.breakpoint.xs}">
                                <span class="blue-grey--text">{{ item.height }}</span>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}">
                                <span class="blue-grey--text">{{ item.weight }}</span>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}">
                                <span class="blue-grey--text">{{ item.date_of_birth }}</span>
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>

                        <v-btn
                          :class="{'d-none': !player_has_more}"
                          :loading="player_loading"
                          :disabled="player_loading"
                          color="success"
                          @click="getPlayer()"
                          small
                        >
                          {{ $t('loadmore') }}
                        </v-btn>
                      </div>
                    </div>

                    <div v-else class="row no-gutters justify-center"><p>{{ $t('message.noplayer') }}</p></div>
                  </template>

                  <div v-else class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                </v-tab-item>

                <v-tab-item key="Coach">
                  <template v-if="tabLoaded">
                    <div v-if="coaches.length" class="row no-gutters justify-center">
                      <div class="align-center">
                        <v-simple-table class="mt-5 table-row-35">
                          <thead class="lightgrey-border-y-2">
                            <tr class="text-left white grey--text text--darken-1">
                              <td>{{ $t('name') }}</td>
                              <td>{{ $t('team') }}</td>
                              <td :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('birthdate') }}</td>
                            </tr>
                          </thead>

                          <tbody>
                            <tr
                              v-for="(item, ind) in coaches"
                              :key="ind"
                              class="text-left"
                            >
                              <td>
                                <router-link :to="{name: 'coach', params: {id: item.id}}">
                                  <img class="wh-30" :src="item.image_path" :title="item.name" onerror="style.display='none'" />
                                  
                                  <span class="red--text text--darken-4 ml-2">{{ item.common_name }}</span>
                                </router-link>
                              </td>

                              <td class="justify-center">
                                <template v-for="(t, ind) in item.teams">
                                  <div v-if="t.active" :key="ind" class="d-flex flex-wrap">
                                    <router-link
                                      class="mr-1"
                                      :to="{name: 'team', params: {id: t.team_id}}">
                                      <img class="wh-20 mr-1" :src="t.team.image_path" onerror="style.display='none'" />

                                      <span class="blue-grey--text">{{ t.team.name }}</span>
                                    </router-link>
                                  </div>
                                </template>
                              </td>
                              
                              <td :class="{'d-none': $vuetify.breakpoint.xs}">
                                <span class="blue-grey--text">{{ item.date_of_birth }}</span>
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>

                        <v-btn
                          :class="{'d-none': !coach_has_more}"
                          :loading="coach_loading"
                          :disabled="coach_loading"
                          color="success"
                          @click="getCoach()"
                          small
                        >
                          {{ $t('loadmore') }}
                        </v-btn>
                      </div>
                    </div>

                    <div v-else class="row no-gutters justify-center"><p>{{ $t('message.nocoach') }}</p></div>
                  </template>

                  <div v-else class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                </v-tab-item>

                <v-tab-item key="Referee">
                  <template v-if="tabLoaded">
                    <div v-if="referees.length" class="row no-gutters justify-center">
                      <div class="align-center">
                        <v-simple-table class="mt-5 table-row-35">
                          <!-- <thead>
                            <tr class="text-left white grey--text text--darken-1">
                              <td>Name</td>
                            </tr>
                          </thead> -->

                          <tbody>
                            <tr
                              v-for="(item, ind) in referees"
                              :key="ind"
                              class="text-left"
                            >
                              <td>
                                <img class="wh-30" :src="item.image_path" :title="item.name" onerror="style.display='none'" />
                                  
                                <span class="red--text text--darken-4 ml-2">{{ item.common_name }}</span>
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>

                        <v-btn
                          :class="{'d-none': !referee_has_more}"
                          :loading="referee_loading"
                          :disabled="referee_loading"
                          color="success"
                          @click="getReferee()"
                          small
                        >
                          {{ $t('loadmore') }}
                        </v-btn>
                      </div>
                    </div>

                    <div v-else class="row no-gutters justify-center"><p>{{ $t('message.noreferee') }}</p></div>
                  </template>

                  <div v-else class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                </v-tab-item>
              </v-tabs>
            </div>
          </div>
        </template>

        <div v-else class="row my-3 justify-center">
          <span>{{ $t('message.nocountry') }}</span>
        </div>
      </template>
    </div>
  </v-app>
</template>
<script>

import { Card } from '@/components'
import axios from 'axios'

export default {
  components: {
    Card,
  },
  data() {
    return {
      country: null,
      // leagues: [],
      teams: [],
      players: [],
      coaches: [],
      referees: [],
      
      authorization: false,
      message: '',
      infoLoaded: false,
      tabLoaded: false,

      team_pg: 1,
      team_has_more: null,
      team_loading: false,
      player_pg: 1,
      player_has_more: null,
      player_loading: false,
      coach_pg: 1,
      coach_has_more: null,
      coach_loading: false,
      referee_pg: 1,
      referee_has_more: null,
      referee_loading: false,

      tabs: [
        { text: 'league', icon: '' },
        { text: 'team', icon: '' },
        { text: 'player', icon: '' },
        { text: 'coach', icon: '' },
        { text: 'referee', icon: '' },
      ],
      tabInd: 0,
    }
  },
  watch: {},
  mounted() {
    this.getLeague()
  },
  methods: {
    getLeague() {
      axios
        .get('/v3/country/league/' + this.$route.params.cid,
          { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
        .then(response => {
          if (response.data.type == 0) {
            this.message = "Sorry, you have not permission to view these data. Please subscribe for it. Thanks."
          }else if (response.data.type == 1) {
            this.message = "Sorry, your subscription has expired. Kindly pay to view these data. Thanks."
          }else {
            this.country = response.data

            this.authorization = true
          }
          this.infoLoaded = true
        })
        .catch(error => {
          if (error.response.status == 401) {
            localStorage.removeItem('token')
            localStorage.removeItem('uid')
            location.reload()
          }
        })
    },
    
    /* get teams from country by pagination */
    getTeam() {
      this.team_loading = true

      axios
        .get('/v3/country/team/' + this.$route.params.cid + '/' + this.team_pg,
          { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
        .then(response => {
          if (response.data.type == 0) {
            this.message = "Sorry, you have not permission to view these data. Please subscribe for it. Thanks."
          }else if (response.data.type == 1) {
            this.message = "Sorry, your subscription has expired. Kindly pay to view these data. Thanks."
          }else {
            this.teams = this.teams.concat(response.data.team)
            
            this.team_pg += 1
            this.team_has_more = response.data.has_more
            this.team_loading = false
          }
          this.tabLoaded = true
        })
        .catch(error => {
          if (error.response.status == 401) {
            localStorage.removeItem('token')
            localStorage.removeItem('uid')
            location.reload()
          }              
        })
    },

    /* get players from country by pagination */
    getPlayer() {
      this.player_loading = true

      axios
        .get('/v3/country/player/' + this.$route.params.cid + '/' + this.player_pg,
          { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
        .then(response => {
          if (response.data.type == 0) {
            this.message = "Sorry, you have not permission to view these data. Please subscribe for it. Thanks."
          }else if (response.data.type == 1) {
            this.message = "Sorry, your subscription has expired. Kindly pay to view these data. Thanks."
          }else {
            this.players = this.players.concat(response.data.player)

            this.player_pg += 1
            this.player_has_more = response.data.has_more
            this.player_loading = false
          }
          this.tabLoaded = true
        })
        .catch(error => {
          if (error.response.status == 401) {
            localStorage.removeItem('token')
            localStorage.removeItem('uid')
            location.reload()
          }              
        })
    },

    getCoach() {
      this.coach_loading = true

      axios
        .get('/v3/country/coach/' + this.$route.params.cid + '/' + this.coach_pg,
          { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
        .then(response => {
          if (response.data.type == 0) {
            this.message = "Sorry, you have not permission to view these data. Please subscribe for it. Thanks."
          }else if (response.data.type == 1) {
            this.message = "Sorry, your subscription has expired. Kindly pay to view these data. Thanks."
          }else {
            this.coaches = this.coaches.concat(response.data.coach)

            this.coach_pg += 1
            this.coach_has_more = response.data.has_more
            this.coach_loading = false
          }
          this.tabLoaded = true
        })
        .catch(error => {
          if (error.response.status == 401) {
            localStorage.removeItem('token')
            localStorage.removeItem('uid')
            location.reload()
          }              
        })
    },

    getReferee() {
      this.referee_loading = true

      axios
        .get('/v3/country/referee/' + this.$route.params.cid + '/' + this.referee_pg,
          { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
        .then(response => {
          if (response.data.type == 0) {
            this.message = "Sorry, you have not permission to view these data. Please subscribe for it. Thanks."
          }else if (response.data.type == 1) {
            this.message = "Sorry, your subscription has expired. Kindly pay to view these data. Thanks."
          }else {
            this.referees = this.referees.concat(response.data.referee)

            this.referee_pg += 1
            this.referee_has_more = response.data.has_more
            this.referee_loading = false
          }
          this.tabLoaded = true
        })
        .catch(error => {
          if (error.response.status == 401) {
            localStorage.removeItem('token')
            localStorage.removeItem('uid')
            location.reload()
          }              
        })
    },

    tabChange() {
      this.tabLoaded = false

      switch (this.tabInd) {
        case 1:
          if (this.teams.length) {
            this.tabLoaded = true
          } else {
            this.getTeam()
          }
          break

        case 2:
          if (this.players.length) {
            this.tabLoaded = true
          } else {
            this.getPlayer()
          }
          break

        case 3:
          if (this.coaches.length) {
            this.tabLoaded = true
          } else {
            this.getCoach()
          }
          break

        case 4:
          if (this.referees.length) {
            this.tabLoaded = true
          } else {
            this.getReferee()
          }
          break
      }
    }
  }
}
</script>
<style>
</style>
