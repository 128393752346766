<template>
	<v-app>
		<div>
			<div class="row no-gutters pt-50 justify-center">
				<span class="h4 d-flex align-center">
					<i class="now-ui-icons ui-1_settings-gear-63 mr-3"></i>
					{{ $t('setting') }}
				</span>
			</div>
		
		<div class="row no-gutters justify-center">
			<div class="col-lg-8">
        <v-tabs
          v-model="tab"
          background-color="transparent"
          centered
          show-arrows
          light>
          <v-tab
            v-for="item in categories"
            :key="item">
            <span style="font-size: small">{{ $t(item) }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item key="General">
            <div class="d-flex justify-center">
              <div class="col-lg-7 align-center" :class="$vuetify.breakpoint.smAndUp ? 'm-5' : 'my-3'" style="min-height: 350px;">
                <div class="d-flex flex-wrap mb-5">
                  <v-select
                    v-model="tz"
                    :items="tzones"
                    item-text="text"
                    item-value="offset"
                    :label="$t('timezone')"
                    class="col-sm-4 my-1"
                    attach
                    hide-details
                    dense
                    outlined>
                  </v-select>

                  <v-alert
                    dense
                    text
                    type="success"
                    icon="mdi-clock"
                    class="my-1 ml-10"
                    border="left">
                    {{ currentTime }}
                  </v-alert>
                </div>
                
                <div>
                  <v-select
                    v-model="ofmt"
                    :items="odds_format"
                    item-text="text"
                    item-value="value"
                    :label="$t('oddsformat')"
                    class="col-sm-4 my-1"
                    attach
                    hide-details
                    dense
                    outlined>
                  </v-select>
                </div>

                <div>
                  <v-text-field
                    v-model="days_coach_changed"
                    prepend-icon="mdi-calendar-month"
                    autofocus
                    filled
                    dense
                    :label="$t('dayscoachchanged')"
                    class="col-lg-6 col-md-8 my-1"
                    type="number"
                  />
                </div>

                <v-card outlined>
                  <v-card-subtitle>
                    {{ $t("teamform_label") }}
                  </v-card-subtitle>

                  <v-card-text>
                    <div class="d-flex justify-space-between flex-wrap row no-gutters m-3">
                      <v-select
                        v-model="type_form"
                        :items="type_form_items"
                        :item-text="item => $t(item.text)"
                        item-value="value"
                        class="col-lg-3"
                        attach
                        hide-details
                        dense>
                      </v-select>

                      <v-select
                        v-model="league_form"
                        :items="league_form_items"
                        :item-text="item => $t(item.text)"
                        item-value="value"
                        class="col-lg-4"
                        attach
                        hide-details
                        dense>
                      </v-select>

                      <v-text-field
                        v-model="count_form"
                        class="col-lg-2"
                        type="number"
                        hide-details
                        dense
                      />
                    </div>
                  </v-card-text>
                </v-card>

                <div class="mt-5 justify-center">
                  <v-btn
                    color="info"
                    elevation="5"
                    class="my-1"
                    :disabled="!apply_general"
                    @click="save_general">
                    {{ $t('save') }}
                  </v-btn>
                </div>
              </div>
            </div>
          </v-tab-item>

          <v-tab-item key="Favorite">
            <div class="row my-2 justify-center" style="min-height: 350px;">
              <div v-if="$vuetify.breakpoint.smAndUp" class="col-sm-5 col-lg-4">
                <v-list
                  class="overflow-auto"
                  height="450"
                  dense
                >
                  <v-list-item-group
                    color="primary"
                  >
                    <v-list-item
                      v-for="(item, i) in countries"
                      :key="i"
                      @click="select_country(item.id, 1)"
                    >
                      <v-list-item-icon class="align-center">
                        <img
                          class='elevation-2 mr-2 w-30 h-20'
                          :src="'/img/flag/'+ item.id + '.png'"
                          onerror="this.src='/img/flag/-.png'"
                          :title="item.name" />
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title v-text="item.name" :title="item.name"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>

              <div v-else class="col">
                <v-select
                  v-model="ct"
                  :items="countries"
                  item-text="name"
                  label="Country"
                  hide-details
                  attach
                  dense
                  return-object
                  outlined>
                  <template #selection="{ item }">
                    <img
                      class='elevation-2 mr-2 w-30 h-20'
                      :src="'/img/flag/'+ item.id + '.png'"
                      onerror="this.src='/img/flag/-.png'"
                      :title="item.name" />
                    {{ item.name }}
                  </template>

                  <template #item="{ item }">
                    <img
                      class='elevation-2 mr-2 w-30 h-20'
                      :src="'/img/flag/'+ item.id + '.png'"
                      onerror="this.src='/img/flag/-.png'"
                      :title="item.name" />
                    {{ item.name }}
                  </template>                  
                </v-select>
              </div>

              <div class="col-sm-7 col-lg-6">
                <div v-if="loading" class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                
                <span v-else-if="!authorization" class="subtitle-2 p-1">{{ $t(`message.${message}`) }}</span>

                <v-list
                  v-else
                  class="overflow-auto"
                  :height="$vuetify.breakpoint.xs ? 250 : 450"
                  dense
                >
                  <v-list-item-group
                    v-model="league_selected"
                    active-class="primary--text"
                    multiple
                  >
                    <template v-for="item in country_leagues">
                      <v-list-item
                        :key="item.league_id"
                        :value="item.league_id"
                        @click="update_favorite(item.league_id)">
                        <template v-slot:default="{ active }">
                          <v-list-item-action>
                            <v-icon
                              v-if="!active"
                              color="grey lighten-1">
                              mdi-star-outline
                            </v-icon>
                            <v-icon
                              v-else
                              color="yellow darken-3">
                              mdi-star
                            </v-icon>
                          </v-list-item-action>

                          <!--<v-list-item-avatar class="mr-4">
                            <img class="wh-40" :src="item.league_logo" onerror="style.display='none'" />
                          </v-list-item-avatar>-->

                          <v-list-item-content>
                            <v-list-item-title v-text="item.league_name"></v-list-item-title>

                            <v-list-item-subtitle v-text="item.league_type"></v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                    </template>
                  </v-list-item-group>
                </v-list>
              </div>
            </div>
          </v-tab-item>

          <v-tab-item key="Topleague">
            <div class="row my-2 justify-center" style="min-height: 350px;">
              <div v-if="$vuetify.breakpoint.smAndUp" class="col-lg-3">
                <v-list
                  class="overflow-auto"
                  height="450"
                  dense
                >
                  <v-list-item-group
                    color="primary"
                  >
                    <v-list-item
                      v-for="(item, i) in countries"
                      :key="i"
                      @click="select_country(item.id, 2)"
                    >
                      <v-list-item-icon class="align-center" style="margin-right: 7px">
                        <img
                          class='elevation-2 w-30 h-20'
                          :src="'/img/flag/'+ item.id + '.png'"
                          onerror="this.src='/img/flag/-.png'"
                          :title="item.name" />
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title v-text="item.name" :title="item.name"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>

              <div v-else class="col">
                <v-select
                  v-model="ct_topleague"
                  :items="countries"
                  item-text="name"
                  label="Country"
                  hide-details
                  attach
                  dense
                  return-object
                  outlined>
                  <template #selection="{ item }">
                    <img
                      class='elevation-2 mr-2 w-30 h-20'
                      :src="'/img/flag/'+ item.id + '.png'"
                      onerror="this.src='/img/flag/-.png'"
                      :title="item.name" />
                    {{ item.name }}
                  </template>

                  <template #item="{ item }">
                    <img
                      class='elevation-2 mr-2 w-30 h-20'
                      :src="'/img/flag/'+ item.id + '.png'"
                      onerror="this.src='/img/flag/-.png'"
                      :title="item.name" />
                    {{ item.name }}
                  </template>                  
                </v-select>
              </div>

              <div class="col-sm-7 col-lg-5">
                <div v-if="loading" class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                
                <span v-else-if="!authorization" class="subtitle-2 p-1">{{ $t(`message.${message}`) }}</span>

                <v-list
                  v-else
                  class="overflow-auto"
                  :max-height="$vuetify.breakpoint.xs ? 250 : 450"
                  dense
                >
                  <v-list-item-group
                    v-model="topleague_selected"
                    active-class="primary--text"
                    multiple
                  >
                    <template v-for="item in country_topleagues">
                      <v-list-item
                        v-show="item.is_active"
                        :key="item.league_id"
                        :value="item.league_id"
                        @click="update_topleague(item)">
                        <template v-slot:default="{ active }">
                          <v-list-item-icon class="align-self-center mr-5">
                            <img
                              class='w-30 h-20'
                              :src="item.league_logo"
                              />
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title v-text="item.league_name"></v-list-item-title>

                            <v-list-item-subtitle v-text="item.league_type"></v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-icon
                              v-if="!active"
                              color="grey lighten-1">
                              mdi-plus
                            </v-icon>
                            <v-icon
                              v-else
                              color="yellow darken-3">
                              mdi-close
                            </v-icon>
                          </v-list-item-action>
                        </template>
                      </v-list-item>
                    </template>
                  </v-list-item-group>
                </v-list>
              </div>

              <div class="col-sm-7 col-lg-4 elevation-1">
                <v-list
                  class="overflow-auto"
                  max-height="70vh"
                  dense
                >
                  <v-subheader class="text-center"><h4>Selected leagues</h4></v-subheader>

                  <v-list-item
                    v-for="item in topleague"
                    :key="item.league_id"
                    :value="item.league_id"
                    @click="update_topleague(item)">
                    <template v-slot:default="{ active }">
                      <v-list-item-icon class="align-self-center mr-5">
                        <img
                          class='w-30 h-30'
                          :src="item.league_logo"
                          />
                      </v-list-item-icon>
                      
                      <v-list-item-content>
                        <v-list-item-title v-text="item.league_name"></v-list-item-title>

                        <v-list-item-subtitle v-text="item.country_name"></v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-icon
                          color="yellow darken-3">
                          mdi-close
                        </v-icon>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </v-list>
              </div>
            </div>
          </v-tab-item>

          <v-tab-item key="Topclub">
            <div class="row my-2 justify-center" style="min-height: 350px;">
              <div class="col-sm-7 col-lg-6">
                <v-text-field
                  v-model="searchTeamName"
                  append-outer-icon="mdi-send"
                  prepend-icon="mdi-database-search"
                  autofocus
                  filled
                  dense
                  clear-icon="mdi-close-circle"
                  clearable
                  :label="$t('team')"
                  type="text"
                  :loading="loading"
                  v-on:keyup.enter="searchTeam"
                  @click:append-outer="searchTeam"
                  @click:clear="searchTeamName=''"
                />
              
                <v-list
                  class="overflow-auto"
                  :max-height="$vuetify.breakpoint.xs ? 250 : 450"
                  dense
                >
                  <v-list-item-group
                    v-model="topclub_selected"
                    active-class="primary--text"
                    multiple
                  >
                    <template v-for="item in club_all">
                      <v-list-item
                        :key="item.team_id"
                        :value="item.team_id"
                        @click="update_topclub(item)">
                        <template v-slot:default="{ active }">
                          <v-list-item-icon class="align-self-center mr-5">
                            <img
                              class='w-30 h-30'
                              :src="item.team_image"
                              />
                          </v-list-item-icon>
                          
                          <v-list-item-content>
                            <v-list-item-title v-text="item.team_name"></v-list-item-title>

                            <v-list-item-subtitle v-text="item.country_name"></v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-icon
                              v-if="!active"
                              color="grey lighten-1">
                              mdi-plus
                            </v-icon>
                            <v-icon
                              v-else
                              color="yellow darken-3">
                              mdi-close
                            </v-icon>
                          </v-list-item-action>
                        </template>
                      </v-list-item>
                    </template>
                  </v-list-item-group>
                </v-list>
              </div>

              <div class="col-sm-7 col-lg-4 elevation-1">
                <v-list
                  class="overflow-auto"
                  max-height="70vh"
                  dense
                >
                  <v-subheader class="text-center"><h4>Selected clubs</h4></v-subheader>

                  <v-list-item
                    v-for="item in topclub"
                    :key="item.team_id"
                    :value="item.team_id"
                    @click="update_topclub(item)">
                    <template v-slot:default="{ active }">
                      <v-list-item-icon class="align-self-center mr-5">
                        <img
                          class='w-30 h-30'
                          :src="item.team_image"
                          />
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title v-text="item.team_name"></v-list-item-title>

                        <v-list-item-subtitle v-text="item.country_name"></v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-icon
                          color="yellow darken-3">
                          mdi-close
                        </v-icon>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </v-list>
              </div>
            </div>
          </v-tab-item>

          <v-snackbar
            v-model="notify"
            :timeout="1500"
            color="success">
            {{ $t('message.successsave') }}
          </v-snackbar>
        </v-tabs-items>
			</div>
		</div>
		</div>
	</v-app>
</template>

<script>
  import axios from 'axios';
  import config from '@/config'
  import variables from '@/variables'
  import moment from 'moment-timezone'
  import Dropdown from '../components/Dropdown.vue';

  axios.defaults.baseURL = config.API_URL;
  
  export default {
    components: { Dropdown },
    data () {
      return {
        tab: null,
        tz: localStorage.tz ? localStorage.tz : '+0',
        ofmt: localStorage.odds_format ? localStorage.odds_format : '0',
        ct: null,
        ct_topleague: null,
        fav_league: localStorage.favorite_league ? JSON.parse(localStorage.favorite_league) : [],
        topleague: localStorage.topleague ? JSON.parse(localStorage.topleague) : [],
        topclub: localStorage.topclub ? JSON.parse(localStorage.topclub) : [],
        days_coach_changed: localStorage.days_coach_changed ? localStorage.days_coach_changed : 20,
        apply_general: false,
        tzones: variables.timezones,
        countries: variables.countries,
        odds_format: [
          {text: "EU Odds (1.50)", value: '0'},
          {text: "US Odds (-200)", value: '1'}
        ],
        currentTime: moment.tz('Etc/GMT' + (localStorage.tz ? localStorage.tz : '+0')).format('YYYY-MM-DD HH:mm:ss'),
        categories: ['general', 'favorite', 'topleague', 'topclub'],
        total_leagues: {},
        country_leagues: [],
        country_topleagues: [],
        league_selected: [],
        topleague_selected: [],
        topclub_selected: [],
        loading: false,
        notify: false,
        authorization: false,
        message: "choosecountry",
        searchTeamName: null,
        searching: false,
        club_all: [],
        count_form: localStorage.count_form ? localStorage.count_form : 5,
        type_form: localStorage.type_form ? localStorage.type_form : '0',       // 0: home/away, 1: overall
        type_form_items: [
          {text: "home_away", value: '0'},
          {text: "overall", value: '1'},
        ],
        league_form: localStorage.league_form ? localStorage.league_form : '0', // 0: all leagues, 1: league to play
        league_form_items: [
          {text: "alltournament", value: '0'},
          {text: "tournamenttoplay", value: '1'},
        ]
      }
    },
    
    created () {
      setInterval(() => {
        this.currentTime = moment.tz('Etc/GMT' + this.tz).format('YYYY-MM-DD HH:mm:ss');
      }, 1000);
    },

    watch: {
      // detect changes on timezone
      tz: function (val) {
        this.tz = val

        this.apply_general = true
      },

      // detect changes on odds format
      ofmt: function (val) {
        this.apply_general = true
      },

      // detect changes on coach changed
      days_coach_changed: function (val) {
        this.apply_general = true
      },

      type_form: function (val) {
        this.apply_general = true
      },

      league_form: function (val) {
        this.apply_general = true
      },

      count_form: function (val) {
        this.apply_general = true
      },

      // detect changes on selection country
      ct: function (val) {
        this.select_country(val.id, 1)
      },

      ct_topleague: function (val) {
        this.select_country(val.id, 2)
      },

      // changes on country
      country_leagues: function (val) {
        this.league_selected = []
        val.forEach((lg, ind) => {
          if (this.fav_league.includes(lg.league_id)) {
            this.league_selected.push(lg.league_id)
          }
        })
      },

      country_topleagues: function (val) {
        this.topleague_selected = [];
        val.forEach((lg, ind) => {
          let index = this.topleague.findIndex(item => item.league_id === lg.league_id);

          if (index !== -1) {
            this.topleague_selected.push(lg.league_id);
          }
        })
      }
    },
    methods: {
      save_general() {
        if (this.tz != localStorage.tz || (!localStorage.tz && this.tz != '+0')) {
          localStorage.tz = this.tz
          this.notify = true
        }

        if (this.ofmt != localStorage.odds_format || (!localStorage.odds_format && this.ofmt != '0')) {
          localStorage.odds_format = this.ofmt
          this.notify = true
        }

        if (this.days_coach_changed != localStorage.days_coach_changed || (!localStorage.days_coach_changed && this.days_coach_changed != 20)) {
          localStorage.days_coach_changed = this.days_coach_changed
          this.notify = true
        }

        if (this.type_form != localStorage.type_form || (!localStorage.type_form && this.type_form != 0)) {
          localStorage.type_form = this.type_form
          this.notify = true
        }

        if (this.league_form != localStorage.league_form || (!localStorage.league_form && this.league_form != 0)) {
          localStorage.league_form = this.league_form
          this.notify = true
        }

        if (this.count_form != localStorage.count_form || (!localStorage.count_form && this.count_form != 0)) {
          localStorage.count_form = this.count_form
          this.notify = true
        }

        this.apply_general = false
      },
      
      select_country(country_id, tab_id) {
        if (!localStorage.token) {
          this.message = "notlogin"
        } else {
          if (this.total_leagues[country_id]) {
            switch (tab_id) {
              case 1:
                this.country_leagues = this.total_leagues[country_id]
                break;
              case 2:
                this.country_topleagues = this.total_leagues[country_id]
                break;
              default:
                break;
            }
            
          } else {
            this.loading = true
            axios
            .get('/country/league/' + country_id,
              { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
            .then(response => {
              if (response.data.type == 0) {
                this.message = "notsubscribe"
              }else if (response.data.type == 1) {
                this.message = "expiredsubscribe"
              }else {
                this.total_leagues[country_id] = response.data.leagues
                
                switch (tab_id) {
                  case 1:
                    this.country_leagues = response.data.leagues
                    break;
                  case 2:
                    this.country_topleagues = response.data.leagues
                    break;
                  default:
                    break;
                }
                
                this.authorization = true
              }
              this.loading = false
            })
            .catch(error => {
              if (error.response.status == 401) {
                localStorage.removeItem('token')
                localStorage.removeItem('uid')
                location.reload()
              }
            })
          }
        }
      },

      update_favorite(lg_id) {
        let f = this.league_selected.includes(lg_id) ? 0 : 1

        if (localStorage.token) {
          axios
            .post('/pick_favorite/', 
              {'league_id': lg_id, 'flag': f },
              { headers: { 'Authorization': 'Token ' + localStorage.token }}
              )
            .then((response) => {
              localStorage.setItem('favorite_league', JSON.stringify(response.data.leagues))
              this.fav_league = response.data.leagues
            })
            .catch(error => {
              if (error.response.status == 401) {
                localStorage.removeItem('token')
                localStorage.removeItem('uid')
                location.reload()
              }
            })
        }
      },

      update_topleague(lg) {
        let arr = this.topleague.slice();
        
        const index = arr.findIndex(item => item.league_id === lg.league_id);

        if (index !== -1) {
          arr.splice(index, 1);

          let tmp = this.topleague_selected.slice();
          let ind = tmp.findIndex(item => item === lg.league_id);
          
          if (ind !== -1) {
            tmp.splice(ind, 1);
          }

          this.topleague_selected = tmp;
        } else {
          arr.push(lg);
        }
        
        localStorage.setItem("topleague", JSON.stringify(arr));
        this.topleague = arr;
      },

      searchTeam () {
        if (this.searchTeamName && !this.searchTeamName.startsWith(" ")) {
          this.searchTeamName = this.searchTeamName.toLowerCase();
          this.loading = true;
          
          axios
            .get('/team/name/' + this.searchTeamName)
            .then(response => {
              this.club_all = response.data;

              this.topclub_selected = [];
              this.club_all.forEach((team, ind) => {
                let index = this.topclub.findIndex(item => item.team_id === team.team_id);

                if (index !== -1) {
                  this.topclub_selected.push(team.team_id);
                }
              })
              this.loading = false;
            })
            .catch(error => {
            })
        }
      },

      update_topclub(team) {
        let arr = this.topclub.slice();
        const index = arr.findIndex(item => item.team_id === team.team_id);

        if (index !== -1) {
          arr.splice(index, 1);

          let tmp = this.topclub_selected.slice();
          let ind = tmp.findIndex(item => item === team.team_id);
          
          if (ind !== -1) {
            tmp.splice(ind, 1);
          }

          this.topclub_selected = tmp;
        } else {
          arr.push(team);
        }
        
        localStorage.setItem("topclub", JSON.stringify(arr));
        this.topclub = arr;
      },
    }
  }
</script>

<style>
</style>