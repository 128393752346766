import Vue from 'vue'
import Router from 'vue-router'
import Upcoming from './pages/Upcoming.vue'
import UpcomingMatches from './components/UpcomingMatches.vue'
import SoccerMatch from './pages/SoccerMatch.vue'
import Compare from './pages/Compare.vue'
import Login from './pages/account/Login.vue'
import SignUp from './pages/account/Signup.vue'
import Activation from './pages/account/Activation.vue'
import Contacts from './pages/Contacts.vue'
import Subscription from './pages/Subscription.vue'
import MainNavbar from './layout/MainNavbar.vue'
import MainFooter from './layout/MainFooter.vue'
// import Blog from './pages/Blog.vue'
// import Article from './pages/Article.vue'
// import EditArticle from './pages/EditArticle.vue'
// import Team from './pages/Team.vue'
// import League from './pages/League.vue'
import Setting from './pages/Setting.vue'
// import Player from './pages/Player.vue'
// import Country from './pages/Country.vue'
// import Fixture from './pages/Fixture.vue'
import ForgotPassword from './pages/account/ForgotPassword.vue'
import ResetPassword from './pages/account/ResetPassword.vue'
import SubscribeMessage from './pages/message/Subscribe.vue'

// sportmonks api v3
import League_v3 from './pages/League_v3.vue'
import Inplay_v3 from './pages/Inplay_v3.vue'
import Fixture_v3 from './pages/Fixture_v3.vue'
import Team_v3 from './pages/Team_v3.vue'
import Player_v3 from './pages/Player_v3.vue'
import Coach_v3 from './pages/Coach_v3.vue'
import Country_v3 from './pages/Country_v3.vue'
import Transfer_v3 from './pages/Transfer_v3.vue'

Vue.use(Router);

const router = new Router({
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  mode: 'history',
  routes: [
    {
      path: '/',
      components: { default: Upcoming, header: MainNavbar, footer: MainFooter },
      children: [
        {
          path: 'matches/:day?',
          name: 'day-match',
          component: UpcomingMatches,
          meta: { title: 'Upcoming Matches | Foot-Corner' }
        },
        {
          path: '',
          name: 'today-match',
          component: UpcomingMatches,
          meta: { title: 'Upcoming Matches | Foot-Corner' }
        }       
      ],
      meta: { title: 'Upcoming Matches | Foot-Corner' }
    },
    // {
    //   path: '/blog',
    //   name: 'blog',
    //   components: { default: Blog, header: MainNavbar, footer: MainFooter },
    //   props: {
    //     header: { colorOnScroll: 400 },
    //   },
    //   meta: { title: 'Blogs | Foot-Corner' }
    // },
    // {
    //   path: '/article/:id',
    //   name: 'article',
    //   components: { default: Article, header: MainNavbar, footer: MainFooter },
    //   props: {
    //     header: { colorOnScroll: 400 },
    //   },
    //   meta: { title: 'Article | Foot-Corner' }
    // },
    // {
    //   path: '/edit/:id',
    //   name: 'editarticle',
    //   components: { default: EditArticle, header: MainNavbar, footer: MainFooter },
    //   props: {
    //     header: { colorOnScroll: 400 },
    //   },
    //   meta: { title: 'Edit article | Foot-Corner' }
    // },
    {
      path: '/subscription',
      name: 'subscription',
      components: { default: Subscription, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      },
      meta: { title: 'Subscription | Foot-Corner' }
    },
    {
      path: '/soccer',
      name: 'soccermatch',
      mode: 'history',
      components: {default: SoccerMatch, header: MainNavbar, footer: MainFooter },
      props: {
        default: route => ({ 
          mid: route.query.mid,
          cid: route.query.cid,
          cn: route.query.cn,
        }),
        header: { colorOnScroll: 400 },
      },
      meta: { title: 'Soccer match | Foot-Corner' }
    },
    {
      path: '/compare/teams/:hid/:aid',
      name: 'copmpare-teams',
      mode: 'history',
      components: {default: Compare, header: MainNavbar, footer: MainFooter },
      props: {
        default: route => ({ 
          hname: route.query.hn,
          aname: route.query.an,
          hcountry: route.query.hc,
          acountry: route.query.ac,
        }),
        header: { colorOnScroll: 400 },
      },
      meta: { title: 'Compare Teams | Foot-Corner' }
    },
    // {
    //   path: '/v2/fixture/:fid',
    //   name: 'fixture_v2',
    //   mode: 'history',
    //   components: {default: Fixture, header: MainNavbar, footer: MainFooter },
    //   meta: {
    //     title: 'Match details | Foot-Corner',
    //     requiresAuth: true
    //   }
    // },
    {
      path: '/fixture/:fid',
      name: 'fixture',
      mode: 'history',
      components: {default: Fixture_v3, header: MainNavbar, footer: MainFooter },
      meta: {
        title: 'Match details | Foot-Corner',
        requiresAuth: true
      }
    },
    // {
    //   path: '/v2/team/:id',
    //   name: 'team_v2',
    //   mode: 'history',
    //   components: {default: Team, header: MainNavbar, footer: MainFooter },
    //   meta: {
    //     title: 'Team | Foot-Corner',
    //     requiresAuth: true
    //   }
    // },
    {
      path: '/team/:id',
      name: 'team',
      mode: 'history',
      components: {default: Team_v3, header: MainNavbar, footer: MainFooter },
      meta: {
        title: 'Team | Foot-Corner',
        requiresAuth: true
      }
    },
    // {
    //   path: '/v2/player/:pid',
    //   name: 'player_v2',
    //   mode: 'history',
    //   components: {default: Player, header: MainNavbar, footer: MainFooter },
    //   meta: {
    //     title: 'Player | Foot-Corner',
    //     requiresAuth: true
    //   }
    // },
    {
      path: '/player/:pid',
      name: 'player',
      mode: 'history',
      components: {default: Player_v3, header: MainNavbar, footer: MainFooter },
      meta: {
        title: 'Player | Foot-Corner',
        requiresAuth: true
      }
    },
    // {
    //   path: '/v2/league/:lid',
    //   name: 'league_v2',
    //   mode: 'history',
    //   components: {default: League, header: MainNavbar, footer: MainFooter },
    //   meta: {
    //     title: 'League | Foot-Corner',
    //     requiresAuth: true
    //   }
    // },
    {
      path: '/league/:lid',
      name: 'league',
      mode: 'history',
      components: {default: League_v3, header: MainNavbar, footer: MainFooter },
      meta: {
        title: 'League | Foot-Corner',
        requiresAuth: true
      }
    },
    {
      path: '/coach/:id',
      name: 'coach',
      mode: 'history',
      components: {default: Coach_v3, header: MainNavbar, footer: MainFooter },
      meta: {
        title: 'Coach | Foot-Corner',
        requiresAuth: true
      }
    },
    {
      path: '/country/:cid',
      name: 'country',
      mode: 'history',
      components: {default: Country_v3, header: MainNavbar, footer: MainFooter },
      meta: {
        title: 'Country | Foot-Corner',
        requiresAuth: true
      }
    },
    // {
    //   path: '/v2/country/:cid',
    //   name: 'country_v2',
    //   mode: 'history',
    //   components: {default: Country, header: MainNavbar, footer: MainFooter },
    //   meta: {
    //     title: 'Country | Foot-Corner',
    //     requiresAuth: true
    //   }
    // },
    {
      path: '/login',
      name: 'login',
      components: { default: Login, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 }
      },
      meta: { title: 'Login | Foot-Corner' }
    },
    {
      path: '/signup',
      name: 'signup',
      components: { default: SignUp, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 }
      },
      meta: { title: 'Signup | Foot-Corner' }
    },
    {
      path: '/activate',
      name: 'activate',
      components: { default: Activation },
      props: {
        header: { colorOnScroll: 400 }
      },
      meta: { title: 'Activation | Foot-Corner' }
    },
    {
      path: '/contacts',
      name: 'contacts',
      components: { default: Contacts, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 },
      },
      meta: { title: 'Contacts | Foot-Corner' }
    },
    {
      path: '/setting',
      name: 'setting',
      components: { default: Setting, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
      },
      meta: { title: 'Settings | Foot-Corner' }
    },
    {
      path: '/account/forgot-password',
      name: 'forgot-password',
      components: { default: ForgotPassword, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 }
      },
      meta: { title: 'Forgot Password | Foot-Corner' }
    },
    {
      path: '/account/reset-password',
      name: 'reset-password',
      components: { default: ResetPassword, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 }
      },
      meta: { title: 'Reset Password | Foot-Corner' }
    },
    {
      path: '/message/subscribe/:type',
      name: 'subscribe-message',
      components: { default: SubscribeMessage, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 }
      },
      meta: { title: 'Subscribe Message | Foot-Corner' }
    },
    {
      path: '/inplay',
      name: 'inplay',
      mode: 'history',
      components: {default: Inplay_v3, header: MainNavbar, footer: MainFooter },
      meta: {
        title: 'Inplay | Foot-Corner',
        requiresAuth: true
      }
    },
    {
      path: '/transfer',
      name: 'transfer',
      mode: 'history',
      components: {default: Transfer_v3, header: MainNavbar, footer: MainFooter },
      meta: {
        title: 'Transfer | Foot-Corner',
        requiresAuth: true
      }
    },
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (localStorage.token) {
      next()
    } else {
      next({
        path: '/login',
        // query: { redirect: to.fullPath }
      })
    }
  } else {
    next() // make sure to always call next()!
  }
})

const DEFAULT_TITLE = 'Foot-Corner';
router.afterEach((to, from) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});
export default router;
