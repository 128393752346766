<template>
    <div class="page-header clear-filter">
        <div
            class="page-header-image"
            style="background-image: url('img/corner-ball.jpg')">
        </div>

        <div class="container">
            <div class="column is-8 is-offset-2">
                <horizontal-stepper
                    :steps="demoSteps"
                    @completed-step="completeStep"
                    :top-buttons="true"
                    @active-step="isStepActive"
                    @stepper-finished="alert"
                    @sendMsg="sendMsg">
                </horizontal-stepper>
            </div>
        </div>
    </div>
</template>

<script>
    import HorizontalStepper from './contacts-stepper/HorizontalStepper.vue';

    import StepOne from './contacts-stepper/StepOne.vue';
    import StepTwo from './contacts-stepper/StepTwo.vue';
    import StepThird from './contacts-stepper/StepThird.vue';
    

    export default {
        name: 'contacts',
        components: {
            HorizontalStepper,
            StepOne,
            StepTwo,
            StepThird,
        },
        data(){
            return {
                activeStep: 0
            }
        },
        computed: {
            demoSteps () {
                return [
                    {
                        icon: 'report_problem',
                        name: 'first',
                        title: this.$t('notrobot'),
                        subtitle: '',
                        component: StepOne,
                        completed: false

                    },
                    {
                        icon: 'mail',
                        name: 'second',
                        title: this.$t('message.message'),
                        subtitle: '',
                        component: StepTwo,
                        completed: false
                    },
                    {
                        icon: 'announcement',
                        name: 'third',
                        title: this.$t('thanks'),
                        subtitle: '',
                        component: StepThird,
                        completed: false
                    }
                ]
            }
        },
        methods: {
            completeStep(payload) {
                this.demoSteps.forEach((step) => {
                    if (step.name === payload.name) {
                        step.completed = true;
                    }
                })
            },
            isStepActive(payload) {
                this.demoSteps.forEach((step) => {
                    if (step.name === payload.name) {
                        if(step.completed === true) {
                            step.completed = false;
                        }
                    }
                })
            },
            alert(payload) {
                alert('end')
            }
        }
    }
</script>

<style scoped>
    #app {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    .pointer {
        cursor: pointer;
    }

    h1, h2 {
        font-weight: normal;
    }

    hr {
        background-color: transparent;
        border: none;
        display: block;
        height: inherit;
        margin: 1.5rem 0;
        border-top: dashed 1px;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        color: #0b99b9;
        text-decoration: underline;
    }

    .text-medium-grey {
        color: #333;
    }

    .text-light-grey {
        color: #888;
    }

    .box.formated {
        position: relative;
        padding: 0;
    }

    .box.formated .heading {
        font-size: 1rem;
        text-transform: capitalize;
        padding: .8rem 1.5rem;
        background-color: #fafafa;
    }

    .box.formated .content {
        padding: 1rem 2rem;
    }

    i.top-left {
        position: absolute;
        left: 1.5rem;
        top: 0.8rem;
    }

    .vertical-separator {
        display: flex;
        justify-content: space-around;
    }

    .vertical-separator .line {
        border-right: 1px solid #cccccc;
    }
</style>
