const timezones = [
    { offset: '+12', text: 'Etc/GMT-12:00' },
    { offset: '+11', text: 'Etc/GMT-11:00' },
    { offset: '+10', text: 'Etc/GMT-10:00' },
    { offset: '+9', text: 'Etc/GMT-09:00' },
    { offset: '+8', text: 'Etc/GMT-08:00' },
    { offset: '+7', text: 'Etc/GMT-07:00' },
    { offset: '+6', text: 'Etc/GMT-06:00' },
    { offset: '+5', text: 'Etc/GMT-05:00' },
    { offset: '+4', text: 'Etc/GMT-04:00' },
    { offset: '+3', text: 'Etc/GMT-03:00' },
    { offset: '+2', text: 'Etc/GMT-02:00' },
    { offset: '+1', text: 'Etc/GMT-01:00' },
    { offset: '+0', text: 'Etc/GMT+00:00' },
    { offset: '-1', text: 'Etc/GMT+01:00' },
    { offset: '-2', text: 'Etc/GMT+02:00' },
    { offset: '-3', text: 'Etc/GMT+03:00' },
    { offset: '-4', text: 'Etc/GMT+04:00' },
    { offset: '-5', text: 'Etc/GMT+05:00' },
    { offset: '-6', text: 'Etc/GMT+06:00' },
    { offset: '-7', text: 'Etc/GMT+07:00' },
    { offset: '-8', text: 'Etc/GMT+08:00' },
    { offset: '-9', text: 'Etc/GMT+09:00' },
    { offset: '-10', text: 'Etc/GMT+10:00' },
    { offset: '-11', text: 'Etc/GMT+11:00' },
    { offset: '-12', text: 'Etc/GMT+12:00' },
]

const tz_location = [
    { no: 1, offset: '-12:00', text: 'Etc/GMT+12' },
    { no: 2, offset: '-11:00', text: 'US/Samoa' },
    { no: 3, offset: '-11:00', text: 'Pacific/Samoa' },
    { no: 4, offset: '-11:00', text: 'Pacific/Niue' },
    { no: 5, offset: '-11:00', text: 'Pacific/Midway' },
    { no: 6, offset: '-10:00', text: 'US/Hawaii' },
    { no: 7, offset: '-10:00', text: 'Pacific/Johnston' },
    { no: 8, offset: '-10:00', text: 'Pacific/Honolulu' },
    { no: 9, offset: '-09:30', text: 'Pacific/Marquesas' },
    { no: 10, offset: '-09:00', text: 'US/Aleutian' },
    { no: 11, offset: '-09:00', text: 'Pacific/Gambier' },
    { no: 12, offset: '-09:00', text: 'America/Atka' },
    { no: 13, offset: '-09:00', text: 'America/Adak' },
    { no: 14, offset: '-08:00', text: 'US/Alaska' },
    { no: 15, offset: '-08:00', text: 'America/Yakutat' },
    { no: 16, offset: '-08:00', text: 'America/Sitka' },
    { no: 17, offset: '-08:00', text: 'America/Nome' },
    { no: 18, offset: '-08:00', text: 'America/Metlakatla' },
    { no: 19, offset: '-08:00', text: 'America/Juneau' },
    { no: 20, offset: '-08:00', text: 'America/Anchorage' },
    { no: 21, offset: '-07:00', text: 'US/Pacific' },
    { no: 22, offset: '-07:00', text: 'US/Arizona' },
    { no: 23, offset: '-07:00', text: 'MST' },
    { no: 24, offset: '-07:00', text: 'Mexico/BajaNorte' },
    { no: 25, offset: '-07:00', text: 'Canada/Yukon' },
    { no: 26, offset: '-07:00', text: 'Canada/Pacific' },
    { no: 27, offset: '-07:00', text: 'America/Vancouver' },
    { no: 28, offset: '-07:00', text: 'America/Phoenix' },
    { no: 29, offset: '-07:00', text: 'America/Los_Angeles' },
    { no: 30, offset: '-06:00', text: 'US/Mountain' },
    { no: 31, offset: '-06:00', text: 'Pacific/Galapagos' },
    { no: 32, offset: '-06:00', text: 'Pacific/Easter' },
    { no: 33, offset: '-06:00', text: 'America/Guatemala' },
    { no: 34, offset: '-06:00', text: 'America/El_Salvador' },
    { no: 35, offset: '-06:00', text: 'America/Costa_Rica' },
    { no: 36, offset: '-05:00', text: 'US/Central' },
    { no: 37, offset: '-05:00', text: 'Mexico/General' },
    { no: 38, offset: '-05:00', text: 'Canada/Central' },
    { no: 39, offset: '-05:00', text: 'Brazil/Acre' },
    { no: 40, offset: '-05:00', text: 'America/Winnipeg' },
    { no: 41, offset: '-05:00', text: 'America/Panama' },
    { no: 42, offset: '-05:00', text: 'America/Monterrey' },
    { no: 43, offset: '-05:00', text: 'America/Mexico_City' },
    { no: 44, offset: '-05:00', text: 'America/Jamaica' },
    { no: 45, offset: '-05:00', text: 'America/Chicago' },
    { no: 46, offset: '-05:00', text: 'America/Bogota' },
    { no: 47, offset: '-05:00', text: 'America/Atikokan' },
    { no: 48, offset: '-04:00', text: 'US/Michigan' },
    { no: 49, offset: '-04:00', text: 'US/Eastern' },
    { no: 50, offset: '-04:00', text: 'Chile/Continental' },
    { no: 51, offset: '-04:00', text: 'Canada/Eastern' },
    { no: 52, offset: '-04:00', text: 'Brazil/West' },
    { no: 53, offset: '-04:00', text: 'America/Virgin' },
    { no: 54, offset: '-04:00', text: 'America/Toronto' },
    { no: 55, offset: '-04:00', text: 'America/Santiago' },
    { no: 56, offset: '-04:00', text: 'America/New_York' },
    { no: 57, offset: '-04:00', text: 'America/Montreal' },
    { no: 58, offset: '-04:00', text: 'America/Iqaluit' },
    { no: 59, offset: '-04:00', text: 'America/Guyana' },
    { no: 60, offset: '-04:00', text: 'America/Guadeloupe' },
    { no: 61, offset: '-04:00', text: 'America/Grenada' },
    { no: 62, offset: '-04:00', text: 'America/Dominica' },
    { no: 63, offset: '-04:00', text: 'America/Detroit' },
    { no: 64, offset: '-04:00', text: 'America/Caracas' },
    { no: 65, offset: '-04:00', text: 'America/Barbados' },
    { no: 66, offset: '-03:00', text: 'Brazil/East' },
    { no: 67, offset: '-03:00', text: 'America/Sao_Paulo' },
    { no: 68, offset: '-03:00', text: 'America/Buenos_Aires' },
    { no: 69, offset: '-02:30', text: 'Canada/Newfoundland' },
    { no: 70, offset: '-02:00', text: 'Atlantic/South_Georgia' },
    { no: 71, offset: '-02:00', text: 'America/Nuuk' },
    { no: 72, offset: '-02:00', text: 'America/Noronha' },
    { no: 73, offset: '-02:00', text: 'America/Miquelon' },
    { no: 74, offset: '-02:00', text: 'America/Godthab' },
    { no: 75, offset: '-01:00', text: 'Atlantic/Cape_Verde' },
    { no: 76, offset: '+00:00', text: 'GMT' },
    { no: 77, offset: '+00:00', text: 'Greenwich' },
    { no: 78, offset: '+00:00', text: 'Iceland' },
    { no: 79, offset: '+00:00', text: 'UTC' },
    { no: 80, offset: '+01:00', text: 'Africa/Casablanca' },
    { no: 81, offset: '+01:00', text: 'Africa/Tunis' },
    { no: 82, offset: '+01:00', text: 'Atlantic/Canary' },
    { no: 83, offset: '+01:00', text: 'Atlantic/Madeira' },
    { no: 84, offset: '+01:00', text: 'Europe/Dublin' },
    { no: 85, offset: '+01:00', text: 'Europe/Lisbon' },
    { no: 86, offset: '+01:00', text: 'Europe/London' },
    { no: 87, offset: '+02:00', text: 'Africa/Cairo' },
    { no: 88, offset: '+02:00', text: 'Africa/Johannesburg' },
    { no: 89, offset: '+02:00', text: 'Europe/Amsterdam' },
    { no: 90, offset: '+02:00', text: 'Europe/Berlin' },
    { no: 91, offset: '+02:00', text: 'Europe/Brussels' },
    { no: 92, offset: '+02:00', text: 'Europe/Budapest' },
    { no: 93, offset: '+02:00', text: 'Europe/Copenhagen' },
    { no: 94, offset: '+02:00', text: 'Europe/Madrid' },
    { no: 95, offset: '+02:00', text: 'Europe/Paris' },
    { no: 96, offset: '+02:00', text: 'Europe/Rome' },
    { no: 97, offset: '+02:00', text: 'Europe/Stockholm' },
    { no: 98, offset: '+02:00', text: 'Europe/Vienna' },
    { no: 99, offset: '+02:00', text: 'Europe/Warsaw' },
    { no: 100, offset: '+02:00', text: 'Europe/Zurich' },
    { no: 101, offset: '+03:00', text: 'Africa/Nairobi' },
    { no: 102, offset: '+03:00', text: 'Asia/Baghdad' },
    { no: 103, offset: '+03:00', text: 'Asia/Bahrain' },
    { no: 104, offset: '+03:00', text: 'Asia/Istanbul' },
    { no: 105, offset: '+03:00', text: 'Asia/Jerusalem' },
    { no: 106, offset: '+03:00', text: 'Asia/Kuwait' },
    { no: 107, offset: '+03:00', text: 'Europe/Athens' },
    { no: 108, offset: '+03:00', text: 'Europe/Helsinki' },
    { no: 109, offset: '+03:00', text: 'Europe/Istanbul' },
    { no: 110, offset: '+03:00', text: 'Europe/Kiev' },
    { no: 111, offset: '+03:00', text: 'Europe/Moscow' },
    { no: 112, offset: '+03:00', text: 'Europe/Sofia' },
    { no: 113, offset: '+03:00', text: 'Europe/Volgograd' },
    { no: 114, offset: '+04:00', text: 'Asia/Dubai' },
    { no: 115, offset: '+04:30', text: 'Asia/Tehran' },
    { no: 116, offset: '+05:00', text: 'Asia/Karachi' },
    { no: 117, offset: '+05:00', text: 'Asia/Oral' },
    { no: 118, offset: '+05:00', text: 'Asia/Qyzylorda' },
    { no: 119, offset: '+05:00', text: 'Asia/Samarkand' },
    { no: 120, offset: '+05:00', text: 'Asia/Tashkent' },
    { no: 121, offset: '+05:00', text: 'Asia/Yekaterinburg' },
    { no: 122, offset: '+05:00', text: 'Indian/Kerguelen' },
    { no: 123, offset: '+05:00', text: 'Indian/Maldives' },
    { no: 124, offset: '+05:30', text: 'Asia/Calcutta' },
    { no: 125, offset: '+05:30', text: 'Asia/Colombo' },
    { no: 126, offset: '+05:30', text: 'Asia/Kolkata' },
    { no: 127, offset: '+05:45', text: 'Asia/Kathmandu' },
    { no: 128, offset: '+06:00', text: 'Antarctica/Vostok' },
    { no: 129, offset: '+06:00', text: 'Asia/Almaty' },
    { no: 130, offset: '+06:00', text: 'Asia/Omsk' },
    { no: 131, offset: '+06:00', text: 'Asia/Urumqi' },
    { no: 132, offset: '+06:30', text: 'Asia/Yangon' },
    { no: 133, offset: '+06:30', text: 'Indian/Cocos' },
    { no: 134, offset: '+07:00', text: 'Asia/Bangkok' },
    { no: 135, offset: '+07:00', text: 'Asia/Ho_Chi_Minh' },
    { no: 136, offset: '+07:00', text: 'Asia/Jakarta' },
    { no: 137, offset: '+07:00', text: 'Asia/Phnom_Penh' },
    { no: 138, offset: '+07:00', text: 'Asia/Saigon' },
    { no: 139, offset: '+07:00', text: 'Asia/Tomsk' },
    { no: 140, offset: '+07:00', text: 'Asia/Vientiane' },
    { no: 141, offset: '+08:00', text: 'Asia/Brunei' },
    { no: 142, offset: '+08:00', text: 'Asia/Hong_Kong' },
    { no: 143, offset: '+08:00', text: 'Asia/Kuala_Lumpur' },
    { no: 144, offset: '+08:00', text: 'Asia/Macau' },
    { no: 145, offset: '+08:00', text: 'Asia/Manila' },
    { no: 146, offset: '+08:00', text: 'Asia/Shanghai' },
    { no: 147, offset: '+08:00', text: 'Asia/Singapore' },
    { no: 148, offset: '+08:00', text: 'Asia/Taipei' },
    { no: 149, offset: '+08:00', text: 'Asia/Ulaanbaatar' },
    { no: 150, offset: '+08:00', text: 'Australia/Perth' },
    { no: 151, offset: '+08:00', text: 'Australia/West' },
    { no: 152, offset: '+09:00', text: 'Asia/Pyongyang' },
    { no: 153, offset: '+09:00', text: 'Asia/Seoul' },
    { no: 154, offset: '+09:00', text: 'Asia/Tokyo' },
    { no: 155, offset: '+09:30', text: 'Australia/Adelaide' },
    { no: 156, offset: '+09:30', text: 'Australia/Broken_Hill' },
    { no: 157, offset: '+09:30', text: 'Australia/Darwin' },
    { no: 158, offset: '+09:30', text: 'Australia/North' },
    { no: 159, offset: '+09:30', text: 'Australia/South' },
    { no: 160, offset: '+09:30', text: 'Australia/Yancowinna' },
    { no: 161, offset: '+10:00', text: 'Antarctica/DumontDUrville' },
    { no: 162, offset: '+10:00', text: 'Australia/Melbourne' },
    { no: 163, offset: '+10:00', text: 'Australia/Queensland' },
    { no: 164, offset: '+10:00', text: 'Australia/Sydney' },
    { no: 165, offset: '+10:00', text: 'Australia/Victoria' },
    { no: 166, offset: '+11:00', text: 'Asia/Sakhalin' },
    { no: 167, offset: '+11:00', text: 'Pacific/Efate' },
    { no: 168, offset: '+11:00', text: 'Pacific/Guadalcanal' },
    { no: 169, offset: '+11:00', text: 'Pacific/Kosrae' },
    { no: 170, offset: '+11:00', text: 'Pacific/Norfolk' },
    { no: 171, offset: '+11:00', text: 'Pacific/Noumea' },
    { no: 172, offset: '+11:00', text: 'Pacific/Pohnpei' },
    { no: 173, offset: '+12:00', text: 'Antarctica/McMurdo' },
    { no: 174, offset: '+12:00', text: 'Antarctica/South_Pole' },
    { no: 175, offset: '+12:00', text: 'Asia/Kamchatka' },
    { no: 176, offset: '+12:00', text: 'Pacific/Fiji' },
    { no: 177, offset: '+12:00', text: 'Pacific/Nauru' },    
]

const countries = [
    {name: "Africa", id: 147},
    {name: "Albania", id: 2454},
    {name: "Algeria", id: 614},
    {name: "Andorra", id: 2931},
    {name: "Angola", id: 911},
    {name: "Antigua and Barbuda", id: 137919},
    {name: "Argentina", id: 44},
    {name: "Armenia", id: 919},
    {name: "Aruba", id: 44569},
    {name: "Asia", id: 11240938},
    {name: "Australia", id: 98},
    {name: "Austria", id: 143},
    {name: "Azerbaijan", id: 2453},
    {name: "Bahrain", id: 190321},
    {name: "Bangladesh", id: 155043},
    {name: "Barbados", id: 33497},
    {name: "Belarus", id: 212},
    {name: "Belgium", id: 556},
    {name: "Belize", id: 144816},
    {name: "Benin", id: 7830},
    {name: "Bermuda", id: 15326},
    {name: "Bhutan", id: 911987},
    {name: "Bolivia", id: 7598},
    {name: "Bosnia and Herzegovina", id: 507},
    {name: "Botswana", id: 191038},
    {name: "Brazil", id: 5},
    {name: "Brunei Darussalam", id: 913469},
    {name: "Bulgaria", id: 224},
    {name: "Burkina Faso", id: 607},
    {name: "Burundi", id: 821},
    {name: "Cambodia", id: 909440},
    {name: "Cameroon", id: 593},
    {name: "Canada", id: 1004},
    {name: "Chile", id: 80},
    {name: "China PR", id: 5618},
    {name: "Chinese Taipei", id: 213370},
    {name: "Colombia", id: 353},
    {name: "Congo", id: 1560},
    {name: "Congo DR", id: 1320},
    {name: "Costa Rica", id: 1739},
    {name: "Côte d'Ivoire", id: 23},
    {name: "Croatia", id: 266},
    {name: "Cuba", id: 63604},
    {name: "Curaçao", id: 7437},
    {name: "Cyprus", id: 116},
    {name: "Czech Republic", id: 245},
    {name: "Denmark", id: 320},
    {name: "Djibouti", id: 908783},
    {name: "Dominican Republic", id: 79146},
    {name: "Ecuador", id: 459},
    {name: "Egypt", id: 886},
    {name: "El Salvador", id: 35008},
    {name: "England", id: 462},
    {name: "Estonia", id: 2405},
    {name: "Ethiopia", id: 44983},
    {name: "Europe", id: 41},
    {name: "Faroe Islands", id: 2154},
    {name: "Fiji", id: 866998},
    {name: "Finland", id: 1233},
    {name: "France", id: 17},
    {name: "Gabon", id: 3662},
    {name: "Gambia", id: 2507},
    {name: "Georgia", id: 119},
    {name: "Germany", id: 11},
    {name: "Ghana", id: 468},
    {name: "Gibraltar", id: 3374},
    {name: "Greece", id: 125},
    {name: "Grenada", id: 26833},
    {name: "Guadeloupe", id: 3779},
    {name: "Guatemala", id: 53128},
    {name: "Guinea", id: 1703},
    {name: "Haiti", id: 2177},
    {name: "Honduras", id: 1176},
    {name: "Hong Kong", id: 64306},
    {name: "Hungary", id: 674},
    {name: "Iceland", id: 1796},
    {name: "India", id: 153732},
    {name: "Indonesia", id: 88407},
    {name: "International", id: 190324},
    {name: "Iran", id: 488},
    {name: "Iraq", id: 107},
    {name: "Israel", id: 802},
    {name: "Italy", id: 251},
    {name: "Jamaica", id: 1640},
    {name: "Japan", id: 479},
    {name: "Jordan", id: 97374},
    {name: "Kazakhstan", id: 2427},
    {name: "Kenya", id: 1179},
    {name: "Korea Republic", id: 712},
    {name: "Kosovo", id: 122},
    {name: "Kuwait", id: 21462},
    {name: "Kyrgyzstan", id: 49438},
    {name: "Laos", id: 869895},
    {name: "Latvia", id: 748},
    {name: "Lebanon", id: 45412},
    {name: "Lesotho", id: 482134},
    {name: "Libya", id: 16175},
    {name: "Lithuania", id: 2079},
    {name: "Luxembourg", id: 3126},
    {name: "Macao", id: 453172},
    {name: "Macedonia FYR", id: 57142},
    {name: "Madagascar", id: 1646},
    {name: "Malawi", id: 20802},
    {name: "Malaysia", id: 3995},
    {name: "Maldives", id: 201580},
    {name: "Mali", id: 26},
    {name: "Malta", id: 2756},
    {name: "Mauritania", id: 2493},
    {name: "Mauritius", id: 360931},
    {name: "Mexico", id: 458},
    {name: "Moldova", id: 2345},
    {name: "Mongolia", id: 909526},
    {name: "Montenegro", id: 998},
    {name: "Morocco", id: 1424},
    {name: "Mozambique", id: 6783},
    {name: "Myanmar", id: 870933},
    {name: "N/C America", id: 344167},
    {name: "Namibia", id: 5790},
    {name: "Nepal", id: 862868},
    {name: "Netherlands", id: 38},
    {name: "New Zealand", id: 2817},
    {name: "Nicaragua", id: 3990},
    {name: "Nigeria", id: 716},
    {name: "North & Central America", id: 24143344},
    {name: "Northern Ireland", id: 491},
    {name: "Norway", id: 1578},
    {name: "Oman", id: 7091},
    {name: "Pakistan", id: 52126},
    {name: "Palestine", id: 48946},
    {name: "Panama", id: 65437},
    {name: "Paraguay", id: 1190},
    {name: "Peru", id: 338},
    {name: "Philippines", id: 50809},
    {name: "Poland", id: 2},
    {name: "Portugal", id: 20},
    {name: "Qatar", id: 74505},
    {name: "Republic of Ireland", id: 455},
    {name: "Romania", id: 155},
    {name: "Russia", id: 227},
    {name: "Rwanda", id: 88137},
    {name: "San Marino", id: 3347},
    {name: "Saudi Arabia", id: 35376},
    {name: "Scotland", id: 1161},
    {name: "Senegal", id: 200},
    {name: "Serbia", id: 296},
    {name: "Sierra Leone", id: 5724},
    {name: "Singapore", id: 213955},
    {name: "Slovakia", id: 401},
    {name: "Slovenia", id: 1638},
    {name: "Solomon Islands", id: 867004},
    {name: "Somalia", id: 7563},
    {name: "South Africa", id: 146},
    {name: "South America", id: 11555657},
    {name: "Spain", id: 32},
    {name: "St. Kitts and Nevis", id: 160047},
    {name: "Sudan", id: 56518},
    {name: "Suriname", id: 2088},
    {name: "Sweden", id: 47},
    {name: "Switzerland", id: 62},
    {name: "Syria", id: 3677},
    {name: "Tajikistan", id: 57160},
    {name: "Tanzania", id: 35210},
    {name: "Thailand", id: 49477},
    {name: "Togo", id: 3039},
    {name: "Trinidad and Tobago", id: 2228},
    {name: "Tunisia", id: 1439},
    {name: "Turkey", id: 404},
    {name: "Turkmenistan", id: 83175},
    {name: "Uganda", id: 4125},
    {name: "Ukraine", id: 86},
    {name: "United Arab Emirates", id: 2802},
    {name: "Uruguay", id: 158},
    {name: "USA", id: 3483},
    {name: "Uzbekistan", id: 2426},
    {name: "Venezuela", id: 275},
    {name: "Vietnam", id: 69697},
    {name: "Wales", id: 515},
    {name: "World", id: 99474},
    {name: "Yemen", id: 190318},
    {name: "Zambia", id: 2568},
    {name: "Zimbabwe", id: 2325}
]

export default {
    timezones: timezones,
    countries: countries
}