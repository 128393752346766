<template>
  <v-app>
    <div>
      <template v-if="!basicLoaded">
        <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
      </template>

      <template v-else>
        <div class="row no-gutters justify-center light-banner pt-50 background-cover">
          <div class="d-flex py-3">
            <img
              class="banner_logo1 align-self-center"
              :src="league.logo"
              onerror="style.display='none'"
            >

            <div class="ml-4 d-flex flex-column justify-space-around">
              <div class="text-center">
                <span
                  class="font-weight-bold green--text text--darken-2"
                  :class="{'subtitle-1': $vuetify.breakpoint.xs, 'h4': $vuetify.breakpoint.smAndUp}">
                  {{ league.name }}</span>
              </div>

              <div class="text-center">
                <router-link :to="{name: 'country', params: {cid: country.id}}">
                  <img
                    class="country_logo elevation-5 mr-3"
                    :class="{'d-none': $vuetify.breakpoint.xs}"
                    :src="country.flag">
                  
                  <span class="red--text text--darken-4">{{ country.name }}</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="justify-center">
          <div class="py-0">
            <v-tabs
              v-model="tab"
              centered
              show-arrows
              light
            >
              <v-tab
                v-for="item in items"
                :key="item"
              >
                <span style="font-size: small">{{ $t(item) }}</span>
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item key="Standing">
                <template v-if="!league.active">
                  <div class="d-flex flex-column align-center">
                    <p>{{ $t('message.leaguenotactive') }}</p>
                  </div>
                </template>

                <template v-else>
                  <div class="row justify-center my-1">
                    <div class="col-7 col-sm-4 col-md-3">
                      <v-select
                        v-model="seasonForStandings"
                        :items="seasons"
                        item-text="name"
                        item-value="id"
                        :label="$t('season')"
                        hide-details
                        dense
                        attach
                        outlined
                      />
                    </div>
                  </div>

                  <div class="align-center">
                    <template v-if="!tabLoaded" >
                      <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                    </template>

                    <div v-else class="d-flex flex-column">
                      <template v-if="standings && standings.length">
                        <template v-for="(group, ind) in standings">
                          <v-data-table
                            :key="ind"
                            class="mb-4 standing green-top-3 lightgrey-bottom-2"
                            :headers="standingsHeaders"
                            :items="group.standings"
                            item-key="id"
                            :expanded.sync="expandedStandings[ind]"
                            disable-pagination
                            hide-default-footer
                            :show-expand="$vuetify.breakpoint.smAndUp"
                            :mobile-breakpoint="0"
                          >
                            <template v-slot:expanded-item="{ item }">
                              <td colspan="2">
                                <span class="red--text text--darken-4">
                                  {{ item.rule ? item.rule.type.name : '' }}
                                </span>
                              </td>

                              <td>
                                <span class="green--text">{{ $t('home') }}</span>/<span class="orange--text">{{ $t('away') }}</span>
                              </td>

                              <td class="text-center">
                                <span class="green--text">{{ item.details.home_match_played }}</span>/<span class="orange--text">{{ item.details.away_match_played }}</span>
                              </td>

                              <td class="text-center">
                                <span class="green--text">{{ item.details.home_won }}</span>/<span class="orange--text">{{ item.details.away_won }}</span>
                              </td>

                              <td class="text-center">
                                <span class="green--text">{{ item.details.home_draw }}</span>/<span class="orange--text">{{ item.details.away_draw }}</span>
                              </td>

                              <td class="text-center">
                                <span class="green--text">{{ item.details.home_lost }}</span>/<span class="orange--text">{{ item.details.away_lost }}</span>
                              </td>

                              <td class="text-center">
                                <span class="green--text">{{ item.details.home_goals_for }}:{{ item.details.home_goals_against }}</span>/<span class="orange--text">{{ item.details.away_goals_for }}:{{ item.details.away_goals_against }}</span>
                              </td>

                              <td class="text-center">
                                <span class="green--text">{{ item.details.home_goals_for - item.details.home_goals_against }}</span>/<span class="orange--text">{{ item.details.away_goals_for - item.details.away_goals_against }}</span>
                              </td>

                              <td></td>

                              <td class="text-center">
                                <span class="green--text">{{ item.details.home_point }}</span>/<span class="orange--text">{{ item.details.away_point }}</span>
                              </td>
                            </template>

                            <template v-if="group.name" v-slot:top>
                              <span class="subtitle-2 pl-1">{{ group.name }}</span>
                            </template>

                            <template v-slot:header.team="{ header }">
                              <span>{{ $t(header.text) }}</span>
                            </template>

                            <template v-slot:header.totalPlayed="{ header }">
                              <span :title="$t('mp.tooltip')">{{ $t(`${header.text}.label`) }}</span>
                            </template>

                            <template v-slot:header.win="{ header }">
                              <span :title="$t('w.tooltip')">{{ $t(`${header.text}.label`) }}</span>
                            </template>

                            <template v-slot:header.draw="{ header }">
                              <span :title="$t('d.tooltip')">{{ $t(`${header.text}.label`) }}</span>
                            </template>

                            <template v-slot:header.lost="{ header }">
                              <span :title="$t('l.tooltip')">{{ $t(`${header.text}.label`) }}</span>
                            </template>

                            <template v-slot:header.goals="{ header }">
                              <span :title="$t('goalsfor.tooltip')">{{ $t(`${header.text}.label`) }}</span>
                            </template>

                            <template v-slot:header.goalDiff="{ header }">
                              <span :title="$t('gd.tooltip')">{{ $t(`${header.text}.label`) }}</span>
                            </template>

                            <template v-slot:header.point="{ header }">
                              <span :title="$t('pts.tooltip')">{{ $t(`${header.text}.label`) }}</span>
                            </template>

                            <template v-slot:item.position="{ item }">
                              <span :style="`color: ${letterColor['position']}`">{{ item.position }}</span>
                            </template>

                            <template v-slot:item.team="{ item }">
                              <router-link :to="{name: 'team', params: {id: item.participant_id}}">
                                <template v-if="item.participant">
                                  <img
                                    class="wh-20"
                                    :src="item.participant.image_path"
                                    :title="item.participant.name"
                                    onerror="style.display='none'"
                                    />

                                  <span class="ml-2 blue-grey--text">
                                    {{ $vuetify.breakpoint.xs ? (item.participant.short_code ? item.participant.short_code : item.participant.name.substring(0, 3)) : item.participant.name }}
                                  </span>
                                </template>

                                <span v-else class="ml-2 blue-grey--text">
                                  {{ item.participant.name}}
                                </span>
                              </router-link>
                            </template>
                            
                            <template v-slot:item.totalPlayed="{ item }">
                              <span>{{ item.details.overall_match_played }}</span>
                            </template>

                            <template v-slot:item.win="{ item }">
                              <span>{{ item.details.overall_won }}</span>
                            </template>

                            <template v-slot:item.draw="{ item }">
                              <span>{{ item.details.overall_draw }}</span>
                            </template>

                            <template v-slot:item.lost="{ item }">
                              <span>{{ item.details.overall_lost }}</span>
                            </template>

                            <template v-slot:item.goals="{ item }">
                              <span>{{ item.details.overall_goals_for }}:{{ item.details.overall_goals_against }}</span>
                            </template>

                            <template v-slot:item.goalDiff="{ item }">
                              <span>{{ item.details.overall_goals_for - item.details.overall_goals_against }}</span>
                            </template>

                            <template v-slot:item.point="{ item }">
                              <span :style="`color: ${letterColor['p']}`">{{ item.details.overall_point }}</span>
                            </template>

                            <template v-slot:item.recent="{ item }">
                              <div 
                                v-for="(fx, ind) in item.form"
                                :key="ind"
                                class="fixture_tooltip font-weight-bold mr-1"
                                :style="`cursor: pointer; color: ${letterColor[fx.form]}`">
                                {{ fx.form }}
                                
                                <span v-if="!$vuetify.breakpoint.xs"
                                  class="tooltiptext font-weight-normal"
                                  :style="`background: ${letterColor[fx.form]}`">
                                  {{ fx.fixture.name }}
                                </span>
                              </div>
                            </template>
                          </v-data-table>
                        </template>
                      </template>

                      <p v-else>{{ $t('message.nostandings') }}</p>
                    </div>
                  </div>
                </template>
              </v-tab-item>

              <v-tab-item key="Upcoming">
                <template v-if="!league.active">
                  <div class="d-flex flex-column align-center">
                    <p>{{ $t('message.leaguenotactive') }}</p>
                  </div>
                </template>

                <template v-else>
                  <template v-if="!tabLoaded" >
                    <div class='ripple_loading' ><img src='/img/icons/Ripple-1s-200px.png'></div>
                  </template>

                  <div v-else class="justify-center">
                    <div class="align-center">
                      <v-simple-table v-if="upcomings && upcomings.length" class="table-row-40">
                        <tbody>
                          <tr
                            v-for="(item, ind) in upcomings"
                            :key="ind"
                          >
                            <td class="overflow-anywhere" :class="{'text-center' : $vuetify.breakpoint.xs}">
                              <template v-if="$vuetify.breakpoint.xs">{{ item.starting_at_timestamp | moment('YY/MM/DD HH:mm')}}</template>
                              <template v-else>{{ item.starting_at_timestamp | moment('YYYY/MM/DD HH:mm')}}</template>
                            </td>

                            <!-- <td v-if="league.is_cup && !$vuetify.breakpoint.xs" class="red--text">{{ item.stage.data.name }}</td> -->
                            <td v-if="!$vuetify.breakpoint.sm" class="red--text">{{ item.stage.name }}</td>

                            <td>
                              <router-link
                                :to="{name: 'team', params: {id: item.participants[0].id}}"
                                class="d-flex align-center justify-end text-end">
                                <span class="blue-grey--text">
                                  {{ $vuetify.breakpoint.xs ? ( item.participants[0].short_code ? item.participants[0].short_code : item.participants[0].name.substring(0, 3)) : item.participants[0].name }}
                                </span>
                                
                                <img
                                  :src="item.participants[0].image_path"
                                  class="wh-30 ml-3"
                                  onerror="style.display='none'"
                                >
                              </router-link>
                            </td>
                            
                            <td
                              class="text-center"
                              :style="{ 'font-size': $vuetify.breakpoint.xs ? 'xx-small !important' : ''}">
                              <template v-if="item.state_id == 1">
                                <span>VS</span>
                              </template>

                              <template v-else>
                                <span class="red--text" :title="statusCode[item.state_id - 1].name + ': ' + statusCode[item.state_id - 1].info">
                                  {{ statusCode[item.state_id - 1].short_name }}
                                </span>
                              </template>
                            </td>
                            
                            <td>
                              <router-link
                                :to="{name: 'team', params: {id: item.participants[1].id}}"
                                class="d-flex align-center justify-start text-start">
                                <img
                                  :src="item.participants[1].image_path"
                                  class="wh-30 mr-3"
                                  onerror="style.display='none'"
                                >
                                
                                <span class="blue-grey--text">
                                  {{ $vuetify.breakpoint.xs ? ( item.participants[1].short_code ? item.participants[1].short_code : item.participants[1].name.substring(0, 3)) : item.participants[1].name }}
                                </span>
                              </router-link>
                            </td>

                            <td class="curPointer text-center" :title="$t('moredetails')" @click.stop="upcomingDetail(item.id, item.state_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                          </tr>
                        </tbody>
                      </v-simple-table>

                      <p v-else>{{ $t('message.noupcomings') }}</p>
                    </div>
                  </div>
                </template>
              </v-tab-item>

              <v-tab-item key="Result">
                <template v-if="!league.active">
                  <div class="d-flex flex-column align-center">
                    <p>{{ $t('message.leaguenotactive') }}</p>
                  </div>
                </template>

                <template v-else>
                  <template v-if="!tabLoaded" >
                    <div class='ripple_loading' ><img src='/img/icons/Ripple-1s-200px.png'></div>
                  </template>

                  <div v-else class="justify-center">
                    <div class="align-center">
                      <v-simple-table v-if="results && results.length" class="table-row-40">
                        <tbody>
                          <tr
                            v-for="(item, ind) in results"
                            :key="ind"
                          >
                            <td class="overflow-anywhere" :class="{'text-center' : $vuetify.breakpoint.xs}">
                              <template v-if="$vuetify.breakpoint.xs">{{ item.starting_at_timestamp | moment('YY/MM/DD HH:mm')}}</template>
                              <template v-else>{{ item.starting_at_timestamp | moment('YYYY/MM/DD HH:mm')}}</template>
                            </td>

                            <td v-if="!$vuetify.breakpoint.xs" class="red--text">{{ item.stage.name }}</td>
                            
                            <td>
                              <router-link :to="{name: 'team', params: {id: item.participants[0].id}}" class="d-flex align-center justify-end text-end">
                                <span
                                  :class="((item.scores.penalty[0] && (item.scores.penalty[0] > item.scores.penalty[1])) || (!item.scores.penalty[0] && (item.scores.goal[0] > item.scores.goal[1]))) ? 'font-weight-bold black--text' : 'blue-grey--text'">
                                  {{ $vuetify.breakpoint.xs ? ( item.participants[0].short_code ? item.participants[0].short_code : item.participants[0].name.substring(0, 3)) : item.participants[0].name }}
                                </span>

                                <img
                                  :src="item.participants[0].image_path"
                                  class="wh-30 ml-3"
                                  onerror="style.display='none'"
                                >
                              </router-link>
                            </td>
                            
                            <td class="text-center" :class="$vuetify.breakpoint.xs ? 'body-2' : 'subtitle-1'">
                              <span class="white-space-nowrap">
                                {{ item.scores.goal[0] }} - {{ item.scores.goal[1] }}
                              </span>

                              <!-- <template v-if="item.scores.penalty || (item.state_id == 8)"> -->
                                <span v-if="item.scores.penalty[0]" class="red--text" :title="$t('penalty')">
                                  ({{ item.scores.penalty[0] }}-{{ item.scores.penalty[1] }})
                                </span>
                              <!-- </template> -->

                              <template v-if="item.state_id != 5 && item.state_id != 8">
                                <span class="red--text" :title="statusCode[item.state_id - 1].name + ': ' + statusCode[item.state_id - 1].info">
                                  {{ statusCode[item.state_id - 1].short_name }}
                                </span>
                              </template>
                            </td>
                            
                            <td>
                              <router-link :to="{name: 'team', params: {id: item.participants[1].id}}" class="d-flex align-center justify-start text-start">
                                <img
                                  :src="item.participants[1].image_path"
                                  class="wh-30 mr-3"
                                  onerror="style.display='none'"
                                >
                                
                                <span
                                  :class="((item.scores.penalty[0] && (item.scores.penalty[0] < item.scores.penalty[1])) || (!item.scores.penalty[0] && (item.scores.goal[0] < item.scores.goal[1]))) ? 'font-weight-bold black--text' : 'blue-grey--text'">
                                  {{ $vuetify.breakpoint.xs ? ( item.participants[1].short_code ? item.participants[1].short_code : item.participants[1].name.substring(0, 3)) : item.participants[1].name }}
                                </span>
                              </router-link>
                            </td>

                            <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(item.id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                          </tr>
                        </tbody>
                      </v-simple-table>

                      <p v-else>{{ $t('message.noresults') }}</p>
                    </div>
                  </div>
                </template>
              </v-tab-item>

              <v-tab-item key="TopScorer">
                <template v-if="!league.active">
                  <div class="d-flex flex-column align-center">
                    <p>{{ $t('message.leaguenotactive') }}</p>
                  </div>
                </template>

                <template v-else>
                  <div class="row justify-center my-1">
                    <div class="col-7 col-sm-4 col-md-3 col-lg-2">
                      <v-select
                        v-model="seasonForScorers"
                        :items="seasons"
                        item-text="name"
                        item-value="id"
                        :label="$t('season')"
                        hide-details
                        dense
                        attach
                        outlined
                      />
                    </div>

                    <div class="col-5 col-sm-3 col-md-2 col-lg-2">
                      <v-select
                        v-model="categoryForScorer"
                        :items="category.scorer"
                        :item-text="item => $t(item.name)"
                        item-value="id"
                        hide-details
                        dense
                        attach
                        outlined
                      />
                    </div>
                  </div>

                  <template v-if="!tabLoaded" >
                    <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                  </template>

                  <div v-else class="d-flex flex-wrap flex-md-nowrap justify-space-around">
                    <template>
                      <v-data-table
                        v-if="scorers && scorers.length"
                        :headers="scorersHeaders"
                        :items="scorers"
                        :sort-by="['total']"
                        :sort-desc="[true]"
                        :items-per-page="15"
                        item-key="player_id"
                        class="table-row-40 green-top-3"
                        :mobile-breakpoint="0"
                        :footer-props="{
                          'items-per-page-text':$t('rowsperpage')
                        }"
                      >
                        <template v-slot:header.player="{ header }">
                          <span>{{ $t(header.text) }}</span>
                        </template>

                        <template v-slot:header.team="{ header }">
                          <!-- <span title="Team of player on the above season">{{ header.text }}</span> -->
                          <span>{{ $t(header.text) }}</span>
                        </template>

                        <template v-slot:header.total="{ header }">
                          <span v-if="categoryForScorer == 2"><img src="/img/icons/yellow_card.png"></span>
                          <span v-else-if="categoryForScorer == 3"><img src="/img/icons/red_card.png"></span>
                          <span v-else>{{ $t('total') }}</span>
                        </template>

                        <template v-slot:item.player="{ item }">
                          <router-link :to="{name: 'player', params: {pid: item.player_id}}">
                            <img class="wh-30" :src="item.player.image_path" onerror="style.display='none'" />
                            
                            <span class="ml-2 blue-grey--text">{{ item.player.common_name }}</span>
                          </router-link>
                        </template>

                        <template v-slot:item.team="{ item }">
                          <router-link :to="{name: 'team', params: {id: item.participant_id}}">
                            <span class="blue-grey--text">
                              {{ $vuetify.breakpoint.xs ? (item.participant.short_code ? item.participant.short_code : item.participant.name.substring(0, 3)) : item.participant.name }}
                            </span>
                          </router-link>
                        </template>
                      </v-data-table>

                      <p v-else>{{ $t('message.noscorers') }}</p>
                    </template>
                  </div>
                </template>
              </v-tab-item>

              <v-tab-item key="Statistic">
                <template v-if="!league.active">
                  <div class="d-flex flex-column align-center">
                    <p>{{ $t('message.leaguenotactive') }}</p>
                  </div>
                </template>

                <template v-else>
                  <div class="row justify-center my-1">
                    <div class="col-7 col-sm-4 col-md-3">
                      <v-select
                        v-model="seasonForStats"
                        :items="seasons"
                        item-text="name"
                        item-value="id"
                        :label="$t('season')"
                        hide-details
                        dense
                        attach
                        outlined
                      />
                    </div>
                  </div>

                  <div class="align-center">
                    <template v-if="!tabLoaded" >
                      <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                    </template>

                    <div v-else class="d-flex flex-column">
                      <template v-if="stats && stats.length">
                        <div
                          v-for="(item, ind) in stats"
                          :key="ind"
                          class="justify-space-between mb-2"
                          :class="(item.type_id == 196 || item.type_id == 1677 || item.type_id == 575) ? 'd-none' : 'd-flex'"
                          style="border-bottom: 1px solid lightgrey;">
                            <span class="blue-grey--text mr-10">{{ $t(`stats_type.${item.statistic_type.developer_name}`) }}: </span>

                            <span>
                              <template v-if="item.type_id == 34">
                                {{ item.value.team_most_corners_name }}, {{ item.value.count }} ({{ item.value.percentage }}%)
                              </template>

                              <template v-else-if="item.type_id == 188">
                                {{ item.value.total }} ({{ $t('played') }}: {{ item.value.played }}, {{ item.value.percentage }}%)
                              </template>

                              <template v-else-if="item.type_id == 191">
                                {{ item.value.total }} ({{ $t('avg') }}: {{ item.value.average }})
                              </template>

                              <template v-else-if="item.type_id == 193">
                                <img src="/img/icons/yellow_card.png"> {{ item.value.yellowcards }}({{ $t('avg') }}: {{ item.value.average_yellowcards }}), 
                                <img src="/img/icons/red_card.png"> {{ item.value.redcards }}({{ $t('avg') }}: {{ item.value.average_redcards }}), 
                                <img src="/img/icons/red_card.png" style="position: absolute;"><img src="/img/icons/yellow_card.png" style="position: relative; left: -3px"> {{ item.value.yellowredcards }}({{ $t('avg') }}: {{ item.value.average_yellowredcards }})
                              </template>

                              <template v-else-if="item.type_id == 195">
                                {{ item.value.player_name }}, {{ item.value.count }}({{ item.value.percentage }}%)
                              </template>

                              <template v-else-if="item.type_id == 201 || item.type_id == 202 || item.type_id ==203">
                                {{ item.value.all }}%
                              </template>

                              <template v-else-if="item.type_id >= 204 && item.type_id <= 207">
                                {{ item.value.participant_name }}, {{ item.value.count }}{{ item.value.percentage ? `(${item.value.percentage}%)` : '' }}
                              </template>

                              <template v-else-if="item.type_id >= 208 && item.type_id <= 210">
                                {{ item.value.player_name }}, {{ item.value.count }}
                              </template>

                              <template v-else-if="item.type_id == 211">
                                {{ item.value.player_name }}, {{ item.value.rating }}
                              </template>

                              <template v-else-if="item.type_id == 212">
                                {{ item.value.team_name }}, {{ item.value.rating }}
                              </template>

                              <template v-else>
                                {{ item.value.count ? item.value.count : item.value.total }} {{ item.value.percentage ? `(${item.value.percentage}%)` : '' }}
                              </template>
                            </span>
                        </div>
                      </template>

                      <p v-else>{{ $t('message.nostats') }}</p>
                    </div>
                  </div>
                </template>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </div>
      </template>
    </div>
  </v-app>
</template>

<script>

import { Card, Tabs, TabPane } from '@/components'
import axios from 'axios'
import moment from 'moment-timezone'
import array from 'lodash'
import sortBy from 'lodash'

export default {
  components: {
    Card,
    Tabs,
    TabPane,
  },
  data() {
    return {
      tz: localStorage.tz ? localStorage.tz : '+0',
      league: {
          active: null,
          id: '',
          name: '',
          logo: '',
          season_id: '',
          round_id: '',
          stage_id: '',
          is_cup: false,
      },
      country: {
          id: '',
          name: '',
          flag: '',
      },
      seasons: [],
      scorers: null,
      standings: null,
      upcomings: null,
      results: null,
      stats: null,

      seasonForScorers: null,
      seasonForStandings: null,
      seasonForStats: null,
      categoryForScorer: null,
      category: {
        'scorer': [
          {'id': 0, 'name': 'goal'},
          {'id': 1, 'name': 'assist'},
          {'id': 2, 'name': 'yellowcard'},
          {'id': 3, 'name': 'redcard'}]
      },

      expandedStandings: [],
      standingsHeaders: [
        {text: '', align: 'center', value: 'position'},
        {text: 'team', align: 'start', sortable: false, value: 'team'},
        {text: 'mp', align: 'center', sortable: false, value: 'totalPlayed'},
        {text: 'w', align: 'center', sortable: false, value: 'win'},
        {text: 'd', align: 'center', sortable: false, value: 'draw'},
        {text: 'l', align: 'center', sortable: false, value: 'lost'},
        {text: 'goalsfor', align: 'center d-none d-sm-table-cell', sortable: false, value: 'goals'},
        {text: 'gd', align: 'center d-none d-sm-table-cell', sortable: false, value: 'goalDiff'},
        {text: 'Recent', align: 'start', sortable: false, value: 'recent'},
        {text: 'pts', align: 'center', sortable: false, value: 'point'},
      ],
      scorersHeaders: [
        {text: 'player', align: 'start', sortable: false, value: 'player'},
        {text: 'team', align: 'start d-none d-sm-table-cell', value: 'team'},
        {text: '', align: 'center', value: 'total'},
      ],

      basicLoaded: false,
      authorization: false,
      message: '',
      tab: null,
      tabLoaded: false,
      items: ['standings', 'upcomings', 'result', 'topscorer', 'stats'],
      letterColor: {
        'team': 'grey',
        'position': 'grey',
        'tp': 'steelblue',
        'W': 'green',
        'D': 'orange',
        'L': 'crimson',
        'gs': 'green',
        'ga': 'crimson',
        'gd': 'orange',
        'p': 'steelblue',
        'home': 'green',
        'away': 'orange'},
      statusCode: [
        {'short_name': 'NS', 'name': 'Not Started', 'info': 'The initial status of a game'},
        {'short_name': '1st', 'name': '1st Half', 'info': 'The game is currently inplay in the 1st half'},
        {'short_name': 'HT', 'name': 'Half-Time', 'info': 'The game currently is in half-time'},
        {'short_name': 'BRK', 'name': 'Regular time finished', 'info': 'Waiting for penalties to start'},
        {'short_name': 'FT', 'name': 'Full-Time', 'info': 'The game has ended after 90 minutes. When a game goes into extra time, the FT status will be presented for a few seconds and then move to the BREAK status. '},
        {'short_name': '1et', 'name': 'Extra Time', 'info': 'The game currently is in extra time in the 1st half, can happen in knockout games'},
        {'short_name': 'AET', 'name': 'Finished after extra time', 'info': 'The game has finished after 120 minutes'},
        {'short_name': 'FTP', 'name': 'Full-Time after penalties', 'info': 'Finished after penalty shootout'},
        {'short_name': 'PEN', 'name': 'Penalty Shootout', 'info': "ET status didn't get a winner, penalties are taken to determine the winner"},
        {'short_name': 'POST', 'name': 'PostPoned', 'info': 'The game is postponed'},
        {'short_name': 'SUSP', 'name': 'SUSPENDED', 'info': 'The game has suspended and will continue at a later time or day'},
        {'short_name': 'CANC', 'name': 'Cancelled', 'info': 'The game has been cancelled'},
        {'short_name': 'TBA', 'name': 'To Be Announced', 'info': 'The game does not have a confirmed date and time yet. It will be announced later on.'},
        {'short_name': 'WO', 'name': 'Walk Over', 'info': 'Awarding of a victory to a contestant because there are no other contestants'},
        {'short_name': 'ABAN', 'name': 'Abandoned', 'info': 'The game has abandoned and will continue at a later time or day'},
        {'short_name': 'DELA', 'name': 'Delayed', 'info': 'The game is delayed so it will start later'},
        {'short_name': 'AWAR', 'name': 'Awarded', 'info': 'Winner is being decided externally'},
        {'short_name': 'INT', 'name': 'Interrupted', 'info': 'The game has been interrupted. Can be due to bad weather'},
        {'short_name': 'AU', 'name': 'Awaiting Updates', 'info': 'Can occur when there is a connectivity issue or something'},
        {'short_name': 'DEL', 'name': 'Deleted', 'info': 'Game is not available anymore via normal api calls because it has been replaced. This can happen in leagues that have a lot of changes in their schedules. The games can still be retrieved by adding deleted=1 to your request so you can update your system properly'},
        {'short_name': 'ETB', 'name': 'Extra Time - Break', 'info': 'The game has suspended and will continue at a later time or day'},
        {'short_name': '2nd', 'name': '2nd Half', 'info': 'The game is currently inplay in the 2nd half'},
        {'short_name': '2et', 'name': 'ET - 2nd Half', 'info': 'The game currently is in extra time in the 2nd half, can happen in knockout games'},
        {},
        {'short_name': 'PENB', 'name': 'Penalties - Break', 'info': 'Waiting for penalties to start'},
        {'short_name': 'PEN', 'name': 'Pending', 'info': 'The fixture is awaiting an update'}
      ],
    }
  },
  mounted() {
      this.init()
  },
  watch: {
    /*
      events for tab changes
    */
    tab (val) {
      if (val == 0) {
        /* tab for Standings */
        if (this.standings) {
          this.tabLoaded = true
        } else {
          if (!localStorage.token) {
            this.message = "notlogin"
            this.tabLoaded = true
          } else {
            if (this.league.active) {
              this.tabLoaded = false

              // setting default season for standings
              this.seasonForStandings = this.league.season_id
            }
          }
        }
      } else if (val == 1) {
        /* tab for Upcomings */

        if (this.upcomings) {
          this.tabLoaded = true
        } else {
          if (!localStorage.token) {
            this.message = "notlogin"
            this.tabLoaded = true
          } else {
            if (this.league.active) {
              this.tabLoaded = false
              this.getUpcomings()
            }
          }
        }
      } else if (val == 2) {
        /* tab for Results */

        if (this.results) {
          this.tabLoaded = true
        } else {
          if (!localStorage.token) {
            this.message = "notlogin"
            this.tabLoaded = true
          } else {
            if (this.league.active) {
              this.tabLoaded = false
              this.getResult()
            }
          }
        }
      } else if (val == 3) {
        /* tabs for Top Scorers */

        if (this.scorers) {
          this.tabLoaded = true
        } else {
          if (!localStorage.token) {
            this.message = "notlogin"
            this.tabLoaded = true
          } else {
            if (this.league.active) {
              this.tabLoaded = false

              // setting default season for scorers.
              this.seasonForScorers = this.league.season_id

              this.categoryForScorer = 0

              // this.getSeasonScorers()
            }
          }
        }
      } else if (val == 4) {
        /* tabs for Stats */

        if (this.stats) {
          this.tabLoaded = true
        } else {
          if (!localStorage.token) {
            this.message = "notlogin"
            this.tabLoaded = true
          } else {
            if (this.league.active) {
              this.tabLoaded = false

              // setting default season for scorers.
              this.seasonForStats = this.league.season_id
            }
          }
        }
      }
    },
    
    /* event for changes of Season on scorers */
    seasonForScorers (val) {
      this.tabLoaded = false

      if (this.categoryForScorer != null) {
        this.getSeasonScorers()
      }
    },

    /* event for changes of Category on scorers */
    categoryForScorer (val) {
      this.tabLoaded = false
      this.getSeasonScorers()
    },

    /* event for changes of Season on standings */
    seasonForStandings (val) {
      this.tabLoaded = false
      this.results = null
      this.getSeasonStandings(val)
    },

    /* event for changes of Season on stats */
    seasonForStats (val) {
      this.tabLoaded = false
      this.stats = null
      this.getSeasonStats(val)
    },
  },
  beforeCreate: function () {
    axios
      .get('/v3/league/' + this.$route.params.lid,
        { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
      .then(response => {
        if (response.data.type == 0 || response.data.type == 1) {
          this.$router.replace('/message/subscribe/' + (response.data.type ? 'expired' : 'not-paid'))
        }else {
          let data = response.data

          this.league.id = this.$route.params.id
          this.league.name = data.name
          this.league.logo = data.image_path
          // this.league.is_cup = data.is_cup
          this.league.active = data.active

          // this.seasons = data.seasons.sort((a, b) => Number(b.name.substring(0,4)) - Number(a.name.substring(0,4)))
          this.seasons = _.orderBy(data.seasons, ['name'], ['desc'])
          
          this.league.season_id = data.currentseason ? data.currentseason.id : this.seasons[0].id

          this.country.id = data.country_id
          this.country.name = data.country ? data.country.name : ''
          this.country.flag = data.country.image_path ? data.country.image_path : '/img/flag/'+ data.country_id + '.png'
          this.authorization = true
        }
        this.basicLoaded = true
      })
      .catch(error => {
        if (error.response.status == 401) {
          localStorage.removeItem('token')
          localStorage.removeItem('uid')
          location.reload()
        }              
      })
  },
  methods: {
      async init() {
        //Etc/GMT-X will have an offset of +X and Etc/GMT+X will have an offset of -X.
        moment.tz.setDefault('Etc/GMT' + this.tz)
      },

      /*
        method for get upcomings data from api.
      */
      getUpcomings() {
        axios
        .get('/v3/season/upcoming/' + this.league.season_id,
          { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
        .then(response => {
          /* 
            Set upcomings from null to []
            To prevent duplication api calls for unsubscribed user
          */
          this.upcomings = []

          if (response.data.type == 0 || response.data.type == 1) {
            this.$router.replace('/message/subscribe/' + (response.data.type ? 'expired' : 'not-paid'))
          }else {
            // this.upcomings = response.data.data.sort((a, b) => (a.starting_at_timestamp - b.starting_at_timestamp))
            this.upcomings = _.orderBy(response.data.data, ['starting_at_timestamp'])

            // sort participants
            this.sortParticipants(this.upcomings)

            this.authorization = true
          }
          this.tabLoaded = true
        })
        .catch(error => {
          if (error.response.status == 401) {
            localStorage.removeItem('token')
            localStorage.removeItem('uid')
            location.reload()
          }              
        })
      },

      /*
        method for get latest data from api.
      */
      /*
      getLatest() {
        axios
        .get('/v3/league/latest/' + this.$route.params.lid,
          { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
        .then(response => {
           
            // Set results from null to []
            // To prevent duplication api calls for unsubscribed user
          
          this.results = []

          if (response.data.type == 0 || response.data.type == 1) {
            this.$router.replace('/message/subscribe/' + (response.data.type ? 'expired' : 'not-paid'))
          }else {
            // this.results = response.data.data.sort((a, b) => (b.starting_at_timestamp - a.starting_at_timestamp))
            this.results = _.orderBy(response.data.data, ['starting_at_timestamp'], ['desc'])

            // sort participants
            this.sortParticipants(this.results)

            this.authorization = true
          }
          this.tabLoaded = true
        })
        .catch(error => {
          if (error.response.status == 401) {
            localStorage.removeItem('token')
            localStorage.removeItem('uid')
            location.reload()
          }              
        })
      },
      */

      /*
        method for get scorers data from api.
      */
      getSeasonScorers() {
        // set scorerType ids
        let type = ""
        switch (this.categoryForScorer) {
          case 0:
            type = "208"
            break;
          case 1:
            type = "209"
            break;
          case 2:
            type = "84"
            break;
          case 3:
            type = "83"
            break;
          default:
        }

        axios
          .get('/v3/season/topscorer/' + this.seasonForScorers + '/' + type,
            { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
          .then(response => {
            /* 
              Set scorers from null to []
              To prevent duplication api calls for unsubscribed user
            */
            this.scorers = []

            if (response.data.type == 0 || response.data.type == 1) {
              this.$router.replace('/message/subscribe/' + (response.data.type ? 'expired' : 'not-paid'))
            }else {
              this.scorers = response.data.data
              this.authorization = true
            }
            this.tabLoaded = true
          })
          .catch(error => {
            if (error.response.status == 401) {
              localStorage.removeItem('token')
              localStorage.removeItem('uid')
              location.reload()
            }              
          })
      },

      /*
        method for get standings data from api.
      */
      getSeasonStandings(val) {
        axios
          .get('/v3/season/standing/' + val,
            { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
          .then(response => {
            /* 
              Set standings from null to []
              To prevent duplication api calls for unsubscribed user
            */
            this.standings = []

            if (response.data.type == 0 || response.data.type == 1) {
              this.$router.replace('/message/subscribe/' + (response.data.type ? 'expired' : 'not-paid'))
            }else {
              if (response.data.data && response.data.data.length) {
                let arr = this.modifyDetailForm(response.data.data)

                if (arr[0].group_id) {
                  try {
                    let group = function(team){
                      return team.stage.name + ' - ' + team.group.name
                    }

                    let teamToGroup = function(team, group){
                      return {
                        name: group,
                        standings: _.orderBy(team, 'position')
                      }
                    }
                    this.standings = _.chain(arr)
                      .groupBy(group)
                      .map(teamToGroup)
                      .sortBy('name')
                      .value()
                  }
                  catch(err) {
                    this.standings = [{
                      name: null,
                      standings: arr
                    }]
                  }
                } else {
                  this.standings = [{
                    name: null,
                    standings: arr
                  }]
                }
              } else {
                this.standings = []
              }

              this.authorization = true
            }
            this.tabLoaded = true
          })
          .catch(error => {
            if (error.response.status == 401) {
              localStorage.removeItem('token')
              localStorage.removeItem('uid')
              location.reload()
            }
          })
      },

      matchDetail(id) {
        let routeData = this.$router.resolve({name: 'fixture', params: {fid: id}});
        window.open(routeData.href, '_blank');
      },

      upcomingDetail(id, state) {
        if (state == 1 || (state > 9 && state < 21)) {
          let routeData = this.$router.resolve({name: 'soccermatch', query: {mid: id, cid: this.country.id, cn: this.country.name}});
          window.open(routeData.href, '_blank');
        } else {
          let routeData = this.$router.resolve({name: 'fixture', params: {fid: id}})
          window.open(routeData.href, '_blank')
        }
      },

      // sort participants by home/away
      sortParticipants(arr) {
        arr.forEach((item) => {
          if (item.participants[0].meta.location == "away"){
            item.participants = [item.participants[1], item.participants[0]]
          }
        })
      },

      // modify details & form
      modifyDetailForm(arr) {
        arr.forEach((team) => {
          let detail_tmp = {}
          let form_tmp = []

          team.details.forEach((data) => {
            switch (data.type_id) {
              case 129:
                detail_tmp.overall_match_played = data.value
                break;
              case 130:
                detail_tmp.overall_won = data.value
                break;
              case 131:
                detail_tmp.overall_draw = data.value
                break;
              case 132:
                detail_tmp.overall_lost = data.value
                break;
              case 133:
                detail_tmp.overall_goals_for = data.value
                break;
              case 134:
                detail_tmp.overall_goals_against = data.value
                break;
              case 135:
                detail_tmp.home_match_played = data.value
                break;
              case 136:
                detail_tmp.home_won = data.value
                break;
              case 137:
                detail_tmp.home_draw = data.value
                break;
              case 138:
                detail_tmp.home_lost = data.value
                break;
              case 139:
                detail_tmp.home_goals_for = data.value
                break;
              case 140:
                detail_tmp.home_goals_against = data.value
                break;
              case 141:
                detail_tmp.away_match_played = data.value
                break;
              case 142:
                detail_tmp.away_won = data.value
                break;
              case 143:
                detail_tmp.away_draw = data.value
                break;
              case 144:
                detail_tmp.away_lost = data.value
                break;
              case 145:
                detail_tmp.away_goals_for = data.value
                break;
              case 146:
                detail_tmp.away_goals_against = data.value
                break;
              case 176:
                detail_tmp.streak = data.value
                break;
              case 179:
                detail_tmp.goal_diff = data.value
                break;
              case 185:
                detail_tmp.home_point = data.value
                break;
              case 186:
                detail_tmp.away_point = data.value
                break;
              case 187:
                detail_tmp.overall_point = data.value
                break;
              default:
            }
          })

          team.details = detail_tmp

          form_tmp = _.orderBy(team.form, ['sort_order'], ['desc']).slice(0,5)
          team.form = form_tmp
        })

        return arr
      },

      /* get result */
      getResult() {
        axios
        .get('/v3/season/result/' + this.seasonForStandings,
          { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
        .then(response => {
          /* 
            Set results from null to []
            To prevent duplication api calls for unsubscribed user
          */
          this.results = []

          if (response.data.type == 0 || response.data.type == 1) {
            this.$router.replace('/message/subscribe/' + (response.data.type ? 'expired' : 'not-paid'))
          }else {
            let tmp = _.orderBy(response.data.data, ['starting_at_timestamp'], ['desc'])

            // sort participants
            this.sortParticipants(tmp)

            tmp.forEach((fx) => {
              let new_score = {goal: [], penalty: []}
            
              fx.scores.forEach((sc) => {
                if (sc.type_id == 1525) {
                  if (sc.score.participant == "home") {
                    new_score.goal[0] = sc.score.goals
                  } else {
                    new_score.goal[1] = sc.score.goals
                  }
                } else if (sc.type_id == 5) {
                  if (sc.score.participant == "home") {
                    new_score.penalty[0] = sc.score.goals
                  } else {
                    new_score.penalty[1] = sc.score.goals
                  }
                }
              })

              fx.scores = new_score
            })
            
            this.results = tmp

            this.authorization = true
          }
          this.tabLoaded = true
        })
        .catch(error => {
          if (error.response.status == 401) {
            localStorage.removeItem('token')
            localStorage.removeItem('uid')
            location.reload()
          }              
        })
      },

      /*
        method for get stats data from api.
      */
      getSeasonStats(val) {
        axios
          .get('/v3/season/stats/' + val,
            { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
          .then(response => {
            /* 
              Set stats from null to []
              To prevent duplication api calls for unsubscribed user
            */
            this.stats = []

            if (response.data.type == 0 || response.data.type == 1) {
              this.$router.replace('/message/subscribe/' + (response.data.type ? 'expired' : 'not-paid'))
            }else {
              if (response.data.data && response.data.data.length) {
                this.stats = response.data.data
              } else {
                this.stats = []
              }

              this.authorization = true
            }
            this.tabLoaded = true
          })
          .catch(error => {
            if (error.response.status == 401) {
              localStorage.removeItem('token')
              localStorage.removeItem('uid')
              location.reload()
            }              
          })
      },
  }
};
</script>
<style>  
</style>
