<template>
  <v-app>
    <div>
      <template v-if="!basicLoaded">
        <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
      </template>

      <template v-else>
        <div class="justify-center mt-12 py-3">
          <div class="align-center">
            <template v-if="transfers && transfers.length">
              <v-simple-table class="mt-5 table-row-35 green-top-3">
                <thead>
                  <tr class="text-left white grey--text text--darken-1">
                    <td>{{ $t('player') }}</td>
                    <td>{{ $t('from') }}</td>
                    <td>{{ $t('to') }}</td>
                    <td>{{ $t('date') }}</td>
                    <td class="d-none d-sm-table-cell">{{ $t('type') }}</td>
                    <td class="d-none d-sm-table-cell">{{ $t('amount') }}</td>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item, ind) in transfers"
                    :key="ind"
                    class="text-left"
                  >
                    <td>
                      <router-link :to="{name: 'player', params: {pid: item.player_id}}">
                        <img
                          class="wh-30"
                          :src="item.player.image_path"
                          onerror="this.src='/img/default-avatar.png'"
                          :title="item.player.common_name" />
                        
                        <span class="blue-grey--text ml-2">{{ item.player.common_name }}</span>
                      </router-link>
                    </td>

                    <td>
                      <router-link :to="{name: 'team', params: {id: item.from_team_id}}">
                        <img 
                          class="wh-30"
                          :src="item.fromteam.image_path"
                          :title="item.fromteam.name"
                          />
                      </router-link>
                    </td>

                    <td>
                      <router-link :to="{name: 'team', params: {id: item.to_team_id}}">
                        <img 
                          class="wh-30"
                          :src="item.toteam.image_path"
                          :title="item.toteam.name"
                          />
                      </router-link>
                    </td>

                    <td style="white-space:nowrap">
                      <span class="blue-grey--text">{{ item.date }}</span>
                    </td>

                    <td class="d-none d-sm-table-cell" style="white-space:nowrap">
                      <span 
                        v-if="item.type_id != 'n/a'"
                        :class="`${transferColors[item.type_id - 218]}--text`"
                      >
                        {{ $t(`transfer_type.${item.type.developer_name}`) }}
                      </span>
                    </td>

                    <td class="d-none d-sm-table-cell">
                      <span v-if="item.amount" class="font-weight-bold deep-orange--text">{{ parseFloat((item.amount / 1000000).toFixed(2)) }}M</span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>

              <v-btn
                :class="{'d-none': !transfer_has_more}"
                :loading="transfer_loading"
                :disabled="transfer_loading"
                color="success"
                @click="latestTransfer()"
                small
              >
                {{ $t('loadmore') }}
              </v-btn>
            </template>

            <p v-else>{{ $t('message.notransfers') }}</p>
          </div>
        </div>
      </template>
    </div>
  </v-app>
</template>

<script>

// import { Card, Tabs, TabPane } from '@/components'
import axios from 'axios'
import moment from 'moment-timezone'

export default {
  components: {},
 
  data() {
    return {
      tz: localStorage.tz ? localStorage.tz : '+0',
      transfers: [],
      transferColors: ['brown', 'orange', 'green'],
      transfer_has_more: null,
      transfer_loading: true,
      transfer_pg: 1,
      
      basicLoaded: false,
      message: '',
    }
  }, 
  watch: {},
  mounted() {
    this.init()
    
    this.latestTransfer()
  },
  methods: {
      async init() {
        //Etc/GMT-X will have an offset of +X and Etc/GMT+X will have an offset of -X.
        moment.tz.setDefault('Etc/GMT' + this.tz)
      },

      latestTransfer() {
        this.transfer_loading = true

        axios
        .get('/v3/transfer/latest/' + this.transfer_pg,
          { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
        .then(response => {
          if (response.data.type == 0 || response.data.type == 1) {
            this.$router.replace('/message/subscribe/' + (response.data.type ? 'expired' : 'not-paid'))
          }else {
            this.transfers = this.transfers.concat(response.data.transfer)
            this.transfer_pg += 1
            this.transfer_has_more = response.data.has_more
            this.transfer_loading = false          
          }
          this.basicLoaded = true
        })
        .catch(error => {
          if (error.response.status == 401) {
            localStorage.removeItem('token')
            localStorage.removeItem('uid')
            location.reload()
          }
        })
      },
      
      // matchDetail(id) {
      //   let routeData = this.$router.resolve({name: 'fixture', params: {fid: id}});
      //   window.open(routeData.href, '_blank');
      // },

      // upcomingDetail(id) {
      //   let routeData = this.$router.resolve({name: 'soccermatch', query: {mid: id, cid: this.country.id, cn: this.country.name}});
      //   window.open(routeData.href, '_blank');
      // }
  }
};
</script>
<style>  
</style>
