<template>
    <div style="padding: 1rem 3rem; text-align: left;">
        <div class="field">
            <label class="label">{{ $t('to') }} : support@foot-corner.com</label>
        </div>
        <div class="field">
            <label class="label">{{ $t('username') }}</label>
            <div class="control">
                <input :class="['input', ($v.form.username.$error) ? 'is-danger' : '']" type="text" placeholder=""
                       v-model="form.username">
            </div>
            <p v-if="$v.form.username.$error" class="help is-danger">{{ $t('message.invalidusername') }}</p>
        </div>
        <div class="field">
            <label class="label">{{ $t('email') }}</label>
            <div class="control">
                <input :class="['input', ($v.form.demoEmail.$error) ? 'is-danger' : '']"  type="text" placeholder="" v-model="form.demoEmail">
            </div>
            <p v-if="$v.form.demoEmail.$error" class="help is-danger">{{ $t('message.invalidemail') }}</p>
        </div>
        <div class="field">
            <label class="label">{{ $t('message.message') }}</label>
            <div class="control">
                <textarea :class="['textarea', ($v.form.message.$error) ? 'is-danger' : '']"  placeholder="" v-model="form.message"></textarea>
            </div>
        </div>
    </div>
</template>


<script>
    import {validationMixin} from 'vuelidate'
    import {required, email} from 'vuelidate/lib/validators'

    export default {
        props: ['clickedNext', 'currentStep'],
        mixins: [validationMixin],
        data() {
            return {
                form: {
                    username: '',
                    demoEmail: '',
                    message: ''
                }
            }
        },
        validations: {
            form: {
                username: {
                    required
                },
                demoEmail: {
                    required,
                    email
                },
                message: {
                    required
                }
            }
        },
        watch: {
            $v: {
                handler: function (val) {
                    if(!val.$invalid) {
                        this.$emit('can-continue', {value: true});
                    } else {
                        this.$emit('can-continue', {value: false});
                        setTimeout(()=> {
                            this.$emit('change-next', {nextBtnValue: false});
                        }, 3000)
                    }
                },
                deep: true
            },

            clickedNext(val) {
                if(val === true) {
                    this.$v.form.$touch();
                }
            }
        },
        mounted() {
            if(!this.$v.$invalid) {
                this.$emit('can-continue', {value: true});
            } else {
                this.$emit('can-continue', {value: false});
            }
        }
    }
</script>
<style>
    .field {
        margin-bottom: 2vh;
    }

    .field .label {
        color: black;
        font-weight: bold;
    }

    .help {
        color: red;
        font-weight: bold;
        font-size: 14px;
    }

    .control input,textarea {
        width: 50vw;
    }

    @media screen and (max-width: 450px){
        .control input, textarea {
            width: 80vw;
        }
    }
</style>