<template>
  <v-card
    elevation="3"
  >
    <v-app-bar
      color="blue"
      dark
      dense
      flat
      height="35"
    >
      <v-toolbar-title>
        <span
          :class="{'subtitle-2': $vuetify.breakpoint.smAndDown, 'subtitle-1': $vuetify.breakpoint.mdAndUp}">
          {{ $t('compareteam') }}
        </span>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-icon @click="show = !show">
        {{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
      </v-icon>
    </v-app-bar>

    <v-expand-transition>
      <div v-show="!show">
        <v-card-text>
          <div class="d-flex flex-column">
            <v-autocomplete
              v-model="homeTeam"
              :items="homeCandidates"
              item-value="team_id"
              item-text="team_name"
              :search-input.sync="searchHome"
              :placeholder="$t('home')"
              class="subtitle-2"
              density="comfortable"
              hide-no-data
              hide-details
            >
              <template slot="item" slot-scope="{ item }">
                {{ item.team_name }} ({{ item.country_name }})
              </template>
            </v-autocomplete>

            <v-autocomplete
              v-model="awayTeam"
              :items="awayCandidates"
              item-value="team_id"
              item-text="team_name"
              :search-input.sync="searchAway"
              :placeholder="$t('away')"
              class="subtitle-2"
              density="comfortable"
              hide-no-data
              hide-details
            >
              <template slot="item" slot-scope="{ item }">
                {{ item.team_name }} ({{ item.country_name }})
              </template>
            </v-autocomplete>

            <div class="row no-gutters justify-center">
              <v-btn
                color="teal"
                class="mt-1"
                dark
                small
                outlined
                @click="homeTeam && awayTeam && $router.push('/compare/teams/' + homeTeam + '/' + awayTeam)"
              >
                {{ $t('compare') }}
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'compare-teams',
    data() {
      return {
        show: this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm,
        homeTeam: null,
        awayTeam: null,
        homeQueryTerm: '',
        awayQueryTerm: '',
        homeCandidates: [],
        awayCandidates: []
      }
    },
    computed: {
      searchHome: {
        get () {
          return this.homeQueryTerm
        },
        
        set (searchInput) {
          if (this.homeQueryTerm !== searchInput) {
            this.homeQueryTerm = searchInput
            this.loadEntries(1, this.homeQueryTerm)
          }
        }
      },

      searchAway: {
        get () {
          return this.awayQueryTerm
        },
        
        set (searchInput) {
          if (this.awayQueryTerm !== searchInput) {
            this.awayQueryTerm = searchInput
            this.loadEntries(2, this.awayQueryTerm)
          }
        }
      }
    },
    
    methods: {
      async loadEntries (type, queryTerm) {
        if (!queryTerm || queryTerm == '') return []

        // setTimeout(() => {
          axios
            .get('/team/name/' + queryTerm)
            .then(response => {
              if (response.data.length) {
                if (type == 1) {
                  this.homeCandidates = response.data
                } else {
                  this.awayCandidates = response.data
                }
              }
            })
            .catch(error => {})
        // }, 1000)
      },

      closeDropdown() {
        if (this.$refs.autocomplete) {
          this.$refs.autocomplete.isActive = false;
        }
      },
    },
  }
</script>