var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:[
        { 'col-md-4': _vm.vertical && !_vm.tabNavWrapperClasses },
        { 'col-12': _vm.centered && !_vm.tabNavWrapperClasses },
        _vm.tabNavWrapperClasses
      ]},[_c('ul',_vm._b({staticClass:"nav",class:[
          _vm.tabTypeClass,
          { 'nav-pills-icons': _vm.icons },
          { 'nav-pills': _vm.pills },
          { 'nav-tabs': !_vm.pills },
          { 'flex-column': _vm.vertical },
          { 'justify-content-center': _vm.centered },
          _vm.tabNavClasses
        ],attrs:{"role":"tablist"}},'ul',_vm.$attrs,false),_vm._l((_vm.tabs),function(tab){return _c('li',{key:tab.id,staticClass:"nav-item active",attrs:{"data-toggle":"tab","role":"tablist","aria-expanded":"true"}},[_c('a',{staticClass:"nav-link",class:{ active: tab.active, disabled: tab.disabled },attrs:{"data-toggle":"tab","role":"tablist","href":`#${tab.id}`,"aria-expanded":tab.active},on:{"click":function($event){$event.preventDefault();return _vm.activateTab(tab)}}},[_c('tab-item-content',{attrs:{"tab":tab}})],1)])}),0)]),_c('div',{staticClass:"tab-content",class:[
        { 'tab-space': !_vm.vertical && !_vm.noContentSpace },
        'text-left',
        { 'col-md-8': _vm.vertical && !_vm.tabContentClasses },
        _vm.tabContentClasses
      ]},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }