<template>
  <v-app>
    <div class="row no-gutters d-flex justify-center">
      <div class="col-lg-9 col-md-12 mt-12">
        <div>
          <img
            src="/img/banner1.png?random"
            :class="{'d-none': $vuetify.breakpoint.smAndDown}"
            class="banner elevation-3"
            style="border-radius: 1px;"
          />
        </div>

        <div class="row no-gutters d-flex flex-wrap">
          <div class="col-lg-3 col-md-3 mt-3 pl-2 pr-10">
            <router-link
              :to="{name: 'inplay'}"
              class="neons text-decoration-none"
              >
              <span class="d-flex align-center" style="font-size: large;">
                <v-icon class="white--text" style="font-size: small;">
                  {{ 'mdi-play' }}
                </v-icon>
                
                <em>{{ $t('livematch') }}</em>
              </span>
            </router-link>

            <div class="my-2">
              <major-leagues />
            </div>

            <div class="my-2">
              <top-clubs />
            </div>

            <div class="my-2">
              <team />
            </div>

            <div class="my-2">
              <compare-teams />
            </div>

            <div class="mb-2">
              <v-btn
                class="blue white--text elevation-3"
                style="width: 100%; height: 35px;"
                v-on:click="$router.push('/transfer')">
                <span
                  class="d-flex text-capitalize"
                  style="border-radius: 3px;">
                  {{ $t('transfer') }}

                  <svg height="19px" viewBox="0 0 66 43" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="arrow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <path class="one" d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z" fill="#FFFFFF" style="height: 25px;"></path>                 
                    </g>
                  </svg>
                </span>
              </v-btn>
            </div>
          </div>
          
          <div class="col-lg-9 col-md-9 mt-3">
            <div class="d-flex justify-space-between">
              <div
                class="row no-gutters d-flex justify-space-between"
                :class="{'flex-column': $vuetify.breakpoint.xs}">
                <div class="col-md-6 col-sm-6">
                  <v-text-field
                    v-model="searchText"
                    height="30"
                    prepend-icon="mdi-magnify"
                    flat
                    hide-details
                    dense>
                    <template v-slot:label>
                      <span class="font-small">{{ $t('searchinupcoming') }}</span>
                    </template>
                  </v-text-field>
                </div>

                <div class="col-md-5 col-sm-5 d-flex">
                  <v-select
                    v-model="chosenDay"
                    :items="week"
                    :class="{'d-none': $vuetify.breakpoint.smAndUp}"
                    append-icon=""
                    attach
                    chips
                    dense
                    flat
                    prepend-inner-icon="mdi-calendar"
                    hide-details
                    small-chips
                    solo
                    style="max-width: 175px;"
                    @change="$router.push('/matches/' + chosenDay)"
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip>
                        <span class="font-small">{{ item }}, {{ item | moment("ddd") }}</span>
                      </v-chip>
                    </template>

                    <template slot="item" slot-scope="data">
                      <span class="font-small">{{ data.item }}, {{ data.item | moment("ddd") }}</span>
                    </template>
                  </v-select>

                  <v-checkbox
                    v-model="favOnly"
                    class="mr-2 mb-1"
                    color="yellow darken-2"
                    hide-details
                    dense
                    on-icon="mdi-star"
                    off-icon="mdi-star-outline"
                    >
                    <template v-slot:label>
                      <span class="font-small">{{ $t('favoriteleagueonly') }}</span>
                    </template>
                  </v-checkbox>
                </div>
              </div>
              
              <div>
                <v-radio-group
                  v-model="sortValue"
                  :row="$vuetify.breakpoint.mdAndUp"
                >
                  <v-radio :value="1">
                    <template v-slot:label>
                      <span class="font-small">{{ $t('sortbytournament') }}</span>
                    </template>
                  </v-radio>

                  <v-radio :value="0">
                    <template v-slot:label>
                      <span class="font-small">{{ $t('sortbytime') }}</span>
                    </template>
                  </v-radio>
                </v-radio-group>
                
                <!-- <v-switch
                  v-model="sortValue"
                  color="blue"
                  hide-details
                  dense>
                  <template v-slot:label>
                    <span class="font-small">{{ sortValue ? `${ $t('sortbytournament') }` : `${ $t('sortbytime') }` }}</span>
                  </template>
                </v-switch> -->
              </div>
            </div>

            <div
              :class="{'d-none': $vuetify.breakpoint.xs, 'd-flex': $vuetify.breakpoint.smAndUp}"
              class="justify-center mb-1">
              <router-link 
                v-for="(day, ind) in week"
                :key="ind"
                :to="{ path: '/matches/' + day }"
                >
                <span
                  :class="((!tab && ind == 0) || (tab == day)) ? 'primary white--text' : 'black--text'"
                  class="body-2 px-3 py-1"
                  style="border-radius: 5px 5px 0 0; box-shadow: 1px 0px 1px 0px grey;">
                  {{ day | moment("DD/MM") }}
                </span>
              </router-link>
            </div>

            <router-view :sortValue="sortValue" :searchText="searchText" :favOnly="favOnly" />
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import { Card, MajorLeagues, TopClubs, CompareTeams, Team } from '@/components'
import moment from 'moment-timezone'
import Cookies from 'js-cookie'
import axios from 'axios'
import config from '@/config'

axios.defaults.baseURL = config.API_URL;
export default {
  name: 'upcoming',
  components: {
    Card,
    MajorLeagues,
    CompareTeams,
    Team,
    TopClubs
  },
  data() {
    return {
      tz: localStorage.tz ? localStorage.tz : '+0',
      searchText: '',
      sortValue: 1,
      week: [],
      chosenDay: this.$route.params.day ? this.$route.params.day : moment().format('YYYY-MM-DD'),
      tab: this.$route.params.day ? this.$route.params.day : null,
      favOnly: false,
    }
  },
  created() {
    const referralCode = this.$route.query.ref; // Extract referral code from URL parameter

    if (referralCode) {
      const expirationDate = new Date()
      expirationDate.setDate(expirationDate.getDate() + 30) // Set expiration date to 30 days from now
      Cookies.set('referralCode', referralCode, { expires: expirationDate }) // Set cookie with referral code and expiration date

      // create a click on tapfiliate
      axios
          .post('/tapfiliate/clicks/', {'referral_code': referralCode})
          .then((response) => {
            Cookies.set('clickId', response.data.id, { expires: expirationDate })
          })
          .catch((err) => {})
    }
  },
  mounted() {
    this.getDates()
  },
  methods: {
    getDates () {
      //Etc/GMT-X will have an offset of +X and Etc/GMT+X will have an offset of -X.
      moment.tz.setDefault('Etc/GMT' + this.tz)
      
      let day = moment().format('YYYY-MM-DD')
      this.week = []
      for (let i = 1; i < 8; i++){
        this.week.push(day)
        day = moment().add(i, 'days').format('YYYY-MM-DD')
      }

    },
  }
};
</script>

<style>
  @import '../assets/footcorner/upcoming.css';
</style>