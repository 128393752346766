<template>
  <!-- <v-app> -->
    <div v-if="!isLoaded" class='ripple_loading' ><img src='/img/icons/Ripple-1s-200px.png'></div>

    <div v-else>
      <div v-if="!sortValue">
        <template v-for="group in byTime">
          <v-list-group
            v-if="!favOnly || !group.fav_star"
            :key="group.time + group.league_id"
            class="grey lighten-2 mb-2"
            value=1
          >
            <template v-slot:activator>
              <img class='elevation-2 mr-2' :src="'/img/flag/'+ group.games[0].league_country.code + '.png'" onerror="this.src='/img/flag/-.png'" />

              <v-list-item-title
                class="d-flex justify-space-between grey--text text--darken-2 white-space-prewrap"
                :class="{'caption': $vuetify.breakpoint.xs, 'body-2': $vuetify.breakpoint.smAndUp}">
                <div class="d-flex align-center">
                  <span :class="{'d-none': $vuetify.breakpoint.xs, '': $vuetify.breakpoint.smAndUp}">
                    {{ group.country }}, 
                  </span>

                  <router-link :to="{name: 'league', params: {lid: group.league_id}}" class="ml-2 black--text">
                    {{ group.league }}
                  </router-link>

                  <v-icon
                    :color="group.fav_star ? 'grey lighten-1' : 'yellow darken-2'"
                    class="ml-1"
                    v-on:click.native.stop="update_favorite(group.fav_star, group.league_id)">
                    {{ group.fav_star ? 'mdi-star-outline' : 'mdi-star' }}
                  </v-icon>
                </div>
                
                <div :class="{'d-none': $vuetify.breakpoint.xs, 'd-flex': $vuetify.breakpoint.smAndUp}" class="float-right">
                  <div class="d-flex pr-7"
                    style="line-height: 30px; width: 145px;">
                    <span class="pr-9">1</span>
                    <span class="pr-9">X</span>
                    <span class="pr-4">2</span>
                  </div>

                  <div class="d-flex align-center pl-3 pr-2" style="min-width: 55px">
                    <span>{{ $t('weather') }}</span>
                  </div>
                </div>
              </v-list-item-title>
            </template>

            <v-list-item
              v-for="game in group.games"
              :key="game.time"
              class="white"
              dense
            >
              <v-list-item-title
                class="my-1"
                :class="{'caption': $vuetify.breakpoint.xs, 'body-2': $vuetify.breakpoint.smAndUp}">
                <router-link
                  :to="{name: 'soccermatch', query: {
                    mid: game.match_id,
                    cid: game.league_country.code,
                    cn: game.league_country.name
                    }}"
                  :class="{' height-30 height-md-40': $vuetify.breakpoint.smAndUp}"
                  class="d-flex align-center justify-space-between black--text cursor-bg-beige"
                >
                  <span v-if="game.state_id" class="d-flex mr-3 red--text font-weight-bold">
                    <img class="align-self-center mr-1" src="/img/icons/live.gif">LIVE
                  </span>

                  <span v-else class="ml-1 mr-2">{{ game.start_dt | moment('HH:mm') }}</span>

                  <div
                    :class="{'flex-column': $vuetify.breakpoint.smAndDown}"
                    class="d-flex justify-center width-100">  
                    <div
                      :class="{'justify-end align-center': $vuetify.breakpoint.mdAndUp}"
                      class="d-flex flex-basis-50">
                      <div class="d-flex align-center" :class="{'flex-row-reverse text-end': $vuetify.breakpoint.mdAndUp}">
                        <div :style="game.local_form || $vuetify.breakpoint.xs ? '' : 'width: 37px'">
                          <span
                            class="white--text p-1 font-weight-bold"
                            :class="game.local_form_color"
                            style="border-radius: 3px;"
                            :title="`${$t('home')} ${$t('form')}`">
                            {{ game.local_form }}
                          </span>
                        </div>

                        <img class="mx-2 wh-20" :src="game.local_logo ? game.local_logo : 'https://cdn.sportmonks.com/images/soccer/team_placeholder.png'" />

                        <div class="white-space-prewrap">
                          <span>{{ game.local_name }}</span>
                        </div>

                        <span
                          v-if="game.local_coach_changed && game.local_coach_changed <= days_coach_changed"
                          class="text-center font-weight-bold red white--text mx-1 px-1"
                          :class="{'caption': $vuetify.breakpoint.xs}"
                          style="line-height: 15px; border-radius: 50%;"
                          :title="`${$t('changedcoach')} ${game.local_coach_changed} ${$t('daysago')}`">
                          c
                        </span>
                      </div>
                    </div>

                    <div :class="{'d-flex align-center': $vuetify.breakpoint.mdAndUp, 'd-none': $vuetify.breakpoint.smAndDown}"><span class="mx-2"> - </span></div>
                      
                    <div class="d-flex flex-basis-50">
                      <div class="d-flex align-center">
                        <div :style="game.visitor_form || $vuetify.breakpoint.xs ? '' : 'width: 37px'">
                          <span
                            class="white--text p-1 font-weight-bold"
                            :class="game.visitor_form_color"
                            style="border-radius: 3px;"
                            :title="`${$t('away')} ${$t('form')}`">
                            {{ game.visitor_form }}
                          </span>
                        </div>

                        <img class="mx-2 wh-20" :src="game.visitor_logo ? game.visitor_logo : 'https://cdn.sportmonks.com/images/soccer/team_placeholder.png'" />

                        <div class="white-space-prewrap">
                          <span>{{ game.visitor_name }}</span>
                        </div>

                        <span
                          v-if="game.visitor_coach_changed && game.visitor_coach_changed <= days_coach_changed"
                          class="text-center font-weight-bold red white--text mx-1 px-1"
                          :class="{'caption': $vuetify.breakpoint.xs}"
                          style="line-height: 15px; border-radius: 50%;"
                          :title="`${$t('changedcoach')} ${game.visitor_coach_changed} ${$t('daysago')}`">
                          c
                        </span>
                      </div>
                    </div>
                  </div>
                  
                  <div class="d-flex align-center float-right pr-3">
                    <div
                      class="text-end"
                      :class="{'pr-2': $vuetify.breakpoint.xs, 'pr-8': $vuetify.breakpoint.smAndUp}"
                      style="width: 155px;">
                      <template v-if="game.odds">
                        <span
                          v-for="(value, ind) in game.odds"
                          :key="ind"
                          :class="{'pl-2': $vuetify.breakpoint.xs, 'pl-3': $vuetify.breakpoint.smAndUp}">
                          {{ value[odds_format] }}
                        </span>
                      </template>
                    </div>

                    <div :class="{'d-none': $vuetify.breakpoint.xs, 'd-flex justify-center align-center': $vuetify.breakpoint.smAndUp}" style="width: 100px;">
                      <img
                        v-if="game.weather_icon"
                        :src="game.weather_icon"
                        style="width: 35px;"
                        :title="$t(`weather_description.${game.weather_text}`)" />

                      <div v-if="game.weather_temp">
                        <span>{{ Math.floor(game.weather_temp) }}</span>

                        <v-icon>mdi-temperature-celsius</v-icon>
                      </div>
                    </div>
                  </div>
                </router-link>
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
        </template>
      </div>

      <div v-else>
        <template v-for="group in byCountryLeague">
          <v-list-group
            v-if="!favOnly || !group.fav_star"
            :key="group.league_id"
            :value="group.expanded ? 1 : 0"
            v-on:click="update_expand(group.league_id)"
            class="grey lighten-2 mb-1"
          >
            <template v-slot:activator>
              <img class="elevation-2 mr-2" :src="'/img/flag/'+ group.games[0].league_country.code + '.png'" onerror="this.src='/img/flag/-.png'" />

              <v-list-item-title
                class="d-flex justify-space-between grey--text text--darken-2 white-space-prewrap"
                :class="{'caption': $vuetify.breakpoint.xs, 'body-2': $vuetify.breakpoint.smAndUp}">
                <div class="d-flex align-center">
                  <span :class="{'d-none': $vuetify.breakpoint.xs}">
                    {{ group.country }}, 
                  </span>

                  <router-link :to="{name: 'league', params: {lid: group.league_id}}" class="black--text">
                    {{ group.league }}
                  </router-link>

                  <v-icon
                    :color="group.fav_star ? 'grey lighten-1' : 'yellow darken-2'"
                    class="ml-1"
                    v-on:click.native.stop="update_favorite(group.fav_star, group.league_id)">
                    {{ group.fav_star ? 'mdi-star-outline' : 'mdi-star' }}
                  </v-icon>
                </div>

                <div :class="{'d-none': $vuetify.breakpoint.xs, 'd-flex': $vuetify.breakpoint.smAndUp}" class="float-right">
                  <div
                    class="d-flex pr-7"
                    style="line-height: 30px; width: 145px;">
                    <span class="pr-9">1</span>
                    <span class="pr-9">X</span>
                    <span class="pr-4">2</span>
                  </div>

                  <div class="d-flex align-center pl-3 pr-2" style="min-width: 55px">
                    <span>{{ $t('weather') }}</span>
                  </div>
                </div>
              </v-list-item-title>
            </template>

            <v-list-item
              v-for="game in group.games"
              :key="game.match_id"
              class="white"
              dense
            >
              <v-list-item-title
                class="my-1"
                :class="{'caption': $vuetify.breakpoint.xs, 'body-2': $vuetify.breakpoint.smAndUp}">
                <router-link
                  :to="{name: 'soccermatch', query: {
                    mid: game.match_id,
                    cid: game.league_country.code,
                    cn: game.league_country.name
                    }}"
                  :class="{' height-30 height-md-40': $vuetify.breakpoint.smAndUp}"
                  class="d-flex align-center justify-space-between black--text cursor-bg-beige"
                >
                  <span v-if="game.state_id" class="d-flex mr-3 red--text font-weight-bold">
                    <img class="align-self-center mr-1" src="/img/icons/live.gif">LIVE
                  </span>
                  
                  <span v-else class="ml-1 mr-2">{{ game.start_dt | moment('HH:mm') }}</span>
                  
                  <div
                    :class="{'flex-column': $vuetify.breakpoint.smAndDown}"
                    class="d-flex justify-center width-100">
                  
                    <div
                      :class="{'justify-end align-center': $vuetify.breakpoint.mdAndUp}"
                      class="d-flex flex-basis-50">
                      <div class="d-flex align-center" :class="{'flex-row-reverse text-end': $vuetify.breakpoint.mdAndUp}">
                        <div :style="game.local_form || $vuetify.breakpoint.xs ? '' : 'width: 37px'">
                          <span
                            class="white--text p-1 font-weight-bold"
                            :class="game.local_form_color"
                            style="border-radius: 3px;"
                            :title="`${$t('home')} ${$t('form')}`">
                            {{ game.local_form }}
                          </span>
                        </div>

                        <img class="mx-2 wh-20" :src="game.local_logo ? game.local_logo : 'https://cdn.sportmonks.com/images/soccer/team_placeholder.png'" />

                        <div class="white-space-prewrap">
                          <span>{{ game.local_name }}</span>
                        </div>

                        <span
                          v-if="game.local_coach_changed && game.local_coach_changed <= days_coach_changed"
                          class="text-center font-weight-bold red white--text mx-1 px-1"
                          :class="{'caption': $vuetify.breakpoint.xs}"
                          style="line-height: 15px; border-radius: 50%;"
                          :title="`${$t('changedcoach')} ${game.local_coach_changed} ${$t('daysago')}`">
                          c
                        </span>
                      </div>
                    </div>

                    <div :class="{'d-flex align-center': $vuetify.breakpoint.mdAndUp, 'd-none': $vuetify.breakpoint.smAndDown}"><span class="mx-2"> - </span></div>
                    
                    <div class="d-flex flex-basis-50">
                      <div class="d-flex align-center">
                        <div :style="game.visitor_form || $vuetify.breakpoint.xs ? '' : 'width: 37px'">
                          <span
                            class="white--text p-1 font-weight-bold"
                            :class="game.visitor_form_color"
                            style="border-radius: 3px;"
                            :title="`${$t('away')} ${$t('form')}`">
                            {{ game.visitor_form }}
                          </span>
                        </div>

                        <img class="mx-2 wh-20" :src="game.visitor_logo ? game.visitor_logo : 'https://cdn.sportmonks.com/images/soccer/team_placeholder.png'" />

                        <div class="white-space-prewrap">
                          <span>{{ game.visitor_name }}</span>
                        </div>
                      
                        <span
                          v-if="game.visitor_coach_changed && game.visitor_coach_changed <= days_coach_changed"
                          class="text-center font-weight-bold red white--text mx-1 px-1"
                          :class="{'caption': $vuetify.breakpoint.xs}"
                          style="line-height: 15px; border-radius: 50%;"
                          :title="`${$t('changedcoach')} ${game.visitor_coach_changed} ${$t('daysago')}`">
                          c
                        </span>
                      </div>
                    </div>
                  </div>
                
                  <div class="d-flex align-center float-right pr-3">
                    <div
                      class="text-end"
                      :class="{'pr-2': $vuetify.breakpoint.xs, 'pr-8': $vuetify.breakpoint.smAndUp}"
                      style="width: 155px;">
                      <template v-if="game.odds">
                        <span
                          v-for="(value, ind) in game.odds"
                          :key="ind"
                          :class="{'pl-2': $vuetify.breakpoint.xs, 'pl-3': $vuetify.breakpoint.smAndUp}">
                          {{ value[odds_format] }}
                        </span>
                      </template>
                    </div>

                    <div :class="{'d-none': $vuetify.breakpoint.xs, 'd-flex justify-center align-center': $vuetify.breakpoint.smAndUp}" style="width: 100px;">
                      <img
                        v-if="game.weather_icon"
                        :src="game.weather_icon"
                        style="width: 35px;"
                        :title="$t(`weather_description.${game.weather_text}`)" />

                      <div v-if="game.weather_temp">
                        <span>{{ Math.floor(game.weather_temp) }}</span>

                        <v-icon>mdi-temperature-celsius</v-icon>
                      </div>
                    </div>
                  </div>
                </router-link>
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
        </template>
      </div>
    </div>
  <!-- </v-app> -->
</template>
<script>
import axios from 'axios'
import moment from 'moment-timezone'
import array from 'lodash'
import sortBy from 'lodash'
import config from '@/config'

axios.defaults.baseURL = config.API_URL
export default {
  name: 'upcoming-matches',
  props: [
      'searchText',
      'sortValue',
      'favOnly',
    ],
  components: {},
  computed: {
    byTime() {
      let sch = this.searchText
      let allGames = []
      let regex = RegExp(sch, 'i')

      if (sch == '') allGames = this.matches
      else {
        this.matches.forEach((evt) => {
          if (regex.test(evt.local_name) || regex.test(evt.visitor_name)
              || regex.test(evt.league_name) || regex.test(evt.league_country.name)) {
                allGames.push(evt)
          }
        })
      }

      /* grouping Label : startHour + league */
      let lg = this.favorite_league
      let label = function(game){
          return moment(game['start_dt']).format("HH:mm") + game['league_country']['name'] + ', ' + game['league_name']
      }

      let grouping = function(group, label){
          return {
              time: label.substring(0, 5),
              // league: label.substring(5),
              country: group[0]['league_country']['name'],
              fav_star: (lg && lg.includes(group[0]['league_id'])) ? 0 : 1,
              league: group[0]['league_name'],
              league_id: group[0]['league_id'],
              games: _.orderBy(group, ['start_dt', 'league_country.code'])
          }
      }
      
      let result = _.chain(allGames)
          .groupBy(label)
          .map(grouping)
          .sortBy('fav_star', 'time', 'league')
          .value()

      return result
    },
    byCountryLeague() {
        let sch = this.searchText
        let allGames = []
        let regex = RegExp(sch, 'i')

        if (sch == '') allGames = this.matches
        else {
          this.matches.forEach((evt) => {
            if (regex.test(evt.local_name) || regex.test(evt.visitor_name)
                || regex.test(evt.league_name) || regex.test(evt.league_country.name)) {
                  allGames.push(evt)
            }
          })
        }

        let league = function(game){
            return game['league_country']['name'] + ', ' + game['league_name']
        }

        let lg = this.favorite_league
        let exp = this.expanding_league
        let groupToLeague = function(group, league){
            return {
                // league: league,
                country: group[0]['league_country']['name'],
                league: group[0]['league_name'],
                league_id: group[0]['league_id'],
                fav_star: (lg && lg.includes(group[0]['league_id'])) ? 0 : 1,
                expanded: (exp && exp.includes(group[0]['league_id'])) ? 1 : 0,
                games: _.orderBy(group, 'start_dt')
            }
        }

        let result = _.chain(allGames)
            .groupBy(league)
            .map(groupToLeague)
            .sortBy('fav_star', 'country')
            .value()
            
        return result    
    }
  },
  data() {
    return {
      tz: localStorage.tz ? localStorage.tz : '+0',
      favorite_league: localStorage.favorite_league ? JSON.parse(localStorage.favorite_league) : [],
      expanding_league: localStorage.expanding_league ? JSON.parse(localStorage.expanding_league) : [],
      odds_format: localStorage.odds_format ? localStorage.odds_format : 0,
      days_coach_changed: localStorage.days_coach_changed ? localStorage.days_coach_changed : 20,
      matches: [],
      isLoaded: false,
      count_form: localStorage.count_form ? localStorage.count_form : 5,
      type_form: localStorage.type_form ? Number(localStorage.type_form) : 0,       // 0: home/away, 1: overall
      league_form: localStorage.league_form ? Number(localStorage.league_form) : 0, // 0: all leagues, 1: league to play
    }
  },
  created: function() {
    this.isLoaded = false

    //Etc/GMT-X will have an offset of +X and Etc/GMT+X will have an offset of -X.
    moment.tz.setDefault('Etc/GMT' + this.tz)
    
    let dt = this.$route.params.day ? this.$route.params.day : moment().format('YYYY-MM-DD')
    let from, to
    // check current time due to timezone
    if (dt == moment().format('YYYY-MM-DD')) {
      from = moment().utc().format('YYYY-MM-DD HH:mm')
      to = moment(dt).add('days', 1).utc().format('YYYY-MM-DD HH:mm')
    } else {
      from = moment(dt).utc().format('YYYY-MM-DD HH:mm')
      to = moment(from).add('days', 1).format('YYYY-MM-DD HH:mm')
    }
    axios
    .get('/upcoming/'+from+'/'+to,
      { params: {
        league_form: this.league_form,
        type_form: this.type_form,
        count_form: this.count_form }})
    .then(response => {
      let list = response.data

      //regularization matches due to none fields such as league_country
      list.forEach((game) => {
        game.local_form = game.local_form ? parseFloat(game.local_form).toFixed(2) : null
        game.visitor_form = game.visitor_form ? parseFloat(game.visitor_form).toFixed(2) : null
        // set color form of team
        game.local_form_color = this.get_form_color(game.local_form)
        game.visitor_form_color = this.get_form_color(game.visitor_form)

        if (!game.league_country) {
          game.league_country = {
            'code': '-',
            'name': 'Unknown'
          }
        }

        // change weather text to code
        if (game.weather_text) {
          let text = game.weather_text
          game.weather_text = text.replace(/ /g, '_')
        }
      })

      this.matches = list
      this.isLoaded = true
    })
    .catch(error => {})
  },

  methods: {
      /* event for pick favorite star on leagues */
      
      update_favorite(f, lg_id) {
        if (localStorage.token) {
          axios
              .post('/pick_favorite/', 
                {'league_id': lg_id, 'flag': f },
                { headers: { 'Authorization': 'Token ' + localStorage.token }}
                )
              .then((response) => {
                  localStorage.setItem('favorite_league', JSON.stringify(response.data.leagues))
                  this.favorite_league = response.data.leagues
              })
              .catch(error => {
                if (error.response.status == 401) {
                  localStorage.removeItem('token')
                  localStorage.removeItem('uid')
                  location.reload()
                }
              })
        } else {
          let arr = JSON.parse(localStorage.favorite_league || "[]")
          let ind = arr.indexOf(lg_id)

          if (ind < 0) {
            arr.push(lg_id)
          } else {
            arr.splice(ind, 1)
          }

          localStorage.setItem("favorite_league", JSON.stringify(arr))
          this.favorite_league = arr
        }
      },
      
      /* event for expand/collapse the grouping list on leagues */
      
      update_expand(lg_id) {
        let arr = JSON.parse(localStorage.expanding_league || "[]")
        let ind = arr.indexOf(lg_id)

        if (ind < 0) {
          arr.push(lg_id)
        } else {
          arr.splice(ind, 1)
        }

        localStorage.setItem("expanding_league", JSON.stringify(arr))
      },
      
      /* function get color for strength */

      get_form_color(val) {
        if (!val) {
          return ''
        } else if (val < 1) {
          return 'red lighten-2'
        } else if (val < 1.5) {
          return 'yellow darken-3'
        } else if (val < 2) {
          return 'green lighten-1'
        } else {
          return 'green darken-2'
        }
      }
  }
};
</script>

<style>
</style>