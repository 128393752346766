<template>
  <v-card
    elevation="3"
  >
    <v-app-bar
      color="blue"
      dark
      dense
      flat
      height="35"
    >
      <v-toolbar-title>
        <span
          :class="{'subtitle-2': $vuetify.breakpoint.smAndDown, 'subtitle-1': $vuetify.breakpoint.mdAndUp}">
          {{ $t('team') }}
        </span>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-icon @click="show = !show">
        {{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
      </v-icon>
    </v-app-bar>

    <v-expand-transition>
      <div v-show="!show">
        <v-card-text>
          <div class="d-flex flex-column">
            <v-autocomplete
              v-model="team"
              :items="teamCandidates"
              item-value="team_id"
              item-text="team_name"
              :search-input.sync="searchTeam"
              :placeholder="$t('enterteam')"
              class="subtitle-2"
              density="comfortable"
              hide-no-data
              hide-details
            >
              <template slot="item" slot-scope="{ item }">
                {{ item.team_name }} ({{ item.country_name }})
              </template>
            </v-autocomplete>
          </div>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'team',
    data() {
      return {
        show: this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm,
        team: null,
        teamQueryTerm: '',
        teamCandidates: [],
      }
    },
    computed: {
      searchTeam: {
        get () {
          return this.teamQueryTerm
        },
        
        set (searchInput) {
          if (this.teamQueryTerm !== searchInput) {
            this.teamQueryTerm = searchInput
            this.loadEntries(this.teamQueryTerm)
          }
        }
      },
    },

    watch: {
      team (val) {
        let routeData = this.$router.resolve({name: 'team', params: {id: val}})
        window.open(routeData.href, '_blank')
      }
    },
    
    methods: {
      async loadEntries (queryTerm) {
        if (!queryTerm || queryTerm == '') return []
          axios
            .get('/team/name/' + queryTerm)
            .then(response => {
              if (response.data.length) {
                this.teamCandidates = response.data
              }
            })
            .catch(error => {})
      },

      closeDropdown() {
        if (this.$refs.autocomplete) {
          this.$refs.autocomplete.isActive = false;
        }
      },
    },
  }
</script>