<template>
  <v-app>
    <v-alert
      type="warning"
      prominent
      style="padding-top: 80px"
    >
      {{ $t(`message.${message[this.$route.params.type]}`) }}
    </v-alert>
  </v-app>
</template>

<script>
export default {
  name: 'subscribe-message',
  data() {
    return {
        message: {
          'not-paid': 'notsubscribe',
          'expired': 'expiredsubscribe'
        }
    }
  }
}
</script>