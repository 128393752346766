<template>
  <v-app>
    <div class="page-header clear-filter">
      <div
        class="page-header-image"
        style="background-image: url('img/corner-ball.jpg')"
      ></div>
      
      <div class="content login-form">
        <div class="container">
            <div class="col-lg-6 col-md-10 ml-auto mr-auto">
              <card v-if="!page" type="login" plain>
                <div slot="header">
                  <span class="h4 white--text">{{ $t('resetpassword') }}</span>
                </div>

                <span class="white--text">{{ $t('message.enteremail') }}</span>

                <fg-input
                  class="no-border input-lg login-input"
                  addon-left-icon="now-ui-icons ui-1_email-85"
                  :placeholder="$t('email')"
                  v-model="email"
                  v-on:keydown="clearError"
                  @keyup.enter.native="send_link"
                >
                </fg-input>

                <span v-show="message" class="error-msg">{{ $t(message) }}</span>

                <template slot="raw-content">
                  <div class="card-footer text-center">
                    <button
                      class="btn btn-primary btn-round btn-lg btn-block"
                      v-on:click="send_link"
                      :disabled="disable_send"
                      >{{ $t('send') }}</button>

                    <v-btn
                      text
                      style="color: white"
                      @click="$router.go('-1')"
                    >
                      <v-icon>
                        mdi-arrow-left
                      </v-icon>
                      {{ $t('back') }}
                    </v-btn>
                  </div>
                </template>
              </card>

              <card v-else type="login" plain>
                <div slot="header">
                  <span class="h1 white--text">
                    <i class="now-ui-icons ui-1_check"></i>
                  </span>
                </div>

                <span class="white--text">{{ $t('message.sentresetpasswordlink') }}</span>

                <template slot="raw-content">
                  <div class="card-footer text-center">
                    <v-btn
                      text
                      style="color: white"
                      @click="$router.go('-1')"
                    >
                      <v-icon>
                        mdi-arrow-left
                      </v-icon>
                      {{ $t('back') }}
                    </v-btn>
                  </div>
                </template>
              </card>
            </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import { Card, Button, FormGroupInput } from '@/components'
import MainFooter from '@/layout/MainFooter'
import axios from 'axios'
import config from '@/config'

axios.defaults.baseURL = config.API_URL;
export default {
  name: 'forgot-password',
  bodyClass: 'login-page',
  components: {
    Card,
    MainFooter,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      email: null,
      message: null,
      page: 0,
      disable_send: true,
    }
  },
  beforeCreate: function () {
    if (localStorage.token) {
      this.$router.replace('/')
    }
  },
  methods: {
    clearError : function () {
      this.message = null
    },

    send_link () {
      axios
        .post('/account/reset-password/send-link/', {'email': this.email})
        .then((response) => {
          if (response.data.status == 1) {
            this.message = 'message.invalidemail'
          } else if (response.data.status == 2) {
            this.message = 'message.errorsendingemail'
          } else {
            this.page = 1
          }
        })
    },

    valid_email() {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
        this.message = null;
        this.disable_send = false;
      } else {
        this.message = 'message.validemail';
        this.disable_send = true;
      } 
    },
  },
  watch: {
    email: function(value) {      
      this.valid_email();
    },
  }
};
</script>
<style>
  .card-header {
    margin-bottom: 30px !important;
  }

  .header {
    color: white;
    font-size: 25px;
    font-weight: bold;
  }
  
  .page-header .login-form {
    margin-top: 20vh;
  }

  .login-input input {
    font-weight: bold;
  }

  .error-msg {
    color: orange;
    font-weight: bold;
  }

  a.link:hover, a.link:focus {
    color: lightgrey !important;
  }

  @media (max-width: 450px) {
    .page-header .login-form {
      margin-top: 15vh;
    }
  }
</style>