<template>
  <v-app>
    <v-dialog
      v-model="subCornerDialog"
      width="fit-content"
      style="z-index: 2000">
      <v-simple-table
        class="table-row-35">
        <thead>
          <tr>
            <!-- <th class="text-center">{{ subCorner.dt }}</th> -->
            <th class="text-center">{{ subCorner.home }}</th>
            <th class="text-center">{{ subCorner.away }}</th>
          </tr>
        </thead>

        <tbody class="text-center">
          <tr
            v-for="(Evt, index) in subCorner.data"
            :key="index">
            <template v-if="Evt.comment != 'X'">
              <!-- <td>{{ Evt.comment }}</td> -->
              <td>{{ Evt.h_m }}</td>
              <td>{{ Evt.a_m }}</td>
            </template>
          </tr>
        </tbody>
      </v-simple-table>
    </v-dialog>

    <v-dialog
      v-model="subGoalDialog"
      width="fit-content"
      style="z-index: 2000">
      <v-simple-table
        class="table-row-35">
        <thead>
          <tr>
            <th class="text-center">{{ subGoal.dt }}</th>
            <th class="text-center">{{ $t('player') }}</th>
            <th class="text-center">{{ subGoal.home }}</th>
            <th class="text-center">{{ subGoal.away }}</th>
          </tr>
        </thead>

        <tbody class="text-center">
          <tr v-for="(Evt, index) in subGoal.data" :key="index">
            <td>{{ Evt.minute }}</td>

            <td>
              <router-link :to="{name: 'player', params: {pid: Evt.player_id}}" class="blue-grey--text">
                {{ Evt.player }}
              </router-link>
            </td>

            <td>
              <img v-if="Evt.h_g=='penalty'" src="/img/icons/penalty.png" class="h-20" :title="$t('penalty')">
              <img v-else-if="Evt.h_g=='goal' || Evt.h_g=='own-goal'" src="/img/icons/goal.png" class="h-20">
            </td>
            
            <td>
              <img v-if="Evt.a_g=='penalty'" src="/img/icons/penalty.png" class="h-20" :title="$t('penalty')">
              <img v-else-if="Evt.a_g=='goal' || Evt.a_g=='own-goal'" src="/img/icons/goal.png" class="h-20">
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-dialog>

    <v-dialog
      v-model="subCardDialog"
      width="fit-content"
      style="z-index: 2000">
      <v-simple-table
        class="table-row-35">
        <thead>
          <tr>
            <th class="text-center">{{ subCard.dt }}</th>
            <th class="text-center">{{ $t('player') }}</th>
            <th class="text-center">{{ subCard.home }}</th>
            <th class="text-center">{{ subCard.away }}</th>
          </tr>
        </thead>

        <tbody class="text-center">
          <tr v-for="(Evt, index) in subCard.data" :key="index">
            <td>{{ Evt.minute }}</td>

            <td>
              <router-link :to="{name: 'player', params: {pid: Evt.player_id}}" class="blue-grey--text">
                {{ Evt.player }}
              </router-link>
            </td>

            <td>
              <img v-if="Evt.h_c=='yellowcard'" src="/img/icons/yellow_card.png">
              <img v-else-if="Evt.h_c=='yellowred' || Evt.h_c=='redcard'" src="/img/icons/red_card.png">
            </td>

            <td>
              <img v-if="Evt.a_c=='yellowcard'" src="/img/icons/yellow_card.png">
              <img v-else-if="Evt.a_c=='yellowred' || Evt.a_c=='redcard'" src="/img/icons/red_card.png">
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-dialog>

    <v-dialog
      v-model="refereeDialog"
      width="fit-content"
      style="z-index: 2000">
      <v-card>
        <template v-if="chosenReferee">
          <div class="d-flex justify-center pt-3">
            <img
              v-if="chosenReferee.image_path"
              :src="chosenReferee.image_path"
              class="wh-70"
              onerror="this.src='/img/default-avatar.png'">

            <div class="mt-2 p-2">
              <template v-if="chosenReferee.name">
                {{ $t('name') }} : <b>{{ chosenReferee.name }}</b><br>
              </template>

              <template v-if="chosenReferee.date_of_birth">
                {{ $t('birthdate') }} : <b>{{ chosenReferee.date_of_birth }}</b><br>
              </template>

              <template v-if="chosenReferee.country">
                {{ $t('country') }} : <b>{{ chosenReferee.country.name }}</b><br>
              </template>
            </div>
          </div>
          
          <div class="d-flex justify-center">
            <template v-if="chosenReferee.statistics.length">
              <v-simple-table class="table-row-35 green-top-3">
                <thead>
                  <tr class="white">
                    <th style="border-right: 1px solid lightgrey">{{ $t('season') }}</th>
                    <th style="border-right: 1px solid lightgrey">{{ $t('league') }}</th>
                    <th class="text-center" style="border-right: 1px solid lightgrey" :title="$t('m.tooltip')">{{ $t('m.label') }}</th>
                    <th class="text-center" style="border-right: 1px solid lightgrey" :title="$t('f.tooltip')">{{ $t('f.label') }}</th>

                    <th class="text-center" style="border-right: 1px solid lightgrey" :title="$t('penalty')">
                      <img
                        src="/img/icons/penalty.png"
                        style="width: 15px"
                        :title="$t('penalty')">
                    </th>
                    
                    <th class="text-center" style="border-right: 1px solid lightgrey" :title="$t('yellowcard')">
                      <img
                        src="/img/icons/yellow_card.png"
                        :title="$t('yellowcard')">
                    </th>

                    <th class="text-center" style="border-right: 1px solid lightgrey" :title="$t('redcard')">
                      <img
                        src="/img/icons/red_card.png"
                        :title="$t('redcard')">
                    </th>

                    <th class="text-center" style="border-right: 1px solid lightgrey" :title="$t('var')">
                      <v-icon color="red" class="subtitle-2">mdi-monitor-eye</v-icon>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="st in chosenReferee.statistics"
                    :key="st.id"
                  >
                    <td style="border-right: 1px solid lightgrey">{{ st.season.name }}</td>
                    <td style="border-right: 1px solid lightgrey">{{ st.season.league.name }}</td>
                    
                    <td class="text-end" style="border-right: 1px solid lightgrey" :title="$t('m.tooltip')">
                      <template v-if="st.details.matches">{{ st.details.matches }}</template>
                    </td>

                    <td class="text-end" style="border-right: 1px solid lightgrey" :title="$t('f.tooltip')">
                      <template v-if="st.details.fouls">{{ st.details.fouls.count }}</template>
                    </td>

                    <td class="text-end" style="border-right: 1px solid lightgrey" :title="$t('penalty')">
                      <template v-if="st.details.penalties">
                        {{ st.details.penalties.count }}
                      </template>
                    </td>

                    <td class="text-end" style="border-right: 1px solid lightgrey" :title="$t('yellowcard')">
                      <template v-if="st.details.yellowcards">{{ st.details.yellowcards.count }}</template>
                    </td>

                    <td class="text-end" style="border-right: 1px solid lightgrey" :title="$t('redcard')">
                      <template v-if="st.details.redcards">{{ st.details.redcards.count }}</template>
                    </td>

                    <td class="text-end" style="border-right: 1px solid lightgrey" :title="$t('var')">
                      <template v-if="st.details.var_moments">{{ st.details.var_moments.count }}</template>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </template>

            <span v-else>{{ $t('message.norefereestats') }}</span>
          </div>
        </template>
        
        <div v-else class="ripple_loading wh-70">
          <img src="/img/icons/Ripple-1s-200px.png">
        </div>
      </v-card>
    </v-dialog>

    <div>
      <v-row v-if="!basicLoaded" justify="center">
        <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
      </v-row>
      
      <template v-else>
        <div class="row no-gutters justify-center light-banner pt-50 background-cover">
          <div class="col-lg-10 col-md-11">
            <div class="p-2 d-flex justify-space-around">
              <div class="d-flex align-center">
                <router-link :to="{name: 'team', params: {id: home_id}}" class="d-flex flex-column align-center mr-3" :title="home_team.team_name">
                  <img class='banner_logo1' :src="home_team.team_image" />

                  <span
                    class="text-center font-weight-bold white--text"
                    :class="{'caption': $vuetify.breakpoint.xs, 'subtitle-1': $vuetify.breakpoint.smAndUp}"
                    style="text-shadow: 0px 0px 10px white;">
                    {{ home_team.team_name }}
                  </span>
                </router-link>

                <img class="country_logo2" :src="home_team.country_image" :title="home_team.country_name" />
              </div>

              <div class="d-flex align-center">
                <span
                  class="font-weight-bold blue--text"
                  :class="{'body-2': $vuetify.breakpoint.xs, 'h3': $vuetify.breakpoint.smAndUp}">
                  VS
                </span>
              </div>

              <div class="d-flex align-center">
                <img class="country_logo2" :src="away_team.country_image" :title="away_team.country_name" />

                <router-link :to="{name: 'team', params: {id: away_id}}" class="d-flex flex-column align-center ml-3" :title="away_team.team_name">
                  <img class='banner_logo1' :src="away_team.team_image" />

                  <span
                    class="text-center font-weight-bold white--text"
                    :class="{'caption': $vuetify.breakpoint.xs, 'subtitle-1': $vuetify.breakpoint.smAndUp}"
                    style="text-shadow: 0px 0px 10px white;">
                    {{ away_team.team_name }}
                  </span>
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="pl-1 justify-center">
          <v-tabs
            v-model="tab"
            centered
            show-arrows
            light
          >
            <v-tab
              v-for="item in items"
              :key="item"
            >
              <span :class="{'caption': $vuetify.breakpoint.xs}">
                {{ $t(item) }}
              </span>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item key="Goals">
              <v-row class="no-gutters" justify="center">
                <v-col lg="8" md="10">
                  <div class="game-nav">
                    <div class="game-nav__btn" :class="{'game-nav__btn_active' : flags.goal==0}" @click="goal_flag(0)">{{ $t('head2head') }}</div>
                    <div class="game-nav__btn" :class="{'game-nav__btn_active' : flags.goal==1}" @click="goal_flag(1)">{{ $t('lastmatches') }}</div>
                  </div>

                  <div v-if="!flags.goal" class="mt-4">
                    <div v-if="!h2hLoaded" >
                      <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                    </div>

                    <div v-else>
                      <v-container fluid>
                        <div class="row d-flex">
                          <v-select
                            v-model="h2hChosenLeagues"
                            :items="h2hLeagues"
                            item-text="league_name"
                            item-value="league_id"
                            class="col-12 col-sm-7 col-lg-9"
                            attach
                            dense
                            :label="$t('tournament')"
                            hide-details
                            multiple
                            />
                          <v-select
                            v-model="h2hChosenSeasons"
                            :items="h2hSeasons"
                            :label="$t('season')"
                            class="col-12 col-sm-5 col-lg-3"
                            attach
                            dense
                            hide-details
                            multiple
                            />
                        </div>

                        <div class="row d-flex flex-wrap justify-space-between mt-4">
                          <v-select
                            v-model="h2hFullHalf"
                            :items="timeCategories"
                            :item-text="item => $t(item.text)"
                            item-value="index"
                            :label="$t('time')"
                            class="col-5 col-sm-3 col-md-2"
                            attach
                            dense
                            hide-details
                          />

                          <v-checkbox
                            v-model="h2hLocal"
                            color="blue"
                            hide-details
                            dense
                            :label="$t('home')"
                            />
                          
                          <v-checkbox
                            v-model="h2hVisitor"
                            color="blue"
                            hide-details
                            dense
                            :label="$t('away')"
                            />

                          <v-select
                            v-model="h2hPerPage"
                            :items="h2hCountPerPage"
                            attach
                            dense
                            class="col-4 col-sm-2 col-md-2"
                            :hint="$t('countperpage')"
                          />

                          <v-text-field
                            v-model="h2hPPageByUser"
                            class="col-4 col-sm-2 col-md-2"
                            dense
                            :hint="$t('countperpage')"
                            type="number"
                            max=149
                            min=1
                            v-on:keyup.enter="h2hPerPageChange"
                          />
                        </div>
                      </v-container>

                      <v-simple-table
                        v-if="goalData.matches"
                        class="table-row-35 border-lightgrey text-center"
                        style="border-radius: 0">
                        <tbody>
                          <tr>
                            <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                            <td :title="$t('pts.tooltip')">{{ $t('pts.label') }}</td>
                            <td :title="$t('w.tooltip')">{{ $t('w.label') }}</td>
                            <td :title="$t('d.tooltip')">{{ $t('d.label') }}</td>
                            <td :title="$t('l.tooltip')">{{ $t('l.label') }}</td>
                            <td :title="$t('diff.tooltip')">{{ $t('diff.label') }}</td>
                            <td :title="$t('diff_av.tooltip')">{{ $t('diff_av.label') }}</td>
                            <td :title="$t('total_av.tooltip')">{{ $t('total_av.label') }}</td>
                            <td :title="$t('teamtotal_av.tooltip')">{{ home_team.team_name }}</td>
                            <td :title="$t('opponenttotal_av.tooltip')"> {{ away_team.team_name }}</td>
                          </tr>

                          <tr class="font-weight-bold">
                            <td>{{ goalData.matches }}</td>
                            <td>{{ goalData.points }}</td>
                            <td>{{ goalData.win }}</td>
                            <td>{{ goalData.draw }}</td>
                            <td>{{ goalData.lost }}</td>
                            <td>{{ goalData.diff }}</td>
                            <td>{{ goalData.diff_av }}</td>
                            <td>{{ goalData.totalAvg }}</td>
                            <td>{{ goalData.homeTotalAvg }}</td>
                            <td>{{ goalData.awayTotalAvg }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>

                      <div class="curPointer pt-5 pb-2">
                        <a :class="{'d-none' : h2hPage == h2hLastPage}" class="ml-1 mr-4" @click="h2hPrev()">{{ $t('previous') }}</a>
                        <a :class="{'d-none' : !h2hPage}" @click="h2hNext()">{{ $t('next') }}</a>
                      </div>

                      <v-simple-table class="table-row-35 cell-py-10 border-lightgrey" style="border-radius: 0">
                        <thead>
                          <tr>
                            <th>{{ $t('date') }}</th>
                            <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                            <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                            <th>{{ $t('home') }}</th>
                            <th></th>
                            <th class="text-right">{{ $t('away') }}</th>
                            <th class="text-center">{{ $t('detail') }}</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="game in CGC[h2hPage]"
                            :key="game.index"
                            class="blue-grey--text"
                            :class="{'curPointer': (game.local_goals != 0 || game.visitor_goals != 0)}"
                            @click="goalModal(game.index, game.local_id, game.local_name, game.visitor_name, 0)">
                            <td class="overflow-anywhere">
                              <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                              <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                            </td>

                            <td :class="{'d-none': $vuetify.breakpoint.xs}" class="overflow-anywhere">
                              <router-link :to="{name: 'league', params: {lid: game.league_id}}" class="blue-grey--text">
                                {{ game.league_name }}
                              </router-link>
                            </td>

                            <td :class="{'d-none': $vuetify.breakpoint.xs}">{{ game.round_name ? game.round_name : '' }}</td>

                            <td class="overflow-anywhere">{{ game.local_name }}</td>

                            <td
                              class="white--text"
                              :style="'background-color:' + game.goals_bgcolor">
                              <div class="d-flex justify-space-around align-center subtitle-2">
                                <span>{{ game.local_goals }}</span>
                                <span> - </span>
                                <span>{{ game.visitor_goals }}</span>
                              </div>
                            </td>

                            <td class="overflow-anywhere text-right">{{ game.visitor_name }}</td>

                            <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </div>
                  </div>

                  <template v-else-if="flags.goal==1">
                    <div v-if="!lastHomeLoaded && !lastAwayLoaded">
                      <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                    </div>

                    <div v-else-if="!authorization">
                      <v-alert
                        type="warning"
                        prominent
                      >
                        {{ $t(`message.${msg_last}`) }}
                      </v-alert>
                    </div>

                    <template v-else>
                      <div class="matches_heading">{{ home_team.team_name }} {{ $t('lastmatches') }}</div>

                      <div>
                        <v-container fluid>
                          <div v-if='homeLeagueLoaded' class="row d-flex">
                            <v-select
                              v-model="homeChosenLeagues"
                              :items="HLeagues"
                              item-text="league_name"
                              item-value="league_id"
                              class="col-12 col-sm-7 col-lg-9"
                              attach
                              dense
                              :label="$t('tournament')"
                              hide-details
                              multiple
                              />
                            
                            <v-select
                              v-model="homeChosenSeasons"
                              :items="HSeasons"
                              :label="$t('season')"
                              class="col-12 col-sm-5 col-lg-3"
                              attach
                              dense
                              hide-details
                              multiple
                            />
                          </div>

                          <div class="row d-flex flex-wrap justify-space-between mt-4">
                            <v-select
                              v-model="hLastFullHalf"
                              :items="timeCategories"
                              :item-text="item => $t(item.text)"
                              item-value="index"
                              :label="$t('time')"
                              class="col-5 col-sm-3 col-md-2"
                              attach
                              dense
                              hide-details
                            />

                            <v-checkbox
                              v-model="hLocal"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('home')"
                              />
                            
                            <v-checkbox
                              v-model="hVisitor"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('away')"
                              />

                            <v-select
                              v-model="homePerPage"
                              :items="countPerPage"
                              attach
                              dense
                              class="col-4 col-sm-2 col-md-2"
                              :hint="$t('countperpage')"
                            />

                            <v-text-field
                              v-model="hPPageByUser"
                              class="col-4 col-sm-2 col-md-2"
                              dense
                              :hint="$t('countperpage')"
                              type="number"
                              max=149
                              min=1
                              v-on:keyup.enter="homePerPageChange"
                            />
                          </div>
                        </v-container>

                        <v-simple-table
                          v-if="hLastAvgLoaded && hLastAvg.goalMatch"
                          class="table-row-35 border-lightgrey text-center"
                          style="border-radius: 0">
                          <tbody>
                            <tr>
                              <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                              <td :title="$t('pts.tooltip')">{{ $t('pts.label') }}</td>
                              <td :title="$t('w.tooltip')">{{ $t('w.label') }}</td>
                              <td :title="$t('d.tooltip')">{{ $t('d.label') }}</td>
                              <td :title="$t('l.tooltip')">{{ $t('l.label') }}</td>
                              <td :title="$t('diff.tooltip')">{{ $t('diff.label') }}</td>
                              <td :title="$t('diff_av.tooltip')">{{ $t('diff_av.label') }}</td>
                              <td :title="$t('total_av.tooltip')">{{ $t('total_av.label') }}</td>
                              <td :title="$t('teamtotal_av.tooltip')">{{ home_team.team_name }}</td>
                              <td :title="$t('opponenttotal_av.tooltip')"> {{ $t('opponenttotal_av.label') }}</td>
                            </tr>

                            <tr class="font-weight-bold">
                              <td>{{ hLastAvg.goalMatch }}</td>
                              <td>{{ hLastAvg.goalPoint }}</td>
                              <td>{{ hLastAvg.goalWDL[0] }}</td>
                              <td>{{ hLastAvg.goalWDL[1] }}</td>
                              <td>{{ hLastAvg.goalWDL[2] }}</td>
                              <td>{{ hLastAvg.goalDiff }}</td>
                              <td>{{ hLastAvg.goalDiff_avg }}</td>
                              <td>{{ hLastAvg.goalTotal }}</td>
                              <td>{{ hLastAvg.goalT1 }}</td>
                              <td>{{ hLastAvg.goalT2 }}</td>
                            </tr>
                          </tbody>
                        </v-simple-table>

                        <div class="curPointer pt-5 pb-2">
                          <a :class="{'d-none' : homePage == hLastPage}" class="ml-1 mr-4" @click="homePrev()">{{ $t('previous') }}</a>
                          <a :class="{'d-none' : !homePage}" @click="homeNext()">{{ $t('next') }}</a>
                        </div>

                        <v-simple-table v-if="lastHomeLoaded" class="table-row-35 cell-py-10 border-lightgrey" style="border-radius: 0">
                          <thead>
                            <tr>
                              <th>{{ $t('date') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                              <th>{{ $t('home') }}</th>
                              <th></th>
                              <th class="text-right">{{ $t('away') }}</th>
                              <th class="text-center">{{ $t('detail') }}</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-for="(game, index) in Hlast[homePage]"
                              :key="index"
                              :class="{'curPointer': (game.local_score != 0 || game.visitor_score != 0)}"
                              @click="goalModal(index, game.local_id, game.local_name, game.visitor_name, 1)">
                              <td class="overflow-anywhere blue-grey--text">
                                <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                                <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="overflow-anywhere">
                                <router-link :to="{name: 'league', params: {lid: game.league.league_id}}" class="blue-grey--text">
                                  {{ game.league.name }}
                                </router-link>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="blue-grey--text">
                                {{ game.round ? game.round.name : '' }}
                              </td>

                              <td v-if="game.local_id != home_id">
                                <router-link :to="{name: 'team', params: {id: game.local_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.local_name }}
                                </router-link>
                              </td>

                              <td v-else class="overflow-anywhere black--text">{{ game.local_name }}</td>

                              <td
                                class="white--text"
                                :style="'background-color:' + stats_color[game.goal_color]">
                                <div class="d-flex justify-space-around align-center subtitle-2">
                                  <span>{{ game.local_score }}</span>
                                  <span> - </span>
                                  <span>{{ game.visitor_score }}</span>
                                </div>
                              </td>

                              <td v-if="game.visitor_id != home_id" class="text-right">
                                <router-link :to="{name: 'team', params: {id: game.visitor_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.visitor_name }}
                                </router-link>
                              </td>

                              <td v-else class="overflow-anywhere text-right black--text">{{ game.visitor_name }}</td>

                              <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                            </tr>
                          </tbody>
                        </v-simple-table>

                        <div v-else>
                          <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                        </div>
                      </div>

                      <div class="matches_heading">{{ away_team.team_name }} {{ $t('lastmatches') }}</div>

                      <div>
                        <v-container fluid>
                          <div v-if='awayLeagueLoaded' class="row d-flex">
                            <v-select
                              v-model="awayChosenLeagues"
                              :items="ALeagues"
                              item-text="league_name"
                              item-value="league_id"
                              class="col-12 col-sm-7 col-lg-9"
                              attach
                              dense
                              :label="$t('tournament')"
                              hide-details
                              multiple
                              />

                            <v-select
                              v-model="awayChosenSeasons"
                              :items="ASeasons"
                              :label="$t('season')"
                              class="col-12 col-sm-5 col-lg-3"
                              attach
                              dense
                              hide-details
                              multiple
                            />
                          </div>

                          <div class="row d-flex flex-wrap justify-space-between mt-4">
                            <v-select
                              v-model="aLastFullHalf"
                              :items="timeCategories"
                              :item-text="item => $t(item.text)"
                              item-value="index"
                              :label="$t('time')"
                              class="col-5 col-sm-3 col-md-2"
                              attach
                              dense
                              hide-details
                            />

                            <v-checkbox
                              v-model="aLocal"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('home')"
                              />
                            
                            <v-checkbox
                              v-model="aVisitor"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('away')"
                              />

                            <v-select
                              v-model="awayPerPage"
                              :items="countPerPage"
                              attach
                              dense
                              class="col-4 col-sm-2 col-md-2"
                              :hint="$t('countperpage')"
                            />

                            <v-text-field
                              v-model="aPPageByUser"
                              class="col-4 col-sm-2 col-md-2"
                              dense
                              :hint="$t('countperpage')"
                              type="number"
                              max=149
                              min=1
                              v-on:keyup.enter="awayPerPageChange"
                            />
                          </div>
                        </v-container>

                        <v-simple-table
                          v-if="aLastAvgLoaded && aLastAvg.goalMatch"
                          class="table-row-35 border-lightgrey text-center"
                          style="border-radius: 0">
                          <tbody>
                            <tr>
                              <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                              <td :title="$t('pts.tooltip')">{{ $t('pts.label') }}</td>
                              <td :title="$t('w.tooltip')">{{ $t('w.label') }}</td>
                              <td :title="$t('d.tooltip')">{{ $t('d.label') }}</td>
                              <td :title="$t('l.tooltip')">{{ $t('l.label') }}</td>
                              <td :title="$t('diff.tooltip')">{{ $t('diff.label') }}</td>
                              <td :title="$t('diff_av.tooltip')">{{ $t('diff_av.label') }}</td>
                              <td :title="$t('total_av.tooltip')">{{ $t('total_av.label') }}</td>
                              <td :title="$t('teamtotal_av.tooltip')">{{ away_team.team_name }}</td>
                              <td :title="$t('opponenttotal_av.tooltip')">{{ $t('opponenttotal_av.label') }}</td>
                            </tr>
                            
                            <tr class="font-weight-bold">
                              <td>{{ aLastAvg.goalMatch }}</td>
                              <td>{{ aLastAvg.goalPoint }}</td>
                              <td>{{ aLastAvg.goalWDL[0] }}</td>
                              <td>{{ aLastAvg.goalWDL[1] }}</td>
                              <td>{{ aLastAvg.goalWDL[2] }}</td>
                              <td>{{ aLastAvg.goalDiff }}</td>
                              <td>{{ aLastAvg.goalDiff_avg }}</td>
                              <td>{{ aLastAvg.goalTotal }}</td>
                              <td>{{ aLastAvg.goalT1 }}</td>
                              <td>{{ aLastAvg.goalT2 }}</td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                        
                        <div class="curPointer pt-5 pb-2">
                          <a :class="{'d-none' : awayPage == aLastPage}" class="ml-1 mr-4" @click="awayPrev()">{{ $t('previous') }}</a>
                          <a :class="{'d-none' : !awayPage}" @click="awayNext()">{{ $t('next') }}</a>
                        </div>

                        <v-simple-table v-if="lastAwayLoaded" class="table-row-35 cell-py-10 border-lightgrey" style="border-radius: 0">
                          <thead>
                            <tr>
                              <th>{{ $t('date') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                              <th>{{ $t('home') }}</th>
                              <th></th>
                              <th class="text-right">{{ $t('away') }}</th>
                              <th class="text-center">{{ $t('detail') }}</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-for="(game, index) in Alast[awayPage]"
                              :key="index"
                              :class="{'curPointer': (game.local_score != 0 || game.visitor_score != 0)}"
                              @click="goalModal(index, game.local_id, game.local_name, game.visitor_name, 2)" >
                              <td class="overflow-anywhere blue-grey--text">
                                <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                                <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}">
                                <router-link :to="{name: 'league', params: {lid: game.league.league_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.league.name }}
                                </router-link>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="blue-grey--text">
                                {{ game.round ? game.round.name : '' }}
                              </td>

                              <td v-if="game.local_id != away_id">
                                <router-link :to="{name: 'team', params: {id: game.local_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.local_name }}
                                </router-link>
                              </td>

                              <td v-else class="overflow-anywhere black--text">{{ game.local_name }}</td>

                              <td
                                class="white--text"
                                :style="'background-color:' + stats_color[game.goal_color]">
                                <div class="d-flex justify-space-around align-center subtitle-2">
                                  <span>{{ game.local_score }}</span>
                                  <span> - </span>
                                  <span>{{ game.visitor_score }}</span>
                                </div>
                              </td>

                              <td v-if="game.visitor_id != away_id" class="text-right">
                                <router-link :to="{name: 'team', params: {id: game.visitor_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.visitor_name }}
                                </router-link>
                              </td>

                              <td v-else class="overflow-anywhere text-right black--text">{{ game.visitor_name }}</td>

                              <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                            </tr>
                          </tbody>
                        </v-simple-table>

                        <div v-else>
                          <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                        </div>
                      </div>
                    </template>
                  </template>
                </v-col>
              </v-row>
            </v-tab-item>
            
            <v-tab-item key="Corners">
              <v-row class="no-gutters" justify="center">
                <v-col lg="8" md="10">
                  <div class="game-nav">
                    <div class="game-nav__btn" :class="{'game-nav__btn_active' : flags.corner==0}" @click="corner_flag(0)">{{ $t('head2head') }}</div>
                    <div class="game-nav__btn" :class="{'game-nav__btn_active' : flags.corner==1}" @click="corner_flag(1)">{{ $t('lastmatches') }}</div>
                  </div>

                  <div v-if="!flags.corner" class="mt-4">
                    <div v-if="!h2hLoaded" >
                      <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                    </div>

                    <div v-else>
                      <v-container fluid>
                        <div class="row d-flex">
                          <v-select
                            v-model="h2hChosenLeagues"
                            :items="h2hLeagues"
                            item-text="league_name"
                            item-value="league_id"
                            class="col-12 col-sm-7 col-lg-9"
                            attach
                            dense
                            :label="$t('tournament')"
                            hide-details
                            multiple
                          />

                          <v-select
                            v-model="h2hChosenSeasons"
                            :items="h2hSeasons"
                            :label="$t('season')"
                            class="col-12 col-sm-5 col-lg-3"
                            attach
                            dense
                            hide-details
                            multiple
                          />
                        </div>

                        <div class="row d-flex flex-wrap justify-space-between mt-4">
                          <v-select
                            v-model="h2hFullHalf"
                            :items="timeCategories"
                            :item-text="item => $t(item.text)"
                            item-value="index"
                            :label="$t('time')"
                            class="col-5 col-sm-3 col-md-2"
                            attach
                            dense
                            hide-details
                          />

                          <v-checkbox
                            v-model="h2hLocal"
                            color="blue"
                            hide-details
                            dense
                            :label="$t('home')"
                            />
                          
                          <v-checkbox
                            v-model="h2hVisitor"
                            color="blue"
                            hide-details
                            dense
                            :label="$t('away')"
                            />

                          <v-select
                            v-model="h2hPerPage"
                            :items="h2hCountPerPage"
                            attach
                            dense
                            class="col-4 col-sm-2 col-md-2"
                            :hint="$t('countperpage')"
                          />

                          <v-text-field
                            v-model="h2hPPageByUser"
                            class="col-2 col-sm-2 col-md-2"
                            dense
                            :hint="$t('countperpage')"
                            type="number"
                            max=149
                            min=1
                            v-on:keyup.enter="h2hPerPageChange"
                          />
                        </div>
                      </v-container>

                      <v-simple-table
                        v-if="cornerData.matches"
                        class="table-row-35 border-lightgrey text-center"
                        style="border-radius: 0">
                        <tbody>
                          <tr>
                            <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                            <td :title="$t('pts.tooltip')">{{ $t('pts.label') }}</td>
                            <td :title="$t('w.tooltip')">{{ $t('w.label') }}</td>
                            <td :title="$t('d.tooltip')">{{ $t('d.label') }}</td>
                            <td :title="$t('l.tooltip')">{{ $t('l.label') }}</td>
                            <td :title="$t('diff.tooltip')">{{ $t('diff.label') }}</td>
                            <td :title="$t('diff_av.tooltip')">{{ $t('diff_av.label') }}</td>
                            <td :title="$t('total_av.tooltip')">{{ $t('total_av.label') }}</td>
                            <td :title="$t('teamtotal_av.tooltip')">{{ home_team.team_name }}</td>
                            <td :title="$t('opponenttotal_av.tooltip')"> {{ away_team.team_name }}</td>
                          </tr>

                          <tr class="font-weight-bold">
                            <td>{{ cornerData.matches }}</td>
                            <td>{{ cornerData.points }}</td>
                            <td>{{ cornerData.win }}</td>
                            <td>{{ cornerData.draw }}</td>
                            <td>{{ cornerData.lost }}</td>
                            <td>{{ cornerData.diff }}</td>
                            <td>{{ cornerData.diff_av }}</td>
                            <td>{{ cornerData.totalAvg }}</td>
                            <td>{{ cornerData.homeTotalAvg }}</td>
                            <td>{{ cornerData.awayTotalAvg }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>

                      <div class="curPointer pt-5 pb-2">
                        <a :class="{'d-none' : h2hPage == h2hLastPage}" class="ml-1 mr-4" @click="h2hPrev()">{{ $t('previous') }}</a>
                        <a :class="{'d-none' : !h2hPage}" @click="h2hNext()">{{ $t('next') }}</a>
                      </div>

                      <v-simple-table  class="table-row-35 cell-py-10 border-lightgrey" style="border-radius: 0">
                        <thead>
                          <tr>
                            <th>{{ $t('date') }}</th>
                            <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                            <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                            <th>{{ $t('home') }}</th>
                            <th></th>
                            <th class="text-right">{{ $t('away') }}</th>
                            <th class="text-center">{{ $t('detail') }}</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="(game, index) in CGC[h2hPage]"
                            :key="index"
                            class="blue-grey--text"
                            :class="{'curPointer': (game.local_corners != 0 || game.visitor_corners != 0)}"
                            @click="cornerModal(game.index, game.local_id, game.local_name, game.visitor_name, 0)">
                            <td class="overflow-anywhere">
                              <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                              <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                            </td>

                            <td :class="{'d-none': $vuetify.breakpoint.xs}" class="overflow-anywhere">
                              <router-link :to="{name: 'league', params: {lid: game.league_id}}" class="blue-grey--text">
                                {{ game.league_name }}
                              </router-link>
                            </td>

                            <td :class="{'d-none': $vuetify.breakpoint.xs}">{{ game.round_name ? game.round_name : '' }}</td>
                            
                            <td class="overflow-anywhere">{{ game.local_name }}</td>

                            <td
                              class="white--text"
                              :style="'background-color:' + game.corners_bgcolor">
                              <div class="d-flex justify-space-around align-center subtitle-2">
                                <span>{{ (game.local_corners != 0 || game.visitor_corners != 0) ? game.local_corners : '?' }}</span>
                                
                                <span v-if="$vuetify.breakpoint.xs">-</span>

                                <img v-else src='/img/icons/corner_kick.png'>

                                <span>{{ (game.local_corners != 0 || game.visitor_corners != 0) ? game.visitor_corners : '?' }}</span>
                              </div>
                            </td>

                            <td class="overflow-anywhere text-right">{{ game.visitor_name }}</td>

                            <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </div>
                  </div>

                  <template v-else-if="flags.corner==1">
                    <div v-if="!lastHomeLoaded && !lastAwayLoaded">
                      <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                    </div>

                    <div v-else-if="!authorization">
                      <v-alert
                        type="warning"
                        prominent
                      >
                        {{ $t(`message.${msg_last}`) }}
                      </v-alert>
                    </div>

                    <template v-else>
                      <div class="matches_heading">{{ home_team.team_name }} {{ $t('lastmatches') }}</div>

                      <div>
                        <v-container fluid>
                          <div v-if='homeLeagueLoaded' class="row d-flex">
                              <v-select
                                v-model="homeChosenLeagues"
                                :items="HLeagues"
                                item-text="league_name"
                                item-value="league_id"
                                class="col-12 col-sm-7 col-lg-9"
                                attach
                                dense
                                :label="$t('tournament')"
                                hide-details
                                multiple
                              />

                              <v-select
                                v-model="homeChosenSeasons"
                                :items="HSeasons"
                                :label="$t('season')"
                                class="col-12 col-sm-5 col-lg-3"
                                attach
                                dense
                                hide-details
                                multiple
                              />
                          </div>

                          <div class="row d-flex flex-wrap justify-space-between mt-4">
                            <v-select
                              v-model="hLastFullHalf"
                              :items="timeCategories"
                              :item-text="item => $t(item.text)"
                              item-value="index"
                              :label="$t('time')"
                              class="col-5 col-sm-3 col-md-2"
                              attach
                              dense
                              hide-details
                            />

                            <v-checkbox
                              v-model="hLocal"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('home')"
                              />
                            
                            <v-checkbox
                              v-model="hVisitor"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('away')"
                              />

                            <v-select
                              v-model="homePerPage"
                              :items="countPerPage"
                              attach
                              dense
                              class="col-4 col-sm-2 col-md-2"
                              :hint="$t('countperpage')"
                            />

                            <v-text-field
                              v-model="hPPageByUser"
                              class="col-4 col-sm-2 col-md-2"
                              dense
                              :hint="$t('countperpage')"
                              type="number"
                              max=149
                              min=1
                              v-on:keyup.enter="homePerPageChange"
                            />
                          </div>
                        </v-container>

                        <v-simple-table
                          v-if="hLastAvgLoaded && hLastAvg.cornerMatch"
                          class="table-row-35 border-lightgrey text-center"
                          style="border-radius: 0">
                          <tbody>
                            <tr>
                              <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                              <td :title="$t('pts.tooltip')">{{ $t('pts.label') }}</td>
                              <td :title="$t('w.tooltip')">{{ $t('w.label') }}</td>
                              <td :title="$t('d.tooltip')">{{ $t('d.label') }}</td>
                              <td :title="$t('l.tooltip')">{{ $t('l.label') }}</td>
                              <td :title="$t('diff.tooltip')">{{ $t('diff.label') }}</td>
                              <td :title="$t('diff_av.tooltip')">{{ $t('diff_av.label') }}</td>
                              <td :title="$t('total_av.tooltip')">{{ $t('total_av.label') }}</td>
                              <td :title="$t('teamtotal_av.tooltip')">{{ home_team.team_name }}</td>
                              <td :title="$t('opponenttotal_av.tooltip')"> {{ $t('opponenttotal_av.label') }}</td>
                            </tr>
                            
                            <tr class="font-weight-bold">
                              <td>{{ hLastAvg.cornerMatch }}</td>
                              <td>{{ hLastAvg.cornerPoint }}</td>
                              <td>{{ hLastAvg.cornerWDL[0] }}</td>
                              <td>{{ hLastAvg.cornerWDL[1] }}</td>
                              <td>{{ hLastAvg.cornerWDL[2] }}</td>
                              <td>{{ hLastAvg.cornerDiff }}</td>
                              <td>{{ hLastAvg.cornerDiff_avg }}</td>
                              <td>{{ hLastAvg.cornerTotal }}</td>
                              <td>{{ hLastAvg.cornerT1 }}</td>
                              <td>{{ hLastAvg.cornerT2 }}</td>
                            </tr>
                          </tbody>
                        </v-simple-table>

                        <div class="curPointer pt-5 pb-2">
                          <a :class="{'d-none' : homePage == hLastPage}" class="ml-1 mr-4" @click="homePrev()">{{ $t('previous') }}</a>
                          <a :class="{'d-none' : !homePage}" @click="homeNext()">{{ $t('next') }}</a>
                        </div>

                        <v-simple-table
                          v-if="lastHomeLoaded"
                          class="table-row-35 cell-py-10 border-lightgrey"
                          style="border-radius: 0">
                          <thead>
                            <tr>
                              <th>{{ $t('date') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                              <th>{{ $t('home') }}</th>
                              <th></th>
                              <th class="text-right">{{ $t('away') }}</th>
                              <th class="text-center">{{ $t('detail') }}</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-for="(game, index) in Hlast[homePage]"
                              :key="index"
                              :class="{'curPointer': (game.corners[game.local_id] != 0 || game.corners[game.visitor_id] != 0)}"
                              @click="cornerModal(index, game.local_id, game.local_name, game.visitor_name, 1)">
                              <td class="overflow-anywhere blue-grey--text">
                                <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                                <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="overflow-anywhere">
                                <router-link :to="{name: 'league', params: {lid: game.league.league_id}}" class="blue-grey--text">
                                  {{ game.league.name }}
                                </router-link>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="blue-grey--text">
                                {{ game.round ? game.round.name : '' }}
                              </td>

                              <td v-if="game.local_id!=home_id">
                                <router-link :to="{name: 'team', params: {id: game.local_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.local_name }}
                                </router-link>
                              </td>

                              <td v-else class="overflow-anywhere black--text">{{ game.local_name }}</td>

                              <td
                                class="white--text"
                                :style="'background-color:' + stats_color[game.corner_color]">
                                <div class="d-flex justify-space-around align-center subtitle-2">
                                  <span>{{ (game.corners[game.local_id] != 0 || game.corners[game.visitor_id] != 0) ? game.corners[game.local_id] : '?' }}</span>
                                  
                                  <span v-if="$vuetify.breakpoint.xs">-</span>

                                  <img v-else src='/img/icons/corner_kick.png'>

                                  <span>{{ (game.corners[game.local_id] != 0 || game.corners[game.visitor_id] != 0) ? game.corners[game.visitor_id] : '?' }}</span>
                                </div>
                              </td>

                              <td v-if="game.visitor_id != home_id"  class="text-right">
                                <router-link :to="{name: 'team', params: {id: game.visitor_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.visitor_name }}
                                </router-link>
                              </td>

                              <td v-else class="overflow-anywhere text-right black--text">{{ game.visitor_name }}</td>
                              
                              <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                            </tr>
                          </tbody>
                        </v-simple-table>

                        <div v-else>
                          <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                        </div>
                      </div>

                      <div class="matches_heading">{{ away_team.team_name }} {{ $t('lastmatches') }}</div>

                      <div>
                        <v-container fluid>
                          <div v-if='awayLeagueLoaded' class="row d-flex">
                            <v-select
                              v-model="awayChosenLeagues"
                              :items="ALeagues"
                              item-text="league_name"
                              item-value="league_id"
                              class="col-12 col-sm-7 col-lg-9"
                              attach
                              dense
                              :label="$t('tournament')"
                              hide-details
                              multiple
                            />

                            <v-select
                              v-model="awayChosenSeasons"
                              :items="ASeasons"
                              :label="$t('season')"
                              class="col-12 col-sm-5 col-lg-3"
                              attach
                              dense
                              hide-details
                              multiple
                            />
                          </div>

                          <div class="row d-flex flex-wrap justify-space-between mt-4">
                            <v-select
                              v-model="aLastFullHalf"
                              :items="timeCategories"
                              :item-text="item => $t(item.text)"
                              item-value="index"
                              :label="$t('time')"
                              class="col-5 col-sm-3 col-md-2"
                              attach
                              dense
                              hide-details
                            />

                            <v-checkbox
                              v-model="aLocal"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('home')"
                              />
                            
                            <v-checkbox
                              v-model="aVisitor"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('away')"
                              />

                            <v-select
                              v-model="awayPerPage"
                              :items="countPerPage"
                              attach
                              dense
                              class="col-4 col-sm-2 col-md-2"
                              :hint="$t('countperpage')"
                            />

                            <v-text-field
                              v-model="aPPageByUser"
                              class="col-4 col-sm-2 col-md-2"
                              dense
                              :hint="$t('countperpage')"
                              type="number"
                              max=149
                              min=1
                              v-on:keyup.enter="awayPerPageChange"
                            />
                          </div>
                        </v-container>

                        <v-simple-table
                          v-if="aLastAvgLoaded && aLastAvg.cornerMatch"
                          class="table-row-35 border-lightgrey text-center"
                          style="border-radius: 0">
                          <tbody>
                            <tr>
                              <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                              <td :title="$t('pts.tooltip')">{{ $t('pts.label') }}</td>
                              <td :title="$t('w.tooltip')">{{ $t('w.label') }}</td>
                              <td :title="$t('d.tooltip')">{{ $t('d.label') }}</td>
                              <td :title="$t('l.tooltip')">{{ $t('l.label') }}</td>
                              <td :title="$t('diff.tooltip')">{{ $t('diff.label') }}</td>
                              <td :title="$t('diff_av.tooltip')">{{ $t('diff_av.label') }}</td>
                              <td :title="$t('total_av.tooltip')">{{ $t('total_av.label') }}</td>
                              <td :title="$t('teamtotal_av.tooltip')">{{ away_team.team_name }}</td>
                              <td :title="$t('opponenttotal_av.tooltip')"> {{ $t('opponenttotal_av.label') }}</td>
                            </tr>

                            <tr class="font-weight-bold">
                              <td>{{ aLastAvg.cornerMatch }}</td>
                              <td>{{ aLastAvg.cornerPoint }}</td>
                              <td>{{ aLastAvg.cornerWDL[0] }}</td>
                              <td>{{ aLastAvg.cornerWDL[1] }}</td>
                              <td>{{ aLastAvg.cornerWDL[2] }}</td>
                              <td>{{ aLastAvg.cornerDiff }}</td>
                              <td>{{ aLastAvg.cornerDiff_avg }}</td>
                              <td>{{ aLastAvg.cornerTotal }}</td>
                              <td>{{ aLastAvg.cornerT1 }}</td>
                              <td>{{ aLastAvg.cornerT2 }}</td>
                            </tr>
                          </tbody>
                        </v-simple-table>

                        <div class="curPointer pt-5 pb-2">
                          <a :class="{'d-none' : awayPage == aLastPage}" class="ml-1 mr-4" @click="awayPrev()">{{ $t('previous') }}</a>
                          <a :class="{'d-none' : !awayPage}" @click="awayNext()">{{ $t('next') }}</a>
                        </div>

                        <v-simple-table v-if="lastAwayLoaded" class="table-row-35 cell-py-10 border-lightgrey" style="border-radius: 0">
                          <thead>
                            <tr>
                              <th>{{ $t('date') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                              <th>{{ $t('home') }}</th>
                              <th></th>
                              <th class="text-right">{{ $t('away') }}</th>
                              <th class="text-center">{{ $t('detail') }}</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-for="(game, index) in Alast[awayPage]"
                              :key="index"
                              :class="{'curPointer': (game.corners[game.local_id] != 0 || game.corners[game.visitor_id] != 0)}"
                              @click="cornerModal(index, game.local_id, game.local_name, game.visitor_name, 2)">
                              <td class="overflow-anywhere blue-grey--text">
                                <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                                <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="overflow-anywhere">
                                <router-link :to="{name: 'league', params: {lid: game.league.league_id}}" class="blue-grey--text">
                                  {{ game.league.name }}
                                </router-link>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="blue-grey--text">
                                {{ game.round ? game.round.name : '' }}
                              </td>

                              <td v-if="game.local_id != away_id">
                                <router-link :to="{name: 'team', params: {id: game.local_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.local_name }}
                                </router-link>
                              </td>

                              <td v-else class="overflow-anywhere black--text">{{ game.local_name }}</td>

                              <td
                                class="white--text"
                                :style="'background-color:' + stats_color[game.corner_color]">
                                <div class="d-flex justify-space-around align-center subtitle-2">
                                  <span>{{ (game.corners[game.local_id] != 0 || game.corners[game.visitor_id] != 0) ? game.corners[game.local_id] : '?' }}</span>
                                  
                                  <span v-if="$vuetify.breakpoint.xs">-</span>

                                  <img v-else src='/img/icons/corner_kick.png'>

                                  <span>{{ (game.corners[game.local_id] != 0 || game.corners[game.visitor_id] != 0) ? game.corners[game.visitor_id] : '?' }}</span>
                                </div>
                              </td>
                              
                              <td v-if="game.visitor_id != away_id" class="text-right">
                                <router-link :to="{name: 'team', params: {id: game.visitor_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.visitor_name }}
                                </router-link>
                              </td>

                              <td v-else class="overflow-anywhere text-right black--text">{{ game.visitor_name }}</td>

                              <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                            </tr>
                          </tbody>
                        </v-simple-table>

                        <div v-else>
                          <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                        </div>
                      </div>
                    </template>
                  </template>
                </v-col>
              </v-row>
            </v-tab-item>              

            <v-tab-item key="Cards">
              <v-row class="no-gutters" justify="center">
                <v-col lg="8" md="10">
                  <div class="game-nav">
                    <div class="game-nav__btn" :class="{'game-nav__btn_active' : flags.card==0}" @click="card_flag(0)">{{ $t('head2head') }}</div>
                    <div class="game-nav__btn" :class="{'game-nav__btn_active' : flags.card==1}" @click="card_flag(1)">{{ $t('lastmatches') }}</div>
                  </div>

                  <div v-if="!flags.card" class="mt-4">
                    <div v-if="!h2hLoaded">
                      <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                    </div>

                    <div v-else>
                      <v-container fluid>
                        <div class="row d-flex">
                          <v-select
                            v-model="h2hChosenLeagues"
                            :items="h2hLeagues"
                            item-text="league_name"
                            item-value="league_id"
                            class="col-12 col-sm-7 col-lg-9"
                            attach
                            dense
                            :label="$t('tournament')"
                            hide-details
                            multiple
                          />

                          <v-select
                            v-model="h2hChosenSeasons"
                            :items="h2hSeasons"
                            :label="$t('season')"
                            class="col-12 col-sm-5 col-lg-3"
                            attach
                            dense
                            hide-details
                            multiple
                          />
                        </div>

                        <div class="row d-flex flex-wrap justify-space-between mt-4">
                          <v-select
                            v-model="h2hFullHalf"
                            :items="timeCategories"
                            :item-text="item => $t(item.text)"
                            item-value="index"
                            :label="$t('time')"
                            class="col-5 col-sm-3 col-md-2"
                            attach
                            dense
                            hide-details
                          />

                          <v-checkbox
                            v-model="h2hLocal"
                            color="blue"
                            hide-details
                            dense
                            :label="$t('home')"
                            />
                          
                          <v-checkbox
                            v-model="h2hVisitor"
                            color="blue"
                            hide-details
                            dense
                            :label="$t('away')"
                            />

                          <v-select
                            v-model="h2hPerPage"
                            :items="h2hCountPerPage"
                            attach
                            dense
                            class="col-4 col-sm-2 col-md-2"
                            :hint="$t('countperpage')"
                          />

                          <v-text-field
                            v-model="h2hPPageByUser"
                            class="col-4 col-sm-2 col-md-2"
                            dense
                            :hint="$t('countperpage')"
                            type="number"
                            max=149
                            min=1
                            v-on:keyup.enter="h2hPerPageChange"
                          />
                        </div>
                      </v-container>

                      <v-simple-table
                        v-if="cardData.matches"
                        class="table-row-35 border-lightgrey text-center"
                        style="border-radius: 0">
                        <tbody>
                          <tr>
                            <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                            <td :title="$t('diff_yc.tooltip')">{{ $t('diff_yc.label') }}</td>
                            <td :title="$t('t_yc.tooltip')">{{ $t('t_yc.label') }}</td>
                            <td :title="$t('t1_yc.tooltip')">{{ $t('t1_yc.label') }}</td>
                            <td :title="$t('t2_yc.tooltip')">{{ $t('t2_yc.label') }}</td>
                            <td :title="$t('diff_rc.tooltip')">{{ $t('diff_rc.label') }}</td>
                            <td :title="$t('t_rc.tooltip')">{{ $t('t_rc.label') }}</td>
                            <td :title="$t('t1_rc.tooltip')">{{ $t('t1_rc.label') }}</td>
                            <td :title="$t('t2_rc.tooltip')">{{ $t('t2_rc.label') }}</td>
                          </tr>

                          <tr class="font-weight-bold">
                            <td>{{ cardData.matches }}</td>
                            <td>{{ cardData.diff_yc }}</td>
                            <td>{{ cardData.totalYC }}</td>
                            <td>{{ cardData.homeTotalYC }}</td>
                            <td>{{ cardData.awayTotalYC }}</td>
                            <td>{{ cardData.diff_rc }}</td>
                            <td>{{ cardData.totalRC }}</td>
                            <td>{{ cardData.homeTotalRC }}</td>
                            <td>{{ cardData.awayTotalRC }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>

                      <div class="curPointer pt-5 pb-2">
                        <a :class="{'d-none' : h2hPage == h2hLastPage}" class="ml-1 mr-4" @click="h2hPrev()">{{ $t('previous') }}</a>
                        <a :class="{'d-none' : !h2hPage}" @click="h2hNext()">{{ $t('next') }}</a>
                      </div>

                      <v-simple-table class="cell-py-10 border-lightgrey" style="border-radius: 0">
                        <thead>
                          <tr>
                            <th>{{ $t('date') }}</th>
                            <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                            <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                            <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('referee') }}</th>
                            <th>{{ $t('home') }}</th>
                            <th></th>
                            <th class="text-right">{{ $t('away') }}</th>
                            <th class="text-center">{{ $t('detail') }}</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="(game, index) in CGC[h2hPage]"
                            :key="index"
                            class="blue-grey--text"
                            :class="{'curPointer': (game.local_cards[0] != -1)}"
                            @click="cardModal(game.index, game.local_id, game.local_name, game.visitor_name, 0)">
                            <td class="overflow-anywhere">
                              <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                              <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                            </td>

                            <td :class="{'d-none': $vuetify.breakpoint.xs}" class="overflow-anywhere">
                              <router-link :to="{name: 'league', params: {lid: game.league_id}}" class="blue-grey--text">
                                {{ game.league_name }}
                              </router-link>
                            </td>

                            <td :class="{'d-none': $vuetify.breakpoint.xs}">{{ game.round_name ? game.round_name : '' }}</td>

                            <td
                              :class="{'d-none': $vuetify.breakpoint.xs}"
                              style="text-decoration-line: underline;"
                              @click.stop="game.referee_id ? modal4RefereeStats(game.referee_id) : ''">
                              {{ game.referee }}
                            </td>

                            <td class="overflow-anywhere">{{ game.local_name }}</td>

                            <td>
                              <div class="d-flex justify-space-around align-center subtitle-2">
                                <div>
                                  <template 
                                    v-if="game.local_cards[0] == -1">
                                    ?
                                  </template>

                                  <template v-else>
                                    <span class="team_1_yellow_cards">{{ game.local_cards[0] }}</span>
                                    <br>
                                    <span class="team_1_red_cards">{{ game.local_cards[1] + game.local_cards[2] }}</span>
                                  </template>
                                </div>
                                
                                <div style="min-width: 11px;">
                                  <img src="/img/icons/yellow_card.png">
                                  <br>
                                  <img src="/img/icons/red_card.png">
                                </div>
                                
                                <div>
                                  <template 
                                    v-if="game.local_cards[0] == -1">
                                    ?
                                  </template>

                                  <template v-else>
                                    <span class='team_2_yellow_cards'>{{ game.visitor_cards[0] }}</span>
                                    <br>
                                    <span class='team_2_red_cards'>{{ game.visitor_cards[1] + game.visitor_cards[2] }}</span>
                                  </template>
                                </div>
                              </div>
                            </td>

                            <td class="overflow-anywhere text-right">{{ game.visitor_name }}</td>

                            <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </div>
                  </div>

                  <template v-else-if="flags.card==1">
                    <div v-if="!lastHomeLoaded && !lastAwayLoaded">
                      <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                    </div>

                    <div v-else-if="!authorization">
                      <v-alert
                        type="warning"
                        prominent
                      >
                        {{ $t(`message.${msg_last}`) }}
                      </v-alert>
                    </div>

                    <template v-else>
                      <div class="matches_heading">{{ home_team.team_name }} {{ $t('lastmatches') }}</div>

                      <div>
                        <v-container fluid>
                          <div v-if='homeLeagueLoaded' class="row d-flex">
                            <v-select
                              v-model="homeChosenLeagues"
                              :items="HLeagues"
                              item-text="league_name"
                              item-value="league_id"
                              class="col-12 col-sm-7 col-lg-9"
                              attach
                              dense
                              :label="$t('tournament')"
                              hide-details
                              multiple
                            />

                            <v-select
                              v-model="homeChosenSeasons"
                              :items="HSeasons"
                              :label="$t('season')"
                              class="col-12 col-sm-5 col-lg-3"
                              attach
                              dense
                              hide-details
                              multiple
                            />
                          </div>

                          <div class="row d-flex flex-wrap justify-space-between mt-4">
                            <v-select
                              v-model="hLastFullHalf"
                              :items="timeCategories"
                              :item-text="item => $t(item.text)"
                              item-value="index"
                              :label="$t('time')"
                              class="col-5 col-sm-3 col-md-2"
                              attach
                              dense
                              hide-details
                            />

                            <v-checkbox
                              v-model="hLocal"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('home')"
                              />
                            
                            <v-checkbox
                              v-model="hVisitor"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('away')"
                              />
                            
                            <v-select
                              v-model="homePerPage"
                              :items="countPerPage"
                              attach
                              dense
                              class="col-4 col-sm-2 col-md-2"
                              :hint="$t('countperpage')"
                            />

                            <v-text-field
                              v-model="hPPageByUser"
                              class="col-4 col-sm-2 col-md-2"
                              dense
                              :hint="$t('countperpage')"
                              type="number"
                              max=149
                              min=1
                              v-on:keyup.enter="homePerPageChange"
                            />
                          </div>
                        </v-container>

                        <v-simple-table
                          v-if="hLastAvgLoaded && hLastAvg.cardMatch"
                          class="table-row-35 border-lightgrey text-center"
                          style="border-radius: 0">
                          <tbody>
                            <tr>
                              <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                              <td :title="$t('diff_yc.tooltip')">{{ $t('diff_yc.label') }}</td>
                              <td :title="$t('t_yc.tooltip')">{{ $t('t_yc.label') }}</td>
                              <td :title="$t('t1_yc.tooltip')">{{ $t('t1_yc.label') }}</td>
                              <td :title="$t('t2_yc.tooltip')">{{ $t('t2_yc.label') }}</td>
                              <td :title="$t('diff_rc.tooltip')">{{ $t('diff_rc.label') }}</td>
                              <td :title="$t('t_rc.tooltip')">{{ $t('t_rc.label') }}</td>
                              <td :title="$t('t1_rc.tooltip')">{{ $t('t1_rc.label') }}</td>
                              <td :title="$t('t2_rc.tooltip')">{{ $t('t2_rc.label') }}</td>
                            </tr>
                            
                            <tr class="font-weight-bold">
                              <td>{{ hLastAvg.cardMatch }}</td>
                              <td>{{ hLastAvg.cardDiff_yc }}</td>
                              <td>{{ hLastAvg.cardTotal_yc }}</td>
                              <td>{{ hLastAvg.cardT1_yc }}</td>
                              <td>{{ hLastAvg.cardT2_yc }}</td>
                              <td>{{ hLastAvg.cardDiff_rc }}</td>
                              <td>{{ hLastAvg.cardTotal_rc }}</td>
                              <td>{{ hLastAvg.cardT1_rc }}</td>
                              <td>{{ hLastAvg.cardT2_rc }}</td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                        
                        <div class="curPointer pt-5 pb-2">
                          <a :class="{'d-none' : homePage == hLastPage}" class="ml-1 mr-4" @click="homePrev()">{{ $t('previous') }}</a>
                          <a :class="{'d-none' : !homePage}" @click="homeNext()">{{ $t('next') }}</a>
                        </div>

                        <v-simple-table v-if="lastHomeLoaded" class="cell-py-10 border-lightgrey" style="border-radius: 0">
                          <thead>
                            <tr>
                              <th>{{ $t('date') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('referee') }}</th>
                              <th>{{ $t('home') }}</th>
                              <th></th>
                              <th class="text-right">{{ $t('away') }}</th>
                              <th class="text-center">{{ $t('detail') }}</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-for="(game, index) in Hlast[homePage]"
                              :key="index"
                              :class="{'curPointer': (game.local_cards.yellow != -1)}"
                              @click="cardModal(index, game.local_id, game.local_name, game.visitor_name, 1)">
                              <td class="overflow-anywhere blue-grey--text">
                                <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                                <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="overflow-anywhere">
                                <router-link :to="{name: 'league', params: {lid: game.league.league_id}}" class="blue-grey--text">
                                  {{ game.league.name }}
                                </router-link>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="blue-grey--text">
                                {{ game.round ? game.round.name : '' }}
                              </td>

                              <td
                                :class="{'d-none': $vuetify.breakpoint.xs}"
                                class="blue-grey--text curPointer"
                                style="text-decoration-line: underline;"
                                @click.stop="game.referee ? modal4RefereeStats(game.referee) : ''">
                                {{ game.referee_name }}
                              </td>

                              <td v-if="game.local_id != home_id">
                                <router-link :to="{name: 'team', params: {id: game.local_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.local_name }}
                                </router-link>
                              </td>

                              <td v-else class="overflow-anywhere black--text">{{ game.local_name }}</td>

                              <td class="blue-grey--text">
                                <div class="d-flex justify-space-around align-center subtitle-2">
                                  <div>
                                    <template 
                                      v-if="game.local_cards.yellow == -1">
                                      ?
                                    </template>

                                    <template v-else>
                                      <span class="team_1_yellow_cards">{{ game.local_cards.yellow }}</span><br>
                                      <span class="team_1_red_cards">{{ game.local_cards.red }}</span>
                                    </template>
                                  </div>
                                  
                                  <div style="min-width: 11px;">
                                    <img src="/img/icons/yellow_card.png"><br>
                                    <img src="/img/icons/red_card.png">
                                  </div>
                                  
                                  <div>
                                    <template 
                                      v-if="game.local_cards.yellow == -1">
                                      ?
                                    </template>

                                    <template v-else>
                                      <span class='team_2_yellow_cards'>{{ game.visitor_cards.yellow }}</span><br>
                                      <span class='team_2_red_cards'>{{ game.visitor_cards.red }}</span>
                                    </template>
                                  </div>
                                </div>
                              </td>

                              <td v-if="game.visitor_id != home_id" class="text-right">
                                <router-link :to="{name: 'team', params: {id: game.visitor_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.visitor_name }}
                                </router-link>
                              </td>

                              <td v-else class="overflow-anywhere text-right black--text">{{ game.visitor_name }}</td>

                              <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                            </tr>
                          </tbody>
                        </v-simple-table>

                        <div v-else>
                          <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                        </div>
                      </div>

                      <div class="matches_heading">{{ away_team.team_name }} {{ $t('lastmatches') }}</div>

                      <div>
                        <v-container fluid>
                          <div v-if='awayLeagueLoaded' class="row d-flex">
                            <v-select
                              v-model="awayChosenLeagues"
                              :items="ALeagues"
                              item-text="league_name"
                              item-value="league_id"
                              class="col-12 col-sm-7 col-lg-9"
                              attach
                              dense
                              :label="$t('tournament')"
                              hide-details
                              multiple
                            />

                            <v-select
                              v-model="awayChosenSeasons"
                              :items="ASeasons"
                              :label="$t('season')"
                              class="col-12 col-sm-5 col-lg-3"
                              attach
                              dense
                              hide-details
                              multiple
                            />
                          </div>

                          <div class="row d-flex flex-wrap justify-space-between mt-4">
                            <v-select
                              v-model="aLastFullHalf"
                              :items="timeCategories"
                              :item-text="item => $t(item.text)"
                              item-value="index"
                              :label="$t('time')"
                              class="col-5 col-sm-3 col-md-2"
                              attach
                              dense
                              hide-details
                            />

                            <v-checkbox
                              v-model="aLocal"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('home')"
                              />
                            
                            <v-checkbox
                              v-model="aVisitor"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('away')"
                              />
                            
                            <v-select
                              v-model="awayPerPage"
                              :items="countPerPage"
                              attach
                              dense
                              class="col-4 col-sm-2 col-md-2"
                              :hint="$t('countperpage')"
                            />

                            <v-text-field
                              v-model="aPPageByUser"
                              class="col-4 col-sm-2 col-md-2"
                              dense
                              :hint="$t('countperpage')"
                              type="number"
                              max=149
                              min=1
                              v-on:keyup.enter="awayPerPageChange"
                            />
                          </div>
                        </v-container>

                        <v-simple-table
                          v-if="aLastAvgLoaded && aLastAvg.cardMatch"
                          class="table-row-35 border-lightgrey text-center"
                          style="border-radius: 0">
                          <tbody>
                            <tr>
                              <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                              <td :title="$t('diff_yc.tooltip')">{{ $t('diff_yc.label') }}</td>
                              <td :title="$t('t_yc.tooltip')">{{ $t('t_yc.label') }}</td>
                              <td :title="$t('t1_yc.tooltip')">{{ $t('t1_yc.label') }}</td>
                              <td :title="$t('t2_yc.tooltip')">{{ $t('t2_yc.label') }}</td>
                              <td :title="$t('diff_rc.tooltip')">{{ $t('diff_rc.label') }}</td>
                              <td :title="$t('t_rc.tooltip')">{{ $t('t_rc.label') }}</td>
                              <td :title="$t('t1_rc.tooltip')">{{ $t('t1_rc.label') }}</td>
                              <td :title="$t('t2_rc.tooltip')">{{ $t('t2_rc.label') }}</td>
                            </tr>
                            
                            <tr class="font-weight-bold">
                              <td>{{ aLastAvg.cardMatch }}</td>
                              <td>{{ aLastAvg.cardDiff_yc }}</td>
                              <td>{{ aLastAvg.cardTotal_yc }}</td>
                              <td>{{ aLastAvg.cardT1_yc }}</td>
                              <td>{{ aLastAvg.cardT2_yc }}</td>
                              <td>{{ aLastAvg.cardDiff_rc }}</td>
                              <td>{{ aLastAvg.cardTotal_rc }}</td>
                              <td>{{ aLastAvg.cardT1_rc }}</td>
                              <td>{{ aLastAvg.cardT2_rc }}</td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                        
                        <div class="curPointer pt-5 pb-2">
                          <a :class="{'d-none' : awayPage == aLastPage}" class="ml-1 mr-4" @click="awayPrev()">{{ $t('previous') }}</a>
                          <a :class="{'d-none' : !awayPage}" @click="awayNext()">{{ $t('next') }}</a>
                        </div>

                        <v-simple-table v-if="lastAwayLoaded" class="cell-py-10 border-lightgrey" style="border-radius: 0">
                          <thead>
                            <tr>
                              <th>{{ $t('date') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('referee') }}</th>
                              <th>{{ $t('home') }}</th>
                              <th></th>
                              <th class="text-right">{{ $t('away') }}</th>
                              <th class="text-center">{{ $t('detail') }}</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-for="(game, index) in Alast[awayPage]"
                              :key="index"
                              :class="{'curPointer': (game.local_cards.yellow != -1)}"
                              @click="cardModal(index, game.local_id, game.local_name, game.visitor_name, 2)">
                              <td class="overflow-anywhere blue-grey--text">
                                <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                                <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="overflow-anywhere">
                                <router-link :to="{name: 'league', params: {lid: game.league.league_id}}" class="blue-grey--text">
                                  {{ game.league.name }}
                                </router-link>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="blue-grey--text">
                                {{ game.round ? game.round.name : '' }}
                              </td>

                              <td
                                :class="{'d-none': $vuetify.breakpoint.xs}"
                                class="blue-grey--text"
                                style="text-decoration-line: underline;"
                                @click.stop="game.referee ? modal4RefereeStats(game.referee) : ''">
                                {{ game.referee_name }}
                              </td>

                              <td v-if="game.local_id != away_id">
                                <router-link :to="{name: 'team', params: {id: game.local_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.local_name }}
                                </router-link>
                              </td>

                              <td v-else class="overflow-anywhere black--text">{{ game.local_name }}</td>

                              <td class="blue-grey--text">
                                <div class="d-flex justify-space-around align-center subtitle-2">
                                  <div>
                                    <template 
                                      v-if="game.local_cards.yellow == -1">
                                      ?
                                    </template>

                                    <template v-else>
                                      <span class="team_1_yellow_cards">{{ game.local_cards.yellow }}</span><br>
                                      <span class="team_1_red_cards">{{ game.local_cards.red }}</span>
                                    </template>
                                  </div>

                                  <div style="min-width: 11px;">
                                    <img src="/img/icons/yellow_card.png"><br>
                                    <img src="/img/icons/red_card.png">
                                  </div>

                                  <div>
                                    <template 
                                      v-if="game.local_cards.yellow == -1">
                                      ?
                                    </template>

                                    <template v-else>
                                      <span class='team_2_yellow_cards'>{{ game.visitor_cards.yellow }}</span><br>
                                      <span class='team_2_red_cards'>{{ game.visitor_cards.red }}</span>
                                    </template>
                                  </div>
                                </div>
                              </td>

                              <td v-if="game.visitor_id != away_id" class="text-right">
                                <router-link :to="{name: 'team', params: {id: game.visitor_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.visitor_name }}
                                </router-link>
                              </td>

                              <td v-else class="overflow-anywhere text-right black--text">{{ game.visitor_name }}</td>

                              <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                            </tr>
                          </tbody>
                        </v-simple-table>

                        <div v-else>
                          <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                        </div>
                      </div>
                    </template>
                  </template>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item key="Stats">
              <v-row class="no-gutters" justify="center">
                <v-col lg="8" md="10">
                  <div class="center col-lg-6 col-md-7">
                    <v-select
                      v-model="chosenCategory"
                      :items="categories"
                      item-text="text"
                      item-value="index"
                      attach
                      hide-details
                      dense
                      :label="$t('choosecategory')"
                      outlined
                    />
                  </div>

                  <div class="game-nav">
                    <div class="game-nav__btn" :class="{'game-nav__btn_active' : flags.other==0}" @click="other_flag(0)">{{ $t('head2head') }}</div>
                    <div class="game-nav__btn" :class="{'game-nav__btn_active' : flags.other==1}" @click="other_flag(1)">{{ $t('lastmatches') }}</div>
                  </div>

                  <div v-if="!flags.other" class="mt-4">
                    <div v-if="!h2hLoaded" >
                      <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                    </div>

                    <div v-else>
                      <v-container fluid>
                        <div class="row d-flex">
                          <v-select
                            v-model="h2hChosenLeagues"
                            :items="h2hLeagues"
                            item-text="league_name"
                            item-value="league_id"
                            class="col-12 col-sm-7 col-lg-9"
                            attach
                            dense
                            :label="$t('tournament')"
                            hide-details
                            multiple
                          />
                          
                          <v-select
                            v-model="h2hChosenSeasons"
                            :items="h2hSeasons"
                            :label="$t('season')"
                            class="col-12 col-sm-5 col-lg-3"
                            attach
                            dense
                            hide-details
                            multiple
                          />
                        </div>

                        <div class="row d-flex flex-wrap justify-space-between">
                          <v-checkbox
                            v-model="h2hLocal"
                            color="blue"
                            hide-details
                            dense
                            :label="$t('home')"
                            />
                          
                          <v-checkbox
                            v-model="h2hVisitor"
                            color="blue"
                            hide-details
                            dense
                            :label="$t('away')"
                            />
                          
                          <v-select
                            v-model="h2hPerPage"
                            :items="h2hCountPerPage"
                            attach
                            dense
                            class="col-4 col-sm-2 col-md-2"
                            :hint="$t('countperpage')"
                          />

                          <v-text-field
                            v-model="h2hPPageByUser"
                            class="col-4 col-sm-2 col-md-2"
                            dense
                            :hint="$t('countperpage')"
                            type="number"
                            max=149
                            min=1
                            v-on:keyup.enter="h2hPerPageChange"
                          />
                        </div>
                      </v-container>

                      <v-simple-table
                        v-if="otherH2hM[this.chosenCategory]"
                        class="table-row-35 border-lightgrey text-center"
                        style="border-radius: 0">
                        <tbody>
                          <tr>
                            <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                            <td :title="$t('pts.tooltip')">{{ $t('pts.label') }}</td>
                            <td :title="$t('w.tooltip')">{{ $t('w.label') }}</td>
                            <td :title="$t('d.tooltip')">{{ $t('d.label') }}</td>
                            <td :title="$t('l.tooltip')">{{ $t('l.label') }}</td>
                            <td :title="$t('diff.tooltip')">{{ $t('diff.label') }}</td>
                            <td :title="$t('diff_av.tooltip')">{{ $t('diff_av.label') }}</td>
                            <td :title="$t('total_av.tooltip')">{{ $t('total_av.label') }}</td>
                            <td :title="$t('teamtotal_av.tooltip')">{{ home_team.team_name }}</td>
                            <td :title="$t('opponenttotal_av.tooltip')"> {{ away_team.team_name }}</td>
                          </tr>
                          
                          <tr class="font-weight-bold">
                            <td>{{ this.otherH2hM[this.chosenCategory] }}</td>
                            <td>{{ this.otherH2hP[this.chosenCategory] }}</td>
                            <td>{{ this.otherH2hW[this.chosenCategory] }}</td>
                            <td>{{ this.otherH2hD[this.chosenCategory] }}</td>
                            <td>{{ this.otherH2hL[this.chosenCategory] }}</td>
                            <td>{{ this.otherH2hDiff[this.chosenCategory] }}</td>
                            <td>{{ this.otherH2hD_av[this.chosenCategory] }}</td>
                            <td>{{ this.otherH2hT[this.chosenCategory] }}</td>
                            <td>{{ this.otherH2hT1[this.chosenCategory] }}</td>
                            <td>{{ this.otherH2hT2[this.chosenCategory] }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>

                      <div class="curPointer pt-5 pb-2">
                        <a :class="{'d-none' : h2hPage == h2hLastPage}" class="ml-1 mr-4" @click="h2hPrev()">{{ $t('previous') }}</a>
                        <a :class="{'d-none' : !h2hPage}" @click="h2hNext()">{{ $t('next') }}</a>
                      </div>

                      <v-simple-table class="table-row-35 cell-py-10 border-lightgrey" style="border-radius: 0">
                        <thead>
                          <tr>
                            <th>{{ $t('date') }}</th>
                            <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                            <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                            <th>{{ $t('home') }}</th>
                            <th></th>
                            <th class="text-right">{{ $t('away') }}</th>
                            <th class="text-center">{{ $t('detail') }}</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="(game, index) in CGC[h2hPage]" :key="index" class="blue-grey--text">
                            <td class="overflow-anywhere">
                              <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                              <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                            </td>

                            <td :class="{'d-none': $vuetify.breakpoint.xs}" class="overflow-anywhere">
                              <router-link :to="{name: 'league', params: {lid: game.league_id}}" class="blue-grey--text">
                                {{ game.league_name }}
                              </router-link>
                            </td>
                            
                            <td :class="{'d-none': $vuetify.breakpoint.xs}">{{ game.round_name ? game.round_name : '' }}</td>

                            <td class="overflow-anywhere">{{ game.local_name }}</td>
                            
                            <td
                              v-if="game.stats.length"
                              class="white--text"
                              :style="'background-color:' + game.stats[chosenCategory][2]">
                              <div class="d-flex justify-space-around align-center subtitle-2">
                                <span>{{ game.stats[chosenCategory][0] }}</span>
                                <span> - </span>
                                <span>{{ game.stats[chosenCategory][1] }}</span>
                              </div>
                            </td>

                            <td
                              v-else
                              class="white--text"
                              :style="'background-color:' + stats_color[1]">
                              <div class="d-flex justify-space-around align-center subtitle-2">
                                <span>?</span>
                                <span> - </span>
                                <span>?</span>
                              </div>
                            </td>

                            <td class="overflow-anywhere text-right">{{ game.visitor_name }}</td>
                            
                            <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </div>
                  </div>

                  <template v-else-if="flags.other==1">
                    <div v-if="!lastHomeLoaded && !lastAwayLoaded">
                      <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                    </div>

                    <div v-else-if="!authorization">
                      <v-alert
                        type="warning"
                        prominent
                      >
                        {{ $t(`message.${msg_last}`) }}
                      </v-alert>
                    </div>

                    <template v-else>
                      <div class="matches_heading">{{ home_team.team_name }} {{ $t('lastmatches') }}</div>

                      <div>
                        <v-container fluid>
                          <div v-if='homeLeagueLoaded' class="row d-flex">
                            <v-select
                              v-model="homeChosenLeagues"
                              :items="HLeagues"
                              item-text="league_name"
                              item-value="league_id"
                              class="col-12 col-sm-7 col-lg-9"
                              attach
                              dense
                              :label="$t('tournament')"
                              hide-details
                              multiple
                            />

                            <v-select
                              v-model="homeChosenSeasons"
                              :items="HSeasons"
                              :label="$t('season')"
                              class="col-12 col-sm-5 col-lg-3"
                              attach
                              dense
                              hide-details
                              multiple
                            />
                          </div>

                          <div class="row d-flex flex-wrap justify-space-between">
                            <v-checkbox
                              v-model="hLocal"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('home')"
                              />
                            
                            <v-checkbox
                              v-model="hVisitor"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('away')"
                              />

                            <v-select
                              v-model="homePerPage"
                              :items="countPerPage"
                              attach
                              dense
                              class="col-4 col-sm-2 col-md-2"
                              :hint="$t('countperpage')"
                            />

                            <v-text-field
                              v-model="hPPageByUser"
                              class="col-4 col-sm-2 col-md-2"
                              dense
                              :hint="$t('countperpage')"
                              type="number"
                              max=149
                              min=1
                              v-on:keyup.enter="homePerPageChange"
                            />
                          </div>
                        </v-container>

                        <v-simple-table
                          v-if="hLastAvgLoaded && hOtherLastAvg.match[chosenCategory]"
                          class="table-row-35 border-lightgrey text-center"
                          style="border-radius: 0">
                          <tbody>
                            <tr>
                              <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                              <td :title="$t('pts.tooltip')">{{ $t('pts.label') }}</td>
                              <td :title="$t('w.tooltip')">{{ $t('w.label') }}</td>
                              <td :title="$t('d.tooltip')">{{ $t('d.label') }}</td>
                              <td :title="$t('l.tooltip')">{{ $t('l.label') }}</td>
                              <td :title="$t('diff.tooltip')">{{ $t('diff.label') }}</td>
                              <td :title="$t('diff_av.tooltip')">{{ $t('diff_av.label') }}</td>
                              <td :title="$t('total_av.tooltip')">{{ $t('total_av.label') }}</td>
                              <td :title="$t('teamtotal_av.tooltip')">{{ home_team.team_name }}</td>
                              <td :title="$t('opponenttotal_av.tooltip')"> {{ $t('opponenttotal_av.label') }}</td>
                            </tr>
                            
                            <tr class="font-weight-bold">
                              <td>{{ hOtherLastAvg.match[chosenCategory] }}</td>
                              <td>{{ hOtherLastAvg.point[chosenCategory] }}</td>
                              <td>{{ hOtherLastAvg.win[chosenCategory] }}</td>
                              <td>{{ hOtherLastAvg.draw[chosenCategory] }}</td>
                              <td>{{ hOtherLastAvg.lost[chosenCategory] }}</td>
                              <td>{{ hOtherLastAvg.diff[chosenCategory] }}</td>
                              <td>{{ hOtherLastAvg.diff_av[chosenCategory] }}</td>
                              <td>{{ hOtherLastAvg.total[chosenCategory] }}</td>
                              <td>{{ hOtherLastAvg.t1[chosenCategory] }}</td>
                              <td>{{ hOtherLastAvg.t2[chosenCategory] }}</td>
                            </tr>
                          </tbody>
                        </v-simple-table>

                        <div class="curPointer pt-5 pb-2">
                          <a :class="{'d-none' : homePage == hLastPage}" class="ml-1 mr-4" @click="homePrev()">{{ $t('previous') }}</a>
                          <a :class="{'d-none' : !homePage}" @click="homeNext()">{{ $t('next') }}</a>
                        </div>

                        <v-simple-table v-if="lastHomeLoaded" class="table-row-35 cell-py-10 border-lightgrey" style="border-radius: 0">
                          <thead>
                            <tr>
                              <th>{{ $t('date') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                              <th>{{ $t('home') }}</th>
                              <th></th>
                              <th class="text-right">{{ $t('away') }}</th>
                              <th class="text-center">{{ $t('detail') }}</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-for="(game, index) in Hlast[homePage]" :key="index">
                              <td class="overflow-anywhere blue-grey--text">
                                <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                                <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="overflow-anywhere">
                                <router-link :to="{name: 'league', params: {lid: game.league.league_id}}" class="blue-grey--text">
                                  {{ game.league.name }}
                                </router-link>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="blue-grey--text">
                                {{ game.round ? game.round.name : '' }}
                              </td>

                              <td v-if="game.local_id != home_id">
                                <router-link :to="{name: 'team', params: {id: game.local_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.local_name }}
                                </router-link>
                              </td>

                              <td v-else class="overflow-anywhere black--text">{{ game.local_name }}</td>

                              <td
                                v-if="game.stats.length"
                                class="white--text"
                                :style="'background-color:' + stats_color[game.stats[chosenCategory][2]]">
                                <div class="d-flex justify-space-around align-center subtitle-2">
                                  <span>{{ game.stats[chosenCategory][0] }}</span>
                                  <span> - </span>
                                  <span>{{ game.stats[chosenCategory][1] }}</span>
                                </div>
                              </td>

                              <td
                                v-else
                                class="white--text"
                                :style="'background-color:' + stats_color[1]">
                                <div class="d-flex justify-space-around align-center subtitle-2">
                                  <span>?</span>
                                  <span> - </span>
                                  <span>?</span>
                                </div>
                              </td>
                              
                              <td v-if="game.visitor_id != home_id" class="text-right">
                                <router-link :to="{name: 'team', params: {id: game.visitor_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.visitor_name }}
                                </router-link>
                              </td>
                              
                              <td v-else class="overflow-anywhere text-right black--text">{{ game.visitor_name }}</td>
                              
                              <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                            </tr>
                          </tbody>
                        </v-simple-table>

                        <div v-else>
                          <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                        </div>
                      </div>

                      <div class="matches_heading">{{ away_team.team_name }} {{ $t('lastmatches') }}</div>

                      <div>
                        <v-container fluid>
                          <div v-if='awayLeagueLoaded' class="row d-flex">
                            <v-select
                              v-model="awayChosenLeagues"
                              :items="ALeagues"
                              item-text="league_name"
                              item-value="league_id"
                              class="col-12 col-sm-7 col-lg-9"
                              attach
                              dense
                              :label="$t('tournament')"
                              hide-details
                              multiple
                            />

                            <v-select
                              v-model="awayChosenSeasons"
                              :items="ASeasons"
                              :label="$t('season')"
                              class="col-12 col-sm-5 col-lg-3"
                              attach
                              dense
                              hide-details
                              multiple
                            />
                          </div>

                          <div class="row d-flex flex-wrap justify-space-between">
                            <v-checkbox
                              v-model="aLocal"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('home')"
                              />
                            
                            <v-checkbox
                              v-model="aVisitor"
                              color="blue"
                              hide-details
                              dense
                              :label="$t('away')"
                              />

                            <v-select
                              v-model="awayPerPage"
                              :items="countPerPage"
                              attach
                              dense
                              class="col-4 col-sm-2 col-md-2"
                              :hint="$t('countperpage')"
                            />

                            <v-text-field
                              v-model="aPPageByUser"
                              class="col-4 col-sm-2 col-md-2"
                              dense
                              :hint="$t('countperpage')"
                              type="number"
                              max=149
                              min=1
                              v-on:keyup.enter="awayPerPageChange"
                            />
                          </div>
                        </v-container>

                        <v-simple-table
                          v-if="aLastAvgLoaded && aOtherLastAvg.match[chosenCategory]"
                          class="table-row-35 border-lightgrey text-center"
                          style="border-radius: 0">
                          <tbody>
                            <tr>
                              <td :title="$t('m.tooltip')">{{ $t('m.label') }}</td>
                              <td :title="$t('pts.tooltip')">{{ $t('pts.label') }}</td>
                              <td :title="$t('w.tooltip')">{{ $t('w.label') }}</td>
                              <td :title="$t('d.tooltip')">{{ $t('d.label') }}</td>
                              <td :title="$t('l.tooltip')">{{ $t('l.label') }}</td>
                              <td :title="$t('diff.tooltip')">{{ $t('diff.label') }}</td>
                              <td :title="$t('diff_av.tooltip')">{{ $t('diff_av.label') }}</td>
                              <td :title="$t('total_av.tooltip')">{{ $t('total_av.label') }}</td>
                              <td :title="$t('teamtotal_av.tooltip')">{{ away_team.team_name }}</td>
                              <td :title="$t('opponenttotal_av.tooltip')"> {{ $t('opponenttotal_av.label') }}</td>
                            </tr>
                            
                            <tr class="font-weight-bold">
                              <td>{{ aOtherLastAvg.match[chosenCategory] }}</td>
                              <td>{{ aOtherLastAvg.point[chosenCategory] }}</td>
                              <td>{{ aOtherLastAvg.win[chosenCategory] }}</td>
                              <td>{{ aOtherLastAvg.draw[chosenCategory] }}</td>
                              <td>{{ aOtherLastAvg.lost[chosenCategory] }}</td>
                              <td>{{ aOtherLastAvg.diff[chosenCategory] }}</td>
                              <td>{{ aOtherLastAvg.diff_av[chosenCategory] }}</td>
                              <td>{{ aOtherLastAvg.total[chosenCategory] }}</td>
                              <td>{{ aOtherLastAvg.t1[chosenCategory] }}</td>
                              <td>{{ aOtherLastAvg.t2[chosenCategory] }}</td>
                            </tr>
                          </tbody>
                        </v-simple-table>

                        <div class="curPointer pt-5 pb-2">
                          <a :class="{'d-none' : awayPage == aLastPage}" class="ml-1 mr-4" @click="awayPrev()">{{ $t('previous') }}</a>
                          <a :class="{'d-none' : !awayPage}" @click="awayNext()">{{ $t('next') }}</a>
                        </div>

                        <v-simple-table v-if="lastAwayLoaded" class="table-row-35 cell-py-10 border-lightgrey" style="border-radius: 0">
                          <thead>
                            <tr>
                              <th>{{ $t('date') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('league') }}</th>
                              <th :class="{'d-none': $vuetify.breakpoint.xs}">{{ $t('rd') }}</th>
                              <th>{{ $t('home') }}</th>
                              <th></th>
                              <th class="text-right">{{ $t('away') }}</th>
                              <th class="text-center">{{ $t('detail') }}</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-for="(game, index) in Alast[awayPage]" :key="index">
                              <td class="overflow-anywhere blue-grey--text">
                                <template v-if="$vuetify.breakpoint.xs">{{ game.date | moment('DD/MM/YY') }}</template>
                                <template v-else>{{ game.date | moment('DD/MM/YYYY') }}</template>
                              </td>

                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="overflow-anywhere">
                                <router-link :to="{name: 'league', params: {lid: game.league.league_id}}" class="blue-grey--text">
                                  {{ game.league.name }}
                                </router-link>
                              </td>
                              
                              <td :class="{'d-none': $vuetify.breakpoint.xs}" class="blue-grey--text">
                                {{ game.round ? game.round.name : '' }}
                              </td>
                              
                              <td v-if="game.local_id != away_id">
                                <router-link :to="{name: 'team', params: {id: game.local_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.local_name }}
                                </router-link>
                              </td>
                              
                              <td v-else class="overflow-anywhere black--text">{{ game.local_name }}</td>
                              
                              <td
                                v-if="game.stats.length"
                                class="white--text"
                                :style="'background-color:' + stats_color[game.stats[chosenCategory][2]]">
                                <div class="d-flex justify-space-around align-center subtitle-2">
                                  <span>{{ game.stats[chosenCategory][0] }}</span>
                                  <span> - </span>
                                  <span>{{ game.stats[chosenCategory][1] }}</span>
                                </div>
                              </td>
                              
                              <td
                                v-else
                                class="white--text"
                                :style="'background-color:' + stats_color[1]">
                                <div class="d-flex justify-space-around align-center subtitle-2">
                                  <span>?</span>
                                  <span> - </span>
                                  <span>?</span>
                                </div>
                              </td>
                              
                              <td v-if="game.visitor_id != away_id" class="text-right">
                                <router-link :to="{name: 'team', params: {id: game.visitor_id}}" class="overflow-anywhere blue-grey--text">
                                  {{ game.visitor_name }}
                                </router-link>
                              </td>
                              
                              <td v-else class="overflow-anywhere text-right black--text">{{ game.visitor_name }}</td>
                              
                              <td class="curPointer text-center" :title="$t('matchdetails')" @click.stop="matchDetail(game.match_id)"><v-icon color="success">mdi-soccer-field</v-icon></td>
                            </tr>
                          </tbody>
                        </v-simple-table>

                        <div v-else>
                          <div class="ripple_loading"><img src="/img/icons/Ripple-1s-200px.png"></div>
                        </div>
                      </div>
                    </template>
                  </template>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </template>
    </div>
  </v-app>
</template>

<script>
import { Card, Tabs, TabPane } from '@/components'
import moment from 'moment-timezone'
import axios from 'axios'
import colors from 'vuetify/lib/util/colors'

export default {
  components: {
    Card,
    Tabs,
    TabPane,
  },
  data() {
      return {
        home_id: this.$route.params.hid,
        away_id: this.$route.params.aid,
        home_team: null,
        away_team: null,
        tz: localStorage.tz ? localStorage.tz : '+0',
        
        // h2h stats
        cornerData: {},
        goalData: {},
        cardData: {},
        h2h: [],
        CGC: [],
        originCGC: [],
        filteredCGC: [],

        // last stats
        Hlast: [],
        Alast: [],
        hLastAvg: {},
        aLastAvg: {},

        subCorner: {
          dt: '',
          home: '',
          away: '',
          data: [],
        },
        subCard: {
          dt: '',
          home: '',
          away: '',
          data: [],
        },
        subGoal: {
          dt: '',
          home: '',
          away: '',
          data: [],
        },

        basicLoaded: false,
        h2hLoaded: false,
        lastHomeLoaded: false,
        lastAwayLoaded: false,
        hLastAvgLoaded: false,
        aLastAvgLoaded: false,

        // Modal Dialogs
        subCornerDialog: false,
        subGoalDialog: false,
        subCardDialog: false,
        refereeDialog: false,

        homePage: 0,
        awayPage: 0,
        h2hPage: 0,
        // similar home & away page
        homePerPage: 20,
        awayPerPage: 20,
        h2hPerPage: 20,
        hPPageByUser: '',
        aPPageByUser: '',
        h2hPPageByUser: '',
        // similar home & away per page
        hLastPage: -1,
        aLastPage: -1,
        h2hLastPage: -1,
        
        hLocal: true,
        hVisitor: true,
        aLocal: true,
        aVisitor: true,
        h2hLocal: true,
        h2hVisitor: true,
        homeLeagueLoaded: false,
        awayLeagueLoaded: false,
        hQuerySeasons: '',
        aQuerySeasons: '',
        homeChosenLeagues: [],
        awayChosenLeagues: [],
        h2hChosenLeagues: [],
        homeChosenSeasons: [],
        awayChosenSeasons: [],
        h2hChosenSeasons: [],
        HLeagues: [],
        ALeagues: [],
        h2hLeagues: [],
        HSeasons: [],
        ASeasons: [],
        h2hSeasons: [],
        HTotalSeasons: [],
        ATotalSeasons: [],
        h2hTotalSeasons: [],

        substitutionLoaded: false,
        tab: null,
        items: ['goals', 'corners', 'cards', 'other'],
        flags: {
            corner: 0,
            goal: 0,
            card: 0,
            other: 0,
          },
        msg_last: 'notlogin',
        authorization: false,
        stats_color: [
          '#28a745',
          '#ffc107',
          '#dc3545'
        ],

        chosenReferee: null,
        refereeStats: new Map(),

        // begin of 'other' tab
        chosenCategory: 0,
        
        otherH2hM: [],
        otherH2hP: [],
        otherH2hW: [],
        otherH2hD: [],
        otherH2hL: [],
        otherH2hDiff: [],
        otherH2hD_av: [],
        otherH2hT: [],
        otherH2hT1: [],
        otherH2hT2: [],

        hOtherLastAvg: {},
        aOtherLastAvg: {},

        // begin of half / full time combobox
        h2hFullHalf: 0,
        hLastFullHalf: 0,
        aLastFullHalf: 0,
        timeCategories: [
          {text: 'fulltime', index: 0},
          {text: 'half1', index: 1},
          {text: 'half2', index: 2}],
        // end of half / full time combobox
      }
  },
  computed: {
    countPerPage () {
      return [20, 30, 40, 60, 80, this.$t('all')]
    },

    h2hCountPerPage () {
      return [5, 10, 20, 30, 50, this.$t('all')]
    },

    categories () {
      return [
        {text: this.$t('stats_type.SHOTS_TOTAL'), index: 0},
        {text: this.$t('stats_type.SHOTS_ON_TARGET'), index: 1},
        {text: this.$t('stats_type.SHOTS_BLOCKED'), index: 2},
        {text: this.$t('stats_type.SHOTS_INSIDEBOX'), index: 3},
        {text: this.$t('stats_type.ATTACKS'), index: 4},
        {text: this.$t('stats_type.DANGEROUS_ATTACKS'), index: 5},
        {text: this.$t('stats_type.PASSES_TOTAL'), index: 6},
        {text: this.$t('stats_type.PASSES_ACCURATE'), index: 7},
        {text: this.$t('stats_type.BALL_SAFE'), index: 8},
        {text: this.$t('stats_type.FOULS'), index: 9},
        {text: this.$t('stats_type.FREE_KICKS'), index: 10},
        {text: this.$t('stats_type.GOAL_ATTEMPTS'), index: 11},
        {text: this.$t('stats_type.GOAL_KICKS'), index: 12},
        {text: this.$t('stats_type.INJURIES'), index: 13},
        {text: this.$t('stats_type.OFFSIDES'), index: 14},
        {text: this.$t('stats_type.PENALTIES'), index: 15},
        {text: this.$t('stats_type.BALL_POSSESSION'), index: 16},
        {text: this.$t('stats_type.SAVES'), index: 17},
        {text: this.$t('stats_type.SUBSTITUTIONS'), index: 18},
        {text: this.$t('stats_type.TACKLES'), index: 19},
        {text: this.$t('stats_type.THROWINS'), index: 20}
      ]
    }
  },
  mounted() {
      this.getBasics()
  },
  watch: {
    homePerPage: function (val) {
      this.homePage = 0
      this.hLastPage = -1
      this.Hlast = []
      this.lastHomeLoaded = false
    
      this.getLast(1)
    },

    awayPerPage: function (val) {
      this.awayPage = 0
      this.aLastPage = -1
      this.Alast = []
      this.lastAwayLoaded = false
    
      this.getLast(2)
    },
    
    h2hPerPage: function (val) {
      this.sliceH2h()
    },

    homeChosenLeagues: function (val) {
      this.changeQSeasons(1)
    },

    homeChosenSeasons: function (val) {
      this.changeQSeasons(1)
    },

    awayChosenLeagues: function (val) {
      this.changeQSeasons(2)
    },

    awayChosenSeasons: function (val) {
      this.changeQSeasons(2)
    },

    h2hChosenLeagues: function (val) {
      this.changeH2HFilter()
    },

    h2hChosenSeasons: function (val) {
      this.changeH2HFilter()
    },

    hLocal (val) {
      if (!val && !this.hVisitor) {
        this.hLocal = !val
      }else {
        this.homePage = 0
        this.hLastPage = -1
        this.Hlast = []
        this.lastHomeLoaded = false

        this.getLast(1)
      }
    },
    
    hVisitor (val) {
      if (!val && !this.hLocal) {
        this.hVisitor = !val
      }else {
        this.homePage = 0
        this.hLastPage = -1
        this.Hlast = []
        this.lastHomeLoaded = false

        this.getLast(1)
      }
    },    

    aLocal (val) {
      if (!val && !this.aVisitor) {
        this.aLocal = !val
      }else {
        this.awayPage = 0
        this.aLastPage = -1
        this.Alast = []
        this.lastAwayLoaded = false

        this.getLast(2)
      }
    },
    
    aVisitor (val) {
      if (!val && !this.aLocal) {
        this.aVisitor = !val
      }else {
        this.awayPage = 0
        this.aLastPage = -1
        this.Alast = []
        this.lastAwayLoaded = false

        this.getLast(2)
      }
    },

    h2hLocal (val) {
      this.changeH2HFilter()
    },

    h2hVisitor (val) {
      this.changeH2HFilter()
    },

    h2hFullHalf (val) {
      this.h2hLoaded = false
      this.originCGC = []
      this.filteredCGC = []
      this.get_cgc()
      this.changeH2HFilter()
    },

    hLastFullHalf (val) {
      this.homePage = 0
      this.hLastPage = -1
      this.Hlast = []
      this.lastHomeLoaded = false

      this.getLast(1)
    },

    aLastFullHalf (val) {
      this.awayPage = 0
      this.aLastPage = -1
      this.Alast = []
      this.lastAwayLoaded = false

      this.getLast(2)
    },

  },
  methods: {
      async getBasics() {
        //Etc/GMT-X will have an offset of +X and Etc/GMT+X will have an offset of -X.
        moment.tz.setDefault('Etc/GMT' + this.tz)

        axios
          .get('/compare/' + this.home_id + '/' + this.away_id)
          .then(response => {
            // check if api sent none match data
            if (!response.data.error) {
              this.home_team = response.data.home
              this.away_team = response.data.away

              this.basicLoaded = true
              this.getH2H()
            } else if (response.data.error == 1) {

            }
          })
          //.catch(error => {})
      },

      async getH2H() {
        axios
          .get('/match_h2h/' + this.home_id + '/' + this.away_id)
          .then(response => {
            this.h2h = response.data.data
            this.get_cgc()
            this.h2hLoaded = true
          })
          //.catch(error => {})
      },

      get_cgc() {
        let seasonIds = [], leagueIds = []
        let index = 0
        this.h2h.forEach((game) => {
            let item = {
                index: index,
                match_id: game.match_id,
                date: game.start_dt,
                local_id: game.local_id,
                local_name: game.local_name,
                visitor_id: game.visitor_id,
                visitor_name: game.visitor_name,
                league_id: game.league_id,
                league_name: game.league_name,
                season_id: game.season_id,
                round_name: game.round_name,
                local_coach: game.local_coach_id,
                visitor_coach: game.visitor_coach_id,
                local_corners: 0,
                visitor_corners: 0,
                local_goals: 0,
                visitor_goals: 0,
                corners_bgcolor: '',
                goals_bgcolor: '',
                local_cards: [0, 0, 0],
                visitor_cards: [0, 0, 0],
                referee: '',
                referee_id: '',
                stats: [], // other stats
            }

            if (!this.h2hFullHalf) {
              item.local_goals = game.scores.localteam_score
              item.visitor_goals = game.scores.visitorteam_score
            } else {
              let ht = (game.scores.ht_score) ? game.scores.ht_score.split('-') : [0, 0]
              if (this.h2hFullHalf == 1) {
                item.local_goals = Number(ht[0])
                item.visitor_goals = Number(ht[1])
              } else if (this.h2hFullHalf == 2) {
                item.local_goals = game.scores.localteam_score - Number(ht[0])
                item.visitor_goals = game.scores.visitorteam_score - Number(ht[1])
              }
            }

            index++

            if (game.corners) {
              game.corners.data.forEach((evt) => {
                if (!evt.comment.includes("Race")) { 
                  if (evt.comment != 'X' && 
                    (this.h2hFullHalf == 0 || (this.h2hFullHalf == 1 && Number(evt.minute) < 46) || (this.h2hFullHalf == 2 && Number(evt.minute) > 45))) {
                    if (evt.team_id == game.local_id) item.local_corners++
                    else if(evt.team_id == game.visitor_id) item.visitor_corners++
                  }
                }else {
                  evt.comment='X'
                }
              })
            }

            if (((game.local_id == this.home_id) && (item.local_corners > item.visitor_corners)) ||
                ((game.local_id == this.away_id) && (item.local_corners < item.visitor_corners))) {
                  item.corners_bgcolor = this.stats_color[0]
            }else if (item.local_corners == item.visitor_corners){
              item.corners_bgcolor = this.stats_color[1]
            }else {
              item.corners_bgcolor = this.stats_color[2]
            }

            if (((game.local_id == this.home_id) && (item.local_goals > item.visitor_goals)) ||
              ((game.local_id == this.away_id) && (item.local_goals < item.visitor_goals))) {
                item.goals_bgcolor = this.stats_color[0]
            }else if (item.local_goals == item.visitor_goals){
              item.goals_bgcolor = this.stats_color[1]
            }else {
              item.goals_bgcolor = this.stats_color[2]
            }

            let cards_exists = 0
            if (game.cards) {
              game.cards.data.forEach((evt) => {
                cards_exists = 1
                if (evt.minute <= 90 && (this.h2hFullHalf == 0 || (this.h2hFullHalf == 1 && Number(evt.minute) < 46) || (this.h2hFullHalf == 2 && Number(evt.minute) > 45))) {
                  if (evt.team_id == game.local_id) {
                    if (evt.type == 'yellowcard') item.local_cards[0]++
                    else if (evt.type == 'redcard') item.local_cards[1]++
                    else if (evt.type == 'yellowred') item.local_cards[2]++
                  }else {
                    if (evt.type == 'yellowcard') item.visitor_cards[0]++
                    else if (evt.type == 'redcard') item.visitor_cards[1]++
                    else if (evt.type == 'yellowred') item.visitor_cards[2]++
                  }
                }
              })
            }
            // check if wrong cards data
            if (!cards_exists && game.stats) {
              if (this.h2hFullHalf == 0) {
                game.stats.data.forEach((t) => {
                  if (t.team_id == game.local_id){
                    if (t.yellowcards) item.local_cards[0] += t.yellowcards
                    if (t.redcards) item.local_cards[1] += t.yellowcards
                    if (t.yellowredcards) item.local_cards[2] += t.yellowcards
                  }else {
                    if (t.yellowcards) item.visitor_cards[0] += t.yellowcards
                    if (t.redcards) item.visitor_cards[1] += t.yellowcards
                    if (t.yellowredcards) item.visitor_cards[2] += t.yellowcards
                  }
                })
              }
              
              // if cards empty [] and stats cards also 0, set cards wrong data -1 for showing '?'
              if (item.local_cards[0] == 0 && item.local_cards[1] == 0 && item.local_cards[2] == 0
              && item.visitor_cards[0] == 0 && item.visitor_cards[1] == 0 && item.visitor_cards[2] == 0) {
                item.local_cards[0] = -1
              }
            }

            if (game.referee_id != null) {
              item.referee = game.referee_common_name
              item.referee_id = game.referee_id
            }

            // update h2h league-season info
            if (!seasonIds.includes(game.season_id)) {
              seasonIds.push(game.season_id)

              if (!this.h2hSeasons.includes(game.season_name))
                this.h2hSeasons.push(game.season_name)

              if (!leagueIds.includes(game.league_id)) {
                leagueIds.push(game.league_id)

                this.h2hLeagues.push({league_id: game.league_id, league_name: game.league_name})
              }

              this.h2hTotalSeasons.push({
                season_id: game.season_id,
                season_name: game.season_name,
                league_id: game.league_id,
                league_name: game.league_name})
            }

            if (game.stats && game.stats.data.length != 0) {
              // stats[0] : total shots
              if (game.stats.data[0].shots == null || game.stats.data[1].shots == null) {
                item.stats.push(['?', '?', this.stats_color[1]])
                item.stats.push(['?', '?', this.stats_color[1]])
                item.stats.push(['?', '?', this.stats_color[1]])
                item.stats.push(['?', '?', this.stats_color[1]])
              } else {
                item.stats.push(this.paint_color(game.local_id, game.stats.data[0].shots.total, game.stats.data[1].shots.total))
                item.stats.push(this.paint_color(game.local_id, game.stats.data[0].shots.ongoal, game.stats.data[1].shots.ongoal))
                item.stats.push(this.paint_color(game.local_id, game.stats.data[0].shots.blocked, game.stats.data[1].shots.blocked))
                item.stats.push(this.paint_color(game.local_id, game.stats.data[0].shots.insidebox, game.stats.data[1].shots.insidebox))
              }

              // stats[4] : attacks
              if (game.stats.data[0].attacks == null || game.stats.data[1].attacks == null) {
                item.stats.push(['?', '?', this.stats_color[1]])
                item.stats.push(['?', '?', this.stats_color[1]])
              } else {
                item.stats.push(this.paint_color(game.local_id, game.stats.data[0].attacks.attacks, game.stats.data[1].attacks.attacks))
                item.stats.push(this.paint_color(game.local_id, game.stats.data[0].attacks.dangerous_attacks, game.stats.data[1].attacks.dangerous_attacks))
              }

              // stats[6] : passes total
              if (game.stats.data[0].passes == null || game.stats.data[1].passes == null) {
                item.stats.push(['?', '?', this.stats_color[1]])
                item.stats.push(['?', '?', this.stats_color[1]])
              } else {
                item.stats.push(this.paint_color(game.local_id, game.stats.data[0].passes.total, game.stats.data[1].passes.total))
                item.stats.push(this.paint_color(game.local_id, game.stats.data[0].passes.accurate, game.stats.data[1].passes.accurate))
              }
              
              // stats[8] : ball safe
              item.stats.push(this.paint_color(game.local_id, game.stats.data[0].ball_safe, game.stats.data[1].ball_safe))

              // stats[9] : fouls
              item.stats.push(this.paint_color(game.local_id, game.stats.data[0].fouls, game.stats.data[1].fouls))

              // stats[10] : free kick
              item.stats.push(this.paint_color(game.local_id, game.stats.data[0].free_kick, game.stats.data[1].free_kick))

              // stats[11] : goal attempts
              item.stats.push(this.paint_color(game.local_id, game.stats.data[0].goal_attempts, game.stats.data[1].goal_attempts))

              // stats[12] : goal kick
              item.stats.push(this.paint_color(game.local_id, game.stats.data[0].goal_kick, game.stats.data[1].goal_kick))

              // stats[13] : injuries
              item.stats.push(this.paint_color(game.local_id, game.stats.data[0].injuries, game.stats.data[1].injuries))

              // stats[14] : offsides
              item.stats.push(this.paint_color(game.local_id, game.stats.data[0].offsides, game.stats.data[1].offsides))

              // stats[15] : penalties
              item.stats.push(this.paint_color(game.local_id, game.stats.data[0].penalties, game.stats.data[1].penalties))

              // stats[16] : possession time
              item.stats.push(this.paint_color(game.local_id, game.stats.data[0].possessiontime, game.stats.data[1].possessiontime))

              // stats[17] : saves
              item.stats.push(this.paint_color(game.local_id, game.stats.data[0].saves, game.stats.data[1].saves))

              // stats[18] : substitutions
              item.stats.push(this.paint_color(game.local_id, game.stats.data[0].substitutions, game.stats.data[1].substitutions))

              // stats[19] : tackles
              if (game.stats.data[0].tackles == null || game.stats.data[1].tackles == null) {
                item.stats.push(['?', '?', this.stats_color[1]])
              } else {
                item.stats.push(this.paint_color(game.local_id, game.stats.data[0].tackles, game.stats.data[1].tackles))
              }

              // stats[20] : throw in
              item.stats.push(this.paint_color(game.local_id, game.stats.data[0].throw_in, game.stats.data[1].throw_in))

            }

            this.originCGC.push(item)

        })

        this.filteredCGC = this.originCGC
        this.sliceH2h()

      },

      paint_color(local, val1, val2) {
        let a = (val1 == null) ? '?' : val1
        let b = (val2 == null) ? '?' : val2
        let col = this.stats_color[2]

        if (a == '?' || b == '?') {
          a = '?'
          b = '?'
          col = this.stats_color[1]
        } else if (a == b) {
          col = this.stats_color[1]
        } else if (((local == this.home_id) && (a > b)) ||
          ((local == this.away_id) && (a < b))) {
            col = this.stats_color[0]
        }

        return [a, b, col]
      },

      sliceH2h() {
        // slice filteredCGC array by h2hPerPage, get slice and calculate avg stats from it.
        
        this.CGC = []
        this.cornerData = {
          matches: 0,
          points: 0,
          win: 0,
          draw: 0,
          lost: 0,
          diff: 0,
          diff_av: 0,
          totalAvg: 0,
          homeTotalAvg: 0,
          awayTotalAvg: 0,
        }
        this.goalData = {
          matches: 0,
          points: 0,
          win: 0,
          draw: 0,
          lost: 0,
          diff: 0,
          diff_av: 0,
          totalAvg: 0,
          homeTotalAvg: 0,
          awayTotalAvg: 0,
        }
        this.cardData = {
          matches: 0,
          diff_yc: 0,
          diff_rc: 0,
          totalYC: 0,
          homeTotalYC: 0,
          awayTotalYC: 0,
          totalRC: 0,
          homeTotalRC: 0,
          awayTotalRC: 0,
        }

        this.otherH2hM = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        this.otherH2hP = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        this.otherH2hW = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        this.otherH2hD = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        this.otherH2hL = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        this.otherH2hDiff = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        this.otherH2hD_av = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        this.otherH2hT = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        this.otherH2hT1 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        this.otherH2hT2 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

        this.h2hLastPage = -1
        if (this.filteredCGC.length) {
          let homeVal = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          let awayVal = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

          let perpage = 0
          if (this.h2hPerPage == this.$t('all')) perpage = this.filteredCGC.length
          else perpage = Number(this.h2hPerPage)

          var i, j=this.filteredCGC.length, temporary
          for (i=0; i<j; i+=perpage) {
              temporary = this.filteredCGC.slice(i,i+perpage)
              this.CGC.push(temporary)
              this.h2hLastPage += 1
          }
          
          // from here, get avg stats on CGC[0]
          
          let homeCorners = 0, awayCorners = 0
          let homeGoals = 0, awayGoals = 0
          let homeYC = 0, homeRC = 0, awayYC = 0, awayRC = 0

          this.CGC[0].forEach((item) => {
            if (item.corners_bgcolor == this.stats_color[0]) {
              this.cornerData.win++
            } else if (item.corners_bgcolor == this.stats_color[2]){
              this.cornerData.lost++
            } else if (item.local_corners != 0) {
              this.cornerData.draw++
            }

            if (item.goals_bgcolor == this.stats_color[0]) {
              this.goalData.win++
            } else if (item.goals_bgcolor == this.stats_color[2]){
              this.goalData.lost++
            } else {
              this.goalData.draw++
            }

            if (item.local_id == this.home_id) {
              homeCorners += item.local_corners
              awayCorners += item.visitor_corners
              
              homeGoals += item.local_goals
              awayGoals += item.visitor_goals

              if (item.local_cards[0] != -1) {
                homeYC += item.local_cards[0]
                homeRC += (item.local_cards[1] + item.local_cards[2])
                awayYC += item.visitor_cards[0]
                awayRC += (item.visitor_cards[1] + item.visitor_cards[2])
              }
            } else {
              homeCorners += item.visitor_corners
              awayCorners += item.local_corners

              homeGoals += item.visitor_goals
              awayGoals += item.local_goals

              if (item.local_cards[0] != -1) {
                homeYC += item.visitor_cards[0]
                homeRC += (item.visitor_cards[1] + item.visitor_cards[2])
                awayYC += item.local_cards[0]
                awayRC += (item.local_cards[1] + item.local_cards[2])
              }
            }

            // remove 0 - 0 cards matches
            if (item.local_cards[0] != -1) {
              this.cardData.matches++
            }

            // calculate avg for other tab
            if (item.stats.length) {
              for(i = 0; i < 21; i++) {
                if (item.stats[i][0] != '?' && item.stats[i][1] != '?') {
                  if (item.stats[i][2] == this.stats_color[0]) {
                    this.otherH2hW[i]++
                  } else if (item.stats[i][2] == this.stats_color[2]){
                    this.otherH2hL[i]++
                  } else {
                    this.otherH2hD[i]++
                  }
                  
                  if (item.local_id == this.home_id) {
                    homeVal[i] += item.stats[i][0]
                    awayVal[i] += item.stats[i][1]
                  } else {
                    homeVal[i] += item.stats[i][1]
                    awayVal[i] += item.stats[i][0]
                  }

                  this.otherH2hM[i]++
                }
              }
            }

          })

          // store corner data
          this.cornerData.matches = this.cornerData.win + this.cornerData.draw + this.cornerData.lost
          this.cornerData.points = (this.cornerData.win * 3) + this.cornerData.draw
          this.cornerData.diff = homeCorners + ' - ' + awayCorners
          this.cornerData.diff_av = !this.cornerData.matches ? 0 : ((homeCorners - awayCorners) / this.cornerData.matches).toFixed(2)
          this.cornerData.totalAvg = !this.cornerData.matches ? 0 : ((homeCorners + awayCorners) / this.cornerData.matches).toFixed(2)
          this.cornerData.homeTotalAvg = !this.cornerData.matches ? 0 : (homeCorners / this.cornerData.matches).toFixed(2)
          this.cornerData.awayTotalAvg = !this.cornerData.matches ? 0 : (awayCorners / this.cornerData.matches).toFixed(2)

          // store goal data
          this.goalData.matches = this.goalData.win + this.goalData.draw + this.goalData.lost
          this.goalData.points = (this.goalData.win * 3) + this.goalData.draw
          this.goalData.diff = homeGoals + ' - ' + awayGoals
          this.goalData.diff_av = !this.goalData.matches ? 0 : ((homeGoals - awayGoals) / this.goalData.matches).toFixed(2)
          this.goalData.totalAvg = !this.goalData.matches ? 0 : ((homeGoals + awayGoals) / this.goalData.matches).toFixed(2)
          this.goalData.homeTotalAvg = !this.goalData.matches ? 0 : (homeGoals / this.goalData.matches).toFixed(2)
          this.goalData.awayTotalAvg = !this.goalData.matches ? 0 : (awayGoals / this.goalData.matches).toFixed(2)

          // store card data
          this.cardData.diff_yc = homeYC + ' - ' + awayYC
          this.cardData.totalYC = !this.cardData.matches ? 0 : ((homeYC + awayYC) / this.cardData.matches).toFixed(2)
          this.cardData.homeTotalYC = !this.cardData.matches ? 0 : (homeYC / this.cardData.matches).toFixed(2)
          this.cardData.awayTotalYC = !this.cardData.matches ? 0 : (awayYC / this.cardData.matches).toFixed(2)
          this.cardData.diff_rc = homeRC + ' - ' + awayRC
          this.cardData.totalRC = !this.cardData.matches ? 0 : ((homeRC + awayRC) / this.cardData.matches).toFixed(2)
          this.cardData.homeTotalRC = !this.cardData.matches ? 0 : (homeRC / this.cardData.matches).toFixed(2)
          this.cardData.awayTotalRC = !this.cardData.matches ? 0 : (awayRC / this.cardData.matches).toFixed(2)

          // store avg for other tab
          for (i = 0; i < 21; i++) {
            this.otherH2hP[i] = (this.otherH2hW[i] * 3) + this.otherH2hD[i]
            this.otherH2hDiff[i] = homeVal[i] + '-' + awayVal[i]
            this.otherH2hD_av[i] = !this.otherH2hM[i] ? 0 : ((homeVal[i] - awayVal[i]) / this.otherH2hM[i]).toFixed(2)
            this.otherH2hT[i] = !this.otherH2hM[i] ? 0 : ((homeVal[i] + awayVal[i]) / this.otherH2hM[i]).toFixed(2)
            this.otherH2hT1[i] = !this.otherH2hM[i] ? 0 : (homeVal[i] / this.otherH2hM[i]).toFixed(2)
            this.otherH2hT2[i] = !this.otherH2hM[i] ? 0 : (awayVal[i] / this.otherH2hM[i]).toFixed(2)
          }
        } else {
          this.h2hLastPage = 0
        }

        this.h2hLoaded = true
      },

      changeH2HFilter() {
        // filter origin
        this.h2hPage = 0
        this.filteredCGC = this.queryH2HFilter()
        this.sliceH2h()
      },

      // filter originCGC by league, season, coach or home/away
      queryH2HFilter() {
        let temp = []
        
        // filter by seasons
        let querys = []
        this.h2hTotalSeasons.forEach((item) => {
          let leagues = this.h2hChosenLeagues.filter(league => league == item.league_id )
            let seasons = this.h2hChosenSeasons.filter(season => season == item.season_name )
            if((this.h2hChosenLeagues.length == 0 || leagues.length > 0) && (this.h2hChosenSeasons.length == 0 || seasons.length > 0))
              querys.push(item.season_id)
          })
        if(querys.length > 0) {
          temp = this.originCGC.filter(game => querys.includes(game.season_id))
        }

        // filter by home/away
        if ((!this.h2hLocal && this.h2hVisitor) || (this.h2hLocal && !this.h2hVisitor)) {
          if (this.h2hLocal) {
            temp = temp.filter( game => game.local_id == this.home_id )
          }else {
            temp = temp.filter( game => game.local_id != this.home_id )
          }

          if (this.h2hVisitor) {
            temp = temp.filter( game => game.visitor_id == this.home_id )
          }else {
            temp = temp.filter( game => game.visitor_id != this.home_id )
          }
        }

        return temp
      },

      changeQSeasons(flag) {
        if (flag == 1) {
          let querys = ''
          this.HTotalSeasons.forEach((item) => {
            let leagues = this.homeChosenLeagues.filter(function (league) { return league == item.league_id })
            let seasons = this.homeChosenSeasons.filter(function (season) { return season == item.season_name })
            if((this.homeChosenLeagues.length == 0 || leagues.length > 0) && (this.homeChosenSeasons.length == 0 || seasons.length > 0))
              querys += (item.season_id + ',')
          })
          if(!querys)
            querys = '-1'
          this.hQuerySeasons = querys

          this.homePage = 0
          this.hLastPage = -1
          this.Hlast = []
          this.lastHomeLoaded = false

          this.getLast(1)
        }else {
          let querys = ''
          this.ATotalSeasons.forEach((item) => {
            let leagues = this.awayChosenLeagues.filter(function (league) { return league == item.league_id })
            let seasons = this.awayChosenSeasons.filter(function (season) { return season == item.season_name })
            if((this.awayChosenLeagues.length == 0 || leagues.length > 0) && (this.awayChosenSeasons.length == 0 || seasons.length > 0))
              querys += (item.season_id + ',')
          })
          if(!querys)
            querys = '-1'
          this.aQuerySeasons = querys

          this.awayPage = 0
          this.aLastPage = -1
          this.Alast = []
          this.lastAwayLoaded = false

          this.getLast(2)
        }
      },
      
      getLast(flag) {
        if (!localStorage.token) {
          this.msg_last = "notlogin"
          if (flag == 1) {
            this.lastHomeLoaded = true
          }else {
            this.lastAwayLoaded = true
          }
        } else {
          // check if already got leagues
          if ((flag == 1 && !this.homeLeagueLoaded) || (flag == 2 && !this.awayLeagueLoaded)) {
            this.callLeague(flag)
          }

          let id, seasons, coach, home, away, perPage, pgNo, fh, prevcoach
          if (flag == 1) {
            id = this.home_id
            seasons = this.hQuerySeasons
            coach = 0
            prevcoach = 0
            perPage = this.homePerPage
            home = this.hLocal ? 1 : 0
            away = this.hVisitor ? 1: 0
            pgNo = this.homePage
            fh = this.hLastFullHalf
          }else {
            id = this.away_id
            seasons = this.aQuerySeasons
            coach = 0
            prevcoach = 0
            perPage = this.awayPerPage
            home = this.aLocal ? 1 : 0
            away = this.aVisitor ? 1: 0
            pgNo = this.awayPage
            fh = this.aLastFullHalf
          }

          if (!seasons) seasons = 0

          axios
          .get(
            '/getLast/',{
              params: {
                id: id,
                seasons: seasons,
                pgNo: pgNo,
                perPage: perPage,
                home: home,
                away: away,
                coach: coach,
                prevcoach: prevcoach,
                fullHalf: fh,
                similar: 0
                },
              headers: { 'Authorization': 'Token ' + localStorage.token } })
          .then(response => {
            if (response.data.type == 0) {
              this.msg_last = "notsubscribe"
            }else if (response.data.type == 1) {
              this.msg_last = "expiredsubscribe"
            }else {
              response = response.data

              let lastAvg = {}
              let otherLastAvg = {}
              // calculate last average table stats
              if (((flag == 1) && !this.homePage) || ((flag == 2) && !this.awayPage)) {
                  
                lastAvg.cornerMatch = response.stats.corner[0] + response.stats.corner[1] + response.stats.corner[2]
                lastAvg.cornerWDL = response.stats.corner
                lastAvg.cornerPoint = 3 * response.stats.corner[0] + response.stats.corner[1]
                lastAvg.cornerDiff = response.stats.tCorner + ' - ' + response.stats.oCorner
                lastAvg.cornerDiff_avg = !lastAvg.cornerMatch ? 0 : ((response.stats.tCorner - response.stats.oCorner) / lastAvg.cornerMatch).toFixed(2)
                lastAvg.cornerTotal = !lastAvg.cornerMatch ? 0 : ((response.stats.tCorner + response.stats.oCorner) / lastAvg.cornerMatch).toFixed(2)
                lastAvg.cornerT1 = !lastAvg.cornerMatch ? 0 : (response.stats.tCorner / lastAvg.cornerMatch).toFixed(2)
                lastAvg.cornerT2 = !lastAvg.cornerMatch ? 0 : (response.stats.oCorner / lastAvg.cornerMatch).toFixed(2)

                lastAvg.goalMatch = response.stats.goal[0] + response.stats.goal[1] + response.stats.goal[2]
                lastAvg.goalWDL = response.stats.goal
                lastAvg.goalPoint = 3 * response.stats.goal[0] + response.stats.goal[1]
                lastAvg.goalDiff = response.stats.tGoal + ' - ' + response.stats.oGoal
                lastAvg.goalDiff_avg = !lastAvg.goalMatch ? 0 : ((response.stats.tGoal - response.stats.oGoal) / lastAvg.goalMatch).toFixed(2)
                lastAvg.goalTotal = !lastAvg.goalMatch ? 0 : ((response.stats.tGoal + response.stats.oGoal) / lastAvg.goalMatch).toFixed(2)
                lastAvg.goalT1 = !lastAvg.goalMatch ? 0 : (response.stats.tGoal / lastAvg.goalMatch).toFixed(2)
                lastAvg.goalT2 = !lastAvg.goalMatch ? 0 : (response.stats.oGoal / lastAvg.goalMatch).toFixed(2)

                lastAvg.cardMatch = response.stats.card
                lastAvg.cardDiff_yc = response.stats.tYC + ' - ' + response.stats.oYC
                lastAvg.cardTotal_yc = !lastAvg.cardMatch ? 0 : ((response.stats.tYC + response.stats.oYC) / lastAvg.cardMatch).toFixed(2)
                lastAvg.cardT1_yc = !lastAvg.cardMatch ? 0 : (response.stats.tYC / lastAvg.cardMatch).toFixed(2)
                lastAvg.cardT2_yc = !lastAvg.cardMatch ? 0 : (response.stats.oYC / lastAvg.cardMatch).toFixed(2)
                lastAvg.cardDiff_rc = response.stats.tRC + ' - ' + response.stats.oRC
                lastAvg.cardTotal_rc = !lastAvg.cardMatch ? 0 : ((response.stats.tRC + response.stats.oRC) / lastAvg.cardMatch).toFixed(2)
                lastAvg.cardT1_rc = !lastAvg.cardMatch ? 0 : (response.stats.tRC / lastAvg.cardMatch).toFixed(2)
                lastAvg.cardT2_rc = !lastAvg.cardMatch ? 0 : (response.stats.oRC / lastAvg.cardMatch).toFixed(2)

                // get avg stats for other tab
                otherLastAvg.win = response.stats.avg_w
                otherLastAvg.draw = response.stats.avg_d
                otherLastAvg.lost = response.stats.avg_l
                otherLastAvg.point = []
                otherLastAvg.match = []
                otherLastAvg.diff = []
                otherLastAvg.diff_av = []
                otherLastAvg.total = []
                otherLastAvg.t1 = []
                otherLastAvg.t2 = []
                for(let i = 0; i < 21; i++) {
                  otherLastAvg.point.push((3 * response.stats.avg_w[i]) + response.stats.avg_d[i])
                  otherLastAvg.match.push(response.stats.avg_w[i] + response.stats.avg_d[i] + response.stats.avg_l[i])
                  otherLastAvg.diff.push(response.stats.avg_t1[i] + ' - ' + response.stats.avg_t2[i])
                  otherLastAvg.diff_av.push(!otherLastAvg.match[i] ? 0 : ((response.stats.avg_t1[i] - response.stats.avg_t2[i]) / otherLastAvg.match[i]).toFixed(2))
                  otherLastAvg.total.push(!otherLastAvg.match[i] ? 0 : ((response.stats.avg_t1[i] + response.stats.avg_t2[i]) / otherLastAvg.match[i]).toFixed(2))
                  otherLastAvg.t1.push(!otherLastAvg.match[i] ? 0 : (response.stats.avg_t1[i] / otherLastAvg.match[i]).toFixed(2))
                  otherLastAvg.t2.push(!otherLastAvg.match[i] ? 0 : (response.stats.avg_t2[i] / otherLastAvg.match[i]).toFixed(2))
                }
              }
              if (flag == 1){
                if (this.homePage == 0) {
                  this.hLastAvg = lastAvg
                  this.hOtherLastAvg = otherLastAvg
                  this.hLastAvgLoaded = true
                }
                if (response.stats.foundCount == 0) {
                  if (this.homePage == 0) {
                    this.hLastPage = 0
                  }else {
                    this.homePage -= 1
                    this.hLastPage = this.homePage
                  }
                }else {
                  if (this.homePerPage == this.$t('all') || response.stats.foundCount < this.homePerPage) {
                    this.hLastPage = this.homePage
                  }
                  this.Hlast[this.homePage] = response.stats.data
                }
              }else {
                if (this.awayPage == 0) {
                    this.aLastAvg = lastAvg
                    this.aOtherLastAvg = otherLastAvg
                    this.aLastAvgLoaded = true
                  }
                  if (response.stats.foundCount == 0) {
                    if (this.awayPage == 0) {
                      this.aLastPage = 0
                    }else {
                      this.awayPage -= 1
                      this.aLastPage = this.awayPage
                    }
                  }else {
                    if (this.awayPerPage == this.$t('all') || response.stats.foundCount < this.awayPerPage) {
                      this.aLastPage = this.awayPage
                    }
                    this.Alast[this.awayPage] = response.stats.data
                  }
              }
              this.authorization = true
            }
            if (flag == 1) {
              this.lastHomeLoaded = true
            }else {
              this.lastAwayLoaded = true
            }
          })
          .catch(error => {
            if (error.response.status == 401) {
              localStorage.removeItem('token')
              localStorage.removeItem('uid')
              location.reload()
            }
          })
        }
      },

      // flag: home? away?
      callLeague(flag) {
        let id
        if (flag == 1) {
          id = this.home_id
        }else {
          id = this.away_id
        }

        axios
          .get('/v3/team/season/' + id,
            { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
          .then(response => {
            if (!response.data.type) {
              let data = this.modifySeason(response.data.season)
              if (flag == 1){
                this.HLeagues = data.leagues
                this.HSeasons = data.seasons
                this.HTotalSeasons = data.totalSeason
                this.homeLeagueLoaded = true
                this.showHomeLeague = true
              }else {
                this.ALeagues = data.leagues
                this.ASeasons = data.seasons
                this.ATotalSeasons = data.totalSeason
                this.awayLeagueLoaded = true
                this.showAwayLeague = true
              }
            }
          })
          .catch(error => {
            if (error.response.status == 401) {
              localStorage.removeItem('token')
              localStorage.removeItem('uid')
              location.reload()
            }
          })
      },

      /*
        modify array of seasons to a specific format
      */
      modifySeason(total) {
        let totalSeason = [], seasons = [], leagues = []
        // let latest = total[0]
        
        total.forEach(el => {
          totalSeason.push({
            season_id: el.id,
            season_name: el.name,
            league_id: el.league_id,
            league_name: el.league.name
          })
          
          seasons.push(el.name)

          let lg = {
            league_id: el.league_id,
            league_name: el.league.name,
          }

          if (!leagues.includes(lg)) {
            leagues.push(lg)
          }
        })

        let season_without_duplicate = seasons.filter((item, index, array) => array.indexOf(item) === index).sort().reverse()

        return {'leagues': leagues, 'seasons': season_without_duplicate, 'totalSeason': totalSeason}
      },

      corner_flag(f) {
        this.flags.corner = f
        if ((f == 1) && !this.lastHomeLoaded && !this.lastAwayLoaded) {
          this.getLast(1)
          this.getLast(2)
        }
      },

      goal_flag(f) {
        this.flags.goal = f
        if ((f == 1) && !this.lastHomeLoaded && !this.lastAwayLoaded) {
          this.getLast(1)
          this.getLast(2)
        }
      },

      card_flag(f) {
        this.flags.card = f
        if ((f == 1) && !this.lastHomeLoaded && !this.lastAwayLoaded) {
          this.getLast(1)
          this.getLast(2)
        }
      },

      other_flag(f) {
        this.flags.other = f
        if ((f == 1) && !this.lastHomeLoaded && !this.lastAwayLoaded) {
          this.getLast(1)
          this.getLast(2)
        }
      },

      cornerModal(id, home_id, home, away, f) {
        if (f == 0) {
          if (this.h2h[id].corners && this.h2h[id].corners.data.length != 0) {
            this.subCorner.dt = moment(this.h2h[id].start_dt).format('DD/MM/YY')
            this.subCorner.home = home
            this.subCorner.away = away
            this.subCorner.data = []
            this.h2h[id].corners.data.forEach((evt) => {
              let item = {
                  comment: evt.comment,
                  h_m: '',
                  a_m: ''
                }
              if (evt.team_id == home_id) item.h_m = (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'"
              else item.a_m = (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'"

              this.subCorner.data.push(item)
            })
            this.subCornerDialog = true
          }
        } else if (f == 1) {
          if (this.Hlast[this.homePage][id].corners && this.Hlast[this.homePage][id].corners.data.length != 0) {
            this.subCorner.dt = moment(this.Hlast[this.homePage][id].date).format('DD/MM/YY')
            this.subCorner.home = home
            this.subCorner.away = away
            this.subCorner.data = []
            this.Hlast[this.homePage][id].corners.data.forEach((evt) => {
              let item = {
                  comment: evt.comment,
                  h_m: '',
                  a_m: ''
                }
              if (evt.team_id == home_id) item.h_m = (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'"
              else item.a_m = (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'"
              this.subCorner.data.push(item)
            })
            this.subCornerDialog = true
          }
        } else if (f == 2) {
          if (this.Alast[this.awayPage][id].corners && this.Alast[this.awayPage][id].corners.data.length != 0) {
            this.subCorner.dt = moment(this.Alast[this.awayPage][id].date).format('DD/MM/YY')
            this.subCorner.home = home
            this.subCorner.away = away
            this.subCorner.data = []
            this.Alast[this.awayPage][id].corners.data.forEach((evt) => {
              let item = {
                  comment: evt.comment,
                  h_m: '',
                  a_m: ''
                }
              if (evt.team_id == home_id) item.h_m = (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'"
              else item.a_m = (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'"
              this.subCorner.data.push(item)
            })
            this.subCornerDialog = true
          }
        }
      },

      cardModal(id, home_id, home, away, f) {
        if (f == 0) {
          if (this.h2h[id].cards && this.h2h[id].cards.data.length != 0) {
            this.subCard.dt = moment(this.h2h[id].start_dt).format('DD/MM/YY')
            this.subCard.home = home
            this.subCard.away = away
            this.subCard.data = []
            this.h2h[id].cards.data.forEach((evt) => {
              let item = {
                  player: evt.player_name,
                  player_id: evt.player_id,
                  minute: (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'",
                  h_c: '',
                  a_c: ''
                }
              if (evt.team_id == home_id) item.h_c = evt.type
              else item.a_c = evt.type

              this.subCard.data.push(item)
            })
            this.subCardDialog = true
          }
        } else if (f == 1) {
          if (this.Hlast[this.homePage][id].cards && this.Hlast[this.homePage][id].cards.length != 0) {
            this.subCard.dt = moment(this.Hlast[this.homePage][id].date).format('DD/MM/YY')
            this.subCard.home = home
            this.subCard.away = away
            this.subCard.data = []
            this.Hlast[this.homePage][id].cards.forEach((evt) => {
              let item = {
                  player: evt.player_name,
                  player_id: evt.player_id,
                  minute: (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'",
                  h_c: '',
                  a_c: ''
                }
              if (evt.team == home_id) item.h_c = evt.type
              else item.a_c = evt.type

              this.subCard.data.push(item)
            })
            this.subCardDialog = true
          }
        } else if (f == 2) {
          if (this.Alast[this.awayPage][id].cards && this.Alast[this.awayPage][id].cards.length != 0) {
            this.subCard.dt = moment(this.Alast[this.awayPage][id].date).format('DD/MM/YY')
            this.subCard.home = home
            this.subCard.away = away
            this.subCard.data = []
            this.Alast[this.awayPage][id].cards.forEach((evt) => {
              let item = {
                  player: evt.player_name,
                  player_id: evt.player_id,
                  minute: (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'",
                  h_c: '',
                  a_c: ''
                }
              if (evt.team == home_id) item.h_c = evt.type
              else item.a_c = evt.type

              this.subCard.data.push(item)
            })
            this.subCardDialog = true
          }
        }
      },

      goalModal(id, home_id, home, away, f) {
        if (f == 0) {
          if (this.h2h[id].goals && this.h2h[id].goals.data.length != 0) {
            this.subGoal.dt = moment(this.h2h[id].start_dt).format('DD/MM/YY')
            this.subGoal.home = home
            this.subGoal.away = away
            this.subGoal.data = []
            this.h2h[id].goals.data.forEach((evt) => {
              let item = {
                  player: evt.player_name,
                  player_id: evt.player_id,
                  minute: (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'",
                  h_g: '',
                  a_g: ''
                }
              if (evt.team_id == home_id) item.h_g = evt.type
              else item.a_g = evt.type

              this.subGoal.data.push(item)
            })
            this.subGoalDialog = true
          }
        } else if (f == 1) {
          if (this.Hlast[this.homePage][id].goals && this.Hlast[this.homePage][id].goals.length != 0) {
            this.subGoal.dt = moment(this.Hlast[this.homePage][id].date).format('DD/MM/YY')
            this.subGoal.home = home
            this.subGoal.away = away
            this.subGoal.data = []
            this.Hlast[this.homePage][id].goals.forEach((evt) => {
              let item = {
                  player: evt.player_name,
                  player_id: evt.player_id,
                  minute: (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'",
                  h_g: '',
                  a_g: ''
                }
              if (evt.team_id == home_id) item.h_g = evt.type
              else item.a_g = evt.type

              this.subGoal.data.push(item)
            })
            this.subGoalDialog = true
          }
        } else if (f == 2) {
          if (this.Alast[this.awayPage][id].goals && this.Alast[this.awayPage][id].goals.length != 0) {
            this.subGoal.dt = moment(this.Alast[this.awayPage][id].date).format('DD/MM/YY')
            this.subGoal.home = home
            this.subGoal.away = away
            this.subGoal.data = []
            this.Alast[this.awayPage][id].goals.forEach((evt) => {
              let item = {
                  player: evt.player_name,
                  player_id: evt.player_id,
                  minute: (evt.extra_minute) ? evt.minute + '+' + evt.extra_minute + "'" : evt.minute + "'",
                  h_g: '',
                  a_g: ''
                }
              if (evt.team_id == home_id) item.h_g = evt.type
              else item.a_g = evt.type

              this.subGoal.data.push(item)
            })
            this.subGoalDialog = true
          }
        }
      },

      modal4RefereeStats(rid) {
        this.chosenReferee = this.refereeStats.get(rid)
        this.refereeDialog = true

        if(!this.chosenReferee) {
          axios
            .get('/v3/referee/stats/' + rid,
              { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
            .then(response => {
              if (response.data.type == 0 || response.data.type == 1) {
                this.$router.replace('/message/subscribe/' + (response.data.type ? 'expired' : 'not-paid'))
              }else {
                response.data.statistics.forEach(element => {
                  let detail = {
                    redcards: null,
                    yellowcards: null,
                    yellowredcards: null,
                    penalties: null,
                    fouls: null,
                    var_moments: null,
                    matches: 0,
                  }

                  element.details.forEach(val => {
                    switch (val.type_id) {
                      case 47:
                        detail.penalties = val.value.all
                        break
                      case 56:
                        detail.fouls = val.value
                        break
                      case 188:
                        detail.matches = val.value.count
                        break
                      case 83:
                        detail.redcards = val.value.all
                        break
                      case 84:
                        detail.yellowcards = val.value.all
                        break
                      case 85:
                        detail.yellowredcards = val.value.all
                        break
                      case 314:
                        detail.var_moments = val.value
                        break
                    }
                  })

                  element.details = detail
                  element.season_name = element.season.name
                })

                response.data.statistics = _.orderBy(response.data.statistics, ['season_name'], ['desc'])
                this.refereeStats.set(rid, response.data)
                this.chosenReferee = response.data
              }
            })
            .catch(error => {
              if (error.response.status == 401) {
                localStorage.removeItem('token')
                localStorage.removeItem('uid')
                location.reload()
              } else {
                this.refereeDialog = false
              }
            })
        }
      },

      homePrev() {
        this.homePage++
        if (!this.Hlast[this.homePage]) {
          this.lastHomeLoaded = false
          this.getLast(1)
        }
      },

      homeNext() {
        this.homePage--
      },

      awayPrev() {
        this.awayPage++
        if (!this.Alast[this.awayPage]) {
          this.lastAwayLoaded = false
          this.getLast(2)
        }
      },

      awayNext() {
        this.awayPage--
      },

      h2hPrev() {
        this.h2hPage++
      },

      h2hNext() {
        this.h2hPage--
      },

      homePerPageChange(evt) {
        this.homePerPage = this.hPPageByUser
      },

      awayPerPageChange(evt) {
        this.awayPerPage = this.aPPageByUser
      },

      h2hPerPageChange(evt) {
        this.h2hPerPage = this.h2hPPageByUser
      },

      matchDetail(id) {
        let routeData = this.$router.resolve({name: 'fixture', params: {fid: id}});
        window.open(routeData.href, '_blank');
      }
  },
};
</script>