<template>
  <navbar
    position="fixed"
    menu-classes="ml-auto"
    disable-route-watcher="true"
  >
    <!-- <template slot-scope="{ toggle, isToggled }"> -->
    <template>
      <router-link v-popover:popover1 class="navbar-brand" to="/">
        <img src="/img/logo.png">

        <span :class="{'d-none': $vuetify.breakpoint.xs}">
          Foot-Corner
        </span>
      </router-link>
      
      <v-dialog
        v-model="searchBox"
        width="400"
        style="z-index: 3000">
        <v-card
          elevation="3"
        >
          <v-app-bar
            color="blue"
            dark
            dense
            flat
            height="35"
          >
            <v-toolbar-title>
              <span class="subtitle-1">{{ $t('search') }}</span>
            </v-toolbar-title>
          </v-app-bar>

          <v-card-text>
            <v-text-field
              v-model="searchText"
              append-outer-icon="mdi-send"
              prepend-icon="mdi-database-search"
              autofocus
              filled
              dense
              clear-icon="mdi-close-circle"
              clearable
              :label="`${ $t('team') }/${ $t('league') }/${ $t('country') }`"
              type="text"
              :loading="searching"
              v-on:keyup.enter="search"
              @click:append-outer="search"
              @click:clear="searchText=''"
            ></v-text-field>

            <v-list
              v-if="countries && countries.length"
              dense
            >
              <v-subheader>{{ $t('country') }}</v-subheader>

              <v-list-item-group
                color="primary"
              >
                <v-list-item
                  v-for="item in countries"
                  :key="'c' + item.id"
                  @click="select_country(item.id)"
                >
                  <v-list-item-title>
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <v-list
              v-if="leagues && leagues.length"
              dense
            >
              <v-subheader>{{ $t('league') }}</v-subheader>

              <v-list-item-group
                color="primary"
              >
                <v-list-item
                  v-for="item in leagues"
                  :key="'l' + item.league_id"
                  @click="select_league(item.league_id)"
                >
                  <v-list-item-title>
                    {{ item.league_name }} ({{ item.country_name }})
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <v-list
              v-if="teams && teams.length"
              dense
            >
              <v-subheader>{{ $t('team') }}</v-subheader>

              <v-list-item-group
                color="primary"
              >
                <v-list-item
                  v-for="item in teams"
                  :key="'t' + item.id"
                  @click="select_team(item.id)"
                >
                  <v-list-item-title>
                    {{ item.name }} ({{ item.country.name }})
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <v-btn
              :class="{'d-none': !team_has_more}"
              :loading="team_loading"
              :disabled="team_loading"
              color="success"
              @click="searchApi()"
              small
            >
              {{ $t('loadmore') }} {{ $t('team') }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-btn
        depressed
        rounded
        style="color: #007bff"
        @click="searchDialog()"
        small
      >
        <v-icon>
          mdi-magnify
        </v-icon>

        <span class="text-capitalize" :class="{'d-none': $vuetify.breakpoint.xs, 'd-inline': $vuetify.breakpoint.smAndUp}">
          {{ $t('search') }}
        </span>
      </v-btn>
    </template>

    <template slot="navbar-menu">
      <li class="nav-item d-flex">
        <v-select
          v-model="language"
          :items="lang"
          item-text="name"
          item-value="id"
          class="col-lg-6 col-10 align-self-center"
          attach
          outlined
          dense
          hide-details
        />
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link"
          :class="$route.name=='today-match' ? 'active' : ''"
          to="/matches"
        >
          <p>{{ $t('upcomings') }}</p>
        </router-link>
      </li>
      <!-- <li class="nav-item">
        <router-link
          class="nav-link"
          to="/blog"
        >
          <p>{{ $t('blog') }}</p>
        </router-link>
      </li> -->
      <li class="nav-item">
        <router-link
          class="nav-link"
          to="/subscription"
        >
          <p>{{ $t('subscription') }}</p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link"
          to="/setting"
        >
          <i class="now-ui-icons ui-1_settings-gear-63"></i>
        </router-link>
      </li>
      <li v-if="!username" class="nav-item">
        <router-link
          class="nav-link"
          to="/login"
        >
          <p>{{ $t('login') }}</p>
        </router-link>
      </li>
      <li v-else class="nav-item">
        <a
          class="nav-link"
          @click="logout()"
          style="cursor: pointer;"
        >
          <i class="now-ui-icons users_single-02"></i> <b>{{ username }}</b> - <p>{{ $t('logout') }}</p>
        </a>
      </li>
    </template>
  </navbar>
</template>

<script>
import { DropDown, NavbarToggleButton, Navbar, NavLink } from '@/components'
import { Popover } from 'element-ui'
import variables from '@/variables'
import axios from 'axios';
import config from '@/config'

axios.defaults.baseURL = config.API_URL;

export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  data() {
    return {
      username: localStorage.token ? localStorage.uid : null,
      searchBox: false,
      searchText: null,
      searching: false,
      leagues: null,
      countries: null,
      teams: [],
      team_pg: 1,
      team_has_more: null,
      team_loading: false,
      language: localStorage.language ? localStorage.language : 'en',
      lang: [
        { name: localStorage.language == 'pt' ? 'Inglês' : 'English', id: 'en' },
        { name: localStorage.language == 'pt' ? 'Português' : 'Portuguese', id: 'pt' },
      ]
    }
  },
  components: {
    DropDown,
    Navbar,
    NavbarToggleButton,
    NavLink,
    [Popover.name]: Popover
  },
  watch: {
    language (val) {
      // this.$vuetify.locale.current = this.language
      this.$i18n.locale = val
      localStorage.language = val

      if (val == 'en') {
        this.lang = [{ name: 'English', id: 'en' }, { name: 'Portuguese', id: 'pt' }]
      } else {
        this.lang = [{ name: 'Inglês', id: 'en' }, { name: 'Português', id: 'pt' }]
      }
    }
  },
  methods: {
    closeMenu() {
      Navbar.toggle()
    },
    logout() {
            localStorage.removeItem('token')
            localStorage.removeItem('uid')
            location.reload()
    },

    searchDialog() {
      this.searchBox = true
    },

    search () {
      if (this.searchText && !this.searchText.startsWith(" ")) {
        this.searchText = this.searchText.toLowerCase()
        this.searching = true      
        
        this.teams = []
        this.team_pg = 1
        
        this.searchApi()
      }
    },

    searchApi () {
      let url = "/search/"
      let headers

      if (localStorage.token) {
        url += "v3/"
        headers = { 'Authorization': 'Token ' + localStorage.token }
                
        this.team_loading = true
      } else {
        url += "v1/"
        headers = null
      }

      axios
        .get(url,
          { params: { q: this.searchText, pg: this.team_pg },
            headers: headers
          })
        .then(response => {
          if (this.team_pg < 2) {
            this.searching = false
            this.leagues = response.data.leagues
            this.countries = variables.countries.filter(ct => (ct.name.toLowerCase().includes(this.searchText)))
          }

          this.teams = this.teams.concat(response.data.teams)
          
          this.team_has_more = response.data.has_more
          this.team_pg++
          this.team_loading = false          
        })
        .catch(error => {
          if (error.response.status == 401) {
            localStorage.removeItem('token')
            localStorage.removeItem('uid')
            location.reload()
          }
        })
    },

    select_country(id) {
      this.$router.replace('/country/' + id)
    },

    select_league(id) {
      this.$router.replace('/league/' + id)
    },

    select_team(id) {
      this.$router.replace('/team/' + id)
    }
  },
};
</script>

<style scoped>
.def-gradient-bg {
    background: #f9f9f9;
    background: -moz-linear-gradient(top, #f9f9f9 0%, #e9e9e9 100%);
    background: -webkit-linear-gradient(top, #f9f9f9 0%,#e9e9e9 100%);
    background: linear-gradient(to bottom, #f9f9f9 0%,#e9e9e9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9f9f9', endColorstr='#e9e9e9',GradientType=0 );
}
.nav_div {
    height: 34px;
    overflow: hidden;
    border: solid 1px #bcbcbc;
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 2px 0 #CFCFCF;
    box-shadow: 0 1px 2px 0 #CFCFCF;
}
.mobi-close {
    position: absolute;
    top: 185px;
    right: 10px;
    width: 16px;
    height: 16px;
}
#nav {
    position: absolute;
    /* position: static; */
    display: table;
    width: 950px;
}
#nav, #nav ul, #nav li {
    margin: 0;
    padding: 0;
}
.nav_left {
    float: left;
}
.nav> a {
    font-size: 13px;
    color: #717171;
    padding: 8px 5px;
    display: inline-block;
    min-width: 83px;
    text-align: center;
}
.nav_left> a {
    border-right: solid 1px #bcbcbc;
}
.time-language-box {
    margin: 10px 30px 0 0;
    /* margin: -23px 30px 0 0; */
    font-size: 14px;
}
.f-r {
    float: right;
}
</style>
