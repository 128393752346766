<template id="results">
    <div class="results">
        <h4>{{ $t('message.successsignup') }}</h4>

        <p>
          {{ $t('thanks') }}.
          <br>
          {{ $t('message.checkemailverification') }}
        </p>
        
        <button
          class="btn btn-primary btn-round btn-lg btn-block"
          @click="back_to_login"
          >
          {{ $t('continue') }}
        </button>
    </div>
</template>

<script>
export default {
  name: 'results',
  template: '#results',
  methods: {
    back_to_login() {
      this.$router.replace('/login')
    }
  }
};
</script>